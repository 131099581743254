import Assure from "./assure.model";
import Contract from "./contract.model";

export default class ContractDetail {
    id;
    // PARTNER
    entitiesCode;
    entitiesTitle;
    entitiesAddress
    contributorRef;
    contributor;

    contract = new Contract();
    productCode;
    categoryCode;
    
    // ASSURE
    assure = new Assure();

    // AVENANT
    avenantRef;
    avenantCode;
    numAvenant;
    avenantTitle;

    // PACK
    packRef;
    packTitle;

    // CONTRACT
    months;
    days;
    startDate;
    endDate;
    period;

    primeTTC;
    primeNette;
    primeExtra;
    agencyFees;
    commission;

    attestationNumber;
    
    couponRef;
    couponCode;

    monoPackSelected;
    flottePacksSelected = [];

    guaranties = [];
    reductionGroups = [];
    extras = [];

    assistance;
    flotteAssistance = [];
    contractSignature;

    transactionId;
    paymentProviderTitle;

    canal;

    status;

    fromJson = (json={})=> {
        var contractDetail = new ContractDetail();
        contractDetail = json;
        return contractDetail;
    }

}