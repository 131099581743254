import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import ContractTable from "views/pages/common/ContractTable";

export default function ListSubscriptionGroupement(props) {
    
    return (
        <React.Fragment>
            <br />
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <div>
                                <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 500 }}>{(`Liste des souscriptions groupement`).toLocaleUpperCase()}</h4>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <ContractTable type={"GROUPEMENT"}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}