import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class ContributorService {
    static readAllContributors(options,sCallBack, eCallBack) {
        ApiRoutes.contributorsResource.resourceOptions = Object.assign({}, ApiRoutes.contributorsResource.resourceOptions, options);
        exeRequest(ApiRoutes.contributorsResource, {}, sCallBack, eCallBack)
    }


}