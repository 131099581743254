import React from 'react'

import { Button } from 'antd'
import { makeStyles } from '@material-ui/styles'


const useStyles = makeStyles(() => ({
  btnContainer: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: 20
  },
  btn: {
    minWidth: 100
  },
  spacer: {
    width: 20
  }
}));

export default function WizardBottomButton(props) {
  const { btn1, btn2, btn3 } = props;

  const classes = useStyles();

  return (
    <div className={classes.btnContainer}>
      {btn3 && (
        <>
          <Button
            disabled={btn3.disabled}
            loading={btn3.loading}
            className={classes.btn}
            type="primary"
            onClick={() => btn3.onClick()}
            {...btn3.props}
          >
            {btn3.label}
          </Button>

          <div className={classes.spacer} />
        </>
      )}

      {btn2 && (
        <>
          <Button
            disabled={btn2.disabled}
            loading={btn2.loading}
            className={classes.btn}
            type="primary"
            onClick={() => btn2.onClick()}
            {...btn2.props}
          >
            {btn2.label}
          </Button>

          <div className={classes.spacer} />
        </>
      )}

      <Button
        disabled={btn1.disabled}
        loading={btn1.loading}
        className={classes.btn}
        type="primary"
        onClick={btn1.onClick ? () => btn1.onClick() : null}
        {...btn1.props}
      >
        {btn1.label}
      </Button>
    </div>
  )
}
