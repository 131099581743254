export default class CreateGroupeReductionRequest {
    code;
    title;
    description;

    fromJson = (json = {}) => {
        var createGroupeReductionRequest = new CreateGroupeReductionRequest();
        createGroupeReductionRequest = json;
        return createGroupeReductionRequest;
    }
}