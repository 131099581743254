const layoutTheme = {
    topbarOne: {
        backgroundColor: "#59a3ee"
    },
    topbarTwo: {
        backgroundColor: "white"
    },
    sidebar: {
        backgroundColor: "#59a3ee",
        titleBackgroundColor: "#59a3ee"
    },
    tab:{
        backgroundColor: "gray",
        selectedItemColor: "#59a3ee"
    },
    footer: {
        backgroundColor: "gray"
    }
}

export default layoutTheme;
