export default class CreateCoInsuranceRequest {
    code;
    title;
    description;

    fromJson = (json={})=> {
        var createCoInsuranceRequest = new CreateCoInsuranceRequest();
        createCoInsuranceRequest = json;
        return createCoInsuranceRequest;
    }
}