import { Table, DatePicker, Form, Pagination, Spin, Input, Tag, Typography } from "antd";
import PageResponse from "api/response/pageResponse";
import { DEFAULT_TABLE_MAX_SIZE } from "constantes";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, Row,Col, CardBody, CardHeader, Button } from "reactstrap";
import {  DocumentType, onDocumentDownload, thousandSeparator } from "helpers/helpers";
import ReportingService from "services/reportingService";
import { useSelector } from "react-redux";
import { PROFILE_ADMINISTATOR, PROFILE_ADMIN_CENTRAL, PROFILE_PRODUCTOR_CENTRAL } from "profileActions";
import { LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

export default function Emission(props) {

    const { user} = useSelector((state) => state.auth);
    console.log("USER", user);
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const [current, setCurrent] = useState(1);
    const [seachOption, setSearchOption]= useState({
        startDate: null,
        endDate: null,
        partnerCode: null
    })
    const [state, setState] = useState({
        reportings: [],
        isReportingLoading: false,
        isGenerateReportLoading: false,
        inputSaissie: "",
        page: 0,
        size: 100, 
        totalElements: 0, 
        totalPages: 0
    });

    useEffect(()=>{
        //readCommissionReportings(null, null, null, 0);
    }, [])

    const onPageChange = (page) => {
        console.log(":::: onPageChange ", page)
        setCurrent(page);
        refresh();
    };

    const onShowSizeChange = (current) => {
        refresh();
    };

    // REFRESH TABLE
    const refresh=()=> {
        form.validateFields().then((value)=> {
            
            const rangeDate = value?.rangeDate;
            const startDate = rangeDate[0]?.format("YYYY-MM-DD");
            const endDate   = rangeDate[1]?.format("YYYY-MM-DD");
            const partnerCode = value?.partnerCode === undefined || value?.partnerCode === null || value?.partnerCode === '' ? null : value?.partnerCode ;

            if(!startDate || !endDate) {
                toast.error("veuiller choisir une date");
                return;
            }
            
            loadCommissionReportings(startDate, endDate, partnerCode, current-1);   
        })
        
    }

    const onDownloadBordereauEmission=()=> {
        setState(state => ({ ...state, isGenerateReportLoading: true }));
        const options = {
            query: { 
                startDate: seachOption.startDate, 
                endDate: seachOption.endDate, 
                partnerCode: seachOption.partnerCode
                 
            }};
        (new ReportingService()).generateBordereauEmissionReporting(options, (data)=> {
            setState(state => ({ ...state, isGenerateReportLoading: false }));
            console.log("response", data);
            if(data?.error) {
                toast.error(data?.errorMessage);
                setState(state => ({ ...state, isReportingLoading: false,  reportings: [], totalElements: 0, page: 0}));
            }
            else {
                // SHEETJS
                //const wb = read(data?.document, {type: "base64" });
                //writeFileXLSX(wb, "bordereau.xlsx", {type: "base64" })

                // GILLE
                //var byte= base64ToArrayBuffer(data?.document);
                //saveByteArray(`${user.entities?.code}_bordereau-emission_${options.query.startDate}_au_${options.query.endDate}.xlsx`, byte);
                
                //saveAsXlsxFile(data?.document);
                onDocumentDownload(data?.filename);
                
            }
            
        }, (error)=> {
            setState(state => ({ ...state, isGenerateReportLoading: false }));
            toast.error(error)
        });        
    }

    // GENERATED RAPPORT
    const generateReport =(values) => {
        setState(state => ({ ...state, isGenerateReportLoading: true }));
        form.validateFields().then((value)=> {
            const rangeDate = value.rangeDate;
            const startDate = rangeDate[0].format("YYYY-MM-DD");
            const endDate = rangeDate[1].format("YYYY-MM-DD");

            let options = {
                query: { startDate: startDate, endDate: endDate }
            };

            (new ReportingService()).generateBordereauEmissionReporting(options, (data)=> {
                setState(state => ({ ...state, isGenerateReportLoading: false }));
                console.log("response", data);
                if(data?.error) {
                    toast.info(data?.errorMessage);
                    setState(state => ({ ...state, isReportingLoading: false,  reportings: [], totalElements: 0, page: 0}));
                }
                else {
                    
                    onDocumentDownload(DocumentType.REPPORT,  `rapport_bordereau_emission_${data?.entitiesCode}.xlsx`);
                    loadCommissionReportings(startDate, endDate, 0);
                }
                
            }, (error)=> {
                setState(state => ({ ...state, isGenerateReportLoading: false }));
                toast.error(error)
            });
        })
    
    }

    // READ ALL REPORT
    const loadCommissionReportings = (startDate, endDate, partnerCode, page, size) => {
        setState(state => ({ ...state, isReportingLoading: true }));
        const options = {
            query: { 
                startDate: startDate, 
                endDate: endDate, 
                partnerCode: partnerCode,
                page: page,
                size: null
             }
        };
        (new ReportingService()).readAllCommissionReporting(options, (data)=> {
            let pageResponse = (new PageResponse()).fromJson(data);
            setState(state => ({ ...state, isReportingLoading: false,  reportings: pageResponse.content, totalElements: pageResponse.totalElements, page: pageResponse.page}));
            setSearchOption((seachOption=> ({...seachOption, startDate: startDate, endDate: endDate, partnerCode:  partnerCode})));
        }, (error)=> {
            setState(state => ({ ...state, isReportingLoading: false }));
            toast.error(error)
        });
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 15 }} spin />;
    const { Text } = Typography;

    const columns = [
        {
            title: "N°",
            dataIndex: "number",
            key: "number",
            width: 50
        },
        {
            title: "Cat.",
            dataIndex: "categoryCode",
            key: "categoryCode",
            width: 50
        },
        {
            title: "N° Police",
            dataIndex: "policeNumber",
            key: "policeNumber",
            width: 150
        },
        {
            title: "Code Int.",
            dataIndex: "entitiesCode",
            key: "entitiesCode",
            width: 100
        },
        {
            title: "Av.",
            dataIndex: "numAvenant",
            key: "numAvenant",
            width: 40
        },
        {
            title: "Assuré",
            dataIndex: "assureName",
            key: "assureName",
            width: 250
        },
        {
            title: "Apporteur",
            dataIndex: "merchant",
            key: "merchant",
            width: 250
        },

        {
            title: "D. Emmission",
            dataIndex: "createdAt",
            key: "createdAt",
            width: 90,
            fixed: 'right',
        },
        {
            title: "Effet",
            dataIndex: "startDate",
            key: "startDate",
            width: 90,
            fixed: 'right',
        },
        {
            title: "Echéance",
            dataIndex: "endDate",
            key: "endDate",
            width: 90,
            fixed: 'right',
        },
        
        /* {
            title: "Prime nette",
            dataIndex: "primeNette",
            key: "primeNette",
            align: 'right',
            fixed: 'right',
            width: 100
        },
        {
            title: "Accessoire",
            dataIndex: "primeAccessory",
            key: "primeAccessory",
            align: 'right',
            fixed: 'right',
            width: 100
        },
        {
            title: "Taxe",
            dataIndex: "primeTaxe",
            key: "primeTaxe",
            align: 'right',
            fixed: 'right',
            width: 100
        }, */
        {
            title: "Prime totale",
            dataIndex: "primeTTC",
            key: "primeTTC",
            align: 'right',
            fixed: 'right',
            width: 110,
            render: (item)=> <span>{thousandSeparator(item)}</span>
        },
        {
            title: "Statut",
            dataIndex: "isPaid",
            key: "isPaid",
            align: 'right',
            fixed: 'right',
            width: 90,
            render:(item)=> {
                return item ? (<Tag color="green">Soldé</Tag>) : (<Tag color="red">Non soldé</Tag>)
            }
        },
        {
            title: "Commission",
            dataIndex: "primeCommission",
            key: "primeCommission",
            align: 'right',
            fixed: 'right',
            width: 100,
            render: (item)=> <span>{thousandSeparator(item)}</span>
        },
        /* {
            title: "Primes Hors. Com",
            dataIndex: "primeExcludingCom",
            key: "primeExcludingCom",
            align: 'right',
            fixed: 'right',
            width: 100
        } */
    ];

    return (
        <React.Fragment>
            <Row>
                <Col lg={12} md={12}>
                    <Card>
                        <CardHeader>
                            <Form {...layout} name="control-ref" form={form} onFinish={refresh} >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="rangeDate" label="Choisir date debut et fin"
                                            rules={[{ required: true }]}
                                        >
                                            <RangePicker
                                                //showTime={{ format: 'HH:mm' }}
                                                size={'large'}
                                                format={"DD/MM/YYYY"}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>

                                    </Col>
                                    {
                                        [PROFILE_ADMINISTATOR, PROFILE_ADMIN_CENTRAL, PROFILE_PRODUCTOR_CENTRAL].includes(user?.profile) ? (
                                            <Col span={2} md={2}>
                                                <Form.Item
                                                    name="partnerCode" 
                                                    label="Saisir le code intermediaire"
                                                    rules={[{ required: false }]}
                                                
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder="Code "
                                                    // onChange={onChange}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ): <></>
                                    }
                                    
                                    <Col span={5} md={6} className="text-right">
                                        <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                            <div className="col-auto">
                                                <Button
                                                    className="btn-label left"
                                                    type="primary"
                                                    style={{ marginTop: 40, justifyContent: 'center' }}
                                                    htmlType="submit"
                                                >
                                                    <i className=" bx bx-refresh label-icon align-middle fs-16"></i>
                                                    Actualisé
                                                </Button>
                                            </div>
                                            <div style={{marginLeft:5}}>
                                                <div className="col-auto">
                                                    <Button  color="primary" className="btn-label right" style={{ marginTop: 40, justifyContent: 'center' }} onClick={onDownloadBordereauEmission}> <i className=" bx bx-download label-icon align-middle fs-16 ms-2"></i> 
                                                        {state.isGenerateReportLoading ? (<Spin indicator={antIcon} />) : null}  Télécharger 
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Table 
                                    columns={columns} 
                                    dataSource={state.reportings}
                                    pagination={{ position: ["topRight"], pageSize: 10 }}
                                    scroll={{ x: 1700 }} 
                                    key="reportTable"
                                    loading={state.isReportingLoading}
                                    bordered
                                    
                                    summary={(pageData) => {
                                        let tPrime = 0;
                                        let tCommission = 0;
                                       
                                        // pageData.forEach(({ primeTTC, primeCommission }) => {
                                        //     tPrime += parseInt(primeTTC.replace(" ", ""));
                                        //     tCommission += parseInt(primeCommission.replace(" ", ""));
                                        // });

                                        tPrime = _.sum(_.map(state.reportings, (reporting)=> parseInt(reporting?.primeTTC.replace(" ", ""))));
                                        tCommission = _.sum(_.map(state.reportings, (reporting)=> parseInt(reporting?.primeCommission.replace(" ", "")))); 
                                        return (
                                          <Table.Summary fixed='bottom'>
                                            <Table.Summary.Row style={{fontWeight: "bold"}} >
                                              <Table.Summary.Cell index={0} colSpan={7} >Total</Table.Summary.Cell>
                                              <Table.Summary.Cell index={7} colSpan={4} align="right">
                                                <Text >{thousandSeparator(tPrime)}</Text>
                                              </Table.Summary.Cell>
                                              <Table.Summary.Cell index={11} colSpan={2} align="right">
                                                <Text>{thousandSeparator(tCommission)}</Text>
                                              </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                          </Table.Summary>
                                        );
                                      }}
                                    
                                /><br />
                                <div style={{ textAlign: 'center' }}>
                                    <Pagination current={current} onChange={onPageChange} defaultPageSize={DEFAULT_TABLE_MAX_SIZE} total={state.totalElements} showSizeChanger onShowSizeChange={onShowSizeChange} />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}