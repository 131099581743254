import React from "react";

const ReturnNewTitle=(title)=> {
    return (
        <React.Fragment>
            <div style={{ height: 35, width: '100%', backgroundColor: '#9db7b4', color: 'white', paddingTop: 5, paddingLeft: 20, paddingBottom: 5 }}>
                <h5 style={{ color: 'white' }}>{title}</h5>
            </div>
        </React.Fragment>
    )
}

export default ReturnNewTitle;