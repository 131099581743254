export default class BrandRegisterForm {
    code;
    title;
    description;

    toJson = (json = {}) => {
        var formRegisterBrand = new BrandRegisterForm();
        formRegisterBrand = json;
        return formRegisterBrand;
    }
}