export default class CreateGroupementRequest {
    title;
    description;
    nbVehicle;
    groupementTypeRef;

    fromJson = (json={})=> {
        var createGroupementRequest = new CreateGroupementRequest();
        createGroupementRequest = json;
        return createGroupementRequest;
    }
}