export default class FormRegisterReduction {
    code;
    title;
    description;
    rate;
    critere;
    groupeReduction;

    toJson = (json = {}) => {
        var formRegisterReduction = new FormRegisterReduction();
        formRegisterReduction = json;
        return formRegisterReduction;
    }
}