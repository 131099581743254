import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AssureTable from 'views/pages/common/AssureTable';
import ProjectTable from 'views/pages/common/ProjectTable';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ContractTable from 'views/pages/common/ContractTable';
import { useEffect } from 'react';
import { basketRaz } from 'redux/slices/basketSlice';
import SubscriptionHeader from 'views/pages/subscription/SubscriptionHeader';
import { SubscriptionSwitchOption } from 'helpers/helpers';
import useInitSubscription from 'views/pages/subscription/hooks/useInitSubscription';

function LoadUserDashboard() {
    localStorage.removeItem("subscription_init");
    const dispatch = useDispatch();
    const [switchValue, setSwitchValue] = useState(SubscriptionSwitchOption.LIST_SUBSCRIPTION);

    useEffect(() => {
        dispatch(basketRaz())
    }, [])

    return (
        <React.Fragment>
            <SubscriptionHeader 
                switchValue={switchValue} 
                onSwitchValue={setSwitchValue} 
                />
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <div>
                                <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 500 }}>{(`Toutes catégories`).toLocaleUpperCase()}</h4>
                            </div>
                        </CardHeader>
                        <CardBody>
                            {(switchValue === SubscriptionSwitchOption.LIST_ASSURE) && (<AssureTable key={"assure"}/>)}

                            { (switchValue === SubscriptionSwitchOption.LIST_SUBSCRIPTION) && (<ContractTable key={"contrat"}/>)}

                            {(switchValue === SubscriptionSwitchOption.LIST_PROJECT) && (<ProjectTable  key={"project"}/>)}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default LoadUserDashboard;
