import { Col, Form, Select, Spin } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import React, { useEffect } from "react";
import { useState } from "react";

import CategoryService from "services/categoryService";

const CategoryField = ({form, categoryRef, disable, handlerFormField, skipCategories=['400'], xs, md})=> {
    const { Option } = Select;
    const [state, setState] = useState({
        // CATEGORY
        categories: [],
        isCategoryLoading: true,
        categorySelected: {}
    })

    useEffect(()=> {
        getAllCategories()
    }, [])

    const getAllCategories = ()=> {
        (new CategoryService()).readAllCategory({}, (data)=> {
            // Elimine la category 400: Flotte dans la liste
            const categories = (data || []).filter((category, index)=> !skipCategories?.includes(category?.code)).sort((a,b) => a.pos - b.pos);
            // DEFAULT 
            let categorySelected = null;
            if(!categoryRef) {
                categorySelected = categories.find((category)=> category?.code === '401');  
            }
            else {
                categorySelected = categories.find((category)=> category?.ref === categoryRef); 
            }

            if(categorySelected) {
                handlerFormField({
                    categoryRef: categorySelected?.ref,
                    categoryCode: categorySelected?.code,
                    categoryTitle: categorySelected?.title
                })

                form.setFieldsValue({
                    categoryRef: categorySelected?.ref
                })
            }
            
                
            setState(state=>({
                ...state, 
                categories: categories,
                categorySelected: categorySelected,   
                isCategoryLoading: false
            })); 
            
            // TRANSPORTS
            //getAllTransport(categorySelected?.ref);
        }, (error)=>{})
    }

    // <On select>
    const onCategorySelected = (categoryRef)=> {
        const categorySelected = state.categories.find((category)=> category.ref === categoryRef);
        if(categorySelected) {
            handlerFormField({
                categoryRef: categorySelected?.ref,
                categoryCode: categorySelected?.code,
                categoryTitle: categorySelected?.title
            })

            form.setFieldsValue({
                categoryRef: categorySelected?.ref
            });

            setState(state=>({
                ...state, 
                categorySelected: categorySelected, 
                isTransportLoading: true, 
            }));
            
            //getAllTransport(categorySelected?.ref);
        }
    }

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Categorie"
                    name="categoryRef"
                    rules={[{ required: false, message: "Champ obligatoire" }]}
                >
                    {
                        state.isCategoryLoading ? <Spin /> : (
                            <SelectWithFilter 
                                loading={state.isCategoryLoading} 
                                placeholder='Choisissez la category' 
                                defaultValue={state.categorySelected !== null ? state.categorySelected?.title : null} 
                                onChange={(e)=> onCategorySelected(e)} 
                                disable={disable}
                                >
                                {state.categories.map((category, index)=><Option value={category?.ref} key={index} >{category?.code} : {category?.title}</Option>)}
                            </SelectWithFilter>
                        )
                    }
                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default CategoryField;