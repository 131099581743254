export default class FormEditPartner {
    code;
    title;
    description;
    address;
    email;
    phone;
    contributorRef;
    contributorTitle;

    fromJson = (json={})=> {
        var formEditPartner = new FormEditPartner();
        formEditPartner = json;
        return formEditPartner;
    }
}