import React from 'react';

import { useSelector } from 'react-redux';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SweetAlert from 'react-bootstrap-sweetalert';
import MyErrorBoundary from 'components/MyErrorBoundary';
import Login from 'components/Logins/DesktopLogin';
import App from 'App'
import 'services/extensions';
import store from 'redux/store';
import { ACTIONS_ADMINISTRATOR, ACTIONS_AGENCY, ACTIONS_PARNER, ACTIONS_PRODUCTOR, ACTIONS_SU, ACTIONS_SUPERVISOR, ADMINISTRATOR, PROFILE_ADMINISTATOR, PROFILE_AGENCY, PROFILE_PARTNER, PROFILE_PRODUCTOR, PROFILE_PRODUCTOR_CENTRAL, PROFILE_SU, PROFILE_SUPERVISOR } from 'profileActions';
import { logout } from 'redux/slices/authSlice';

function Starter() {
    const { accessToken, user} = useSelector((state) => state.auth);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (isMobile) {
        return (
            <SweetAlert
                info
                title='SANLAM BENIN'
                showConfirm={false}
            >
                <p>Désolé, cette application ne peut être visualisée que sur un grand écran</p>
                <div style={{ height: 20 }} />
            </SweetAlert>
        );
    }

    let userActions = [];

    switch (user?.profile) {
        case PROFILE_SU:
            userActions = [...ACTIONS_SU];
            break;
        case PROFILE_ADMINISTATOR:
            userActions = [...ACTIONS_ADMINISTRATOR];
            break;

        case PROFILE_SUPERVISOR:
            userActions = [...ACTIONS_SUPERVISOR];
        break;

        case PROFILE_PRODUCTOR_CENTRAL:
        case PROFILE_PRODUCTOR:
            userActions = [...ACTIONS_PRODUCTOR];
            break;

        case PROFILE_AGENCY:
            userActions = [...ACTIONS_AGENCY];
            break;
        case PROFILE_PARTNER:
            userActions = [...ACTIONS_PARNER];
            break;

        default:

    }

    if (!accessToken) {
        return (
            <Switch>
                <Route component={Login} exact path="/" />
                <Redirect to="/" />
            </Switch>
        );
    }

    if (!userActions || userActions.length === 0) 
        return (
            <SweetAlert
                title='SANLAM BENIN'
                showConfirm={false}
                danger={true}
            >
                <p>Désolé, vous n'avez acces à aucune action, veuillez contactez l'administrateur </p>
                <p>ou votre session est expiré, veuillez <Link onClick={()=> {
                    store.dispatch(logout());
                }}> vous reconnecter </Link> </p>
                <div style={{ height: 20 }} />
            </SweetAlert>
        );

    return (
        <MyErrorBoundary >
            <App userActions={userActions} />
        </MyErrorBoundary>
    )
}

export default Starter