import { Tag } from 'antd';
import { thousandSeparator } from 'helpers/helpers';
import React from 'react'

const AmountHeader=({packSelected})=> {
    return (
        <div style={{ display: 'flex', justifyContent: 'end', backgroundColor: 'rgba(233,233,233, 0.5)', color: '#ffffff', boxShadow: "0px 0.5px 0.5px gray" }}>
            <div style={{ height: 30,  display: 'flex' }}>
                <h4 >Montant à payer</h4>
                {
                    packSelected?.selected ? (
                        <div style={{marginLeft: '10px'}}>
                            <Tag color='#1DCC70' style={{fontSize: 18}}>{thousandSeparator(packSelected?.primeTTC)} FCFA</Tag>
                        </div>
                    ) : (
                        <div style={{marginLeft: '10px'}}>
                            <Tag color='#9db7b4'>*** Selon le pack choisi ***</Tag>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default AmountHeader;