export default class CreateGarantieRequest {
    code;
    title;
    description;

    static fromJson = (json = {}) => {
        var createGarantieRequest = new CreateGarantieRequest();
        createGarantieRequest = json;
        return createGarantieRequest;
    }
}