import { createSlice } from "@reduxjs/toolkit"
import Assure from "api/model/assure.model"
import Avenant from "api/model/avenant.model"
import Category from "api/model/category.model"
import Contract from "api/model/contract.model"
import ContractDetail from "api/model/contractDeatil.model"
import Period from "api/model/period.model"
import * as helpers from 'helpers/helpers';

const initialState = {
    /* pathParam: {},
    subscriptionWidget  : SubscriptionWidget.LIST_SUBSCRIPTION,
    subscriptionType    : SubscriptionType.NORMAL, */

    category: new Category(),
    assure: new Assure(),
    avenant: new Avenant(),
    contract: new Contract(),
    period: new Period(),
    contractDetails: new ContractDetail(),

    partnerCode: null,
    // PROJECT
    isProject: false,
    projectCode: null,
    
}

export const subscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {
        sUpdateState: (state, action) => {
            // console.log("updateState subscription", action?.payload);
            const data = helpers.statelessUpdate(action?.payload, state);
            state = data
            return state
        },
        sRaz: (state) => {
            state = initialState;
            return state;
        }
    }
})

export const { sUpdateState, sRaz } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;