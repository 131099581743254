import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react"

export const CardHeaderError = (error)=> {
    return (
        <React.Fragment>
            <div style={{ height: 30, width: '100%', marginTop: -10, display: 'flex' }}>
                <h4 style={{ color: 'grey', marginRight: 3 }}>Packs</h4>
                <Tooltip placement="right" title={""} AutoAdjustOverflow={true}>
                    <InfoCircleOutlined style={{ color: '#0075C9', marginLeft: 10, marginTop: 7 }} />
                </Tooltip>
                {error ? (<div style={{ color: "red" }}> Corriger les données invalides saisies</div>) : null}
            </div>
        </React.Fragment>
    )
}

export default CardHeaderError;
