import { Col, Divider, Row, Tabs } from "antd";
import { avatar } from "assets/images";
import React, { useEffect, useState } from "react";
import SouscriptionActive from "./components/SouscriptionActive";
import SouscriptionExpired from "./components/SouscriptionExpired";
import SouscriptionResilie from "./components/SouscriptionResilie";
import { useHistory, useParams } from "react-router-dom";
import Assure from "api/model/assure.model";
import ContractService from "services/contractService";
import { Card } from "reactstrap";
import PageResponse from "api/response/pageResponse";

const DetailAssureSouscription=(props)=> {

    const { TabPane } = Tabs;
    const history = useHistory();
    const params = useParams();
    const [pageSize, setPageSize] = useState(100);

    const [state, setState] = useState({
        contracts: [],
        assure: new Assure(),
        totalPage: 0,
        page: 0,
        totalElements: 0,
        size: 10,
        isLoad: false
    })


    useEffect(() => {
        getAssureContrats();
    }, []);

    // Liste des soubscription
    const getSubscriptionContracts = (categoryRef, word, page) => {
        if (page == null || page === undefined) page = 0;
        if (word == null || word === undefined) word = null;

        const options = {
            query: {
                categoryRef: categoryRef ?? null,
                assureCode: params.assureCode,
                word: word,
                page: page
            }
        };

        setState(state => ({ ...state, isContractLoading: true }));
        ContractService.readSubscriptionContracts(options, (data) => {
            let _pageResponse = (new PageResponse()).fromJson(data);

            const contracts = _pageResponse.content || [];
            setState(state => ({
                ...state,
                initialData: contracts,
                tempContractData: contracts,
                page: _pageResponse.page,
                size: _pageResponse.size,
                totalElements: _pageResponse.totalElements,
                totalPages: _pageResponse.totalPages,
                isContractLoading: false
            }))
        }, (error) => {
            setState(state => ({ ...state, isContractLoading: false, contractLoadingError: true, contractLoadingErrorMessage: error }))
        });
    }

    const getAssureContrats=(size, page, word) =>{
        if (size == null || size == undefined) size = pageSize;
        if (page == null || page == undefined) page = 0;
        if (word == null || word == undefined) word = null;

        if (page == null || page === undefined) page = 0;
        if (word == null || word === undefined) word = null;

        let options = {
            query: {
                assureCode: params.assureCode
            }
        };

        ContractService.readAssureContracts(options, (data) => {
            console.log(":::: assure contracts", data)
            setState(state => ({
                ...state,
                contracts: data?.contracts || [],
                assure: Assure.fromJson(data?.assure),
                isLoad: true
            }))
        }, (error) => {

        });
    }

    return (
        <div>

            <Card>
                <Divider orientation="left">
                    <h4 style={{ color: '#006699', fontWeight: 'bold' }}>Informations personnelles</h4>
                </Divider>
            </Card>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Card
                    style={{
                        width: "30%",
                    }}
                >
                    <div style={{margin: 10}}>
                        <Row>
                            <Col md={24}>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 10}}>
                                        <img src={avatar} width={100} height={100} style={{ marginLeft: 20 }} alt="avatar" />
                                    </div>
                                    <div style={{width: '100%'}}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                            <h4 style={{ color: 'grey' }}>Code :</h4>
                                            <h4 style={{color: '#006699', fontWeight: 'bold' }}>{state?.assure?.code}</h4>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                            <h4 style={{ color: 'grey' }}>Nom :</h4>
                                            <h4 style={{ color: '#006699', fontWeight: 'bold' }}>{state?.assure?.firstName}</h4>
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                            <h4 style={{ color: 'grey' }}>Prenoms :</h4>
                                            <h4 style={{ color: '#006699', fontWeight: 'bold' }}>{state?.assure?.lastName}</h4>
                                        </div>
                                        
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col md={24}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h4 style={{ color: 'grey' }}>Nom :</h4>
                                    <h4 style={{ color: '#006699', fontWeight: 'bold' }}>{state?.assure?.firstName}</h4>
                                </div>
                            </Col>

                            <Col md={24}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h4 style={{ color: 'grey' }}>Prenoms :</h4>
                                    <h4 style={{ color: '#006699', fontWeight: 'bold' }}>{state?.assure?.lastName}</h4>
                                </div>
                            </Col> */}

                            <Col md={24}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h4 style={{ color: 'grey' }}>Contact(s) :</h4>
                                    <h4 style={{ color: '#006699', fontWeight: 'bold' }}>{state?.assure?.phone}</h4>
                                </div>
                            </Col>

                            <Col md={24}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h4 style={{ color: 'grey' }}>Email :</h4>
                                    <h4 style={{ color: '#006699', fontWeight: 'bold' }}>{state?.assure?.email}</h4>
                                </div>
                            </Col>

                            <Col md={24}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h4 style={{ color: 'grey' }}>Profession :</h4>
                                    <h4 style={{ color: '#006699', fontWeight: 'bold' }}>{state?.assure?.statusSocio}</h4>
                                </div>
                            </Col>

                            <Col md={24}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h4 style={{ color: 'grey' }}>Type de piece :</h4>
                                    <h4 style={{ color: '#006699', fontWeight: 'bold' }}>{state?.assure?.identityCardTitle === "" || state.assure.identityCardTitle === null ? "---" : state.assure.identityCardTitle}</h4>
                                </div>
                            </Col>

                            <Col md={24}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h4 style={{ color: 'grey' }}>Numero de piece :</h4>
                                    <h4 style={{ color: '#006699', fontWeight: 'bold' }}>{state?.assure?.identityCardNumber === "" || state?.assure?.identityCardNumber === null ? "---" : state?.assure?.identityCardNumber}</h4>
                                </div>
                            </Col>

                            <Col md={24}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h4 style={{ color: 'grey' }}>Créé le :</h4>
                                    <h4 style={{ color: '#006699', fontWeight: 'bold' }}>{new Date(state?.assure?.createdAt).toLocaleDateString()}</h4>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Card>

                <Card style={{
                        width: "68%",
                    }}>
                    <Tabs type="card">
                        <TabPane tab="Contrats Actifs" key="1">
                            <SouscriptionActive contracts={state.contracts} assure={state.assure} isLoad={state.isLoad} />
                        </TabPane>
                        <TabPane tab="Contrats Resiliés" key="2">
                            <SouscriptionResilie souscription={[]} isLoad={state.isLoad} />
                        </TabPane>
                        <TabPane tab="Contrats Expirés" key="3">
                            <SouscriptionExpired souscription={[]} isLoad={state.isLoad} />
                        </TabPane>
                    </Tabs>
                </Card>
            </div>

            <br /><br /><br />
        </div >
    )
}

export default DetailAssureSouscription;