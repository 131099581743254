import React from "react";
import { Card, Col, Row, Tag } from "antd";
import MarqueInfo from "./components/Marque";
import ReductionInfo from "./components/Reductions";
import PackageInfo from "./components/Package";
import { useParams } from "react-router-dom";


export default function Details(props) {

    const params = useParams();

    function screenRender() {
        switch (params.type) {
            case "marque":
                return <MarqueInfo />

            case "reduction":
                return <ReductionInfo />

            case "package":
                return <PackageInfo />

            default:
                return "Desolé une erreur est survenue !!"
        }
    }

    console.log('Params  ---> ', params);
    return (
        <div>
            <div>
                <h4 style={{ textAlign: "center" }}>Informations sur {params.type == "package" ? ("le " + params.type) : params.type == "marque" ? ("la " + params.type) : ("la " + params.type)}</h4>
            </div>


            <div>

                {screenRender()}
                {/* <PackageInfo /> */}
            </div>
        </div>
    )
}