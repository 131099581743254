import React, { useEffect, useRef, useState } from 'react';

import { useHistory, useLocation } from "react-router-dom";
import { Tree } from 'antd';
import makeStyles from '@material-ui/styles/makeStyles';

import useLayoutOneStyles from '../../styles'
import { logo } from 'assets/images'
import Title from 'antd/lib/typography/Title';

const useStyles = makeStyles(theme => ({
    sidebarRoot: {
        position: 'fixed',
        paddingLeft: 10,
        paddingRight: 10,
        width: 270,
        borderRight: '1px solid #f7eded',
        height: '100%',
        background: 'white',
        overflow: "auto"
    },
    sidebarTitleContainer: {
        position: 'sticky',
        top: 95,
        zIndex: 1,
        background: "white",
        paddingTop: 15,
        paddingBottom: 2,
    },
    sidebarTitle: {
        color: "white",
        textAlign: "center",
        fontWeight: 700,
        padding: 10,
        fontSize: 17,
        borderRadius: 5,
        boxShadow: "0px 2px 2px gray",
    },
    sidebarContent: {
        padding: 10,
        marginBottom: 100
    },
    logoContainer: {},
    logo: {
        marginBottom: 20,
        [theme.breakpoints.up('sm')]: {
            width: "100%"
        }
    },
}));

function getUrls(items) {
    let urls = [];

    for (let item of items) {
        if (item.children?.length > 0) {
            for (let sub_item of item.children) {
                urls = [...urls, sub_item.url]
            }
        } else {
            urls = [...urls, item.url]
        }
    }

    return urls
}

function Sidebar(props) {
    const { items, topBarItems, theme } = props
    const layoutOneClasses = useLayoutOneStyles();
    const classes = useStyles();

    const { pathname } = useLocation();
    const { DirectoryTree } = Tree;

    const [state, setState] = useState({
        selectedKeys: [],
        expandedKeys: []
    });

    const urls = useRef(getUrls(items || []));

    const history = useHistory()

    useEffect(() => {
        const data = (topBarItems || []).find(item => item.url === pathname);

        if (data) {
            setState(state => ({
                ...state,
                expandedKeys: [data.title],
                selectedKeys: [pathname]
            }));
        } else {
            let newSelectedItem;

            for (let item of items) {
                if (item.url === pathname) {
                    newSelectedItem = item;
                    break;
                } else {
                    if (item.children) {
                        for (let childItem of item.children) {
                            if (childItem.url === pathname) {
                                newSelectedItem = item;
                                break;
                            }
                        }
                    }
                }

                if (newSelectedItem) break;
            }

            setState(state => ({
                ...state,
                expandedKeys: [newSelectedItem?.title],
                selectedKeys: [pathname]
            }));
        }
    }, [pathname])

    function onSelect(data) {

        const selectedItem = data[0];

        if (urls.current.includes(selectedItem)) {
            setState(state => ({
                ...state,
                selectedKeys: data
            }));

            history.push(selectedItem);
        } else {

            const newSelectedItem = (topBarItems || []).find(item => item.title == selectedItem)

            if (newSelectedItem) {
                setState(state => ({
                    ...state,
                    expandedKeys: data,
                    selectedKeys: [newSelectedItem.url]
                }));

                history.push(newSelectedItem.url);
            }
        }
    }

    function onExpand(data) {
        setState(state => ({
            ...state,
            expandedKeys: data,
        }));
    }

    return (
        <div className={classes.sidebarRoot}>

            <div className={layoutOneClasses.topSpacerHeight} />

            <div className={classes.logoContainer} style={{textAlign: 'center'}}>
                <img
                    alt=''
                    className={classes.logo}
                    height='auto'
                    width='auto'
                    src={logo}
                />
                {/* <Title type='primary' style={{color: "rgb(0, 117, 201)"}}>Radis</Title> */}
            </div>

            <div className={classes.sidebarTitleContainer}>
                <div className={classes.sidebarTitle} style={{background: theme.sidebar.titleBackgroundColor}}>
                    MENU
                </div>
            </div>

            <DirectoryTree
                className={classes.sidebarContent}
                treeData={items}
                selectedKeys={state.selectedKeys}
                expandedKeys={state.expandedKeys}
                onExpand={onExpand}
                onSelect={onSelect}
                showIcon={false}
            />
        </div>
    );
}

export default Sidebar