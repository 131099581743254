export default class Merchant {
    id;
    code;
    firstName;
    lastName;
    phone;
    email;

    static fromJson = (json={})=> {
        var assure = new Merchant();
        assure = json;
        return assure
    }
}