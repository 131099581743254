export default class CreatePartnerRequest {
    code;
    title;
    description;
    address;
    email;
    phone;
    natureCode;
    contributorRef;

    fromJson = (json={})=> {
        var createPartnerRequest = new CreatePartnerRequest();
        createPartnerRequest = json;
        return createPartnerRequest;
    }
}