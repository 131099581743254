import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';

import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import store from 'redux/store';
import { ThemeProvider } from '@material-ui/styles';

import 'assets/styles.less';

import materialUiTheme from 'assets/themes/materialUiTheme';
import 'assets/themes/antdTheme.less';
import 'services/extensions';
import Starter from 'Starter';

const browserHistory = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={frFR}>

      <ThemeProvider theme={materialUiTheme}>
        <Router history={browserHistory}>
          <Starter />
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
