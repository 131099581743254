export default class CreateModelRequest {
    code;
    title;
    description;
    // brandRef;

    static fromJson = (json = {}) => {
        var createModelRequest = new CreateModelRequest();
        createModelRequest = json;
        return createModelRequest;
    }
}