import { Col, Form, Select, Spin } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CityService from "services/cityService";

const CityField=({categoryCode, disable, handlerFormField, xs, md})=> {

    const { Option } = Select;

    const [state, setState]= useState({
        // CITY
        cities: [],
        isCityLoading: true,
    })

    useEffect(()=> {
        getCities()
    }, [])

    const getCities = () => {

        (new CityService()).getAll((data) => {
            setState(state => ({ ...state, cities: data, isCityLoading: false }))
        }, (error) => {
            //setState(state=> ({...state, errorBag: {error: true, errorMessage: errorInfo} })); 
        })
    }

    const onCitySelected = (cityRef) => {
        const citySelected = state.cities.find((city) => city.ref === cityRef);
        if (citySelected) {
            handlerFormField({
                cityRef: citySelected?.ref,
                cityTitle: citySelected?.title
            })
        }
    }

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                    <Form.Item
                        label="Zone de circulation du vehicule"
                        name="cityRef"
                        rules={[{ required: true, message: "Champ obligatoire" }]}
                    >
                        {
                            state.isCityLoading ? <Spin /> :
                                (
                                    <SelectWithFilter placeholder='Choisissez la zone' onChange={(e) => onCitySelected(e)} disabled={disable}>
                                        {state.cities.map((city, index) => (<Option value={city?.ref} key={index}>{city?.title}</Option>))}
                                    </SelectWithFilter>
                                )
                        }

                    </Form.Item>
                </Col>
        </React.Fragment>
    )
}

export default CityField;