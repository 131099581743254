export default class FormEditUser {
    name;
    username;
    phone;
    email;
    password;
    entitiesCode;
    entitiesTitle;
    profileRef;
    profileTitle;
    fromJson = (json = {}) => {
        var formEditUser = new FormEditUser();
        formEditUser = json
        return formEditUser
    }

}