export default class Brand {
    ref;
    code;
    title;
    description;

    static fromJson = (json={})=> {
        var brand = new Brand();
        brand = json;
        return brand
    }
 }