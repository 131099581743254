import React, { useEffect, useState } from 'react'

import { Row, Col, Card, Form} from 'antd'

import CustomAntdForm from 'modules/components/Forms/CustomAntdForm';
import { FileDoneOutlined, LeftOutlined } from '@ant-design/icons';
import { thousandSeparator } from 'helpers/helpers';
import { VehicleCardHeader2 } from 'views/pages/subscription/common/VehicleCardHeader2';
import { AssureCodeField, AssureFirstNameField, AssureLastNameField, BrandField, CardIDField, CardNumberField, ChassisNumberField, CustomerBrandField, CustomerModelField, DateEffectField, EmailField, MatriculationField, ModelField, PhoneField } from 'views/pages/subscription/common/fields';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';
import BackNextWidget from 'views/pages/subscription/flotte/common/BackNextWidget';
import AmountHeader from 'views/pages/subscription/common/AmounHeader';

const StepAssureContract = ({basket}) => {
    const {updateFormField, goToPreviousStep, goToNextStep, updateSusbscriptionRequest} = useBasket()
    const [form] = Form.useForm();
    const [matriculationExist, setMatriculationExist] = useState(true)
    const [customerBrand, setCustomerBrand] = useState(false);

    useEffect(()=> {
        if(basket?.formField?.modelRef == null && basket?.formField?.modelTitle) {
            setCustomerBrand(true)
        }
    }, [])

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                console.log("Values :", values);
                // ========== CARTE GRISE ===========
                /* let formData = new FormData();
                formData.append('file', (values.carteGrise));
                values.file = formData; */
                // ==================================
                updateFormField({...values});
                updateSusbscriptionRequest({isProject: false, projectCode: "", isProrate: false})
                goToNextStep(null)
            })
            .catch(errorInfo => {
                console.log("errorInfo", errorInfo);
            });
    }

    const cardHeader1 = (
        <div style={{ height: 30, width: '100%', marginTop: -10, display: 'flex' }}>
            <h4 style={{ color: 'grey' }}>Montant a payer</h4>
            <div style={{ display: 'flex' }}>
                <div style={{ height: 20, width: 120, backgroundColor: '#1DCC70', fontSize: 17, color: 'white', marginLeft: 10, marginTop: 5 }}>
                    <h4 style={{ marginTop: -3, color: '#ffffff', textAlign: 'center' }}>{thousandSeparator(basket?.packSelected?.primeTTC)} FCFA</h4>
                </div>

                {/* <Tooltip placement="right" title={info1}>
                    <InfoCircleOutlined style={{ color: '#0075C9', marginLeft: 10, marginTop: 7 }} />
                </Tooltip> */}

            </div>
        </div>
    );

    return (
        <React.Fragment>
            <AmountHeader packSelected={basket?.packSelected}/>
            <CustomAntdForm form={form} initialValues={basket?.formField}>
                
                <Card title={VehicleCardHeader2} headStyle={{ backgroundColor: '#e9e9e9', color: '#ffffff', height: 20 }}>
                    {/* <VehicleSecondOtherInfo updateFormFields={updateFormFields} formField={state.formField} /> */}
                    <Row gutter={[8, 8]}>
                        {!customerBrand ? 
                            (<BrandField
                                disable={true}
                                handlerFormField={updateFormField} 
                                setCustomerBrand={setCustomerBrand}/>): 
                                <CustomerBrandField
                                    disable={true} 
                                    handlerFormField={updateFormField} 
                                    setCustomerBrand={setCustomerBrand}/>
                                }

                        {!customerBrand ? 
                                (<ModelField 
                                    disable={true}
                                    brandRef={basket?.formField?.brandRef} 
                                    handlerFormField={updateFormField}/>):
                                <CustomerModelField disable={true} />}

                        <MatriculationField 
                            handlerFormField={updateFormField}
                            disable={true} 
                            setMatriculationExist={setMatriculationExist}
                            />

                        <ChassisNumberField disable={true} />
                    </Row>
                </Card>
                <Card title={<div style={{ height: 30, width: '100%', marginTop: -10 }}>
                                <h4 style={{ color: 'grey' }}>Détails sur votre contrat</h4>
                            </div>} 
                            headStyle={{ backgroundColor: '#e9e9e9', color: '#ffffff', height: 20, boxShadow: "0px 0.5px 0.5px gray", }}>
                    <Row gutter={[8, 8]}>
                        <Col className="gutter-row" xs={12} md={6}>
                            <DateEffectField disable={false} />
                        </Col>
                    </Row>
                </Card>

                <Card title={<div style={{ height: 30, width: '100%', marginTop: -10, display: "flex" }}>
                                <h4 style={{ color: 'grey' }}>Informations sur l'assuré </h4>
                            </div>} 
                    headStyle={{ backgroundColor: '#e9e9e9', color: '#ffffff', height: 15 }}>
                   
                    <Row gutter={[8, 8]}>
                        <Col className="gutter-row" xs={12} md={6}>
                            <AssureFirstNameField disable={matriculationExist} />
                        </Col>

                        <Col className="gutter-row" xs={12} md={6}>
                            <AssureLastNameField disable={matriculationExist} />
                        </Col>

                        <Col className="gutter-row" xs={12} md={6}>
                           <PhoneField disable={matriculationExist} />
                        </Col>

                        <Col className="gutter-row" xs={12} md={6} style={{ visibility: "hidden" }}>
                            <AssureCodeField disable={matriculationExist}/>
                        </Col>
                        <Col className="gutter-row" xs={12} md={6}>
                            <EmailField disable={matriculationExist}/>
                        </Col>

                        <Col className="gutter-row" xs={12} md={6}>
                            <CardIDField handlerFormField={updateFormField} disable={matriculationExist} />
                        </Col>

                        <Col className="gutter-row" xs={12} md={6}>
                            <CardNumberField disable={matriculationExist} />
                        </Col>
                    </Row>
                    <h5 style={{ color: '#a9abad', marginTop: -15 }}>Entrez ci-dessous l'adresse mail à laquelle le client recevra les detatils des notifications sur votre contrat</h5>
                </Card>
                <BackNextWidget 
                    confirmText={'PASSER AU DETAILS DU CONTRAT'}
                    onConfirm={handleSubmit}
                    confirmIcon={(<FileDoneOutlined style={{ color: '#ffffff', fontSize: 20 }} />)}
                    onCancel={()=> goToPreviousStep(null)}
                    cancelIcon={(<LeftOutlined style={{ color: '#005680' }} /> )}
                />  

                

            </CustomAntdForm>
        </React.Fragment>
    )
}

export default StepAssureContract;
