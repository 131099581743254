import { EditOutlined, FileAddOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, notification, Row, Select, Spin, Switch, Table, Tag } from "antd";
import { TEST_API } from "constantes";
import React, { useEffect, useState } from "react";
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";

export default function MarqueInfo(props) {

    const Option = Select;
    const [form] = Form.useForm();

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg
        });
    };


    const [state, setState] = useState({
        listModele: [],
        modeleLoad: false,
        loading: false,
        updateLine: {},
        isLoad: false,
        itemId: null,
    });

    useEffect(() => {
        getAllModele();
    }, []);

    const [list, setList] = useState([])
    const [visible, setVisible] = useState(false);
    const [update, setUpdate] = useState(false);

    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Titre',
            dataIndex: 'libelle',
            key: 'libelle'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: "Statut",
            dataIndex: "statut",
            key: "statut",
            render: (item, data) => (
                <div>
                    <Switch defaultChecked={data.statut} onChange={() => onChange(data)} size="small" />
                </div>
            )
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (item, data) => (
                <>
                    <EditOutlined style={{ color: '#40E070', cursor: 'pointer' }} onClick={() => { updateModalVisible(true); setState(state => ({ ...state, updateLine: data })) }} />
                </>

            )
        }
    ];

    function getAllModele() {
        fetch(`http://localhost:3003/modele`,
            {
                method: 'get',
                headers: { 'Content-Type': 'application/json' }
            }
        ).then(response => {
            return response.json();
        })
            .then(data => {
                console.log('get all modele ', data);
                setState(state => ({ ...state, listModele: data, modeleLoad: true }));
                setList(data);
            })
            .catch(error => {
                console.log('get all modele error -------------> ', error);
            })

    };


    function onFinish(value) {
        setState(state => ({ ...state, loading: true }));
        console.log('typing values --> ', value);
        var config = {
            method: 'post',
            url: `${TEST_API}modele`,
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                // "Authorization": `Bearer ${token}`
            },
            data: JSON.stringify(value)
        };
        axios(config)
            .then(function (response) {
                console.log('response list des marques', response);
                setState(state => ({ ...state, loading: false }));
                openNotificationWithIcon('success', 'marque ajoutée avec succès !');
                getAllModele();
                setVisible(false);
            })
            .catch(function (error) {
                console.log(error);
                openNotificationWithIcon('error', "Erreur lors de l'enregistrement !");
            });
    }

    function onUpdate(value) {
        setState(state => ({ ...state, loading: true }));
        value.id = state.updateLine.id;
        console.log('updating data --> ', value);
        var config = {
            method: 'put',
            url: `${TEST_API}modele/${state.updateLine.id}`,
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                // "Authorization": `Bearer ${token}`
            },
            data: JSON.stringify(value)
        };
        axios(config)
            .then(function (response) {
                console.log('response update des modele', response);
                setState(state => ({ ...state, loading: false }));
                getAllModele();
                openNotificationWithIcon('success', 'Mise à jour effectuée avec succès !');
                setUpdate(false);
            })
            .catch(function (error) {
                console.log(error);
                openNotificationWithIcon('error', "Erreur lors de la mise à jour !");
            });
    }

    function addModalVisible(value) {
        form.setFieldsValue({
            code: "",
            libelle: "",
        });
        setVisible(value);
    }

    function updateModalVisible(value) {
        if (value) {
            setUpdate(value);
            setTimeout(() => {
                setState(state => ({ ...state, isLoad: true }))
            }, 1);
        }
        else {
            setUpdate(value);
            setState(state => ({ ...state, isLoad: false }))
        }

    }

    function onSearch(e) {
        let value = e.target.value;
        console.log('typing values --> ', value);
        let table = [];
        let index = 0;
        let data = list;

        for (let i = 0; i < data.length; i++) {
            if (
                data[i].code.toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1 ||
                data[i].libelle.toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1
            ) {
                table[index] = data[i];
                index++;
            }
        }
        setState(state => ({ ...state, listModele: table }))
        console.log('filtered list ---> ', table);
    }

    function onChange(data) {
        data.statut = !data.statut;
        console.log('data ---> ', data);
        fetch(`http://localhost:3003/modele/${data.id}`,
            {
                method: 'put',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            }
        ).then(response => {
            return response.json();
        })
            .then(data => {
                console.log('update statut modele ', data);
                openNotificationWithIcon('success', "Statut modifié avec succes !")
                getAllModele();
            })
            .catch(error => {
                console.log('update statut modele error -------------> ', error);
            })
    }
    return (
        <div>
            <div style={{
                boxShadow: "3px 5px 5px rgba(220, 224, 232,1)",
                width: '80%',
                border: '1px solid rgba(233, 236, 242,0.5)',
                borderRadius: 5,
                margin: '0 auto',
                padding: 20
            }}>

                <Row>
                    <Col md={8}>
                        <Row>
                            <Col md={24}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h4 style={{ color: 'grey' }}>Titre :</h4>
                                    <h3 style={{ color: '#006699', fontWeight: 'bold' }}>AUTO</h3>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={24}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%' }}>
                                    <h4 style={{ color: 'grey' }}>Statut :</h4>
                                    <Tag color="green">Activé</Tag>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={16}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                            <h4 style={{ color: 'grey', marginRight: 10 }}>Description :</h4>
                            <p style={{ color: '#006699', fontWeight: 'bold', }}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing
                                elit, sed do eiusmod tempor incididunt ut labore et
                                dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat
                            </p>
                        </div>
                    </Col>
                </Row>
            </div><br /><br />

            <div style={{ width: '80%', margin: '0 auto' }}>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Input placeholder="Recherche" style={{ width: 200, marginRight: 10 }} onChange={onSearch} />
                    <Button type="primary" onClick={() => addModalVisible(true)}>
                        <FileAddOutlined />
                        Ajouter un modele
                    </Button>
                </div><br />
                {state.modeleLoad ? (
                    <Table dataSource={state.listModele} columns={columns} />
                ) : (<Spin />)}

            </div>


            {
                visible && (
                    <Drawer
                        title="Enregistrement d'un modele"
                        width={720}
                        onClose={() => addModalVisible(false)}
                        visible={visible}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                    >
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            layout="vertical"
                            form={form}
                        >
                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Code"
                                        name="code"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Entrez le code du modele svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Entrez le code code du modele" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Titre"
                                        name="libelle"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Le titre du modele svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Titre du modele" />
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Description"
                                        name="description"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Description du modele svp !',
                                            },
                                        ]}
                                    >
                                        <TextArea placeholder="Description du modele" />
                                    </Form.Item>
                                </Col>

                            </Row>

                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e8e8e8',
                                    padding: '10px 16px',
                                    textAlign: 'right',
                                    left: 0,
                                    background: '#fff',
                                    borderRadius: '0 0 4px 4px',
                                }}
                            >
                                <Button
                                    style={{
                                        marginRight: 8,
                                    }}
                                    onClick={() => addModalVisible(false)}
                                >
                                    Annuler
                                </Button>
                                <Button type="primary" htmlType="submit" loading={state.loading}>
                                    Enregistrer
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                )
            }

            {
                update && (
                    <Drawer
                        title="Modification d'un partenaire"
                        width={720}
                        onClose={() => updateModalVisible(false)}
                        visible={update}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                    >
                        {state.isLoad ? (
                            <Form
                                name="update"
                                onFinish={onUpdate}
                                layout="vertical"
                            // form={form}
                            >
                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Code"
                                            name="code"
                                            initialValue={state.updateLine.code}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Entrez le code du modele svp !',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Entrez le code code du modele" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Titre"
                                            name="libelle"
                                            initialValue={state.updateLine.libelle}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Le titre du modele svp !',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Titre du modele" />
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Description"
                                            name="description"
                                            initialValue={state.updateLine.description}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Description du modele svp !',
                                                },
                                            ]}
                                        >
                                            <TextArea placeholder="Description du modele" />
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        width: '100%',
                                        borderTop: '1px solid #e8e8e8',
                                        padding: '10px 16px',
                                        textAlign: 'right',
                                        left: 0,
                                        background: '#fff',
                                        borderRadius: '0 0 4px 4px',
                                    }}
                                >
                                    <Button
                                        style={{
                                            marginRight: 8,
                                        }}
                                        onClick={() => updateModalVisible(false)}
                                    >
                                        Annuler
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={state.loading}>
                                        Modifier
                                    </Button>
                                </div>
                            </Form>
                        ) : (<Spin />)}
                    </Drawer>
                )
            }
        </div >
    )
}