import { Button, Form, Input, Space, Spin } from "antd";
import React, { useState } from "react";
import VehicleService from "services/vehicleService";

const TrailerMatriculationField2=({listMats, currentId, onConfirm, defaultValue, label="", status='error', width=350})=> {
    const [input, setInput]=useState(defaultValue)
    const [inputStatus, setInputStatus] = useState(status)
    const [matriculeExist, setMatriculeExist] = useState({
        exist: false,
        errorMessage: '',
        value: "",
        isChecking: false
    });

    const checkMatriculation=() => {
       
        //let input = e?.target?.value;
        if(input == null || input === undefined || input==="") {
            setInputStatus('error')
            return;
        }
        console.log(":::: checkMat")

        const _matricule = (listMats || []).find((mat)=> mat === input);
        setMatriculeExist(matriculeExist=> ({...matriculeExist, exist: false, errorMessage: '', isChecking: true}));
        if(_matricule) {
            setInputStatus('error')
            setMatriculeExist(matriculeExist=> ({...matriculeExist, exist: true, errorMessage: "Le matricule existe déjà", isChecking: false}));
            return;
        }
        
        VehicleService.verifyMatriculation({matriculation: input, isTrailer: true}, (data) => {
            setMatriculeExist(matriculeExist=> ({...matriculeExist, exist: false, errorMessage: '', value: input, isChecking: false}));
            if(onConfirm) {
                onConfirm(currentId, input);
            } 
            
        }, (error) => {
            setInputStatus('error')
            setMatriculeExist(matriculeExist=> ({...matriculeExist, exist: true, errorMessage: error, isChecking: false}));
        })
    }

    const validate=()=> {
        checkMatriculation()
    }

    const onChange=(e)=> {
        setInput(e?.target?.value)
    }

    return(
        <React.Fragment>
            <Form.Item
                label={label}
                name="matricule"
                rules={[{ required: false, message: "Champ obligatoire" }]}
                style={{marginBottom:0}}
            >
                <Space direction="vertical">
                    <Space direction="horizontal">
                        <Space.Compact>
                            <Input 
                                defaultValue={defaultValue} 
                                allowClear 
                                onChange={onChange}
                                suffix={matriculeExist?.isChecking ? (<Space><div>Verification...</div><Spin size='small' /></Space>) : null}
                                status={matriculeExist?.exist || inputStatus ? inputStatus : ''}
                                style={{ width: width }}
                                placeholder="Numero d'Immatriculation de la remorque"
                                />
                            <Button type="primary" onClick={()=> validate()}><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i></Button>
                        </Space.Compact>
                    </Space>
                    {matriculeExist?.exist ? (<span style={{ color: 'red', fontSize:11 }}>{matriculeExist.errorMessage}</span>) : null}
                </Space>
                {/* <div className="input-group">
                    <Input
                        type="text"
                        className="form-control bg-light border-1 is-invalid"
                        placeholder="Numero d'Immatriculation du vehicule"
                        // onChange={checkMatriculation}
                        onChange={onChange}
                        
                    />
                    <button className="btn btn-primary" type="button" id="button-addon2" onClick={()=> validate()}><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{" "}</button>
                </div>
                
                {matriculeExist?.exist ? (<span style={{ color: 'red', fontSize:11 }}>{matriculeExist.errorMessage}</span>) : null}
                 */}
            </Form.Item>
             
        </React.Fragment>
    )
}

export default TrailerMatriculationField2;