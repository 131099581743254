import React from 'react';
import { FStepChangementImmatriculation, FStepDetails, FStepThankYou } from './steps';
import useInitFlotteAvenant from '../useInitFlotteAvenant';
import ErrorWidget from 'components/Widgets/ErrorWidget';
import { Card, Spin } from 'antd';
import WizardHeader from 'modules/components/WizardHeader';
import { useSelector } from 'react-redux';
import * as helpers from 'helpers/helpers';

function FAvenantChangementImmatriculation() {
    const {avenant, category,contract, partnerCode, projectCode} = useSelector((state) => state.subscription);
    const {currentStep, baskets, page, fSubscriptionRequest, quotationResponse, transactionResponse, initAvenantResponse} =  useSelector((state) => state.basketSlice);
    const { state } = useInitFlotteAvenant();
    
    if(state.avenantStatus?.loading) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if(state.avenantStatus?.error) {
        return <ErrorWidget errorMessage={state.avenantStatus?.errorMessage} />;
    }

    function renderChildren() {
        switch (currentStep) {
            case 0:
                return <FStepChangementImmatriculation 
                            baskets={baskets}
                            fSubscriptionRequest={fSubscriptionRequest}
                            startDate={initAvenantResponse?.startDate}
                            endDate={initAvenantResponse?.endDate}
                            />

            case 1:
                return (
                    <FStepDetails
                        avenantCode={avenant?.code}
                        endDate={initAvenantResponse?.endDate}
                        initAvenantResponse={initAvenantResponse}
                        quotationResponse={quotationResponse} 
                        fSubscriptionRequest={fSubscriptionRequest}
                        baskets={baskets}
                    />
                );

            case 2:
                if(page === helpers.FlotteWidget.THANK_YOU) {
                    return <FStepThankYou transactionResponse={transactionResponse} />
                }
                break;

            default:
                return <ErrorWidget errorMessage="Ooops la page n'existe pas" />;
        }
    }

    return (
        <React.Fragment>
            <WizardHeader
                title={`Categorie ${category?.code} : ${category?.title}`}
                avenantTilte={`Avenant de changement d'immatriculation`}
                partnerCode={partnerCode}
                police={contract?.policeNumber}
                //onClick={(state.currentStep > 0 && state.currentStep < 4) ? goToPreviousStep : null}
                percent={currentStep * 33}
            />
            {renderChildren()}
        </React.Fragment> 
    )
}

export default FAvenantChangementImmatriculation;