import React from 'react';

import SweetAlert from 'react-bootstrap-sweetalert'
import { Button } from 'antd';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import io from "socket.io-client";

import { getSideBarItems, getTopBarItems, getTabs } from 'modules/components/layouts/config';
import Routes from 'routes';
import Layout from 'components/Layout';
import { appModules} from 'appModule';
import { useDispatch, useSelector } from 'react-redux';
import { hideApiResponse } from 'redux/slices/apiResponseSlice';
import { SocketDispatch } from 'context';

// const socket = io.connect('/');

//import Scss
import './assets/scss/themes.scss';
import HeaderDashboard from 'views/pages/common/HeaderDashboard';
import { AppRoutes } from 'routes.module';

function App({ userActions }) {

  const { apiResponse } = useSelector(state => state);
  const dispatch = useDispatch();

  const filteredActions = appModules.filter(item => (
    userActions.includes(item?.name)
  ));
  const tabItems = getTabs(filteredActions);
  const topBarItems = getTopBarItems(filteredActions);
  const sideBarItems = getSideBarItems(filteredActions);


  /* const activatedRoutes = AppRoutes.map(route => {
    if(route?.visibility && userActions.includes(route?.name)) {
      const children = route?.children.filter(child => userActions.includes(child?.name) && child?.visibility);
      return {...route, children: [...children]}
    }
  });

  const topBarItems = getTopBarItems2(activatedRoutes); */

  function onCancel() {
    dispatch(hideApiResponse());
  }

  return (
    // SocketDispatch.Provider
    <React.Fragment > 
      <Layout
        sideBarItems={sideBarItems}
        topBarItems={topBarItems}
        tabItems={tabItems}
        widgets={<HeaderDashboard />}
        routes={<Routes tabItems={tabItems} />}
        /* sideBarItems={activatedRoutes}
        topBarItems={topBarItems}
        tabItems={activatedRoutes}
        widgets={<HeaderDashboard />}
        routes={<Routes appRoutes={activatedRoutes} />} */
      />

      {apiResponse.message && (
        <SweetAlert
          {...apiResponse.status}
          title='SANLAM BENIN'
          onCancel={onCancel}
          customButtons={
            <Button type="primary" size="large" onClick={onCancel}>OK</Button>
          }
        >
          {apiResponse.message}
        </SweetAlert>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
}

export default App
