import Assure from "api/model/assure.model";
import Merchant from "api/model/merchant.model";
import Period from "api/model/period.model";
import Vehicle from "api/model/vehicle.model";

export default class SubscriptionRequest {
    calculateGuarantee= true;
    offering;
    contractRef; // Utile lorsqu'on choisit un avenant autre que avenant de création
    productRef;
    productTitle;

    avenantRef;
    avenantTitle;
    
    categoryRef;
    categoryTitle;

    packLineRef;

    period = new Period();
    periodRef;
    periodTitle;
    customerPeriod = false;
    customerStartDate;
    customerEndDate;

    vehicle = new Vehicle();

    driverBirthDay;
    driverLicdeliveryDate

    customGuaranties = [];
    reductionGroups = [];
    customAssistances = [];
    
    dateEffect;

    assure = new Assure();
    merchant = new Merchant();
    
    primeTTC;
    primeNette;
    primeExtra;
    agencyFees = 0;

    capitalRef;
    paymentProviderRef;
    promoCode = "";
    canal = "BO";
    isProject = false;
    projectCode = "";

    partnerCode= "";

    static fromJson = (json = {}) => {
        var subscriptionRequest = new SubscriptionRequest();
        subscriptionRequest = json;
        return subscriptionRequest
    }

}