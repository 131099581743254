import React from 'react';
import { Alert, Button, Image, Space } from "antd";
import { illustrator_warning } from "assets/images";

export default function ErrorWidget({errorMessage, goBack, image}) {
    
    return (
        <div style={{display:'flex', alignItems: "center", flexDirection: "column"}}>
            
            <div>
                <img width={200} src={image ?? illustrator_warning} alt="warning illustrator" />
            </div><br/>

            <div>
            <Alert
                message={`Oops!!! ${errorMessage}`}
                type="warning"
                action={ goBack ?
                    (<Space>
                        <Button size="small" type="ghost" danger onClick={goBack} >Retour</Button>
                    </Space>) : null
                }
                />
            </div>
        </div>
    )
}