import {  Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";

const MotifField=({disable})=> {
    return (
        <React.Fragment>
            <Form.Item
                label=""
                name="motif"
                rules={[{ required: true, message: "Champ obligatoire" }]}
            >
                <TextArea size='medium' placeholder="Saisir le motid d'annulation" disabled={disable} rows={4} />
            </Form.Item>
        </React.Fragment>
    )
}

export default MotifField;