import { Form, Select } from "antd"
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter"
import React,  { useState } from "react"
import { useEffect } from "react"
import AreaService from "services/areaService"

const AreaField=({areas})=> {
    const Option = Select;
    const [state, setState]=useState({
        areas: areas ?? [],
        isAreasLoading: false
    })

    useEffect(()=> {
        if(!areas) {
            getAreas()
        }
        
    }, [])

    const getAreas=()=> {
        setState(state=> ({...state, isAreasLoading: true}))
        AreaService.getAll({}, (data)=> {
            const _areas = data || [];
            setState(state=> ({...state, areas: _areas, isAreasLoading: false}))
        }, (error)=> {
            setState(state=> ({...state, isAreasLoading: false}))
        })
    }

    return (
        <React.Fragment>
            <Form.Item
                label="Zone de circ."
                name="areaRef"
                rules={[{ required: false, message: "Champ obligatoire" }]}
            >
                <SelectWithFilter size='medium' placeholder="Selectionner" addonBefore={"Zone de circ. :"}>
                    {state.areas.map((area, index)=> (<Option value={area?.ref} key={index}>{area?.title}</Option>))}
                </SelectWithFilter>
            </Form.Item>
        </React.Fragment>
    )
}

export default AreaField;