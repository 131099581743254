import React from 'react';
import { 
    Card
 } from 'reactstrap';
import WizardHeader from 'modules/components/WizardHeader';
import {   FStepDetails,  FStepListVehicle, FStepThankYou } from './steps';
import {  useSelector } from 'react-redux';
import useInitFlotteAvenant from '../useInitFlotteAvenant';
import { useBasket } from '../../../hooks/useBasket';
import * as helpers from 'helpers/helpers';
import ErrorWidget from 'components/Widgets/ErrorWidget';
import { Spin } from 'antd';

const FAvenantIncorporation = ()=> {
    
    const { avenant, contract, category, partnerCode, projectCode, isProject } = useSelector((state) => state.subscription);

    const { currentStep, page, basket, baskets, fSubscriptionRequest, quotationResponse, transactionResponse, initAvenantResponse } = useSelector((state) => state.basketSlice);

    const {state: iniAvenant } = useInitFlotteAvenant();
    const { goToPreviousStep} = useBasket();

    if (iniAvenant?.avenantStatus?.loading) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if (iniAvenant?.avenantStatus?.error) {
        return <ErrorWidget errorMessage={iniAvenant?.avenantStatus?.errorMessage} />;
    }

    if(page === helpers.FlotteWidget.THANK_YOU) {
        return <FStepThankYou transactionResponse={transactionResponse} />
    }

    const renderChildren = ()=> {
        switch(currentStep) {
            case 0:
                return <FStepListVehicle 
                            baskets={baskets}
                            basket={basket}
                            startDate={initAvenantResponse?.startDate}
                            endDate={initAvenantResponse?.endDate}
                            initAvenantResponse={initAvenantResponse}
                            isProject={isProject}/>

            case 1:
                return <FStepDetails
                            avenantCode={avenant?.code}
                            endDate={initAvenantResponse?.endDate}
                            quotationResponse={quotationResponse} 
                            fSubscriptionRequest={fSubscriptionRequest}
                            goBack={goToPreviousStep}/>
            
            default:

        }
    }

    return (
        <React.Fragment>
            <WizardHeader
                title={`Categorie ${category.code} : ${category.title}`}
                avenantTilte={`Avenant d'incorporation`}
                partnerCode={partnerCode}
                police={contract?.policeNumber ?? projectCode}  
                //onClick={(state.currentStep > 0 && state.currentStep < 4) ? goToPreviousStep : null}
                percent={currentStep * 25}
            />
            {renderChildren()}
        </React.Fragment>
    );
}

export default FAvenantIncorporation;