import * as constants from 'constantes';
import Model from "api/model/model.model";
import Brand from "api/model/brand.model";
import Vehicle from "api/model/vehicle.model";
import Transport from "api/model/transport.model";
import City from "api/model/city.model";
import Assure from "api/model/assure.model";

export default class FSubscriptionRequestBuilder {
    
    /*static buildFlotte({ formFields, avenant, assure, contract, category, pack, autos, isProject, projectCode, state }) {
       
        const newFormFields = (new FormField(formFields));
        const newAssure = assure;

        return FSubscriptionRequest.fromJson({
            offering: "CLASSIC",
            avenantRef: avenant.ref,
            avenantTitle: avenant.title,
            productRef: constants.PRODUCT_AUTO_REF,
            categoryRef: category?.ref,
            productTitle: "AUTO",

            periodRef: state.period.ref,
            periodTitle: state.period.title,
            // CUSTOMER PERIOD
            customerPeriod: newFormFields?.customerPeriod,
            customerStartDate: newFormFields?.customerStartDate,
            customerEndDate: newFormFields?.customerEndDate,

            driverBirthDay: null,
            driverLicDeliveryDate: null,

            cGuaranties: [],
            reductionGroups: [],

            contractRef: contract?.ref, // Utile lorsqu'on choisit un avenant autre que avenant de création
            dateEffect: state?.flotteBasket[0]?.quotationRequest?.customerStartDate,
            // dateEffect: convert(newFormFields.startDate),

            capitalRef: '',
            assure: newAssure,

            primeTTC: pack?.primeTTC,
            primeNette: pack?.primeNette,
            primeExtra: pack?.primeExtra,
            flotteRate: parseFloat(pack?.flotteRate) * 100,
            agencyFees: 0,

            paymentProviderRef: constants.DEFAULT_PAYEMENT_PROVIDER,

            promoCode: "",
            canal: "BO",
            isProject: isProject,
            projectCode: projectCode,

            autos: autos,
        })

    } */


    static fromFleetBaskets({ category, contract, avenant, assure, baskets, basket, quotationResponse,partnerCode, isProject, projectCode, isProrate }) {
        
        const autos = [];
        
        for (const basket of baskets) {
            
            const { formField, packSelected, quotationRequest } = basket;
            console.log(":::: From Fleet Baskets packSelected :", {...packSelected})

            const transport = Transport.fromJson({
                ref: formField?.transportRef,
                title: formField?.transportTitle
            });

            const model = Model.fromJson({
                ref: formField?.modelRef,
                title: formField?.modelTitle,
                brand: Brand.fromJson({
                    ref : formField?.brandRef,
                    title: formField?.brandTitle
                })
            });

            const trailerModel=Model.fromJson({
                ref: formField?.trailerModelRef,
                title: formField?.trailerModelTitle,
                brand: Brand.fromJson({
                    ref: formField?.trailerBrandRef,
                    title: formField?.trailerBrandTitle
                })
            })

            const _vehicle = Vehicle.fromJson({
                ref: formField?.vehicleRef,
                transport: transport,
                model: model,
                energy: Model.fromJson({ ref: formField?.energyRef, title: formField?.energyTitle }),
                city: City.fromJson({ ref: formField?.cityRef, title: formField?.cityTitle }),
                totalPlace: formField?.nbPlace,
                fiscalPower: formField?.fiscalPower,
                dateMec: formField?.dateMec,
                hasGroup: formField?.hasGroup,
                groupRef: formField?.hasGroup ? formField?.groupementRef : '',
                groupName: formField?.hasGroup ? formField?.groupementTitle : '',
                groupType: formField?.hasGroup ? formField?.groupementTypeTitle : '',
                hasGps: formField?.hasGps,
                newValue: formField?.newValue,
                marketValue: formField?.marketValue,
                matriculation: formField?.matriculation,
                chassisNumber: formField?.chassisNumber,
                greyCardImg: formField?.greyCardImg,
                isGood: formField?.isGood,
                goodWeigh: formField?.goodWeigh,

                // Trailer
                hasTrailer: formField?.hasTrailer,
                trailerModel: formField?.hasTrailer ? trailerModel : null,
                trailerMatriculation: formField?.trailerMatriculation,
                trailerChassisNumber: formField?.chassisNumber,
                payload: formField?.payload,
                inflammable: formField?.inflammable
            });

            const auto = {
                packLineRef: packSelected?.packLineRef,
                categoryRef: quotationRequest?.categoryRef,
                categoryTitle: quotationRequest?.categoryTitle,

                primeTTC: packSelected?.primeTTC,
                primeNette: packSelected?.primeNette,
                primeExtra: packSelected?.primeExtra,
                agencyFees: packSelected?.angencyFees,
                customPrimeAcc: packSelected?.customPrimeAcc,
                driverBirthDay: formField?.driverBirthDay,
                driverLicdeliveryDate: formField?.driverLicdeliveryDate,

                customGuaranties: packSelected?.customGuaranties || [],
                customAssistances: packSelected?.customAssistances || [],
                reductionGroups: packSelected?.reductionGroups || [],
                customAssistances : packSelected?.customAssistances,

                vehicle: _vehicle
            }

            autos.push({ ...auto });
        }

        const _fSubscriptionRequest = {
            
            partnerCode: partnerCode,
            offering: "CLASSIC",
            contractRef: contract?.ref, // Utile lorsqu'on choisit un avenant autre que avenant de création
            productRef: constants.PRODUCT_AUTO_REF,
            categoryRef: category?.ref,
            categoryTitle: category?.title,
            productTitle: "AUTO",
            avenantRef: avenant?.ref,
            avenantTitle: avenant?.title,

            /* period: new Period({
                ref: basket?.formField?.periodRef,
                title: basket?.formField?.periodTitle,
                customerStartDate: formField?.customerStartDate,
                customerEndDate: formField.customerEndDate,
                prorate: formField?.prorate,
            }), */

            periodRef: basket?.formField?.periodRef,
            periodTitle: basket?.formField?.periodTitle,
            customerStartDate: basket?.formField?.customerStartDate,
            customerEndDate: basket?.formField?.customerEndDate,
            prorate: isProrate,

            dateEffect: basket?.formField?.startDate,

            // PRIME
            primeTTC    : quotationResponse?.primeTTC,
            primeNette  : quotationResponse?.primeNette,
            primeExtra  : quotationResponse?.primeExtra,
            agencyFees  : quotationResponse?.agencyFees,

            assure: Assure?.fromJson(assure),

            paymentProviderRef: constants.DEFAULT_PAYEMENT_PROVIDER,
            promoCode: "",
            canal: "BO",

            autos: autos,

            isProject: isProject,
            projectCode: projectCode,

            capitalRef: "",

        }

        return _fSubscriptionRequest;
    }

    static fromStatelessPrevSubscription({prevSubscription, setFields}) {
        const _prevSubscription = {...prevSubscription, ...setFields};
        return _prevSubscription;
    }
}
