import { Form, Select } from "antd"
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter"
import React,  { useState } from "react"
import { useEffect } from "react"
import TransportService from "services/transportService"

const TransportField=({categoryRef, transports})=> {
    const Option = Select;
    const [state, setState]=useState({
        transports: transports ?? [],
        isTransportsLoading: false
    })

    useEffect(()=> {
        if(!transports) {
            getTransports()
        }
    }, [])

    const getTransports=()=> {
        setState(state=> ({...state, isTransportsLoading: true}));
        (new TransportService()).getByCategoryRef(categoryRef, (data)=> {
            const _transports = data || [];
            setState(state=> ({...state, transports: _transports, isTransportsLoading: false}))
        }, (error)=> {
            setState(state=> ({...state, isTransportsLoading: false}))
        })
    }

    return (
        <React.Fragment>
            <Form.Item
                label="Transport"
                name="transportRef"
                rules={[{ required: false, message: "Champ obligatoire" }]}
            >
                <SelectWithFilter size='medium' placeholder="Selectionner" addonBefore={"Transport :"}>
                    {(state.transports || []).map((transport, index)=> (<Option value={transport?.ref} key={index}>{transport?.title}</Option>))}
                </SelectWithFilter>
            </Form.Item>
        </React.Fragment>
    )
}

export default TransportField;