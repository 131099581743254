import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, Form, Select, Spin } from "antd";
import { CATEGORY_406 } from "constantes";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import React, { useEffect } from "react";
import { useState } from "react";
import BrandService from "services/brandService";

const BrandField=({categoryCode, form, disable, handlerFormField, setCustomerBrand, xs, md})=> {
    const Option= Select;

    const [state, setState] = useState({
        // BRAND
        brands: [],
        isBrandLoading: true,
        isCustomerBrand: false
    })

    useEffect(()=> {
        // ALL BRAND
        BrandService.getAll({}, (data) => {
            const _brands = (data || []).sort((a,b)=> a.id - b.id)
            setState(state => ({ ...state, brands: _brands, isBrandLoading: false }));
        }, (error) => { });
    }, [])

    const onBrandSelected = (brandRef) => {
        const brandSelected = state.brands.find((brand) => brand.ref === brandRef);

        if (brandSelected) {
            handlerFormField({
                customerBrand: false,
                brandRef: brandSelected?.ref,
                brandTitle: brandSelected?.title,
            })
        }
    }

    const resetBrandFields= ()=> {
        
        handlerFormField({
            brandRef: null,
            brandTitle: null,
            modelRef: null,
            modelTitle: null
        })
    }

    if([CATEGORY_406].includes(categoryCode)) return null;

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label={(<div style={{ display: 'flex' }}><span>Marque</span><PlusCircleOutlined style={{ marginLeft: 10, marginTop: 5, cursor: 'pointer' }} onClick={() => {setCustomerBrand(true); resetBrandFields()}} /></div>)}
                    name="brandRef"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    {
                        state.isBrandLoading ? <Spin /> :
                            (
                                <SelectWithFilter 
                                    // mode="tags" maxTagCount={1} maxTagTextLength={1} 
                                    placeholder='Marque du vehicule' 
                                    onChange={onBrandSelected} 
                                    id="marque" 
                                    disabled={disable}>
                                    {state.brands.map((brand, index) => (<Option value={brand.ref} key={index}>{brand.title}</Option>))}
                                </SelectWithFilter>
                            )
                    }
                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default BrandField;