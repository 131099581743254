import { Col, Form, Select, Space, Spin } from "antd";
import { CATEGORY_406 } from "constantes";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import React, { useEffect, useState } from "react";
import ModelService from "services/modelService";

const ModelField=({categoryCode, brandRef, disable, handlerFormField, xs, md})=> {
    
    const Option= Select;

    const [state, setState] = useState({
        // MODEL
        models: [],
        isModelLoading: false,
        isSelected: false,
    });

    useEffect(()=> {
        if(brandRef)
            getModelsByBrandRef(brandRef)
    }, [brandRef])

    const getModelsByBrandRef=(brandRef)=> {
        if (brandRef) {
            setState(state => ({ ...state, isModelLoading: true }));
            (new ModelService()).readModelsByBrand(brandRef, (data) => {
                setState(state => ({ ...state, models: data, isModelLoading: false }));
            }, (error) => { });
        }
        else console.log('la marque n\'existe pas.')
    }

    const onModelChange = (modelRef) => {
        const modelSelected = state.models.find((model) => model.ref === modelRef);

        if (modelSelected) {
            handlerFormField({
                modelRef: modelSelected?.ref,
                modelTitle: modelSelected?.title
            })
        }
    }

    if([CATEGORY_406].includes(categoryCode)) return null;

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label={(<span>Modèle</span>)}
                    name="modelRef"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    <SelectWithFilter
                            placeholder='Modèle du vehicule'
                            onChange={onModelChange}
                            loading={state.isModelLoading}
                            id="model"
                            disabled={disable || state?.isModelLoading}
                            suffixIcon={state.isModelLoading ? (<Space><div>Chargement...</div><Spin size='small' /></Space>) : null}
                        >
                            {state.models.map((model, index) => (<Option value={model.ref} key={`${model.ref}${index}`}>{model.title}</Option>))}
                        </SelectWithFilter>
                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default ModelField;