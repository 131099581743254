import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: { success: false, error: false },
    message: ''
};

export const apiResponseSlice = createSlice({
    name: 'apiResponse',
    initialState,
    reducers: {
        showApiResponse: (state, action) => {
            state.status = { success: action.payload.status, error: !action.payload.status };
            state.message = action.payload.message;
        },
        hideApiResponse: (state) => {
            state.status = { ...initialState.status };
            state.message = initialState.message;
        }
    },
})

export const { showApiResponse, hideApiResponse } = apiResponseSlice.actions

export default apiResponseSlice.reducer