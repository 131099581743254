export default class PageResponse {
    page;
    size;
    totalElements;
    totalPages;
    last;
    content=[];

    fromJson = (json={})=> {
        var pageResponse = new PageResponse();
        pageResponse = json;
        return pageResponse
    }
}