import { LeftCircleOutlined } from "@ant-design/icons";
import { Col, Form, Input } from "antd";
import { CATEGORY_403 } from "constantes";
import React from "react";

const TrailerCustomerBrandField= ({categoryCode, disable, handlerFormField, setTrailerCustomerBrand, xs, md})=> {

    const resetTrailerCustomerBrandFields= ()=> {
        handlerFormField({
            trailerCustomerBrand: false,
            trailerBrandRef: null,
            trailerBrandTitle: null
        })
    } 

    if(![CATEGORY_403].includes(categoryCode)) return null;
    
    return (
      <React.Fragment>
        <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
            <Form.Item
                label={(<div style={{ display: 'flex' }}><h5>Saisissez la marque</h5><LeftCircleOutlined style={{ marginLeft: 10, marginTop: 5, cursor: 'pointer' }} onClick={() => {setTrailerCustomerBrand(false); resetTrailerCustomerBrandFields()}} /></div>)}
                name="trailerBrandTitle"
                rules={[{ required: true, message: "Champ obligatoire" }]}
            >
                <Input placeholder='Saisissez la marque' size='large'  disable={disable} />
            </Form.Item>
        </Col>

        <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>

            <Form.Item
                label={(<div style={{ display: 'flex' }}><h5>Saisissez le modèle</h5></div>)}
                name="trailerModelTitle"
                rules={[{ required: true, message: "Champ obligatoire" }]}
            >
                <Input placeholder='Saisissez le modèle' size='large' disable={disable}/>
            </Form.Item>
        </Col>
          
      </React.Fragment>
    )  
  }
  
  export default TrailerCustomerBrandField;