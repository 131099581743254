import { FormField } from "forms/FormField";
import * as constants from 'constantes'

export default class QuotationRequest {
    key;
    calculateGuarantee = true;

    contractRef = null;

    avenantRef;
    packLineRef;

    productRef;
    categoryRef;

    cityRef;
    energyRef;

    periodRef;
    customerPeriod=false;
    customerStartDate;
    customerEndDate;
    prorate = false;

    socialPosRef;

    dateMec;
    matriculation = null;
    totalPlace = 0;
    fiscalPower = 0;
    newValue = 0;
    marketValue = 0;
    payload = null;
    inflammable=false;

    hasGroup = false;
    groupRef;

    hasGps = false;

    isGood = false;
    goodWeight = 0;

    driverBirthDay;
    driverLicDeliveryDate;
    
    customGuaranties = [];
    reductionGroups = [];
    customAssistances = [];

    primeAccessory=null;

    fromJson = (json={})=> {
        var quotationRequest = new QuotationRequest();
        quotationRequest = json;
        return quotationRequest
    }

    static buildFromFormFields({ formFields, contract, avenant, packLineRef, matriculation, calculateGuarantee=true }) {
        const formField = new FormField({...formFields});

        return {
            calculateGuarantee: calculateGuarantee,
            contractRef: contract?.ref,

            packLineRef: packLineRef,
            avenantRef: avenant?.ref,
            productRef: constants.PRODUCT_AUTO_REF,
            //customerPeriod: true,
            categoryRef: formField.categoryRef,

            transportRef: formField?.transportRef,

            // Vehicle
            energyRef    : formField?.energyRef,
            matriculation: formField?.matriculation || matriculation,
            totalPlace   : formField?.nbPlace,
            fiscalPower  : formField?.fiscalPower,
            cylinder     : formField?.cylinder,
            dateMec      : formField?.dateMec,
            cityRef      : formField?.cityRef,
            newValue     : formField?.newValue,
            marketValue  : formField?.marketValue,
            payload      : formField?.payload,
            inflammable  : formField?.inflammable,
            hasGroup: formField?.hasGroup,
            groupRef: formField?.groupementRef,
            hasGps: formField?.hasGps,
            isGood: false,
            goodWeight: 0,

            // Period
            periodRef   : formField?.periodRef,
            customerStartDate: formField?.customerStartDate,
            customerEndDate: formField?.customerEndDate,
            prorate     : formField?.prorate,

            socialPosRef: formField?.socialPosRef,

            driverBirthDay: formField?.driverBirthDay,
            driverLicDeliveryDate: formField?.driverLicDeliverDate,

            customGuaranties: formField?.customGuaranties,
            reductionGroups : formField?.reductionGroups
        }
    }

}