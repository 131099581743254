import { Col, Form, Input } from "antd";
import { CATEGORY_406 } from "constantes";
import React from "react";

const CustomerModelField= ({categoryCode, disable,  xs, md})=> {

  if([CATEGORY_406].includes(categoryCode)) return null;
  return (
    <React.Fragment>
      <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
          <Form.Item
              label={(<div style={{ display: 'flex' }}><span>Saisissez le modèle</span></div>)}
              name="modelTitle"
              rules={[{ required: true, message: "Champ obligatoire" }]}
          >
              <Input placeholder='Saisissez le modèle' size='large' disabled={disable} />
          </Form.Item>
      </Col>
        
    </React.Fragment>
  )  
}

export default CustomerModelField;