import { EditOutlined, ExclamationCircleOutlined, FileAddOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Modal, notification, Row, Spin, Table, Select, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import InputNumberFormatFr from "modules/components/InputNumberFormatFr/InputNumberFormatFr";
import { GroupementService } from "services/groupementService";
import CreateGroupementRequest from "api/request/createGroupementRequest";
import FormAddGroupement from "forms/addGroupementForm";
import FormEditGroupement from "forms/formEditGroupement";
import { toast } from "react-toastify";
import EditGroupementRequest from "api/request/editGroupementRequest";
import ActionType from "api/acionType";
import { Card } from "reactstrap";

export default function Groupement(props) {
    let formRegisterGroupement = new FormAddGroupement();
    let formEditGroupement = new FormEditGroupement();
    const Option = Select;
    const [form] = Form.useForm();

    const [drawerVisibility, setDrawerVisibility] = useState({
        visibility: false,
        actionType: ActionType.REGISTER
    });
    
    const [state, setState] = useState({
        groupements: [],
        isGroupementLoading: [],
        groupementSelected: {},
        typeGroupements: [],
        isTypeGroupementLoading: false,
        
        loading: false,
        itemId: null,
        dataSource: []
    });

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg
        });
    };

    useEffect(() => {
        getAllGroupements();
    }, [])

    function getAllTypeGroupements() {
        (new GroupementService()).readAllGroupementType((data)=> {
            setState(state => ({ ...state, typeGroupements: data, isTypeGroupementLoading: false }));
        }, (error)=>{});
    }

    const getAllGroupements = ()=> {
        (new GroupementService()).getAllGroupement((data)=> {
            setState(state => ({ ...state, groupements: data, dataSource: data, isGroupementLoading: false }));
        }, (error)=> {})
    }


    const onModalOpen= (value, actionType)=> {
        setState(state => ({ ...state, isTypeGroupementLoading: true }));
        if(value) {
            
            formEditGroupement = formEditGroupement.fromJson({
                ref: value?.ref,
                code: value.code,
                groupementTypeRef: value?.groupementType?.ref,
                groupementTypeTitle: value?.groupementType?.title,
                title: value?.title,
                nbVehicle: value?.nbVehicle,
                description: value?.description
            });
            console.log(formEditGroupement)
            setState(state => ({ ...state, groupementSelected: formEditGroupement }));
            form.setFieldsValue({...form.getFieldsValue(), ...formEditGroupement});
        }
        else {
            form.setFieldsValue({...form.getFieldsValue(), ...formRegisterGroupement});
        }
        
        setState(state => ({ ...state, isTypeGroupementLoading: false }));
        getAllTypeGroupements();
        setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: true, actionType: actionType}));
    }

    // TODO-OK: <Enregistrement et edition d'un groupement>
    const onRegisterOrEditGroupementSubmit = (action)=> {
        setState(state => ({ ...state, loading: true }));

        form.validateFields().then((fields)=> {
            switch(action) {
                case ActionType.REGISTER:
                    let formRegisterFields = formRegisterGroupement.fromJson(fields);
                    let createGroupementRequest = (new CreateGroupementRequest()).fromJson(formRegisterFields);

                    new GroupementService().createGroupement(createGroupementRequest, (data)=>{
                        setState(state => ({ ...state, isGroupementLoading: true }));
                        form.resetFields();
                        getAllGroupements();
                        setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false}));
                        toast.success("Groupement ajouté avec succès", {style: {background: 'primary'}});
                    }, (error)=> {});
                break;

                case ActionType.EDIT:
                    
                    let formEditFields = {...formEditGroupement, ...fields};
                    let editGroupementRequest = (new EditGroupementRequest()).fromJson(formEditFields);

                    new GroupementService().editGroupement(editGroupementRequest, (data)=>{
                        setState(state => ({ ...state, isGroupementLoading: true }));
                        form.resetFields();
                        getAllGroupements();
                        setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false}));
                        toast.success("Groupement ajouté avec succès", {style: {background: 'primary'}});
                    }, (error)=> {
                        toast.error(error);
                    });
                break;

                default:
                    setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false}));
                    toast.error("Une erreur est survenue, veuillez réesayer");
                break;
            }
        }).catch((error)=> {
            toast.error("Groupement non enregistré");
        })
        
        setState(state => ({ ...state, loading: false }));
    }

    const onSearchGroupement= (e)=> {
        const val = e?.target?.value?.toLowerCase();
        const temp = state.groupements?.filter((d)=> {
            return d?.code?.toLowerCase().indexOf(val) !== -1 ||
            d?.title?.toLowerCase().indexOf(val) !== -1 ||
            d?.description?.toLowerCase().indexOf(val) !== -1 ||
            d?.groupementType.title?.toLowerCase().indexOf(val) !== -1 || 
            !val
        });
        setState(state => ({...state, dataSource: temp}))
    }

    function onEntitieChange(value) {
        console.log('entite ---> ', value);
        let data = state.listGroupement.filter(item => item.type == value);
        setState(state => ({ ...state, groupement: data }))
    }

    // TABE CUSTOMIZATION
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div
            style={{
              padding: 8,
            }}
          >
            <Input
              ref={searchInput}
              placeholder={`Rechercher ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                marginBottom: 8,
                display: 'block',
              }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Rechercher
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 90,
                }}
              >
                Reset
              </Button>
              {/* <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({
                    closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
                }}
              >
                Filtrer
              </Button> */}
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#1890ff' : undefined,
            }}
          />
        ),
        onFilter: (value, record) =>
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
        render: (text) =>
          searchedColumn === dataIndex ? (
            text
          ) : (
            text
          ),
      });

    const columns = [

        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            width: 150,
            render:(code, item)=> (
                <div style={{fontWeight: "bold"}}>
                    {code}
                </div>
            ),
            ...getColumnSearchProps("code"),
        },
        {
            title: "Titre",
            dataIndex: "title",
            key: "title",
            ...getColumnSearchProps("title"),
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            ...getColumnSearchProps("description"),
        },
        {
            title: "Type",
            dataIndex: "groupementType",
            width: 250,
            render: (item, groupement) => (
                <div>
                    {groupement.groupementType.title}
                </div>

            ),
            //...getColumnSearchProps("type"),
        },
        {
            title: "Nombre de vehicule",
            dataIndex: "nbVehicle",
            key: "nbVehicle",
            width: 250,
            ...getColumnSearchProps("nbVehicle")
        },
        {
            title: "Actions",
            dataIndex: "action",
            width: 100,
            key: "action",
            render: (item, groupement) => (
                <>
                    <EditOutlined style={{ color: '#40E070', cursor: 'pointer' }} onClick={() => { onModalOpen(groupement, ActionType.EDIT) }} />
                    {/* <DeleteOutlined style={{ color: '#E5AC28', cursor: 'pointer', marginLeft: 10 }} onClick={() => { setDel(true); setState(state => ({ ...state, itemId: data.id })) }} /> */}
                </>

            )
        }
    ];

    return (
        <Card>
            <div style={{padding:20}}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Liste des groupements</h4>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Input placeholder="Recherche: code, type, description" style={{ width: 300, marginRight: 10 }} onChange={onSearchGroupement} />

                        <Button type="primary" onClick={()=> onModalOpen(null, ActionType.REGISTER)} style={{ marginLeft: 10 }}>
                            <FileAddOutlined />
                            Ajouter un groupement
                        </Button>   
                    </div>
                </div>
                <br />
                {!state.isGroupementLoading ? (
                    <Table 
                        dataSource={state.dataSource} 
                        columns={columns} 
                        pagination={{
                            position: ["topRight"],
                            defaultPageSize: 5
                        }} 
                    />
                ) : (<Spin />)}

                {drawerVisibility.visibility && (
                    <Drawer
                        title="Enregistrement d'un groupement"
                        width={720}
                        onClose={() => setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false}))}
                        visible={drawerVisibility.visibility}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                    >
                        <Form
                            name="basic"
                            onFinish={()=> onRegisterOrEditGroupementSubmit(drawerVisibility.actionType)}
                            layout="vertical"
                            form={form}
                            initialValues={formRegisterGroupement}
                        >

                            <Row gutter={8} >
                                {
                                    drawerVisibility.actionType === ActionType.EDIT ? (
                                        <Col className="gutter-row" xs={24} md={12} style={{display: "none"}}>
                                            <Form.Item
                                                label="ref"
                                                name="ref"
                                            >
                                                <Input  size="middle" />
                                            </Form.Item>
                                        </Col>
                                    ): null
                                }
                                
                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Type de groupement"
                                        name="groupementTypeRef"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Le type de gouprment svp !',
                                            },
                                        ]}
                                    >
                                        {
                                            state.isTypeGroupementLoading ? (<Spin />): 
                                            <SelectWithFilter placeholder="Choisissez le type de groupement" size="middle">
                                                {
                                                    state.typeGroupements.map(function (typeGroupement, index) {
                                                        return (<Option value={typeGroupement?.ref} key={index}>{typeGroupement.title}</Option>)
                                                    })
                                                }
                                            </SelectWithFilter>
                                        }
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" xs={24} md={12}>
                                    <Form.Item
                                        label="Nom du groupement"
                                        name="title"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Entrez le nom du groupement svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Entrez le nom  du groupement" size="middle" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Nombre de vehicule"
                                        name="nbVehicle"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Entrez le nombre de vehicule svp !',
                                            },
                                        ]}
                                    >
                                        <InputNumberFormatFr placeholder="Entrez le nom de vehicule" size="middle" min={1} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Description du groupement"
                                        name="description"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Entrez la description du groupement svp !',
                                            },
                                        ]}
                                    >
                                        <TextArea placeholder="Entrez la description  du groupement" size="middle" />
                                    </Form.Item>
                                </Col>
                                
                            </Row>

                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e8e8e8',
                                    padding: '10px 16px',
                                    textAlign: 'right',
                                    left: 0,
                                    background: '#fff',
                                    borderRadius: '0 0 4px 4px',
                                }}
                            >
                                <Button
                                    style={{
                                        marginRight: 8,
                                    }}
                                    onClick={() => setDrawerVisibility(drawerVisibility=> ({...drawerVisibility, visibility: false}))}
                                >
                                    Annuler
                                </Button>
                                <Button type="primary" htmlType="submit" loading={state.loading}>
                                    {drawerVisibility.actionType === ActionType.REGISTER ?  "Enregistrer" : "Editer" }
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                )}
                {/**********DELETE ALERTE ****** */}
                {false && (
                    <Modal
                        title="Supprimer un groupement !!!"
                        visible={false}
                        onOk={() => { }}
                        onCancel={() => {}}
                        okText="Confirmer"
                        cancelText="Annuler"
                    >
                        <h3 style={{ color: 'orange' }}> <ExclamationCircleOutlined />  Voulez-vous supprimer vraiment cette agence ?</h3>
                    </Modal>
                )}
            </div>
        </Card>
    )
}