import React from "react";
import {  Col, Form } from "antd";
import InputNumberFormatFr from "modules/components/InputNumberFormatFr/InputNumberFormatFr";
import { CATEGORY_405, CATEGORY_406 } from "constantes";

const NewValueField=({categoryCode, form, onBlur, disable, xs, md})=> {

    if([CATEGORY_405, CATEGORY_406].includes(categoryCode) ) return null;

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label={<>Valeur neuve (<i>Minimal: 5 000 000 F CFA</i>)</>}
                    name="newValue"
                    rules={[
                        { required: true, message: "Champ obligatoire" }
                    ]}
                >
                    <InputNumberFormatFr
                        placeholder='Valeur neuve' 
                        min={5000000}
                        //onBlur={(e) => { onChange(e.target.value); }}
            
                        disabled={disable} />

                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default NewValueField;