import { Form, Select, Spin } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import React, { useEffect } from "react";
import { useState } from "react";
import IdentityCardService from "services/identityCardService";

const CardIDField=({disable, handlerFormField})=> {
    const Option = Select;
    const [state, setState] = useState({
        identityCards: [],
        isIdentityCardLoading: true,
        isSelected: false,
    })

    useEffect(()=> {
        (new IdentityCardService()).getAll((data) => {
            setState(state => ({ ...state, identityCards: data || [], isIdentityCardLoading: false }))
        }, (error) => { });
    }, [])

    const onIDCardSelected = (cardRef) => {
        const cardSelected = state.identityCards.find((card) => card?.ref === cardRef);
        if (cardSelected) {
            handlerFormField({
                assureIdentityCardRef: cardSelected?.ref,
                assureIdentityCardTitle: cardSelected?.title
            })

            setState(state => ({ ...state, isSelected: true }))
        }
    }

    return (
        <React.Fragment>
            <Form.Item
                label="Nature de la piece d'identité"
                name="assureIdentityCardRef"
                rules={[{ required: false, message: "Champ obligatoire" }]}
            >
                {
                    state.isIdentityCardLoading ? <Spin /> :
                        (
                            (<SelectWithFilter placeholder='Nature de la piece'  disabled={disable} onChange={(e) => { onIDCardSelected(e) }}>
                                {state.identityCards.map((identityCard, index) => (<Option value={identityCard?.ref} key={index} >{identityCard.title}</Option>))}
                            </SelectWithFilter>)
                        )
                }
            </Form.Item>
        </React.Fragment>
    )
}

export default CardIDField;