import {  Form, Input } from "antd";
import React from "react";

const MerchantLastNameField=({disable})=> {
    return (
        <React.Fragment>
            <Form.Item
                label="Prénoms apporteur"
                name="merchantLastname"
                rules={[{ required: true, message: "Champ obligatoire" }]}
            >
                <Input size='medium' placeholder='Prenom' disabled={disable} />
            </Form.Item>
        </React.Fragment>
    )
}

export default MerchantLastNameField;