import { useEffect, useState } from "react";
import moment from "moment";
import * as constants from 'constantes';
import AvenantService from "services/avenantService";
import InitAvenantResponse from "api/response/initAvenantResponse";
import InitAvenantRequest from "api/request/initAvenantRequest";
import FormFieldsBuilder from 'forms/FormFieldsBuilder';
import QuotationRequestBuilder from "helpers/QuotationRequestBuilder";
import SocailPosition from "api/model/socialPosition";
import { FormField } from "forms/FormField";
import { useSelector } from "react-redux";
import { useBasket } from "../../hooks/useBasket";
import SubscriptionRequestBuilder from "api/request/SubscriptionRequestBuilder";
import { randomString } from "helpers/helpers";
import QuotationResponse from "api/v1/response/QuotationResponse";
import _ from "lodash";

export default function useInitFlotteAvenant() {
    const { contract, avenant, category, assure, projectCode, isProject} = useSelector((state) => state.subscription);
    const {basket}= useSelector(state=> state.basketSlice);
    const {updateBasket, updateBaskets, updateGroup} = useBasket();

    const [state, setState]=useState({
        avenantStatus : {loading: true, error: false, errorMessage: ""},

        isPeriodSelected: false,
        socialPosition: new SocailPosition(),
        isSocialPositionSelected: false,
        openVehicleDrawer: false,
        customPeriodTitle: ''
    })

    useEffect(() => {
        const initAvenantRequest = InitAvenantRequest.fromJson({
            productRef: constants.PRODUCT_AUTO_REF,
            contractRef: contract?.ref,
            avenantCode: avenant?.code,
            assureCode: assure?.code,
            //periodRef: contract.period?.ref,
            projectCode: projectCode
        });
        initialiseAvenant(initAvenantRequest);
    }, []);

    const initialiseAvenant = (initAvenantRequest) => {
        
        AvenantService.init(initAvenantRequest, (responseData) => {
            if(!responseData) {
                setState(state => ({ ...state, avenantStatus : {loading: false, error: false, errorMessage: ""}, }));
                return;
            }
            let initAvenantResponse = (new InitAvenantResponse()).fromJson(responseData);
            console.log("avenant?.code 1 :", avenant?.code)
            console.log('responseData', responseData);
            switch(parseInt(avenant?.code)) {
                case constants.CODE_AVENANTS.INCORPORATION:
                    console.log("avenant?.code :", avenant?.code)
                    const _formField = new FormField({

                        assureFirstname  : assure?.firstName,
                        assureLastname  : assure?.lastName,
                        assurePhone     : assure?.phone,
                        assureCode      : assure?.code,
                        assureEmail     : assure?.email,
                        assureIdentityCardRef: assure?.identityCardRef,
                        assureIdentityCardTitle: assure?.identityCardTitle,
                        assureIdentityCardNumber: assure?.identityCardNumber,
                        socialPosRef: assure?.socialPosRef,
                        socialPosTitle: assure?.socialPosTitle,
                        
                        periodRef: contract?.period?.ref ,
                        periodTitle: contract?.period?.title ,
                        startDate: initAvenantResponse?.startDate,
                        customerStartDate: initAvenantResponse?.startDate,
                        prorate: true
                    });
            
                    const __basket = {
                        ...basket,
                        formField: {..._formField}
                    }
            
                    console.log("baskets===========>", __basket)
                    updateBasket(__basket);
                    updateBaskets([]);
                    updateGroup([
                        {path: ['initAvenantResponse'], value: initAvenantResponse}
                    ])
                    setState(state => ({ ...state, avenantStatus : {loading: false, error: false, errorMessage: ""}, }));
                    return;

                default:
                    // RECUPERATION DE L'ANCIENNE DATA ET CONSTRUCTION D'UNE BASKET
                    const quotationResponse = QuotationResponse.fromJson(initAvenantResponse?.quotationResponse);
        
                    // :::::::::::::::::::: Reconstruire le tableau des baskets
                    const baskets = [];
                    const autos = SubscriptionRequestBuilder.buildAutoFromContrat({contract: contract});
                    for (const auto of autos) {
                        const formFields = FormFieldsBuilder.fromContract({ contract: contract, auto: auto, assure: assure });
                        console.log(":::: formFields :", formFields)
                        const quotationRequest = QuotationRequestBuilder.build({ 
                            formFields, 
                            avenant: avenant, 
                            contract: contract,
                            packLineRef: auto.packLineRef,
                            calculateGuarantee: true 
                        });
                        const packSelected = contract?.packsSelected?.find(item => item?.vehicle?.matriculation === auto?.vehicle?.matriculation);
                        console.log("::::: contract ", contract)
                        const key = randomString(8);
                        
                        baskets.push({ 
                            id: baskets?.length + 1,
                            key: key, 
                            formField: {
                                ...formFields
                            }, 
                            quotationRequest, 
                            packSelected: { 
                                ...packSelected?.packResponse,
                                customGuaranties:packSelected?.packResponse?.guaranties?.map((guarantee)=> ({ 
                                    guaranteeRef: guarantee?.ref,
                                    engagementRef: _.first(guarantee?.engagements)?.ref ?? null,
                                    franchise: guarantee?.franchise
                                })),
                                key: key 
                            } 
                        });
                    }
                    // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::
        
                    // ::::::::::::::::::::::::::: Reconstruire la flotteSubscriptionRequest
                    const fSubscriptionRequest = SubscriptionRequestBuilder.fSubscriptionFromContract({
                        avenant: avenant, 
                        contract: contract,
                        assure: assure,
                        isProject: isProject,
                        projectCode: projectCode,
                        isProrate: false
                    });
                    fSubscriptionRequest.dateEffect = initAvenantResponse?.startDate;
                    
                     console.log("fSubscriptionRequest ::::: ", fSubscriptionRequest)
                     console.log("initAvenantResponse?.startDate ::::: ", initAvenantResponse?.startDate)
                     // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
        
                     // :::::::::: Pre-construire la basket courante
                     console.log(":::: Assure : ", assure)
                     const ___basket = _.first(baskets);
                    const formField = new FormField({
                        categoryRef: category?.ref,
                        categoryTitle   : category?.title,
        
                        assureFirstname  : assure?.firstName,
                        assureLastname  : assure?.lastName,
                        assurePhone     : assure?.phone,
                        assureCode      : assure?.code,
                        assureEmail     : assure?.email,
                        assureIdentityCardRef: assure?.identityCardRef,
                        assureIdentityCardTitle: assure?.identityCardTitle,
                        assureIdentityCardNumber: assure?.identityCardNumber,
                        socialPosRef: assure?.socialPosRef,
                        socialPosTitle: assure?.socialPosTitle,
                        
                        periodRef: contract?.period?.ref ,
                        periodTitle: contract?.period?.title ,
                        startDate: initAvenantResponse?.startDate ? moment((new Date(initAvenantResponse?.startDate).toLocaleDateString()), "DD/MM/YYYY") : moment((new Date().toLocaleDateString()), "DD/MM/YYYY"),//initAvenantResponse?.startDate

                        reductionGroups: _.size(___basket?.packSelected?.reductionGroups) > 0 ? [...___basket?.packSelected?.reductionGroups] : []
                    });
        
                    const _basket = {
                        ...basket,
                        formField: {...formField}
                    }
        
                    console.log("baskets===========>", baskets)
                    updateBasket(_basket);
                    updateBaskets(baskets);
                    updateGroup([
                        {path: ['fSubscriptionRequest'], value: fSubscriptionRequest},
                        {path: ['quotationResponse'], value: quotationResponse},
                        {path: ['initAvenantResponse'], value: initAvenantResponse}
                    ])
        
                    //quoteFBaskets();
                    setState(state=> ({
                        ...state,
                        avenantStatus : {loading: false, error: false, errorMessage: ""},
                        customPeriodTitle: initAvenantResponse?.periodTitle
                    }))
            }
            
        }, (error) => {
            setState(state=> ({
                ...state,
                avenantStatus : {loading: false, error: true, errorMessage: error}
            }))
        });
    }

    return {state, initialiseAvenant}
}