import React, { useEffect, } from 'react';
import { Col, Drawer, Form, Row, Select, DatePicker, Button } from "antd";
import CustomAntdForm from 'modules/components/Forms/CustomAntdForm';
import SelectWithFilter from 'modules/components/CustomsSelects/SelectWithFilter';
import InputNumberFormatFr from 'modules/components/InputNumberFormatFr/InputNumberFormatFr';
import moment from 'moment';

import { useGetVehiclesCategories } from 'hooks/useCategory';
import { useTypeVehiclesOfCategory } from 'hooks/useTypeVehicles';
import { useGetEnergies } from 'hooks/useEnergy';
import { useGetCities } from 'hooks/useCity';

const Option = Select.Option;

export default function EditInfoVehicle(props) {
    const { visible, close, formFields } = props;

    const { data: categories, isLoading: isCategoriesLoading } = useGetVehiclesCategories();
    const { data: typeVehicles, isLoading: isTypeVehiclesLoading, defaultTypeVehicle, getTypeVehiclesOfCategory } = useTypeVehiclesOfCategory(formFields.categoryRef);
    const { data: energies, isLoading: isEnergiesLoading } = useGetEnergies();
    const { data: cities, isLoading: isCitiesLoading } = useGetCities();

    const [form] = Form.useForm();

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    function onSumit() {
        form.validateFields().then(values => {
            close({ ...formFields, ...values });
        }).catch((error) => console.log(error));
    }

    // Debut de gestion de la mise a jour des type de vehicule

    function handleChangeCategory(categoryRef) {
        getTypeVehiclesOfCategory(categoryRef);
    }

    useEffect(() => {
        if (typeVehicles.length > 0) {
            form.setFieldsValue({
                transportRef: defaultTypeVehicle?.ref
            });
        }
    }, [defaultTypeVehicle?.ref, typeVehicles]);

    // Fin de gestion de la mise a jour des type de vehicule

    return (
        <Drawer
            title="Modification de vehicule"
            onClose={() => close()}
            visible={visible}
            width={700}
        >
            <CustomAntdForm
                form={form}
                initialValues={{ ...formFields }}
            >
                <Row gutter={12}>
                    <Col className="gutter-row" xs={12} md={12}>
                        <Form.Item
                            label="Categorie"
                            name="categoryRef"
                            rules={[{ required: false, message: "Champ obligatoire" }]}
                        >
                            <SelectWithFilter loading={isCategoriesLoading} placeholder='Choisissez la category' onChange={handleChangeCategory}>
                                {categories.map(category => <Option value={category?.ref} key={category.ref}>{category?.code} : {category?.title}</Option>)}
                            </SelectWithFilter>
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={12} md={12}>
                        <Form.Item
                            label="Type de vehicule"
                            name="transportRef"
                            rules={[{ required: false, message: "Champ obligatoire" }]}
                        >
                            <SelectWithFilter loading={isTypeVehiclesLoading} placeholder='Choisissez le type de transport'>
                                {typeVehicles.map(transport => <Option value={transport?.ref} key={transport?.ref} >{transport?.description}</Option>)}
                            </SelectWithFilter>
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={12} md={12}>
                        <Form.Item
                            label="Nombre de place"
                            name="nbPlace"
                            rules={[{ required: true, message: "Champ obligatoire" }]}
                        >
                            <InputNumberFormatFr placeholder='nombre de place' min={1} />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={12} md={12}>
                        <Form.Item
                            label="Energie"
                            name="energyRef"
                            rules={[{ required: true, message: "Champ obligatoire" }]}
                        >
                            <SelectWithFilter loading={isEnergiesLoading} placeholder='Energie du vehicule'>
                                {energies.map(energy => <Option value={energy?.ref} key={energy?.ref}>{energy?.title}</Option>)}
                            </SelectWithFilter>
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={12} md={12}>
                        <Form.Item
                            label="Date de 1ère mise en circulation"
                            name="dateMec"
                            rules={[{ required: true, message: "Champ obligatoire" }]}
                        >
                            <DatePicker style={{ width: '100%' }} size='large' format={"DD/MM/YYYY"} disabledDate={disabledDate} />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={12} md={12}>
                        <Form.Item
                            label="Zone de circulation du vehicule"
                            name="cityRef"
                            rules={[{ required: true, message: "Champ obligatoire" }]}
                        >
                            <SelectWithFilter loading={isCitiesLoading} placeholder='Choisissez la zone'>
                                {cities.map(city => <Option value={city?.ref} key={city?.ref}>{city?.title}</Option>)}
                            </SelectWithFilter>
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={12} md={12}>
                        <Form.Item
                            label="Puissance fiscale"
                            name="fiscalPower"
                            rules={[{ required: true, message: "Champ obligatoire" }]}
                        >
                            <InputNumberFormatFr
                                min={1}
                                style={{ width: '100%' }}
                                placeholder='Puissance fiscale'
                            />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={12} md={12}>
                        <Form.Item
                            label="Valeur neuve"
                            name="newValue"
                            rules={[
                                { required: true, message: "Champ obligatoire" }
                            ]}
                        >
                            <InputNumberFormatFr placeholder='Valeur neuve' min={1} />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={12} md={12}>
                        <Form.Item
                            label="Valeur venale"
                            name="marketValue"
                            rules={[
                                { required: true, message: "Champ obligatoire" },
                            ]}
                        >
                            <InputNumberFormatFr placeholder='Valeur venale' min={1} max={form.getFieldValue("newValue")} />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={12} md={12}>
                        <Form.Item
                            label="Vehicule possede t-il un tracer GPS ?"
                            name="hasGps"
                            rules={[{ required: false, message: "Champ obligatoire" }]}
                        >
                            <SelectWithFilter defaultValue={false}>
                                <Option value={true} >Oui</Option>
                                <Option value={false} >Non</Option>
                            </SelectWithFilter>
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="primary" onClick={onSumit}>
                        Valider
                    </Button>
                </div>
            </CustomAntdForm>
        </Drawer >
    );
}