import React, { useEffect, useState } from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import { Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

const { SubMenu } = Menu;

const useStyles = makeStyles(() => ({
	topRoot: {
		position: 'fixed',
		top: 0,
		right: 0,
		left: 0,
		zIndex: 100,
		borderBottom: '1px solid #c4c4c4',
	},
	toolBarContainer1: {
		width: '100%',
		display: "flex",
		justifyContent: "space-between",
		width: '100%',
	},
	toolBarContainer2: {
		display: "flex",
		justifyContent: "center",
	},
	logoContainer: {
		cursor: "pointer",
		color: "white",
		fontWeight: "bold",
		fontSize: 20
	},
	menu: {
		background: 'inherit'
	},
}));

function getUrls(items) {
	let urls = {};

	for (let item of items) {
		if (item.sub_items?.length > 0) {
			for (let sub_item of item.sub_items) {
				urls[`${item.title}-${sub_item.title}`] = sub_item.url
			}
		} else {
			urls[`${item.title}`] = item.url
		}
	}

	return urls
}

function useHandleClick(items) {
	const history = useHistory()

	const urls = getUrls(items || []) || {};

	function handleClick(data) {
		history.push(urls[data.key])
	}

	return handleClick
}

function Topbar2(props) {
	const { items, dashboardItems, theme } = props
	const classes = useStyles();

	const [state, setState] = useState({ selectedKeys: [] });

	const { pathname } = useLocation();

	const handleClick = useHandleClick(items || [])

	useEffect(() => {
		const selectedItem = (items || []).find(item => item.url === pathname);

		if (selectedItem) {
			setState(state => ({
				...state,
				selectedKeys: [selectedItem.title]
			}));
		} else {
			let newSelectedItem;

			for (let item of dashboardItems) {
				if (item.url === pathname) {
					newSelectedItem = item;
					break;
				} else {
					if (item.children) {
						for (let childItem of item.children) {
							if (childItem.url === pathname) {
								newSelectedItem = item;
								break;
							}
						}
					}
				}

				if (newSelectedItem) break;
			}

			setState(state => ({
				...state,
				selectedKeys: [newSelectedItem?.title]
			}));
		}
	}, [pathname])

	return (
		<Menu selectedKeys={state.selectedKeys} className={classes.toolBarContainer2} onClick={handleClick} style={{ background: theme.topbarTwo.backgroundColor }} mode="horizontal">
			{items?.map(item => {
				if (item.sub_items?.length > 0) {
					return (
						<SubMenu key={item.title} icon={item.icon} title={item.title}>
							{item.sub_items.map(sub_item => {
								return <Menu.Item disabled={item.disabled} key={`${item.title}-${sub_item.title}`}>{sub_item.title}</Menu.Item>
							})}
						</SubMenu>
					)
				}

				return <Menu.Item disabled={item.disabled} key={item.title} icon={item.icon}>{item.title}</Menu.Item>
			})}
		</Menu>
	)
}

function Topbar(props) {
	const { actionsWidget, companyName, topBarItems, sideBarItems, theme } = props
	const classes = useStyles();
	const history = useHistory()

	return (
		<div className={classes.topRoot}>
			<div className={classes.toolBarContainer1} style={{background: theme.topbarOne.backgroundColor}}>
				<div className={classes.logoContainer} onClick={() => history.push('/')} style={{ display: "flex", alignItems: "center", marginLeft: 20 }}>
					{companyName}
				</div>

				{actionsWidget}
			</div>

			<Topbar2 theme={theme} items={topBarItems} dashboardItems={sideBarItems} />
		</div>
	);
}

export default Topbar;