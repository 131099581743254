import React, { useState } from "react";

import { EyeOutlined } from "@ant-design/icons";
import { Table, Tooltip, Tag, Drawer } from "antd";
import { Col, Row } from "reactstrap";
import * as helpers from 'helpers/helpers';
import ReturnNewTitle from "views/pages/common/ReturnNewTitle";
import ReturnNewLine from "views/pages/common/ReturnNewLine";

const FStepListVehicle = (props) => {
    const { flotteBasket: { baskets } } = props;

    const [state, setState] = useState({ visibleDetailsInfoVehicle: false, currentInfoVehicle: null });

    const toogleDetailsInfoVehicle = (currentInfoVehicle) => {
        setState(prevState => ({
            ...prevState,
            currentInfoVehicle,
            visibleDetailsInfoVehicle: !prevState.visibleDetailsInfoVehicle
        }));
    }

    const columns = [
        {
            title: 'Marque',
            dataIndex: 'formFields',
            key: 'brandTitle',
            render: formFields => formFields?.brandTitle
        },
        {
            title: 'Modèle',
            dataIndex: 'formFields',
            key: 'modelTitle',
            render: formFields => formFields?.modelTitle
        },
        {
            title: 'Matricule',
            dataIndex: 'formFields',
            key: 'matriculation',
            render: (formFields) => formFields?.matriculation
        },
        {
            title: 'N° chassis',
            dataIndex: 'formFields',
            key: 'chassisNumber',
            render: (formFields) => formFields?.chassisNumber
        },
        {
            title: 'Pack',
            dataIndex: 'packSelected',
            key: 'pack',
            render: (packSelected) => packSelected?.packTitle
        },
        {
            title: 'Prime nette',
            dataIndex: 'packSelected',
            key: 'primeNette',
            render: (packSelected) => (
                <Tag color="volcano">
                    <div style={{ fontWeight: 'bold' }}>{helpers.thousandSeparator(packSelected?.primeNette)} F CFA</div>
                </Tag>
            )
        },
        {
            title: 'Prime TTC',
            dataIndex: 'packSelected',
            key: 'primeTTC',
            render: (packSelected) => (
                <Tag color="green">
                    <div style={{ fontWeight: 'bold' }}>{helpers.thousandSeparator(packSelected?.primeTTC)} F CFA</div>
                </Tag>
            )
        },
        {
            title: 'Action',
            dataIndex: 'formFields',
            key: 'action',
            render: (_, backet) => (
                <Tooltip title="Modifier">
                    <EyeOutlined style={{ color: '#40AEE0', cursor: 'pointer' }} onClick={() => toogleDetailsInfoVehicle(backet)} />
                </Tooltip>
            )
        }
    ];

    return (
        <>
            <Row>
                <Col lg={12}>
                    <Table
                        columns={columns}
                        dataSource={baskets}
                        pagination={{ position: ["topRight"] }}
                    />
                </Col>
            </Row>

            {state.visibleDetailsInfoVehicle && (
                <Drawer
                    title="Détails du contract"
                    placement="right"
                    width="30%"
                    onClose={() => toogleDetailsInfoVehicle()}
                    visible={state.visibleDetailsInfoVehicle}>
                    <>
                        {ReturnNewTitle('Informations sur le vehicule')}

                        {ReturnNewLine('Energie', state.currentInfoVehicle?.formFields?.energyTitle, false)}
                        {ReturnNewLine('Nombre de places assises', state.currentInfoVehicle?.formFields?.nbPlace, false)}
                        {ReturnNewLine('Puissance fiscale', state.currentInfoVehicle?.formFields?.fiscalPower + ' Cv', false)}
                        {ReturnNewLine('Date de 1ere mise en circulation', new Date(state.currentInfoVehicle?.formFields?.dateMec).toLocaleDateString(), false)}
                        {ReturnNewLine('Valeur neuve', state.currentInfoVehicle?.formFields?.newValue + ' FCFA', false)}
                        {ReturnNewLine('Valeur venale', state.currentInfoVehicle?.formFields?.marketValue + ' FCFA', false)}
                        {ReturnNewLine('Balise GPS', state.currentInfoVehicle?.formFields?.hasGps ? "Oui" : "Non", false)}
                        {ReturnNewLine('Zone de circulation', state.currentInfoVehicle?.formFields?.cityTitle, true)}

                        {ReturnNewLine('Periode de couverture', state.currentInfoVehicle?.formFields?.periodTitle, false)}

                        {ReturnNewTitle('Package et reduction')}

                        {ReturnNewLine('Package', state.currentInfoVehicle.packSelected?.packTitle, true)}

                        {ReturnNewTitle('Autres informations sur le vehicule', false)}

                        {ReturnNewLine('Marque', state.currentInfoVehicle?.formFields?.brandTitle, false)}
                        {ReturnNewLine('Modele', state.currentInfoVehicle?.formFields?.modelTitle, false)}
                        {ReturnNewLine('Immatriculation', state.currentInfoVehicle?.formFields?.matriculation, false)}
                        {ReturnNewLine('Numero de chassis', state.currentInfoVehicle?.formFields?.chassisNumber, true)}
                    </>
                </Drawer>
            )}
        </>
    );
}

export default FStepListVehicle;