import { FormField } from "forms/FormField";
import * as constants from 'constantes';

export default class QuotationRequestBuilder {

    static build({ formFields, contract, avenant, packLineRef, matriculation, calculateGuarantee=true }) {
        const formField = new FormField({...formFields});

        return {
            calculateGuarantee: calculateGuarantee,
            contractRef: contract?.ref,

            packLineRef: packLineRef,
            avenantRef: avenant?.ref,
            productRef: constants.PRODUCT_AUTO_REF,
            //customerPeriod: true,
            categoryRef: formField.categoryRef,

            transportRef: formField?.transportRef,

            // Vehicle
            energyRef    : formField?.energyRef,
            matriculation: formField?.matriculation || matriculation,
            totalPlace   : formField?.nbPlace,
            fiscalPower  : formField?.fiscalPower,
            cylinder     : formField?.cylinder,
            dateMec      : formField?.dateMec,
            cityRef      : formField?.cityRef,
            newValue     : formField?.newValue,
            marketValue  : formField?.marketValue,
            payload      : formField?.payload,
            inflammable  : formField?.inflammable,
            hasGroup: formField?.hasGroup,
            groupRef: formField?.groupementRef,
            hasGps: formField?.hasGps,
            isGood: false,
            goodWeight: 0,

            // Period
            periodRef   : formField?.periodRef,
            customerStartDate: formField?.customerStartDate,
            customerEndDate: formField?.customerEndDate,
            prorate     : formField?.prorate,

            socialPosRef: formField?.socialPosRef,

            driverBirthDay: formField?.driverBirthDay,
            driverLicDeliveryDate: formField?.driverLicDeliverDate,

            customGuaranties: formField?.customGuaranties,
            reductionGroups : formField?.reductionGroups
        }
    }

    // Util das retrait vehicule
    static buildFromAssureAndPackSelected({ contract, assure, period, avenant, packSelected: { vehicle, packResponse } }) {
        //const {vehicle, packResponse}=packSelected;
        return {
            calculateGuarantee: true,
            contractRef: contract?.ref,

            packLineRef: packResponse?.packLineRef,
            avenantRef: avenant?.ref,
            productRef: constants.PRODUCT_AUTO_REF,
            //customerPeriod: true,
            categoryRef: packResponse?.category?.ref,

            cityRef: vehicle?.city?.ref,
            energyRef: vehicle?.energy?.ref,

            periodRef: period?.ref,
            customerStartDate: period?.customerStartDate,
            customerEndDate: period?.customerEndDate,
            prorate: period?.prorate,
            
            matriculation: vehicle?.matriculation,
            totalPlace: vehicle?.totalPlace,
            fiscalPower: vehicle?.fiscalPower,
            dateMec: vehicle?.dateMec,

            newValue: vehicle?.newValue,
            marketValue: vehicle?.marketValue,

            socialPosRef: assure?.socialPosRef,

            hasGroup: vehicle?.hasGroup,
            groupRef: vehicle?.groupementRef,

            hasGps: vehicle?.hasGps,
            isGood: false,
            goodWeight: 0,

            //driverBirthDay: newFormFields?.driverBirthDay,
            //driverLicDeliveryDate: newFormFields?.driverLicDeliverDate,

            customGuaranties: packResponse?.customGuaranties,
            reductionGroups: packResponse?.reductionGroups
        }
    }
}

