import { FileDoneOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import CustomAntdForm from "modules/components/Forms/CustomAntdForm";
import React from "react";

const PaymentByBanque=()=> {
    const form = useForm;
    return (
        <React.Fragment>
            <Button
                    style={{ color: '#ffffff', borderRadius: 8, borderColor: '#9db7b4', backgroundColor: '#9db7b4', marginRight: 10, marginTop: 21 }}
                    onClick={() => {}}
                    disabled={true}
                >
                    <FileDoneOutlined style={{ color: '#ffffff', fontSize: 20 }} /> PRELEVEMENT BANCAIRE
                </Button>

            <Modal
                footer={[
                    <div>
                        <Button >
                            Annuler
                        </Button>
                        <Button type='primary'>
                            Valider
                        </Button>
                    </div>
                ]}
                onOk={() => ''}
                onCancel={() => ''}
                visible={false}
                title='Prelevement bancaire'
            >
                <CustomAntdForm form={form}>
                    <Row gutter={[8, 4]}>
                        <Col className="gutter-row" xs={24} md={24}>
                            <Form.Item
                                label="Nom"
                                name="name"
                                rules={[{ required: true, message: "Champ obligatoire" }]}
                            >
                                <Input placeholder='Nom du proprietaire du compte' size='large' />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={24} md={24}>
                            <Form.Item
                                label="Prenom"
                                name="lastName"
                                rules={[{ required: true, message: "Champ obligatoire" }]}
                            >
                                <Input placeholder='Prenom du proprietaire du compte' size='large' />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={24} md={24}>
                            <Form.Item
                                label="Telephone mobile"
                                name="mobile"
                                rules={[{ required: true, message: "Champ obligatoire" }]}
                            >
                                <Input placeholder='Numero de telephone du proprietaire du compte' size='large' />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={24} md={24}>
                            <Form.Item
                                label="RIB"
                                name="rib"
                                rules={[{ required: true, message: "Champ obligatoire" }]}
                            >
                                <Input placeholder='RIB du proprietaire du compte' size='large' />
                            </Form.Item>
                        </Col>
                    </Row>

                </CustomAntdForm>
            </Modal>
        </React.Fragment>
    )
}

export default PaymentByBanque;