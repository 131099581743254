import React, { useState } from 'react'

import { Divider, /* Modal, */ Button } from 'antd';
import SweetAlert from 'react-bootstrap-sweetalert'

import DescriptionItem from 'modules/components/DescriptionItem'


export function ValidationModal(props) {
  const { title, visible, handleCancel, handleOk, confirmLoading, data, descriptionItemTitleWidth, confirmBtnText } = props;

  if (!visible) return null;

  return (
    <SweetAlert
      warning
      showCancel
      confirmBtnText={confirmBtnText || "Oui, je valide"}
      confirmBtnBsStyle="danger"
      title={<span style={{ fontSize: 20 }}>{title || "Êtes-vous sûr de valider cette opération?"}</span>}
      onConfirm={handleOk}
      onCancel={handleCancel}
      customButtons={
        <>
          <Button onFocus disabled={confirmLoading} size="large" onClick={handleCancel}>Annuler</Button>
          <div style={{ width: 20 }} />
          <Button loading={confirmLoading} type="primary" size="large" onClick={handleOk}>{confirmBtnText || "Oui, je valide"}</Button>
        </>
      }
    >
      <Divider />

      {data?.length > 0 && (
        <>
          {data.map((item, index) => (
            <DescriptionItem key={index} titleWidth={descriptionItemTitleWidth} title={item.label} content={item.content || item.value} />
          ))}

          {/* <Divider /> */}
        </>
      )}

      <div style={{ color: 'red', fontSize: 20 }}>
        Vous ne pouvez pas revenir en arrière après validation!
      </div>

    </SweetAlert>
  );

  // return (
  //   <>
  //     <Modal
  //       title={title || "Validation"}
  //       visible={visible}
  //       onOk={handleOk}
  //       okText="Oui"
  //       cancelText="Non"
  //       confirmLoading={confirmLoading}
  //       onCancel={handleCancel}
  //       centered
  //     >
  //       {data?.length > 0 && (
  //         <>
  //           {data.map((item, index) => (
  //             <DescriptionItem key={index} titleWidth={descriptionItemTitleWidth} title={item.label} content={item.content || item.value} />
  //           ))}

  //           <Divider />
  //         </>
  //       )}

  //       <div style={{ color: 'red', fontSize: 20 }}>
  //         Êtes-vous sûr de valider cette opération?
  //       </div>
  //     </Modal>
  //   </>
  // );
}

export function useValidationModal() {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  function handleCancel() {
    if (!confirmLoading) {
      setVisible(false)
    }
  }

  return {
    validationModalVisible: visible,
    validationModalHandleCancel: handleCancel,
    validationModalSetConfirmLoading: setConfirmLoading,
    validationModalSetVisible: setVisible,
    validationModalConfirmLoading: confirmLoading,
  }
}

