export default class FormEditExtra {
    id;
    code;
    description;

    fromJson = (json = {}) => {
        var formEditExtra = new FormEditExtra();
        formEditExtra = json;
        return formEditExtra;
    }
}