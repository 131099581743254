import { Table } from "antd";
import React from "react";
import { Col, Row } from "reactstrap";
import { thousandSeparator } from "helpers/helpers";


const FDetailListVehicle=({autos})=> {

    
    const columns = [
        
        {
            title: 'Marque',
            dataIndex: 'brand',
            key: 'brand',
            width: 150,
            render: (index, auto)=> (<div>{auto?.vehicle?.model?.brand?.title}</div>)
        }, 
        {
            title: 'Modèle',
            dataIndex: 'model',
            key: 'model',
            width: 150,
            render: (index, auto)=> <div>{auto?.vehicle?.model?.title}</div>
        },
        
        {
            title: 'Matricule',
            dataIndex: 'matriculation',
            render: (index, auto)=> <div>{auto?.vehicle?.matriculation}</div>
        },
        {
            title: 'N° chassis',
            dataIndex: 'chassisNumber',
            key: 'chassisNumber',
            render: (index, auto)=> <div>{auto?.vehicle?.chassisNumber}</div>
        },
        {
            title: 'Transport',
            dataIndex: 'transportTitle',
            key: 'transportTitle',
            render: (index, auto)=> <div>{auto?.vehicle?.transport?.title}</div>
        },
        {
            title: 'Places',
            dataIndex: 'totalPlace',
            key: 'totalPlace',
            render: (index, auto)=> <div>{auto?.vehicle?.totalPlace}</div>
        },
        {
            title: 'Puiss. fiscale',
            dataIndex: 'fiscalPower',
            key: 'fiscalPower',
            render: (index, auto)=> <div>{auto?.vehicle?.fiscalPower} Cv</div>
        },

        {
            title: 'Valeur neuve',
            dataIndex: 'newValue',
            fixed: 'right',
            key: 'newValue',
            render: (index, auto)=> <div>{thousandSeparator(auto?.vehicle?.newValue)}</div>
        },

        {
            title: 'Valeur venale',
            dataIndex: 'marketValue',
            fixed: 'right',
            key: 'marketValue',
            render: (index, auto)=> <div>{thousandSeparator(auto?.vehicle?.marketValue)}</div>
        },

        {
            title: 'Ville de circulation',
            dataIndex: 'cityTitle',
            fixed: 'right',
            key: 'cityTitle',
            render: (index, auto)=> (
                <div>{auto?.vehicle?.city?.title}</div>
            )
        },

        /* {
            title: 'Zone de circulation',
            dataIndex: 'formField',
            key: 'cityTitle',
            render: (formField, basket)=> (
                <div>{"Cotonou"}</div>
            )
        }, */
    ];

    return(
        <React.Fragment>
           
            <Row>

                <Col lg={12}>
                    <Table
                        //loading={isFlotteQuotationLoading}
                        columns= {columns}
                        dataSource= {autos || []}
                        scroll={{
                            x: 1300,
                        }}
                        //pagination= {{ position: ["topRight"], pageSize: pageSize ? pageSize : 10}}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default FDetailListVehicle;