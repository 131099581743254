import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class ReductionService {

    createReduction(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.createReductionResource, payload, sCallBack, eCallBack)
    }

    editReduction(options, payload, sCallBack, eCallBack) {
        ApiRoutes.editReductionResource.resourceOptions = Object.assign({}, ApiRoutes.editReductionResource.resourceOptions, options);
        exeRequest(ApiRoutes.editReductionResource, payload, sCallBack, eCallBack);
    }

    static readAllReductions(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.reductionResource, {}, sCallBack, eCallBack)
    }

    // REDUCTION GROUP
    static readAllReductionsGroup(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.reductionsGroupResource, {}, sCallBack, eCallBack)
    }
}