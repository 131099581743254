import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(1),
    marginTop: 'auto',
    color: 'white'
  },
}));

export default function Footer({ companyName, theme }) {
  const classes = useStyles();

  return (
    <footer className={classes.footer} style={{backgroundColor: theme.footer.backgroundColor}}>
      <Container maxWidth="lg">
        <Typography variant="body2" color="inherit" align="center">
          {' © Copyright '}
          {new Date().getFullYear()}{" "}
          {companyName}
          {' | '}
          {'Tous droits reservés'}
        </Typography>
      </Container>
    </footer>
  );
}