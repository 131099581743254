import { LoadingOutlined } from "@ant-design/icons";
import { Select } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import React, { useState } from "react";
import { toast } from "react-toastify";
import PackageService from "services/packageService";

const CopyPackGuarantee=({category, currentPack, categoryPacks, refreshPacksAndGuaranties})=> {
    const Option = Select;

    const [state, setState] = useState({
        isCategoryPackGuaranteeCopying: false,
        categotyPackSelected: null
    })

    const copyCatgoryPack=()=> {
        const options = {
            path: {
                categoryId: category?.id,
                sourcePackId: state?.categotyPackSelected?.id,
                targetPackId: currentPack?.id
            }
        }
        PackageService.copyPackGuarantee(options,(data)=> {
            console.log(":::: data ", data)
            toast.success("Le pack a été ajouté avec succès")
            if(refreshPacksAndGuaranties) refreshPacksAndGuaranties(category);
        }, (error)=> {
            toast.error("Echec de copie de pack")
        })
    }

    /* const addGuarantee2Pack=(packSelectedRef)=> {
        const options = {
            path: { 
                categoryRef: category?.ref,
                packRef: packSelectedRef,
                guaranteeRef: state?.packOthersGuarantiesSelected?.ref
            }
        }

        CategoryService.attachPackGuarantee(options, (data)=> {
            toast.success("La garantie a été ajoutée avec succès")
            refreshPacksAndGuaranties(category)
        }, (error)=> {
            toast.error("Echec d'enregistrement de guarantee")
        })
    }*/

    const onCategotyPackSelected=(categoryPackRef)=> {
        console.log(":::: categoryPackRef :", categoryPackRef)
        const _categotyPackSelected = categoryPacks.find((categoryPack)=> categoryPack?.ref === categoryPackRef);
        setState(state=> ({...state, categotyPackSelected: _categotyPackSelected}))
    } 

    return (
        <React.Fragment>
            <div className="d-flex item-align-end flex-row" >
                <div style={{width: "100%"}}>
                    <SelectWithFilter style={{ width: '100%' }} placeholder='Selectionner une garantie' onChange={(e)=> onCategotyPackSelected(e)} size="medium">
                        {(categoryPacks || []).filter((categoryPack)=> categoryPack?.ref !== currentPack?.ref).map((pack, index) => (<Option value={pack?.ref} key={index}>{pack?.title}</Option>))}
                    </SelectWithFilter>
                </div>
                <div style={{marginLeft: 5, height: "33px"}}>
                    <button
                        type="button"
                        className="btn btn-primary btn-label right nexttab btn-sm"
                        style={{width: "100%"}}
                        onClick={() => copyCatgoryPack()}
                        onLoad
                    >
                        <i className="bx bx-plus label-icon align-middle fs-16 ms-2"></i>{" "}
                        {state.isPackGuaranteeCopying ? (<LoadingOutlined style={{ color: '#ffffff', fontSize: 20 }} />) : (<></>)}
                        Ajouter
                    </button>
                </div>
            </div> 
        </React.Fragment>
    )
}

export default CopyPackGuarantee;