import React, { useState } from 'react'
import { Form, Card, Button, Modal, Row, Col, Input } from 'antd'
import CustomAntdForm from 'modules/components/Forms/CustomAntdForm';
import DetailsListVehicle from './DetailsListVehicle';
import Payment from 'views/pages/subscription/component/payment/Payment';
import FDetailsHeader from '../../../../common/FDetailsHeader';
import FDetailsContract from '../../../../common/FDetailsContract';
import ReturnNewTitle from 'views/pages/common/ReturnNewTitle';
import FDetailsAssure from '../../../../common/FDetailsAssure';
import FDetailsReductionAndPrime from '../../../../common/FDetailsReductionAndPrimes';
import { ContractType } from 'views/pages/subscription/component/ComponentWidget';

export default function DetailsParcoursFlotte(props) {
    const { flotteBasket, goToNextStep, goToPreviousStep } = props
    const { flotteSubscriptionRequest, flotteExtras, period, baskets } = flotteBasket

    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);

    function hideModal(value) {
        setOpen(value)
    }

    const successStatus = (transactionResponse) => {
        console.log("STATE PROJET", transactionResponse)
        goToNextStep([{ path: ['transactionResponse'], value: transactionResponse }])
    }

    return (
        <>
            <CustomAntdForm form={form}>
                <Card className='fistCard'>
                    <FDetailsHeader primeTTC={flotteSubscriptionRequest?.primeTTC} />
                    {ReturnNewTitle('Informations sur le vehicule')}
                    <DetailsListVehicle flotteBasket={flotteBasket} />
                    <FDetailsAssure assure={flotteSubscriptionRequest?.assure} />
                    <FDetailsContract flotteSubscriptionRequest={flotteSubscriptionRequest} period={period} />
                    <FDetailsReductionAndPrime flotteSubscriptionRequest={flotteSubscriptionRequest} extras={flotteExtras} />
                </Card>

                <Card>
                    <Payment
                        projectVisibility={true}
                        onlineVisiblity={true}
                        payload={flotteSubscriptionRequest}
                        successStatus={successStatus}
                        goBack={() => goToPreviousStep()}
                        type={ContractType.FLOTTE}
                    />
                </Card>

                <Modal
                    footer={[
                        <div>
                            <Button onClick={() => hideModal(false)}>
                                Annuler
                            </Button>
                            <Button type='primary'>
                                Valider
                            </Button>
                        </div>
                    ]}
                    onOk={() => hideModal(false)}
                    onCancel={() => hideModal(false)}
                    visible={open}
                    title='Prelevement bancaire'
                >
                    <CustomAntdForm form={form}>
                        <Row gutter={[8, 4]}>
                            <Col className="gutter-row" xs={24} md={24}>
                                <Form.Item
                                    label="Nom"
                                    name="name"
                                    rules={[{ required: true, message: "Champ obligatoire" }]}
                                >
                                    <Input placeholder='Nom du proprietaire du compte' size='large' />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={24} md={24}>
                                <Form.Item
                                    label="Prenom"
                                    name="lastName"
                                    rules={[{ required: true, message: "Champ obligatoire" }]}
                                >
                                    <Input placeholder='Prenom du proprietaire du compte' size='large' />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={24} md={24}>
                                <Form.Item
                                    label="Telephone mobile"
                                    name="mobile"
                                    rules={[{ required: true, message: "Champ obligatoire" }]}
                                >
                                    <Input placeholder='Numero de telephone du proprietaire du compte' size='large' />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={24} md={24}>
                                <Form.Item
                                    label="RIB"
                                    name="rib"
                                    rules={[{ required: true, message: "Champ obligatoire" }]}
                                >
                                    <Input placeholder='RIB du proprietaire du compte' size='large' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </CustomAntdForm>
                </Modal>
            </CustomAntdForm >
        </>
    )
}
