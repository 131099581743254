import { DownOutlined, EyeOutlined, FileAddOutlined, FileFilled, FileOutlined, HomeOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { Modal, Select, Table, Tag, Spin, Form, Input, Space, Dropdown, Menu, Collapse, Switch, Popconfirm } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DocumentType, onDocumentOpen, splitDate, thousandSeparator } from "helpers/helpers";
import { Button, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import {Card  as AntCard } from "antd";
import useDetailsSubscription from "./useDetailsSubscription";
import * as constants from 'constantes';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { sRaz } from "redux/slices/subscriptionSlice";
import { basketRaz } from "redux/slices/basketSlice";
import { CAN_PRODUCT_FOR_OTHER, CAN_TOOGLE_SUBSCRIPTION_STATUS } from "profileActions";
import DetailsSubscription from "./component/DetailsSubscription";
import ContractService from "services/contractService";
import { toast } from "react-toastify";
import { fullFormatDate } from "helpers/date_helpers";
import ModalGenerateAttestation from "./component/ModalGenerateAttestation";

export default function DetailsSouscription(props) {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const Option = Select;
    const {
        state,
        setState,
        readAssureSubscriptions,
        onMainAttestationGenerate,
        onAvenantSelected,
        onDetailsAvenantSelected,
        onAvenantSelectedSubmit,
        onAvenantClick,
        form } = useDetailsSubscription();
    const [attestInitialValue, setAttestInitialValue] = useState(null);
    const [subscriptionSelected, setSubscriptionSelected] = useState(null);

    useEffect(() => {
        dispatch(sRaz())
        dispatch(basketRaz())
    }, [])

    useEffect(()=> {
        console.log(state?.isAttestationModalVisible)
    }, [state?.isAttestationModalVisible])

    const toggleSubscriptionStatus = (subscription)=> {
        setState(state=> ({...state, togglePaiementStatusActivating: true}))
        const options = {
            path: {subscriptionRef: subscription?.ref }
        }
        ContractService.toggleSubscriptionPaiementStatus(options, (data)=> {
            console.log(":::: data" ,data)
            readAssureSubscriptions()
            setState(state=> ({...state, togglePaiementStatusActivating: false}))
        }, (error)=> {
            toast.error(error)
            setState(state=> ({...state, togglePaiementStatusActivating: false}))
        }); 
    }

    const onCloseDetailAvenant=(refresh)=> {
        setState(state => ({ 
            ...state, 
            drawerContractDetailVisibility: false 
        })); 

        if(refresh) {
            readAssureSubscriptions();
        }
            
    }

    const openAttestModal=(data, subscription, open=true)=> {
        setState(state=> ({...state, subscriptionSelected: subscription, isAttestationModalVisible: true }))
        console.log(":::: subscription :", subscription)
        setSubscriptionSelected(subscription)
        setAttestInitialValue(data)
        //setIsAttestationModalVisible(open)
        
    }

    const avenantColumns = [
        {
            title: 'Type',
            dataIndex: 'avenantTitle',
            key: 'avenantTitle',
            width: 250,
        },

        {
            title: 'Assuré',
            dataIndex: 'assure',
            key: 'assure',
            width: 250,
            render: (item, subscription) => {
                return subscription?.assureFirstname + ' ' + subscription?.assureLastname;
            }
        },
        {
            title: "Numéro d'ordre",
            dataIndex: 'numAvenant',
            key: 'numAvenant',
            width: 150,
            render: (index, subscription) => {
                return   subscription?.avenantCode !== "0" ? (<Tag color='green'>{((index === 1) ? (index + "er ") : (index + "ième ")) + "Avenant "}</Tag>) : "-";
            }
        },
        {
            title: 'Apporteur',
            dataIndex: 'merchant',
            key: 'merchant',
            width: 150,
            align: 'center',
            render:(index, subscription)=> {
                return subscription?.merchantCode ? (<div>{subscription?.merchantFirstname} {subscription?.merchantLastname}</div>) : "-"
            }
        },
        {
            title: "Package",
            dataIndex: 'packTitle',
            key: 'packTitle',
            width: 150,
            render: (index, subscription) => {
                return <div>{subscription?.packTitle || '-'}</div>
            }
        },
        
        {
            title: 'Total vehicule',
            dataIndex: 'totalVehicle',
            key: 'totalVehicle',
            fixed: 'right',
            align: 'center',
            width: 100,
            render: (item, subscription) => subscription?.totalVehicle
        },
        {
            title: 'Souscrit le',
            dataIndex: 'date',
            key: 'date',
            fixed: 'right',
            width: 100,
            render: (item, subscription) => fullFormatDate(subscription?.createdAt)
        },
        {
            title: "Date d'effet",
            dataIndex: 'startDate',
            key: 'startDate',
            fixed: 'right',
            align: 'left',
            width: 100,
            render: (item, subscription) => fullFormatDate(subscription?.startDate)
        },
        {
            title: "Echéance",
            dataIndex: 'endDate',
            key: 'endDate',
            fixed: 'right',
            width: 100,
            align: 'left',
            render: (item, subscription) => fullFormatDate(subscription?.endDate)
        },
        /* {
            title: "Souscripteur",
            dataIndex: 'endDate',
            key: 'endDate',
            with: 100,
            render: (item, subscription) => (
                <div>
                    {new Date(subscription?.endDate).toLocaleDateString()}
                </div>
            )
        }, */

        {
            title: "Prime TTC",
            dataIndex: 'primeTTC',
            key: 'primeTTC',
            fixed: 'right',
            width: 150,
            align: 'right',
            render: (item, subscription) => {
                return <Tag color='green'>{thousandSeparator(subscription?.primeTTC) + ' FCFA'}</Tag>;
            }
        },
        {
            title: "Attestation",
            dataIndex: 'attestationNumber',
            key: 'attestationNumber',
            align: 'center',
            fixed: 'right',
            width: 200,
            render: (item, subscription) => {
                return (
                    <>
                        {subscription.applyAttestation && subscription?.isPaid ? (
                            <>
                                {subscription.avenantCode !== constants.CODE_AVENANTS.SUSPENSION.toString() ? (
                                    <>
                                        <Space size="middle">
                                            { subscription?.attestSerialNumber != null && subscription?.attestSerialStatus !== null && subscription?.attestSerialStatus === 'USED' ? 
                                                
                                                (<Dropdown overlay={(
                                                        <Menu items={[
                                                            { key: '1', label: <div style={{ color: '#3573DF', cursor: 'pointer' }} onClick={() => onDocumentOpen(DocumentType.JAUNE, getDocumentDir(subscription, "centrale"))} > Centrale</div> },
                                                            { key: '2', label: <div style={{ color: '#35DF8D', cursor: 'pointer' }} onClick={() => onDocumentOpen(DocumentType.JAUNE, getDocumentDir(subscription, "local"))}>Locale</div> },
                                                        ]}/>
                                                    )}><a onClick={(e) => e.preventDefault()}> <FileFilled style={{ color: ['403', '404']?.includes(subscription?.categoryCode) ? 'orange' : ['405']?.includes(subscription?.categoryCode) ? 'green': '#FFF3C7' } } /> {['403', '404']?.includes(subscription?.categoryCode) ? 'ORANGE' : ['405']?.includes(subscription?.categoryCode) ? 'VERTE': 'JAUNE' } <DownOutlined /></a>
                                                </Dropdown>) :
                                                (<div style={{ color: '#47C8DF', cursor: 'pointer' }} onClick={() => openAttestModal({ natureCode: ['403', '404']?.includes(subscription?.categoryCode) ? 'ORG' : ['405']?.includes(subscription?.categoryCode) ? 'VRT': 'JAN' }, subscription)}>
                                                <FileFilled style={{ color: ['403', '404']?.includes(subscription?.categoryCode) ? 'orange' : ['405']?.includes(subscription?.categoryCode) ? 'green': '#FFF3C7' }} /> {['403', '404']?.includes(subscription?.categoryCode) ? 'ORANGE' : ['405']?.includes(subscription?.categoryCode) ? 'VERTE': 'JAUNE' }</div>)
                                            }

                                            {subscription?.attestSerialNumberCedeao != null && subscription?.attestSerialStatus !== null && subscription?.attestSerialStatus === 'USED' ? 
                                                (<Dropdown overlay={(
                                                    <Menu items={[
                                                        { key: '1', label: (<div style={{ color: '#3573DF', cursor: 'pointer' }} onClick={() => onDocumentOpen(DocumentType.CEDEAO, getDocumentDir(subscription, "centrale"))} >Centrale</div>) },
                                                        { key: '2', label: (<div style={{ color: '#35DF8D', cursor: 'pointer' }} onClick={() => onDocumentOpen(DocumentType.CEDEAO, getDocumentDir(subscription, "local"))}> Locale</div>) },
                                                    ]}/>
                                                )}><a onClick={(e) => e.preventDefault()}><FileFilled style={{ color: 'gray' /*#35DF8D */}} /> CEDEAO <DownOutlined /></a>
                                                </Dropdown>)  :
                                                (<div style={{ color: '#47C8DF', cursor: 'pointer' }} onClick={() => openAttestModal({ natureCode: 'CED' }, subscription)}>
                                                    <FileFilled style={{ color: 'gray' /*#35DF8D */}} /> CEDEAO</div>) 
                                            }
                                        </Space>
                                        { (subscription?.attestSerialNumber !== null || subscription?.attestSerialNumberCedeao !== null) && CAN_PRODUCT_FOR_OTHER.includes(user.profile) && (
                                            <div style={{ color: '#47C8DF', cursor: 'pointer', textAlign: 'center' }} onClick={() => openAttestModal(null, subscription)}>
                                                <UploadOutlined style={{ color: '#47C8DF' }} /> Regenerer 
                                            </div>
                                        )}
                                    </>
                                ) : (" - ")}
                            </>
                        ) : subscription.applyAttestation ? (<span>Disponible après paiement</span>): "-"}
                    </>
                );
            }
        },

        {
            title: "Actions / Doc.",
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 150,
            align: 'left',
            render: (item, subscription) => {
                return (
                    <>
                        <div style={{ color: '#3573DF', cursor: 'pointer', marginBottom: 4 }} >
                            <Tag color='green' style={{ width: 120 }} onClick={() => { setState(state => ({ ...state, drawerContractDetailVisibility: true })); onDetailsAvenantSelected(subscription) }}><EyeOutlined style={{ color: '#3573DF' }} /> Voir details</Tag>
                        </div>
                        <div style={{ color: '#3573DF', cursor: 'pointer', marginBottom: 4 }} >
                            <Dropdown overlay={(
                                <Menu
                                    items={[
                                        {
                                            key: '1',
                                            label: <div style={{ color: '#3573DF', cursor: 'pointer' }} onClick={() => onDocumentOpen(DocumentType.FACTURE, `${subscription?.contract?.policeNumber?.replace("/", "_")}_${subscription.id}.pdf`)}>
                                                <FileFilled style={{ color: '#3573DF' }} /> Facture
                                            </div>
                                        },
                                        {
                                            key: '2',
                                            label: <div style={{ color: '#3573DF', cursor: 'pointer' }} onClick={() => onDocumentOpen(DocumentType.CP, `${subscription?.contract?.policeNumber?.replace("/", "_")}_${subscription.id}.pdf`)}>
                                                <FileFilled style={{ color: '#3573DF' }} /> Cond. part.
                                            </div>
                                        },
                                        subscription?.categoryCode === '400' ? {
                                            key: '3',
                                            label: <div style={{ color: '#3573DF', cursor: 'pointer' }} onClick={() => onDocumentOpen(DocumentType.CP, `annexe_${subscription?.contract?.policeNumber?.replace("/", "_")}_${subscription.id}.pdf`)}>
                                                <FileFilled style={{ color: '#3573DF' }} /> Annexe
                                            </div>
                                        } : null,
                                        {
                                            key: '4',
                                            label: <div style={{ color: '#3573DF', cursor: 'pointer' }} onClick={() => onDocumentOpen(DocumentType.CG, `${DocumentType.CG}_auto.pdf`)}>
                                                <FileFilled style={{ color: '#3573DF' }} /> Cond. générale
                                            </div>
                                        },
                                    ]}
                                />
                            )}>
                                <Tag color='green' style={{ width: 120 }} ><FileFilled style={{ color: '#3573DF' }} /> Documents <DownOutlined /></Tag>
                            </Dropdown>
                        </div>
                    </>
                );
            }
        },

        {
            title: "Status",
            dataIndex: 'status_payment',
            key: 'status_payment',
            fixed: 'right',
            width: 100,
            align: 'left',
            render: (item, subscription) => {
                return (
                    <Popconfirm
                        placement="top"
                        title="Voulez-vous vraiment confirmer le paiement ?"
                        cancelText="Annuler"
                        okText="Confirmer"
                        overlayStyle={{width: 300}}
                        onConfirm={()=> toggleSubscriptionStatus(subscription)}
                    >
                        <Switch 
                            disabled={subscription?.isPaid && !CAN_TOOGLE_SUBSCRIPTION_STATUS.includes(user.profile) }
                            defaultChecked={subscription?.isPaid}
                            loading={state?.togglePaiementStatusActivating} 
                            unCheckedChildren={"Impayer"} 
                            checkedChildren={"Payé"} 
                            size="medium"
                            />
                    </Popconfirm>
                );
            }
        }
        
    ];

    const getDocumentDir=(subscription, point)=> {
        if(subscription?.avenantCode == '12') 
            return `duplicata_${point}_${subscription?.contract?.policeNumber?.replace("/", "_")}_${subscription.id}.pdf`
        else
            return `${point}_${subscription?.contract?.policeNumber?.replace("/", "_")}_${subscription.id}.pdf`
    }

    if(state?.isSubscriptionsLoading) {
        return (<AntCard>
            <Spin /> Connexion au server veuillez patienter s'il vous plait ...
        </AntCard>);    
    }

    return (
        <div>
            <Row className="mb-3 pb-1">
                <Col xs={12}>
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                        <div className="flex-grow-1">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Link to='/souscription/401'>
                                    <HomeOutlined style={{ fontSize: 25, color: '#006699', cursor: 'pointer' }} />
                                </Link>
                                <h3 style={{ color: '#006699', fontWeight: 'bold' }}>*** POLICE ***</h3>

                                <div>
                                    {
                                        !(["CONTRACT_RESCIND", "CONTRACT_CANCEL"].includes(state?.baseSubscription?.contract?.statusMessage)) ? (
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm  btn-label right nexttab"
                                                onClick={() => { onAvenantClick() }}
                                                onLoad
                                            >
                                                <i className="bx bx-plus  label-icon align-middle fs-16 ms-2"></i>{" "}
                                                {state.isAvenantLoading && state.isCategoryLoading ? (<LoadingOutlined style={{ color: '#ffffff', fontSize: 20 }} />) : (<FileAddOutlined style={{ color: '#ffffff', fontSize: 20 }} />)}
                                                 NOUVEL AVENANT
                                            </button>
                                        ) : null
                                    }
                                </div>

                            </div >
                        </div >
                    </div >
                </Col >
            </Row >

            <Card>
                <CardHeader>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4 style={{ paddingLeft: 10, paddingBottom: 5, width: 500 }}>{(`SOUSCRIPTION`).toLocaleUpperCase()}</h4>
                        <div >
                            <h5 style={{ color: 'grey' }}> Total vehicule : <Tag color="rgb(0, 117, 201)">{state?.baseSubscription?.contract?.nbVehicle}</Tag></h5>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>    
                    <div style={{ border: '1px solid #E1E6E7', padding: 20, borderRadius: 5 }}>
                    
                        <Row>
                            <Col md={4}>
                                <div style={{ display: 'flex', width: '90%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h5 style={{ color: 'grey' }}>Numero de police : <span style={{ color: 'rgb(82, 196, 26)', fontWeight: 'bold' }}>{state?.baseSubscription?.contract?.policeNumber}</span></h5>
                                </div>
                            </Col>

                            <Col md={4}>
                                <div style={{ display: 'flex', width: '90%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h5 style={{ color: 'grey', marginRight: 10 }}>Partenaire : <span style={{ color: '#808384', fontWeight: 'bold' }}>{state?.baseSubscription?.entitiesTitle}</span> </h5>
                                </div>
                            </Col>

                            <Col md={4}>
                                <div style={{ display: 'flex', width: '90%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h5 style={{ color: 'grey' }}>Souscripteur : <span style={{ color: '#808384', fontWeight: 'bold' }}>{state?.baseSubscription?.assure?.firstName + " " + state?.baseSubscription?.assure?.lastName}</span></h5>
                                </div>
                            </Col>

                            <Col md={4}>
                                <div style={{ display: 'flex', width: '90%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h5 style={{ color: 'grey' }}>Produit : <span style={{ color: '#808384', fontWeight: 'bold' }}>{state?.baseSubscription?.productTitle}</span></h5>
                                </div>
                            </Col>

                            <Col md={4}>
                                <div style={{ display: 'flex', width: '90%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h5 style={{ color: 'grey' }}>Categorie : <span style={{ color: '#808384', fontWeight: 'bold' }}>{state?.baseSubscription?.categoryCode} {
                                        state?.baseSubscription?.categoryTitle.length > 30 ?
                                            state?.baseSubscription?.categoryTitle.substring(0, 25) + '...' :
                                            state?.baseSubscription?.categoryTitle
                                    }</span></h5>
                                </div>
                            </Col>

                            <Col md={4}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                                    <h5 style={{ color: 'grey' }}>Date de souscription : <span style={{ color: '#808384', fontWeight: 'bold' }}>{(new Date(state?.baseSubscription?.createdAt).toLocaleDateString())}</span></h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>

            {state.subscriptions.length !== 0 ? (
                    <Card>
                        <CardHeader style={{paddingBottom: 0}}>
                            <div>
                                <h5 style={{ color: 'grey', paddingLeft: 10, paddingBottom: 5, width: 500 }}>{(`AVENANTS`).toLocaleUpperCase()}</h5>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Table
                                    columns={avenantColumns}
                                    pagination={{ position: ["bottomRight"], pageSize: 10 }}
                                    dataSource={state.subscriptions}
                                    loading={state.isSubscriptionsLoading || state?.togglePaiementStatusActivating}
                                    scroll={{ x: 1400 }}
                                    key="avenantTable"
                                />
                            </div>
                        </CardBody>
                    </Card>
                ) : null
            }
            
            <Card>
                <CardHeader style={{paddingBottom: 0}}>
                    <div><h4 style={{ color: 'grey' }}>{(`AFFAIRE NOUVELLE`).toLocaleUpperCase()}</h4></div>
                </CardHeader>
                <CardBody>
                    <Table
                        pagination={false}
                        columns={avenantColumns}
                        dataSource={state.baseSubscription ? [state.baseSubscription] : []}
                        loading={state.isSubscriptionsLoading || state?.togglePaiementStatusActivating}
                        scroll={{ x: 1400 }}
                        key="nouvelleAffaireTable"
                    />
                </CardBody>
            </Card>


            {/* ------------DRAWERS DETAILS CONTRACT------------- */}
            { state?.subscriptionSelected && 
                <DetailsSubscription 
                    subscriptionSelected={state?.subscriptionSelected} 
                    visibility={state.drawerContractDetailVisibility} 
                    onClose={onCloseDetailAvenant}/>
            }
          
            {/* -------------MODALS AVENANT--------------- */}
            <Modal
                title="Avenants"
                visible={state.isAvenantModalVisible}
                centered
                onOk={() => onAvenantSelectedSubmit()}
                onCancel={() => setState(state => ({ ...state, isAvenantModalVisible: false }))}>

                {
                    !state.isAvenantLoading ? (
                        <Select style={{ width: '100%' }} placeholder='Selectionner un avenant' onChange={onAvenantSelected}>
                            {
                                state.avenants.map((avenant, index) => {
                                    return (<Option value={avenant.ref} key={index}>{avenant.title}</Option>)
                                })
                            }
                        </Select>
                    ) : (<Spin />)
                }
            </Modal>

            {/* ------------MOAL GENERTION ATTESTATION------------- */}
            {state?.isAttestationModalVisible && 
                (<ModalGenerateAttestation 
                    subscriptionSelected={subscriptionSelected}
                    initialValues={attestInitialValue}
                    onCancel={()=> setState(state=> ({...state, isAttestationModalVisible: false}))}
                    onOk={()=> readAssureSubscriptions()}/>)}
            

            {/* ------------MODAL GENERTION MAIN ATTESTATION------------- */}
            <Modal
                title="Generation d'attestation"
                visible={state.visible}
                centered
                onOk={() => { }}
                onCancel={() => setState(state => ({ ...state, visible: false }))}
                footer={[]}
            // okText="Valider"
            // cancelText="Annuler"
            >
                <Form
                    name="basic"
                    onFinish={onMainAttestationGenerate}
                    layout="vertical"
                    form={form}
                >
                    <Row gutter={8} >
                        
                        <Col className="gutter-row" xs={24} md={24}>
                            <Form.Item
                                label="Numero d'attestations"
                                name="attestation"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Entrez le numero d\'attestation svp !',
                                    },
                                ]}
                            >
                                <Input placeholder="Numero d'attestation" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        <span style={{ marginLeft: 8, color: "red" }}> {state.attestationGenerateError} </span>
                        <Button
                            style={{marginRight: 8}}
                            onClick={() => setState(state => ({ ...state, isAttestationModalVisible: false }))}
                        >
                            Annuler
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={state.isAttestationLoading}
                        >
                            Valider
                        </Button>
                    </div>
                </Form>

                <div id="errorMessage" style={{ border: '1px solid #ffa39e', backgroundColor: '#fff1f0', borderRadius: 5, marginTop: -10, display: 'none' }}>
                    <p style={{ textAlign: "center" }} >Numero d'attestation non trouvé !!</p>
                </div><br />
            </Modal>

        </div >
    )
}