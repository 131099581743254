import { LeftOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import React, { useState } from "react";
import PaymentByBanque from "./PaymentByBanque";
import PaymentEspece from "./PaymentEspece";
import PaymentOnline from "./PaymentOnline";
import ProjectConfirmation from "./ProjectConfirmation";
import { useBasket } from "../../hooks/useBasket";
import { toast } from "react-toastify";

const Payment = ({ payload, goBack, type, projectVisibility, onlineVisiblity, backVisibility }) => {
    const {goToNextStep} = useBasket();
    const [state, setState]=useState({
        paymentStatus: null
    })

    const paymentStatusHandler=(status)=> {
        if(status?.error) {
            setState(state=> ({...state, paymentStatus: status}))
            toast.error(status?.errorMessage);
            return;
        }
        //console.log(":::: paymentStatusHandler Gooooo nex")
        goToNextStep([
            { path: ['paymentStatus'], value: {error: status?.error, errorMessage: status?.errorMessage, transactionResponse:status?.transactionResponse, page: status?.page } },
            { path: ['transactionResponse'], value: status?.transactionResponse },
            { path: ['page'], value: status?.page },
        ])
    }

    return (
        <React.Fragment>
            {
                state?.paymentStatus?.error && (<Alert message={state?.paymentStatus?.errorMessage} type="error"/>)
            }
            
            <div style={{ display: 'flex', justifyContent: 'end', paddingBottom: 10 }}>
                {backVisibility && (
                    <Button
                        style={{ color: '#005680', border: '1px solid #005680', borderRadius: 8, marginRight: 10, marginTop: 21 }}
                        onClick={() => goBack(null)}
                    >
                        <LeftOutlined style={{ color: '#005680' }} /> Retour
                    </Button>
                )}
                {projectVisibility && (
                    <ProjectConfirmation
                        payload={payload}
                        type={type}
                        onPaymentStatus={paymentStatusHandler} />
                )}

                {onlineVisiblity && (
                    <PaymentEspece
                        payload={payload}
                        type={type}
                        onPaymentStatus={paymentStatusHandler} />
                ) }


                <PaymentByBanque />

                <PaymentOnline />
            </div>

        </React.Fragment>
    )
}

export default Payment;