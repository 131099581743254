import { useEffect, useState } from "react";
import * as constants from 'constantes';
import AvenantService from "services/avenantService";
import InitAvenantResponse from "api/response/initAvenantResponse";
import InitAvenantRequest from "api/request/initAvenantRequest";
import { toast } from "react-toastify";
import FormFieldsBuilder from "forms/FormFieldsBuilder";
import SubscriptionRequestBuilder from "api/request/SubscriptionRequestBuilder";
import QuotationRequestBuilder from "helpers/QuotationRequestBuilder";
import { useSelector } from "react-redux";
import QuotationResponse from "api/v1/response/QuotationResponse";
import _ from "lodash";
import { useBasket } from "../../hooks/useBasket";
import moment from "moment";

export default function useInitAvenant() {
    const { contract, avenant, assure, period, projectCode, isProject } = useSelector((state) => state.subscription);
    const { basket } = useSelector((state) => state.basketSlice);
    const {updateBasket, updateGroup, quote} = useBasket();

    const [state, setState] = useState({
        isInitAvenantLoading: true,
        iniAvenantLoadingError: false,
        iniAvenantLoadingErrorMessage: '',
        customPeriodTitle: ''
    });

    // console.log('useInitAvenant mBasket', mBasket)

    useEffect(() => {
        const initAvenantRequest = InitAvenantRequest.fromJson({
            productRef: constants.PRODUCT_AUTO_REF,
            contractRef: contract?.ref,
            avenantCode: avenant?.code,
            assureCode: assure?.code,
            //periodRef: period?.ref,
            projectCode: projectCode
        });

        initialiseAvenant(initAvenantRequest);
    }, []);

    const initialiseAvenant = (initAvenantRequest) => {
        AvenantService.init(initAvenantRequest, (responseData) => {
            if (responseData === null) { // Avenant de creation
                setState(state => ({ ...state, isInitAvenantLoading: false }));
                return;
            }

            if (responseData) {
                // console.log('responseData', responseData)
                const initAvenantResponse = (new InitAvenantResponse()).fromJson(responseData);

                const quotationResponse = QuotationResponse.fromJson(initAvenantResponse?.quotationResponse);

                const formField = FormFieldsBuilder.from({contract: contract, assure: assure});
                formField.startDate = moment(new Date(initAvenantResponse?.startDate).toLocaleDateString(), "DD/MM/YYYY");

                const packSelected = _.first(contract?.packsSelected)?.packResponse;
                console.log(">=== Init packSelected ==>", packSelected)
                const quotationRequest = QuotationRequestBuilder.build({
                    formFields: formField,
                    contract: contract,
                    avenant: avenant,
                    matriculation: null,
                    packLineRef: packSelected?.packLineRef,
                    calculateGuarantee: true 
                })

                const subscriptionRequest = SubscriptionRequestBuilder.fromMContract({
                    contract: contract,
                    assure: assure, 
                    avenant: avenant, 
                    isProject: isProject,
                    projectCode: projectCode,
                    isProrate: false 
                })
                subscriptionRequest.dateEffect = initAvenantResponse?.startDate; //? moment(initAvenantResponse?.startDate).format("DD/MM/YYYY").toLocaleLowerCase() : moment().format("DD/MM/YYYY").toLocaleLowerCase();

                const _basket = {
                    ...basket,
                    formField: {
                        ...basket?.formField,
                        ...formField
                    },
                    quotationRequest: {
                        ...basket?.quotationRequest,
                        ...quotationRequest
                    },
                    subscriptionRequest: {
                        ...basket?.subscriptionRequest,
                        ...subscriptionRequest
                    },
                    quotationResponse: {
                        ...basket?.quotationResponse,
                        ...quotationResponse
                    },
                    packSelected: {
                        ...basket?.packSelected,
                        selected: packSelected?.packLineRef != null,
                        ...packSelected
                    }
                }

                updateBasket(_basket);
                updateGroup([
                    { path: ['initAvenantResponse'], value: { ...initAvenantResponse } },
                ])

                //quote({baskets: [_basket]});
                setState((state) => ({
                    ...state,
                    isInitAvenantLoading: false,
                    isGuaranteeOptionRequoting: false,
                    customPeriodTitle: initAvenantResponse?.periodTitle,
                }))

            }
            else {
                toast.error("Une erreur est survenue pendant l'initialisation de l'avenat")
                setState(state => ({ ...state, isInitAvenantLoading: false }));
            }

        }, (error) => {
            // console.log('errrrrrrrrrooooorrrrrrrr')
            setState((state) => ({
                ...state,
                isInitAvenantLoading: false,
                isGuaranteeOptionRequoting: false,
                iniAvenantLoadingError: true,
                iniAvenantLoadingErrorMessage: error
            }))
        });
    }

    return { state, setState, initialiseAvenant }
}