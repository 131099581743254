export default class Entities {
    id;
    code;
    title;
    description;
    email;
    phone;
    contributorRef;
    contributorTitle;
    
    fromJson = (json={})=> {
        var entities = new Entities();
        entities = json;
    }
}