export default class SocailPosition {
    id;
    ref;
    code;
    title;
    description;
    
    fromJson = (json={})=> {
        var socialPosition = new SocailPosition();
        socialPosition = json;
        return socialPosition
    }
 }