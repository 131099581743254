export default class FormRegisterGarantie {
    code;
    title;
    description;

    toJson = (json = {}) => {
        var formRegisterGarantie = new FormRegisterGarantie();
        formRegisterGarantie = json;
        return formRegisterGarantie;
    }
}