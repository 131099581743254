import React, { useEffect, useState } from 'react';
import * as helpsers from 'helpers/helpers';

import FInputQuotation from './FInputQuotation';
import FStepPackages from './FStepPackages';

const FStepsWidget=({basket, setOpenVehicleDrawer, onValidateBasket, action})=> {
    
    const [currentBasket, setCurrentBasket] = useState(basket);
    const [state, setState] = useState({
        currentStep: 0
    });

    useEffect(()=> {
        console.log("::::: FStepWidget basket update", basket)
        //updateBasket({...basket,  key: basket?.key ?? helpsers.randomString(8).toUpperCase()})
        setCurrentBasket(basket)
        return () => {};
    }, [basket])

    const goToNextStep=(data)=> helpsers.goToNextStep(data, setState);

    const goToPreviousStep=()=> helpsers.goToPreviousStep(null, setState);

    const validateBasket=(basket)=> {
        onValidateBasket(basket)
        setState(state=> ({...state, currentStep: 0}))
    }

    const childernRender= ()=> {
        switch(state.currentStep) {
            case 0:
               return <FInputQuotation  
                        basket={currentBasket}
                        setOpenVehicleDrawer={setOpenVehicleDrawer}
                        goToNextStep={goToNextStep}
                        />
            case 1:
                return <FStepPackages 
                            basket={currentBasket} 
                            goToPreviousStep={goToPreviousStep}
                            setOpenVehicleDrawer={setOpenVehicleDrawer}
                            validateBasket={validateBasket}/>
            default:

        }
    }

    return (
        <React.Fragment>
            {/* <div className="step-arrow-nav">
                <Nav
                    className="nav-pills nav-justified custom-nav"
                    role="tablist">
                    <NavItem>
                        <NavLink
                            href="#"
                            className={classnames({ active: state.currentStep === 0 }, "p-3")}
                            onClick={null}>
                                Informations sur le vehicule
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            href="#"
                            className={classnames({ active: state.currentStep === 1 }, "p-3")}
                            onClick={null}>
                                Choix du pack
                        </NavLink>
                    </NavItem>
                </Nav>
            </div> */}
            {childernRender()}
        </React.Fragment>
    )
}

export default FStepsWidget;