import { AutoComplete, Button, Input } from "antd";
import Merchant from "api/model/merchant.model";
import React, { useState } from "react";
import MerchantService from "services/merchantService";

const MerchantSearchField= ({form, formField, handlerFormField})=> {
    const [options, setOptions] = useState([]);
    const [defaultMerchant, setDefaultMerchant] = useState({});
    /* useEffect(()=> {
        setDefaultAssure({
            assureCode      : formField?.assureCode,
            assureFirstname : formField?.assureFirstname,
            assureLastname  : formField?.assureLastname,
            assurePhone     : formField?.assurePhone,
            assureIdentityCardNumber: formField?.assureIdentityCardNumber,
            assureIdentityCardRef: formField?.assureIdentityCardRef,
            assureIdentityCardTitle: formField?.assureIdentityCardTitle,
            assureEmail     : formField?.assureEmail
        })
    }, []) */

    // TODO-OK: <La recherche en 360°>
    const handleSearchMerchant = (word) => {
        if(word === null || word === undefined || word === '' ) {
            form.setFieldsValue({
                merchantCode      : defaultMerchant?.merchantCode,
                merchantFirstname : defaultMerchant?.merchantFirstname,
                merchantLastname  : defaultMerchant?.merchantLastname,
                merchantPhone     : defaultMerchant?.merchantPhone,
                merchantEmail     : defaultMerchant?.merchantEmail
            });
            setOptions([])
        };

        const options = {query: {word: word, page: 0, size: 10}};
        console.log(":::: options :", options);
        MerchantService.search(options, (data)=> {
            console.log(":::: data :", data)
            const _merchantSearchResult = (data?.content || []).map((merchant)=> (
                {
                    item: merchant,
                    value: `${merchant.firstName} ${merchant.lastName}`,
                    label: (
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span> { merchant.firstName + " " +  merchant.lastName}  </span>
                            <span> { merchant.phone }</span>
                        </div>
                    )
                }
            ));

            console.log(":::: _assureSearchResult......: ", _merchantSearchResult)
            setOptions(_merchantSearchResult);
        }, (error)=> {
            console.log(":::: error :", error)
            setOptions([])
        });

        
    };

    const onMerchantSearchSelected = (item) => {
        if (item == null) return;
        
        const _mercahant = Merchant.fromJson(item);
        const mercahant = {
            merchantCode      : _mercahant?.code,
            merchantFirstname : _mercahant?.firstName,
            merchantLastname  : _mercahant?.lastName,
            merchantPhone     : _mercahant?.phone,
            merchantEmail     : _mercahant?.email
        }
        form.setFieldsValue({...mercahant});
        //handlerFormField(mercahant)
    }

    const resetField=()=> {
        const mercahant = {
            merchantCode      : null,
            merchantFirstname : null,
            merchantLastname  : null,
            merchantPhone     : "00-00-00-00",
            merchantEmail     : null
        }
        form.setFieldsValue({...mercahant});
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex', marginLeft: 20, flexDirection:'column', alignItems:'end' }}>
                <AutoComplete
                    dropdownMatchSelectWidth={400}
                    style={{width: 400}}
                    options={options}
                    onSelect={(e, itemSelected)=> {
                        onMerchantSearchSelected(itemSelected.item)
                    }}
                    onSearch={handleSearchMerchant}
                >
                    
                    <Input.Search size="medium" placeholder='Recherche : Code apporteur, nom et prénom, Email, Telephone' enterButton />
                </AutoComplete>
                <Button type="link" onClick={()=> resetField()}> Nouveau + </Button>
            </div>
        </React.Fragment>
    )
}

export default MerchantSearchField;