import React from "react";

const Header1 = ()=> {
    return (
        <React.Fragment>
            <div>
                <p>
                    Une selection de packs adaptés au besoin du client
                </p>
            </div>
        </React.Fragment>
    )
}

export default Header1;