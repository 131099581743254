import { Col, Form, Input } from "antd";
import { CATEGORY_403 } from "constantes";
import React from "react";

const TrailerChassisNumberField=({ categoryCode, disable, xs, md })=> {
    
    if(![CATEGORY_403].includes(categoryCode)) return null;
    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Numero de chassis"
                    name="trailerChassisNumber"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    <Input
                        size='large'
                        placeholder='Numero de chassis du vehicule'
                        disabled={disable}
                    />
                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default TrailerChassisNumberField;