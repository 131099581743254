export default class Category {
    id;
    ref;
    code;
    title;
    description;

    fromJson = (json={})=> {
        var category = new Category();
        category = json;
        return category
    }
}