import { LoadingOutlined } from "@ant-design/icons";
import { Select, Spin } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CategoryService from "services/categoryService";
const AddGuarantee=({category, pack, guaranties, refreshPacksAndGuaranties})=> {
    const Option = Select;

    const [state, setState] = useState({
        isPackGuaranteeAdding: false
    })

    const addGuarantee2Pack=(packSelectedRef)=> {
        const options = {
            path: { 
                categoryRef: category?.ref,
                packRef: packSelectedRef,
                guaranteeRef: state?.packOthersGuarantiesSelected?.ref
            }
        }

        CategoryService.attachPackGuarantee(options, (data)=> {
            toast.success("La garantie a été ajoutée avec succès")
            refreshPacksAndGuaranties(category)
        }, (error)=> {
            toast.error("Echec d'enregistrement de guarantee")
        })
    }

    const onPackOthersGuarantiesSelected=(guaranteeSelectedRef)=> {
        console.log(":::: guaranteeSelectedRef :", guaranteeSelectedRef)
        const packOtherGuaranteeSelected = guaranties.find((guarantee)=> guarantee?.ref === guaranteeSelectedRef);
        setState(state=> ({...state, packOthersGuarantiesSelected: packOtherGuaranteeSelected}))
    }

    return (
        <React.Fragment>
            <div className="d-flex item-align-end flex-row" >
                <div style={{width: "100%"}}>
                    <SelectWithFilter style={{ width: '100%' }} placeholder='Selectionner une garantie' onChange={(e)=> onPackOthersGuarantiesSelected(e, pack?.ref)} size="medium">
                        {guaranties.map((guarantie, index) => (<Option value={guarantie?.ref} key={index}>{guarantie?.title}</Option>))}
                    </SelectWithFilter>
                </div>
                <div style={{marginLeft: 5, height: "33px"}}>
                    <button
                        type="button"
                        className="btn btn-primary btn-label right nexttab btn-sm"
                        style={{width: "100%"}}
                        onClick={() => addGuarantee2Pack(pack?.ref)}
                        onLoad
                    >
                        <i className="bx bx-plus label-icon align-middle fs-16 ms-2"></i>{" "}
                        {state.isPackGuaranteeAdding ? (<LoadingOutlined style={{ color: '#ffffff', fontSize: 20 }} />) : (<></>)}
                        Ajouter
                    </button>
                </div>
            </div> 
        </React.Fragment>
    )
}

export default AddGuarantee;