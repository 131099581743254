import { HttpMethod } from "./httpMethod"

export class ResourceOptions {
    path;
    query;
    constructor(path={}, query={}) {
        this.path   = path;
        this.query  = query;
    }

    fromJson = (json={})=> {
        var resourceOptions = new ResourceOptions({},{});
        resourceOptions = json;
        return resourceOptions
    }
}

export default class ResourceConfig {
    /* urlSuffix;
    method;
    stripTrailingSlashes;
    isList;
    invalidateCache;
    resourceOptions;
    authenticated; */

    constructor({
        urlSuffix= "", 
        method= HttpMethod.GET,
        stripTrailingSlashes= false,
        isList = false,
        invalidateCache = false,
        authenticated = true,
        resourceOptions= (new ResourceOptions())
    }) {
        this.urlSuffix = urlSuffix;
        this.method = method;
        this.stripTrailingSlashes = stripTrailingSlashes;
        this.isList = isList;
        this.invalidateCache = invalidateCache;
        this.authenticated = authenticated;
        this.resourceOptions = resourceOptions;
    }
    
    fromJson = (json)=> {
        var resourceConfig = new ResourceConfig(json);
        return resourceConfig
    }
}

export class HttpStatus {
    static STATUS_CODE_SUCCESS= 10;
}


export function buildRequestUrl(url, resourceOption = {} ) {
    let _queryParams = [] 
    let _strQueryParam = "";
    let _resourceOption = (new ResourceOptions()).fromJson(resourceOption);
    let uri = url;
    if(_resourceOption.path && Object.keys(_resourceOption.path).length > 0) {
        for (const [key, value] of Object.entries(_resourceOption.path)) {
                uri = uri.replace(`:${key}`,`${value}`)
        } 
    }

    if(_resourceOption.query && Object.keys(_resourceOption.query).length > 0) {
        for (const [key, value] of Object.entries(_resourceOption.query)) {
            if(value !== null)
                _queryParams.push(`${key}=${value}`)
        }
        _strQueryParam = _queryParams.length > 0 ?  "?" + _queryParams.join("&") : "";
    }

    return `${uri}${_strQueryParam}`;
}

export function NavigationPush(_callBack) {
    _callBack(true)
}