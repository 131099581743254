import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Modal, notification, Row, Spin, Switch, Table, Select } from "antd";
import { TEST_API } from "constantes";
import React, { useEffect, useState } from "react";
import axios from "axios";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";


export default function Agences(props) {
    const Option = Select;
    const [form] = Form.useForm();

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg
        });
    };

    useEffect(() => {
        //getAllAgence();
        //getAllPartenaire();
    }, [])

    const [state, setState] = useState({
        listAgence: [],
        agenceLoad: false,
        loading: false,
        updateLine: {},
        isLoad: false,
        itemId: null,
        listPartenaire: [],
        partenaireLoad: false
    });

    const [visible, setVisible] = useState(false);
    const [update, setUpdate] = useState(false);
    const [del, setDel] = useState(false);
    const [list, setList] = useState([]);

    const columns = [
        {
            title: "Date de creation",
            dataIndex: "date",
            key: "date"
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code"
        },
        {
            title: "Titre",
            dataIndex: "libelle",
            key: "libelle"
        },
        {
            title: "Telephone",
            dataIndex: "telephone",
            key: "telephone"
        },
        {
            title: "Partenaire",
            dataIndex: "partenaire",
            key: "partenaire",
            render: (item, data) => (
                <div>
                    {data.partenaire.libelle}
                </div>

            )
        },
        {
            title: "Heure d'ouverture",
            dataIndex: "ouverture",
            key: "ouverture"
        },
        {
            title: "Activation",
            dataIndex: "ouverture",
            key: "ouverture",
            render: (item, data) => (
                <div>
                    <Switch defaultChecked={data.statut} onChange={() => onChange(data)} />
                </div>
            )
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (item, data) => (
                <>
                    <EditOutlined style={{ color: '#40E070', cursor: 'pointer' }} onClick={() => { updateModalVisible(true); setState(state => ({ ...state, updateLine: data })) }} />
                    <DeleteOutlined style={{ color: '#E5AC28', cursor: 'pointer', marginLeft: 10 }} onClick={() => { setDel(true); setState(state => ({ ...state, itemId: data.id })) }} />
                </>

            )
        }
    ];

    function onChange(data) {
        data.statut = !data.statut;
        console.log('data ---> ', data);
        fetch(`http://localhost:3003/agences/${data.id}`,
            {
                method: 'put',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            }
        ).then(response => {
            return response.json();
        })
            .then(data => {
                console.log('update statut agence ', data);
                openNotificationWithIcon('success', "Agence activée avec succes !")
                getAllAgence();
            })
            .catch(error => {
                console.log('update statut agence error -------------> ', error);
            })
    }

    function getAllAgence() {
        fetch(`http://localhost:3003/agences`,
            {
                method: 'get',
                headers: { 'Content-Type': 'application/json' }
            }
        ).then(response => {
            return response.json();
        })
            .then(data => {
                console.log('get all agence ', data);
                setState(state => ({ ...state, listAgence: data, agenceLoad: true }));
                setList(data);
            })
            .catch(error => {
                console.log('get all agence error -------------> ', error);
            })

    }


    function getAllPartenaire() {
        fetch(`http://localhost:3003/partenaires`,
            {
                method: 'get',
                headers: { 'Content-Type': 'application/json' }
            }
        ).then(response => {
            return response.json();
        })
            .then(data => {
                console.log('get all partenaire ', data);
                setState(state => ({ ...state, listPartenaire: data, partenaireLoad: true }));
            })
            .catch(error => {
                console.log('get all partenaire error -------------> ', error);
            })

    }


    function deleteLine() {
        setState(state => ({ ...state, isLoad: true }))
        fetch(`http://localhost:3003/agences/${state.itemId}`,
            {
                method: 'delete',
                headers: { 'Content-Type': 'application/json' }
            }
        ).then(response => {
            return response.json();
        })
            .then(data => {
                console.log('delete agence ', data);
                getAllAgence();
                setState(state => ({ ...state, isLoad: true }))
                setDel(false);
                openNotificationWithIcon('success', 'Suppression effectuée avec succès !');

            })
            .catch(error => {
                console.log('delete partenaire error -------------> ', error);
                openNotificationWithIcon('error', 'Error lors de la suppression !');
            })

    }

    function addModalVisible(value) {
        form.setFieldsValue({
            code: "",
            libelle: "",
            partenaire: '',
            telephone: '',
            ouverture: ''
        });
        setVisible(value);
    }

    function updateModalVisible(value) {
        if (value) {
            setUpdate(value);
            setTimeout(() => {
                setState(state => ({ ...state, isLoad: true }))
            }, 1);
        }
        else {
            setUpdate(value);
            setState(state => ({ ...state, isLoad: false }))
        }

    }


    function deleteModalVisible(value) {
        setDel(value);
    }


    function onFinish(value) {
        setState(state => ({ ...state, loading: true }));
        let table = state.listPartenaire.filter(item => item.code == value.partenaire);

        let day = new Date().getDate();
        let month = new Date().getMonth() + 1;
        let year = new Date().getFullYear();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        value.date = day + '-' + month + '-' + year;
        value.partenaire = table[0];
        console.log('typing values --> ', value);
        var config = {
            method: 'post',
            url: `${TEST_API}agences`,
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                // "Authorization": `Bearer ${token}`
            },
            data: JSON.stringify(value)
        };
        axios(config)
            .then(function (response) {
                console.log('response list des agences', response);
                setState(state => ({ ...state, loading: false }));
                openNotificationWithIcon('success', 'agence ajoutée avec succès !');
                getAllAgence();
                setVisible(false);
            })
            .catch(function (error) {
                console.log(error);
                openNotificationWithIcon('error', "Erreur lors de l'enregistrement !");
            });
    }

    function onUpdate(value) {
        setState(state => ({ ...state, loading: true }));
        let table = state.listPartenaire.filter(item => item.code == value.partenaire);
        value.partenaire = table[0];
        value.date = state.updateLine.date;
        value.id = state.updateLine.id;
        console.log('updating data --> ', value);
        var config = {
            method: 'put',
            url: `${TEST_API}agences/${state.updateLine.id}`,
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                // "Authorization": `Bearer ${token}`
            },
            data: JSON.stringify(value)
        };
        axios(config)
            .then(function (response) {
                console.log('response update des agence', response);
                setState(state => ({ ...state, loading: false }));
                getAllAgence();
                openNotificationWithIcon('success', 'Mise à jour effectuée avec succès !');
                setUpdate(false);
            })
            .catch(function (error) {
                console.log(error);
                openNotificationWithIcon('error', "Erreur lors de la mise à jour !");
            });
    }

    function onSearch(e) {
        let value = e.target.value;
        console.log('typing values --> ', value);
        let table = [];
        let index = 0;
        let data = list;

        for (let i = 0; i < data.length; i++) {
            if (
                data[i].date.toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1 ||
                data[i].code.toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1 ||
                data[i].libelle.toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1 ||
                data[i].partenaire.toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1 ||
                data[i].telephone.toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1 ||
                data[i].ouverture.toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1
            ) {
                table[index] = data[i];
                index++;
            }
        }
        setState(state => ({ ...state, listAgence: table }))
        console.log('filtered list ---> ', table);
    }

    console.log('updating data --> ', state.updateLine);

    return (
        <div>
            <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Liste des agences</h4>

            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Input placeholder="Recherche" style={{ width: 200, marginRight: 10 }} onChange={onSearch} />
                <Button type="primary" onClick={() => addModalVisible(true)}>
                    <FileAddOutlined />
                    Ajouter une agence
                </Button>
            </div><br />
            {state.agenceLoad ? (
                <Table dataSource={state.listAgence} columns={columns} />
            ) : (<p>En operation bientôt !</p>)}

            {visible && (
                <Drawer
                    title="Enregistrement d'une agence"
                    width={720}
                    onClose={() => addModalVisible(false)}
                    visible={visible}
                    bodyStyle={{
                        paddingBottom: 80,
                    }}
                >
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        layout="vertical"
                        form={form}
                    >
                        <Row gutter={8} >
                            <Col className="gutter-row" xs={24} md={24}>
                                <Form.Item
                                    label="Code"
                                    name="code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Entrez le code de l\'agence svp !',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Entrez le code de l'agence" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={8} >
                            <Col className="gutter-row" xs={24} md={12}>
                                <Form.Item
                                    label="Titre"
                                    name="libelle"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Le libelle de l\'agence svp !',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Libelle de l'agence" />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={12} md={12}>
                                <Form.Item
                                    label="Partenaire"
                                    name="partenaire"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Le partenaire svp !',
                                        },
                                    ]}
                                >
                                    <SelectWithFilter placeholder='Choisissez le partenaire' size="middle">
                                        {
                                            state.partenaireLoad ? (
                                                <>
                                                    {
                                                        state.listPartenaire.map(function (item, index) {
                                                            return (<Option value={item.code} key={index}>{item.libelle}</Option>)
                                                        })
                                                    }
                                                </>
                                            ) : (<Spin />)
                                        }
                                    </SelectWithFilter>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={8} >
                            <Col className="gutter-row" xs={12} md={12}>
                                <Form.Item
                                    label="Telephone"
                                    name="telephone"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Le telephone de l\'agence svp !',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Entrez le telephone de l'agence" />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={12} md={12}>
                                <Form.Item
                                    label="Heure d'ouverture"
                                    name="ouverture"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Heure d\'ouverture de l\'agence svp !',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Entrez la heure d'ouverture de l'agence" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e8e8e8',
                                padding: '10px 16px',
                                textAlign: 'right',
                                left: 0,
                                background: '#fff',
                                borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <Button
                                style={{
                                    marginRight: 8,
                                }}
                                onClick={() => addModalVisible(false)}
                            >
                                Annuler
                            </Button>
                            <Button type="primary" htmlType="submit" loading={state.loading}>
                                Enregistrer
                            </Button>
                        </div>
                    </Form>
                </Drawer>
            )}

            {update && (
                <Drawer
                    title="Modification d'un partenaire"
                    width={720}
                    onClose={() => updateModalVisible(false)}
                    visible={update}
                    bodyStyle={{
                        paddingBottom: 80,
                    }}
                >
                    {state.isLoad ? (
                        <Form
                            name="update"
                            onFinish={onUpdate}
                            layout="vertical"
                        // form={form}
                        >
                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Code"
                                        name="code"
                                        initialValue={state.updateLine.code}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Entrez le code de l\'agence svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Entrez le code de l'agence" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={12}>
                                    <Form.Item
                                        label="Titre"
                                        name="libelle"
                                        initialValue={state.updateLine.libelle}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Le libelle de l\'agence svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Libelle de l'agence" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Partenaire"
                                        name="partenaire"
                                        initialValue={state.updateLine.partenaire.code}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Le partenaire svp !',
                                            },
                                        ]}
                                    >
                                        <SelectWithFilter placeholder='Choisissez le partenaire' size="middle">
                                            {
                                                state.partenaireLoad ? (
                                                    <>
                                                        {
                                                            state.listPartenaire.map(function (item, index) {
                                                                return (<Option value={item.code} key={index}>{item.libelle}</Option>)
                                                            })
                                                        }
                                                    </>
                                                ) : (<Spin />)
                                            }
                                        </SelectWithFilter>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Telephone"
                                        name="telephone"
                                        initialValue={state.updateLine.telephone}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Le telephone de l\'agence svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Entrez le telephone de l'agence" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={12} md={12}>
                                    <Form.Item
                                        label="Heure d'ouverture"
                                        initialValue={state.updateLine.ouverture}
                                        name="ouverture"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Heure d\'ouverture de l\'agence svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Entrez la heure d'ouverture de l'agence" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e8e8e8',
                                    padding: '10px 16px',
                                    textAlign: 'right',
                                    left: 0,
                                    background: '#fff',
                                    borderRadius: '0 0 4px 4px',
                                }}
                            >
                                <Button
                                    style={{
                                        marginRight: 8,
                                    }}
                                    onClick={() => updateModalVisible(false)}
                                >
                                    Annuler
                                </Button>
                                <Button type="primary" htmlType="submit" loading={state.loading}>
                                    Modifier
                                </Button>
                            </div>
                        </Form>
                    ) : (<Spin />)}
                </Drawer>
            )}

            {/**********DELETE ALERTE ****** */}
            {del && (
                <Modal
                    title="Supprimer un partenaire !!!"
                    visible={del}
                    onOk={() => { deleteModalVisible(false); deleteLine() }}
                    onCancel={() => deleteModalVisible(false)}
                    okText="Confirmer"
                    cancelText="Annuler"
                >
                    <h3 style={{ color: 'orange' }}> <ExclamationCircleOutlined />  Voulez-vous supprimer vraiment cette agence ?</h3>
                </Modal>
            )}
        </div>
    )
}