import React, { useState, useEffect } from 'react';

import TemplateLayout from 'modules/components/layouts/LayoutTwo';

import makeStyles from '@material-ui/styles/makeStyles';
import { Menu } from 'antd';
import { CalendarOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store from 'redux/store';
import { HomeOutlined } from '@material-ui/icons';
import ProfileDropdown from 'modules/components/common/ProfileDropdown';


const { SubMenu } = Menu;

const useStyles = makeStyles(() => ({
    toolBarContainer1: {
        width: '100%',
        display: "flex",
        background: "#F14818",
        justifyContent: "space-between",
        width: '100%',
    },
    toolBarContainer2: {
        display: "flex",
        justifyContent: "center",
    },
    logoContainer: {
        cursor: "pointer",
        color: "white",
        fontWeight: "bold",
        fontSize: 20
    },
    menu: {
        background: 'inherit'
    },
}));

const toolBarItem1 = {
    color: "white",
}

function Layout(props) {
    const { sideBarItems, topBarItems, tabItems, routes, widgets } = props;
    const currentUser = store.getState().auth.user;
    // console.log('contenue state global ----> ', currentUser)
    return (
        <TemplateLayout
            theme={{
                topbarOne: {
                    backgroundColor: "#0075C9"
                },
                sidebar: {
                    titleBackgroundColor: "#0075C9"
                },
                tab: {
                    backgroundColor: "gray",
                    selectedItemColor: "#0075C9"
                },
                footer: {
                    backgroundColor: "#0075C9"
                }
            }}
            sideBarItems={sideBarItems}
            topBarItems={topBarItems}
            tabItems={tabItems}
            widgets={widgets}
            actionsWidget={<ProfileDropdown /> } 
            companyName={currentUser ? (currentUser?.entities?.title + ' (' + currentUser?.entities?.code + ')') : (<HomeOutlined />)}
            routes={routes}
        />
    );
}
{/* <Topbar1 /> */}
export default Layout
