import React from "react";
import { Button } from "reactstrap";

const ReactBtn=({title, icon, onClickHandler})=> {
    return (
        <React.Fragment>
            <Button color="primary" className="btn-label right" onClick={()=> onClickHandler ? onClickHandler() : console.log("no func")}> 
                <i className={`" bx bx-${icon} label-icon align-middle fs-16 ms-2"`}></i> {title}
            </Button>
        </React.Fragment>
    )
}

export default ReactBtn;