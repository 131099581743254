export default class FormRegisterGroupeReduction {
    code;
    title;
    description;

    toJson = (json = {}) => {
        var formRegisterGroupeReduction = new FormRegisterGroupeReduction();
        formRegisterGroupeReduction = json;
        return formRegisterGroupeReduction;
    }
}