import React, { useState } from "react";
import { Col, Form, Row } from "antd";
import CustomAntdForm from "modules/components/Forms/CustomAntdForm";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import MotifField from "../../common/fields/MotifField";
import SubscriptionService from "services/subscriptionService";

const AddAnnulationMotif=({subscriptionRef, defautltContent, onMotifApplying})=> {
    const [form] = Form.useForm();
    
    const [state, setState]=useState({
        isMotifApplying: false
    })

    const applyMotif=()=> {
        setState(state=>({...state, isMotifApplying: true}))
        form.validateFields().then((values)=> {
            const payload = {...values, subscriptionRef: subscriptionRef}
            console.log(":::: register motif value : ", payload)
            SubscriptionService.addCancelMotif(payload, (data)=> {
                setState(state=>({...state, isMotifApplying: false}))
                toast.success("Action réalisée avec succès")
                onMotifApplying(true)
            }, (error)=> {
                setState(state=>({...state, isMotifApplying: false}))
            })
        }).catch((error)=> {
            toast.error("Erreur pendant la validation");
            setState(state=>({...state, isMotifApplying: false}))
        })
    }

    return (
        <CustomAntdForm form={form} initialValues={{motif: defautltContent}}>
            <Row gutter={[8, 8]}>
                
                <Col className="gutter-row" xs={24} md={24}>
                    <MotifField  />
                </Col>

                
                <Col xs={24} md={24}>
                    <div style={{display: 'flex', justifyContent:'end'}}>
                        <button
                            type="button"
                            className="btn btn-primary btn-label right nexttab btn-sm"
                            onClick={() => applyMotif()}
                            onLoad
                        >
                            <i className="bx bx-plus label-icon align-middle fs-16 ms-2"></i>{" "}
                            {state?.isMotifApplying ? (<LoadingOutlined style={{ color: '#ffffff', fontSize: 20 }} />) : (" ")} Confirmer
                            
                        </button>
                    </div>
                </Col>
            </Row>
        </CustomAntdForm>
        
    );
}

export default AddAnnulationMotif;