import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Card, Button, Spin, Menu, Dropdown, Space, Tag, Checkbox } from 'antd'
import { FileDoneOutlined, LeftOutlined, SettingOutlined } from '@ant-design/icons';
import './styleStep2.css'
import { thousandSeparator } from 'helpers/helpers';
import { toast } from 'react-toastify';
import AssurancePackage from 'views/pages/common/AssurancePackage';
import {  useSelector } from 'react-redux';
import ErrorWidget from 'components/Widgets/ErrorWidget';
import * as helpers from 'helpers/helpers';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';
import _ from 'lodash';
import QuotationService from 'services/quotationService';
import FooterDrawer from '../../../common/FooterDrawer';
import BrandField from '../../../../common/fields/BrandField';
import ModelField from '../../../../common/fields/ModelField';
import CustomerBrandField from '../../../../common/fields/CustomerBrandField';
import CustomerModelField from '../../../../common/fields/CustomerModelField';
import MatriculationField from '../../../../common/fields/MatriculationField';
import ChassisNumberField from '../../../../common/fields/ChassisNumberField';
import CustomAntdForm from 'modules/components/Forms/CustomAntdForm';
import { CardBody } from 'reactstrap';
import { CATEGORY_403 } from 'constantes';
import TrailerBrandField from 'views/pages/subscription/common/fields/TrailerBrandField';
import TrailerCustomerBrandField from 'views/pages/subscription/common/fields/TrailerCustomerBrandField';
import TrailerMatriculationField from 'views/pages/subscription/common/fields/TrailerMatriculationField';
import TrailerChassisNumberField from 'views/pages/subscription/common/fields/TrailerChassisNumberField';
import QuotationResponse from 'api/v1/response/QuotationResponse';
import QuotationRequest from 'api/request/quotationRequest';
import AmountHeader from '../../../../common/AmounHeader';

const menu =(handlePacksMenuClick)=> (
    <Menu
        onClick={handlePacksMenuClick}
        items={[
            {
                label: (
                    <>Package à la carte</>
                ),
                key: '0',
            },

            {
                type: 'divider',
            },
            {
                label: 'Pack avantage',
                key: '2',
                disabled: true,
            },
            {
                label: 'Pack évolutive',
                key: '3',
                disabled: true,
            },
            {
                label: 'Pack confort',
                key: '4',
                disabled: true,
            },
            {
                label: 'Pack liberté',
                key: '5',
                disabled: true,
            },
        ]}
    />
);

const FStepPackages = ({basket, goToPreviousStep, setOpenVehicleDrawer, validateBasket}) => {
    const { category, contract, avenant } = useSelector((state) => state.subscription);
    const {quoteStatus, runQuote} = useSelector(state=> state.basketSlice);
    const {goToNextStep, quote, updateFormField, updateBasket, updateGroup} = useBasket();
    
    const [form] = Form.useForm();
    const [customerBrand, setCustomerBrand] = useState(false);
    const [trailerCustomerBrand, setTrailerCustomerBrand] = useState(false);
    const [toogleTrailer, setToogleTrailer] = useState(basket?.formField?.hasTrailer)
    const [matriculationExist, setMatriculationExist] = useState(true)
    const [isRegeneratedLoading, setIsRegeneratedLoading] = useState(false);
    
    const [isPackCarteOn, setIsPackCarteOn] = useState(false);
    const [ packSelected, setPackSelected] = useState(basket?.packSelected) 

    const [state, setState] = useState({
        basket: basket,
        selectedPackageId: '',
        reductions: [],
        customerReductions: [], // basket?.quotationResponse?.reductionGroups ||
        isReductionEditionOpened: false,
        packSelected: {},
        errorGeneratePacks: false,
        errorMessageGeneratePacks: "",

        isPacksLoading: false,
        isPacksLoadingError: false,
        isPacksLoadingErrorMessage: ""
    });


    useEffect(() => { 
        setState(state=> ({...state, isPacksLoading: true}))

        // Quote tous les packs
        const _quotationRequest = QuotationRequest.buildFromFormFields({
            formFields: basket?.formField, 
            contract: contract, 
            avenant: avenant,
            packLineRef: null
        });

        QuotationService.quote({quotationRequests: [_quotationRequest], t: 'm'}, (result)=> {
            const _quotationResponse = QuotationResponse.fromJson(result);
            const _packSelected = (_quotationResponse?.packsResponse || []).find((packResponse)=> packResponse?.packLineRef === packSelected.packLineRef);
            const _basket = {
                ...basket, 
                quotationRequest: _quotationRequest, 
                packSelected: {
                    ...basket?.packSelected, 
                    ..._packSelected
                }, 
                quotationResponse: _quotationResponse
            }
            updateBasket(_basket);
            setState(state=> ({ ...state, isPacksLoading: false}))
            
            // Quote specifiquement le package selectionné
            if(packSelected?.selected) {
                quotePackSelected(_quotationRequest)
            }

        }, (error)=> {
            setState(state=> ({...state, isPacksLoading: false, isPacksLoadingError: true, isPacksLoadingErrorMessage: error}))
        })
        //============================================        
    }, []);

    const quotePackSelected=(_quotationRequest)=> {
        const __quotationRequest =  {
            ..._quotationRequest,
            customGuaranties: [...packSelected?.customGuaranties],
            reductionGroups: [...packSelected?.reductionGroups],
            packLineRef: packSelected?.packLineRef
        }

        QuotationService.quote({quotationRequests: [__quotationRequest], t: 'm'}, (result)=> {
            const _quotationResponse = QuotationResponse.fromJson(result);
            const _pack = _.first(_quotationResponse.packsResponse);
            updateGroup([
                {path: ['basket', 'quotationRequest'], value: {...__quotationRequest}},
                {path: ['basket', 'packSelected'], value: {...packSelected, _pack}}
            ])
        }, (error)=> {
        })
    }

    const onPackSelected=(pack)=> {
        setPackSelected(pack)
    }

    const handlePacksMenuClick = (e) => {
        if(e?.key ==="0" ) {
            setIsPackCarteOn(true)
        }
    };

    const goBack=()=> {
        // Persist pack Selected
        if(packSelected?.selected) {
            updateGroup([
                { path: ['basket', 'packSelected'], value: {...packSelected}}
            ])
        }
        goToPreviousStep(null)
    }

    const onPackageValidate=()=> {
        // Persist pack Selected
        if (_.size(packSelected?.selected) <= 0  && packSelected.primeTTC <= 0) {
            toast.error("Vous devrez selectionnez un package");
            return;
        }

        console.log(":::: OnPackageValidate packSelected ", packSelected)

        form.validateFields()
            .then(values => {
                // Custom guaranties
                let _customGuaranties = [];
                for(const customGuarantee of packSelected?.customGuaranties) {
                    if(customGuarantee?.selected === true) {
                        _customGuaranties = [..._customGuaranties, {
                            guaranteeRef: customGuarantee?.guaranteeRef,
                            engagementRef: customGuarantee?.engagementRef,
                            franchise: customGuarantee?.franchise
                        }]
                    }
                }

                let _otherCustomGuaranties=[];
                for(const guarantee of packSelected?.guaranties) {
                    if(guarantee?.required && (packSelected?.customGuaranties || []).findIndex((customGuarantie)=> customGuarantie?.guaranteeRef === guarantee?.ref) === -1) {
                        const defaultEngagement = (guarantee?.engagements || []).find((engagement)=> engagement?.default);
                        _otherCustomGuaranties = [..._otherCustomGuaranties, {
                            guaranteeRef: guarantee?.ref,
                            engagementRef: defaultEngagement?.ref,
                            franchise: guarantee?.franchise
                        }]
                    }
                }
                _customGuaranties = [..._customGuaranties, ..._otherCustomGuaranties];

                const _basket = {
                    ...state?.basket,
                    formField: {
                        ...basket?.formField,
                        ...values
                    },
                    quotationRequest: {
                        ...basket?.quotationRequest,
                        customGuaranties: [..._customGuaranties],
                        reductionGroups: [...packSelected?.reductionGroups],
                        packLineRef: packSelected?.packLineRef
                    },
                    packSelected: {
                        ...packSelected,
                        //customGuaranties: [..._customGuaranties]
                    }
                }
                validateBasket(_basket)
                //updateBasket(_basket);
                //goToNextStep(null)
            })
            .catch(errorInfo => { 
                console.log("Error form :", errorInfo)
            });
    }

    const toggleTrailerCheckBox=(e)=> {
        const checked = e.target.checked; 
        updateFormField({hasTrailer: checked})
        setToogleTrailer(checked);

        if(!checked) {
            /* updateFormField({
                hasTrailer: false,
                groupementRef: null,
                groupementTitle: ""
            }); */
        }
    }

    const isMatriculeExist=(exist)=> {
        //setDisableDateEffect(exist)
        setMatriculationExist(exist)
    }

    if(state?.isPacksLoading) {
        return (
            <Card>
                <Spin/> Calcul de quotation en cours ....
            </Card>
        )
    }

    if(state?.isPacksLoadingError) {
        return <ErrorWidget errorMessage={state?.isPacksLoadingErrorMessage} goBack={()=> goToPreviousStep(null)}/>
    }

    return (
        <React.Fragment>
            <AmountHeader packSelected={packSelected}/>
            <Card
                title={(
                    <div style={{ display: 'flex', justifyContent: 'space-betwenn' }}>
                        <div style={{ height: 30, width: '100%', marginTop: -10 }}><h4 style={{ color: 'grey' }}>Choix du pack</h4></div>
                        <div style={{ marginTop: 0 }}>
                            <Dropdown overlay={menu(handlePacksMenuClick)} trigger={['click']}>
                                <Button type="link" onClick={() => console.log("Carte")} style={{paddingRight: "0px"}}>
                                    <SettingOutlined style={{color: 'blue'}}/>
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                )}
                headStyle={{ backgroundColor: 'rgba(233,233,233, 0.5)', color: '#ffffff', height: 15, boxShadow: "0px 0.5px 0.5px gray", }}
                style={{}}
            >
                <AssurancePackage
                    packSelect={basket?.packSelected}
                    quotationRequest={basket?.quotationRequest}
                    packsResponse={basket?.quotationResponse?.packsResponse || []}
                    
                    onPackSelected={onPackSelected} 
                    isPackCarteOn={isPackCarteOn}
                    md={12}
                    />
            </Card>
            <br />
            
            <Card title={(
                <div style={{ display: 'flex', justifyContent: 'space-betwenn' }}>
                    <div style={{ height: 30, width: '100%', marginTop: -10 }}><h4 style={{ color: 'grey' }}>Information complémentaire</h4></div>
                </div>
            )}>
                <CardBody className="p-0">
                    <CustomAntdForm form={form} initialValues={basket?.formField}>
                        <Row gutter={8} className="align-items-end p-3">
                            {!customerBrand ? 
                                <BrandField md={12} categoryCode={basket?.formField?.categoryCode} handlerFormField={updateFormField} setCustomerBrand={setCustomerBrand} />: 
                                <CustomerBrandField md={12} categoryCode={basket?.formField?.categoryCode} handlerFormField={updateFormField} setCustomerBrand={setCustomerBrand}/>
                            }

                            {!customerBrand ? 
                                <ModelField md={12} categoryCode={basket?.formField?.categoryCode}
                                        brandRef={basket?.formField?.brandRef} 
                                        handlerFormField={updateFormField} />:
                                <CustomerModelField md={12} categoryCode={basket?.formField?.categoryCode}/>
                            }
                            <MatriculationField md={12} categoryCode={basket?.formField?.categoryCode} handlerFormField={updateFormField} />
                            <ChassisNumberField md={12} categoryCode={basket?.formField?.categoryCode}/>
                            {
                                [CATEGORY_403].includes(basket?.formField?.categoryCode) ? (
                                    <>
                                        <Row>
                                            <Col className="gutter-row" xs={24} md={24}>
                                                <Checkbox defaultChecked={toogleTrailer} onChange={(e) => toggleTrailerCheckBox(e)} disabled={false} >Disposez-vous d'une remorque ?</Checkbox>
                                            </Col>
                                        </Row> 
                                        <br/>
                                        {toogleTrailer && (
                                            <Row gutter={[8, 8]}>
                                                {!trailerCustomerBrand ? 
                                                        <TrailerBrandField categoryCode={basket?.formField?.categoryCode} trailerBrandRef={basket?.formField?.trailerBrandRef} handlerFormField={updateFormField} setCustomerBrand={setTrailerCustomerBrand} xs={12} md={12}/>: 
                                                        <TrailerCustomerBrandField categoryCode={basket?.formField?.categoryCode} handlerFormField={updateFormField} setTrailerCustomerBrand={setTrailerCustomerBrand} xs={12} md={12}/>
                                                }

                                                {/* {!trailerCustomerBrand ? 
                                                        <TrailerModelField categoryCode={category?.code} brandRef={basket?.formField?.brandRef} handlerFormField={updateFormField}/>:
                                                        <TrailerCustomerModelField categoryCode={category?.code}/>
                                                } */}
                                                <TrailerMatriculationField categoryCode={basket?.formField?.categoryCode} handlerFormField={updateFormField} setMatriculationExist={isMatriculeExist} xs={12} md={12}/>
                                                <TrailerChassisNumberField categoryCode={basket?.formField?.categoryCode} xs={12} md={12}/>
                                            </Row>
                                        ) }   
                                    </>
                                ) : null
                            }
                            
                        </Row>
                    </CustomAntdForm>
                </CardBody>
            </Card>
            <FooterDrawer 
                onConfirm={()=> onPackageValidate()} 
                confirmText={'VALIDER LE PACK'}
                disableConfirm={_.size(packSelected?.guaranties) <= 0}
                onCancel={()=> goBack()}
                cancelText={"Retour"} />
        </React.Fragment>
    )
}

export default FStepPackages;