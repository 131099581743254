import { EyeOutlined } from "@ant-design/icons";
import { Input, Pagination, Table } from "antd";
import PageResponse from "api/response/pageResponse";
import { DEFAULT_TABLE_MAX_SIZE } from "constantes";
import React, { useEffect, useState }  from "react";
import { Link } from "react-router-dom";
import AssureService from "services/assureService";

function AssureTable({ category, title }) {
    const { Search } = Input;
    const [current, setCurrent] = useState(1);

    const [state, setState]=useState({
        initialData: [],
        tempAssureData: [],

        assuresPageResponse: new PageResponse(),
        assureStatus: {loading: true, error: false, errorMessage: ''},

        page: 0, 
        size: 0, 
        totalElements: 0, 
        totalPages: 0
    })

    useEffect(()=> {
        getAllAssureByCategoryRef(category?.ref)
    }, [])

    // LOAD ASSURES
    function getAllAssureByCategoryRef(categoryRef, word, page, size) {
        if (size == null || size === undefined) size = 100;
        if (page == null || page === undefined) page = 0;
        if (word == null || word === undefined) word = null;
        if (categoryRef == null || categoryRef === undefined) categoryRef = null;

        let options = {
            query: {
                categoryRef: categoryRef,
                word: word,
                page: page,
                size: size
            }
        };

        (new AssureService()).readAllAssure(options, (data) => {
            let _pageResponse = (new PageResponse()).fromJson(data);
            const assures = _pageResponse?.content || []
            setState(state => ({
                ...state,
                initialData: assures,
                assuresPageResponse: _pageResponse,
                tempAssureData: assures,
                assureStatus: {loading: false, error: false, errorMessage: ""},

                page: _pageResponse?.page,
                size: _pageResponse?.size,
                totalElements: _pageResponse.totalElements,
                totalPages: _pageResponse.totalPages,
            }))
        }, (error) => {
            setState(state => ({ ...state,  assureStatus: {loading: false, error: true, errorMessage: error} }))
        });
    }

    const onPageChange = (page) => {
        console.log(":::: onPageChange ", page)
        setCurrent(page);
        changePagePaginationHandler(page - 1)
    };

    const onShowSizeChange = (current) => {
        changePagePaginationHandler(current - 1)
    };

    const changePagePaginationHandler= (currentPage) => {
        getAllAssureByCategoryRef(category?.ref, null, currentPage );
    }

    const onSearch= (e)=> {
        const val = e?.target?.value?.toLowerCase()?.trim();
        const temp = state?.initialData?.filter((d)=> {
            return d?.code?.toLowerCase().indexOf(val) !== -1 ||
            d?.firstName?.toLowerCase().indexOf(val) !== -1 ||
            d?.lastName?.toLowerCase().indexOf(val) !== -1 ||
            d?.email?.toLowerCase().indexOf(val) !== -1 || 
            d?.phone?.toLowerCase().indexOf(val) !== -1 ||
            (new Date(d.createdAt).toLocaleDateString()).toLowerCase().indexOf(val) !== -1 ||
            d?.createdAt?.toLowerCase().indexOf(val) !== -1 ||
            !val
        });

        setState(state => ({ ...state, tempAssureData: [...temp] }))
    }

    const column = [
        {
            key: 'code',
            title: 'Code',
            dataIndex: 'code',
            width: 100,
            filterSearch: true,
            filters: (state?.initialData || []).map((item)=> ({
                text: item?.code,
                value: item?.code,
              })),
            onFilter: (value, record) => record?.code?.startsWith(value),
            render: (item, data) => (
                <div style={{fontWeight: "bold"}}>
                    {data.code}
                </div>
            )
        },
        {
            title: 'Nom et prenoms',
            dataIndex: 'firstName',
            key: 'firstName',
            filterSearch: true,
            filters: (state?.initialData || []).map((item, i)=> ({
                
                text: item?.firstName + ' ' + item?.lastName,
                value: item?.firstName+ ' ' + item?.lastName + ' ' + item?.code,
              })),
            onFilter: (value, record) => (record?.firstName+ ' ' + record?.lastName + ' ' + record?.code)?.startsWith(value),
            render: (item, data) => (
                <div >
                    {data.firstName + ' ' + data.lastName}
                </div>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Contact',
            dataIndex: 'phone',
            key: 'phone',
            width: 200
        },
        {
            title: 'Crée le',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 200,
            render: (item, data) => (
                <div>
                    {(new Date(data.createdAt).toLocaleDateString()) + " à " + (new Date(data.createdAt).toLocaleTimeString()) }
                </div>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 50,
            render: (item, assure) => (
                <>
                    
                    <Link to={ `/assure/${assure.code}/contrats`}>
                        <EyeOutlined style={{ color: '#40AEE0', cursor: 'pointer' }} />
                    </Link>
                </>

            )

        },
    ];

    return (
        <React.Fragment>
            {
                title ? (
                    <>
                        <div>
                            <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>{title}</h4>
                        </div><br />
                    </>
                ): null
            }
            
            <Search
                addonBefore="Recherche"
                placeholder="Code assuré, Nom Assure, Contact, Email, Numero Piece ..."
                allowClear
                onSearch={()=>{}}
                style={{}}
                onChange={onSearch}
                onFocus={onSearch}

            /><br />
            <div>
                <Table 
                    key="table_assure"
                    columns={column} 
                    dataSource={state.tempAssureData} 
                    pagination={{
                        position: ["topRight"],
                        defaultPageSize: 10
                    }} 
                    loading={state?.assureStatus?.loading}
                />
                <br />
                <div style={{ textAlign: 'center' }}>
                    <Pagination 
                        current={current} 
                        onChange={onPageChange} 
                        defaultPageSize={DEFAULT_TABLE_MAX_SIZE} 
                        total={state?.totalElements} 
                        showSizeChanger 
                        showTitle={true}
                        onShowSizeChange={onShowSizeChange} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AssureTable;