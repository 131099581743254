import React from "react";

export default function AvenantResiliationPure() {
    /* const {contract, category, avenant, assure, period, projectCode, partnerCode } = useSelector((state) => state.subscription);
    const { currentStep, page, basket, transactionResponse, initAvenantResponse } = useSelector((state) => state.basketSlice);

    const {state, setState, initialiseAvenant} = useInitAvenant();

    const [initStartDate, setInitStartDate] = useState({
        startDate: moment().toDate(),
        endDate: moment().toDate(),
        initialDate: moment().toDate()
    })
    
    useEffect(()=> {
        if(!state.isInitAvenantLoading) {
            setInitStartDate({
                startDate: initAvenantResponse?.startDate,
                endDate:    initAvenantResponse?.endDate,
                initialDate: initAvenantResponse?.startDate
            });
        }
    }, [state?.isInitAvenantLoading])

    if(state.isInitAvenantLoading) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if(state.iniAvenantLoadingError) {
        return <ErrorWidget errorMessage={state.iniAvenantLoadingErrorMessage} />;
    }

    if(page === helpers.PAGES.THANK_YOU) {
        return <StepThankYou transactionResponse={transactionResponse} />;
    }

    const goBack=(basket)=> {
        const _basket = basket ? basket : mBasket;
        dispatch(mGoToPreviousStep([
            { path: ['mBasket'], value: { ..._basket } }
        ]))
    }

    const paymentStatus=(status)=> {
        if(!status) {
          toast.error("Une erreur s'est produite");
          return;
        }
    
        if(status && status?.error) {
          toast.error(status.errorMessage);
          return;
        }
        dispatch(mGoToNextStep([
          { path: ['paymentStatus'], value: status },
          { path: ['transactionResponse'], value: status?.transactionResponse },
          { path: ['page'], value: helpers.PAGES.THANK_YOU },
        ]))
    }

    function renderChildren() {
        // console.log('on arrive ici 1111111');
        switch (currentStep) {
            case 0:
                return <StepDetails 
                            subscriptionRequest={mBasket?.subscriptionRequest}
                            packSelected={mBasket?.packSelected}
                            rangeDate={{
                                startDate: initStartDate?.startDate, 
                                endDate: initStartDate?.endDate,
                                initialDate: initStartDate?.startDate
                            }}
                            goBack={goBack}

                            paymentStatus={paymentStatus} />;
            default:
                return <ErrorWidget errorMessage="Ooops la page n'existe pas" />;
        }
    } */

    return (
        {/* <React.Fragment>
            <WizardHeader
                title={`Categorie ${category?.code} : ${category?.title}`}
                avenantTilte="Avenant de resiliation sans ristoune"
                percent={100}
            />
            {renderChildren()}
        </React.Fragment> */}
    )
}