import Assure from "api/model/assure.model";
import Period from "api/model/period.model";

export default class FSubscriptionRequest {
    
    contractRef; // Utile lorsqu'on choisit un avenant autre que avenant de création

    offering;
    avenantRef;
    avenantTitle;
    
    productRef;
    productTitle;
    
    period = new Period()
    periodRef;
    periodTitle;
    customerPeriod=false;
    customerStartDate;
    customerEndDate;
    prorate=false;
    
    primeTTC;
    primeNette;
    primeExtra;
    agencyFees = 0;

    dateEffect;
    
    assure = new Assure();

    paymentProviderRef;

    promoCode="";
    canal = "BO";
    isProject = false;
    projectCode = "";

    capitalRef;

    autos= [];

    static fromJson = (json={})=> {
        var fSubscriptionRequest = new FSubscriptionRequest();
        fSubscriptionRequest = json;
        return fSubscriptionRequest
    }
}