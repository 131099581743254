import React, { useEffect, useState } from "react";
import WizardHeader from "modules/components/WizardHeader";
import { Card, Spin } from "antd";
import ErrorWidget from "components/Widgets/ErrorWidget";
import { FStepDetails, FStepThankYou } from "./steps";
import useInitFlotteAvenant from "../useInitFlotteAvenant";
import * as helpers from 'helpers/helpers';
import { useSelector } from "react-redux";
import QuotationService from "services/quotationService";
import { useBasket } from "views/pages/subscription/hooks/useBasket";
import QuotationResponse from "api/v1/response/QuotationResponse";
import _ from "lodash";

const FAvenantRenouvellementSansModification= ()=> {
    const {avenant, contract, category,projectCode, partnerCode} = useSelector((state) => state.subscription);
    const {
        currentStep, 
        page,
        baskets,
        fSubscriptionRequest, 
        quotationResponse, 
        transactionResponse,
        initAvenantResponse
    } = useSelector((state) => state.basketSlice);
    const {updateGroup} = useBasket();
    const { state } = useInitFlotteAvenant();
    const [quoting, setQuoting] = useState(true);

    useEffect(()=> {
        if(!state.avenantStatus?.loading) {
            const quotationRequests = [];
            console.log("baskets [] :", baskets)
            for(const basket of baskets) {
                quotationRequests.push({
                    ...basket.quotationRequest,
                    calculateGuarantee: true,
                    contractRef: contract?.ref,
                    prorate: false
                })
            }

            QuotationService.quote({quotationRequests: [...quotationRequests], t:'f'}, (result)=> {
                const quotationResponse = QuotationResponse.fromJson(result);
                const primeTTC = quotationResponse?.primeTTC
                const primeExtra = quotationResponse?.primeExtra;
                const primeNette = quotationResponse?.primeNette;
                //const extras = quotationResponse?.extras || []
                //const pack = _.first(quotationResponse?.packsResponse)

                // Update each basket
                updateGroup([
                    {path: ['fSubscriptionRequest'], value: {
                        ...fSubscriptionRequest,
                        primeTTC    : primeTTC,
                        primeNette  : primeExtra,
                        primeExtra  : primeNette
                    }},
                    {path: ["quotationResponse"], value: {...quotationResponse}}
                ])

                setQuoting(false)
            }, (error)=> {
                
            })
        }
    }, [state.avenantStatus?.loading])

    if(state.avenantStatus?.loading || quoting) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if(state.avenantStatus?.error) {
        return <ErrorWidget errorMessage={state.avenantStatus?.errorMessage} />;
    }

    function renderChildren() {
        
        switch (currentStep) {
            case 0:
                return <FStepDetails
                            avenantCode={avenant?.code}
                            endDate={initAvenantResponse?.endDate}
                            initAvenantResponse={initAvenantResponse}
                            quotationResponse={quotationResponse} 
                            fSubscriptionRequest={fSubscriptionRequest}
                         />
            case 1:
                if(page === helpers.FlotteWidget.THANK_YOU) {
                    return <FStepThankYou transactionResponse={transactionResponse} />
                }

                break;

            default:
                return <ErrorWidget errorMessage="Ooops ! la page n'existe pas" />;
        }
    }

    return (
        <React.Fragment>
             <WizardHeader
                    title={`Categorie ${category?.code} : ${category?.title}`}
                    avenantTilte={`Avenant de renouvellement sans modification`}
                    partnerCode={partnerCode}
                    police={projectCode}
                    //onClick={(state.currentStep > 0 && state.currentStep < 4) ? goToPreviousStep : null}
                    percent={currentStep * 100}
                    />
            {renderChildren()}
        </React.Fragment>
    )
}

export default FAvenantRenouvellementSansModification;