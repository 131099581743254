import { Tag } from "antd";
import React from "react";
import { Card } from "reactstrap";
import { thousandSeparator } from "helpers/helpers";


const FDetailsHeader =({primeTTC})=> {
    return (
        <React.Fragment>
            <div style={{ height: 30, width: '100%', marginTop: 15, marginRight: 20, display: 'flex', justifyContent: "end" }}>
                <h4 style={{ color: 'grey', textAlign: "center", marginRight: "5px" }}>Montant à payer</h4>
                <h4 style={{ color: '#52c41a', size: 'large', fontSize: 22 }}><Tag color= "rgb(0, 117, 201)" style={{ fontSize: 22, padding: "2px"}}>{thousandSeparator(primeTTC)} FCFA</Tag></h4>
            </div>
        </React.Fragment>
    )
}

export default FDetailsHeader;