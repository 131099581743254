import { Table, DatePicker, Button, Form, Input, Pagination } from "antd";
import PageResponse from "api/response/pageResponse";
import { DEFAULT_TABLE_MAX_SIZE } from "constantes";
import { CAN_EXTRAT_REPORTING_FOR_OTHER } from "profileActions";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardBody, CardHeader,  Row, Col } from "reactstrap";
import {  DocumentType, onDocumentDownload } from "helpers/helpers";
import ReportingService from "services/reportingService";

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

export default function Rapporting(props) {
    const { user} = useSelector((state) => state.auth);
    const [partnerCode, setFiles] = useState('partnerCode');
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const [current, setCurrent] = useState(1);
    const [seachOption, setSearchOption]= useState({
        startDate: null,
        endDate: null,
        partnerCode: null
    })
    const [state, setState] = useState({
        reportings: [],
        isReportingLoading: false,
        isGenerateReportLoading: false,
        inputSaissie: "",
        page: 0,
        size: 100, 
        totalElements: 0, 
        totalPages: 0
    });

    useEffect(()=>{
        //readReportings(null, null, 0);
    }, [])

    const onPageChange = (page) => {
        console.log(":::: onPageChange ", page)
        setCurrent(page);
        refresh();
    };

    const onShowSizeChange = (current) => {
        refresh();
    };

    const columns = [
        {
            title: "N°",
            dataIndex: "number",
            key: "number",
            width: 50
        },
        {
            title: "Police",
            dataIndex: "contractPoliceNumber",
            key: "contractPoliceNumber",
            width: 150
        },
        {
            title: "Avenant",
            dataIndex: "avenantTitle",
            key: "avenantTitle",
            width: 150
        },
        {
            title: "Agence",
            dataIndex: "agence",
            key: "agence",
            width: 150
        },
        {
            title: "Effet",
            dataIndex: "contractStartDate",
            key: "contractStartDate",
            width: 100
        },
        {
            title: "Echéance",
            dataIndex: "contractEndDate",
            key: "contractEndDate",
            width: 100
        },
        {
            title: "Usage vehicule",
            dataIndex: "usage",
            key: "usage",
            width: 150
        },
        {
            title: "Marque",
            dataIndex: "marque",
            key: "marque",
            width: 100
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "model",
            width: 100
        },
        {
            title: "Puissance fiscale",
            dataIndex: "puissance",
            key: "puissance",
            width: 100,
            align: 'center'
        },
        {
            title: "Energie",
            dataIndex: "energie",
            key: "energie",
            width: 100
        },
        {
            title: "Nombre de place",
            dataIndex: "numberPlace",
            key: "numberPlace",
            width: 100,
            align: 'center'
        },
        {
            title: "Immatriculation",
            dataIndex: "matriculation",
            key: "matriculation",
            width: 120
        },
        {
            title: "N° chassis",
            dataIndex: "chassisNumber",
            key: "chassisNumber",
            width: 150
        },
        {
            title: "Valeur à neuf",
            dataIndex: "valeurNeuf",
            key: "valeurNeuf",
            width: 100,
            align: 'right'
        },
        {
            title: "Valeur venale",
            dataIndex: "valeurVenale",
            key: "valeurVenale",
            width: 100,
            align: 'right'
        },
        {
            title: "RC",
            dataIndex: "rc",
            key: "rc",
            width: 100,
            align: 'right'
        },
        {
            title: "CEDEAO",
            dataIndex: "cedeao",
            key: "cedeao",
            width: 100,
            align: 'right'
        },
        {
            title: "Incendie",
            dataIndex: "incendie",
            key: "incendie",
            width: 100,
            align: 'right'
        },
        {
            title: "Vol",
            dataIndex: "vol",
            key: "vol",
            width: 100,
            align: 'right'
        },
        {
            title: "Vol par braquage",
            dataIndex: "volBraquage",
            key: "volBraquage",
            width: 100,
            align: 'right'
        },
        {
            title: "Vol à main armée",
            dataIndex: "volAccessoire",
            key: "volAccessoire",
            width: 100,
            align: 'right'
        },
        {
            title: "Tierce collision",
            dataIndex: "tierceCollision",
            key: "tierceCollision",
            width: 100,
            align: 'right'
        },
        {
            title: "Tiece complète",
            dataIndex: "tierceComplexe",
            key: "tierceComplexe",
            width: 100,
            align: 'right'
        },
        {
            title: "Avance sur recours",
            dataIndex: "avenantRecours",
            key: "avenantRecours",
            width: 100,
            align: 'right'
        },
        {
            title: "Vandalisme",
            dataIndex: "vandalisme",
            key: "vandalisme",
            width: 100,
            align: 'right'
        },
        {
            title: "Protection conducteur",
            dataIndex: "protectionConduction",
            key: "protectionConduction",
            width: 100,
            align: 'right'
        },
        {
            title: "Decès proprietaire",
            dataIndex: "decesPraccessoireoprietaire",
            key: "decesPraccessoireoprietaire",
            width: 100,
            align: 'right'
        },
        {
            title: "Personne Transportées",
            dataIndex: "personnelTransport",
            key: "personnelTransport",
            width: 100,
            align: 'right',
        },
        {
            title: "Prime nette totale",
            dataIndex: "primeNette",
            key: "primeNette",
            width: 100,
            fixed: 'right',
            align: 'right'
            
        },
        {
            title: "Cout de police",
            dataIndex: "accessoire",
            key: "accessoire",
            width: 100,
            fixed: 'right',
            align: 'right'
        },
        {
            title: "Taxe",
            dataIndex: "taxe",
            key: "taxe",
            fixed: 'right',
            width: 100,
            align: 'right'
        },
        {
            title: "FGA",
            dataIndex: "fga",
            key: "fga",
            fixed: 'right',
            width: 100,
            align: 'right'
        },

        {
            title: "Prime totale",
            dataIndex: "total",
            key: "total",
            width: 100,
            fixed: 'right',
            align: 'right'

        }
    ];

    // REFRESH TABLE
    const refresh=()=> {
        form.validateFields().then((value)=> {
            const rangeDate = value?.rangeDate;
            const startDate = rangeDate[0]?.format("YYYY-MM-DD");
            const endDate   = rangeDate[1]?.format("YYYY-MM-DD");
            const partnerCode = value?.partnerCode === undefined || value?.partnerCode === null || value?.partnerCode === '' ? null : value?.partnerCode ;

            if(!startDate || !endDate) {
                toast.error("veuiller choisir une date");
                return;
            }

            readReportings(startDate, endDate, partnerCode, current-1);   
        })
        
    }

    const onDownloadReporting=()=> {
        setState(state => ({ ...state, isGenerateReportLoading: true }));
        const options = {
            query: { 
                startDate: seachOption.startDate, 
                endDate: seachOption.endDate, 
                partnerCode: seachOption.partnerCode  
            }};
        (new ReportingService()).generateReporting(options, (data)=> {
            try {
                console.log("response", data);
                if(data?.error) {
                    toast.info(data?.errorMessage);
                    setState(state => ({ ...state, isGenerateReportLoading: false,  reportings: [], totalElements: 0, page: 0}));
                }
                else {
                    // SHEETJS
                    //const wb = read(data?.document, {type: "base64" });
                    //writeFileXLSX(wb, "bordereau.xlsx", {type: "base64" })

                    // GILLE
                    //var byte= base64ToArrayBuffer(data?.document);
                    //saveByteArray(`${user.entities?.code}_liste-des-vehicules_${options.query.startDate}_au_${options.query.endDate}.xlsx`, byte);
                    
                    //saveAsXlsxFile(data?.document);
                    onDocumentDownload(data?.filename);
                    
                }

                setState(state => ({ ...state, isGenerateReportLoading: false }));
            }
            catch(error) {
                toast.error("Une erreur s'est produite pendant la generation");
                setState(state => ({ ...state, isGenerateReportLoading: false }));
            }
            
            
        }, (error)=> {
            setState(state => ({ ...state, isGenerateReportLoading: false }));
            toast.error(error)
        });        
    }

    // GENERATED RAPPORT
    const generateReport =(values) => {
        setState(state => ({ ...state, isGenerateReportLoading: true }));
        form.validateFields().then((value)=> {
            const rangeDate = value.rangeDate;
            const startDate = rangeDate[0].format("YYYY-MM-DD");
            const endDate = rangeDate[1].format("YYYY-MM-DD");

            let options = {
                query: { startDate: startDate, endDate: endDate }
            };

            (new ReportingService()).generateReporting(options, (data)=> {
                setState(state => ({ ...state, isGenerateReportLoading: false }));
                console.log("response", data);
                if(data?.error) {
                    toast.info(data?.errorMessage);
                    setState(state => ({ ...state, isReportingLoading: false,  reportings: [], totalElements: 0, page: 0}));
                }
                else {
                    
                    onDocumentDownload(DocumentType.REPPORT,  `rapport_liste_vehicule_${data?.entitiesCode}.xlsx`);
                    readReportings(startDate, endDate, 0);
                }
                
            }, (error)=> {
                setState(state => ({ ...state, isGenerateReportLoading: false }));
                toast.error(error)
            });
        })
    
    }

    // READ ALL REPORT
    const readReportings = (startDate, endDate, partnerCode, page, size) => {
        setState(state => ({ ...state, isReportingLoading: true }));
        const options = {
            query: { 
                startDate: startDate, 
                endDate: endDate, 
                partnerCode: partnerCode,
                page: null,
                size: null
             }
        };
        (new ReportingService()).readAllReporting(options, (data)=> {
            let pageResponse = (new PageResponse).fromJson(data);
            setState(state => ({ ...state, isReportingLoading: false,  reportings: pageResponse.content, totalElements: pageResponse.totalElements, page: pageResponse.page}));
            setSearchOption((seachOption=> ({...seachOption, startDate: startDate, endDate: endDate, partnerCode:  partnerCode})));
        }, (error)=> {
            setState(state => ({ ...state, isReportingLoading: false }));
            toast.error(error)
        });
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <Form {...layout} name="control-ref" form={form} onFinish={refresh} >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="rangeDate" label="Choisir date debut et fin"
                                            rules={[{ required: true }]}
                                        >
                                            <RangePicker
                                                //showTime={{ format: 'HH:mm' }}
                                                size={'large'}
                                                format={"DD/MM/YYYY"}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {
                                        CAN_EXTRAT_REPORTING_FOR_OTHER.includes(user?.profile) ? (
                                            <Col span={2} md={2}>
                                                <Form.Item
                                                    name="partnerCode" 
                                                    label="Saisir le code intermediaire"
                                                    rules={[{ required: false }]}
                                                
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder="Code "
                                                    // onChange={onChange}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ): <></>
                                    }
                                    <Col span={5} md={6} className="text-right">
                                        <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                            <div className="col-auto">
                                                <Button
                                                    className="btn-label left"
                                                    
                                                    type="primary"
                                                    loading={state.isReportingLoading}
                                                    style={{ marginTop: 40, justifyContent: 'center' }}
                                                    htmlType="submit"
                                                >
                                                    <i className=" bx bx-refresh label-icon align-middle fs-16"></i>
                                                    Actualisé
                                                </Button>
                                            </div>
                                            <div style={{marginLeft:5}}>
                                                <div className="col-auto">
                                                    <Button loading={state.isGenerateReportLoading} color="primary" className="btn-label right" style={{ marginTop: 40, justifyContent: 'center' }} onClick={onDownloadReporting}> <i className=" bx bx-download label-icon align-middle fs-16 ms-2"></i> Télécharger </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Table 
                                    columns={columns} 
                                    dataSource={state.reportings}
                                    pagination={{ position: ["topRight"], pageSize: 10 }}
                                    scroll={{ x: 1400 }} 
                                    key="reportTable"
                                    loading={state.isReportingLoading}
                                    
                                /><br />
                                <div style={{ textAlign: 'center' }}>
                                    <Pagination current={current} onChange={onPageChange} defaultPageSize={DEFAULT_TABLE_MAX_SIZE} total={state.totalElements} showSizeChanger onShowSizeChange={onShowSizeChange} />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}