import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";

import Payment from "views/pages/subscription/component/payment/Payment";
import { ContractType } from "views/pages/subscription/component/ComponentWidget";
import { MDetailOthers, MDetailPackage, MDetailPeriod, MDetailPrime, MDetailStartDate } from "../../common/details";
import { useBasket } from "views/pages/subscription/hooks/useBasket";
import SubscriptionRequestBuilder from "api/request/SubscriptionRequestBuilder";

const StepDetails = ({
    avenantCode,
    endDate,
    subscriptionRequest,
    packSelected
}) => {

    const { initStartDate, setInitStartDate, onStartDateChange}=useBasket();

    useEffect(() => {
        setInitStartDate({
            startDate: subscriptionRequest?.customerStartDate ?? subscriptionRequest?.dateEffect,
            endDate: endDate,
            initialDate: subscriptionRequest?.customerStartDate ?? subscriptionRequest?.dateEffect
        });
    }, [])

    return (
        <React.Fragment>
            <div /* className="page-content" */>
                <Container fluid>

                    <Row>
                        <Col className="col-xxl-9 order-xxl-0 order-first">
                            <Row>
                                <MDetailPackage packTitle={packSelected?.packTitle} />
                                <MDetailPeriod perioTitle={packSelected?.period?.title} />
                                <MDetailStartDate rangeDate={initStartDate} onStartDateChange={onStartDateChange} />
                            </Row>
                            <MDetailOthers
                                vehicle={subscriptionRequest?.vehicle}
                                assure={subscriptionRequest?.assure}
                            />
                        </Col>
                        <MDetailPrime
                            primeTTC={packSelected?.primeTTC}
                            primeNette={packSelected?.primeNette}
                            extras={packSelected?.extras}
                        />

                    </Row>
                    <Row>
                        <Col className="col-xxl-12">
                            <Payment
                                onlineVisiblity={true}
                                payload={SubscriptionRequestBuilder.fromThis(avenantCode, subscriptionRequest)}
                                type={ContractType.MONO}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default StepDetails;