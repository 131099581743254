import { EditOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Space, Table, Tooltip, Input, Tag, Drawer} from "antd";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { ActionType } from "helpers/helpers";
import * as helpers from 'helpers/helpers';
import ReturnNewTitle from "views/pages/common/ReturnNewTitle";
import ReturnNewLine from "views/pages/common/ReturnNewLine";
import { useBasket } from "../../../../hooks/useBasket";
import VehicleService from "services/vehicleService";
import FSubscriptionWidget from "../../../common/FSubscriptionWidget";
import QuotationService from "services/quotationService";
import BasketSerivce from "services/basketService";
import FStepsWidget from "../component/FStepsWidget";
import Basket from "api/model/basket";
import QuotationResponse from "api/v1/response/QuotationResponse";
import { toast } from "react-toastify";
import _ from "lodash";
import BackNextWidget from "../../../common/BackNextWidget";

const FStepListVehicle=({baskets, basket, isProject })=> {
    const { Search } = Input;
    const {updateBaskets,updateBasket, goToNextStep, updateGroup, updateFSusbscriptionRequest} = useBasket();
    const [quoting, setQuoting]= useState(false);
    const [matriculeExist, setMatriculeExist] = useState({
        exist: false,
        errorMessage: '',
        value: ""
    });
    const [chassisExist, setChassisExist] = useState({
        exist: false,
        value: ""
    });

    const [dataSource, setDataSource] = useState(baskets || [])
    const [detailVehicleDrawer, setDetailVehicleDrawer] = useState({
        visible: false,
        basket: null
    });

    const [action, setAction]= useState(ActionType.ADD);

    const [openVehicleDrawer, setOpenVehicleDrawer] = useState(false);
    const [accessorySettingVisibility, setAccessorySettingVisibility] = useState(false);

    const [state, setState]=useState({
        primeTotalHF : 0,
        primeTotalTTC : 0,
        nbVehicle: 0,
        reductionsWithoutFlotte: [],
        reductionFlotte: {},
        isQuotingFleet: true
    })

    useEffect(()=> {
        console.log(":::: BAsket in StepList ", basket)
    }, [])

    useEffect(()=> {
        if(isProject) {
            const _baskets = [];
            for(const b of baskets) {
                const __basket = {
                    ...b,
                    formField: {
                        ...b?.formField,
                        periodRef: basket?.formField?.periodRef,
                        periodTitle: basket?.formField?.periodTitle,
                        socialPosRef: basket?.formField?.socialPosRef,
                        socialPosTitle: basket?.formField?.socialPosTitle
                    },
                    quotationRequest: {
                        ...b?.quotationRequest,
                        periodRef: basket?.formField?.periodRef,
                        socialPosRef: basket?.formField?.socialPosRef
                    }
                }
                _baskets.push({...__basket})
            }

            console.log("Peoject: formfield", _baskets)
            updateBaskets(_baskets);
            quoteRequest(_baskets)
          }
          else {
            quoteRequest(baskets, null, false)
          }
    }, [])

    useEffect(()=> {
        setDataSource(baskets) 
    }, [baskets]); 

    const onCloseQuotationDrawer=()=> {
        quoteRequest(baskets)
    }

    const quoteRequest=(lbaskets, customPrimeAcc=null, init=true)=> {
        setState(state=> ({...state, isQuotingFleet: true}))

        if(lbaskets?.length === 0) {
            setState(state=> ({...state, isQuotingFleet: false}))
            return;
        }

        // Build baskets quotationRequests
        const quotationRequests= []
        for(const lbasket of lbaskets) {
            quotationRequests.push({
                ...lbasket?.quotationRequest,
                customPrimeAcc: customPrimeAcc,
                customGuaranties: lbasket?.packSelected?.customGuaranties,
                reductionGroups: lbasket?.packSelected?.reductionGroups,
                customAssistances: lbasket?.packSelected?.customAssistances,
                packLineRef: lbasket?.packSelected?.packLineRef,
                prorate: false,
            })
        }

        // Quote now
        QuotationService.quote({quotationRequests: [...quotationRequests], t: 'f', init: init}, (result)=> {
            const _qutotationResponse = QuotationResponse.fromJson(result);                
            const reductionFlotte = QuotationService.getFlotteReduction(_qutotationResponse?.reductionGroups)
            const reductionsWithoutFlotte = QuotationService.getFlotteReductionWithoutFlotte(_qutotationResponse?.reductionGroups);
            
            // Mettre à jour les baskets
            const _baskets = [];
            _.forEach(lbaskets, (basket, index)=> {
                const _packSelected = _qutotationResponse?.packsResponse[index];
                if(_packSelected) {
                    _baskets.push({
                        ...basket,
                        packSelected: {
                            ..._packSelected,
                            customPrimeAcc: customPrimeAcc,
                            customGuaranties: basket?.packSelected?.customGuaranties,
                            reductionGroups: basket?.packSelected?.reductionGroups,
                            customAssistances: basket?.customAssistances,
                            customPrimeAcc: customPrimeAcc
                        }
                    })
                }
            })

            // Met à jour la vue
            setState(state=> ({
                ...state,
                primeTotalHF: _qutotationResponse?.primeTHFTTC,
                primeFlotteTTC: _qutotationResponse?.primeTTC,
                reductionsWithoutFlotte: reductionsWithoutFlotte,
                reductionFlotte: reductionFlotte,
                nbVehicle: _qutotationResponse?.nbVehicle,
                isQuotingFleet: false
            }));

            updateBaskets(_baskets)
            updateGroup([
                {path: ['quotationResponse'], value: {..._qutotationResponse}}
            ])
        }, (error)=> {
            toast.error("Echec de calcul de cotation flotte")
        })
    }

    const checkMatriculation=(e) => {

        let _matriculation = e.target.value;
        if(_matriculation == null || _matriculation === undefined || _matriculation==="") {
            return;
        }

        const _basket = dataSource.find((__basket)=> __basket?.formField?.matriculation == _matriculation);
        setMatriculeExist(matriculeExist=> ({...matriculeExist, exist: false, errorMessage: ''}));
        if(_basket) {
            setMatriculeExist(matriculeExist=> ({...matriculeExist, exist: true, errorMessage: "Le matricule existe déjà"}));
            return;
        }
        VehicleService.verifyMatriculation({matriculation: _matriculation}, (data) => {
            setMatriculeExist(matriculeExist=> ({...matriculeExist, exist: false, errorMessage: '', value: _matriculation}));
        }, (error) => {
            setMatriculeExist(matriculeExist=> ({...matriculeExist, exist: true, errorMessage: error}));
        })
    }

    const onEditMatriculation =(basket)=> {
        if(!matriculeExist.exist && matriculeExist.value) {
            const collectBaskets = (dataSource || []).map((basketDataSource, i)=> {
                if(basketDataSource?.id === basket?.id) {
                    console.log("basketDataSource : ", basketDataSource)
                    const _basketDataSource = {
                        ...basketDataSource,
                        formField: {
                            ...basketDataSource.formField,
                             matriculation: matriculeExist.value
                        }
                    }
                   // basketDataSource.formField.matriculation = matriculeExist.value;
                   return _basketDataSource;
                }
                return basketDataSource;
            });

            console.log("collectBaskets : ", collectBaskets);

            updateBaskets(collectBaskets);
        }
    }

    const checkChassis=(e) => {
        const _basket = dataSource.find((__basket)=> __basket?.formField?.chassisNumber == e?.target?.value);
        if(_basket) {
            //setChassisExist(chassisExist=> ({...chassisExist, exist: true}));
            setChassisExist(chassisExist=> ({...chassisExist, exist: false, value: e?.target?.value }));
        }
        else {
            setChassisExist(chassisExist=> ({...chassisExist, exist: false, value: e?.target?.value }));
        }
    }

    const onEditChassisNumber=(basket)=> {
        if(!chassisExist.exist) {
            const collectBaskets = (dataSource || []).map((basketDataSource, i)=> {
                if(basketDataSource?.id === basket?.id) {
                    console.log("basketDataSource : ", basketDataSource)
                    const _basketDataSource = {
                        ...basketDataSource,
                        formField: {
                            ...basketDataSource.formField,
                            chassisNumber: chassisExist.value
                        }
                    }
                   // basketDataSource.formField.matriculation = matriculeExist.value;
                   return _basketDataSource;
                }
                return basketDataSource;
            });

            console.log("collectBaskets : ", collectBaskets);

            updateBaskets(collectBaskets);
        }
    }

    const   onTableAction = (action, lbasket)=> {
        switch(action) {
            case helpers.ActionType.DETAILS:
                setDetailVehicleDrawer({basket: lbasket, visible: true})
            break;

            case helpers.ActionType.COPY:
                const basket = {...lbasket}
                basket.id = null
                basket.formField = {...basket.formField, matriculation: null, chassisNumber: null };

                const _baskets = BasketSerivce.addBasketOnFleet(basket, baskets);

                updateBaskets(_baskets);
                quoteRequest(_baskets)
            break;

            case helpers.ActionType.EDIT:
                console.log("Edit basket :", lbasket)
                updateBasket(lbasket)
                setAction(ActionType.EDIT)
                setOpenVehicleDrawer(true);
            break;

            case helpers.ActionType.DELETE:
                const __baskets = BasketSerivce.removeBasketFromFleet(lbasket, baskets)
                updateBaskets(__baskets)
                quoteRequest(__baskets)
            break;

            default:
        }
    }
 
    const columns = [
        {
            title: 'N°',
            dataIndex: 'id',
            key: 'id',
            render: (item, basket)=> {
                return (
                    <div>{basket?.id}</div>
                )
            }
        },
        {
            title: 'Cat.',
            dataIndex: 'category',
            key: 'category',
            render: (item, basket)=> {
                return (
                    <div>{basket?.packSelected?.category?.code}</div>
                )
            }
        },
        {
            title: 'Marque',
            dataIndex: 'formField',
            key: 'brandTitle',
            render: (formField, basket)=> <div>{formField?.brandTitle}</div>
        }, 
        {
            title: 'Modèle',
            dataIndex: 'formField',
            key: 'modelTitle',
            render: (formField, index)=> <div>{formField?.modelTitle}</div>
        },
        {
            title: 'Matricule',
            dataIndex: 'formField',
            render: (formField, basket)=> {
                
                return (
                    (!formField?.matriculation)  ? (
                        <Form.Item
                            label=""
                            name="matriculation"
                            rules={[{ required: false, message: "Champ obligatoire" }]}
                        >
                            <div className="input-group">
                                <Input
                                    type="text"
                                    className="form-control bg-light border-1 is-invalid"
                                    placeholder="Numero d'Immatriculation du vehicule"
                                    onChange={checkMatriculation}
                                    
                                />
                                <button className="btn btn-primary" type="button" id="button-addon2" onClick={()=> onEditMatriculation(basket)}><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{" "}</button>
                            </div>
                            
                            {matriculeExist?.exist ? (<span style={{ color: 'red', fontSize:11 }}>{matriculeExist.errorMessage}</span>) : null}
                            
                        </Form.Item>
                        
                    ) : <div>{formField?.matriculation}</div>
                
                )
            }
        },
        {
            title: 'N° chassis',
            dataIndex: 'formField',
            key: 'chassisNumber',
            render: (formField, basket)=> {
                return (
                    !formField?.chassisNumber && onEditChassisNumber ? (
                        <Form.Item
                            label=""
                            name="chassisNumber"
                            rules={[{ required: true, message: "Champ obligatoire" }]}
                        >
                            <div className="input-group">
                                <Input
                                    type="text"
                                    className="form-control bg-light border-1 is-invalid"
                                    placeholder="Numero de chassis"
                                    onChange={checkChassis}
                                /> <button className="btn btn-primary" type="button" id="button-addon2" onClick={()=> onEditChassisNumber(basket)}>
                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{" "}
                                </button>
                            </div>
                            
                            {/* {chassisExist?.exist ? (<span style={{ color: 'red', fontSize:11 }}>Le matricule existe déjà</span>) : null} */}
                            
                        </Form.Item>
                        
                    ): <div>{formField?.chassisNumber}</div>
                );
            }
        },
        {
            title: 'Pack',
            dataIndex: 'packSelected',
            key: 'packTitle',
            render: (packSelected, basket)=> (
                <div>{packSelected?.packTitle}</div>
            )
        },
        {
            title: 'Prime au condit. mono',
            dataIndex: 'primeHFTTC',
            key: 'primeHFTTC',
            align:  "right",
            render: (packSelected, basket)=> (
                <Tag color="volcano" key={`mono_${basket?.id}`}>
                    <div style={{fontWeight: 'bold'}}>{helpers.thousandSeparator(basket?.packSelected?.primeHFTTC)} F CFA</div>
                </Tag>
            )
        },
        {
            title: 'Prime au condit. flotte',
            dataIndex: 'packSelected',
            align:  "right",
            key: 'packSelected',
            render: (packSelected, basket)=> (
                <Tag color="green" key={`flotte_${basket?.id}`}>
                    <div style={{fontWeight: 'bold'}}>{helpers.thousandSeparator(packSelected?.primeTTC)} F CFA</div>
                </Tag>
            )
        },
        {
            title: 'Action',
            render: (item, basket) => (
                <div style={{ display: 'flex' }}>
                    <Space>
                        <Tooltip title="Details">
                            <EyeOutlined style={{ color: '#40AEE0' }}  onClick={()=> setDetailVehicleDrawer({visible: true, basket: {...basket}})}/>
                        </Tooltip>
                        {
                            onTableAction && quoteRequest ? (
                                <>
                                    <Tooltip title="Modifier">
                                        <EditOutlined style={{ color: '#40AEE0' }}  onClick={()=> onTableAction(ActionType.EDIT, basket)}/>
                                    </Tooltip>
                                    {/* <Tooltip title="Dupliquer">
                                        <CopyOutlined style={{ color: '#40AEE0' }}  onClick={()=> onTableAction(ActionType.COPY, basket)}/>
                                    </Tooltip> */}
                                    {/* <Tooltip title="Supprimer">
                                        <Popconfirm
                                            placement="top"
                                            title="Voulez-vous vraiment supprimer le projet ?"
                                            cancelText="Annuler"
                                            okText="Confirmer"
                                            okType="danger"
                                            onConfirm={()=> onTableAction(ActionType.DELETE, basket)}
                                            
                                        >
                                            <DeleteOutline style={{ color: 'red', fontSize: 18 }}/>
                                            
                                        </Popconfirm>
                                        
                                    </Tooltip> */}
                                </>
                            ): (<></>)
                        }

                    </Space>
                </div>

            )
        }
    ];

    const onAccessorySettingValidate=(e)=> {
        if((e || e !== '') && baskets?.length > 0) {
            quoteRequest(baskets, parseInt(e));
        }
        

        console.log(":::: e ", e)
    }

    const onValidateBasket=(basket)=> {
        const _baskets = action === ActionType.ADD ? BasketSerivce.addBasketOnFleet(basket, baskets) : BasketSerivce.updateBasketOnFleet(basket, baskets)
        
        console.log(":::: Global basket to quote fleet : ", _baskets)
        // ========= Update global basket
        updateBaskets(_baskets);

        let _basket = new Basket();
        _basket.formField.socialPosRef    = basket?.formField?.socialPosRef;
        _basket.formField.socialPosTitle  = basket?.formField?.socialPosTitle; 
        _basket.formField.periodRef       = basket?.formField?.periodRef;
        _basket.formField.periodTitle     = basket?.formField?.periodTitle;
        _basket.formField.startDate       = basket?.formField?.startDate;

        _basket.formField.assureCode = basket?.formField?.assureCode; 
        _basket.formField.assureFirstname = basket?.formField?.assureFirstname;
        _basket.formField.assureLastname = basket?.formField?.assureLastname;
        _basket.formField.assurePhone = basket?.formField?.assurePhone;
        _basket.formField.assureEmail = basket?.formField?.assureEmail;
        _basket.formField.assureBirthDay = basket?.formField?.assureBirthDay;
    
        _basket.formField.assureIdentityCardRef = basket?.formField?.assureIdentityCardRef; // String of Card Object
        _basket.formField.assureIdentityCardTitle = basket?.formField?.assureIdentityCardTitle;
        _basket.formField.assureIdentityCardNumber = basket?.formField?.assureIdentityCardNumber;

        updateBasket(_basket);
        // ================================
        setOpenVehicleDrawer(false)
        quoteRequest(_baskets, null, false)
    }

    const goNextStep=()=>{
        updateFSusbscriptionRequest({isProject: false, projectCode: "", isProrate: false})
        goToNextStep()
    }
    return(
        <React.Fragment>
            <FSubscriptionWidget 
                visibility={baskets?.length > 0}
                primeTotalHF={state.primeTotalHF} 
                primeFlotteTTC={state?.primeFlotteTTC}
                rate={state?.reductionFlotte?.rate}
                nbVehicle={state.nbVehicle}
                isQuoteLoading={quoting}/>
            <Card>
                <CardHeader style={{paddingBottom: 0}}>
                    <div style={{display: 'flex', justifyContent: "space-between"}}>
                        <h4>Liste des vehicules </h4>
                        <div style={{display: 'flex', justifyContent: "flex-end"}}>
                            {accessorySettingVisibility && (
                                <div className="btn-label  nexttab mb-2 mr-5">
                                    
                                    <Search
                                        style={{ width: 250 }}
                                        placeholder="Prime accessoire"
                                        allowClear
                                        enterButton="Valider"
                                        disabled={_.size(baskets) <= 0}
                                        size="medium"
                                        onSearch={onAccessorySettingValidate}
                                        type="number"
                                        />
                                </div>
                            )}
                            
                            {/* <div className="btn-label  nexttab mb-2 mr-5">
                                <FSettings onItemClick={onSettingItemClick} /> {" "}
                            </div>
                            
                            <button onClick={ ()=> setOpenVehicleDrawer(true) } disabled={true} type="button" className="btn btn-success btn-label right nexttab mb-2 btn-sm">
                                <i className="bx bx-plus label-icon align-middle fs-16 ms-2"></i>{" "}
                                <CarOutlined style={{ color: '#ffffff', fontSize: 20 }} /> AJOUTER VEHICULE 
                            </button> */}
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="modal-body">
                        <Row>
                            <Col lg={12}>
                                <Table loading={state?.isQuotingFleet} columns= {columns} dataSource= {dataSource}/>
                            </Col>

                            <Col lg={12}>
                                <BackNextWidget 
                                    confirmText={"CONTINUER"}
                                    onConfirm={()=> goNextStep()}
                                    confirmIcon={(<UserOutlined style={{ color: '#ffffff', fontSize: 20 }} />)}
                                    disableConfirm={_.size(dataSource) < 1}
                                    icon={UserOutlined}
                                    />
                            </Col>
                        </Row>
                    </div> 
                </CardBody>
            </Card>
            
            {/* Quotation panel */}
            {openVehicleDrawer && (
                <Drawer
                    title="Cotation"
                    onClosed={()=> onCloseQuotationDrawer()}
                    closable={true}
                    open={openVehicleDrawer}
                    placement="right"
                    width="50vw"
                    
                    /*style={{ minWidth: "50vw" }}*/>
                        <FStepsWidget 
                            action={action}
                            basket={basket}
                            onValidateBasket={onValidateBasket}
                            setOpenVehicleDrawer={setOpenVehicleDrawer}
                            />
                </Drawer>
            )}
            
            {/* Basket details panel */}
            {detailVehicleDrawer.visible && (
                <Drawer
                    title="Détails du contract"
                    placement="right"
                    width="40%"
                    onClose={() => setDetailVehicleDrawer({visible: false})}
                    visible={detailVehicleDrawer.visible}>
                    <>
                        {ReturnNewTitle('Informations sur le vehicule')}
                        {ReturnNewLine('Category', `${detailVehicleDrawer?.basket?.formField?.categoryCode} : ${detailVehicleDrawer?.basket?.formField?.categoryTitle}`, false)}   
                        {ReturnNewLine('Energie', detailVehicleDrawer.basket?.formField?.energyTitle, false)}
                        {ReturnNewLine('Nombre de places assises', detailVehicleDrawer.basket?.formField?.nbPlace, false)}
                        {ReturnNewLine('Puissance fiscale', detailVehicleDrawer.basket?.formField?.fiscalPower + ' Cv', false)}
                        {ReturnNewLine('Date de 1ere mise en circulation', new Date(detailVehicleDrawer.basket?.formField?.dateMec).toLocaleDateString(), false)}
                        {ReturnNewLine('Valeur neuve', detailVehicleDrawer.basket?.formField?.newValue + ' FCFA', false)}
                        {ReturnNewLine('Valeur venale', detailVehicleDrawer.basket?.formField?.marketValue + ' FCFA', false)}
                        {ReturnNewLine('Balise GPS', detailVehicleDrawer.basket?.formField?.hasGps ? "Oui" : "Non", false)}
                        {ReturnNewLine('Zone de circulation', detailVehicleDrawer.basket?.formField?.cityTitle, true)}

                        {/* {returnNewTitle('Options et Garanties')} */}

                        {ReturnNewLine('Periode de couverture', detailVehicleDrawer.basket?.formField?.periodTitle, false)}
                        {/* {returnNewLine('Formule', state.listSoucription.garantie.title, false)} */}
                        {/* {returnNewLine('Franchise', state?.souscription?.packResponse?.franchise?.title, true)} */}
                        {/* {returnNewLine('Avance sur recours', state.listSoucription.avanceRecours ? "Oui" : "Non", true)} */}

                        {ReturnNewTitle('Package et reduction')}
                        {ReturnNewLine('Package', detailVehicleDrawer.basket?.packSelected?.packTitle, true)}

                        {ReturnNewTitle('Autres informations sur le vehicule', false)}
                        {ReturnNewLine('Marque', detailVehicleDrawer.basket?.formField?.brandTitle, false)}
                        {ReturnNewLine('Modèle', detailVehicleDrawer.basket?.formField?.modelTitle, false)}
                        {ReturnNewLine('Immatriculation', detailVehicleDrawer.basket?.formField?.matriculation, false)}
                        {ReturnNewLine('Numero de chassis', detailVehicleDrawer.basket?.formField?.chassisNumber, true)}

                        {/* {returnNewTitle('Informations sur l\'assuré')}

                        {returnNewLine('Nom', _initAvenantResponse?.subscription?.assure?.firstName)}
                        {returnNewLine('Prenom', _initAvenantResponse?.subscription?.assure?.lastName, false)}
                        {returnNewLine('Numero mobile', _initAvenantResponse?.subscription?.assure?.phone, true)}
                        {returnNewLine('Nature de la piece d\'identité', _initAvenantResponse?.subscription?.assure?.identityCardTitle, false)}
                        {returnNewLine('Numéro de la piece d\'identité', _initAvenantResponse?.subscription?.assure?.identityCardNumber, true)} */}

                        {/* {returnNewTitle('Détails sur le contrat')} */}


                        {/* {returnNewTitle('Reduction et Prime', false)} */}

                        {/* {returnNewLine('Frais d\'agence', state?.listSoucription?.package?.primeTTC, false)} */}
                        {/* {returnNewLine('Prime TTC', _initAvenantResponse?.packResponse?.primeTTC + ' FCFA', true)} */}
                    </>
                </Drawer>
            )}
            
        </React.Fragment>
    );
}

export default FStepListVehicle;