import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class SubscriptionService {
    generateTransaction(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.subscriptionMonoGenerateResource, payload, sCallBack, eCallBack)
    }

    generateProject(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.projectMonoGenerateResource, payload, sCallBack, eCallBack)
    }

    static addCancelMotif(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.addCancelMotifRessource, payload, sCallBack, eCallBack)
    }

    // FLOTTE
    static duplicateProject(options, sCallBack, eCallBack) {
        ApiRoutes.projectDuplicateRessource.resourceOptions = Object.assign({}, ApiRoutes.projectDuplicateRessource?.resourceOptions, options);
        exeRequest(ApiRoutes.projectDuplicateRessource, {}, sCallBack, eCallBack)
    }

    // FLOTTE
    flotteGenerateTransaction(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.subscriptionFlotteGenerateResource, payload, sCallBack, eCallBack)
    }

    flotteGenerateProject(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.projectFlotteGenerateResource, payload, sCallBack, eCallBack)
    }

    // EDIT
    static edit(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.editSubscriptionRessource, payload, sCallBack, eCallBack)
    }

}