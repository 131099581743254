import { Button, Col, Form, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import useDetailsSubscription from "../useDetailsSubscription";
import SerialNatureField from "../../common/fields/SerialNatureField";
import ContractService from "services/contractService";

const ModalGenerateAttestation=({subscriptionSelected, initialValues, onCancel, onOk, msgError})=> {
    const [form] = Form.useForm();
    const [isAttestationLoading, setIsAttestationLoading] = useState(false);
    const [attestationGenerateError, setAttestationGenerateError] = useState("");
    const {
        state,
        readAssureSubscriptions,
        setState} = useDetailsSubscription();

    const generateAttestation = (subscriptionSelected, callback) => {
        setIsAttestationLoading(true);
        form.validateFields().then(value => {
            let options = {
                path: {
                    contractDeatilID: subscriptionSelected?.id,
                    attestationNumber: value.attestation
                },
                query: {
                    natureCode: value?.natureCode
                }
            }
            
            ContractService.generateAttestation(options, (data) => {
                setIsAttestationLoading(false);
                if (onOk) onOk();
                if(onCancel) onCancel();
            }, (error) => {
                setIsAttestationLoading(false);
                setAttestationGenerateError(error)
            })
        });
    }

    return (
        <React.Fragment>
            <Modal
                title="Generation d'attestation"
                open={true}
                centered
                onOk={() => { }}
                onCancel={() => onCancel()}
                footer={[]}
            // okText="Valider"
            // cancelText="Annuler"
            >
                <Form
                    name="basic"
                    onFinish={()=> generateAttestation(subscriptionSelected)}
                    layout="vertical"
                    form={form}
                    initialValues={initialValues}

                >
                    <Row gutter={8} >
                        <SerialNatureField disable={initialValues != null} md={24}/>
                        <Col className="gutter-row" xs={24} md={24}>
                            <Form.Item
                                label="Numero d'attestation"
                                name="attestation"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Entrez le numero d\'attestation svp !',
                                    },
                                ]}
                            >
                                <Input placeholder="Numero d'attestation" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        <span style={{ marginLeft: 8, color: "red" }}> {attestationGenerateError} </span>
                        <Button
                            style={{
                                marginRight: 8,
                            }}
                            onClick={() => onCancel()}
                        >
                            Annuler
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isAttestationLoading}
                        >
                            Valider
                        </Button>
                    </div>
                </Form>

                <div id="errorMessage" style={{ border: '1px solid #ffa39e', backgroundColor: '#fff1f0', borderRadius: 5, marginTop: -10, display: 'none' }}>
                    <p style={{ textAlign: "center" }} >Numero d'attestation non trouvé !!</p>
                </div><br />
            </Modal>
        </React.Fragment>
    )
}

export default ModalGenerateAttestation;