import React from 'react';
import { Card } from "reactstrap";
import WizardHeader from 'modules/components/WizardHeader';
import { FStepAssureAndContract,  FStepDetails, FStepListVehicle, FStepThankYou } from './steps';
import { useSelector } from 'react-redux';
import useInitFlotteAvenant from '../useInitFlotteAvenant';
import { useBasket } from '../../../hooks/useBasket';
import * as helpers from 'helpers/helpers';
import FStepThankYouProject from '../../common/steps/FStepThankYouProject';
import { Spin } from 'antd';
import ErrorWidget from 'components/Widgets/ErrorWidget';

const FAvenantDeCreation = ()=> {
    
    const { 
        category,
        avenant,
        partnerCode,
        projectCode,
        isProject
    } = useSelector((state) => state.subscription);
    const {
        currentStep, 
        page, 
        basket,
        baskets,
        fSubscriptionRequest, 
        quotationResponse, 
        transactionResponse
    } = useSelector((state) => state.basketSlice);

    const {state: iniAvenant } = useInitFlotteAvenant();
    const {goToPreviousStep} = useBasket();

    if (iniAvenant?.avenantStatus?.loading) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if (iniAvenant?.avenantStatus?.error) {
        return <ErrorWidget errorMessage={iniAvenant?.avenantStatus?.errorMessage} />;
    }

    if(page === helpers.FlotteWidget.THANK_YOU) {
        return <FStepThankYou transactionResponse={transactionResponse} />
    }

    if(page === helpers.FlotteWidget.PROJECT_THANK_YOU) {
        return <FStepThankYouProject transactionResponse={transactionResponse} />
    }

    const renderChildren = ()=> {
        switch(currentStep) {
            // case 0:
            //     return <FStepIntro formField={basket?.formField}/>
            case 0:
                return <FStepListVehicle 
                            baskets={baskets}
                            basket={basket}
                            isProject={isProject}
                             />

            case 1:
                return <FStepAssureAndContract  
                            formField={basket?.formField} />
            case 2:
                return <FStepDetails
                            avenantCode={avenant?.code}
                            quotationResponse={quotationResponse} 
                            fSubscriptionRequest={fSubscriptionRequest}
                            />
            
            default:
                return <></>

        }
    }

    return (
        <React.Fragment>
            <WizardHeader
                title={`Categorie ${category.code} : ${category.title}`}
                avenantTilte={`Affaire nouvelle`}
                partnerCode={partnerCode}
                police={projectCode} 
                isProject={isProject} 
                //onClick={(state.currentStep > 0 && state.currentStep < 4) ? goToPreviousStep : null}
                percent={currentStep * 25}
            />
            {renderChildren()}
        </React.Fragment>
    );
}

export default FAvenantDeCreation;