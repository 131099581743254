import { Col, DatePicker, Form } from "antd";
import { CATEGORY_403, CATEGORY_406, CATEGORY_407, CATEGORY_408, CATEGORY_409, CATEGORY_410 } from "constantes";
import React from "react";
import { disabledNextDate } from "helpers/helpers";

const DriverBirthField=({categoryCode, disable, xs, md})=> {

    if([CATEGORY_403,CATEGORY_406, CATEGORY_407, CATEGORY_408, CATEGORY_409, CATEGORY_410].includes(categoryCode)) return null;
    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Date de naissance du conducteur habituel"
                    name="driverBirthDay"
                    rules={[{ required: false, message: "Champ obligatoire" }]}
                >
                    <DatePicker style={{ width: '100%' }} size='large' format={"DD/MM/YYYY"} disabledDate={disabledNextDate} disabled={disable} />
                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default DriverBirthField;