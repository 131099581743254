export default class FormRegisterGroupement {
    groupementTypeRef;
    groupementTypeTitle;
    title;
    nbVehicle;
    description;

    fromJson = (json = {}) => {
        var formAddGroupement = new FormRegisterGroupement();
        formAddGroupement = json
        return formAddGroupement
    }
}