import { useEffect, useState } from 'react';
import CategoryService from 'services/categoryService';

export function useGetVehiclesCategories() {
    const [state, setState] = useState({
        isLoading: true,
        data: []
    });

    useEffect(() => {
        (new CategoryService()).readAllCategory((responseData) => {
            const categories = responseData || [];

            setState(prevState => ({
                ...prevState,
                data: categories.filter(category => category?.code !== '400'),
                isLoading: false
            }));
        }, (error) => {
            setState(prevState => ({
                ...prevState,
                isLoading: false
            }));
        });
    }, []);

    return {
        ...state
    }
}