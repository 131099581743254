import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";

import Payment from "views/pages/subscription/component/payment/Payment";
import { ContractType } from "views/pages/subscription/component/ComponentWidget";
import { MDetailOthers, MDetailPackage, MDetailPeriod, MDetailPrime, MDetailStartDate } from "../../common/details";
import { useBasket } from "views/pages/subscription/hooks/useBasket";
import _ from "lodash";
import SubscriptionRequestBuilder from "api/request/SubscriptionRequestBuilder";

const StepDetailsLite=({
    avenantCode,
    endDate,
    subscriptionRequest,
    packSelected
})=> {

    const {goToPreviousStep, initStartDate, setInitStartDate, onStartDateChange, updateSusbscriptionRequest }=useBasket();
    
    useEffect(()=> {
        setInitStartDate({
            startDate: subscriptionRequest?.customerStartDate ?? subscriptionRequest?.dateEffect,
            endDate: endDate,
            initialDate: subscriptionRequest?.customerStartDate ?? subscriptionRequest?.dateEffect
        });
    }, [])

    const getExtendGuaranties=()=> {
        if(_.size(packSelected?.customGuaranties) > 0){
            const customGuarantiesRef = (packSelected?.customGuaranties || []).map((customGuarantee)=> customGuarantee?.guaranteeRef);
            return (packSelected?.guaranties || [])?.filter((guarantee)=> customGuarantiesRef.includes(guarantee?.ref))
        }
        else {
             return packSelected?.guaranties;
        }
    }

    return(
        <React.Fragment>
            <div /* className="page-content" */>
                <Container fluid>                    
                    
                    <Row>
                        <Col className="col-xxl-9 order-xxl-0 order-first">
                            <Row>
                                <MDetailPackage packTitle={packSelected?.packTitle}/>

                                <MDetailPeriod perioTitle={packSelected?.period?.title} />

                                <MDetailStartDate 
                                    editable={false}
                                    rangeDate={initStartDate} 
                                    onStartDateChange={onStartDateChange} />

                            </Row>
                            <MDetailOthers
                                defaultTab={"2"}
                                guaranties={getExtendGuaranties()}
                                assure={subscriptionRequest?.assure}
                            />
                        </Col>
                        <MDetailPrime 
                            primeTTC={packSelected?.primeTTC}
                            primeNette={packSelected?.primeNette}
                            extras={packSelected?.extras}
                            />
                        
                    </Row>
                    <Row>
                        <Col className="col-xxl-12">
                            <Payment 
                                    backVisibility={true}
                                    projectVisibility={false}
                                    onlineVisiblity={true}
                                    payload={SubscriptionRequestBuilder.fromThis(avenantCode, {...subscriptionRequest, prorate: true})}
                                    goBack={goToPreviousStep}
                                    type={ContractType.MONO}
                                />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default StepDetailsLite;