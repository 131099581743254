
import { Tabs } from "antd";
import React from "react";
import { Card } from "reactstrap";
import Guarantee from "./component/Guarantee";
import Brand from "./component/Brand";
import Model from "./component/Model";
import Category from "./component/Category";
import Package from "./component/Package";
import Reduction from "./component/Reduction";
import Extra from "./component/Extra";

export default function Categorie(props) {
    const { TabPane } = Tabs;

    return (
        <Card>
            <div style={{ padding: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                    <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5 }}>Parametrages - Categories | Packages | Garanties | Marques | Modèles</h4>
                     {/*  <div style={{ display: 'flex', justifyContent: 'end' }}>
                                
                                <Input placeholder="Recherche: Catégorie" style={{ width: 350, marginRight: 10 }} />
                                <Button type="primary" onClick={() => onDrawerOpen(null, ActionType.REGISTER)} style={{ marginLeft: 10 }}>
                                    <FileAddOutlined />
                                    Ajouter une catégorie
                                </Button>
                            </div> */}
                </div>
                <Tabs defaultActiveKey="1" onChange={''} key={"gparams"}>
                    <TabPane tab="Catégories" key="1" tabKey="cGparams">
                        <Category />
                    </TabPane>
                    <TabPane tab="Packages" key="2" tabKey="cGparams">
                        <Package />
                    </TabPane>
                    <TabPane tab="Garanties" key="3" tabKey="cGparams">
                        <Guarantee />
                    </TabPane>

                    <TabPane tab="Marques" key="4" tabKey="cGparams">
                        <Brand />
                    </TabPane>

                    <TabPane tab="Modèles" key="5" tabKey="cGparams">
                        <Model />
                    </TabPane>

                    <TabPane tab="Reductions" key="6" tabKey="cGparams">
                        <Reduction />
                    </TabPane>

                    <TabPane tab="Extras" key="7" tabKey="cGparams">
                        <Extra />
                    </TabPane>

                </Tabs> 
            </div>
        </Card>
    )
}