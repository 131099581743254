export default class EditGroupementRequest {
    ref;
    title;
    description;
    nbVehicle;
    groupementTypeRef;

    fromJson = (json={})=> {
        var editGroupementRequest = new EditGroupementRequest();
        editGroupementRequest = json;
        return editGroupementRequest;
    }
}