export default class FormEditGroupeReduction {
    code;
    categorie;
    description;

    fromJson = (json = {}) => {
        var formEditGroupeReduction = new FormEditGroupeReduction();
        formEditGroupeReduction = json;
        return formEditGroupeReduction;
    }
}