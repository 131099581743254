export default class Assure {
    id;
    code;
    statusSocio;
    firstName;
    lastName;
    otherName;
    identityCardRef;
    identityCardNumber;
    identityCardTitle;
    address;
    profession;
    phone;
    email;
    birthDay;
    socialPosRef;
    socialPosTitle;
    prospect;

    static fromJson = (json={})=> {
        var assure = new Assure();
        assure = json;
        return assure
    }
}