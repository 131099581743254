import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import ApiRoutes from "api/apiRoutes";
import Basket from "api/model/basket";
import InitAvenantResponse from "api/response/initAvenantResponse";
import TransactionResponse from "api/response/transactionResponse";
import QuotationResponse from "api/v1/response/QuotationResponse";
import FSubscriptionRequestBuilder from "helpers/FSubscriptionRequestBuilder";
import QuotationRequestBuilder from "helpers/QuotationRequestBuilder";
import SubscriptionRequestBuilder from "api/request/SubscriptionRequestBuilder";
import { exeRequestAsync } from "http/requestConfig";
import * as helpers from 'helpers/helpers';
import _ from "lodash";
import moment from "moment";
import { date_r } from "helpers/date_helpers";

const initialState = {
    currentStep: 0,
    page: null,

    basket : new Basket(),     // Le basket courant en cours de construction / actif

    // FLOTTE
    baskets : [], // ex : [{packSelected, quotationRequest, quotationResponse, formField}]
    packsSelected : [], // List des packs retenu pour la flotte y compris la prime TTC de la flotte,
    fSubscriptionRequest : null, //new FSubscriptionRequest(),
    quotationResponse: null, //new QuotationResponse(),
    
    runQuote: false,
    runFQuote: false,
    // Simple Quotation
    quoteStatus: {type: helpers.TYPE.MONO, loading: false, error: false, message: ""},


    isInitAvenantLoading: false,
    initAvenantResponse : new InitAvenantResponse(),
    transactionResponse : new TransactionResponse(),
    paymentStatus: {success: false, errorMessage: ''},
}

// :::::::::: SIMPLE QUOTATION :::::::::
async function quote(options, payload) {
    if(options) {
        ApiRoutes.v1QuotationPackResource.resourceOptions = Object.assign({}, ApiRoutes.v1QuotationPackResource.resourceOptions, options);
    }
    const response = await exeRequestAsync(ApiRoutes.v1QuotationPackResource, payload )
    return response.json();
}

export const quoteAsync = createAsyncThunk(
    'packs/quote',
    async ({options, payload}) => {
      const response = await quote(options, payload);
      return response;
    }
);

// :::::::::: INIT AVENANT :::::::::
/* async function initAvenant2(payload) {
    const response = await exeRequestAsync(ApiRoutes.initAvenantResource, { ...payload });
    return response.json();
}

export const initAvenantAsync2 = createAsyncThunk(
    'avenant/init2',
    async (payload) => {
      const response = await initAvenant2(payload);
      return response;
    }
  ); */
// ::::::::::::::::::::::::::::::::::::

// :::::::::::::::::::::::::::::::::::::::::::::
/* '☀️' : '🌙 */
export const basketSlice = createSlice({
    name: "basketSlice",
    initialState,
    reducers: {
        upState: (state, action)=> {
            const data = helpers.statelessUpdate(action?.payload, state);
            state = data
            return state
        },
        toNextStep: (state, action)=> {
            //console.log(":::::::::::::::::: Gooooooooo to nex")
            window.scrollTo(0, 0);
             
            if(action?.payload == null) {
                 state.currentStep = state.currentStep + 1;
            }
            else {
                state = helpers.statelessGotoNextStep(action?.payload, state);
            } 
            
            return state;
        },
        toPreviousStep: (state, action)=> {
            
            window.scrollTo(0, 0);
            if(action?.payload == null) {
                 //state.currentStep = state.currentStep - 1;
                 state = {
                    ...state,
                    currentStep: state.currentStep - 1,
                    basket: {
                        ...state.basket,
                        formField: {
                            ...state.basket.formField,
                            startDate: state.basket.formField ? moment(state.basket.formField) : state.basket.formField
                        }
                    }
                 }
            }   
            else {
                const newState = helpers.statelessGotoPreviousStep(action?.payload, state);
                state = {
                    ...newState,
                    basket: {
                        ...state.basket,
                        formField: {
                            ...state.basket.formField,
                            startDate: state.basket.formField ? moment(state.basket.formField) : state.basket.formField
                        }
                    }
                 }
            } 
            
            return state;
        },
        paymentStatus: (state, action)=> {
            state.paymentStatus = action.payload;
            return state;
        },

        // UPDATE FORMFIELD
        upFormField: (state, action)=> {
            var fields = action.payload;
            if(fields?.startDate) {
                const date = moment(fields?.startDate);
                fields = {...fields, startDate: date_r(date)}
            }
            
            const data = helpers.statelessUpdate([
                {path: ['basket', 'formField'], value: {
                    ...state.basket?.formField,
                    ...fields
                }}
            ], state);

            
            state = data
            return state;
        },

        upBasket: (state, action)=> {
            const newBasket = action.payload;
            const basket = {
                ...state.basket,
                ...newBasket
            }
            state.basket = basket;
            return state;
        },

        upQuotationRequest: (state, action)=> {

            const payload   = action.payload;
            const contract  = payload?.contract;
            const avenant   = payload?.avenant;
            const specificPack = payload?.specificPack;
            console.log("::::: Basket packLineRef :", specificPack)
            const _quotationRequest = QuotationRequestBuilder.build({
                formFields: state.basket.formField, 
                contract: contract, 
                avenant: avenant, 
                packLineRef: specificPack ? state?.basket?.packSelected?.packLineRef : null
            });

            state.basket.quotationRequest = _quotationRequest;

            return state;
        },

        upSusbscriptionRequest: (state, action)=> {
            const {contract, avenant, category, partnerCode, isProject, projectCode, isProrate} = action.payload;
            
            const subscriptionRequest = SubscriptionRequestBuilder.fromBasket({
                category    : category,
                contract    : contract,
                avenant     : avenant,
                partnerCode : partnerCode,
                isProject   : isProject,
                projectCode : projectCode,
                isProrate   : isProrate,
                basket      : state?.basket,
                
            });

            state.basket.subscriptionRequest = subscriptionRequest;
            return state;
        },

        // FLOTTE
        upBasketsQuotationRequest: (state, action)=> {
            const payload = action.payload;
            const contract = payload?.contract;
            const avenant = payload?.avenant;
            
            const _baskets=[];
            for(const basket of state?.baskets) {
                const _quotationRequest = QuotationRequestBuilder.build({
                    formFields: basket.formField, 
                    contract: contract, 
                    avenant: avenant, 
                    packLineRef: basket?.packSelected?.packLineRef
                });
                _baskets.push({
                    ...basket,
                    quotationRequest: {
                        ...basket?.quotationRequest,
                        ..._quotationRequest
                    }
                })
            }

            state.baskets = _baskets;

            return state;
        },
        quoteBaskets: (state, action)=> {
            state.runFQuote = true;
            return state;
        },

        eventFQuote: (state, action)=> {
            state.runFQuote = action?.payload;
            return state;
        },

        upFSusbscriptionRequest: (state, action)=> {
            const {contract, avenant, category, assure, partnerCode, isProject, projectCode, isProrate} = action.payload;

            const fSubscriptionRequest = FSubscriptionRequestBuilder.fromFleetBaskets({
                category: category,
                contract: contract,
                avenant: avenant,
                partnerCode: partnerCode,
                isProject   : isProject,
                projectCode : projectCode,
                isProrate   : isProrate,

                assure: assure ?? {
                    code      : state?.basket?.formField?.assureCode,
                    firstName  : state?.basket?.formField?.assureFirstname,
                    lastName  : state?.basket?.formField?.assureLastname,
                    phone     : state?.basket?.formField?.assurePhone,
                    email     : state?.basket?.formField?.assureEmail,
                    identityCardRef: state?.basket?.formField?.assureIdentityCardRef,
                    identityCardTitle: state?.basket?.formField?.assureIdentityCardTitle,
                    identityCardNumber: state?.basket?.formField?.assureIdentityCardNumber,
                    socialPosRef: state?.basket?.formField?.socialPosRef,
                    socialPosTitle: state?.basket?.formField?.socialPosTitle
                },
                baskets: state?.baskets || [],
                basket: state?.basket,
                quotationResponse: state?.quotationResponse                
            });

            state.fSubscriptionRequest = fSubscriptionRequest;
            return state;
        },

        upBaskets: (state, action)=> {
            const newBaskets = action.payload || [];
            state.baskets = [...newBaskets];
            return state;
        },

        deleteBasket: (state, action)=> {
            let newBasket = action?.payload;
            const __baskets = state.baskets.filter((_basket, index)=> _basket?.id !== newBasket?.id);
            state.baskets = __baskets;
            return state;
        },

        addBasket: (state, action)=> {
            let newBasket = action?.payload;
            
            if(newBasket?.id === null || newBasket?.id == undefined) {
                const _id = state.baskets.length + 1;
                state.baskets.push({...newBasket, id: _id});
            }
            else {
                const index = state.baskets.findIndex((basket)=> basket?.id === newBasket?.id);
                if(index !== -1 && state.baskets.length > 0) { // Update
                    state.baskets[index] = newBasket; 
                }
            }
            
            let _basket = new Basket();
            _basket.formField.socialPosRef    = state.basket?.formField?.socialPosRef;
            _basket.formField.socialPosTitle  = state.basket?.formField?.socialPosTitle; 
            _basket.formField.periodRef       = state.basket?.formField?.periodRef;
            _basket.formField.periodTitle     = state.basket?.formField?.periodTitle;
            _basket.formField.startDate       = state.basket?.formField?.startDate;
            state.basket = _basket;
            // REQUOTE
            //state.runFQuote = true;
            //quoteAsync()
            return state;
        },

        editBaskets: (state, action)=> {
            let newBasket = action?.payload;
            const index = state.baskets.findIndex((basket)=> basket?.id === newBasket?.id);
            if(index !== -1 && state.baskets.length > 0) { // Update
                state.baskets[index] = newBasket; 
            }
            //state.runFQuote = true;
        },

        basketRaz: (state)=> {
            state = initialState;
            return state;
        }
    },
    
    extraReducers: (builder) => {
        builder
        // ::::::::: QUOTING ::::::::::
        .addCase(quoteAsync.pending, (state, action)=> {
            state.quoteStatus = {
                type: action.meta.arg?.options?.query?.isFlotte ? helpers.TYPE.FLOTTE : helpers.TYPE.MONO,
                loading: true
            }
        })
        .addCase(quoteAsync.rejected, (state, action)=> {
            state.runQuote = false;
            state.runFQuote = false;
            state.quoteStatus = {
                type: action.meta.arg?.options?.query?.isFlotte ? helpers.TYPE.FLOTTE : helpers.TYPE.MONO,
                loading: true,
                error: true,
                message: "Une erreur s'est producte"
            }

        })
        .addCase(quoteAsync.fulfilled, (state, action)=> {
            state.runQuote = false;
            state.runFQuote = false;
            if(action.payload?.error) {
                state.quoteStatus = {
                    type: action.meta.arg?.options?.query?.isFlotte ? helpers.TYPE.FLOTTE : helpers.TYPE.MONO,
                    loading: false,
                    error: true,
                    message:action.payload?.statusMessage
                }
                return state;
            }

            const {data} = action.payload;

            const _baskets = [];

            const quotationResponse = QuotationResponse.fromJson(data);
            console.log(":::: quotationResponse :", quotationResponse)
            // Fleet treatment
            for (const basket of state?.baskets) {
                let packs = (quotationResponse?.packsResponse || []).filter((packResponse)=> packResponse?.key === basket?.key);
                const _packSelected = (packs || []).find((pack)=> pack?.packLineRef === basket?.packSelected?.packLineRef)
                _baskets.push({
                    ...basket,
                    packSelected: {
                        ...basket?.packSelected, 
                        _packSelected
                    }
                })
            }
            // End fleet treatment

            if(_.size(state.baskets) === 1) {
                state.basket = _.first(_baskets);
            }
            state.baskets = _baskets;
            state.basket.quotationResponse = quotationResponse;
          
            // Status
            state.quoteStatus = {
                type: action.meta.arg?.options?.query?.isFlotte ? helpers.TYPE.FLOTTE : helpers.TYPE.MONO,
                loading: false,
                error: false,
                message:action.payload?.statusMessage
            }
            
        })

        // :::::::::::::::::::::::::::::::::::::::
        
    }
})

export const { 
    upState, 
    toNextStep, 
    toPreviousStep,
    
    upFormField,
    upBasket,
    upBaskets,

    upQuotationRequest,
    upSusbscriptionRequest,
    upBasketsQuotationRequest,
    upFSusbscriptionRequest,

    paymentStatus, 
    quoteBaskets,
    eventFQuote,
    addBasket,
    editBaskets,
    deleteBasket, 
    basketRaz  } = basketSlice.actions;
export default basketSlice.reducer;