import Assure from "api/model/assure.model";
import Contract from "api/model/contract.model";
import ContractDetail from "api/model/contractDeatil.model";

export default class TransactionResponse {
    primeNette;
    primeExtras;
    primeTTC;
    aggencyFees;
    contractDetail = new ContractDetail();
    contract = new Contract();
    assure = new Assure();
    primePromo;
    factureUrl;

    fromJson=(json={})=> {
        var transactionResponse = new TransactionResponse();
        transactionResponse = json;
        return transactionResponse;
    }
}