import { EditTwoTone } from "@ant-design/icons";
import { Button, Col, Collapse, Divider, Popconfirm, Row, Switch, Table, Tabs, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import CategoryService from "services/categoryService";
import { toast } from "react-toastify";
import CriterionForm from "./CriterionForm";
import { DeleteOutline } from "@material-ui/icons";
import AddGuarantee from "./AddGuarantie";
import CopyPackGuarantee from "./CopyPackGuarantee";
import _ from "lodash";


const PackOptions=({category, pack, categoryPacks, packOthersGuaranties, isLoadingPackOthersGuaranties, refreshPacksAndGuaranties})=> {
    const { TabPane } = Tabs;
    const { Panel } = Collapse;

    const [state, setState] = useState({
        packOthersGuarantiesSelected: {},
        isPackGuaranteeAdding: false,
        guaranteeLines: [],
        guaranteeLineSelected: {},
        initialCriterion: {}
    })

    useEffect(()=> {
        let _guaranteeLines = (pack?.guaranteeLine || []).sort((a,b)=> a.guaranteeOrder - b.guaranteeOrder);
        _guaranteeLines = _guaranteeLines.map((guaranteeLine)=> Object.assign({}, { ...guaranteeLine }, {crAdding: false}))
        setState(state=> ({...state, guaranteeLines: _guaranteeLines}))
    }, [pack])

    const onGuaranteeCollapseSelected=(index)=> {
        const guaranteeLineSelected = state?.guaranteeLines[index];
        setState(state=> ({...state, guaranteeLineSelected: guaranteeLineSelected, initialCriterion: {}}))
    }

    const onBtnAddingCriterion=(guaranteeLine, status=true)=> {
        const _guaranteeLineSelected = Object.assign({}, { ...guaranteeLine }, {crAdding: status})
        setState(state=> ({...state, guaranteeLineSelected: _guaranteeLineSelected}))
    }

    const onEditCriterion=(criterion)=> {
        const _guaranteeLineSelected = Object.assign({}, { ...state.guaranteeLineSelected }, {crAdding: true})
        setState(state=> ({...state, guaranteeLineSelected: _guaranteeLineSelected, initialCriterion: criterion}))
    }

    const onDeleteGuaranteeCriterion=(criterion)=> {
        const options = {
            path: { 
                categoryRef: category?.ref,
                packRef: pack?.ref,
                guaranteeRef: state?.guaranteeLineSelected?.guarantee?.ref,
                criterionId: criterion?.id
            }
        }

        CategoryService.deleteGuaranteeCriterion(options, (data)=> {
            toast.success("Le critère a été supprimé")
            refreshPacksAndGuaranties(category, true)
        }, (error)=> {
            console.log(":::: error ", error)
            toast.error("Echec de supression de critère")
        })
    }

    const toggleCategoryPackGuarantieOptionsStatus=(optionStatus)=> {
        
        const options = {
            path: { 
                categoryRef: category?.ref,
                packRef: pack?.ref,
                guaranteeRef: state?.guaranteeLineSelected?.guarantee?.ref
            },
            query: {...optionStatus}
        }

        CategoryService.togglePackcGuaranties(options, (data)=> {
            toast.success("L'option a été modifié")
            refreshPacksAndGuaranties(category, true)
        }, (error)=> {
            toast.error("Echec de supression de l'option")
        })
    }

    const onDeletePackGuarantee=(guaranteeLine)=> {
        const options = {
            path: { 
                guaranteeLineId: guaranteeLine?.id,
            }
        }

        CategoryService.deletePackGuarantee(options, (data)=> {
            toast.success("La garantie a été supprimé")
            refreshPacksAndGuaranties(category, true)
        }, (error)=> {
            console.log(":::: error ", error)
            toast.error("Echec de supression de garantie")
        })
    }

    const criterionTableColums = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id'
        },
        /* {
            title: 'Def.',
            dataIndex: 'default',
            key: 'default',
            render:(index, criterion)=> {
                return (<Rate count={1} defaultValue={state?.guaranteeLineSelected?.defaultEngagementRef === criterion?.variables?.engagementRef ? 1 : 0} onChange={onChangeCreterionDefaultRate} tooltips={"Valeur par defaut"} />)
            }
        }, */
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Valeur',
            dataIndex: 'value',
            key: 'value'
        },
        {
            title: 'Surprime',
            dataIndex: 'surprime',
            key: 'surprime'
        },
        {
            title: 'Redevance',
            dataIndex: 'redevance',
            key: 'redevance'
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render:(index, criterion)=> {
                return (
                    <div style={{display: 'flex', justifyContent: 'end'}}>
                        <div>
                            <EditTwoTone style={{fontSize: 15, marginRight: 5, cursor: "pointer"}} onClick={()=> onEditCriterion(criterion)}/>
                        </div>
                        <div>
                            <Tooltip title="Supprimer le pack" align={"bottom"}>
                                <DeleteOutline style={{color: 'red', fontSize: 15, cursor: "pointer"}} onClick={()=> onDeleteGuaranteeCriterion(criterion)} />
                            </Tooltip>
                        </div>
                    </div>
                )
            }
        }
    ];

    return (
        <React.Fragment>
            <Tabs defaultActiveKey="1" onChange={''} key={"kPackOptions"}>
                <TabPane tab="Garanties" key="1" tabKey="kPackOptions"> 
                    <Collapse onChange={(e)=> onGuaranteeCollapseSelected(e)} accordion>
                        <Panel  key={-1} header={(<><i className="bx bx-plus label-icon align-middle fs-16 ms-2"></i>{" "} Ajouter une garantie</>)}>
                            <AddGuarantee 
                                category={category}
                                pack={pack} 
                                guaranties={packOthersGuaranties} 
                                refreshPacksAndGuaranties={refreshPacksAndGuaranties}/>
                            {
                                (_.size(state?.guaranteeLines) <= 0) && (
                                    <>
                                        <Divider> <span style={{fontSize: 13}}>Ou selectionner un package pour copier ses guaranties</span></Divider>
                                        <CopyPackGuarantee 
                                            category={category} 
                                            currentPack={pack} categoryPacks={categoryPacks} 
                                            refreshPacksAndGuaranties={refreshPacksAndGuaranties}
                                            />
                                    </>
                                )
                            }
                        </Panel>
                        {
                            (state?.guaranteeLines || []).map((guaranteeLine, index) => {
                                return (
                                    <Panel header={(<>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <div> {guaranteeLine?.guarantee?.title}</div>{/* <Checkbox checked={false} onChange={(e)=> onGuaranteeCheckBoxSelected(guaranteeLine)}></Checkbox> */}
                                            <div>
                                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <div style={{marginRight: 5}}>
                                                        <Switch 
                                                            defaultChecked={pack?.status} 
                                                            unCheckedChildren={"Desactivé"} 
                                                            checkedChildren={"Activé"} 
                                                            size="small" 
                                                            loading={state?.isPackToggling}
                                                            //onChange={()=> onDeletePackGuarantee(guaranteeLine)}
                                                            />
                                                        </div>
                                                    <div >
                                                        <Popconfirm 
                                                            title="Voulez-vous vraiment supprimer ?" 
                                                            okText="Supprimer" 
                                                            onConfirm={()=> onDeletePackGuarantee(guaranteeLine)} 
                                                            overlayStyle={{width: 300}}
                                                            cancelText="Annuler"
                                                            okType="danger"
                                                            >
                                                            <DeleteOutline style={{color: 'red', fontSize: 20, cursor: "pointer"}} />
                                                        </Popconfirm>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>)} key={index}>
                                        Paramétrage
                                        <Row>
                                            <Col md={12}>
                                                <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between' }}>
                                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Appliqué franchise  </p>
                                                    <p style={{ fonWeight: 'bold' }}>
                                                        <Switch 
                                                            defaultChecked={guaranteeLine?.applyFranchise} 
                                                            unCheckedChildren={"Non"} 
                                                            checkedChildren={"Oui"} 
                                                            size="small"
                                                            onChange={(e)=> {toggleCategoryPackGuarantieOptionsStatus({applyFranchise: e})}}
                                                            />
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col md={12}>
                                                <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between' }}>
                                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Appliqué commission  </p>
                                                    <p style={{ fonWeight: 'bold' }}>
                                                        <Switch 
                                                            defaultChecked={guaranteeLine?.applyCommission} 
                                                            unCheckedChildren={"Non"} 
                                                            checkedChildren={"Oui"} 
                                                            size="small"
                                                            onChange={(e)=> {toggleCategoryPackGuarantieOptionsStatus({applyCommission: e})}}/>
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col md={12}>
                                                <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between' }}>
                                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Appliqué red. groupement  </p>
                                                    <p style={{ fonWeight: 'bold' }}>
                                                        <Switch 
                                                            defaultChecked={guaranteeLine?.applyGroupement} 
                                                            unCheckedChildren={"Non"} 
                                                            checkedChildren={"Oui"} 
                                                            size="small"
                                                            onChange={(e)=> {toggleCategoryPackGuarantieOptionsStatus({applyRedGroup: e})}} />
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col md={12}>
                                                <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between' }}>
                                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Appliqué red. flotte  </p>
                                                    <p style={{ fonWeight: 'bold' }}>
                                                        <Switch 
                                                            defaultChecked={guaranteeLine?.applyFlotte} 
                                                            unCheckedChildren={"Non"} 
                                                            checkedChildren={"Oui"} 
                                                            size="small"
                                                            onChange={(e)=> {toggleCategoryPackGuarantieOptionsStatus({applyRedFleet: e})}}
                                                            />
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col md={12}>
                                                <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between' }}>
                                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Appliqué periode  </p>
                                                    <p style={{ fonWeight: 'bold' }}>
                                                        <Switch 
                                                            defaultChecked={guaranteeLine?.applyPeriodRate} 
                                                            unCheckedChildren={"Non"} 
                                                            checkedChildren={"Oui"} 
                                                            size="small"
                                                            onChange={(e)=> {toggleCategoryPackGuarantieOptionsStatus({applyPeriod: e})}}/>
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col md={24}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div style={{ marginRight: 10, color: '#8E8E8E' }}>Critère de cotation:  </div>
                                                    <div ><Button type="primary" ghost size="small" onClick={()=> onBtnAddingCriterion(guaranteeLine, true)} title="Ajouter une critère">+</Button></div>
                                                </div>
                                                {
                                                    state?.guaranteeLineSelected?.crAdding ? (<div>
                                                        <Divider orientation="left">Options de cotation: </Divider>
                                                        <CriterionForm 
                                                            initialCriterion={state?.initialCriterion}
                                                            category={category} 
                                                            pack={pack} 
                                                            guarantee={state?.guaranteeLineSelected?.guarantee} 
                                                            defaulEngamentRef={state?.guaranteeLineSelected?.defaultEngagementRef}
                                                            refreshPacksAndGuaranties={refreshPacksAndGuaranties}/>
                                                    </div>) : null
                                                }
                                                <Table columns={criterionTableColums} dataSource={guaranteeLine?.criterions} rowKey="id" pagination={{ position: ["topRight"], pageSize: 6 }}  />
                                                
                                            </Col>
                                        </Row>
                                    </Panel>
                                );
                            })
                        }
                        
                    </Collapse>   
                </TabPane>
                <TabPane tab="Assistance" key="2" tabKey="kPackOptions"> </TabPane>
            </Tabs>
        </React.Fragment>
    )
}

export default PackOptions;