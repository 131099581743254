import Brand from "./brand.model";

export default class Model {
   id;
   ref;
   code;
   title;
   description;
   brand = new Brand();

   static fromJson = (json = {}) => {
      var model = new Model();
      model = json;
      return model
   }
}