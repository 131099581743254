import { Button, Col, Drawer, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import ActionType from "api/acionType";
import CreateCategoryRequest from "api/request/createCategoryRequest";
import EditCategoryRequest from "api/request/editCategoryRequest";
import FormRegisterCategory from "forms/addCategorieForm";
import FormEditCategory from "forms/editCategoryForm";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import CategoryService from "services/categoryService";

const EditCategory=({category, drawerVisibility, setDrawerVisibility, refreshCategories })=> {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        formRegisterCategory: new FormRegisterCategory(),
        formEditCategory: new FormEditCategory(),

    });

    useEffect(()=> {
        if (category) {
            const formEditCategory = FormEditCategory.fromJson({
                id: category?.id,
                ref: category?.ref,
                code: category?.code,
                title: category?.title,
                description: category?.description,
            });
            setState(state=> ({...state, formEditCategory: formEditCategory}))
            form.setFieldsValue({ ...form.getFieldsValue(), ...formEditCategory });
        }
        else {
            form.setFieldsValue({ ...form.getFieldsValue(), ...state.formRegisterCategory });
        }
    }, [category])

    // TODO-OK: Ajout et edition d'une co-assurance
    const onRegisterOrEditCategorySubmit = (action) => {
        setState(state => ({ ...state, loading: true }));
        form.validateFields().then((fields) => {
            switch (action) {
                case ActionType.REGISTER:
                    const _formRegisterFields = {...state.formRegisterCategory, ...fields}
                    let createCategoryRequest = CreateCategoryRequest.fromJson(_formRegisterFields);

                    CategoryService.createCategory(createCategoryRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, formRegisterCategory: new FormRegisterCategory(), isCategoryTableUpdateContent: true }));
                        refreshCategories();
                        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                        toast.success("Catégorie ajoutée avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        console.log("error", error);
                        toast.error(error);
                    });
                    break;

                case ActionType.EDIT:
                    const formEditFields = { ...state.formEditCategory, ...fields };
                    let editCategoryRequest = EditCategoryRequest.fromJson(formEditFields);

                    CategoryService.editCategory(editCategoryRequest, (data) => {
                        
                        form.resetFields();
                        setState(state => ({ ...state, formEditCategory: new FormEditCategory(), isUserTableUpdateContent: true }));
                        refreshCategories();
                        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                        toast.success("Catégorie modifiée avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        toast.error(error);
                    });
                    break;

                default:
                    setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                    toast.error("Une erreur est survenue, veuillez réesayer");
                    break;
            }
        }).catch((error) => {
            toast.error("Catégorie non enregistrée");
        })
        setState(state => ({ ...state, loading: false }));
    }

    return (
        <React.Fragment>
            <Drawer
                title={drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrement d'une catégorie" : "Modifier la catégorie"}
                width={720}
                onClose={() => { setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false })) }}
                visible={drawerVisibility.visibility}
                bodyStyle={{
                    paddingBottom: 80,
                }}
                key={"editCategory"}
            >
                <Form
                    name="basic"
                    onFinish={() => onRegisterOrEditCategorySubmit(drawerVisibility.actionType)}
                    layout="vertical"
                    form={form}
                    initialValues={drawerVisibility.actionType === ActionType.REGISTER ? state.formRegisterCategory : state.formEditCategory}
                >

                    <Row gutter={8} >
                        <Col className="gutter-row" xs={24} md={24}>
                            <Form.Item
                                label="Code de la catégorie"
                                name="code"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Le code de la catégorie svp !',
                                    },
                                ]}
                            >
                                <Input placeholder="Le code de la catégorie" size="middle" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={8} >
                        <Col className="gutter-row" xs={24} md={24}>
                            <Form.Item
                                label="Nom de la catégorie"
                                name="title"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Le nom de la catégorie svp !',
                                    },
                                ]}
                            >
                                <Input placeholder="Le nom de la catégorie" size="middle" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={8} >
                        <Col className="gutter-row" xs={24} md={24}>
                            <Form.Item
                                label="Description de la catégorie"
                                name="description"
                                rules={[
                                    {
                                        required: false,
                                        message: 'La description svp !',
                                    },
                                ]}
                            >
                                <TextArea placeholder='Description de la catégorie' size="middle" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e8e8e8',
                            padding: '10px 16px',
                            textAlign: 'right',
                            left: 0,
                            background: '#fff',
                            borderRadius: '0 0 4px 4px',
                        }}
                    >
                        <Button
                            style={{
                                marginRight: 8,
                            }}
                            onClick={() => { setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false })); }}
                        >
                            Annuler
                        </Button>
                        <Button type="primary" htmlType="submit" loading={state.loading}>
                            {drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrer" : "Editer"}
                        </Button>
                    </div>
                </Form>
            </Drawer>
        </React.Fragment>
    )
}
export default EditCategory;