import Basket from "api/model/basket";

export default class SubscriptionHeaderContext {
    componentWidget;
    subscriptionWidget;
    partnerCode;
    avenant;
    contract;
    assure;
    basket=  new Basket();
    period;
    category;
    newPeriod;
    contractDetails;

    static fromJson = (json={})=> {
        var subscriptionHeaderContext = new SubscriptionHeaderContext();
        subscriptionHeaderContext = json;
        return subscriptionHeaderContext;
    }
}