import React from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { ContractType } from "views/pages/subscription/component/ComponentWidget";
import Payment from "views/pages/subscription/component/payment/Payment";
import { useBasket } from "views/pages/subscription/hooks/useBasket";
import { FDetailOthers, FDetailPackage, FDetailPeriod, FDetailPrime, FDetailStartDate } from "../../../common/details";
import SubscriptionRequestBuilder from "api/request/SubscriptionRequestBuilder";


const FStepDetails=({ 
    avenantCode,
    endDate,
    fSubscriptionRequest,
    quotationResponse,
    packsSelected
})=> {

    const {goToPreviousStep, initStartDate, setInitStartDate, onFStartDateChange }=useBasket();
    
    useEffect(()=> {
        setInitStartDate({
            startDate: fSubscriptionRequest?.dateEffect,
            endDate: endDate,
            initialDate: fSubscriptionRequest?.dateEffect
        });
    }, [])

    return(
        <React.Fragment>
            <div /* className="page-content" */>
                <Container fluid>                    
                    
                    <Row>
                        <Col className="col-xxl-9 order-xxl-0 order-first">
                            <Row>
                                <Col lg={4} md={6} >
                                    <FDetailPackage packTitle={"---"}/>
                                </Col>
                                <Col lg={4} md={6} >
                                    <FDetailPeriod perioTitle={quotationResponse?.period?.title} />
                                </Col>

                                <Col lg={4} md={6} >
                                    <FDetailStartDate 
                                        rangeDate={initStartDate} 
                                        onStartDateChange={onFStartDateChange} />
                                </Col>
                            </Row>
                            <FDetailOthers
                              autos= {fSubscriptionRequest?.autos}
                              guaranties={quotationResponse?.guaranties}
                              reductions={quotationResponse?.reductionGroups}
                              assistances={quotationResponse?.assistances}
                              assure={fSubscriptionRequest?.assure}
                               />
                        </Col>
                        <FDetailPrime 
                            primeTTC={fSubscriptionRequest?.primeTTC}
                            primeNette={fSubscriptionRequest?.primeNette}
                            extras={quotationResponse?.extras}
                            />
                        
                    </Row>
                    <Row>
                        <Col className="col-xxl-12">
                            <Payment 
                                backVisibility={true}
                                projectVisibility={false}
                                onlineVisiblity={true}
                                payload={SubscriptionRequestBuilder.fromThisFleet(avenantCode, fSubscriptionRequest)}
                                goBack={goToPreviousStep}
                                type={ContractType.FLOTTE} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default FStepDetails;