import { DeleteOutlined, EditOutlined, SettingTwoTone } from "@ant-design/icons";
import { Button, Drawer, Spin, Table, Tabs, Switch, Divider, Tooltip, Alert } from "antd";
import React, { useEffect, useState } from "react";
import EditCategory from "./EditCategory";
import ActionType from "api/acionType";
import CategoryService from "services/categoryService";
import { toast } from "react-toastify";
import PackageService from "services/packageService";
import GuaranteeService from "services/guaranteeService";
import AddPack from "./AddPack";
import PackOptions from "./PackOptions";
import _ from "lodash";

const Category=()=> {
    const { TabPane } = Tabs;
    const [detailsPackDrawerVisibility, setDetailsPackDrawerVisibility] = useState({
        visible: false
    });
    const [deleteAlerteVisibility, setDeleteAlerteVisibility] = useState(false)
    const [drawerVisibility, setDrawerVisibility] = useState({
        visibility: false,
        actionType: ActionType.REGISTER
    });

    const [state, setState] = useState({
        categories: [],
        dataSource: [],
        categorySelected: {},
        
        isCategoryLoading: true,
        isCategoryTableUpdateContent: false,

        loading: false,

        // Pack
        packages: [],
        packOthers: [],
        packOthersSelected: {},
        isPackLoading: true,
        isPackDeleting: false,
        isPackToggling: false,

        categoriePacks: [],
        isCategoriePackLoading: false,
        isRefreshPackContent: false,

        guaranties: [],
        packOthersGuaranties: [],
        packOthersGuarantiesSelected: {},
        isLoadingPackOthersGuaranties: false,

        isPackGuaranteeAdding: false,

        // Engagements
        engagements: [],
        isEngagementsLoading: false,

    });

    useEffect(() => {
        getAllCategories();
        PackageService.getAllPackages((respData) => {
            setState(state => ({ ...state, packages: respData || [], packOthers: respData || [], isPackLoading: false }));
        }, (error) => { 
            setState(state => ({ ...state, isPackLoading: false }));
        });

        GuaranteeService.getAll({}, (data)=> {
            const _guaranties = (data || []).sort(((a,b)=> a.id - b.id));
            setState(state => ({ ...state, guaranties:_guaranties, packOthersGuaranties: data || [] }));
        }, (error)=> {

        })
    }, [])

    const getAllCategories = () => {
        setState(state => ({ ...state, isCategoryLoading: true }));
        (new CategoryService()).readAllCategory({}, (data) => {
            const _categories = (data || []).sort(((a,b) => a.pos - b.pos));
            setState(state => ({ ...state, categories: _categories, dataSource: _categories, isCategoryLoading: false, isCategoryTableUpdateContent: false }));
        }, (error) => {
            setState(state => ({ ...state, isCategoryLoading: false }));
         });

    }

    const loadCategoryPackAndGaranties=(category, refreshLoading=false)=> {
        if(refreshLoading) {
            setState(state => ({ ...state, isRefreshPackContent: true }));
        }
        else {
            setState(state => ({ ...state, isCategoriePackLoading: true }));
        }
        
        CategoryService.getPacksAndGuaranties(category?.id, (data)=> {
            const packs = data || [];
            // PACKAGES
            const _packages = (state.packages || []).filter((pack)=> !packs.map((item)=> item?.id ).includes(pack?.id)) ;
            // GUARANTIES
            const _garanties = _.first(packs)?.guaranteeLine?.map((guaranteeLine)=> guaranteeLine?.guarantee);
            const _packOthersGuaranties = (state.guaranties || []).filter((guarantee)=> !(_garanties || []).map((item)=> item?.id ).includes(guarantee?.id)) ;

            setState(state => ({ 
                ...state, 
                categoriePacks: packs, 
                packOthers: _packages, 
                packOthersGuaranties: _packOthersGuaranties, 
                categorySelected: category, 
                isCategoriePackLoading: false ,
                isRefreshPackContent: false
            }));
        }, (error)=> {
            setState(state => ({ ...state, isCategoriePackLoading: false, isRefreshPackContent: false }));
        })
    }

    // ::::::: PACK ::::::::::::: //
    
    const deleteAttachPack=(packRef)=> {
        setState(state=> ({...state, isPackDeleting: true}))
        const options = {
            path: { 
                categoryRef: state?.categorySelected?.ref,
                packRef: packRef
            }
        }
        CategoryService.deleteAttachPack(options, (data)=> {
            setState(state=> ({...state, isPackDeleting: false}))
            setDeleteAlerteVisibility(false);
            loadCategoryPackAndGaranties(state.categorySelected)
        }, (error)=> {
            setDeleteAlerteVisibility(false);
            setState(state=> ({...state, isPackDeleting: false}))
            toast.error("Echec de suppression de pack")
        })
    }

    const toggleAttachPack=(packRef)=> {
        setState(state=> ({...state, isPackToggling: true}))
        const options = {
            path: { 
                categoryRef: state?.categorySelected?.ref,
                packRef: packRef
            }
        }
        CategoryService.toggleAttachPack(options, (data)=> {
            setState(state=> ({...state, isPackToggling: false}))
            toast.success("Le pack a été mise à jour")
        }, (error)=> {
            setState(state=> ({...state, isPackToggling: false}))
            toast.error("Echec de mise à jour du pack")
        })
    }

    // :::::::::::::::::::::::::::::: //


    const onFormDrawerOpen = (category, actionType) => {
        setState(state=> ({...state, categorySelected: category}))
        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: true, actionType: actionType }));
    }

    const onDetailsDrawerOpen = (category) => {
        setDetailsPackDrawerVisibility({ visible: true })
        loadCategoryPackAndGaranties(category)        
    }

    const onCategoryPackTabSelected=(panelIndex)=> {
        console.log(":::: onCategoryPackTabSelected panelIndex : ", panelIndex)
        console.log(":::: onCategoryPackTabSelected state?.categoriePacks[panelIndex] : ", state?.categoriePacks[panelIndex])
        const categoryPackSelected = state?.categoriePacks[panelIndex];
        const _garanties = categoryPackSelected?.guaranteeLine?.map((guaranteeLine)=> guaranteeLine?.guarantee);
        const _packOthersGuaranties = (state.guaranties || []).filter((guarantee)=> !(_garanties || []).map((item)=> item?.id ).includes(guarantee?.id)) ;
        setState(state => ({ ...state, packOthersGuaranties: _packOthersGuaranties }));
        setDeleteAlerteVisibility(false)
    }

    const columns = [

        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            width: 100,
            filters: state.dataSource.map((item)=> ({
                text: item?.code,
                value: item?.code,
              })),
            onFilter: (value, record) => record?.code?.startsWith(value),
            filterSearch: true,
            render: (code) => (
                <div style={{ fontWeight: 'bold' }}>
                    {code}
                </div>
            )
        },
        {
            title: "Titre",
            dataIndex: "title",
            key: "title",
            filters: state.dataSource.map((item)=> ({
                text: item?.title,
                value: item?.title,
              })),
            onFilter: (value, record) => record?.title?.startsWith(value),
            filterSearch: true,

        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",

        },
        {
            title: "Actions",
            dataIndex: "action",
            key: "action",
            render: (item, category) => (
                <>
                    <EditOutlined style={{ color: '#40E070', cursor: 'pointer', marginRight: 20 }} onClick={() => onFormDrawerOpen(category, ActionType.EDIT)} />
                    {
                        category?.code !== '400' ? (<SettingTwoTone onClick={() => onDetailsDrawerOpen(category)}/>) : null
                    }
                    
                    {/* <Dropdown overlay={(
                        <Menu
                            items={[
                                {
                                    key: '1',
                                    label: <div style={{ color: '#000', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onDetailsDrawerOpen(category)}>
                                        <PlusOutlined style={{ color: '#000', marginRight: 5 }} /> Ajouter un package
                                    </div>
                                },
                                // {
                                //     key: '2',
                                //     label: <div style={{ color: '#000', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => console.log("Merci")}>
                                //         <PlusOutlined style={{ color: '#000', marginRight: 5 }} /> Ajouter un package
                                //     </div>
                                // },
                                // {
                                //     key: '3',
                                //     label: <div style={{ color: '#000', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => console.log("Merci")}>
                                //         <PlusOutlined style={{ color: '#000', marginRight: 5 }} /> Ajouter un package
                                //     </div>
                                // },
                            ]}
                        />
                    )}>
                        
                        <MoreOutlined style={{ color: 'orange', fontSize: 18 }} />
                    </Dropdown> */}
                </>
            )
        }
    ];

    return (
        <React.Fragment>
            <Table
                dataSource={state.dataSource}
                columns={columns}
                pagination={{ position: ["bottomRight"], pageSize: 11 }}
                loading={state.isCategoryLoading}
            />

            <EditCategory 
                category={state?.categorySelected}
                drawerVisibility={drawerVisibility} 
                setDrawerVisibility={setDrawerVisibility}
                refreshCategories={getAllCategories}
                />

            <Drawer
                title= {`${state?.categorySelected?.code} : ${state?.categorySelected?.title}`}
                width={800}
                onClose={() => setDetailsPackDrawerVisibility({ visible: false })}
                visible={detailsPackDrawerVisibility.visible}
                bodyStyle={{
                    paddingBottom: 80,
                }}
            >
                <Tabs defaultActiveKey="1" onChange={''} key={"kParams"}>
                    <TabPane tab="Packages" key="1" tabKey="params"> 
                        <AddPack 
                            category={state?.categorySelected} 
                            packs={state?.packOthers} 
                            refreshPacksAndGuaranties={loadCategoryPackAndGaranties}/>
                        
                        {
                            state?.isCategoriePackLoading ? (<><Spin /> Chargement des configurations ...</>) : (
                                <Tabs defaultActiveKey="0" onChange={(e)=> onCategoryPackTabSelected(e)} tabPosition="left" key={"kPacks"} >
                                    {
                                        (state.categoriePacks || []).map((pack, index) => {
                                            return (<TabPane tab={pack?.title} key={`${index}`} tabKey="kPacks">
                                                
                                                <Divider orientation="right" orientationMargin={10} style={{margin:0}}> 
                                                    <div style={{display: 'flex', justifyContent: 'end', flexDirection: "row"}}>
                                                        <div style={{marginRight: 5}}>{pack?.title}</div>
                                                        <div style={{marginRight: 5}}>
                                                            <Switch 
                                                                defaultChecked={pack?.status} 
                                                                unCheckedChildren={"Desactivé"} 
                                                                checkedChildren={"Activé"} 
                                                                size="small" 
                                                                loading={state?.isPackToggling}
                                                                onChange={()=> toggleAttachPack(pack?.ref)}/>
                                                            </div>
                                                        <div >
                                                            <Tooltip title="Supprimer le pack" align={"bottom"}>
                                                                <DeleteOutlined style={{color: 'red', fontSize: 20, cursor: "pointer"}} onClick={()=> setDeleteAlerteVisibility(true)} />
                                                            </Tooltip>
                                                            
                                                        </div>
                                                    </div>
                                                </Divider>
                                                {
                                                    deleteAlerteVisibility ? (
                                                        <Alert 
                                                            message={`Voulez-vous vraiment supprimer (${pack?.title})`} 
                                                            type="error" 
                                                            closeText="Annuler"
                                                            onClose={()=> setDeleteAlerteVisibility(false)}
                                                            action={
                                                                <Button size="small" danger onClick={()=> deleteAttachPack(pack?.ref)} loading={state?.isPackDeleting}>
                                                                Supprimer
                                                                </Button>
                                                            }
                                                        />
                                                    ) : null
                                                }
                                                
                                                {
                                                    state.isRefreshPackContent ? (<><Spin /> Chargement des configurations ...</>) : (
                                                        <PackOptions 
                                                            category={state?.categorySelected} 
                                                            pack={pack}
                                                            categoryPacks={state?.categoriePacks}
                                                            refreshPacksAndGuaranties={loadCategoryPackAndGaranties}
                                                            packOthersGuaranties={state?.packOthersGuaranties}
                                                            isLoadingPackOthersGuaranties={false} key={"packOptions"}/>
                                                    )
                                                }
                                                

                                            </TabPane>);
                                        })
                                    }
                                </Tabs>
                            )
                        }
                    </TabPane>

                    <TabPane tab="Extra" key="2" tabKey="kParams"> </TabPane>
                    <TabPane tab="Periode" key="3" tabKey="kParams"> </TabPane>
                </Tabs>
                
            </Drawer>
        </React.Fragment>
    )
}

export default Category;