export default class FormEditModel {
    id;
    code;
    categorie;
    description;
    // brandRef;

    fromJson = (json = {}) => {
        var formEditModel = new FormEditModel();
        formEditModel = json;
        return formEditModel;
    }
}