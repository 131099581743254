import { notification } from 'antd';
import * as constants from 'constantes'
import moment from 'moment';
import DocumentService from '../services/documentService';

export function getUrlVars(url) {
  const vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}

export function deepUpdateObject(obj, path, cb) {
  const checkValidPath = (obj, path) => {
    if (path.length > 1) {
      if (typeof obj[path[0]] !== 'object') {
        return false
      } else {
        return checkValidPath(obj[path[0]], path.slice(1, path.length))
      }
    } else {
      // if (obj[path[0]]) {
      if (obj.hasOwnProperty(path[0])) {
        return true
      } else {
        return false
      }
    }
  }

  if (checkValidPath(obj, path)) {
    // if (typeof obj[path[0]] !== 'object') return false
    const key = path[0];
    if (path.length > 1) {
      // CALL RECURSIVELY
      return Object.assign({}, obj, { [key]: deepUpdateObject(Object.assign({}, obj[key]), path.slice(1, path.length), cb) })
    } else {
      return Object.assign({}, obj, { [key]: cb(obj[key]) })
    }
  } else {
    return false
  }
}

export function getObjectFromObjectTable(prevObject, objectTable) {
  let newObject = { ...prevObject }
  const _objTable = objectTable || [];
  
  for (let item of objectTable) {
    newObject = deepUpdateObject(newObject, item.path, (val) => item.value);
    if (!newObject) return false
  }
  return newObject
}

export function goBack(context, history) {
  if (context) context.props.history.goBack();
  else if (history) history.goBack();
  else return;
}

export function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = ` ${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

export function isAmount(value, cb) {
  const reg = /^-?\d*(\.\d*)?$/;

  if ((!isNaN(value) && reg.test(value) && value !== 0) || value === '' || value === '-') {
    cb()
  }
}

/* ---Debut Maninpuler les donnees pour la monnaies F CFA --- */

function isInteger(value) {
  const reg = /^\d+$/;

  if (reg.test(value)) {
    return true
  } else {
    return false
  }
}

function formatIntegerValidToFcfaAmount(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = ` ${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

export function formatNumberInFcfaAmount(valueToBeFormattedInFcfaAmount) {

  try {
    let valueFormatted = `${valueToBeFormattedInFcfaAmount}`.replace(/\s+/g, '');

    if (!isInteger(valueFormatted)) {
      return '';
    }

    valueFormatted = formatIntegerValidToFcfaAmount(valueFormatted)

    return valueFormatted;

  } catch (error) {
    return '';
  }
}

export function formatFcfaAmountInInteger(fcfaAmountValidToBeFormattedInInteger) {
  return `${fcfaAmountValidToBeFormattedInInteger}`.replace(/\s+/g, '');
}

/* ---Fin Maninpuler les donnees pour la monnaies F CFA --- */

export function isNumeric(value, cb) {
  const reg = /^-?\d*(\.\d*)?$/;

  if ((!isNaN(value) && reg.test(value) && value !== 0) || value === '' || value === '-') {
    cb()
  }
}

export function getAmounts(dataSource, name) {
  const total = dataSource?.reduce((accumulator, item) => {
    const currentAmount = name ? parseInt(item[name]) : parseInt(item);

    return (parseInt(accumulator) + (isNaN(currentAmount) ? 0 : currentAmount))
  }, 0)
  return total;
}

export function goToStep(currentStep, data, setState) {
  setState(state => {
    const newData = data ? getObjectFromObjectTable(state, data) : state
    return { ...state, ...newData, currentStep: currentStep || 0 }
  })
}

export function goToNextStep(data, setState) {
  setState(state => {
    const newData = data ? getObjectFromObjectTable(state, data) : state;
    return { ...state, ...newData, currentStep: state.currentStep + 1 }
  })
}

export function goToNextStepStep(data, setState) {
  setState(state => {
    //const newData = data ? getObjectFromObjectTable(state, data) : state;
    const newData = { ...data[0].value }
    return { ...state, ...newData, currentStep: state.currentStep + 1 }
  })
}

export function goToPreviousStep(data, setState) {
  setState(state => {
    const newData = data ? getObjectFromObjectTable(state, data) : state
    return { ...state, ...newData, currentStep: state.currentStep - 1 }
  })
}

export function goToView(view, data, setState) {
  setState(state => {
    const newData = data ? getObjectFromObjectTable(state, data) : state
    return { ...state, ...newData, currentView: view }
  })
}

export function updateState(data, setState) {
  setState(state => {
    const newData = data ? getObjectFromObjectTable(state, data) : state
    return { ...state, ...newData }
  })
}

// ---------- Stateless
export function statelessUpdate(data, state) {
  const newData = data ? getObjectFromObjectTable(state, data) : state
  return { ...state, ...newData }
}

export function statelessGotoNextStep(data, state) {
  const newData = data ? getObjectFromObjectTable(state, data) : state;
  return { ...state, ...newData, currentStep: state.currentStep + 1 }
}

export function statelessGotoPreviousStep(data, state) {
  const newData = data ? getObjectFromObjectTable(state, data) : state;
  return { ...state, ...newData, currentStep: state.currentStep - 1 }
}

// --------------------------------------------------------------
export function openFileInWindow(fileName, path) {
  if (path) window.open(path)
  else window.open(`${constants.API_URL.PDF_VIEWER_URL}${fileName}`);
}

export function getInitials(name = '') {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('');
}

export function thousandSeparator(x) {
  if (x == null || x === undefined) {
    return 0;
  }
  else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
}

export const openNotificationWithIcon = (type, msg) => {
  notification[type]({
    message: msg
  });
};

export function getToday() {
  let day = new Date().getDate();
  let month = new Date().getMonth() + 1;
  let year = new Date().getFullYear();
  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;
  let date = day + '-' + month + '-' + year;
  // console.log('Today ', date)
  return date;
}

// https://stackoverflow.com/questions/53764209/disable-dates-before-current-date-and-after-1-month-of-current-date-in-antd-date
// <Can not select days before today and today>
export const disabledPrevDate = (current) => current && current < moment().add(-1,'day'); // 

// Can not select days before today and today
export const disabledNextDate = (current) => current && current > moment().endOf('day');

// Can not select days before today and today
export function disableDateRanges(range = { startDate: false, endDate: false }) {
  const { startDate, endDate } = range;
  
  return function disabledDate(current) {
    let startCheck = true;
    let endCheck = true;
    if (startDate) {
      startCheck = current < moment(startDate, 'YYYY-MM-DD'); //current && 
    }
    if (endDate) {
      endCheck = current && current > moment(endDate, 'YYYY-MM-DD');
    }
    return (startDate && startCheck) || (endDate && endCheck);
  };
}

export const disabledPrevCustomerDate = (current, start) => {
  return moment(current).isBefore(start);
}

export const returnTime = (time) => {
  // console.log('time --> ', time);
  let day = time.substring(0, 2);
  let month = time.substring(3, 5);
  let year = time.substring(10, 6);
  // console.log('day ***', day, 'MONTH *****', month, 'year ***', year);

  return year + '-' + month + '-' + day;
}

export const convert = (str) => {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);

  let convertedDate = (str === "" || str === null || str === undefined) ? "Non definie" : [date.getFullYear(), mnth, day].join("-");
  if (str === "" || str == null || str === undefined) {
    return convertedDate;
  }
  else {
    return new Date(convertedDate).toLocaleDateString();
  }
};

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function randomString(length) {
  return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
}

export function formatDate(date) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
}

export function splitDate(strDate) {
  if(strDate) {
    const date = strDate.split("T");
    if(date?.length > 0) {
      return date[0];
    }
  }
  return strDate;
}

export function formatUTCDate(strDate) {
  let date, hour;
  if(strDate) {
    const dateSplit = strDate.split("T");
    if(dateSplit?.length > 0) {
      date = dateSplit[0];
    }
    if(dateSplit?.length > 1) {
      hour = dateSplit[1];
    }
  }
  return `${date} à ${hour}`;;
}

export const formatDateToUTC=(strDate)=> {
  const date = (new Date(strDate));
  const hours = date.getUTCHours();
}
export const base64ToArrayBuffer = (base64) => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export const saveByteArray = (reportName, byte) => {

  // var blob = new Blob([byte], {type: "application/pdf"});
  var blob = new Blob([byte], { type: "application/octet-stream" });
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(link.href);
}

export const saveAsXlsxFile = (base64) => {
  var documentXLSX = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + base64;
  document.location.href = documentXLSX;
}

export class ActionType {
  static ADD = "add";
  static DETAILS = "details";
  static EDIT = "edit";
  static DELETE = "delete";
  static COPY = "copy";
}

export class DocumentType {
  static CP = "cps";
  static CG = "cgs";
  static CEDEAO = "cedeao";
  static JAUNE = "jaune";
  static FACTURE = "factures";
  static REPPORT = "reports";
  static DEVIS = "projects";

  // FLOTTE
  static CP_FLOTTE = "cps_flotte";
  static FACTURE_FLOTTE = "facture_flotte";
}

export const onDocumentOpen = (documentType, filename) => {

  DocumentService.openDocument(documentType, filename)
}

export const onDocumentDownload = (filename, documentType) => {
  DocumentService.documentDownload(filename, documentType)
}


export class PAGES {
  static MODAL_DATE_EFFET = "modal_date_effet";
  static THANK_YOU = "thank_you_page";
  static PROJECT_THANK_YOU = "project_tjank_you";
}

export class FlotteWidget {
  static INTRO        = "intro";
  static STEPS        = "steps";
  static LIST_VEHICLE = "vehicles";
  static ASSURE_DETAILS = "assure_details";
  static DETAILS      = "details";
  static THANK_YOU    = "thank_you_page";
  static PROJECT_THANK_YOU = "project_tjank_you";
}

export class TYPE {
  static MONO = "MONO";
  static FLOTTE = "FLOTTE";
}

export class SubscriptionSwitchOption {
  static LIST_SUBSCRIPTION = "LIST_SUBSCRIPTION";
  static LIST_ASSURE = "LIST_ASSURE";
  static LIST_PROJECT = "LIST_PROJECT";
}

export class TableAction {
  static DETAILS = "DETAILS";
  static COINSURANCE= "COINSURANCE"
}