export default class CreateReductionRequest {
    code;
    title;
    description;
    rate;
    critere;
    groupeReduction;

    fromJson = (json = {}) => {
        var createReductionRequest = new CreateReductionRequest();
        createReductionRequest = json;
        return createReductionRequest;
    }
}