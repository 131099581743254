import React from "react";
import { useState } from "react";
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import MDetailReduction from "./MDetailReduction";
import MDetailListVehicle from "./MDetailListVehicle";
import MDetailGuaranties from "./MDetailGuaranties";
import MDetailAssistance from "./MDetailAssistance";
import MDetailAssure from "./MDetailAssure";

const MDetailOthers = ({ assure, vehicle, guaranties, reductions, assistance, defaultTab }) => {

    // Nav With Badge
    const [navBadgeTab, setnavBadgeTab] = useState(defaultTab ?? "1");
    const navBadgeToggle = (tab) => {
        if (navBadgeTab !== tab) {
            setnavBadgeTab(tab);
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardHeader className="border-0 align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Information complementaire</h4>
                        </CardHeader>
                        <CardBody className="p-0">
                            <div className="bg-soft-light border-top-dashed border border-start-0 border-end-0 border-bottom-dashed py-3 px-4">
                                <Row className="align-items-center">
                                    <Nav tabs className="nav-tabs nav-justified mb-3">
                                        {vehicle && (
                                            <NavItem>
                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: navBadgeTab === "1", })} onClick={() => { navBadgeToggle("1"); }} >
                                                    Vehicules
                                                </NavLink>
                                            </NavItem>
                                        )}
                                        
                                        {guaranties && (
                                            <NavItem>
                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: navBadgeTab === "2", })} onClick={() => { navBadgeToggle("2"); }} >
                                                    Garanties <span className="badge bg-success">{guaranties?.length}</span>
                                                </NavLink>
                                            </NavItem>
                                        )}
                                        
                                        {reductions && (
                                            <NavItem>
                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: navBadgeTab === "3", })} onClick={() => { navBadgeToggle("3"); }} >
                                                    Reductions
                                                </NavLink>
                                            </NavItem>
                                        )}
                                        
                                        {assistance && (
                                            <NavItem>
                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: navBadgeTab === "4", })} onClick={() => { navBadgeToggle("4"); }} >
                                                    Assistances
                                                </NavLink>
                                            </NavItem>
                                        )}
                                        
                                        {assure && (
                                            <NavItem>
                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: navBadgeTab === "5", })} onClick={() => { navBadgeToggle("5"); }} >
                                                    Assuré
                                                </NavLink>
                                            </NavItem>
                                        )}
                                        
                                    </Nav>

                                    <TabContent activeTab={navBadgeTab} className="text-muted">
                                        {vehicle && (
                                            <TabPane tabId="1" id="nav-badge-home">
                                                <MDetailListVehicle vehicle={vehicle}/>
                                            </TabPane>
                                        )}

                                        {guaranties && (
                                            <TabPane tabId="2" id="nav-badge-profile">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1 ms-2">
                                                        <MDetailGuaranties guaranties={guaranties} />
                                                    </div>
                                                </div>
                                            </TabPane>
                                        )}
                                        
                                        {reductions && (
                                            <TabPane tabId="3" id="nav-badge-messages" >
                                                <div className="d-flex">
                                                    <div className="flex-grow-1 ms-2">
                                                        <MDetailReduction reductions={reductions} />
                                                    </div>
                                                </div>
                                            </TabPane>
                                        )}
                                        
                                        {assistance && (
                                            <TabPane tabId="4" id="nav-badge-settings">
                                                <div className="d-flex mt-2">
                                                    <div className="flex-grow-1 ms-2">
                                                        <MDetailAssistance assistance={assistance}/>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        )}
                                        
                                        {assure && (
                                            <TabPane tabId="5" id="nav-badge-settings">
                                                <div className="d-flex mt-2">
                                                    <div className="flex-grow-1 ms-2">
                                                        <MDetailAssure assure={assure} />
                                                    </div>
                                                </div>
                                                {/* <div className="d-flex mt-2">
                                                    <div className="flex-shrink-0">
                                                        <i className="ri-checkbox-circle-fill text-success"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        After gathering lots of different opinions and graphic design basics, I came up with a list of 30 graphic design tips that you can start implementing.
                                                    </div>
                                                </div> */}
                                            </TabPane>
                                        )}
                                    </TabContent>
                                </Row>
                            </div>
                        </CardBody>
                        <div className="card-body p-0 pb-3">
                            <div id="Market_chart" className="apex-charts" dir="ltr">
                                {/* <MarkerCharts series={chartData} dataColors='["--vz-success", "--vz-danger"]' /> */}
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default MDetailOthers;