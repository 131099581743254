import QuotationRequest from "api/request/quotationRequest";
import Pack from "api/model/pack";
import SubscriptionRequest from "api/request/subscriptionRequest";
import { FormField } from "forms/FormField";
import QuotationResponse from "api/v1/response/QuotationResponse";

export default class Basket {
    id;
    key;
    formField = new FormField({});
    quotationRequest = new QuotationRequest();
    quotationResponse = new QuotationResponse();
    packSelected = new Pack();
    subscriptionRequest= new SubscriptionRequest();
    
    fromJson = (json = {}) => {
        var basket = new Basket();
        basket = json
        return basket
    }
}