// Material helpers
import { createTheme } from '@mui/material/styles';

import globalTheme from 'modules/assets/themes/materialUiTheme';

let theme = globalTheme;

theme = createTheme(theme, {
	palette: { primary: 'rgb(0, 102, 153)' },
});

export default theme;
