import React, { useEffect, useState } from "react";
import {  Spin, Table, Tag, } from "antd";
import CoInsuranceService from "services/coInsuranceService";
import ContractsResponse from "api/response/contratsResponse";

export default function DetailCoInsurance(props) {

    const { contract } = props;

    let _contract = (new ContractsResponse()).fromJson(contract)
    const [state, setState] = useState({
        coIsurancesRepartitions: [],
        isLoad: true
    });

    const columns = [

        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            render: (item, data) => (
                <>
                    {data.coInsurance.code }
                </>
            )

        },
        {
            title: "Titre",
            dataIndex: "title",
            key: "title",
            render: (item, data) => (
                <>
                    {data.coInsurance.title }
                </>
            )

        },
        {
            title: "Taux",
            dataIndex: "taux",
            key: "taux",
            render: (item, data) => (
                <>
                    {data.rate + ' %'}
                </>
            )
        },
        {
            title: "Part",
            dataIndex: "montant",
            key: "montant",
            align: "right",
            render: (item, data) => (
                <>
                    <Tag color="processing">{data.primePart + ' FCFA'}</Tag>
                </>
            )
        },
    ];

    useEffect(()=> {
        let options = {
            path: {
                contractDetailID: _contract.id, 
            }
        };

        (new CoInsuranceService()).readAllCoInsuranceByContractDetailID(options, (data)=> {
            setState(state => ({ ...state, coIsurancesRepartitions: data }))
        }, (error) => {

        })
    }, [])


    return (
        <div>
            <div>
                <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Liste des souscipteurs</h4>
            </div><br />

            {state.isLoad ? (
                <Table dataSource={state.coIsurancesRepartitions} columns={columns} />
            ) : (<Spin />)}

        </div>
    )
}