import { GiftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

const FooterDrawer=({onConfirm, onCancel, confirmText, disableConfirm, cancelText})=> {
    return (
        <React.Fragment>
             <div className="offcanvas-foorter border p-1 text-center bg-soft-dark" style={{position: "fixed",bottom: 0, right:0, width: "50%"}}>
                <div className="d-flex align-items-start gap-3 mr-3">
                    
                    <button
                        onClick={()=> onCancel() }
                        type="button"
                        className="btn btn-light btn-label previestab"
                        data-previous="pills-bill-info-tab"
                      >
                        <i className="ri-close-line label-icon align-middle fs-16 me-2"></i>
                        {cancelText ?? 'Fermer'}
                    </button>
                    <button
                        disabled={disableConfirm ?? false}
                        onClick={()=>onConfirm()}
                        type="button"
                        className="btn btn-primary btn-label right ms-auto nexttab"
                    >
                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{" "}
                        <GiftOutlined style={{ color: '#ffffff', fontSize: 20 }} /> {confirmText}
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FooterDrawer;