import React from "react";
import {  Col, Form, Select, Spin } from "antd";
import { useState } from "react";
import { useEffect } from "react";

const ReductionField=({ reduction, setReduction, handlerFormField, suffixIcon, formItemStyle={label: "Reduction"}, size='large', disable, xs, md})=> {
    const { Option } = Select;

    useEffect(()=> {
        console.log(":::: reduction :", reduction)
    }, [reduction])

    const onReductionSelected=(index)=> {
        setReduction({...reduction, rate: index/100})
    }

    return (
        <React.Fragment>
            <div>
            <Select  
                defaultValue={reduction?.rate * 100}
                size={size} 
                suffixIcon={suffixIcon} 
                disabled={disable} 
                key={'red'} 
                {...formItemStyle}
                onChange={onReductionSelected}
                >
                { [0, 5, 10, 15].map((index) =><Option value={index} key={`rdg${index}`} >{index}</Option>)}
            </Select>  
            </div>
        </React.Fragment>
    )
}

export default ReductionField;