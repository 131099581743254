import { InfoCircleOutlined } from "@ant-design/icons";
import { Checkbox, Tooltip } from "antd";
import React, { useState } from "react";

const info = (title, description) => (
    <div>
        <div style={{ borderBottom: '1px solid white', marginBottom: 10 }}>
            <h4 style={{ color: 'white' }}>{title}</h4>
        </div>
        <p>
            {description}
        </p>
    </div>
)

const SettingAssisance=({assistance, setAssistanceLinesCollect })=> {

    const [assistancesLinesSelected, setAssistancesLinesSelected] = useState([]);

    const onAssistanceSelected=(e, assistanceLine)=> {
        
        let _assistancesLinesCollect = assistancesLinesSelected;
        
        const checked= e?.target?.checked;
        console.log(":::: e :",  e)
        if (checked) {
            console.log(":::: checked :",  checked)
            const index = _assistancesLinesCollect?.findIndex((al)=> al?.id === assistanceLine?.id);
            if(index === -1) {
                _assistancesLinesCollect.push({...assistanceLine}) 
            } 
        }
        else {
            _assistancesLinesCollect = _assistancesLinesCollect?.filter((al)=> al?.id !== assistanceLine?.id);
        }

        console.log(":::: _assistancesLinesCollect :",  _assistancesLinesCollect)
        
        setAssistancesLinesSelected(_assistancesLinesCollect)
        setAssistanceLinesCollect(_assistancesLinesCollect)
    }

    return (
        <React.Fragment>
            <div>
                {
                    assistance ? (
                        (assistance?.assistanceLines || []).map(function (item, index) {
                            return (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }} key={index}>
                                    <div>
                                        {item?.required ? 
                                        (<Checkbox checked style={{ color: '#9EA1A3' }} >{item.guaranteeName}</Checkbox>):
                                        <Checkbox style={{ color: '#9EA1A3' }} onChange={(e)=> onAssistanceSelected(e, item)}>{item.guaranteeName}</Checkbox>}
                                        
                                        {/* <h4 style={{ marginLeft: 8, }}>{item.amount}</h4> */}
                                    </div>
                                    <Tooltip placement="right" title={info(item.assistanceTitle, item.guaranteeName)}>
                                        <InfoCircleOutlined style={{ color: 'blue', fontSize: 15, cursor: 'pointer' }} />
                                    </Tooltip>
                                </div>
                            )
                        })
                    ) : null}
            </div>
        </React.Fragment>
    )
}

export default SettingAssisance;