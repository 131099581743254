import * as constants from "constantes";
import React from "react";
import FAvenantAnnulation from "./avenant/annulation";
import FChangementAssure from "./avenant/changement_assure";
import FAvenantDuplicata from "./avenant/duplicata";
import FAvenantDeCreation from "./avenant/nouvelle_subscription";
import FAvenantProrogation from "./avenant/prorogation";
import FAvenantRenouvellementSansModification from "./avenant/renouvellement_sans_modification";
import FAvenantRenouvellementAvecModification from "./avenant/renouvellement_avec_modification";
import FAvenantChangementImmatriculation from "./avenant/changement_immatriculation";
import FAvenantChangementVehicule from "./avenant/changement_vehicule";
import FAvenantremiseEnVigueur from "./avenant/remise_en_vigueur";
import FAvenantResiliationAvecRistourne from "./avenant/resiliation_avec_ristourne";
import FAvenantIncorporation from "./avenant/incorporation/index";
import FAvenantRetraitVehicule from "./avenant/retrait_de_vehicule";

const FlotteSubscription = ({ avenant }) => {

    // NORMAL
    function renderChildren() {
        switch (parseInt(avenant?.code)) {
            case constants.CODE_AVENANTS.CREATION:
                return <FAvenantDeCreation />
                
            case constants.CODE_AVENANTS.RENOUVELEMENT_SANS_MODIFICATION:
                return <FAvenantRenouvellementSansModification />

            case constants.CODE_AVENANTS.RENOUVELEMENT_AVEC_MODIFICATION:
                return <FAvenantRenouvellementAvecModification />

            case constants.CODE_AVENANTS.CHANGEMENT_ASSURE:
                return <FChangementAssure />

            case constants.CODE_AVENANTS.DUPLICATA_ATTESTATION:
                return <FAvenantDuplicata />

            case constants.CODE_AVENANTS.PROROGATON:
                return <FAvenantProrogation />

            case constants.CODE_AVENANTS.CHANGEMENT_IMMATRICULATION:
                return <FAvenantChangementImmatriculation />

            case constants.CODE_AVENANTS.CHANGEMENT_VEHICULE:
                return <FAvenantChangementVehicule />

            
            case constants.CODE_AVENANTS.ANNULATION_PURE:
                return <FAvenantAnnulation />

            case constants.CODE_AVENANTS.REMISE_EN_VIGUEUR_AVEC_REPORT:
                return <FAvenantremiseEnVigueur />
                
            case constants.CODE_AVENANTS.RESILIATION_AVEC_RISTOUNE:
                return <FAvenantResiliationAvecRistourne />

            case constants.CODE_AVENANTS.INCORPORATION:
                return <FAvenantIncorporation />
                
            case constants.CODE_AVENANTS.RETRAIT_VEHICULE:
                return <FAvenantRetraitVehicule />

            default:
        }

    }

    return (
        <React.Fragment>
            {renderChildren()}
        </React.Fragment>
    )
}

export default FlotteSubscription;