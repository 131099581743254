import { Table } from "antd";
import _ from "lodash";
import React from "react";
import { Col, Row } from "reactstrap";

const MDetailAssistance=({assistance})=> {

    const columns = [
        {
            title: 'N°',
            dataIndex: 'id',
            key: 'id',
            render: (item, _)=> {
                return (
                    <div>{item}</div>
                )
            }
        },
        {
            title: 'Assistance',
            dataIndex: 'guaranteeName',
            key: 'guaranteeName',
        },
    ];

    return (
        <React.Fragment>
            <Row>

                <Col lg={12}>
                    <Table
                        /* loading={isFlotteQuotationLoading}*/
                        columns= {columns} 
                        dataSource= { _.size(assistance?.customAssistancesLines ) > 0 ?  assistance?.customAssistancesLines : assistance?.assistanceLines}
                        //pagination= {{ position: ["topRight"], pageSize: pageSize ? pageSize : 10}}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default MDetailAssistance;