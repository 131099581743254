import { Card, Spin } from "antd";
import WizardHeader from "modules/components/WizardHeader";
import React from "react";
import ErrorWidget from "components/Widgets/ErrorWidget";
import { StepThankYou } from "../../common/steps";
import useInitAvenant from "views/pages/subscription/mono/avenant/useInitAvenant";
import { StepDetails } from "./steps";
import { useSelector } from "react-redux";
import * as helpers from 'helpers/helpers';
import InitAvenantRequest from "api/request/initAvenantRequest";
import * as constants from 'constantes'; 

export default function AvenantResiliationAvecRistourne() {
    const {contract, category, avenant, assure, period, projectCode, partnerCode } = useSelector((state) => state.subscription);
    const { currentStep, page, basket, transactionResponse, initAvenantResponse } = useSelector((state) => state.basketSlice);

    const {state, initialiseAvenant} = useInitAvenant();

    if(state.isInitAvenantLoading) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if(state.iniAvenantLoadingError) {
        return <ErrorWidget errorMessage={state.iniAvenantLoadingErrorMessage} />;
    }

    function renderChildren() {
        switch (currentStep) {
            case 0:
                const initAvenantRequest = InitAvenantRequest.fromJson({
                    productRef: constants.PRODUCT_AUTO_REF,
                    contractRef: contract?.ref,
                    avenantCode: avenant?.code,
                    assureCode: assure?.code,
                    periodRef: period?.ref,
                    projectCode: projectCode 
                });
                return <StepDetails
                            avenantCode={avenant?.code}
                            initAvenantRequest={initAvenantRequest}
                            initialiseAvenant={initialiseAvenant}
                            initAvenantResponse={initAvenantResponse}
                            packSelected={basket?.packSelected}
                            subscriptionRequest={basket?.subscriptionRequest}
                            refresh={state?.isInitAvenantLoading}
                  />
            case 1:
                if(page === helpers.PAGES.THANK_YOU) {
                    return <StepThankYou transactionResponse={transactionResponse} />;
                }
            break;
            default:
                return <ErrorWidget errorMessage="Ooops la page n'existe pas" />;
        }
    }

    return (
        <React.Fragment>
            <WizardHeader
                title={`Categorie ${category?.code} : ${category?.title}`}
                avenantTilte="Avenant de resiliation avec ristoune"
                partnerCode={partnerCode}
                police={contract?.policeNumber}
                percent={state.currentStep * 100}
            />

            {renderChildren()}
        </React.Fragment>
    )
}