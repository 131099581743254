export default class Transport {
    id;
    ref;
    code;
    title;
    description;
    
    static fromJson = (json={})=> {
        var transport = new Transport();
        transport = json;
        return transport
    }
}