import React, { useEffect } from "react";
import WizardHeader from "modules/components/WizardHeader";
import { useSelector } from "react-redux";
import * as helpers from 'helpers/helpers';
import useInitFlotteAvenant from "../useInitFlotteAvenant";
import { Card, Spin } from "antd";
import ErrorWidget from "components/Widgets/ErrorWidget";
import { FStepAssure, FStepDetails, FStepThankYou } from "./steps";
import { useBasket } from "views/pages/subscription/hooks/useBasket";
import QuotationService from "services/quotationService";
import { useState } from "react";

const FChangementAssure=()=> {
    const {avenant, category, contract, partnerCode} = useSelector((state) => state.subscription);
    const {basket} =  useSelector((state) => state.basketSlice);
    const {updateGroup} = useBasket()
    const {currentStep, page, fSubscriptionRequest, quotationResponse, transactionResponse, initAvenantResponse } = useSelector((state) => state.basketSlice);
    const { state } = useInitFlotteAvenant();

    useEffect(()=> {
        if(!state.avenantStatus?.loading) {
            const _fSubscriptionRequest = {
                ...fSubscriptionRequest,
                primeTTC: quotationResponse?.primeTTC,
                primeExtra: quotationResponse?.primeExtra,
                primeNette: quotationResponse?.primeNette,
                extras: quotationResponse?.extras || []
            }
            updateGroup([
                { path: ['fSubscriptionRequest'], value: { ..._fSubscriptionRequest } },
            ])
          }
    }, [state.avenantStatus?.loading])

    if(state.avenantStatus?.loading) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if(state.avenantStatus?.error) {
        return <ErrorWidget errorMessage={state.avenantStatus?.errorMessage} />;
    }

    function renderChildren() {
        switch (currentStep) {
            case 0:
                return <FStepAssure 
                        primeTTC={fSubscriptionRequest?.primeTTC} 
                        basket={basket} />
            case 1: 
                return <FStepDetails
                            avenantCode={avenant?.code}
                            endDate={initAvenantResponse?.endDate}
                            quotationResponse={quotationResponse} 
                            fSubscriptionRequest={fSubscriptionRequest}
                         />
           
            case 2:
                if(page === helpers.FlotteWidget.THANK_YOU) {
                    return <FStepThankYou transactionResponse={transactionResponse} />
                }
                break;

            default:
                return <ErrorWidget errorMessage="Ooops la page n'existe pas" />;
        }
    }

    return (
        <React.Fragment>
           <WizardHeader
                title={`Categorie ${category?.code} : ${category?.title}`}
                avenantTilte={`Avenant de changement d'assuré`}
                partnerCode={partnerCode}
                police={contract?.policeNumber}
                //onClick={(state.currentStep > 0 && state.currentStep < 4) ? goToPreviousStep : null}
                percent={currentStep * 33}
                />
            {renderChildren()}
        </React.Fragment>
    )
}

export default FChangementAssure;