import {  Form, Input } from "antd";
import React from "react";

const SurpimeField=({disable})=> {
    return (
        <React.Fragment>
            <Form.Item
                name="surprime"
                rules={[{ required: true, message: "Champ obligatoire" }]}
            >
                <Input size='medium' placeholder='Surprime' disabled={disable} addonBefore={"Surprime :"}/>
            </Form.Item>
        </React.Fragment>
    )
}

export default SurpimeField;