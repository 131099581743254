import React from "react";

const ReturnNewLine=(label, info, isEnd, icon)=> {
    return (
        <React.Fragment>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20, paddingTop: 8 }}>
                    <h5>{label}</h5>
                    <h5 style={{ color: 'grey' }}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div >{info}</div>
                            <div style={{paddingLeft: 20}}>{icon}</div>
                        </div>
                    </h5>
                    
                </div>
                {!isEnd ? (
                    <div style={{ borderBottom: '1px solid #d3d3d3', marginLeft: 20, marginRight: 20, paddingTop: 8 }}>
                    </div>
                ) : (
                    <div style={{ marginLeft: 20, marginRight: 20, paddingTop: 8 }}>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default ReturnNewLine;