import { DEFAULT_TABLE_MAX_SIZE, DEFAULT_TABLE_MIN_PAGE, PRODUCT_AUTO_REF } from "constantes"
import { HttpMethod } from "./httpMethod"
import ResourceConfig, { ResourceOptions } from "./routeManager"

export default class ApiRoutes {
    // DASHBOARD STAT
    static readStatsResource = new ResourceConfig({ urlSuffix: "/dashboard/stats", method: HttpMethod.GET })

    // AUTH
    static authResource = new ResourceConfig({ urlSuffix: "/auth/signin", method: HttpMethod.POST, authenticated: false })
    // USER
    static usersResource = new ResourceConfig({ urlSuffix: "/user/all", method: HttpMethod.GET })
    static createUserResource = new ResourceConfig({ urlSuffix: "/user/register", method: HttpMethod.POST })
    static editUserResource = new ResourceConfig({ urlSuffix: "/user/edit", method: HttpMethod.POST })
    static editStatusUserResource = new ResourceConfig({ urlSuffix: "/user/status", method: HttpMethod.POST })

    static rolesResource = new ResourceConfig({ urlSuffix: "/role/all", method: HttpMethod.GET })
    static profilesResource = new ResourceConfig({ urlSuffix: "/profile/all", method: HttpMethod.GET })

    // ENTITIES
    static entitiesResource = new ResourceConfig({ urlSuffix: "/entities/all", method: HttpMethod.GET })
    static entitiesNatureResource = new ResourceConfig({ urlSuffix: "/entities/natures", method: HttpMethod.GET })
    static createEntitieResource = new ResourceConfig({ urlSuffix: "/entities/register", method: HttpMethod.POST })
    static editEntitieResource = new ResourceConfig({ urlSuffix: "/entities/edit", method: HttpMethod.POST })

    /* :::: CONTRIBUTOR :::: */
    static contributorsResource = new ResourceConfig({ urlSuffix: "/contributors/nature/:natureCode", method: HttpMethod.GET })

    /* :::: ASSURE :::: */
    static assureResource = new ResourceConfig({ urlSuffix: "/assures/search/:productRef", method: HttpMethod.GET, resourceOptions: (new ResourceOptions({ 'productRef': PRODUCT_AUTO_REF }, { 'page': DEFAULT_TABLE_MIN_PAGE, 'size': DEFAULT_TABLE_MAX_SIZE })) })
    static assureLoupResource = new ResourceConfig({ urlSuffix: "/assures/loup/search/:productRef", method: HttpMethod.GET, resourceOptions: (new ResourceOptions({ 'productRef': PRODUCT_AUTO_REF }, { 'page': DEFAULT_TABLE_MIN_PAGE, 'size': DEFAULT_TABLE_MAX_SIZE })) })
    static searchableAssureResource = new ResourceConfig({ urlSuffix: "/assures/searchable", method: HttpMethod.GET, resourceOptions: (new ResourceOptions({}, { 'page': DEFAULT_TABLE_MIN_PAGE, 'size': DEFAULT_TABLE_MAX_SIZE })) })
    static searchAssureResource = new ResourceConfig({ urlSuffix: "/assure/search", method: HttpMethod.GET })

    /* :::: MERCHANT :::: */
    static searchMerchantResource = new ResourceConfig({ urlSuffix: "/merchants", method: HttpMethod.GET, resourceOptions: (new ResourceOptions({}, { 'page': DEFAULT_TABLE_MIN_PAGE, 'size': DEFAULT_TABLE_MAX_SIZE })) })
    static attachMerchantOnSubscriptionResource = new ResourceConfig({ urlSuffix: "/subscription/merchant", method: HttpMethod.POST })
    
    /* :::: SUBSCRIPTION :::: */ 
    // <Generation d'une nouvelle soubscription de type contrat>
    static subscriptionMonoGenerateResource = new ResourceConfig({ urlSuffix: "/subscription/mono/contract/generate", method: HttpMethod.POST })
    static subscriptionFlotteGenerateResource = new ResourceConfig({ urlSuffix: "/subscription/flotte/contract/generate", method: HttpMethod.POST })
    static subscriptionTogglePaimentStatus = new ResourceConfig({ urlSuffix: "/subscription/:subscriptionRef/toggle/paiement-espece/status", method: HttpMethod.PUT })
    // <Liste des soubscriptions d'une categorie
    static subscriptionContractsResource = new ResourceConfig({ urlSuffix: "/subscription/contracts/:productRef", method: HttpMethod.GET, resourceOptions: (new ResourceOptions({ 'productRef': PRODUCT_AUTO_REF }, { 'page': DEFAULT_TABLE_MIN_PAGE, 'size': DEFAULT_TABLE_MAX_SIZE })) })
    static assureSubscriptionsByCategoryResource = new ResourceConfig({ urlSuffix: "/subscription/:contractID/:assureCode", method: HttpMethod.GET, resourceOptions: (new ResourceOptions({}, { 'productRef': PRODUCT_AUTO_REF })) })
    static addCancelMotifRessource = new ResourceConfig({ urlSuffix: "/subscription/cancel-motif", method: HttpMethod.PUT })
    // <Edition subscription>
    static editSubscriptionRessource = new ResourceConfig({ urlSuffix: "/subscription/edit", method: HttpMethod.PUT })
    /* :::::: PROJECT ::::: */
    // <Generation d'une nouvelle soubscription de type project>
    static projectMonoGenerateResource = new ResourceConfig({ urlSuffix: "/project/mono/generate", method: HttpMethod.POST })
    static projectFlotteGenerateResource = new ResourceConfig({ urlSuffix: "/project/flotte/generate", method: HttpMethod.POST })
    // <Duplication project>
    static projectDuplicateRessource = new ResourceConfig({ urlSuffix: "/project/duplicate/:ref", method: HttpMethod.POST })
    // <Liste des soubscriptions project d'une categorie
    static projectsResource = new ResourceConfig({ urlSuffix: "/projects/:productRef", method: HttpMethod.GET, resourceOptions: (new ResourceOptions({ 'productRef': PRODUCT_AUTO_REF }, { 'page': DEFAULT_TABLE_MIN_PAGE, 'size': DEFAULT_TABLE_MAX_SIZE })) })
    // <Suppression d'un projet>
    static deleteProjectResource = new ResourceConfig({ urlSuffix: "/project", method: HttpMethod.DELETE, })
    
    // REPORT
    static generateListeVRepportingDocResource = new ResourceConfig({ urlSuffix: "/document/rapport/liste-des-vehicules", method: HttpMethod.GET, resourceOptions: (new ResourceOptions({}, { 'productRef': PRODUCT_AUTO_REF })) })
    static generateBordereauEmRepportingDocResource = new ResourceConfig({ urlSuffix: "/document/rapport/bordereau-emission", method: HttpMethod.GET, resourceOptions: (new ResourceOptions({}, { 'productRef': PRODUCT_AUTO_REF })) })
    static repportingResource = new ResourceConfig({ urlSuffix: "/report/subscription", method: HttpMethod.GET, resourceOptions: (new ResourceOptions({}, { 'page': DEFAULT_TABLE_MIN_PAGE, 'size': DEFAULT_TABLE_MAX_SIZE })) })
    static repportingEmissionBordereauResource = new ResourceConfig({ urlSuffix: "/report/bordereau-des-emissions", method: HttpMethod.GET, resourceOptions: (new ResourceOptions({}, { 'page': DEFAULT_TABLE_MIN_PAGE, 'size': DEFAULT_TABLE_MAX_SIZE })) })
    static repportingBordereauExpirationResource = new ResourceConfig({ urlSuffix: "/report/bordereau-des-expirations", method: HttpMethod.GET, resourceOptions: (new ResourceOptions({}, { 'page': DEFAULT_TABLE_MIN_PAGE, 'size': DEFAULT_TABLE_MAX_SIZE })) })
    static generateExpirationRepportingDocResource = new ResourceConfig({ urlSuffix: "/document/rapport/bordereau-expiration", method: HttpMethod.GET, resourceOptions: (new ResourceOptions({}, { 'productRef': PRODUCT_AUTO_REF })) })
    // CONTRACT
    static assureContractsResource = new ResourceConfig({ urlSuffix: "/contracts/assure", method: HttpMethod.GET, })

    // ATTESTATION
    static generateAttestationResource = new ResourceConfig({ urlSuffix: "/document/subscription/:contractDeatilID/generate/attestation/:attestationNumber", method: HttpMethod.GET, stripTrailingSlashes: false, isList: true, invalidateCache: true, })

    // GROUPEMENT
    static groupementTypeResource = new ResourceConfig({ urlSuffix: "/groupement/type/all", method: HttpMethod.GET, })
    static groupementByTypeResource = new ResourceConfig({ urlSuffix: "/groupement/:groupementTypeRef", method: HttpMethod.GET, })
    static createGroupementResource = new ResourceConfig({ urlSuffix: "/groupement/register", method: HttpMethod.POST, })
    static editGroupementResource = new ResourceConfig({ urlSuffix: "/groupement/edit", method: HttpMethod.POST, })

    // CATEGORY
    static allcategoryResource = new ResourceConfig({ urlSuffix: "/categories", method: HttpMethod.GET })
    static createCategoryResource = new ResourceConfig({ urlSuffix: "/category", method: HttpMethod.POST, })
    static editCategoryResource = new ResourceConfig({ urlSuffix: "/category", method: HttpMethod.PUT })
    static categoryByCodeResource = new ResourceConfig({ urlSuffix: "/category", method: HttpMethod.GET })
    static categoryPacksResource = new ResourceConfig({ urlSuffix: "/category/:id/packs", method: HttpMethod.GET })
    static categoryPacksGuarantiesResource = new ResourceConfig({ urlSuffix: "/category/:id/packs/guaranties", method: HttpMethod.GET })
    static categoryAttachPackResource = new ResourceConfig({ urlSuffix: "/category/:categoryRef/pack/:packRef", method: HttpMethod.POST })
    static categoryDeletePackResource = new ResourceConfig({ urlSuffix: "/category/:categoryRef/pack/:packRef", method: HttpMethod.DELETE })
    static toggleCategoryPackResource = new ResourceConfig({ urlSuffix: "/toggle/category/:categoryRef/pack/:packRef", method: HttpMethod.PUT })
    static attachCategoryPackGuaranteeResource = new ResourceConfig({ urlSuffix: "/category/:categoryRef/pack/:packRef/guarantee/:guaranteeRef", method: HttpMethod.POST })
    static modifyCategoryPackGuaranteeResource = new ResourceConfig({ urlSuffix: "/category/:categoryRef/pack/:packRef/guarantee/:guaranteeRef", method: HttpMethod.PUT })
    static deleteCategoryPackGuaranteeResource = new ResourceConfig({ urlSuffix: "/category/pack/guaranteeLine/:guaranteeLineId", method: HttpMethod.DELETE })
    static toggleCategoryPackcGuarantiesResource = new ResourceConfig({ urlSuffix: "/category/:categoryRef/pack/:packRef/guarantee/:guaranteeRef/status", method: HttpMethod.PUT })
    static deleteGuaranteeCriterionResource = new ResourceConfig({ urlSuffix: "/category/:categoryRef/pack/:packRef/guarantee/:guaranteeRef/criterion/:criterionId", method: HttpMethod.DELETE })

    // CO-ASSUREUR
    static createCoInsurancesResource = new ResourceConfig({ urlSuffix: "/coinsurance", method: HttpMethod.POST })
    static editCoInsurancesResource = new ResourceConfig({ urlSuffix: "/coinsurance", method: HttpMethod.PUT })
    static allCoInsuranceByContractDetailIDResource = new ResourceConfig({ urlSuffix: "/coinsurance/repartition/contract/:contractDetailID", method: HttpMethod.GET })
    static allCoInsurancesResource = new ResourceConfig({ urlSuffix: "/coinsurances", method: HttpMethod.GET })
    static applyRepartitionResource = new ResourceConfig({ urlSuffix: "/coinsurance/apply-partition", method: HttpMethod.POST })

    // AVENANT
    static initAvenantResource = new ResourceConfig({ urlSuffix: "/avenant/init", method: HttpMethod.POST })
    static avenantDefaultResource = new ResourceConfig({ urlSuffix: "/avenant/default", method: HttpMethod.GET })
    static readAllAvenantsResource = new ResourceConfig({ urlSuffix: "/avenants/actif/:contractId", method: HttpMethod.GET })

    // QUOTATION MONO
    static quotationPackResource = new ResourceConfig({ urlSuffix: "/quotation/generate", method: HttpMethod.POST })
    // QUOTATION FLOTTE
    static flotteQuotationPackResource = new ResourceConfig({ urlSuffix: "/quotation/flotte/generate", method: HttpMethod.POST })
    static v1QuotationPackResource = new ResourceConfig({ urlSuffix: "/quotation/v1/generate", method: HttpMethod.POST })

    // TRANSACTION
    static notifytransactionResource = new ResourceConfig({ urlSuffix: "/transactions/notify/:transactionId", method: HttpMethod.GET })

    // CITY / AREA
    static cityResource = new ResourceConfig({ urlSuffix: "/cities", method: HttpMethod.GET, })
    static areasResource = new ResourceConfig({ urlSuffix: "/areas", method: HttpMethod.GET, })

    // ENERGY
    static energyResource = new ResourceConfig({ urlSuffix: "/energies", method: HttpMethod.GET, })

    // POSITIONS
    static positionResource = new ResourceConfig({ urlSuffix: "/positions", method: HttpMethod.GET, })

    // TRANSPORT
    static transportResource = new ResourceConfig({ urlSuffix: "/transport/:categoryRef", method: HttpMethod.GET, })

    // SEAT 
    static seatResource = new ResourceConfig({ urlSuffix: "/seat/:categoryRef", method: HttpMethod.GET, })

    // PERIOD
    static periodResource = new ResourceConfig({ urlSuffix: "/periods", method: HttpMethod.GET })
    static getPeriodEndDateResource = new ResourceConfig({ urlSuffix: "/period/enddate", method: HttpMethod.POST })

    // GROUPEMENT
    static groupementResource = new ResourceConfig({ urlSuffix: "/groupement/all", method: HttpMethod.GET, })

    // BRAND
    static brandResource = new ResourceConfig({ urlSuffix: "/brands", method: HttpMethod.GET, })
    static createBrandResource = new ResourceConfig({ urlSuffix: "/brand", method: HttpMethod.POST, })
    static editBrandResource = new ResourceConfig({ urlSuffix: "/brand", method: HttpMethod.PUT, })
    static toogleBrandResource = new ResourceConfig({ urlSuffix: "/brand/toggle/:id", method: HttpMethod.PUT })

    // MODEL
    static modelsResource = new ResourceConfig({ urlSuffix: "/models", method: HttpMethod.GET })
    static createModelResource = new ResourceConfig({ urlSuffix: "/model", method: HttpMethod.POST, })
    static editModelResource = new ResourceConfig({ urlSuffix: "/model", method: HttpMethod.PUT })
    static toggleStatusModelResource = new ResourceConfig({ urlSuffix: "/model/toggle/:id", method: HttpMethod.PUT, })

    // PACKAGES
    static packageResource = new ResourceConfig({ urlSuffix: "/packs", method: HttpMethod.GET })
    static createPackageResource = new ResourceConfig({ urlSuffix: "/pack", method: HttpMethod.POST, })
    static editPackageResource = new ResourceConfig({ urlSuffix: "/pack", method: HttpMethod.PUT, })
    static toggleStatusPackageResource = new ResourceConfig({ urlSuffix: "/pack/toggle/:id", method: HttpMethod.PUT, })
    static copyPackGuaranteeResource = new ResourceConfig({ urlSuffix: "/pack/guarantee/copy/:categoryId/:sourcePackId/:targetPackId", method: HttpMethod.POST })
    static packLineResource = new ResourceConfig({ urlSuffix: "/packLines", method: HttpMethod.GET })
    
    // EXTRA
    static extrasResource = new ResourceConfig({ urlSuffix: "/extras", method: HttpMethod.GET })
    static toggleStatusExtraResource = new ResourceConfig({ urlSuffix: "/extra/toggle/:id", method: HttpMethod.PUT })

    // REDUCTION GROUP
    static reductionsGroupResource = new ResourceConfig({ urlSuffix: "/group/reductions", method: HttpMethod.GET })
    // REDUCTION
    static reductionResource = new ResourceConfig({ urlSuffix: "/reductions", method: HttpMethod.GET, })
    static createReductionResource = new ResourceConfig({ urlSuffix: "/reduction/register", method: HttpMethod.POST, })
    static editReductionResource = new ResourceConfig({ urlSuffix: "/reduction/edit", method: HttpMethod.POST })

    // GROUPE REDUCTION
    static groupeReductionResource = new ResourceConfig({ urlSuffix: "/groupe-reduction", method: HttpMethod.GET, })
    static createGroupeReductionResource = new ResourceConfig({ urlSuffix: "/groupe-reduction/register", method: HttpMethod.POST, })
    static editGroupeReductionResource = new ResourceConfig({ urlSuffix: "/groupe-reduction/edit", method: HttpMethod.POST })

    // IDENTITY CARD
    static identityCardResource = new ResourceConfig({ urlSuffix: "/identity-card/all", method: HttpMethod.GET, })

    static openDocumentFileResource = new ResourceConfig({ urlSuffix: "/files/get/:documentType/:filename", method: HttpMethod.GET, stripTrailingSlashes: false, isList: true, invalidateCache: true, authenticated: false, })

    // VOITURE
    static verifyMatriculationResource = new ResourceConfig({ urlSuffix: "/vehicle/verify", method: HttpMethod.GET, stripTrailingSlashes: false, isList: true, invalidateCache: true, authenticated: false, })

    // GUARANTIES
    static guaranteesResource = new ResourceConfig({ urlSuffix: "/guaranties", method: HttpMethod.GET, })
    static createGuaranteeResource = new ResourceConfig({ urlSuffix: "/guarantee", method: HttpMethod.POST, })
    static editGuaranteeResource = new ResourceConfig({ urlSuffix: "/guarantee", method: HttpMethod.PUT, })
    static toggleStatusGuaranteeResource = new ResourceConfig({ urlSuffix: "/guarantee/toggle/:id", method: HttpMethod.PUT, })
    static guaranteeLineResource = new ResourceConfig({ urlSuffix: "/guarantee/line", method: HttpMethod.GET })
    static freePackGuaranteeLineResource = new ResourceConfig({ urlSuffix: "/guarantee/line/pack/carte", method: HttpMethod.GET })
    
    // GURANTIES ENGAGEMENTS
    static engagementsResource = new ResourceConfig({ urlSuffix: "/engagements", method: HttpMethod.GET, })

    // SOCIAL STATUS
    static socialStatusResource = new ResourceConfig({ urlSuffix: "/socialStatus", method: HttpMethod.GET, })

    // ASSURE CLASS
    static assureClassResource = new ResourceConfig({ urlSuffix: "/socialClasses", method: HttpMethod.GET, })

    // FISCAL POWER
    static fiscalPowerByCategoryCodeResource = new ResourceConfig({ urlSuffix: "/fiscalpowers/category/:categoryCode", method: HttpMethod.GET, })

    // UPLOAD ATTESTATION
    static uploadAttestationResource = new ResourceConfig({ urlSuffix: "/files/excel/upload", method: HttpMethod.POST, })

    // UPLOAD ATTESTATION
    static searchPartnerResource = new ResourceConfig({ urlSuffix: "/partner/:partnerCode", method: HttpMethod.GET, })

    // SERIAL
    static getSeriesResource = new ResourceConfig({ urlSuffix: "/series", method: HttpMethod.GET })
    static saveSerialResource = new ResourceConfig({ urlSuffix: "/serial", method: HttpMethod.POST })
    static getSerialLinesResource = new ResourceConfig({ urlSuffix: "/serial/details/:id", method: HttpMethod.GET })
    static getSerialNatureResource = new ResourceConfig({ urlSuffix: "/serial/natures", method: HttpMethod.GET })
    static dissociateSerialResource = new ResourceConfig({ urlSuffix: "/serial/dissociate", method: HttpMethod.PUT })
}