export class SubscriptionWidget {
    static LIST_SUBSCRIPTION    = "LIST_SUBSCRIPTION";
    static SUBSCRIPTION         = "SUBSCRIPTION";
    static PROJECT              = "PROJECT";
}

export class SubscriptionType {
    static AVENANT  = "AVENANT";
    static PROJECT  = "PROJECT";
    static NORMAL   = "NORMAL";
}

export class ContractType {
    static MONO     = "MONO";
    static FLOTTE   = "FLOTTE";
}