import { UserOutlined } from "@ant-design/icons";
import { DatePicker, Card, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { CardHeader, Col, Label, Row, Spinner } from "reactstrap";
import TableTransfer from "views/pages/subscription/common/TableTransfer";
import * as helpers from 'helpers/helpers';
import QuotationService from "services/quotationService";
import SubscriptionRequestBuilder from "api/request/SubscriptionRequestBuilder";
import { useBasket } from "views/pages/subscription/hooks/useBasket";
import HeaderPrime from "views/pages/subscription/common/HeaderPrime";
import QuotationResponse from "api/v1/response/QuotationResponse";
import { toast } from "react-toastify";
import BackNextWidget from "../../../common/BackNextWidget";
import moment from "moment";

const FStepChoiseVehicle=({baskets, fSubscriptionRequest, startDate, endDate})=> {
    const {updateGroup, goToNextStep} = useBasket();
    const [targetKeys, setTargetKeys] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [startDateSelected, setStartDateSelected]=useState(moment(startDate))
    const [state, setState]=useState({
        primeTTC: 0,
        primeVisibility: false,
        isQuotingFleet: false
    })

    useEffect(()=> {
        setDataSource((baskets || []).map((basket, i)=> ({...basket, key: i.toString()})));
    }, [])

    const onTransferChange = (nextTargetKeys) => {
        const basketsSelected = dataSource.filter((basket)=> nextTargetKeys.includes(basket?.key));
        console.log(":::::::::::basketSelected: ", basketsSelected)
        quoteRequest(basketsSelected)
        setTargetKeys(nextTargetKeys);
    };

    const quoteRequest=(lbaskets, init=false)=> {
        setState(state=> ({...state, isQuotingFleet: true}))

        if(lbaskets?.length === 0) {
            setState(state=> ({...state, isQuotingFleet: false}))
            return;
        }

        // Build baskets quotationRequests
        const quotationRequests= []
        for(const lbasket of lbaskets) {
            quotationRequests.push({
                ...lbasket?.quotationRequest,
                calculateGuarantee: false,
                packLineRef: lbasket?.packSelected?.packLineRef
            })
        }

        // Quote now
        QuotationService.quote({quotationRequests: [...quotationRequests], t: 'f', init: init}, (result)=> {
            const _qutotationResponse = QuotationResponse.fromJson(result);

            // Met à jour la vue
            setState(state=> ({
                ...state,
                primeTTC: _qutotationResponse?.primeTTC,
                primeVisibility: true,
                isQuotingFleet: false
                
            }));

            const matsSelected = (lbaskets || []).map((basket)=> basket?.formField?.matriculation);
            const _autos = (fSubscriptionRequest.autos || []).filter((auto)=> matsSelected.includes(auto?.vehicle?.matriculation))
            console.log(":::: startDateSelected : ", startDateSelected)
            updateGroup([
                {path: ['fSubscriptionRequest'], value: {
                    ...fSubscriptionRequest, 
                    autos: [..._autos],
                    primeTTC: _qutotationResponse?.primeTTC,
                    primeNette: _qutotationResponse?.primeNette,
                    primeExtra  : _qutotationResponse?.primeExtra,
                    agencyFees  : _qutotationResponse?.agencyFees,
                    dateEffect: startDateSelected
                }},
                {path: ['quotationResponse'], value: {..._qutotationResponse}}
            ])
        }, (error)=> {
            toast.error("Echec de calcul de cotation flotte")
            setState(state=> ({...state,isQuotingFleet: false}));
        })
    }

    const onDatePickerChange=(e)=> {
        setStartDateSelected(e)
    }

    const goNextStep=()=> {
        updateGroup([
            {path: ['fSubscriptionRequest'], value: {
                ...fSubscriptionRequest,
                dateEffect: startDateSelected
            }}
        ])
        goToNextStep()
    }

    const leftTableColumns = [
        {
            dataIndex: 'id',
            title: 'id',
            render: (item,basket) => {
                return (<span>{item}</span>)
            }
        },
        {
            dataIndex: 'brandTitle',
            title: 'Marque',
            render: (item, basket) => {
                return (<span>{basket?.formField?.brandTitle}</span>)
            }
        },
        {
            dataIndex: 'modelTitle',
            title: 'Modèle',
            render: (item, basket) => {
                return (<span>{basket?.formField?.modelTitle}</span>)
            }
        },
        {
            dataIndex: 'energyTitle',
            title: 'Energie',
            render: (item, basket) => {
                return (<span>{basket?.formField?.energyTitle}</span>)
            }
        },
        {
            dataIndex: 'matriculation',
            title: 'Matricule',
            render: (item, basket) => {
                return (<span>{basket?.formField?.matriculation}</span>)
            }
        },
        {
            dataIndex: 'chassisNumber',
            title: 'Chassis',
            render: (item, basket) => {
                return (<span>{basket?.formField?.chassisNumber}</span>)
            }
        },
      ];

    const rightTableColumns = [
        {
            dataIndex: 'brandTitle',
            title: 'Marque',
            render: (item, basket) => {
                return (<span>{basket?.formField?.brandTitle}</span>)
            }
        },
        {
            dataIndex: 'modelTitle',
            title: 'Modèle',
            render: (item, basket) => {
                return (<span>{basket?.formField?.modelTitle}</span>)
            }
        },
        {
            dataIndex: 'energyTitle',
            title: 'Energie',
            render: (item, basket) => {
                return (<span>{basket?.formField?.energyTitle}</span>)
            }
        },
        {
            dataIndex: 'matriculation',
            title: 'Matricule',
            render: (item, basket) => {
                return (<span>{basket?.formField?.matriculation}</span>)
            }
        },
        {
            dataIndex: 'chassisNumber',
            title: 'Chassis',
            render: (item, basket) => {
                return (<span>{basket?.formField?.chassisNumber}</span>)
            }
        },
    ];

    return (
        <React.Fragment>
            <HeaderPrime 
                prime={state?.primeTTC} 
                visible={state?.primeVisibility} 
                isLoading={state.isQuotingFleet}
                noVisibleMsg="** Selon le vehicle choisi ***"
                 />
            <Card headStyle={{height: "55px"}} title={(<div className="d-flex align-items-start flex-row-reverse gap-3">
                        
                        <div>
                            <DatePicker 
                                style={{ width: '100%' }} 
                                size={'medium'} 
                                format={"DD/MM/YYYY"} 
                                defaultValue={moment(startDate)}
                                onChange={onDatePickerChange}
                                disabledDate={helpers.disableDateRanges({
                                    startDate: startDate, 
                                    endDate: endDate
                                })} 
                            />
                        </div>
                        <Label>Date effet : </Label>
                    </div>)}>
                
                <div>
                    <TableTransfer
                        titles={['Vehicules de la flotte', 'Véhicules selectionnés']}
                        dataSource={dataSource}
                        targetKeys={targetKeys}
                        disabled={false}
                        showSearch={true}
                        onChange={onTransferChange}
                        filterOption={(inputValue, item) =>
                            item?.vehicle?.model?.brand?.title?.toLowerCase().indexOf(inputValue) !== -1 ||
                            item?.vehicle?.model?.title?.toLowerCase()?.indexOf(inputValue) !== -1 ||
                            item?.vehicle?.matriculation?.toLowerCase()?.indexOf(inputValue) !== -1 ||
                            item?.vehicle?.chassisNumber?.toLowerCase()?.indexOf(inputValue) !== -1
                        }
                        leftColumns={leftTableColumns}
                        rightColumns={rightTableColumns}
                    />

                    <Row>
                        <Col lg={12}>
                            <BackNextWidget 
                                confirmText={"Afficher details"}
                                onConfirm={()=> goNextStep()}
                                confirmIcon={(<UserOutlined style={{ color: '#ffffff', fontSize: 20 }} />)}
                                disableConfirm={targetKeys.length < 1}
                                icon={UserOutlined}
                                />
                        </Col>
                    </Row>
                </div>
            </Card>
        </React.Fragment>
    )
}

export default FStepChoiseVehicle;