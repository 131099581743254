import React from "react";
import {  Col, Form, Select, Spin } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import { useState } from "react";
import { useEffect } from "react";
import EntitiesService from "services/entitiesService";

const EntitiesField=({ disable, xs, md })=> {
    const { Option } = Select;

    const [state, setState]= useState({
        entities: [],
        isEntitiesLoading: false
    })

    useEffect(()=> {
        getAllEntities()
    }, [])

    const getAllEntities = () => {
        
        setState((state) => ({ ...state, isEntitiesLoading: true }));
        EntitiesService.readAllEntities((data) => {
            setState(state => ({ ...state, entities: data || [], isEntitiesLoading: false }))
        }, (error) => {
            setState((state) => ({ ...state, isEntitiesLoading: false }));
        });
    }
    
    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Partenaire"
                    name="entitiesCode"
                    rules={[{ required: false, message: "Champ obligatoire" }]}
                >
                    {
                        state.isEntitiesLoading ? <Spin /> :
                            (<SelectWithFilter placeholder='Code intermediaire' disabled={disable}>
                                {state.entities.map((entitie, index) => (<Option value={entitie?.code} key={index}>{`${entitie?.code}-${entitie?.title}`}</Option>))}
                            </SelectWithFilter>)
                    }

                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default EntitiesField;