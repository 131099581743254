import { Col, DatePicker, Form, Space, Spin } from "antd";
import React from "react";
import {  disabledPrevDate } from "helpers/helpers";
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/fr_FR';

const SerialDateEffectField=({disable, label, size='large', md, xs })=> {

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label={label }
                    name="startDate"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                    style={{marginBottom: '5px'}}
                >
                    <DatePicker  
                        style={{ width: '100%' }} 
                        size={size} 
                        locale={locale} 
                        format={"DD/MM/YYYY"} 
                        disabledDate={ disabledPrevDate}   
                        disabled={disable} />
                        
                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default SerialDateEffectField;