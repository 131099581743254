import React, { useEffect, useState } from 'react';
import { Card, DatePicker, Form, Input, Table } from 'antd';
import VehicleService from 'services/vehicleService';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Label, Row } from 'reactstrap';
import QuotationService from 'services/quotationService';
import HeaderPrime from 'views/pages/subscription/common/HeaderPrime';
import { toast } from 'react-toastify';
import QuotationResponse from 'api/v1/response/QuotationResponse';
import MatriculationField2 from 'views/pages/subscription/common/fields/MatriculationField2';
import _ from 'lodash';
import * as helpers from 'helpers/helpers';
import moment from 'moment';

function FAvenantChangementImmatriculation({  baskets, fSubscriptionRequest, startDate, endDate }) {
    const {updateBaskets,goToNextStep, updateGroup} = useBasket();
    const [isVehicleUpdated, setIsVehicleUpdated] = useState(false)
    const [startDateSelected, setStartDateSelected]=useState(moment(startDate))
    const [dataSource, setDataSource] = useState([])
    
    const [state, setState]=useState({
        primeTTC: 0,
        primeVisibility: true,
        quoting: false
    })

    useEffect(()=> {
        const _baskets = [];
        for(const basket of baskets) {
            _baskets.push({...basket, editable: false, edited: false}) 
        }
        updateBaskets(_baskets); 
    }, []);

    useEffect(()=> {
        setDataSource(baskets) 
        const _basketsEdited = baskets.filter((basket)=> basket?.edited === true)
        setIsVehicleUpdated(_.size(_basketsEdited) > 0) 

    }, [baskets]);

    const onEditMatriculation =(newMat, oldMat)=> {
        
        const _baskets = (dataSource || []).map((basket)=> {
            return basket.formField.matriculation !== oldMat ? basket : {...basket, formField: {...basket?.formField, newMatriculation: newMat}, edited: true, editable: false}
        })
        updateBaskets([..._baskets]);
        quoteRequest([..._baskets].filter((basket)=> basket?.edited === true), false)
    }

    const activeEdit=(basket)=> {
       
        const _baskets = dataSource.map((item)=> {
            return {
                ...item,
                editable: item?.id === basket?.id
            }
        }) 
        console.log("!!!!!!!!!!_baskets :", _baskets)
        setDataSource([..._baskets])  
    }

    const confirmMatriculationEdition=()=> {
        
        updateGroup([
            {path: ['fSubscriptionRequest'], value: {
                ...fSubscriptionRequest, 
                customerStartDate: startDateSelected,
                dateEffect: startDateSelected
            }}
        ])
        goToNextStep() 
    }

    const quoteRequest=(lbaskets, init=false)=> {
        setState(state=> ({...state, isQuotingFleet: true}))

        if(lbaskets?.length === 0) {
            setState(state=> ({...state, isQuotingFleet: false}))
            return;
        }

        // Build baskets quotationRequests
        const quotationRequests= []
        for(const lbasket of lbaskets) {
            quotationRequests.push({
                ...lbasket?.quotationRequest,
                calculateGuarantee: false,
                packLineRef: lbasket?.packSelected?.packLineRef
            })
        }

        // Quote now
        QuotationService.quote({quotationRequests: [...quotationRequests], t: 'f', init: init}, (result)=> {
            const _qutotationResponse = QuotationResponse.fromJson(result);

            // Met à jour la vue
            setState(state=> ({
                ...state,
                primeTTC: _qutotationResponse?.primeTTC,
                
            }));

            const _autos = (fSubscriptionRequest?.autos || []).map((auto=> {
                const _basket = (lbaskets || []).find((b)=> b?.formField?.matriculation === auto.vehicle.matriculation)
                return _basket ? {...auto, vehicle: {...auto.vehicle, matriculation: _basket?.formField?.newMatriculation}} : auto 
            }))

            updateGroup([
                {path: ['fSubscriptionRequest'], value: {
                    ...fSubscriptionRequest, 
                    autos: [..._autos],
                    primeTTC: _qutotationResponse?.primeTTC,
                    primeNette: _qutotationResponse?.primeNette
                }},
                {path: ['quotationResponse'], value: {..._qutotationResponse}}
            ])
        }, (error)=> {
            toast.error("Echec de calcul de cotation flotte")
        })
    }

    const onDatePickerChange=(e)=> {
        setStartDateSelected(e)
    }

    const columns = [
        {
            title: 'N°',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (item, basket)=> <div>{basket?.id}</div>
        },
        {
            title: 'Cat.',
            dataIndex: 'category',
            key: 'category',
            width: 50,
            render: (item, basket)=> {
                return (
                    <div>{basket?.packSelected?.category?.code}</div>
                )
            }
        },
        {
            title: 'Marque',
            dataIndex: 'formField',
            key: 'brandTitle',
            width: 300,
            render: (formField, basket)=> <div>{formField?.brandTitle}</div>
        }, 
        {
            title: 'Modèle',
            dataIndex: 'formField',
            key: 'modelTitle',
            width: 300,
            render: (formField, index)=> <div>{formField?.modelTitle}</div>
        },
        {
            title: 'Matricule',
            dataIndex: 'formField',
            
            render: (formField, basket)=> {
                return (
                    (basket?.editable  && onEditMatriculation)  ? (
                        <MatriculationField2 
                            initialMatriculations={ dataSource.map((__basket)=> __basket?.formField?.matriculation)} 
                            onConfirm={onEditMatriculation}
                            oldMat={basket?.formField?.matriculation}
                            defaultValue={basket?.edited ? basket?.formField?.newMatriculation : basket?.formField?.matriculation}
                            />
                        
                    ) : (<div> <EditOutlined onClick={()=> activeEdit(basket)}/> {formField?.newMatriculation ?? formField?.matriculation}</div>)
                
                )
            }
        },
        {
            title: 'N° chassis',
            dataIndex: 'formField',
            key: 'chassisNumber',
            width: 300,
            render: (formField, basket)=> <div>{formField?.chassisNumber}</div>
        },
    ];

    return (
        <React.Fragment>
            <HeaderPrime prime={state?.primeTTC} visible={state?.primeVisibility} />
            <Card headStyle={{height: "55px"}} title={(<div className="d-flex align-items-start flex-row-reverse gap-3">
                        
                        <div>
                            <DatePicker 
                                style={{ width: '100%' }} 
                                size={'medium'} 
                                format={"DD/MM/YYYY"} 
                                defaultValue={moment(startDate)}
                                onChange={onDatePickerChange}
                                disabledDate={helpers.disableDateRanges({
                                    startDate: startDate, 
                                    endDate: endDate
                                })} 
                            />
                        </div>
                        <Label>Date effet : </Label>
                    </div>)}>
                {/* <FDetailsHeader primeTTC={data?.fBasket?.flotteSubscriptionRequest?.primeTTC} /> <br /> */}

                <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{ position: ["topRight"] }}
                />
                <Row>
                    {
                        dataSource?.length > 0 ? (
                            <Col lg={12}>
                                <div className="d-flex align-items-start gap-3 mt-3">
                                    <button
                                        onClick={()=> confirmMatriculationEdition()}
                                        type="button"
                                        className="btn btn-primary btn-label right ms-auto nexttab"
                                        disabled={!isVehicleUpdated}
                                    >
                                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{" "}
                                        {state.quoting ? (<LoadingOutlined style={{ color: '#ffffff', fontSize: 20 }} />) : null}
                                        CONTINUER
                                    </button>
                                </div>
                            </Col>
                        ): null
                    }
                </Row>
            </Card>
        </React.Fragment>
    )
}

export default FAvenantChangementImmatriculation;