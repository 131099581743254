import { Card, Spin } from "antd";
import ErrorWidget from "components/Widgets/ErrorWidget";
import WizardHeader from "modules/components/WizardHeader";
import React from "react";
import { StepChangeVehicle, StepDetails, StepThankYou } from "./steps";
import useInitAvenant from "../useInitAvenant";
import * as helpers from "helpers/helpers";
import {useSelector } from "react-redux";
import { useBasket } from "views/pages/subscription/hooks/useBasket";

export default function AvenantChangementVehicule() {
  const { avenant, contract, category, partnerCode } = useSelector((state) => state.subscription);
  const { currentStep, page, basket, transactionResponse, initAvenantResponse } = useSelector((state) => state.basketSlice);
  const { goToPreviousStep } = useBasket();
  const {state} = useInitAvenant()
  
  if(state?.isInitAvenantLoading) {
    return <Card><Spin /> Chargement et configuration en cours ...</Card>
  }

  if(state?.iniAvenantLoadingError) {
    return <ErrorWidget errorMessage={state?.iniAvenantLoadingErrorMessage} />;
  }
  
  function renderChildren() {
      switch (currentStep) {
          case 0:
              return <StepChangeVehicle 
                        basket={basket} 
                        startDate={initAvenantResponse?.startDate}
                        endDate={initAvenantResponse?.endDate}
                        />;

          case 1:
              
            return <StepDetails
                      avenantCode={avenant?.code}
                      endDate={initAvenantResponse?.endDate}
                      formField={basket?.formField}
                      subscriptionRequest={basket?.subscriptionRequest}
                      packSelected={basket?.packSelected}
                    />           
          case 2:
            if(page === helpers.PAGES.THANK_YOU) {
              return <StepThankYou transactionResponse={transactionResponse} />;
            }
          
            /* if(page === helpers.PAGES.PROJECT_THANK_YOU) {
              return <StepThankYouProject transactionResponse={transactionResponse} />;
            } */

            break;

          default:
              return <ErrorWidget errorMessage="Oops! la page n'existe pas" />
      }
  }

  return (
      <React.Fragment>
          <WizardHeader
              title={`Categorie ${category.code} : ${category.title}`}
              avenantTilte={`Changement de vehicule`}
              partnerCode={partnerCode}
              police={contract?.policeNumber}
              onClick={(currentStep === 2) ? goToPreviousStep : null}
              percent={currentStep * 25}
          />
          {renderChildren()}
      </React.Fragment>
  )
}