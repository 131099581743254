import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class BrandService {

    static createBrand(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.createBrandResource, payload, sCallBack, eCallBack)
    }

    editBrand(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.editBrandResource, payload, sCallBack, eCallBack);
    }

    static getAll(options, sCallBack, eCallBack) {
        ApiRoutes.brandResource.resourceOptions = Object.assign({}, ApiRoutes.brandResource.resourceOptions, options);
        exeRequest(ApiRoutes.brandResource, {}, sCallBack, eCallBack)
    }

    static toggleStatus(options, sCallBack, eCallBack) {
        ApiRoutes.toogleBrandResource.resourceOptions = Object.assign({}, ApiRoutes.toogleBrandResource.resourceOptions, options);
        exeRequest(ApiRoutes.brandResource, {}, sCallBack, eCallBack)
    }
}