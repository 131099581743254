import { Button, Drawer, Form, Select, Space, Table, Tag } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SerialService from "services/SerialService";
import SimpleInputField from "views/pages/subscription/common/fields/SimpleInputField";

const SerialDetails=({serial, setSerieDetailsDrawerVisibility})=> {
    const [form]=Form.useForm();

    const [actionSelected, setActionSelected] = useState(null);
    const [selectedItems, setSelectedItems] = useState([])

    const [state, setState] = useState({
        // Serial détail
        serialLines: [],
        isSerialLinesLoading: false,

        isDissociatingSerial: false
    });

    useEffect(()=> {
        loadSeriesDetails(serial)
    },[])

    const columns=[
        {
            title: 'N° papier',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            width: 100,
            filters: (state?.serialLines || []).map((serialLine)=> ({text: serialLine?.serialNumber, value: serialLine?.serialNumber})),
            onFilter: (value, record) => record.serialNumber?.startsWith(value),
            filterSearch: true,
        },
        {
            title: 'Statut',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            filters: [
                {
                  text: 'Non utilisé',
                  value: 'NOT_USED',
                },
                {
                  text: 'Annulé',
                  value: 'CANCELLED',
                },
                {
                    text: 'Bourrage',
                    value: 'BLOCKED',
                },
                {
                    text: 'Retour',
                    value: 'RETURNED',
                },
                {
                    text: 'Autres',
                    value: 'OTHERS',
                },
              ],
            onFilter: (value, record) => record.status?.startsWith(value),
            filterSearch: true,
            render: (_, serial)=> {
                return serial?.status === 'NOT_USED' ? (<Tag color="green">Non utilisé</Tag>) : 
                serial?.status === 'USED' ? (<Tag color="red">Utilisé</Tag>) : 
                serial?.status === 'CANCELLED' ? (<Tag color="red">Annulé</Tag>) : 
                serial?.status === 'BLOCKED' ? (<Tag color="red">Bourrage</Tag>) : 
                serial?.status === 'RETURNED' ? (<Tag color="red">Retour</Tag>) : 
                serial?.status === 'OTHERS' ? (<Tag color="red">Autres</Tag>) : "";
            }
        },

        {
            title: 'Interm',
            dataIndex: 'entitiesCode',
            key: 'entitiesCode',
            width: 100,
            filters: (_.uniqBy(state?.serialLines, "entitiesCode") || []).filter(serialLine=> serialLine?.entitiesCode !== "" && serialLine?.entitiesCode !== null)?.map((serialLine)=> ({text: serialLine?.entitiesCode, value: serialLine?.entitiesCode})),
            onFilter: (value, record) => record.entitiesCode?.startsWith(value),
            filterSearch: true,
        },
        {
            title: 'N° Police',
            dataIndex: 'police',
            key: 'police',
            width:200,
            filters: (state?.serialLines || []).filter(serialLine=> serialLine?.police !== "" && serialLine?.police !== null)?.map((serialLine)=> ({text: serialLine?.police, value: serialLine?.police})),
            onFilter: (value, record) => record.police?.startsWith(value),
            filterSearch: true,
        },
        {
            title: 'Av.',
            dataIndex: 'avenantCode',
            key: 'avenantCode',
            width: 50,
        },
        {
            title: 'Risque',
            dataIndex: 'risque',
            key: 'risque'
        },
        // {
        //     dataIndex: 'action',
        //     key: 'action',
        //     title: 'Actions',
        //     fixed: 'right',
        //     width: 100,
        //     render:(_, item)=> (<Button>Desactfecter</Button>)
        // },
    ]

    const  loadSeriesDetails=(serial)=> {
        setState(state=> ({...state, isSerialLinesLoading: true}))
        const options = {
            path: {id: serial?.id}
        }
        SerialService.getSerialDetail(options, (data)=> {
            setState(state=> ({...state, serialLines: (data || []).map((item, i)=> ({...item, key: i})), isSerialLinesLoading: false}))
        }, (error)=> {
            setState(state=> ({...state, isSerialLinesLoading: false}))
        })
    }

    const handleChange = (value) => {
        setActionSelected({value: value, description: value==='OTHERS' ? form.getFieldValue("serialOther") : ""})
        console.log(`selected ${value}`);
      };

    const onConfirmAction=()=> {
        //const 
        console.log("::: selectedItems: ", selectedItems);
        const serialLineIds = selectedItems.map((item)=> item?.id);

        const options = {
            query: {
                action: actionSelected?.value
            }
        }
        setState(state=> ({...state, isDissociatingSerial: true}))
        SerialService.dissociateSerial(options,{
            serialLineId: serialLineIds,
            motif: actionSelected?.description
        }, (data)=> {
            setState(state=> ({...state, isDissociatingSerial: false}))
            toast.success("Action réalisée avec succès")
            loadSeriesDetails(serial)
        }, (error)=> {
            setState(state=> ({...state, isDissociatingSerial: false}))
            toast.error(`Echec de validation: ${error}` )
        })
    }

    return (
        <React.Fragment>
            <Drawer
                title= {`Séries: `}
                width={800}
                onClose={() => setSerieDetailsDrawerVisibility(false)}
                open={true}
                bodyStyle={{
                    paddingBottom: 80,
                }}
            >
                
                <div>
                    <Space
                        style={{
                        marginBottom: 16,
                        }}
                    >
                        <div >Actions: </div>
                        <Select
                            // defaultValue="lucy"
                            placeholder={"Choisir une action"}
                            style={{ width: 120 }}
                            onChange={handleChange}
                            options={[
                                { value: 'CANCELLED', label: 'Annuler' },
                                { value: 'BLOCKED', label: 'Bourrage' },
                                { value: 'RETURNED', label: 'Retourné' },
                                { value: 'OTHERS', label: 'Autres'},
                            ]}
                        />
                        {actionSelected?.value === "OTHERS" && (
                            <div style={{ width:"100%" }}>
                                <Form form={form} style={{ width:"100%" }}>
                                    <SimpleInputField 
                                        label={""} 
                                        name={"serialOther"} 
                                        size="medium" 
                                        required={false} 
                                        placeholder={"Saisir le motif"}
                                        md={24}
                                        formItemStyle={{ marginBottom: 0, width: "100%" }}/>
                                </Form>
                            </div>
                        )}
                        
                        <Button onClick={onConfirmAction}>Confirmer</Button>
                    </Space>
                    <Table 
                        columns={columns} 
                        dataSource={state.serialLines} 
                        loading={state?.isSerialLinesLoading}
                        scroll={{ x: 1000 }}
                        
                        rowSelection={{
                            onChange: (selectedRowKeys, selectedRows) => {
                                //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                              },
                              onSelect: (record, selected, selectedRows) => {
                                setSelectedItems(selectedRows)
                                //console.log(record, selected, selectedRows);
                              },
                            //   onSelectAll: (selected, selectedRows, changeRows) => {
                            //     console.log(selected, selectedRows, changeRows);
                            //   },
                            checkStrictly: false
                          }}
                        />
                </div>
            </Drawer>
        </React.Fragment>
    )
}

export default SerialDetails;