export default class FormEditGroupement {
    ref;
    code;
    groupementTypeRef;
    groupementTypeTitle;
    title;
    nbVehicle;
    description;

    fromJson = (json = {}) => {
        var formEditGroupement = new FormEditGroupement();
        formEditGroupement = json
        return formEditGroupement
    }
}