import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class EntitiesService {
    static readAllEntities(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.entitiesResource, {}, sCallBack, eCallBack)
    }

    static readAllEntitiesNatures(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.entitiesNatureResource, {}, sCallBack, eCallBack)
    }

    createEntitie(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.createEntitieResource, payload, sCallBack, eCallBack)
    }

    editEntitie(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.editEntitieResource, payload, sCallBack, eCallBack)
    }

    static searchEntitie(options, sCallBack, eCallBack) {
        ApiRoutes.searchPartnerResource.resourceOptions = Object.assign({}, ApiRoutes.searchPartnerResource.resourceOptions, options);
        exeRequest(ApiRoutes.searchPartnerResource, {}, sCallBack, eCallBack)
    }
}