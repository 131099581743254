import ApiRoutes from "api/apiRoutes";
import AuthRequest from "api/request/authRequest";
import { exeRequest } from "../http/requestConfig";

export default class AuthService {
     signIn(payload = {}, sCallBack, eCallBack) {
          let authRequest = (new AuthRequest()).fromJson(payload);
          exeRequest(ApiRoutes.authResource, authRequest, sCallBack, eCallBack)
     }

     refreshToken() {

     }

     
}