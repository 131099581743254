import Entities from "./entities";

export default class User {
    id;
    code;
    name;
    username;
    email;
    phone;
    verifyPhone;
    roles = [];
    entities = new Entities();
    entitiesTitle;
    profile= "";
    status;

    fromJson = (json={})=> {
        var user = new User();
        user = json;
        return user
    }
    
}