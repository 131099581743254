import { FStepDetails, FStepThankYou, StepProrogationPeriod } from "./steps";
import React from "react";
import WizardHeader from "modules/components/WizardHeader";
import { Card, Spin } from "antd";
import ErrorWidget from "components/Widgets/ErrorWidget";
import useInitFlotteAvenant from "../useInitFlotteAvenant";
import { useSelector } from "react-redux";
import * as helpers from 'helpers/helpers';

const FAvenantProrogation = ()=> {
    const {contract, category, partnerCode} = useSelector((state) => state.subscription);
    const {
        currentStep, 
        page,
        fSubscriptionRequest, 
        quotationResponse, 
        transactionResponse,
        initAvenantResponse
    } = useSelector((state) => state.basketSlice);

    const { state } = useInitFlotteAvenant();

    if(state.avenantStatus?.loading) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if(state.avenantStatus?.error) {
        return <ErrorWidget errorMessage={state.avenantStatus?.errorMessage} />;
    }

    function renderChildren() {
        switch (currentStep) {
            case 0:
                return <StepProrogationPeriod fSubscriptionRequest={fSubscriptionRequest} periodTitle={state?.customPeriodTitle}/>;
            case 1:
                return <FStepDetails 
                            initAvenantResponse={initAvenantResponse}
                            quotationResponse={quotationResponse} 
                            fSubscriptionRequest={fSubscriptionRequest} />;
             case 2:
                if(page === helpers.FlotteWidget.THANK_YOU) {
                    return <FStepThankYou transactionResponse={transactionResponse} />
                }
                break;
            default:
                return <ErrorWidget errorMessage="Ooops ! la page n'existe pas" />;
        }
    }

    return (
        <React.Fragment>
            <WizardHeader
                title={`Categorie ${category?.code} : ${category?.title}`}
                avenantTilte={`Avenant de prorogation`}
                partnerCode={partnerCode}
                police={contract?.policeNumber}
                percent={currentStep * 50}
                />
            {renderChildren()}
        </React.Fragment>
    )
}

export default FAvenantProrogation;