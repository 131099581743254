import City from "./city.model";
import Energy from "./energy.model";
import Model from "./model.model.js";
import Transport from "./transport.model";

export default class Vehicle {
    ref;
    transport = new Transport();
    model = new Model();
    
    energy = new Energy();
    totalPlace;
    fiscalPower;
    dateMec;

    payload; // Charge utile
    inflammable = false;
    
    hasGroup;
    groupRef;
    groupName;
    groupType;
    
    hasGps;
    newValue;
    marketValue;
    matriculation;
    chassisNumber;

    hasTrailer;
    trailerModel= new Model();
    trailerMatriculation;
    trailerChassisNumber;

    city = new City();

    greyCardImg;

    isGood;
    goodWeight;

    // Driver
    driverBirthDay;
    driverLicDeliverDate;

    static fromJson = (json={})=> {
        var vehicule = new Vehicle();
        vehicule = json;
        return vehicule
    }
}