import React from "react";
import { Card, CardBody, Col } from "reactstrap";

const MDetailPeriod=({perioTitle})=> {
    return (
        <React.Fragment>
            <Col lg={4} md={6} >
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-center">
                            <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-light text-primary rounded-circle fs-3">
                                    <i className={"align-middle ri-money-dollar-circle-fill"}></i>
                                </span>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <p className="text-uppercase fw-bold fs-13 text-muted mb-1">Durée du contrat</p>
                                <h4 className=" mb-0"> {perioTitle}</h4>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default MDetailPeriod;