import React from 'react'

import { Card, Col, Divider, Input, Row, Form } from 'antd'
import { useSelector } from 'react-redux'

import * as apiRequests from "services/apiRequests"
import DescriptionItem from 'modules/components/DescriptionItem'
import WizardHeader from 'modules/components/WizardHeader'
import WizardBottomButton from 'modules/components/WizardBottomButton';
import { ValidationModal, useValidationModal } from 'modules/components/ValidationModal';
import CustomAntdForm from 'modules/components/Forms/CustomAntdForm'


const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
  }
};

const cardStyles = {
  height: 365
}

function ModifierMotDePasse() {
  const [form] = Form.useForm();

  const {
    validationModalSetVisible,
    validationModalVisible,
    validationModalHandleCancel,
    validationModalSetConfirmLoading,
    validationModalConfirmLoading
  } = useValidationModal();


  function validate() {
    validationModalSetConfirmLoading(true);

    const values = form.getFieldsValue();

    apiRequests.modifierMotDePasse(values, res => {
    }, () => {
      validationModalSetVisible(false);
      validationModalSetConfirmLoading(false);
    });
  }

  function handleSubmit() {
    form.validateFields()
      .then(values => {
        validationModalSetVisible(true)
      })
      .catch(errorInfo => { });
  }

  return (
    <Card style={cardStyles}>
      <h4 style={{ color: "#449D48" }}>Modification de mot de passe</h4>

      <Divider />

      <CustomAntdForm
        {...layout}
        name="basic"
        form={form}
      >
        <Row gutter={8}>
          <Col className="gutter-row" xs={24} md={24}>
            <Form.Item
              label="Mot de passe actuel"
              name="motDePasseActuel"
              rules={[{ required: true, message: "Champ obligatoire" }]}
            >
              <Input.Password placeholder='Votre mot de passe actuel' />
            </Form.Item>
          </Col>

          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label="Nouveau mot de passe"
              name="nouveauMotDePasse"
              rules={[{ required: true, message: "Champ obligatoire" }]}
              hasFeedback
            >
              <Input.Password placeholder='Votre nouveau mot de passe' />
            </Form.Item>
          </Col>

          <Col className="gutter-row" xs={24} md={12}>
            <Form.Item
              label="Confirmer nouveau mot de passe"
              name="confirmationNouveauMotDePasse"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Champ obligatoire"
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('nouveauMotDePasse') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject('Les deux mots de passe que vous avez saisis ne sont pas identiques!');
                  },
                }),
              ]}
            >
              <Input.Password placeholder='Confirmez votre nouveau mot de passe' />
            </Form.Item>
          </Col>
        </Row>
      </CustomAntdForm>

      <WizardBottomButton
        btn1={{
          label: 'Modifier',
          onClick: handleSubmit
        }}
      />

      {validationModalVisible && (
        <ValidationModal
          visible={validationModalVisible}
          confirmLoading={validationModalConfirmLoading}
          handleOk={validate}
          handleCancel={validationModalHandleCancel}
          title="Êtes-vous sûr de modfier votre mot de passe?"
          confirmBtnText="Oui, je modifie?"
        />
      )}
    </Card>
  )
}

function ProfilOperateur() {
  const auth = useSelector(state => state.auth);

  return (
    <Card style={cardStyles}>
      <h4 style={{ color: "#449D48" }}>Information de l'opérateur</h4>

      <Divider />

      <DescriptionItem titleWidth={150} title="Nom" content={auth?.user?.nom} />
      <DescriptionItem titleWidth={150} title="Prénoms" content={auth?.user?.prenom} />
      <DescriptionItem titleWidth={150} title="Matricule" content={auth?.user?.matricule} />
      <DescriptionItem titleWidth={150} title="Profil" content={auth.profil} />
      <DescriptionItem titleWidth={150} title="Contact" content={auth?.user?.tel} />
      <DescriptionItem titleWidth={150} title="Agence" content={auth?.user?.tel} />
    </Card>
  )
}

export default function UserProfile() {

  return (
    <div>
      <WizardHeader
        title="Profil utilisateur"
      />

      <Row gutter={8}>
        <Col sm={24} md={10}>
          <ProfilOperateur />
        </Col>

        <Col sm={24} md={14}>
          <ModifierMotDePasse />
        </Col>
      </Row>
    </div>
  )
}
