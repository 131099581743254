import React, { useState } from 'react'
import { Row, Col, Button, Card, Form, Checkbox, Alert} from 'antd'
import CustomAntdForm from 'modules/components/Forms/CustomAntdForm';
import { FileDoneOutlined, LeftOutlined } from '@ant-design/icons';
import { AssureCodeField, AssureFirstNameField, AssureLastNameField, AssureSearchField, BrandField, CardIDField, CardNumberField, ChassisNumberField, CustomerBrandField, CustomerModelField, DateEffectField, EmailField, MatriculationField, ModelField, PhoneField } from 'views/pages/subscription/common/fields';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';
import TrailerBrandField from 'views/pages/subscription/common/fields/TrailerBrandField';
import TrailerCustomerBrandField from 'views/pages/subscription/common/fields/TrailerCustomerBrandField';
import TrailerMatriculationField from 'views/pages/subscription/common/fields/TrailerMatriculationField';
import TrailerChassisNumberField from 'views/pages/subscription/common/fields/TrailerChassisNumberField';
import { CATEGORY_403 } from 'constantes';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import AmountHeader from 'views/pages/subscription/common/AmounHeader';

const StepAssureContract = ({basket}) => {
    const { category, contract, avenant } = useSelector((state) => state.subscription);
    const {updateFormField, goToPreviousStep, goToNextStep, updateSusbscriptionRequest} = useBasket()
    const [form] = Form.useForm();
    const [matriculationExist, setMatriculationExist] = useState(true)
    const [defaultDisableField, setDefaultDisableField] = useState(true);
    const [customerBrand, setCustomerBrand] = useState(basket?.formField?.customerBrand);
    const [trailerCustomerBrand, setTrailerCustomerBrand] = useState(basket?.formField?.trailerCustomerModel);
    const [toogleTrailer, setToogleTrailer] = useState(basket?.formField?.hasTrailer)
    const [submitError, setSubmitError] = useState(false)

    useEffect(()=> {
        if(basket?.formField?.modelRef == null && basket?.formField?.modelTitle) {
            setCustomerBrand(true)
        }
        if(basket?.formField?.trailerModelRef == null && basket?.formField?.trailerModelTitle) {
            setTrailerCustomerBrand(true)
        }
        if(basket?.formField.startDate) {
            setDefaultDisableField(false)
            setMatriculationExist(false)
        }
    }, [])

    const isMatriculeExist=(exist)=> {
        setDefaultDisableField(exist)
        setMatriculationExist(exist)
    }

    const handleSubmit = () => {
        setSubmitError(false)
        form.validateFields()
            .then(values => {
                // ========== CARTE GRISE ===========
                /* let formData = new FormData();
                formData.append('file', (values.carteGrise));
                values.file = formData; */
                // ==================================
                console.log("values : ", values);

                updateFormField({...values});
                updateSusbscriptionRequest({ 
                    isProrate: false
                })
                goToNextStep(null)
            })
            .catch(errorInfo => {
                setSubmitError(true)
                console.log("errorInfo", errorInfo);
            });
    }

    const toggleTrailerCheckBox=(e)=> {
        const checked = e.target.checked; 
        updateFormField({hasTrailer: checked})
        setToogleTrailer(checked);

        if(!checked) {
            /* updateFormField({
                hasTrailer: false,
                groupementRef: null,
                groupementTitle: ""
            }); */
        }
    }

    const goBack=()=> {
        const values = form.getFieldsValue();
        updateFormField({
            //customerBrand: values?.brandRef == null,
            brandTitle: values?.brandTitle,
            modelTitle: values?.modelTitle,
            chassisNumber: values?.chassisNumber,

            //trailerCustomerBrand: values?.trailerBrandRef == null,
            trailerBrandTitle: values?.trailerBrandTitle,
            trailerModelTitle: values?.trailerModelTitle,
            trailerMatriculation: values?.trailerMatriculation,
            trailerChassisNumber: values?.trailerChassisNumber

        })

        goToPreviousStep(null)

        console.log("::::: values :", values)
        // form.validateFields().then((values)=> {
        //     // updateFormField({
        //     //     //customerBrand: values?.brandRef == null,
        //     //     brandTitle: values?.brandTitle,
        //     //     modelTitle: values?.modelTitle,
        //     //     chassisNumber: values?.chassisNumber,

        //     //     //trailerCustomerBrand: values?.trailerBrandRef == null,
        //     //     trailerBrandTitle: values?.trailerBrandTitle,
        //     //     trailerModelTitle: values?.trailerModelTitle,
        //     //     trailerChassisNumber: values?.trailerChassisNumber

        //     // })
        //     goToPreviousStep([{path: ['basket', 'formField'], value: {
        //         ...basket?.formField,
        //         //customerBrand: values?.brandRef == null,
        //         brandTitle: values?.brandTitle,
        //         modelTitle: values?.modelTitle,
        //         chassisNumber: values?.chassisNumber,
    
        //         //trailerCustomerBrand: values?.trailerBrandRef == null,
        //         trailerBrandTitle: values?.trailerBrandTitle,
        //         trailerModelTitle: values?.trailerModelTitle,
        //         trailerChassisNumber: values?.trailerChassisNumber
        //     }}])
        //     console.log("::::: values :" , values)
        // })

        // updateFormField({
            //     //customerBrand: values?.brandRef == null,
            //     brandTitle: values?.brandTitle,
            //     modelTitle: values?.modelTitle,
            //     chassisNumber: values?.chassisNumber,

            //     //trailerCustomerBrand: values?.trailerBrandRef == null,
            //     trailerBrandTitle: values?.trailerBrandTitle,
            //     trailerModelTitle: values?.trailerModelTitle,
            //     trailerChassisNumber: values?.trailerChassisNumber

            // })
        
    }

    return (
        <React.Fragment>
            <AmountHeader packSelected={basket?.packSelected}/>
            <CustomAntdForm form={form} initialValues={basket?.formField}>
                
                <Card title={<div style={{ height: 30, width: '100%', marginTop: -10 }}>
                    <h4 style={{ color: 'grey' }}>Autres informations sur le véhicule</h4>
                    </div>} headStyle={{ backgroundColor: '#e9e9e9', color: '#ffffff', height: 20 }}>
                    <Row gutter={[8, 8]}>
                        {!customerBrand ? 
                            (<BrandField categoryCode={category?.code} handlerFormField={updateFormField} setCustomerBrand={setCustomerBrand}/>): 
                            <CustomerBrandField categoryCode={category?.code} handlerFormField={updateFormField} setCustomerBrand={setCustomerBrand}/>
                        }
                        {/* <BrandAndModelField form={form} categoryCode={category?.code} handlerFormField={updateFormField} setCustomerBrand={setCustomerBrand} /> */}

                        { !customerBrand ? 
                            (<ModelField 
                                categoryCode={category?.code}
                                brandRef={basket?.formField?.brandRef} 
                                handlerFormField={updateFormField}/>):
                            <CustomerModelField categoryCode={category?.code} />  
                        }
                        <MatriculationField handlerFormField={updateFormField} setMatriculationExist={isMatriculeExist}/>
                        <ChassisNumberField categoryCode={category?.code}/>
                    </Row>
                    {
                        [CATEGORY_403].includes(category?.code) ? (
                            <>
                                <Row>
                                    <Col className="gutter-row" xs={12} md={6}>
                                        <Checkbox defaultChecked={toogleTrailer} onChange={(e) => toggleTrailerCheckBox(e)} disabled={false} >Disposez-vous d'une remorque ?</Checkbox>
                                    </Col>
                                </Row> 
                                <br/>
                                {toogleTrailer && (
                                    <Row gutter={[8, 8]}>
                                        {!trailerCustomerBrand ? 
                                                <TrailerBrandField categoryCode={category?.code} trailerBrandRef={basket?.formField?.trailerBrandRef} handlerFormField={updateFormField} setCustomerBrand={setTrailerCustomerBrand}/>: 
                                                <TrailerCustomerBrandField categoryCode={category?.code} handlerFormField={updateFormField} setTrailerCustomerBrand={setTrailerCustomerBrand}/>
                                        }

                                        {/* {!trailerCustomerBrand ? 
                                                <TrailerModelField categoryCode={category?.code} brandRef={basket?.formField?.brandRef} handlerFormField={updateFormField}/>:
                                                <TrailerCustomerModelField categoryCode={category?.code}/>
                                        } */}
                                        <TrailerMatriculationField categoryCode={category?.code} handlerFormField={updateFormField} setMatriculationExist={isMatriculeExist} />
                                        <TrailerChassisNumberField categoryCode={category?.code}/>
                                    </Row>
                                )}   
                            </>
                        ) : null
                    }
                </Card>

                <Card title={<div style={{ height: 30, width: '100%', marginTop: -10 }}>
                                <h4 style={{ color: 'grey' }}>Détails sur votre contrat</h4>
                            </div>} 
                            headStyle={{ backgroundColor: '#e9e9e9', color: '#ffffff', height: 20, boxShadow: "0px 0.5px 0.5px gray", }}>
                    <Row gutter={[8, 8]}>
                        <Col className="gutter-row" xs={12} md={6}>
                            <DateEffectField 
                                disable={matriculationExist || defaultDisableField}
                                avenantCode={avenant?.code}
                                contractRef={contract?.ref}
                                formField={basket?.formField}
                                  />
                        </Col>
                    </Row>
                </Card>
                
                <div style={{display: 'flex'}}>
                    <Card title={<div className='d-flex' style={{ height: 30, width: '100%', marginTop: -10, display: "flex" }}>
                                    <div><h4 style={{ color: 'grey' }}>Informations sur l'assuré </h4></div>
                                    <div className='ms-auto'>
                                        <AssureSearchField form={form} handlerFormField={updateFormField} newBtnVisibility={true}/>
                                    </div>
                                </div>} 
                            headStyle={{ backgroundColor: '#e9e9e9', color: '#ffffff', height: 15 }}>
                    
                        <Row gutter={[8, 8]}>
                            <Col className="gutter-row" xs={12} md={6}>
                                <AssureFirstNameField disable={defaultDisableField || basket?.formField?.isProspect==false} />
                            </Col>

                            <Col className="gutter-row" xs={12} md={6}>
                                <AssureLastNameField disable={defaultDisableField || basket?.formField?.isProspect==false} />
                            </Col>

                            <Col className="gutter-row" xs={12} md={6}>
                                <PhoneField disable={defaultDisableField || basket?.formField?.isProspect==false} />
                            </Col>

                            <Col className="gutter-row" xs={12} md={6}>
                                <CardIDField handlerFormField={updateFormField} disable={defaultDisableField || basket?.formField?.isProspect==false} />
                            </Col>

                            <Col className="gutter-row" xs={12} md={6}>
                                <CardNumberField disable={defaultDisableField || basket?.formField?.isProspect==false} />
                            </Col>
                            <Col className="gutter-row" xs={12} md={6}>
                                <EmailField disable={defaultDisableField || basket?.formField?.isProspect==false}/>
                            </Col>
                            <Col className="gutter-row" xs={12} md={6} style={{ visibility: "hidden" }}>
                                <AssureCodeField disable={defaultDisableField || basket?.formField?.isProspect==false}/>
                            </Col>
                        </Row>
                        
                    </Card>
                    {/* <Card title={<div className='d-flex' style={{ height: 30, width: '100%', marginTop: -10, display: "flex" }}>
                                    <div><h4 style={{ color: 'grey' }}>Informations sur l'apporteur </h4></div>
                                    <div className='ms-auto'><MerchantSearchField form={form} handlerFormField={updateFormField} /></div>
                                </div>}
                        headStyle={{ backgroundColor: '#e9e9e9', color: '#ffffff', height: 15 }}>
                    
                        <Row gutter={[8, 8]}>
                            <Col className="gutter-row" xs={12} md={12}>
                                <MerchantFirstNameField disable={matriculationExist} />
                            </Col>

                            <Col className="gutter-row" xs={12} md={12}>
                                <MerchantLastNameField disable={matriculationExist} />
                            </Col>

                            <Col className="gutter-row" xs={12} md={12}>
                            <MerchantPhoneField disable={matriculationExist} />
                            </Col>
                            <Col className="gutter-row" xs={12} md={12}>
                                <MerchantEmailField disable={matriculationExist}/>
                            </Col>
                            <Col className="gutter-row" xs={12} md={12} style={{ visibility: "hidden" }}>
                                <MerchantCodeField disable={matriculationExist}/>
                            </Col>
                        </Row>
                        
                    </Card> */}
                </div>
                

                {/* <Card
                    title={<div style={{ height: 30, width: '100%', marginTop: -10 }}>
                                <h4 style={{ color: 'grey' }}>Identification du vehicule</h4>
                            </div>}
                    headStyle={{ backgroundColor: '#e9e9e9', color: '#ffffff', height: 15, boxShadow: "0px 0.5px 0.5px gray", }}
                >
                    <Row gutter={[8, 8]}>
                        <Col className="gutter-row" xs={12} md={6}>
                            <GreyCardField />
                        </Col>
                    </Row>
                </Card> */}
                {
                    submitError && (<Alert message={"Des erreurs ont été detectée dans le formulaire, veuillez les corriger"} type="error"/>)
                }
                <Card >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            style={{ color: '#005680', border: '1px solid #005680', borderRadius: 8, marginRight: 10, marginTop: 21, marginBottom: 20 }}
                            onClick={()=> goBack()}
                        >
                            <LeftOutlined style={{ color: '#005680' }} /> Retour
                        </Button>

                        <Button
                            style={{ color: '#ffffff', borderRadius: 8, backgroundColor: '#005680', marginRight: 10, marginTop: 21, marginBottom: 20, border: '1px solid #005680' }}
                            onClick={handleSubmit}
                            disabled={matriculationExist}
                        >
                            <FileDoneOutlined style={{ color: '#ffffff', fontSize: 20 }} /> PASSER AU DETAILS DU CONTRAT
                        </Button>
                    </div>
                </Card>

            </CustomAntdForm>
        </React.Fragment>
    )
}

export default StepAssureContract;
