import { Form } from "antd";
import Assistance from "api/model/assistance.model";
import Assure from "api/model/assure.model";
import Avenant from "api/model/avenant.model";
import Category from "api/model/category.model";
import Contract from "api/model/contract.model";
import ContractDetail from "api/model/contractDeatil.model";
import Period from "api/model/period.model";
import Product from "api/model/product.model";
import Vehicle from "api/model/vehicle.model";
import { NavigationPush } from "api/routeManager";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AvenantService from "services/avenantService";
import CategoryService from "services/categoryService";
import ContractService from "services/contractService";
import SubscriptionHeaderContext from "../component/SubscriptionHeaderContext";
import * as constants from 'constantes';
import { useDispatch, useSelector } from "react-redux";
import { sUpdateState } from "redux/slices/subscriptionSlice";
import { SubscriptionWidget } from "../component/ComponentWidget";
import _ from "lodash";

export default function useDetailsSubscription() {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const history = useHistory();
    const params = useParams();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        souscription: {},
        isLoad: false,
        isOpen: false,

        subscriptions: [],
        subscriptionSelected: null,
        isSubscriptionsLoading: true,
        baseSubscription: new ContractDetail(),

        product: new Product(),

        // CATEGORY
        category: new Category(),
        isCategoryLoading: false,

        // ASSURE
        assure: new Assure(),

        reductionGroups: [],
        guaranties: [],
        extras: [],
        assistances: [],
        period: new Period(),

        //AVENANT
        avenants: [],
        isAvenantLoading: true,
        avenantSelected: new Avenant(),
        avenantSelectedRef: null,

        vehicles: [],

        matriculeLoad: false,

        isAttestationLoading: false,
        attestationGenerateError: '',
        newPeriod: {},
        maxSouscription: {},

        // ATTESTATION
        isAttestationModalVisible: false,
        // AVENANT
        isAvenantModalVisible: false,
        // DETAIL CONTRACT
        drawerContractDetailVisibility: false,
        visible: false,

        isWaintg: false,

        // Paiement status
        togglePaiementStatusActivating: false 
    });

    useEffect(() => {
        readAssureSubscriptions();
    }, []);

    const waiting = () => {
        setTimeout(() => {
            setState(state => ({ ...state, isWaintg: false }))
        }, 2000)
    }

    const readAssureSubscriptions = () => {
        setState(state => ({
            ...state,
            isSubscriptionsLoading: true
        }))
        const options = {
            path: {
                contractID: parseInt(params.contratId),
                assureCode: params.assureCode.toString()
            }
        };

        ContractService.readAllContractDetailsByAssure(options, (data) => {
            
            const avenants = (data?.avenants || []).sort(((a,b)=> b.id - a.id));
            const baseSubscription = data?.base;

            setState(state => ({
                ...state,
                newPeriod: baseSubscription?.period, //_currentSubscription?.period,
                period: baseSubscription?.period,//_currentSubscription?.period, // Use only for avenant de prorogation
                subscriptions: avenants,
                baseSubscription: baseSubscription,
                isSubscriptionsLoading: false
            }))

        }, (error) => {
            toast.error("Erreur de chargement")
            setState(state => ({
                ...state,
                isSubscriptionsLoading: false
            }))
        });
    }

    const loadAvenants = () => {
        setState(state => ({ ...state, isAvenantLoading: true, isCategoryLoading: true }));
        const options = {
            path: {
                contractId: state.baseSubscription?.contract?.id
            }
        };
        (new AvenantService()).readAllAvenants(options, (data) => {

            const avenants = data || [];

            let _avenants = [];

            for (const avenant of avenants) {
                if (avenant?.code === constants.CODE_AVENANTS.SUSPENSION) {
                    _avenants = [avenant];
                    break;
                }

                const subscription = state.subscriptions.find((subscription) => subscription?.months >= 12);

                if (subscription && avenant?.code == constants.CODE_AVENANTS.PROROGATON) {
                    continue;
                }

                _avenants.push(avenant);
            }

            setState(state => ({ ...state, avenants: _avenants, isAvenantLoading: false }));
            new CategoryService().getCategoryByCode(state?.baseSubscription?.categoryCode, (data) => {
                setState(state => ({ ...state, category: data, isAvenantLoading: false, isCategoryLoading: false, isAvenantModalVisible: true }));

            }, (error) => {
                toast.error("Erreur pendant le chargement des données d'avenants")
            });

        }, (error) => {
            toast.error("Erreur pendant le chargement des données d'avenants")
        });
    }

    const onAttestationGenerate = (subscriptionSelected, callback) => {
        setState(state => ({ ...state, isAttestationLoading: true }));
        form.validateFields().then(value => {
            let options = {
                path: {
                    contractDeatilID: subscriptionSelected?.id,
                    attestationNumber: value.attestation
                },
                query: {
                    natureCode: value?.natureCode
                }
            }
            setState(state => ({ ...state, isAttestationLoading: true }));
            ContractService.generateAttestation(options, (data) => {
                setState(state => ({ ...state, isSubscriptionsLoading: true, isAttestationLoading: false, isAttestationModalVisible: false }));
                if(callback) {
                    callback();
                }
                readAssureSubscriptions();
            }, (error) => {
                setState(state => ({ ...state, isAttestationLoading: false, attestationGenerateError: error }))
            })
        });
    }

    const onMainAttestationGenerate = () => {
        setState(state => ({ ...state, isAttestationLoading: true }));
        form.validateFields().then(value => {
            let options = {
                path: {
                    contractDeatilID: state.baseSubscription.id,
                    attestationNumber: value.attestation
                },
                query: {
                    jaune: true,
                    cedeao: true
                }
            }
            setState(state => ({ ...state, isAttestationLoading: true }));
            (new ContractService()).generateAttestation(options, (data) => {
                setState(state => ({ ...state, isSubscriptionsLoading: true, isAttestationLoading: false, visible: false }));

                readAssureSubscriptions();
            }, (error) => {
                setState(state => ({ ...state, isAttestationLoading: false, attestationGenerateError: error }))
            })
        });
    }

    const onAvenantSelected = (value) => {
        const _avenant = state.avenants.find((avenant) => avenant.ref === value);
        setState(state => ({ ...state, avenantSelected: _avenant }));
    }

    const onDetailsAvenantSelected = (subscription) => {
        // console.log(' subscription=> ', subscription)
        //waiting();
        const contractDetailSelected = (new ContractDetail()).fromJson(subscription);
        const packsSelected = contractDetailSelected?.packsSelected || [];

        const _vehicles = packsSelected.map(packSelected => {
            const v = Vehicle.fromJson(packSelected?.vehicle);
            return {...v, categoryTitle: packSelected?.packResponse?.category?.title, packTitle: packSelected?.packResponse?.packTitle}
        });
        const _assure = Assure.fromJson(contractDetailSelected?.assure);
        const _reductionGroups = contractDetailSelected?.reductionGroups || [];
        const _guaranties = contractDetailSelected?.guaranties || [];
        const _extras = contractDetailSelected?.extras || [];
        //const _product = subscription?.monoPackSelected?.quotationSinglePackResponse?.product;
        const _assistances = (contractDetailSelected?.assistances || []).map((assistance) => (new Assistance()).fromJson(assistance));

        const _period = (new Period()).fromJson(contractDetailSelected?.period);

        //setFakeDrawerContractDetailLoader(true);
        setState(state => ({
            ...state,
            drawerContractDetailVisibility: true,
            // product: _product,
            extras: _extras,
            reductionGroups: _reductionGroups,
            guaranties: _guaranties,
            vehicles: _vehicles,
            assure: _assure,
            assistances: _assistances,
            period: _period,
            subscriptionSelected: contractDetailSelected
        }));
    }

    const onAvenantSelectedSubmit = () => {
        NavigationPush(() => {

            // ------- FOR MONO
            dispatch(sUpdateState([
                {path: ["avenant"], value: state.avenantSelected},
                {path: ["contract"], value: (new Contract()).fromJson(state.baseSubscription?.contract)},
                {path: ["category"], value: state.category},
                {path: ["assure"], value: Assure.fromJson(state.baseSubscription?.assure)},
                {path: ["period"], value: state.period},
                {path: ["contractDetails"], value: state.baseSubscription},
                {path: ["partnerCode"], value: state?.partnerCode ? state?.partnerCode : user?.entities?.code},
            ]))
            // -------------------

            const subscriptionHeaderContext = SubscriptionHeaderContext.fromJson({
                subscriptionWidget: SubscriptionWidget.SUBSCRIPTION,
                avenant: state.avenantSelected,
                contract: (new Contract()).fromJson(state.baseSubscription?.contract),
                assure: Assure.fromJson(state.baseSubscription?.assure),
                period: state.period,
                category: state.category,
                newPeriod: state.newPeriod,
                contractDetails: state.baseSubscription
            });
            history.push({
                pathname: '/souscription/' + state?.baseSubscription?.categoryCode,
                state: subscriptionHeaderContext
            })
        })
    }

    const onAvenantClick = () => {
        loadAvenants();
    }

    return {
        state,
        setState,
        readAssureSubscriptions,
        onAttestationGenerate,
        onAvenantSelected,
        onDetailsAvenantSelected,
        onAvenantSelectedSubmit,
        onAvenantClick,
        waiting,
        onMainAttestationGenerate,
        form
    }
}