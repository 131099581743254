import React from 'react'
import { Button, Popconfirm } from "antd"
import { DownloadOutlined } from '@ant-design/icons'
import { useState } from 'react';
import SubscriptionService from 'services/subscriptionService';
import TransactionResponse from 'api/response/transactionResponse';
import { toast } from 'react-toastify';
import * as helpers from 'helpers/helpers';
import { ContractType } from '../ComponentWidget';
import { useSelector } from 'react-redux';

const ProjectConfirmation = ({ payload, onPaymentStatus, type }) => {
    const projectCode = useSelector(state=> state.subscription.projectCode)
    const [state, setState] = useState({
        isProjectSending: false
    });
    const onProjectProcess = () => {

        switch (type) {
            case ContractType.MONO:
                onMonoProjectProcess()
                break

            case ContractType.FLOTTE:
                onFlotteProjectProcess()
                break;
            default:
        }
    }

    //<validate Souscription project>
    const onMonoProjectProcess = () => {
        setState(state => ({ ...state, isProjectSending: true }));
        // console.log("payload :", payload);

        const _payload = { ...payload, isProject: true, projectCode: projectCode };
        (new SubscriptionService()).generateProject(_payload, (data) => {
            let _transactionResponse = (new TransactionResponse()).fromJson(data);
            
            onPaymentStatus({
                error: false, 
                transactionResponse: _transactionResponse,  
                page: helpers.PAGES.PROJECT_THANK_YOU
            })
            setState(state => ({
                ...state,
                isProjectSending: false,

            }))
        }, (error) => {
            toast.error(error);
            setState(state => ({ ...state, isProjectSending: false }))
            onPaymentStatus({error: true, errorMessage: error})
        })
    }

    //<validate Souscription project>
    const onFlotteProjectProcess = () => {
        setState(state => ({ ...state, isProjectSending: true }));
        const _payload = { ...payload, isProject: true, projectCode: projectCode };
        // console.log('flotte projet payload --> ', payload);
        (new SubscriptionService()).flotteGenerateProject(_payload, (data) => {
            let _transactionResponse = (new TransactionResponse()).fromJson(data);
            onPaymentStatus({
                error: false, 
                transactionResponse: _transactionResponse,  
                page: helpers.PAGES.PROJECT_THANK_YOU
            })
            setState(state => ({
                ...state,
                isProjectSending: false,
            }))
        }, (error) => {
            toast.error(error);
            onPaymentStatus({ error: true, errorMessage: error });
            setState(state => ({ ...state, isProjectSending: false }))
        })
    }

    return (
        <React.Fragment>
            <Popconfirm
                placement="top"
                title="Voulez-vous generer un projet ?"
                cancelText="Annuler"
                okText="Confirmer"
                onConfirm={onProjectProcess}>
                <Button
                    style={{ color: '#ffffff', borderRadius: 8, borderColor: '#0075C9', backgroundColor: '#005680', marginRight: 10, marginTop: 21 }}
                    loading={state.isProjectSending}
                //disabled={true}
                >
                    <DownloadOutlined style={{ color: '#ffffff', fontSize: 20 }} /> Convertir en projet
                </Button>
            </Popconfirm>
        </React.Fragment>
    )
}

export default ProjectConfirmation;