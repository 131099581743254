export default class SubscriptionDetailModel {
    id;
    categoryCode;
    policeNumber;
    entitiesTitle;
    avenantTitle;
    numAvenant;
    categoryCode;
    startDate;
    endDate;
    months;
    days;
    paymentProviderTitle;
    transactionId;
    primeNette;
    primeTTC;
    extras = [];
    guaranties= [];
    reductionGroups = [];
    assistance;
    assure;
    
    subscription;
    
    packsSelected = [];
    vehicle;

    assistances = [];
    vehicles = [];

    fromJson = (json={})=> {
        var subscriptionDetail = new SubscriptionDetailModel();
        subscriptionDetail = json;
        return subscriptionDetail;
    }
}