import { CheckCircleFilled, CheckCircleOutlined, DoubleLeftOutlined, DoubleRightOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Row, Tag } from "antd";
import TransactionResponse from "api/response/transactionResponse";
import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import { DocumentType, onDocumentOpen, thousandSeparator } from "helpers/helpers";

//THANK YOU PAGE
export default function StepThankYouProject({ transactionResponse }) {
    console.log('StepThankYouProject transactionResponse', transactionResponse)
    let _transactionResponse = (new TransactionResponse()).fromJson(transactionResponse);
    console.log('StepThankYouProject _transactionResponse', _transactionResponse)
    return (
        <React.Fragment>
            <Row className="justify-content-center">
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <div style={{ minHeight: 450, paddingTop: 50 }}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <CheckCircleFilled style={{ fontSize: 80, color: 'rgb(0, 117, 201)' }} />
                                </div><br />
                                <div style={{ display: 'flex', width: '100%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                    <h4 style={{ marginRight: 10 }}>Status  </h4>
                                    <h4 style={{ color: 'rgb(0, 117, 201)' }}>Action réalisée avec succès</h4>
                                </div>
                                <div style={{ display: 'flex', width: '100%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                    <h4 style={{ marginRight: 10 }}>N° projet </h4>
                                    <h4 style={{ color: '#52c41a', fontSize: 18 }}><Tag color='rgb(0, 117, 201)'>{_transactionResponse?.contractDetail?.projectCode} </Tag></h4>
                                </div>
                                <div style={{ display: 'flex', width: '100%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                    <h4 style={{ marginRight: 10 }}>Prime nette </h4>
                                    <h4 style={{ color: '#52c41a' }}><Tag color='gray'>{thousandSeparator(_transactionResponse.primeNette) + " FCFA"} </Tag></h4>
                                </div>

                                <>
                                    {
                                        _transactionResponse.primeExtras.map(function (item, index) {
                                            return (
                                                <div style={{ display: 'flex', width: '100%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }} key={index}>
                                                    <h4 style={{ marginRight: 10 }}>{item.title} </h4>
                                                    <h4 style={{ color: '#52c41a' }}><Tag color='gray'>{thousandSeparator(item?.amount) + " FCFA"}</Tag></h4>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                                {/* <div style={{ display: 'flex', width: '100%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                    <h4 style={{ marginRight: 10 }}>Montant  </h4>
                                    <h4 style={{ color: '#52c41a' }}><Tag color='green'>{initialValues?.step2?.package?.primeTTC} FCFA</Tag></h4>
                                </div> */}
                                <div style={{ display: 'flex', width: '100%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid #31CE49' }}>
                                    <h4 style={{ marginRight: 10 }}>Prime TTC  </h4>
                                    <h4 style={{ color: '#52c41a', size: 'large' }}><Tag color='rgb(0, 117, 201)'>{thousandSeparator(_transactionResponse?.primeTTC)} FCFA</Tag></h4>
                                </div>
                                <br />
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <h4>Merci de votre fidélité</h4>
                                </div><br />

                                <Divider />
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                    <Button style={{ backgroundColor: "rgb(0, 117, 201)", color: 'white', border: '0px solid #31CE49', borderRadius: 7 }} onClick={() => onDocumentOpen(DocumentType.DEVIS, `${_transactionResponse?.contractDetail?.projectCode?.replace("/", "_")}_${_transactionResponse?.contractDetail?.id}.pdf`)}>
                                        <DoubleRightOutlined />
                                        Télécharger le devis
                                    </Button>

                                    {/* <Link to={`/project-details/${_transactionResponse.contractDetail.id}/${_transactionResponse.contractDetail?.assure?.code}/${_transactionResponse?.contractDetail?.contract?.id}/${_transactionResponse?.contractDetail?.contract?.categoryCode}`}>
                                            <Button style={{ backgroundColor: "rgb(0, 117, 201)", color: 'white', border: '0px solid #31CE49', borderRadius: 7 }}>
                                                Acceder au details la souscription
                                                <DoubleLeftOutlined />
                                            </Button>
                                        </Link> */}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}