import { useEffect, useState } from 'react';
import CityService from 'services/cityService';

export function useGetCities() {
    const [state, setState] = useState({
        isLoading: true,
        data: []
    });

    useEffect(() => {
        (new CityService()).getAll((responseData) => {
            setState(prevState => ({
                ...prevState,
                data: responseData || [],
                isLoading: false
            }));
        }, (error) => {
            setState(prevState => ({
                ...prevState,
                isLoading: false
            }));
        });
    }, []);

    return {
        ...state
    }
}