import { Form, Input } from "antd";
import React from "react";

const MerchantEmailField=({disable})=> {
    return (
        <React.Fragment>
            <Form.Item
                label='Email apporteur'
                name="merchantEmail"
                rules={[{
                    required: false,
                    type: 'email',
                    // pattern: new RegExp("/\S+@\S+\.\S+/"),
                    message: 'Enter a valid email address!',
                }]}

            >
                <Input  placeholder='Email' size='medium' disabled={disable} />
            </Form.Item>
        </React.Fragment>
    )
}

export default MerchantEmailField;