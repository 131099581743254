import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';


import Logout from 'components/Logout';
import LoadUserDashboard from 'views/dashboard';
import UserProfile from 'views/pages/user_profile';

/* export default function Routes({ appRoutes }) {
  return (
    <Switch>
      <Route
        component={Logout}
        exact
        path="/deconnexion"
      />

      {
        (appRoutes || []).map(appRoute => {
          return (
            <Route key={appRoute?.key} path={appRoute?.baseUrl}>
              {(appRoute?.children || []).map(child => (
                <Route component={child?.component} exact path={child?.url}/>
                ))}
            </Route>
          )
        })
      }

      <Route
        component={UserProfile}
        exact
        path="/profil"
      />

      <Route
        component={LoadUserDashboard}
        exact
        path="/"
      />

      <Redirect to="/" />
    </Switch>
  );
} */


export default function Routes({ tabItems }) {

  return (
    <Switch>
      <Route
        component={Logout}
        exact
        path="/deconnexion"
      />

      {
        Object.keys(tabItems).map(tabUrl => (
          <Route key={tabUrl} path={tabUrl}>
            {tabItems[tabUrl].map((item, index) => (<Route key={index} component={item.component} exact path={item.url}/>))}
          </Route>
        ))
      }

      <Route
        component={UserProfile}
        exact
        path="/profil"
      />

      <Route
        component={LoadUserDashboard}
        exact
        path="/"
      />

      <Redirect to="/" />
    </Switch>
  );
}
