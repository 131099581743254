export default class EditGarantieRequest {
    id;
    code;
    title;
    description;

    static fromJson = (json = {}) => {
        var createGarantieRequest = new EditGarantieRequest();
        createGarantieRequest = json;
        return createGarantieRequest;
    }
}