import React from "react";
import { convert } from "helpers/helpers";
import ReturnNewLine from "views/pages/common/ReturnNewLine";
import ReturnNewTitle from "views/pages/common/ReturnNewTitle";

const FDetailsAssure=({assure})=> {
    return (
        <React.Fragment>
            {ReturnNewTitle('Informations sur l\'assuré')}
            {ReturnNewLine('Nom', assure?.firstName, false)}
            {ReturnNewLine('Prenom', assure?.lastName, false)}
            {ReturnNewLine('Numero mobile', assure?.phone, false)}
            {ReturnNewLine("Nature de la piece d'identité", assure?.identityCardTitle, false)}
            {ReturnNewLine("Numéro de la piece", assure?.identityCardNumber, false)}
            {ReturnNewLine("Date de naissance de l'assuré", convert(assure?.birthDay), false)}
            {/* {ReturnNewLine("Date du permis", convert(driverLicdeliveryDate), false)}
            {ReturnNewLine("Status socio-professionnel", assure?.statusSocio, true)} */}
        </React.Fragment>
    )
}

export default FDetailsAssure;