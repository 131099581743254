import { Divider } from "antd";
import React from "react";
import { Col, Row } from "reactstrap";
import { thousandSeparator } from "helpers/helpers";

const VehicleDetailsWidget=({vehicle})=> {
    return (
        <React.Fragment>
            <Row>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Categorie:  </p>
                        <p >{vehicle?.categoryTitle}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Pack:  </p>
                        <p >{vehicle?.packTitle}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Date de circulation:  </p>
                        <p >{(new Date(vehicle?.dateMec).toLocaleDateString())}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Energie  </p>
                        <p >{vehicle?.energy?.title}</p>
                    </div>
                </Col>

                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>GPS:  </p>
                        <p >{vehicle?.hasGps ? ("Oui") : ("Non")}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Immatriculation </p>
                        <p >{vehicle?.matriculation}</p>
                    </div>
                </Col>

                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Marque:  </p>
                        <p >{vehicle?.model?.brand?.title}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Modele:</p>
                        <p >{vehicle?.model?.title}</p>
                    </div>
                </Col>

                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Nombre de place:  </p>
                        <p >{vehicle?.totalPlace}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Numero de chassis:</p>
                        <p >{vehicle?.chassisNumber}</p>
                    </div>
                </Col>

                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Puissance Poids Cylindre:  </p>
                        <p >{vehicle?.fiscalPower + ' Cv'}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Transport:</p>
                        <p >{vehicle?.transport?.title}</p>
                    </div>
                </Col>

                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Valeur neuve:  </p>
                        <p >{thousandSeparator(vehicle?.newValue) + ' FCFA'}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Valeur venale:</p>
                        <p >{thousandSeparator(vehicle?.marketValue) + ' FCFA'}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Ville de circulation:</p>
                        <p >{vehicle?.city?.title}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Zone de circulation:</p>
                        <p >{vehicle?.city?.area?.title}</p>
                    </div >
                </Col >

                
            </Row >

            {
                vehicle?.hasTrailer ? (
                   <div>
                        <Divider orientation="left">Remorque: </Divider>
                        <Row>
                            <Col md={12}>
                                <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                    <p style={{ marginRight: 10, color: '#8E8E8E', fontWeight: 'bold' }}>Informations remorques:</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Charge utile:</p>
                                    <p >{vehicle?.payload}</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Inflammable:</p>
                                    <p >{vehicle?.inflammable ? "Oui" : "Non"}</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Marque:</p>
                                    <p >{vehicle?.trailerModel?.brand?.title}</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Model:</p>
                                    <p >{vehicle?.trailerModel?.title}</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Matriculation:</p>
                                    <p >{vehicle?.trailerMatriculation}</p>
                                </div>
                            </Col>
                    </Row> 
                   </div>
                ) : null
            }
        </React.Fragment>
    )
}

export default VehicleDetailsWidget;