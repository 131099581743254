import { Drawer, Skeleton, Tabs, Tag } from "antd";
import { Button} from 'reactstrap';
import React, { useState } from "react";
import { thousandSeparator } from "helpers/helpers";
import SettingGuarantee from "./SettingGuarantee";
import SettingAssisance from "./SettingAssisance";
import QuotationService from "services/quotationService";
import _ from "lodash";
import { toast } from "react-toastify";
import QuotationResponse from "api/v1/response/QuotationResponse";

const SettingPackage=({packSelect, quotationRequest, onConfirmPackSetting, setDrawerVisibility, drawerVisibility= false})=> {
    

    const { TabPane } = Tabs;
    const [customGuarantiesCollect, setCustomGuarantiesCollect]=useState([]);
    const [assistanceLinesCollect, setAssistanceLinesCollect] = useState([]);
    const [packSelected, setPackSelected] = useState(packSelect);

    const [state, setState]=useState({
        isQuoting: false
    })


    const onCancelPackSetting=()=>  setDrawerVisibility(false);

    const calculatePackPrime=()=> {

        // Custom guaranties
        let _customGuaranties = [];
        for(const customGuarantee of customGuarantiesCollect) {
            if(customGuarantee?.selected === true) {
                _customGuaranties = [..._customGuaranties, {
                    guaranteeRef: customGuarantee?.guaranteeRef,
                    engagementRef: customGuarantee?.engagementRef,
                    franchise: customGuarantee?.franchise
                }]
            }
        }

        let _otherCustomGuaranties=[];
        for(const guarantee of packSelected?.guaranties) {
            if(guarantee?.required && customGuarantiesCollect.findIndex((customGuarantie)=> customGuarantie?.guaranteeRef === guarantee?.ref) === -1) {
                const defaultEngagement = (guarantee?.engagements || []).find((engagement)=> engagement?.default);
                _otherCustomGuaranties = [..._otherCustomGuaranties, {
                    guaranteeRef: guarantee?.ref,
                    engagementRef: defaultEngagement?.ref,
                    franchise: guarantee?.franchise
                }]
            }
        }
        _customGuaranties = [..._customGuaranties, ..._otherCustomGuaranties];

        
        setState(state=> ({...state, isQuoting: true}))
        const _quotationRequest =  {
            ...quotationRequest,
            customGuaranties: [..._customGuaranties],
            reductionGroups: [...packSelected?.reductionGroups],
            packLineRef: packSelected?.packLineRef
        }

        QuotationService.quote({quotationRequests: [_quotationRequest], t: 'm', prorate: false}, (result)=> {
            const _quotationResponse = QuotationResponse.fromJson(result);
            const _pack = _.first(_quotationResponse?.packsResponse);
            console.log("::: _pack:", _pack)
            
            setPackSelected({
                ...packSelected,
                primeTTC: _pack?.primeTTC,
                primeExtra: _pack?.primeExtra,
                primeNette: _pack?.primeNette,
                extras:  [..._pack?.extras],
                customGuaranties: [..._customGuaranties], 
                assistance: {
                    ...packSelected?.assistance,
                    customAssistancesLines: [...assistanceLinesCollect] 
                }
            })
            setState(state=> ({...state, isQuoting: false}))
        }, (error)=> {
            toast.error("Echec calcul cotation")
        })
    }

    return (
        <Drawer
            title={<div style={{display: 'flex', flexDirection: "column"}}>
                    <div> Options des guaranties {packSelected?.packTitle}</div>
                    <div style={{display: "flex", justifyContent: 'space-between', height: "30px"}}>
                        <div style={{ height: "auto",   marginRight: 20, display: 'flex', justifyContent: "start" }}>
                            <h4 style={{ color: 'grey', textAlign: "center", marginRight: "5px" }}>Pack prime : </h4>
                            <h4 style={{ color: '#52c41a', size: 'large', fontSize: 22 }}>{state.isQuoting ? (<Skeleton.Input active={true} size='default' />) : (<Tag color= "rgb(0, 117, 201)" style={{ fontSize: 20, padding: "2px"}}>{thousandSeparator(packSelected.primeTTC)} FCFA</Tag>)} </h4>
                        </div>
                        <Button style={{height: "30px"}} color="primary" className="btn-label right btn-sm" disabled={(customGuarantiesCollect?.length === 0)} onClick={()=> calculatePackPrime()} >
                            <i className=" bx bx-refresh label-icon align-middle fs-16 ms-2"></i> Mettre à jour
                        </Button>
                    </div>
                </div>}
            width={600}
            closable={false}
            //onClose={() => onClose(false)}
            visible={drawerVisibility}
            bodyStyle={{paddingBottom: 80,paddingTop: 10}}
        >
            <React.Fragment>
                <Tabs defaultActiveKey="1" onChange={()=> console.log("Setting guarantee tab change")} key={"settingPackParams"}>
                    <TabPane tab="Garanties" key="1" tabKey="gSettingPackParams">
                        <SettingGuarantee 
                            category={packSelected?.category}
                            pack={packSelected}
                            setCustomGuarantiesCollect={setCustomGuarantiesCollect}
                        />
                    </TabPane>
                    <TabPane tab="Assistances" key="2" tabKey="gSettingPackParams">
                        <SettingAssisance 
                            assistance={packSelected?.assistance} 
                            setAssistanceLinesCollect={setAssistanceLinesCollect}/>
                    </TabPane>
                </Tabs>
                
                <div
                    style={{ position: 'absolute', bottom: 0, width: '100%', borderTop: '1px solid #e8e8e8',
                        padding: '10px 16px',textAlign: 'right', left: 0, background: '#fff', borderRadius: '0 0 4px 4px',
                    }}
                >
                    <div className="d-flex justify-content-between">
                    <Button
                        color="light" className="btn-label left"
                        // style={{
                        //     marginRight: 8,
                        // }}
                        onClick={() => onCancelPackSetting()}
                    >
                        <i className=" bx bx-x label-icon align-middle fs-16 ms-0"></i> Annuler et fermer
                    </Button>
                    
                    <Button color="primary" className="btn-label right" disabled={(customGuarantiesCollect?.length === 0)} onClick={()=> onConfirmPackSetting(packSelected)} >
                        <i className=" bx bx-check label-icon align-middle fs-16 ms-2"></i> Valider et fermer
                    </Button>
                    </div>
                    
                </div>
            </React.Fragment>
        </Drawer>
        
    )
}
export default SettingPackage;