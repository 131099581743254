import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class PeriodService {
    
    getAll(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.periodResource, {}, sCallBack, eCallBack)
    }getPeriodEndDateResource

    static getPeriodEndDate(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.getPeriodEndDateResource, payload, sCallBack, eCallBack)
    }
}