import { Col, Collapse, Divider, Row, Table, Tabs, Tag } from "antd";
import React from "react";
import { thousandSeparator } from "helpers/helpers";
import VehicleDetailsWidget from "../subscription/details_souscriptions/VehicleDetailsWidget";
import { fullFormatDate } from "helpers/date_helpers";

export default function SubscriptionDetails({subscriptionDetail}) {
    const { Panel } = Collapse;

    const callback = (key) => {
        console.log(key);
    }

    const { TabPane } = Tabs;
    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Titre',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Prime nette',
            dataIndex: 'primeNet',
            key: 'primeNet'
        },
    ];

    const columnsReduction = [
        {
            title: 'Titre',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Pourcentage',
            dataIndex: 'rate',
            key: 'rate',
            render: (item, reduction) => {
                return ((reduction.rate) * 100) + " %";
            }
        }
    ];

    const columnsAssistances = [
        {
            title: 'Details assistance',
            dataIndex: 'guaranteeName',
            key: 'guaranteeName'
        },
    ]

    return (
        <div>
            <Row>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%' }}>
                        <p style={{ marginRight: 10 }}>Partenaire :</p>
                        <p >{subscriptionDetail?.entitiesTitle}</p>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%' }}>
                        <p style={{ marginRight: 10 }}>Avenant :</p>
                        <p >{subscriptionDetail?.avenantTitle}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%' }}>
                        <p style={{ marginRight: 10 }}>Numero avenant :</p>
                        <p >{subscriptionDetail?.numAvenant}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%' }}>
                        <p style={{ marginRight: 10 }}>Date debut :</p>
                        <p >{fullFormatDate(subscriptionDetail?.startDate)}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%' }}>
                        <p style={{ marginRight: 10 }}>Date fin :</p>
                        <p >{fullFormatDate(subscriptionDetail?.endDate)}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%' }}>
                        <p style={{ marginRight: 10 }}>Durée contrat :</p>
                        <p >{subscriptionDetail?.months > 0 ? subscriptionDetail?.months + " mois" : subscriptionDetail?.days + ' Jours'}</p>
                    </div>
                </Col>
            </Row>

            <Divider />

            <Tabs defaultActiveKey="1" onChange={callback}>

                <TabPane tab="Transaction" key="1">
                    <Row>
                        <Col md={24}>
                            <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                <p style={{ marginRight: 10, color: '#8E8E8E' }}>Moyen de paiement </p>
                                <p>{subscriptionDetail?.paymentProviderTitle}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24}>
                            <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                <p style={{ marginRight: 10, color: '#8E8E8E' }}>Ref. transaction </p>
                                <p>{subscriptionDetail?.transactionId}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={24}>
                            <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                <p style={{ marginRight: 10, color: '#8E8E8E' }}>Prime nette:  </p>
                                <p ><Tag color={'green'}>{(thousandSeparator(subscriptionDetail?.primeNette) + ' FCFA')}</Tag></p>
                            </div>
                        </Col>
                    </Row>
                    {
                        (subscriptionDetail?.extras || []).map(function (extra, index) {
                            // console.log("Package item : ", item)
                            return (
                                <Row>
                                    <Col md={24}>
                                        <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                            <p style={{ marginRight: 10, color: '#8E8E8E' }}>{extra?.title}({extra?.code})  </p>
                                            <p >{thousandSeparator(extra?.amount) + ' FCFA'}</p>
                                        </div>
                                    </Col>
                                </Row>
                            )
                        })
                    }

                    <Row>
                        <Col md={24}>
                            <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                <p style={{ marginRight: 10, color: '#8E8E8E' }}>TTC à payer  </p>
                                <p ><Tag color={'green'}>{(thousandSeparator(subscriptionDetail?.primeTTC) + ' FCFA')}</Tag></p>
                            </div>
                        </Col>
                    </Row>

                </TabPane>

                <TabPane tab="Garanties" key="2">
                    <Table columns={columns} dataSource={subscriptionDetail?.guaranties} rowKey="id" />
                </TabPane>

                <TabPane tab="Véhicule" key="3">
                    {
                        subscriptionDetail?.categoryCode === '400' ? (
                            <Collapse>
                                {
                                    (subscriptionDetail?.vehicles || []).map((vehicle, index) => {
                                        return (
                                            <Panel header={`${vehicle?.model?.brand?.title} / ${vehicle?.model?.title}`} key={index}>
                                                {/* {vehicleTabPane(vehicle)} */}
                                                <VehicleDetailsWidget vehicle={vehicle}/>
                                            </Panel>
                                        );
                                    })
                                }
                            </Collapse>
                        ) : <VehicleDetailsWidget vehicle={subscriptionDetail.vehicles[0]}/> /* vehicleTabPane(subscriptionDetail?.vehicles[0]) */
                    }
                </TabPane>
                <TabPane tab="Reduction" key="4">
                    <Table columns={columnsReduction} dataSource={subscriptionDetail?.reductionGroups} />
                </TabPane>
                <TabPane tab="Assistance" key="5">

                    {

                        <Collapse>
                            {
                                (subscriptionDetail?.assistances || []).map((assistance, index) => {
                                    return (
                                        <Panel header={`${assistance?.title}`} key={index}>
                                            <Table columns={columnsAssistances} dataSource={assistance?.assistanceLines} />
                                        </Panel>
                                    );
                                })
                            }
                        </Collapse>

                    }

                </TabPane>
                <TabPane tab="Assuré" key="6">
                    <Row>
                        <Col md={24}>
                            <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                <p style={{ marginRight: 10, color: '#8E8E8E' }}>Code:  </p>
                                <p style={{ fonWeight: 'bold' }}>{subscriptionDetail?.assure?.code}</p>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                <p style={{ marginRight: 10, color: '#8E8E8E' }}>Nom:  </p>
                                <p >{subscriptionDetail?.assure?.firstName} </p>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                <p style={{ marginRight: 10, color: '#8E8E8E' }}>Prenoms: </p>
                                <p >{subscriptionDetail?.assure?.lastName}</p>
                            </div>
                        </Col>

                        {/* <Col md={12}>
                        <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                <p style={{ marginRight: 10, color: '#8E8E8E' }}>Adresse:  </p>
                                <p >{state?.assure?.address}</p>
                            </div>
                        </Col> */}
                        {/* <Col md={12}>
                            <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                <p style={{ marginRight: 10, color: '#8E8E8E' }}>Profession: </p>
                                <p >{state?.assure?.profession}</p>
                            </div>
                        </Col> */}

                        <Col md={12}>
                            <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                <p style={{ marginRight: 10, color: '#8E8E8E' }}>Email:  </p>
                                <p >{subscriptionDetail?.assure?.email}</p>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                <p style={{ marginRight: 10, color: '#8E8E8E' }}>Telephone: </p>
                                <p >{subscriptionDetail?.assure?.phone}</p>
                            </div>
                        </Col>

                        <Col md={12}>
                            <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                <p style={{ marginRight: 10, color: '#8E8E8E' }}>Type de piece:  </p>
                                <p >{subscriptionDetail?.assure?.identityCardTitle}</p>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                <p style={{ marginRight: 10, color: '#8E8E8E' }}>Numero de piece: </p>
                                <p >{subscriptionDetail?.assure?.identityCardNumber}</p>
                            </div>
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        </div>
    );
}