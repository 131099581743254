export default class EditModelRequest {
    id;
    code;
    title;
    description;
    // brandRef;

    static fromJson = (json = {}) => {
        var createModelRequest = new EditModelRequest();
        createModelRequest = json;
        return createModelRequest;
    }
}