import React, { useEffect, useState } from "react";
import {Col, Container, Row } from "reactstrap";

import Payment from "views/pages/subscription/component/payment/Payment";
import { ContractType } from "views/pages/subscription/component/ComponentWidget";
import { MDetailOthers, MDetailPackage, MDetailPeriod, MDetailPrime, MDetailStartDate } from "../../common/details";
import { useBasket } from "views/pages/subscription/hooks/useBasket";
import _ from "lodash";
import QuotationResponse from "api/v1/response/QuotationResponse";
import { Spin, Card } from "antd";
import { useSelector } from "react-redux";
import QuotationRequest from "api/request/quotationRequest";
import QuotationService from "services/quotationService";
import SubscriptionRequestBuilder from "api/request/SubscriptionRequestBuilder";

const StepDetails = ({
    avenantCode,
    endDate,
    formField,
    packSelected,
    subscriptionRequest
}) => {
    const { contract, avenant, assure, period, projectCode } = useSelector((state) => state.subscription);
    const { goToPreviousStep, updateGroup} = useBasket();
    const [isQoting, setIsQuoting]= useState(false);
    const [initStartDate, setInitStartDate] = useState({
        startDate: subscriptionRequest?.customerStartDate ?? subscriptionRequest?.dateEffect,
        endDate: endDate,
        initialDate: subscriptionRequest?.customerStartDate ?? subscriptionRequest?.dateEffect
    })

    useEffect(()=> {
        const _quotationRequest = QuotationRequest.buildFromFormFields({
            formFields: formField, 
            contract: contract, 
            avenant: avenant, 
            packLineRef: null
        });

        quotePackSelected(_quotationRequest)
    
    }, [])

    const quotePackSelected=(_quotationRequest)=> {
        setIsQuoting(true)
        const __quotationRequest =  {
            ..._quotationRequest,
            customGuaranties: [...packSelected?.customGuaranties],
            reductionGroups: [...packSelected?.reductionGroups],
            packLineRef: packSelected?.packLineRef
        }

        QuotationService.quote({quotationRequests: [__quotationRequest], t: 'm'}, (result)=> {
            const _quotationResponse = QuotationResponse.fromJson(result);
            const _pack = _.first(_quotationResponse.packsResponse);
  
            updateGroup([
                {path: ['basket', 'quotationRequest'], value: {...__quotationRequest}},
                {path: ['basket', 'packSelected'], value: {...packSelected, ..._pack}}
            ])

            setIsQuoting(false)
        
        }, (error)=> {
            console.log("Quoting changement vehicule error :", error)
            setIsQuoting(false)
        })
    }

    const onStartDateChange = (newDateEffect) => {
        setInitStartDate({
            ...initStartDate,
            startDate: newDateEffect
        })

        updateGroup([
            { path: ['basket', 'subscriptionRequest', 'dateEffect'], value: newDateEffect },
            { path: ['basket', 'subscriptionRequest', 'customerStartDate'], value: newDateEffect },
            { path: ['basket', 'subscriptionRequest', 'prorate'], value: true }
        ])
    }

    const onDateEffectChange=(newDateEffect)=> {
        onStartDateChange(newDateEffect);
        const _quotationRequest = QuotationRequest.buildFromFormFields({
            formFields: formField, 
            contract: contract, 
            avenant: avenant, 
            packLineRef: null
        });
        quotePackSelected({
            ..._quotationRequest, 
            startDate: newDateEffect,
            customerStartDate: newDateEffect,
            prorate: true
        })

        
    }

    if(isQoting) {
        return <Card><Spin /> Calcul nouvelle prime en cours ...</Card>
      }
    
    return (
        <React.Fragment>
            <div /* className="page-content" */>
                <Container fluid>

                    <Row>
                        <Col className="col-xxl-9 order-xxl-0 order-first">
                            <Row>
                                <MDetailPackage packTitle={packSelected?.packTitle} />
                                <MDetailPeriod perioTitle={packSelected?.period?.title} />
                                <MDetailStartDate rangeDate={initStartDate} onStartDateChange={onDateEffectChange} />
                            </Row>
                            <MDetailOthers
                                vehicle={subscriptionRequest?.vehicle}
                                assure={subscriptionRequest?.assure}
                            />
                        </Col>
                        <MDetailPrime
                            primeTTC={packSelected?.primeTTC}
                            primeNette={packSelected?.primeNette}
                            extras={packSelected?.extras}
                        />

                    </Row>
                    <Row>
                        <Col className="col-xxl-12">
                            <Payment
                                backVisibility={true}
                                projectVisibility={false}
                                onlineVisiblity={true}
                                payload={SubscriptionRequestBuilder.fromThis(avenantCode, {...subscriptionRequest, prorate: true})}
                                goBack={goToPreviousStep}
                                type={ContractType.MONO}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default StepDetails;