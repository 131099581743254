import Category from "api/model/category.model";
import Pack from "api/model/pack";
import Period from "api/model/period.model";
import Product from "api/model/product.model";

export default class QuotationResponse {
    product= new Product();
    category=new Category();
    
    primeTTC    = 0; //<Cumul packs primeTTC>
    primeTHFTTC  = 0; //<Cumul packs primeTTC Hors Flotte>
    primeNette  = 0; //<Cumul packs primeNette>
    primeExtra  = 0; //<Cumul packs primeExtra>
    agencyFees  = 0; //<Cumul packs agencyFees>
    nbVehicle   = 0; 
    extras = []; // //<Cumul packs extras>
    period = new Period();
    
    guaranties = []; //<Cumul packs guaranties>
    reductionGroups = []; //<Cumul packs reductionGroups>

    assistances = []; //<Cumul packs assistances>
    
    packsResponse = []; //<packs>

    static fromJson=(json={})=> {
        var quotationResponse = new QuotationResponse();
        quotationResponse = {...quotationResponse, ...json, packsResponse: (json?.packsResponse || []).map((packResponse)=> Pack._fromJson(packResponse))};
        return quotationResponse;
    }
}