import { Col, Form, Input } from "antd";
import { CATEGORY_403 } from "constantes";
import React from "react";

const TrailerCustomerModelField= ({categoryCode, disable, xs, md})=> {

  if(![CATEGORY_403].includes(categoryCode)) return null;
  return (
    <React.Fragment>
      <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>

        <Form.Item
            label={(<div style={{ display: 'flex' }}><h5>Saisissez le modèle</h5></div>)}
            name="trailerModelTitle"
            rules={[{ required: true, message: "Champ obligatoire" }]}
        >
            <Input placeholder='Saisissez le modèle' size='large' disable={disable}/>
        </Form.Item>
      </Col>
       
    </React.Fragment>
  )  
}

export default TrailerCustomerModelField;