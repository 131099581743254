import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Form, InputNumber, Row, Table, Select, Spin } from "antd";
import ContractsResponse from "api/response/contratsResponse";
import _, { round } from "lodash";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CoInsuranceService from "services/coInsuranceService";
import { openNotificationWithIcon, thousandSeparator } from "helpers/helpers";

export default function AddCoInsurance({ contract, onConfirm, onCancel }) {
    
    const Option = Select;
    const [form] = Form.useForm();
    const [state, setState] = useState({
        coInsurances: [],
        isCoInsuranceLoading: true,
        coInsurancesCollected: [],
        isLoad: false,
        cpt: 0,
        updateLine: {},
        item: {},
        contractSelected: (new ContractsResponse()).fromJson(contract),

        totalRate: 0,

        isRepartitionConfirming: false
    });

    useEffect(() => {
        loadAllInsurance();
    }, [])

    const loadAllInsurance=()=> {
        (new CoInsuranceService()).getAllCoInsurances((data)=>{
            setState(state => ({ ...state, coInsurances: data || [], isCoInsuranceLoading: false }));
        }, (error)=>{
           // openNotificationWithIcon('waring', "Erreur", null);
        })
    }

    const addInsuranceInList = ()=> {
        form.validateFields().then((value)=> {
            console.log("::::: VALUES : ", value);
            console.log("::::: VALUES 2 : ", state.insurancesLines)
            
            let insurance = state.coInsurancesCollected.find(insurance => insurance.ref === value?.ref);
            
            if(insurance) {
                openNotificationWithIcon("warning", "Assurance déja pris en compte");
                return false;
            }

            let insuranceSelected = state.coInsurances.find(insurance => insurance.ref === value?.ref);
            insuranceSelected = Object.assign({}, insuranceSelected, {rate: value.rate} );
            const _totalRate = totalRate(_.concat(state.coInsurancesCollected, insuranceSelected));
            
            if(_totalRate > 100) {
                openNotificationWithIcon("warning", "Vous ne pouvez pas depasser les 100 %");
                return false;
            }

            console.log("::::: insuraneSelected : ", insuranceSelected)
            const _insurances = _.concat(state.coInsurancesCollected, insuranceSelected);
            setState((state)=>({
                ...state, 
                coInsurancesCollected: _insurances, 
                totalRate: _totalRate
            }));

        }).catch((error)=> {
            console.log(error)
        })
    }

    const totalRate = (insuranceLines = [])=> {
        console.log(":::: Total ARRAY : ", insuranceLines)
        let rates = insuranceLines.map((insuranceLine)=> insuranceLine?.rate);
        return  _.sum(rates);
    }

    const onTableElementDeleted = (insurance)=> {
        const insurancesLines = state.coInsurancesCollected.filter(_insurance => _insurance.ref !== insurance.ref); 
        setState((state)=>({
            ...state,
            coInsurancesCollected: insurancesLines,
            totalRate: totalRate(insurancesLines)
        }));
    }

    var columns = [
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            render: (item, insurance) => (<div>{insurance?.title}</div>)
        },
        {
            title: 'Taux(%)',
            dataIndex: 'rate',
            key: 'rate',
            width: 20,
            align: "right"
        },
        {
            title: 'Valeur',
            dataIndex: 'rate',
            key: 'rate',
            align: "right",
            width: 150,
            render: (index, insurance)=> {
                console.log(":::: insurance : ", insurance)
                const value = insurance?.rate ? ((state.contractSelected.primeNette )*((parseInt(insurance?.rate)/100))) : 0;
                return value ? thousandSeparator(round(value, 2)) : 0
            }
            
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            align: "right",
            width: 80,
            render: (item, insurance) => (
                <DeleteOutlined style={{ marginRight: 5, color: 'red' }} onClick={() => { onTableElementDeleted(insurance) }} />

            )
        }
    ];

    function onConfirmRepartition() {
        console.log(":::: Validate")
        const p = state?.coInsurancesCollected.map((insuranceLine) => ({ ref: insuranceLine.ref, rate: insuranceLine.rate }));
        const payload = {
            coInsuranceRates: p,
            contractDetailId: state?.contractSelected?.id
        };

        setState(state=> ({...state, isRepartitionConfirming: true}));
        CoInsuranceService.applyRepartition(payload, (data) => {
            openNotificationWithIcon("warning", "Créer avec succès");
            setState(state=> ({...state, isRepartitionConfirming: false}));
            onConfirm();
        }, (error) => {
            console.log(error)
            toast.error("Une erreur est survenue")
            setState(state=> ({...state, isRepartitionConfirming: false}));
        })
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 10 }}>
                <h3>Total : {state?.totalRate + " %"}</h3>
                {/* <Button type="primary" onClick={() => formAddRateVisibility(true)}><FileAddOutlined />Ajouter un co-assureur</Button> */}
            </div>

            <div style={{ marginTop: 15 }}>
                <Form
                    name="basic"
                    onFinish={addInsuranceInList}
                    layout="vertical"
                    form={form}
                >
                    <Row gutter={8} >
                        <Col className="gutter-row" xs={12} md={16}>
                            <Form.Item
                                label="Co-assureur"
                                name="ref"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Option obligatoire!',
                                    },
                                ]}
                            >
                                <SelectWithFilter placeholder="Choisissez le co-assureur" size="middle" style={{ minWidth: 200 }}>
                                    {
                                        state.coInsurances.map(function (coInsurance, index) {
                                            return (<Option value={coInsurance?.ref} key={index}>{coInsurance.title}</Option>)
                                        })
                                    }
                                </SelectWithFilter>
                            </Form.Item>
                        </Col>
                        <Col md={4}>
                            <Form.Item
                                label="Taux"
                                name="rate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Option obliatoire',
                                    },
                                ]}
                            >
                                <InputNumber placeholder="taux" min={0} />
                            </Form.Item>
                        </Col>

                        <Col md={4}>
                            <Form.Item
                                label=" "
                                name="selected"
                                rules={[{
                                        required: false,
                                        message: '',
                                    }]}
                            >
                                <div style={{ display: 'flex' }}>
                                    <Button type="primary" htmlType="submit">
                                        <CheckOutlined /> Ajouter
                                    </Button>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>

            <Table columns={columns} dataSource={state.coInsurancesCollected} />
            
            <Form
                name="btnForm"
                onFinish={onConfirmRepartition}
                layout="vertical"
            // form={form}
            >

                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e8e8e8',
                        padding: '10px 16px',
                        textAlign: 'right',
                        left: 0,
                        background: '#fff',
                        borderRadius: '0 0 4px 4px',
                    }}
                >
                    <Button style={{marginRight: 8}} onClick={onCancel}>
                        Annuler
                    </Button>
                    <Button  type="primary" htmlType="submit" loading={state.isRepartitionConfirming} disabled={ state.totalRate < 100 }>
                        Confirmer
                    </Button>
                </div>
            </Form>
        </React.Fragment>
    )
}