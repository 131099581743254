export default class FormRegisterCoInsurance {
    code;
    title;
    phone;
    email;
    description;

    toJson = (json={})=> {
        var formRegisterCoInsurance = new FormRegisterCoInsurance();
        formRegisterCoInsurance = json;
        return formRegisterCoInsurance;
    }
}