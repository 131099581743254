import 'services/extensions'

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//const _window = window;
//const maintenanceMsg = _window.__ENV && _window.__ENV.maintenanceMsg;

export const DEFAULT_TABLE_MIN_PAGE = 0;
export const DEFAULT_TABLE_MAX_SIZE = 100;
export const PRODUCT_AUTO_REF = "a234285b-84b3-4c39-978d-1ca7bc5cd95b";
export const DEFAULT_PAYEMENT_PROVIDER = "7e06a3c1-4a80-474b-beaf-91d5ea943fa0";
//export const PDF_VIEWER_URL = API_BASE_URL + "/viewer/pdf/viewer.html";
export const PDF_VIEWER_URL = "https://viewer.sanlambj.com/web/viewer.html"; //"https://viewer.radisbn.com/web/viewer.html";
export const FILE_PATH_ROOT = API_BASE_URL;

export const CODE_AVENANTS = {
    CREATION: 0,
    RENOUVELEMENT_SANS_MODIFICATION: 1,
    RENOUVELEMENT_AVEC_MODIFICATION: 2,
    SUSPENSION: 4,
    REMISE_EN_VIGUEUR_AVEC_REPORT: 6,
    CHANGEMENT_IMMATRICULATION: 9,
    CHANGEMENT_VEHICULE: 10,
    DUPLICATA_ATTESTATION: 12,
    code15: 15,
    code16: 16,
    EXTENSION_RETRAIT_GARENTIES: -17,
    EXTENSION_GARENTIES: 17,
    RETRAIT_GARENTIES: 18,
    code19: 19,
    RESILIATION_AVEC_RISTOUNE: 20,
    RESILIATION_PURE: 21,
    MODIFICATION_RISQUE: 35,
    CHANGEMENT_ASSURE: 101,
    PROROGATON: 102,
    ANNULATION_PURE: 103,
    INCORPORATION: 104,
    RETRAIT_VEHICULE: 105,
}

export const TEST_API = "http://localhost:3003/"

export const API_URL = {
    API_PDF_VIEWER_URL: API_BASE_URL + "/files/downloadFile/?fileName="
}
export const USERS_PROFILES = {
    COMMISSION: 'COMMISSION',
    CASSIER_AGENCE: 'CAISSIERE AGENCE',
};
// -------------------------------------------------------------------------------//

export const COLORS = {
    primaryColor: '#2B98E0',
    secondaryColor: '#449D48',
    cardTitleColor: '#449D48',
}

export const API_ROUTE = 'http://localhost:8082/api';
export const ACCESS_TOKEN = 'accessToken';

export const CURRENT_USER = 'currentUser';

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 3;

export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;

export const PASSWORD_MAX_LENGTH = 20;

export const GOOGLE_API_KEY = "AIzaSyAFYHmNIwY1kAwnOiUvCnYQUYzLfLtVga0";

export const USERS_ROLE = {
    ROLE_ADMIN: "ROLE_ADMIN",
    ROLE_CA: "ROLE_CA",
    ROLE_CE: "ROLE_CE",
    ROLE_ACJ: "ROLE_ACJ",
    ROLE_CONTROLLER: "ROLE_CONTROLLER"
}

// CATEGORY
export const CATEGORY_401 = "401";
export const CATEGORY_402 = "402";
export const CATEGORY_403 = "403";
export const CATEGORY_404 = "404";
export const CATEGORY_405 = "405";
export const CATEGORY_406 = "406";
export const CATEGORY_407 = "407";
export const CATEGORY_408 = "408";
export const CATEGORY_409 = "409";
export const CATEGORY_410 = "410";