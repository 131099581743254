import { Form, Input } from "antd";
import React from "react";

const EmailField=({disable})=> {
    return (
        <React.Fragment>
            <Form.Item
                label='Email de notification'
                name="assureEmail"
                rules={[{
                    required: false,
                    type: 'email',
                    // pattern: new RegExp("/\S+@\S+\.\S+/"),
                    message: 'Enter a valid email address!',
                }]}

            >
                <Input  placeholder='Email' size='large' disabled={disable} />
                
            </Form.Item>
            <span style={{ color: '#a9abad', marginTop: -15 }}>Entrez ci-dessous l'adresse mail à laquelle le client recevra les detatils des notifications sur votre contrat</span>
        </React.Fragment>
    )
}

export default EmailField;