
export default class Contract {
    id;
    ref;
    contractType;
    policeNumber;
    offering;

    productRef;
    productCode;
    productTitle;

    categoryRef;
    categoryCode;
    categoryTitle;

    primeTTC = 0;
    primeNette = 0;
    primeExtra = 0;

    period;
    months;
    days;
    startDate;
    endDate;

    nbVehicle;

    packsSelected = [];
    guaranties = [];
    reductionGroups = [];
    extras = [];
    assistances = [];

    suspension;
    status;
    statusMessage;

    fromJson = (json={})=> {
        var conract = new Contract();
        conract = json;
        return conract;
    }
    
}