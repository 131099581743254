import { EditOutlined, ExclamationCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Modal, Row, Spin, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import CreatePackRequest from "api/request/createPackRequest";
import { toast } from "react-toastify";
import ActionType from "api/acionType";
import FormRegisterPack from "forms/addPackForm";
import FormEditPack from "forms/editPackForm";
import { Card } from "reactstrap";
import PackageService from "services/packageService";
import EditPackRequest from "api/request/editPackRequest";

export default function Package(props) {

    const [form] = Form.useForm();
    const [drawerVisibility, setDrawerVisibility] = useState({
        visibility: false,
        actionType: ActionType.REGISTER
    });

    const [state, setState] = useState({
        packages: [],
        dataSource: [],
        isPackagesLoading: true,
        isPackagesTableUpdateContent: false,

        isRegistingOrEditing: false,
        updateLine: {},
        isLoad: false,
        itemId: null,

        formRegisterPack: new FormRegisterPack(),
        formEditPack: new FormEditPack()
    });

    useEffect(() => {
        getAllPackages();
    }, [])

    const getAllPackages = () => {
        setState(state => ({ ...state, isPackagesLoading: true }));
        PackageService.getAllPackages((data) => {
            console.log("data", data);
            setState(state => ({ ...state, packages: data, dataSource: data, isPackagesLoading: false, isPackagesTableUpdateContent: false }));
        }, (error) => {
            setState(state => ({ ...state, isPackagesLoading: false }));
        });

    }

    const onDrawerOpen = (pack, actionType) => {
        if (pack) {
            const formEditPack = state.formEditPack.fromJson({
                id: pack?.id,
                code: pack?.code,
                title: pack?.title,
                description: pack?.description,
            });
            setState(state => ({ ...state, formEditPack: formEditPack }))
            form.setFieldsValue({ ...form.getFieldsValue(), ...formEditPack });
        }
        else {
            form.setFieldsValue({ ...form.getFieldsValue(), ...state.formRegisterPack });
        }

        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: true, actionType: actionType }));
    }

    // TODO-OK: Ajout et edition d'une co-assurance
    const onRegisterOrEditPackSubmit = (action) => {
        setState(state => ({ ...state, isRegistingOrEditing: true }));
        form.validateFields().then((fields) => {
            switch (action) {
                case ActionType.REGISTER:
                    const _formRegisterFields = { ...state.formRegisterPack, ...fields }
                    let createPackRequest = CreatePackRequest.fromJson(_formRegisterFields);

                    PackageService.createPackage(createPackRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, formRegisterPack: new FormRegisterPack(), isRegistingOrEditing: false, isPackagesTableUpdateContent: true }));
                        getAllPackages();
                        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                        toast.success("Package ajouté avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        console.log("error", error);
                        toast.error(error);
                    });
                    break;

                case ActionType.EDIT:
                    const formEditFields = { ...state.formEditPack, ...fields };
                    let editPackRequest = EditPackRequest.fromJson(formEditFields);

                    new PackageService().editPackage(editPackRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, formEditPack: new FormEditPack(), isRegistingOrEditing: false, isUserTableUpdateContent: true }));
                        getAllPackages();
                        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                        toast.success("Package modifié avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        toast.error(error);
                    });
                    break;

                default:
                    setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                    toast.error("Une erreur est survenue, veuillez réesayer");
                    break;
            }
        }).catch((error) => {
            toast.error("Package non enregistrée");
        })
        setState(state => ({ ...state, loading: false }));
    }

    //TODO-OK: <Recherche d'un co assureur>
    // function onSearchCoInsurance(e) {
    //     const val = e?.target?.value?.toLowerCase();
    //     const temp = state.coInsurances?.filter((d)=> {
    //         return d?.code?.toLowerCase().indexOf(val) !== -1 ||
    //         d?.title?.toLowerCase().indexOf(val) !== -1 ||
    //         !val
    //     });
    //     setState(state => ({...state, dataSource: temp}))
    // }

    const onSwitchChange = (pack) => {
        const option = { path: { id: pack?.id } }
        PackageService.toggleStatus(option, (data) => {
            toast.success("Package modifié avec succès", { style: { background: 'primary' } });
            getAllPackages();
        }, (error) => {
            toast.error("Une erreur est survenue");
        })
    }

    const columns = [
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            width: 100,
            render: (code) => (
                <div style={{ fontWeight: 'bold' }}>
                    {code}
                </div>
            )
        },
        {
            title: "Titre",
            dataIndex: "title",
            key: "libelle"
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Etat",
            dataIndex: "status",
            key: "status",
            render: (item, data) => (
                <div>
                    <Switch defaultChecked={data.status} size="small" onChange={() => onSwitchChange(data)} />
                </div>
            )
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 100,
            key: "action",
            render: (item, pack) => (
                <>
                    <EditOutlined style={{ color: '#40E070', cursor: 'pointer' }} onClick={() => onDrawerOpen(pack, ActionType.EDIT)} />
                    {/* <DeleteOutlined style={{ color: '#E5AC28', cursor: 'pointer', marginLeft: 10 }} onClick={() => {  }} /> */}
                </>

            )
        }
    ];

    return (
        <Card>
            <div style={{ padding: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Liste des packages</h4>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        {/* <Input placeholder="Recherche: Code, titre" style={{ width: 350, marginRight: 10 }} onChange={onSearchCoInsurance} /> */}
                        <Input placeholder="Recherche: Marque" style={{ width: 350, marginRight: 10 }} />
                        <Button type="primary" onClick={() => onDrawerOpen(null, ActionType.REGISTER)} style={{ marginLeft: 10 }}>
                            <FileAddOutlined />
                            Ajouter un package
                        </Button>
                    </div>
                </div>
                <br />
                <Table
                    dataSource={state.dataSource}
                    columns={columns}
                    loading={state.isPackagesLoading}
                />

                {drawerVisibility.visibility && (
                    <Drawer
                        title={drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrement d'un package" : "Modifier le package"}
                        width={720}
                        onClose={() => { setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false })) }}
                        visible={drawerVisibility.visibility}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                    >
                        <Form
                            name="basic"
                            onFinish={() => onRegisterOrEditPackSubmit(drawerVisibility.actionType)}
                            layout="vertical"
                            form={form}
                            initialValues={drawerVisibility.actionType === ActionType.REGISTER ? state.formRegisterPack : state.formEditPack}
                        >

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Code du package"
                                        name="code"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Le code du package svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Le code du package" size="middle" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Nom du package"
                                        name="title"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Le nom du package svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Le nom du package" size="middle" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Description du package"
                                        name="description"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'La description svp !',
                                            },
                                        ]}
                                    >
                                        <TextArea placeholder='Description du package' size="middle" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e8e8e8',
                                    padding: '10px 16px',
                                    textAlign: 'right',
                                    left: 0,
                                    background: '#fff',
                                    borderRadius: '0 0 4px 4px',
                                }}
                            >
                                <Button
                                    style={{
                                        marginRight: 8,
                                    }}
                                    onClick={() => { setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false })); }}
                                >
                                    Annuler
                                </Button>
                                <Button type="primary" htmlType="submit" loading={state.loading}>
                                    {drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrer" : "Editer"}
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                )}

                {/**********DELETE ALERTE ****** */}
                {false && (
                    <Modal
                        title="Supprimer un partenaire !!!"
                        visible={false}
                        onOk={() => { }}
                        onCancel={() => { }}
                        okText="Confirmer"
                        cancelText="Annuler"
                    >
                        <h3 style={{ color: 'orange' }}> <ExclamationCircleOutlined />  Voulez-vous supprimer vraiment cette catégorie ?</h3>
                    </Modal>
                )}
            </div>
        </Card>
    )
}