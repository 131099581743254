export default class FormRegisterUser {
    name;
    username;
    phone;
    email;
    password;
    entitiesCode;
    entitiesTitle;
    profileRef;
    profileTitle;

    toJson(json){
        let formRegisterUser = new FormRegisterUser();
        formRegisterUser =json;
        return formRegisterUser;
    }

    fromJson(json) {
        let formRegisterUser = new FormRegisterUser();
        formRegisterUser.name = json?.name;
        formRegisterUser.username = json?.username;
        formRegisterUser.phone = json?.phone;
        formRegisterUser.email = json?.email;
        return formRegisterUser;
    }
}