import React, { useState } from "react";
import { Col, Form, Row } from "antd";
import MerchantSearchField from "../../common/fields/MerchantSearchField";
import MerchantFirstNameField from "../../common/fields/MerchantFirtsNameField";
import MerchantLastNameField from "../../common/fields/MerchantLastNameField";
import MerchantPhoneField from "../../common/fields/MerchantPhoneField";
import MerchantEmailField from "../../common/fields/MerchantEmailField";
import MerchantCodeField from "../../common/fields/MerchantCodeField";
import CustomAntdForm from "modules/components/Forms/CustomAntdForm";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import MerchantService from "services/merchantService";

const RegisterMerchant=({initialValues, subscriptionRef, onMerchantRegistered})=> {
    const [form] = Form.useForm();
    
    const [state, setState]=useState({
        isMechantRegistering: false
    })

    const registerMerchant=()=> {
        setState(state=>({...state, isMechantRegistering: true}))
        form.validateFields().then((values)=> {
            console.log(":::: register merchant value : ", values)
            const payload = {...values, subscriptionRef: subscriptionRef}
            MerchantService.attachMerchantToSubscription(payload, (data)=> {
                setState(state=>({...state, isMechantRegistering: false}))
                toast.success("Action réalisée avec succès")
                onMerchantRegistered(true)
            }, (error)=> {})
        }).catch((error)=> {
            console.log("::::: error merchant register: ", error)
            toast.error("Erreur pendant la validation");
            setState(state=>({...state, isMechantRegistering: false}))
        })
    }

    console.log("::::: merchantEmail :", initialValues)

    return (
        <CustomAntdForm form={form} initialValues={initialValues}>
            <Row gutter={[8, 8]}>
                <Col style={{alignContent: "right"}}>
                    <div className="d-flex flex-row-reverse">
                        <MerchantSearchField form={form}  />
                        
                    </div>
                </Col>
                <Col className="gutter-row" xs={12} md={12}>
                    <MerchantFirstNameField  />
                </Col>

                <Col className="gutter-row" xs={12} md={12}>
                    <MerchantLastNameField  />
                </Col>

                <Col className="gutter-row" xs={12} md={12}>
                    <MerchantPhoneField  />
                </Col>
                <Col className="gutter-row" xs={12} md={12}>
                    <MerchantEmailField />
                </Col>
                <Col className="gutter-row" xs={12} md={12} style={{ visibility: "hidden" }}>
                    <MerchantCodeField />
                </Col>
                <Col md={12}>
                    <div className="d-flex flex-row-reverse">
                        <button
                            type="button"
                            className="btn btn-primary btn-label right nexttab btn-sm"
                            onClick={() => registerMerchant()}
                            onLoad
                        >
                            <i className="bx bx-check label-icon align-middle fs-16 ms-2"></i>{" "}
                            {state?.isMechantRegistering ? (<LoadingOutlined style={{ color: '#ffffff', fontSize: 20 }} />) : (" ")} {initialValues?.merchantCode ? 'Modifier' : 'Confirmer' }
                            
                        </button>
                    </div>
                </Col>
            </Row>
        </CustomAntdForm>
        
    );
}

export default RegisterMerchant;