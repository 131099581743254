import { Col, Row } from "antd";
import React from "react";

const MDetailAssure=({assure})=> {
    return (
        <React.Fragment>
            <Row>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E', fontWeight: 'bold' }}>Code:  </p>
                        <p style={{ fonWeight: 'bold' }}>{assure?.code}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E', fontWeight: 'bold' }}>Email:  </p>
                        <p >{assure?.email}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E', fontWeight: 'bold' }}>Nom:  </p>
                        <p >{assure?.firstName} </p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E', fontWeight: 'bold' }}>Prenoms: </p>
                        <p >{assure?.lastName}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E', fontWeight: 'bold' }}>Statut:  </p>
                        <p style={{ fonWeight: 'bold' }}>{assure?.socialPosTitle}</p>
                    </div>
                </Col>
                
                {/* <Col md={12}>
                <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Adresse:  </p>
                        <p >{state?.assure?.address}</p>
                    </div>
                </Col> */}
                {/* <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Profession: </p>
                        <p >{state?.assure?.profession}</p>
                    </div>
                </Col> */}

                
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E', fontWeight: 'bold' }}>Telephone: </p>
                        <p >{assure?.phone}</p>
                    </div>
                </Col>

                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E', fontWeight: 'bold' }}>Type de piece:  </p>
                        <p >{assure?.identityCardTitle}</p>
                    </div>
                </Col>
                <Col md={12}>
                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                        <p style={{ marginRight: 10, color: '#8E8E8E', fontWeight: 'bold' }}>Numero de piece: </p>
                        <p >{assure?.identityCardNumber}</p>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default MDetailAssure;