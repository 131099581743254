import dayjs from "dayjs";
import { FormField } from "forms/FormField";
import _ from "lodash";

import moment from "moment";

export default class FormFieldsBuilder {

    static from({contract, assure, matriculation}) {
        console.log(".....contract: ", contract)
        const {packsSelected, period } = contract;
        const _packSelected = matriculation ?  packsSelected.find(packSelected => packSelected?.vehicle?.matriculation === matriculation) : _.first(packsSelected);
        const {vehicle, packResponse} = _packSelected;
        console.log(".....vehicle: ", vehicle)

        return new FormField({
            // Category
            categoryRef    : packResponse?.category?.ref,
            categoryTitle  : packResponse?.category?.title,
            
            // vehicle
            vehicleRef     : vehicle?.ref,
            transportRef   : vehicle?.transport?.ref,
            transportTitle : vehicle?.transport?.title,
            energyRef      : vehicle?.energy?.ref,
            energyTitle    : vehicle?.energy?.title,
            nbPlace        : vehicle?.totalPlace,
            fiscalPower    : vehicle?.fiscalPower,
            dateMec        : moment(  (new Date(vehicle?.dateMec).toLocaleDateString()), "DD/MM/YYYY"), //dayjs(vehicle?.dateMec),
            newValue       : vehicle?.newValue,
            marketValue    : vehicle?.marketValue,
            //Model/marque/matriculation/chassis
            customerBrand  : vehicle?.model?.forCustomer,
            brandRef       : vehicle?.model?.brand?.ref,
            brandTitle     : vehicle?.model?.brand?.title,
            modelRef       : vehicle?.model?.ref,
            modelTitle     : vehicle?.model?.title,
            
            matriculation  : vehicle?.matriculation,
            chassisNumber  : vehicle?.chassisNumber,
            // chauffeur
            driverBirthDay : vehicle?.driverBirthDay ? moment((new Date(vehicle?.driverBirthDay).toLocaleDateString()), "DD/MM/YYYY") : "",
            driverLicDeliverDate: vehicle?.driverLicdeliveryDate ? moment((new Date(vehicle?.driverLicdeliveryDate).toLocaleDateString()), "DD/MM/YYYY") : "",
            // Zone de circulation
            cityRef        : vehicle?.city?.ref,
            cityTitle      : vehicle?.city?.title,
            hasGps         : vehicle?.hasGps,
            payload        : vehicle?.payload,
            inflammable    : vehicle?.inflammable,
            // Trailer
            hasTrailer     : vehicle?.hasTrailer,
            trailerCustomerBrand: vehicle?.trailerModel?.Brand?.forCustomer,
            trailerBrandRef: vehicle?.trailerModel?.brand?.ref,
            trailerBrandTitle: vehicle?.trailerModel?.brand?.title,
            trailerCustomerModel: vehicle?.trailerModel?.forCustomer,
            trailerModelRef: vehicle?.trailerModel?.ref,
            trailerModelTitle: vehicle?.trailerModel?.title,
            trailerMatriculation: vehicle?.trailerMatriculation,
            trailerChassisNumber: vehicle?.trailerChassisNumber,
            // groupement
            hasGroup       : vehicle?.hasGroup,
            groupementTypeRef : "",
            groupementTypeTitle: "",
            groupementRef  : vehicle?.groupRef,
            groupementTitle : vehicle?.groupName,
            
            // PERIOD
            periodRef      : period?.ref,
            periodTitle    : period?.title,
            customerPeriod : period?.customerPeriod,
            customerStartDate: period?.customerStartDate,
            customerEndDate: period?.customerEndDate,
            
            // Assure
            assureCode     : assure?.code, // Utile lorsque l'assuré a été trouvé automatiquement
            assureFirstname : assure?.firstName,
            assureLastname : assure?.lastName,
            assurePhone    : assure?.phone,
            assureEmail    : assure?.email,
            assureBirthDay : assure?.birthDay,
            assureIdentityCardRef: assure?.identityCardRef,
            assureIdentityCardTitle: assure?.identityCardTitle,
            assureIdentityCardNumber: assure?.identityCardNumber,
            
            socialPosRef   : assure?.socialPosRef,
            socialPosTitle : assure?.socialPosTitle,
            
            startDate:  moment(new Date(contract?.startDate).toLocaleDateString(), "DD/MM/YYYY"),

            guaranties      : packResponse?.guaranties || [],
            customGuaranties: packResponse?.customGuaranties || [],
            reductionGroups : packResponse?.reductionGroups || []

        })
        
    }

    static fromContract({contract, assure, auto}) {
        console.log(":::: contract: ", contract)
        const {period } = contract;
        const {vehicle, guaranties, customGuaranties, reductionGroups  } = auto;
        console.log(":::: vehicule: ", vehicle)

        return new FormField({
            // Category
            categoryRef    : auto?.categoryRef,
            categoryTitle  : auto?.categoryTitle,
            
            // vehicle
            vehicleRef     : vehicle?.ref,
            transportRef   : vehicle?.transport?.ref,
            transportTitle : vehicle?.transport?.title,
            energyRef      : vehicle?.energy?.ref,
            energyTitle    : vehicle?.energy?.title,
            nbPlace        : vehicle?.totalPlace,
            fiscalPower    : vehicle?.fiscalPower,
            dateMec        : moment(  (new Date(vehicle?.dateMec).toLocaleDateString()), "DD/MM/YYYY"), //dayjs(vehicle?.dateMec),
            newValue       : vehicle?.newValue,
            marketValue    : vehicle?.marketValue,
            //Model/marque/matriculation/chassis
            customerBrand  : vehicle?.model?.forCustomer,
            brandRef       : vehicle?.model?.brand?.ref,
            brandTitle     : vehicle?.model?.brand?.title,
            modelRef       : vehicle?.model?.ref,
            modelTitle     : vehicle?.model?.title,
            
            matriculation  : vehicle?.matriculation,
            chassisNumber  : vehicle?.chassisNumber,
            // chauffeur
            driverBirthDay : vehicle?.driverBirthDay ? moment((new Date(vehicle?.driverBirthDay).toLocaleDateString()), "DD/MM/YYYY") : "",
            driverLicDeliverDate: vehicle?.driverLicdeliveryDate ? moment((new Date(vehicle?.driverLicdeliveryDate).toLocaleDateString()), "DD/MM/YYYY") : "",
            // Zone de circulation
            cityRef        : vehicle?.city?.ref,
            cityTitle      : vehicle?.city?.title,
            hasGps         : vehicle?.hasGps,
            payload        : vehicle?.payload,
            inflammable    : vehicle?.inflammable,
            // Trailer
            hasTrailer     : vehicle?.hasTrailer,
            trailerCustomerBrand: vehicle?.trailerModel?.Brand?.forCustomer,
            trailerBrandRef: vehicle?.trailerModel?.brand?.ref,
            trailerBrandTitle: vehicle?.trailerModel?.brand?.title,
            trailerCustomerModel: vehicle?.trailerModel?.forCustomer,
            trailerModelRef: vehicle?.trailerModel?.ref,
            trailerModelTitle: vehicle?.trailerModel?.title,
            trailerMatriculation: vehicle?.trailerMatriculation,
            trailerChassisNumber: vehicle?.trailerChassisNumber,
            // groupement
            hasGroup       : vehicle?.hasGroup,
            groupementTypeRef : "",
            groupementTypeTitle: "",
            groupementRef  : vehicle?.groupRef,
            groupementTitle : vehicle?.groupName,
            
            // PERIOD
            periodRef      : period?.ref,
            periodTitle    : period?.title,
            customerPeriod : period?.customerPeriod,
            customerStartDate: period?.customerStartDate,
            customerEndDate: period?.customerEndDate,
            
            // Assure
            assureCode     : assure?.code, // Utile lorsque l'assuré a été trouvé automatiquement
            assureFirstname : assure?.firstName,
            assureLastname : assure?.lastName,
            assurePhone    : assure?.phone,
            assureEmail    : assure?.email,
            assureBirthDay : assure?.birthDay,
            assureIdentityCardRef: assure?.identityCardRef,
            assureIdentityCardTitle: assure?.identityCardTitle,
            assureIdentityCardNumber: assure?.identityCardNumber,
            
            socialPosRef   : assure?.socialPosRef,
            socialPosTitle : assure?.socialPosTitle,
            
            startDate:  moment(new Date(contract?.startDate).toLocaleDateString(), "DD/MM/YYYY"),

            guaranties      : guaranties || [],
            customGuaranties: customGuaranties || [],
            reductionGroups : reductionGroups || []

        })
    }
}