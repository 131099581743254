import Area from "./area.model";

export default class City {
    id;
    ref;
    code;
    title;
    description;
    area = new Area();
    
    static fromJson = (json={})=> {
        var city = new City();
        city = json;
        return city
    }
 }