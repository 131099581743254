import { Form } from "antd";
import React from "react";
import NumberFormat from "react-number-format";

const MerchantPhoneField=({disable})=> {
    return (
        <React.Fragment>
             <Form.Item
                    label="Téléphone apporteur"
                    name="merchantPhone"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    <NumberFormat 
                        placeholder="Télephone"
                        size='medium'
                        format="+229 ##-##-##-##" 
                        mask="_"
                        className='ant-input'  
                        disabled={disable} />
                </Form.Item>
        </React.Fragment>
    )
}

export default MerchantPhoneField;