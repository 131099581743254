import React from "react";
import {  Col, Form, Select, Spin } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import { useState } from "react";
import { useEffect } from "react";
import { GroupementService } from "services/groupementService";

const GroupementField=({ groupementTypeRef, handlerFormField, disable, xs, md })=> {
    const { Option } = Select;

    const [state, setState]= useState({
        groupements: [],
        isGroupementLoading: false,
        groupementSelected: {},
    })

    useEffect(()=> {
        if(groupementTypeRef) {
            getAllGroupement(groupementTypeRef)
        }
    }, [groupementTypeRef])

    const getAllGroupement = (groupementTypeRef) => {
        
        setState((state) => ({ ...state, isGroupementLoading: true }));
        new GroupementService().readAllGroupementByType(groupementTypeRef, (data) => {
            setState(state => ({ ...state, groupements: data || [], isGroupementLoading: false }))
        }, (error) => {

        });
    }

    const onGroupementSelected = (grouepementRef) => {
        const groupementSelected = state.groupements.find((groupement) => groupement.ref === grouepementRef);
        if (groupementSelected) {
            handlerFormField({
                hasGroup: true,
                groupementRef: groupementSelected?.ref,
                groupementTitle: groupementSelected?.title
            });
        }
    }
    
    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Nom de votre groupe"
                    name="groupementRef"
                    rules={[{ required: false, message: "Champ obligatoire" }]}
                >
                    {
                        state.isGroupementLoading ? <Spin /> :
                            (<SelectWithFilter placeholder='Nom Groupement ?' onChange={(e) => { onGroupementSelected(e) }} disabled={disable}>
                                {state.groupements.map((groupement, index) => (<Option value={groupement?.ref} key={index}>{groupement?.title}</Option>))}
                            </SelectWithFilter>)
                    }

                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default GroupementField;