import { Col, Form } from "antd";
import { CATEGORY_405 } from "constantes";
import InputNumberFormatFr from "modules/components/InputNumberFormatFr/InputNumberFormatFr";
import React from "react";
const CylinderField=({categoryCode, disable, xs, md})=> {

    if(![CATEGORY_405].includes(categoryCode) ) return null;

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Valeur cylindré (cc)"
                    name="cylinder"
                    rules={[{ required: false, message: "Champ obligatoire" }]}
                >
                    <InputNumberFormatFr
                        min={1}
                        max={1000}
                        style={{ width: '100%' }}
                        placeholder='Valeur cylindré'
                        disabled={disable}
                    />
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default CylinderField;