import { Button, Form, Input, Space, Spin } from "antd";
import React, { useState } from "react";

const ChassisNumberField2=({listChassis, currentId, onConfirm, defaultValue, label="", status='error', width=350})=> {
    
    const [input, setInput]=useState(defaultValue)
    const [inputStatus, setInputStatus] = useState(status)
    const [chassisExist, setChassisExist] = useState({
        exist: false,
        errorMessage: '',
        value: "",
        isChecking: false
    });

    const checkChassis=() => {
       
        //let input = e?.target?.value;
        if(input == null || input === undefined || input==="") {
            setInputStatus('error')
            return;
        }
        console.log(":::: checkMat")

        setChassisExist(chassisExist=> ({...chassisExist, exist: false, errorMessage: '', isChecking: true}));
        const _chassisNumber = (listChassis || []).find((mat)=> mat === input);
        if(_chassisNumber) {
            setInputStatus('error')
            setChassisExist(chassisExist=> ({...chassisExist, exist: true, errorMessage: "Le matricule existe déjà", isChecking: false}));
            return;
        }

        if(onConfirm) {
            setChassisExist(chassisExist=> ({...chassisExist, exist: false, errorMessage: '', isChecking: false}));
            onConfirm(currentId, input);

        } 
        
    }

    const validate=()=> {
        checkChassis()
    }

    const onChange=(e)=> {
        console.log("::: e?.target?.value :", e?.target?.value)
        setInput(e?.target?.value)
    }

    return(
        <React.Fragment>
            <Form.Item
                label={label}
                name="chassisNumber"
                rules={[{ required: false, message: "Champ obligatoire" }]}
                style={{marginBottom:0}}
            >
                <Space direction="vertical">
                    <Space direction="horizontal">
                        <Space.Compact>
                            <Input 
                                defaultValue={defaultValue} 
                                allowClear 
                                onChange={onChange}
                                suffix={chassisExist?.isChecking ? (<Space><div>Verification...</div><Spin size='small' /></Space>) : null}
                                status={chassisExist?.exist || inputStatus ? inputStatus : ''}
                                style={{ width: width }}
                                placeholder="Numero d'Immatriculation de la remorque"
                                />
                            <Button type="primary" onClick={()=> validate()}><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i></Button>
                        </Space.Compact>
                    </Space>
                    {chassisExist?.exist ? (<span style={{ color: 'red', fontSize:11 }}>{chassisExist.errorMessage}</span>) : null}
                </Space>
            </Form.Item>
             
        </React.Fragment>
    )
}

export default ChassisNumberField2;