export default class FormEditReduction {
    code;
    categorie;
    description;
    rate;
    critere;
    groupeReduction;

    fromJson = (json = {}) => {
        var formEditReduction = new FormEditReduction();
        formEditReduction = json;
        return formEditReduction;
    }
}