import { DatePicker, Form } from 'antd';
import React from 'react';
import * as helpers from 'helpers/helpers';

const StartDateField= ({onStartDateChange, rangeDate, showTitle, size})=> {
    const [form] = Form.useForm();

    const onSubmitDateEffect = () => {
        form.validateFields().then((values) => {
            onStartDateChange(values?.startDate);
        }).catch((error) => {

        })
    }

    return (
        <React.Fragment>
            <Form
            name="basic"
            onFinish={onSubmitDateEffect}
            layout="vertical"
            form={form}
        >
            <Form.Item
                    label={showTitle ? "Date d'éffet de l'avenant" : ""}
                    name="startDate"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    <DatePicker 
                        style={{ width: '100%' }} 
                        size={size ?? 'large'} 
                        format={"DD/MM/YYYY"} 
                        //defaultValue={moment()}
                        onChange={onSubmitDateEffect}
                        disabledDate={helpers.disableDateRanges({
                            startDate: rangeDate?.startDate, 
                            endDate: rangeDate?.endDate
                        })} 
                        
                        />
                </Form.Item>
            </Form>
        </React.Fragment>
    )
}

export default StartDateField;