import { EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { Divider, Drawer, Form, Input, message, notification, Row, Space, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Card } from "reactstrap";
import { toast } from "react-toastify";
import { API_BASE_URL } from "constantes";
import SerialService from "services/SerialService";
import CustomAntdForm from "modules/components/Forms/CustomAntdForm";
import SimpleInputNumber from "views/pages/subscription/common/fields/SimpleInputNumber";
import EntitiesField from "views/pages/subscription/common/fields/EntiiesField";
import SerialNatureField from "views/pages/subscription/common/fields/SerialNatureField";
import SerialDateEffectField from "views/pages/subscription/common/fields/SerialDateEffectField";
import { fullFormatDate } from "helpers/date_helpers";
import SerialDetails from "./SerialDetails";



const SerialAttestation=(props)=> {
    const [form] = Form.useForm();
    const [serieDrawerVisibility, setSerieDrawerVisibility] = useState(false)
    const [serieDetailsDrawerVisibility, setSerieDetailsDrawerVisibility] = useState(false);
    const [serialSelected, setSerialSelected] = useState(false);
    const [state, setState] = useState({
        series: [],
        serialSelected: null,
        isSeriesLoading: false,
        loading: false,
        isSearialAdding: false,

        // Serial détail
        serialLines: [],
        isSerialLinesLoading: false
    });

    useEffect(()=>{
        getAll()
    },[])

    const getAll =()=> {
        setState(state=> ({...state, isSeriesLoading: true}))
        SerialService.getAll((data)=> {
            setState(state=> ({...state, series: data || [], isSeriesLoading: false}))
        }, (error)=> {
            setState(state=> ({...state, isSeriesLoading: false}))
        })
    }

    const columns = [
        {
            title: 'Code int.',
            dataIndex: 'nature',
            key: 'nature',
            width: 100,
            render: (_, serial)=> (<div>{serial?.entitiesCode}</div>)
        },

        {
            title: 'Titre int.',
            dataIndex: 'nature',
            key: 'nature',
            render: (_, serial)=> (<div>{serial?.entitiesTitle}</div>)
        },
        {
            title: 'N° Début',
            dataIndex: 'serialStart',
            key: 'serialStart',
            render: (_, serial)=> (<div>{`${serial?.initial}${serial?.serialStart}`}</div>)
        },
        {
            title: 'N° Fin',
            dataIndex: 'serialEnd',
            key: 'serialEnd',
            render: (_, serial)=> (<div>{`${serial?.initial}${serial?.serialEnd}`}</div>)
        },

        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total'
        },

        // {
        //     title: 'Date effet',
        //     dataIndex: 'startDate',
        //     key: 'startDate',
        //     render: (_, serial)=> fullFormatDate(serial?.startDate, "/", false)
        // },

        {
            title: 'Cat.',
            dataIndex: 'nature',
            key: 'nature',
            width: 200,
            render: (_, serial)=> (<div>{serial?.serialNature?.title}</div>)
        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 100,
            render: (_, serial)=> (
                <div style={{ display: 'flex' }}>
                    <Space>
                        <Tooltip title="Details">
                            <EyeOutlined style={{ color: '#40AEE0' }} onClick={() => onSerieDetailsDrawerOpen(serial)} />
                        </Tooltip>
                    </Space>
                </div>
            )
        },

        // {
        //     title: 'Total',
        //     dataIndex: 'total',
        //     key: 'generated',
        //     render: (item, data) => (
        //         <>
        //             {data.generated ? (<Tag color="cyan">Generée</Tag>) : (<Tag color="blue">Non generée</Tag>)}
        //         </>
        //     )
        // },
        // {
        //     title: 'Créé par',
        //     dataIndex: 'createdBy',
        //     key: 'createdBy'
        // },
        // {
        //     title: 'Modifié par',
        //     dataIndex: 'updatedBy',
        //     key: 'updatedBy'
        // },
    ];

    const validateSeriesHandler=()=> {
        setState(state=> ({...state, isSearialAdding: true}))
        form.validateFields().then((values)=> {
            console.log("::: Values ", values)
            const addSerialRequest = {
                entitiesCode: values?.entitiesCode,
                serialStart: values?.serialStart,
                serialEnd: values?.serialEnd,
                natureCode: values?.natureCode,
                startDate: values?.startDate
            }
            SerialService.registerSerial(addSerialRequest, (data)=> {
                toast.success("L'action a été effectuée avec succès")
                setState(state=> ({...state, isSearialAdding: false}))
                setSerieDrawerVisibility(false)
                getAll()
            }, (error)=> {
                toast.error("Echec pendant l'ajout de serie")
                setState(state=> ({...state, isSearialAdding: false}))
            })
        })
    }

    const onSerieDetailsDrawerOpen=(serial)=> {
        setSerialSelected(serial);
        setSerieDetailsDrawerVisibility(true)
    }

    return (
        <React.Fragment>
            <Card>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <div style={{ margin: 10 }}>
                        <Button color="primary" className="btn-label right" onClick={()=> setSerieDrawerVisibility(true)}> <i className=" bx bx-plus label-icon align-middle fs-16 ms-2"></i> Ajouter nouvelle series</Button>
                    </div>
                </div>
                <div style={{padding:20}}>
                    <div>
                        <Table columns={columns} dataSource={state.series} loading={state?.isSeriesLoading}/>
                    </div>
                </div>
            </Card>

            { serieDrawerVisibility && (
                <Drawer
                    title= {`Nouvelle séries`}
                    width={800}
                    onClose={() => {
                        setSerieDrawerVisibility(false); 
                        getAll()
                    }}
                    open={serieDrawerVisibility}
                    bodyStyle={{
                        paddingBottom: 80,
                    }}
                    footer={(<div style={{ display: 'flex', justifyContent:'end' }}><Button onClick={()=> validateSeriesHandler()}>Valider</Button></div>)}
                >
                    <CustomAntdForm form={form} >
                        <Row gutter={8} >
                        <EntitiesField md={12} />
                        <SerialNatureField md={12}/>
                        <SimpleInputNumber label={"Debut série"} name="serialStart" placeholder={"Début série"} md={12}/> 
                        <SimpleInputNumber label={"Fin série"} name="serialEnd"  placeholder={"Fin série"} md={12}/> 
                        <SerialDateEffectField label={"Date effet"} md={12} />
                        
                        </Row>
                    </CustomAntdForm>

                </Drawer>
            )}
            
            
            {serieDetailsDrawerVisibility && (<SerialDetails serial={serialSelected} setSerieDetailsDrawerVisibility={setSerieDetailsDrawerVisibility}/>)}
            
        </React.Fragment>
    )
}



export default SerialAttestation