import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class TransactionService {
    notify(transactionID, sCallBack, eCallBack) {
        let options = {
            path: {
                transactionId: transactionID
            }
        }
        ApiRoutes.notifytransactionResource.resourceOptions = Object.assign({}, ApiRoutes.notifytransactionResource.resourceOptions, options);
        exeRequest(ApiRoutes.notifytransactionResource, {}, sCallBack, eCallBack)
    }
}