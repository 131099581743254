import React from 'react';

import { InputNumber } from 'antd';

export default function InputNumberFormatFr({ ...restProps }) {
    return (
        <InputNumber
            placeholder={"Montant"}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            parser={value => value.replace(/\$\s?|( *)/g, '')}
            style={{ width: '100%' }}
            size="large"
            {...restProps}
            
        />
    )
}
