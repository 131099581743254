import {  Form, Input } from "antd";
import React from "react";

const RedevanceField=({disable})=> {
    return (
        <React.Fragment>
            <Form.Item
                name="rds"
                rules={[{ required: true, message: "Champ obligatoire" }]}
            >
                <Input size='medium' placeholder='Redevance spéciale' disabled={disable} addonBefore={"Redevance speciale :"}/>
            </Form.Item>
        </React.Fragment>
    )
}

export default RedevanceField;