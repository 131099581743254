import { useEffect, useState } from 'react';
import TransportService from 'services/transportService';

export function useTypeVehiclesOfCategory(categoryRef) {
    const [state, setState] = useState({
        isLoading: true,
        data: [],
        defaultTypeVehicle: null,
    });

    useEffect(() => {
        getTypeVehiclesOfCategory(categoryRef)
    }, [categoryRef]);

    function getTypeVehiclesOfCategory(categoryRef) {
        setState(prevState => ({
            ...prevState,
            isLoading: true
        }));

        (new TransportService()).getByCategoryRef(categoryRef, (data) => {
            const typeVehicles = data || [];
            const defaultTypeVehicle = typeVehicles.find(transport => transport?.isDefault === true);

            setState(prevState => ({
                ...prevState,
                data: typeVehicles,
                defaultTypeVehicle,
                isLoading: false
            }));

        }, (error) => {
            setState(prevState => ({
                ...prevState,
                isLoading: false
            }));
        });
    }

    return {
        ...state,
        getTypeVehiclesOfCategory
    }
}