import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class SeatService {
    
    getByCategoryRef(categoryRef, sCallBack, eCallBack) {
        let options = {
            path: {categoryRef: categoryRef}
        }
        ApiRoutes.seatResource.resourceOptions = Object.assign({}, ApiRoutes.seatResource.resourceOptions, options);
        exeRequest(ApiRoutes.seatResource, {}, sCallBack, eCallBack)
    }
}