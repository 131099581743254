import { EditOutlined, ExclamationCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Modal, notification, Row, Spin, Table,Select } from "antd";
import React, { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import ReductionService from "services/reductionService";
import GroupeReductionService from "services/groupeReductionService";
import CreateReductionRequest from "api/request/createReductionRequest";
import CreateGroupeReductionRequest from "api/request/createGroupeReductionRequest";
import { toast } from "react-toastify";
import ActionType from "api/acionType";
import FormRegisterReduction from "forms/addReductionForm";
import FormEditReduction from "forms/editReductionForm";
import FormRegisterGroupeReduction from "forms/addGroupeReductionForm";
import FormEditGroupeReduction from "forms/editGroupeReductionForm";
import { Card } from "reactstrap";

export default function Reduction(props) {
    let formRegisterReduction = new FormRegisterReduction();
    let formEditReduction = new FormEditReduction();
    let formRegisterGroupeReduction = new FormRegisterGroupeReduction();
    let formEditGroupeReduction = new FormEditGroupeReduction();
    const [form] = Form.useForm();
    const [reductionDrawerVisibility, setReductionDrawerVisibility] = useState({
        visibility: false,
        actionType: ActionType.REGISTER
    });
    const [groupeReductionDrawerVisibility, setGroupeReductionDrawerVisibility] = useState({
        visibility: false,
        actionType: ActionType.REGISTER
    });

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg
        });
    };

    useEffect(() => {
        getAllGroupeReductions();
        getAllReductions();
    }, [])

    const [state, setState] = useState({
        reductionGroup: [],
        dataSourceGroupeReduction: [],
        isReductionsGroupLoading: true,

        reductions: [],
        dataSourceReductions: [],
        isReductionLoading: true,

        loading: false,
        updateLine: {},
        isLoad: false,
        itemId: null,
    });

    const getAllReductions = () => {
        setState(state=> ({...state, isReductionLoading: true}))
        ReductionService.readAllReductions((data) => {
            console.log(":::: Reduction : " + data); 
            setState(state => ({ ...state, reductions: data || [], dataSourceReductions: data || [], isReductionLoading: false }));
        }, (error) => { 
            toast.error("Erreur pednant le chargement des reductions")
            setState(state=> ({...state, isReductionLoading: false}))
        });

    }

    const getAllGroupeReductions = () => {
        setState(state=> ({...state, isReductionsGroupLoading: true}))
        
         ReductionService.readAllReductionsGroup((data) => {
            console.log(":::: Reduction group : " + data); 
            setState(state => ({ ...state, reductionGroup: data || [], dataSourceGroupeReduction: data || [], isReductionsGroupLoading: false }));
        }, (error) => {
            toast.error("Erreur pednant le chargement des groupes de reduction")
            setState(state=> ({...state, isReductionsGroupLoading: false}))
         });

    }

    const onGroupeReductionDrawerOpen = (value, actionType) => {
        if (value) {
            formEditGroupeReduction = formEditGroupeReduction.fromJson({
                code: value?.code,
                title: value?.title,
                description: value?.description,
            });

            form.setFieldsValue({ ...form.getFieldsValue(), ...formEditGroupeReduction });
        }
        else {
            form.setFieldsValue({ ...form.getFieldsValue(), ...formRegisterGroupeReduction });
        }

        setGroupeReductionDrawerVisibility(groupeReductionDrawerVisibility => ({ ...groupeReductionDrawerVisibility, visibility: true, actionType: actionType }));
    }

    const onReductionDrawerOpen = (value, actionType) => {
        if (value) {
            formEditReduction = formEditReduction.fromJson({
                code: value?.code,
                title: value?.title,
                description: value?.description,
                rate: value?.rate,
                critere: value?.critere,
                groupeReduction: value?.groupeReduction,
            });

            form.setFieldsValue({ ...form.getFieldsValue(), ...formEditReduction });
        }
        else {
            form.setFieldsValue({ ...form.getFieldsValue(), ...formRegisterReduction });
        }

        setReductionDrawerVisibility(reductionDrawerVisibility => ({ ...reductionDrawerVisibility, visibility: true, actionType: actionType }));
    }

    // TODO-OK: Ajout et edition d'un groupe de réduction
    const onRegisterOrEditGroupeReductionSubmit = (action) => {
        setState(state => ({ ...state, loading: true }));
        form.validateFields().then((fields) => {
            switch (action) {
                case ActionType.REGISTER:
                    let formRegisterFields = formRegisterGroupeReduction.toJson(fields);
                    let createGroupeReductionRequest = (new CreateGroupeReductionRequest()).fromJson(formRegisterFields);

                    (new GroupeReductionService()).createGroupeReduction(createGroupeReductionRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, isGroupeReductionTableUpdateContent: true }));
                        getAllGroupeReductions();
                        setGroupeReductionDrawerVisibility(groupeReductionDrawerVisibility => ({ ...groupeReductionDrawerVisibility, visibility: false }));
                        toast.success("Groupe de réduction ajouté avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        console.log("error", error);
                        toast.error(error);
                    });
                    break;

                case ActionType.EDIT:
                    let formEditFields = { ...formEditReduction, ...fields };
                    let editGroupeReductionRequest = (new CreateGroupeReductionRequest()).fromJson(formEditFields);

                    new GroupeReductionService().editGroupeReduction({ query: { code: form.getFieldValue("code") } }, editGroupeReductionRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, isGroupementLoading: true, isUserTableUpdateContent: true }));
                        getAllGroupeReductions();
                        setGroupeReductionDrawerVisibility(groupeReductionDrawerVisibility => ({ ...groupeReductionDrawerVisibility, visibility: false }));
                        toast.success("Groupe de réduction modifié avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        toast.error(error);
                    });
                    break;

                default:
                    setGroupeReductionDrawerVisibility(groupeReductionDrawerVisibility => ({ ...groupeReductionDrawerVisibility, visibility: false }));
                    toast.error("Une erreur est survenue, veuillez réesayer");
                    break;
            }
        }).catch((error) => {
            toast.error("Groupe de réduction non enregistré");
        })
        setState(state => ({ ...state, loading: false }));
    }

    // TODO-OK: Ajout et edition d'une réduction
    const onRegisterOrEditReductionSubmit = (action) => {
        setState(state => ({ ...state, loading: true }));
        form.validateFields().then((fields) => {
            switch (action) {
                case ActionType.REGISTER:
                    let formRegisterFields = formRegisterReduction.toJson(fields);
                    let createReductionRequest = (new CreateReductionRequest()).fromJson(formRegisterFields);

                    (new ReductionService()).createReduction(createReductionRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, isReductionTableUpdateContent: true }));
                        getAllReductions();
                        setReductionDrawerVisibility(reductionDrawerVisibility => ({ ...reductionDrawerVisibility, visibility: false }));
                        toast.success("Réduction ajoutée avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        console.log("error", error);
                        toast.error(error);
                    });
                    break;

                case ActionType.EDIT:
                    let formEditFields = { ...formEditReduction, ...fields };
                    let editReductionRequest = (new CreateReductionRequest()).fromJson(formEditFields);

                    new ReductionService().editReduction({ query: { code: form.getFieldValue("code") } }, editReductionRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, isGroupementLoading: true, isUserTableUpdateContent: true }));
                        getAllReductions();
                        setReductionDrawerVisibility(reductionDrawerVisibility => ({ ...reductionDrawerVisibility, visibility: false }));
                        toast.success("Réduction modifiée avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        toast.error(error);
                    });
                    break;

                default:
                    setReductionDrawerVisibility(reductionDrawerVisibility => ({ ...reductionDrawerVisibility, visibility: false }));
                    toast.error("Une erreur est survenue, veuillez réesayer");
                    break;
            }
        }).catch((error) => {
            toast.error("Réduction non enregistrée");
        })
        setState(state => ({ ...state, loading: false }));
    }

    //TODO-OK: <Recherche d'un co assureur>
    // function onSearchCoInsurance(e) {
    //     const val = e?.target?.value?.toLowerCase();
    //     const temp = state.coInsurances?.filter((d)=> {
    //         return d?.code?.toLowerCase().indexOf(val) !== -1 ||
    //         d?.title?.toLowerCase().indexOf(val) !== -1 ||
    //         !val
    //     });
    //     setState(state => ({...state, dataSource: temp}))
    // }

    const groupeReductionColumns = [

        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            render: (code) => (
                <div style={{ fontWeight: 'bold' }}>
                    {code}
                </div>
            )
        },
        {
            title: "Titre",
            dataIndex: "title",
            key: "title",

        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",

        },
        {
            title: "Statut",
            dataIndex: "status",
            key: "status",

        },
        {
            title: "Actions",
            dataIndex: "action",
            key: "action",
            render: (item, reductionGroup) => (
                <>
                    {/* <EditOutlined style={{ color: '#40E070', cursor: 'pointer', marginRight: 50 }} /> */}
                    <EditOutlined style={{ color: '#40E070', cursor: 'pointer', marginRight: 50 }} onClick={() => onGroupeReductionDrawerOpen(reductionGroup, ActionType.EDIT)} />
                </>
            )
        }
    ];

    const reductionColumns = [

        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            render: (code) => (
                <div style={{ fontWeight: 'bold' }}>
                    {code}
                </div>
            )
        },
        {
            title: "Titre",
            dataIndex: "title",
            key: "title",

        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",

        },
        {
            title: "Groupe Réduction",
            dataIndex: "groupe",
            key: "groupe",
            render: (item, reduction)=> {
                return (<span>{reduction?.reductionGroup?.title}</span>)
            }

        },
        {
            title: "Critère",
            dataIndex: "critere",
            key: "critere",
            render: (item, reduction)=> {
                return (<span>{reduction?.criterion}</span>)
            }

        },
        {
            title: "Statut",
            dataIndex: "status",
            key: "status",

        },
        {
            title: "Actions",
            dataIndex: "action",
            key: "action",
            render: (item, reduction) => (
                <>
                    <EditOutlined style={{ color: '#40E070', cursor: 'pointer', marginRight: 50 }} />
                    {/* <EditOutlined style={{ color: '#40E070', cursor: 'pointer', marginRight: 50 }} onClick={() => onDrawerOpen(category, ActionType.EDIT)} /> */}
                </>
            )
        }
    ];

    return (
        <>
            <Card>
                <div style={{ padding: 20 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Groupe de réduction</h4>

                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button type="primary" onClick={() => onGroupeReductionDrawerOpen(null, ActionType.REGISTER)} style={{ marginLeft: 10 }}>
                                <FileAddOutlined />
                                Ajouter un groupe de réduction
                            </Button>
                        </div>
                    </div>
                    <br />
                    <Table
                        dataSource={state.dataSourceGroupeReduction}
                        columns={groupeReductionColumns}
                        loading={state.isReductionsGroupLoading}
                    />

                    {groupeReductionDrawerVisibility.visibility && (
                        <Drawer
                            title={groupeReductionDrawerVisibility.actionType === ActionType.REGISTER ? "Enregistrement d'une catégorie" : "Modifier la catégorie"}
                            width={720}
                            onClose={() => { setGroupeReductionDrawerVisibility(groupeReductionDrawerVisibility => ({ ...groupeReductionDrawerVisibility, visibility: false })) }}
                            visible={groupeReductionDrawerVisibility.visibility}
                            bodyStyle={{
                                paddingBottom: 80,
                            }}
                        >
                            <Form
                                name="basic"
                                onFinish={() => onRegisterOrEditGroupeReductionSubmit(groupeReductionDrawerVisibility.actionType)}
                                layout="vertical"
                                form={form}
                                initialValues={formRegisterGroupeReduction}
                            >

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Code du groupe de réduction"
                                            name="code"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Le code du groupe de réduction svp !',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Le code du groupe de réduction" size="middle" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Nom du groupe de réduction"
                                            name="title"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Le nom du groupe de réduction svp !',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Le nom du groupe de réduction" size="middle" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Description du groupe de réduction"
                                            name="description"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'La description svp !',
                                                },
                                            ]}
                                        >
                                            <TextArea placeholder='Description du groupe de réduction' size="middle" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        width: '100%',
                                        borderTop: '1px solid #e8e8e8',
                                        padding: '10px 16px',
                                        textAlign: 'right',
                                        left: 0,
                                        background: '#fff',
                                        borderRadius: '0 0 4px 4px',
                                    }}
                                >
                                    <Button
                                        style={{
                                            marginRight: 8,
                                        }}
                                        onClick={() => { setGroupeReductionDrawerVisibility(groupeReductionDrawerVisibility => ({ ...groupeReductionDrawerVisibility, visibility: false })); }}
                                    >
                                        Annuler
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={state.loading}>
                                        {groupeReductionDrawerVisibility.actionType === ActionType.REGISTER ? "Enregistrer" : "Editer"}
                                    </Button>
                                </div>
                            </Form>
                        </Drawer>
                    )}


                    {/**********DELETE ALERTE ****** */}
                    {false && (
                        <Modal
                            title="Supprimer un partenaire !!!"
                            visible={false}
                            onOk={() => { }}
                            onCancel={() => { }}
                            okText="Confirmer"
                            cancelText="Annuler"
                        >
                            <h3 style={{ color: 'orange' }}> <ExclamationCircleOutlined />  Voulez-vous supprimer vraiment cette catégorie ?</h3>
                        </Modal>
                    )}
                </div>
            </Card>

            <Card>
                <div style={{ padding: 20 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Liste des réductions</h4>

                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            {/* <Input placeholder="Recherche: Code, titre" style={{ width: 350, marginRight: 10 }} onChange={onSearchCoInsurance} /> */}
                            <Input placeholder="Recherche: Réduction" style={{ width: 350, marginRight: 10 }} />
                            <Button type="primary" onClick={() => onReductionDrawerOpen(null, ActionType.REGISTER)} style={{ marginLeft: 10 }}>
                                <FileAddOutlined />
                                Ajouter une réduction
                            </Button>
                        </div>
                    </div>
                    <br />
                    <Table
                        dataSource={state.dataSourceReductions}
                        columns={reductionColumns}
                        loading={state.isReductionLoading}
                        />

                    {reductionDrawerVisibility.visibility && (
                        <Drawer
                            title={reductionDrawerVisibility.actionType === ActionType.REGISTER ? "Enregistrement d'une réduction" : "Modifier la réduction"}
                            width={720}
                            onClose={() => { setReductionDrawerVisibility(reductionDrawerVisibility => ({ ...reductionDrawerVisibility, visibility: false })) }}
                            visible={reductionDrawerVisibility.visibility}
                            bodyStyle={{
                                paddingBottom: 80,
                            }}
                        >
                            <Form
                                name="basic"
                                onFinish={() => onRegisterOrEditReductionSubmit(reductionDrawerVisibility.actionType)}
                                layout="vertical"
                                form={form}
                                initialValues={formRegisterReduction}
                            >

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Groupe de réduction"
                                            name="groupeReduction"
                                        >
                                            <Select defaultValue="Test" options={[
                                                {
                                                    value: "option 1",
                                                    label: "Option 1"
                                                },
                                                {
                                                    value: "option 2",
                                                    label: "Option 2"
                                                },
                                                {
                                                    value: "option 3",
                                                    label: "Option 3"
                                                },
                                            ]} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Code de la réduction"
                                            name="code"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Le code de la réduction svp !',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Le code de la réduction" size="middle" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Titre de la réduction"
                                            name="title"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Le titre de la réduction svp !',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Le titre de la réduction" size="middle" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Description de la réduction"
                                            name="description"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'La description svp !',
                                                },
                                            ]}
                                        >
                                            <TextArea placeholder='Description de la réduction' size="middle" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Taux de la réduction"
                                            name="rate"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Le taux de la réduction svp !',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Le taux de la réduction" size="middle" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Critère de la réduction"
                                            name="critere"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Le critère svp !',
                                                },
                                            ]}
                                        >
                                            <TextArea placeholder='Critère de la réduction' size="middle" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        width: '100%',
                                        borderTop: '1px solid #e8e8e8',
                                        padding: '10px 16px',
                                        textAlign: 'right',
                                        left: 0,
                                        background: '#fff',
                                        borderRadius: '0 0 4px 4px',
                                    }}
                                >
                                    <Button
                                        style={{
                                            marginRight: 8,
                                        }}
                                        onClick={() => { setReductionDrawerVisibility(reductionDrawerVisibility => ({ ...reductionDrawerVisibility, visibility: false })); }}
                                    >
                                        Annuler
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={state.loading}>
                                        {reductionDrawerVisibility.actionType === ActionType.REGISTER ? "Enregistrer" : "Editer"}
                                    </Button>
                                </div>
                            </Form>
                        </Drawer>
                    )}


                    {/**********DELETE ALERTE ****** */}
                    {false && (
                        <Modal
                            title="Supprimer un partenaire !!!"
                            visible={false}
                            onOk={() => { }}
                            onCancel={() => { }}
                            okText="Confirmer"
                            cancelText="Annuler"
                        >
                            <h3 style={{ color: 'orange' }}> <ExclamationCircleOutlined />  Voulez-vous supprimer vraiment cette catégorie ?</h3>
                        </Modal>
                    )}
                </div>
            </Card>
        </>
    )
}