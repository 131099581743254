import React, { useEffect, useState } from 'react';
import CustomAntdForm from 'modules/components/Forms/CustomAntdForm';
import { Form, Row} from 'antd';
import Avenant from 'api/model/avenant.model';
import { CategoryField, CityField, DriverLicField, EnergyField, FiscalPowerField, GpsField, MarketValueField, MecField, NewValueField, PeriodField, PlaceField, SocialPositionField, TransportField } from '../../../../common/fields';
import AvenantService from 'services/avenantService';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';
import {  CATEGORY_405 } from 'constantes';
import PayloadField from 'views/pages/subscription/common/fields/PayloadField';
import InflammableField from 'views/pages/subscription/common/fields/InflammableField';
import CylinderField from 'views/pages/subscription/common/fields/CylinderField';
import DriverBirthField from 'views/pages/subscription/common/fields/DriverBirthdayField';
import FooterDrawer from '../../../common/FooterDrawer';

const FInputQuotation =({basket, goToNextStep, setOpenVehicleDrawer})=> {

    const { updateFormField } = useBasket();

    const [form] = Form.useForm();
    const [maxMarketValue, setMaxMarketValue] = useState(null);
    const [state, setState] = useState({
        // AVENANT
        defaultAvenant: new Avenant(),
        isDefaultAvenantLoading: true,
        customerStartDate: null,
        customerEndDate: null
    })
    
    useEffect(() => {

        console.log(":::: basket: FinputQutotation : ", basket);
        (new AvenantService()).getDefaultAvenant((data)=> {
            setState(state => ({ ...state, defaultAvenant: data, isDefaultAvenantLoading: false }));
        }, (error)=> {
        });
    }, [])

    // <Subimit form>
    const goToPacksStep=()=> {
        form.validateFields()
            .then(values => {
                // Traitement des inputs
                updateFormField(values);
                //updateQuotationRequest();
                goToNextStep(null)
            })
            .catch(errorInfo => { 
                //setErrorBag({error: true, errorMessage: errorInfo}); 
             });
    }

    return (
        <React.Fragment>
            <div className='d-flex flex-column justify-content-between' >
                <Row gutter={8}>
                    <CustomAntdForm form={form} initialValues={basket?.formField}>
                        <Row gutter={12} >
                            <CategoryField form={form} categoryRef={basket?.formField?.categoryRef} handlerFormField={updateFormField} xs={12} md={8}/>
                            <TransportField form={form} categoryCode={basket?.formField?.categoryCode} categoryRef={basket?.formField?.categoryRef} handlerFormField={updateFormField} xs={12} md={8}/>
                            {basket?.formField.motor !== 'NEANT' ? (<PlaceField categoryCode={basket?.formField?.categoryCode} xs={12} md={8}/>) : null}
                            <EnergyField categoryCode={basket?.formField?.categoryCode} handlerFormField={updateFormField} xs={12} md={8} />
                            <MecField categoryCode={basket?.formField?.categoryCode} xs={12} md={8}/>
                            {basket?.formField.motor !== 'NEANT' ? (<CityField categoryCode={basket?.formField?.categoryCode} handlerFormField={updateFormField} xs={12} md={8} />) : null}
                            {basket?.formField.motor !== 'NEANT' ? (<FiscalPowerField categoryCode={basket?.formField?.categoryCode} form={form} handlerFormField={updateFormField} defaultValue={ ["TRIC", "TRI"].includes(basket?.formField?.transportCode) ? 2 : null } xs={12} md={8} />) : null}
                            <CylinderField categoryCode={basket?.formField?.categoryCode} xs={12} md={8}/>
                            <NewValueField categoryCode={basket?.formField?.categoryCode}  onBlur={setMaxMarketValue} xs={12} md={8} />
                            <MarketValueField categoryCode={basket?.formField?.categoryCode} max={maxMarketValue} xs={12} md={8}/>
                            <GpsField xs={12} md={8}/>
                            <PayloadField categoryCode={basket?.formField?.categoryCode} xs={12} md={8}/>
                            <InflammableField categoryCode={basket?.formField?.categoryCode} xs={12} md={8}/>
                            <SocialPositionField categoryCode={basket?.formField?.categoryCode} handlerFormField={updateFormField} disable={true} xs={12} md={8}/>
                            <PeriodField handlerFormField={updateFormField} month={[CATEGORY_405].includes(basket?.formField?.categoryCode) ? 12 : null} disable={true} xs={12} md={8} />
                            <DriverBirthField categoryCode={basket?.formField?.categoryCode} xs={12} md={8}/>
                            <DriverLicField categoryCode={basket?.formField?.categoryCode} xs={12} md={8}/>
                        </Row>
                    </CustomAntdForm>
                    
                </Row>
            </div>
            <FooterDrawer 
                onConfirm={()=> goToPacksStep()} 
                confirmText={'AFFICHER LES PACKS'}
                onCancel={()=> setOpenVehicleDrawer(false)} />
            
        </React.Fragment>
    );
}

export default FInputQuotation;