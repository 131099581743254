export default class FormRegisterModel {
    code;
    title;
    description;
    // brandRef;

    toJson = (json = {}) => {
        var formRegisterModel = new FormRegisterModel();
        formRegisterModel = json;
        return formRegisterModel;
    }
}