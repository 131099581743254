export default class EditPackRequest {
    id;
    code;
    title;
    description;

    static fromJson = (json = {}) => {
        var createPackRequest = new EditPackRequest();
        createPackRequest = json;
        return createPackRequest;
    }
}