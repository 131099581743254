import React from "react";
import {  Col, Form, Select, Spin } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import PositionService from "services/positionService";
import { useState } from "react";
import { useEffect } from "react";
import { CATEGORY_403, CATEGORY_404, CATEGORY_405, CATEGORY_406, CATEGORY_407, CATEGORY_408, CATEGORY_409, CATEGORY_410 } from "constantes";

const SocialPositionField=({categoryCode, handlerFormField, onSocialSelected, suffixIcon, size='large', formItemStyle={label: "Status socio-professionnel"}, disable, activateCol=true, xs, md})=> {
    const { Option } = Select;

    const [state, setState]= useState({
        // SOCIAL POSITION
        socialPositions: [],
        isSocialPositionsLoading: true,
    })

    useEffect(()=> {
        getSocialPositions()
    }, [])

    const getSocialPositions = () => {
        (new PositionService()).getAll((data) => {
            setState(state => ({ ...state, socialPositions: data, isSocialPositionsLoading: false }))
        }, (error) => {
            //setState(state=> ({...state, errorBag: {error: true, errorMessage: errorInfo} })); 
        })
    }

    const onSocialPositionSelected = (socialPosRef) => {
        const socialPosSelected = state.socialPositions.find((socialPosition) => socialPosition.ref === socialPosRef);
        if(socialPosSelected) {
            handlerFormField({
                socialPosRef: socialPosSelected?.ref,
                socialPosTitle: socialPosSelected?.title
            })
            if(onSocialSelected){
                onSocialSelected(socialPosSelected)
            }
            
        }
    }

    if([CATEGORY_403, CATEGORY_404, CATEGORY_405, CATEGORY_406, CATEGORY_407, CATEGORY_408, CATEGORY_409, CATEGORY_410].includes(categoryCode)) return null;

    const formItem = (
        <Form.Item
            name="socialPosRef"
            rules={[{ required: true, message: "Champ obligatoire" }]}
            {...formItemStyle}
        >
            {state.isSocialPositionsLoading ? <Spin /> :
                    (<SelectWithFilter 
                        placeholder='Choisissez la formule' 
                        onChange={(e) => { onSocialPositionSelected(e) }} 
                        disabled={disable} 
                        size={size}
                        suffixIcon={suffixIcon}
                        >
                        {state.socialPositions.map((socialPosition, index) => (<Option value={socialPosition?.ref} key={index}>{socialPosition?.title}</Option>))}
                    </SelectWithFilter>)}

        </Form.Item>
    )
    return (
        <React.Fragment>
            { activateCol ? (<Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>{ formItem} </Col>) : formItem}
        </React.Fragment>
    )
}

export default SocialPositionField;