import { WarningOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Row, Card, CardBody, Col } from 'reactstrap';
import DashboardService from 'services/dashboardService';

const dashboadWidgets = [
    {
        id: 2,
        name:"autos",
        cardColor: "secondary",
        label: "Souscription auto",
        badge: "ri-arrow-right-down-line",
        badgeClass: "danger",
        percentage: "-3.57",
        counter: "0",
        /* link: "Details", */
        bgcolor: "light",
        icon: "bx bx-car",
        decimals: 0,
        prefix: "",
        separator: " ",
        suffix: "",
        badgebgColor: "info"
    },
    {
        id: 1,
        name:"flotte",
        cardColor: "primary",
        label: "Souscription flotte",
        badge: "ri-arrow-right-up-line",
        badgeClass: "success",
        percentage: "+16.24",
        counter: "0",
        /* link: "Details", */
        bgcolor: "light",
        icon: "bx bx-car",
        decimals: 0,
        prefix: "",
        separator: " ",
        suffix: "",
        badgebgColor: "success"
    },

    {
        id: 3,
        name:"groupement",
        cardColor: "success",
        label: "Souscription groupement",
        badge: "ri-arrow-right-up-line",
        badgeClass: "success",
        percentage: "+29.08",
        counter: "0",
        /* link: "Details", */
        bgcolor: "light",
        icon: "bx bx-car",
        decimals: 0,
        prefix: "",
        separator: " ",
        suffix: "",
        badgebgColor: "warning"
    },
    {
        id: 4,
        name:"assures",
        cardColor: "info",
        label: "Assurés",
        badgeClass: "muted",
        percentage: "+0.00",
        counter: "0",
        /* link: "Details", */
        bgcolor: "light",
        icon: "bx bx-id-card",
        decimals: 0,
        prefix: "",
        separator: " ",
        suffix: "",
        badgebgColor: "primary"
    },
];

const HeaderDashboard=()=> {

    const [state, setState] = useState({
        isDashboadStatLoading: true,
        errorStatLoading: false,
        dashboadStatus: dashboadWidgets
    })
    
    useEffect(()=> {
        DashboardService.readStats((data)=> {
            let widgets = [];
            for (const widget of dashboadWidgets) {
                switch(widget?.name) {
                    case 'autos':
                        widgets.push({...widget, counter: data?.tsubscription ?? "0"})
                    break;
                    case 'flotte':
                        widgets.push({...widget, counter: data?.tflotte ?? "0"})
                    break;
                    case 'groupement':
                        widgets.push({...widget, counter: data?.tgroupement ?? "0"})
                    break;
                    case 'assures':
                        widgets.push({...widget, counter: data?.tassures ?? "0"})
                    break;

                    default:

                }
            }

            //console.log(":::: widgets ", widgets)

            setState(state=> ({...state, dashboadStatus: [...widgets], isDashboadStatLoading: false}))
        }, (error)=> {
            setState(state=> ({...state, isDashboadStatLoading: false, errorStatLoading: true}))
        })
    }, [])

    return (
        <React.Fragment>
            <Row>
                {(state?.dashboadStatus || []).map((item, key) => (  
                    <Col xl={3} md={4} key={key}>
                        <Card className={"card-animate mr-10 bg-" + item.badgebgColor} >
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <p className="text-uppercase fw-medium  text-truncate mb-0 text-light fs-5"  >{item.label}</p>
                                    </div>
                                    {/* <div className="flex-shrink-0">
                                        <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                                            {item.badge ? <i className={"fs-13 align-middle " + item.badge}></i> : null} {item.percentage} %
                                        </h5>
                                    </div> */}
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-4">
                                    <div>
                                        <h4 className="fs-22 fw-semibold ff-secondary mb-4"><span className="counter-value text-light" data-target="559.25">
                                            { state.isDashboadStatLoading ? (<Spin />) : state.errorStatLoading ? (<WarningOutlined />) : (
                                                <CountUp
                                                    start={0}
                                                    prefix={item.prefix}
                                                    suffix={item.suffix}
                                                    separator={item.separator}
                                                    end={item?.counter}
                                                    decimals={item.decimals}
                                                    duration={2}
                                                />
                                            ) }
                                        </span></h4>
                                        <Link to="#" className="text-decoration-underline text-light">{item.link}</Link>
                                    </div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className={"avatar-title rounded fs-3 bg-soft-" + item.bgcolor}>
                                            <i className={`text-${item.bgcolor} ${item.icon}`}></i>
                                        </span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>)
                )}
            </Row>
        </React.Fragment>
    );
   
}

export default HeaderDashboard;