import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class UserService {
    getAllUser(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.usersResource, {}, sCallBack, eCallBack)
    }

    createUser(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.createUserResource, payload, sCallBack, eCallBack);
    }

    editUser(options,payload, sCallBack, eCallBack) {
        ApiRoutes.editUserResource.resourceOptions = Object.assign({}, ApiRoutes.editUserResource.resourceOptions, options);
        exeRequest(ApiRoutes.editUserResource, payload, sCallBack, eCallBack);
    }

    editUserStatus(options, sCallBack, eCallBack) {
        ApiRoutes.editStatusUserResource.resourceOptions = Object.assign({}, ApiRoutes.editStatusUserResource.resourceOptions, options);
        exeRequest(ApiRoutes.editStatusUserResource, {}, sCallBack, eCallBack);
    }
}