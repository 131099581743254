export default class FormRegisterPack {
    code;
    title;
    description;

    static toJson = (json = {}) => {
        var formRegisterPack = new FormRegisterPack();
        formRegisterPack = json;
        return formRegisterPack;
    }
}