import { Table } from "antd";
import React from "react";
import { Col, Row } from "reactstrap";

const FDetailReduction=({reductions})=> {

    const columns = [
        {
            title: 'N°',
            dataIndex: 'id',
            key: 'id',
            render: (item, _)=> {
                return (
                    <div>{item}</div>
                )
            }
        }, 
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Titre',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Taux',
            dataIndex: 'rate',
            key: 'rate',
            render: (rate, item)=> (
                <div>{`${rate * 100} %` }</div>
            )
        },
    ];

    return (
        <React.Fragment>
            <Row>

                <Col lg={12}>
                    <Table
                        /* loading={isFlotteQuotationLoading}*/
                        columns= {columns} 
                        dataSource= {reductions}
                        //pagination= {{ position: ["topRight"], pageSize: pageSize ? pageSize : 10}}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default FDetailReduction;