import { Card, Spin } from 'antd';
import React from 'react'
import * as helpers from 'helpers/helpers';
import ErrorWidget from 'components/Widgets/ErrorWidget';
import { StepDetails, StepProrogationPeriod } from './steps';
import { StepThankYou } from '../../common/steps';
import WizardHeader from 'modules/components/WizardHeader';
import { useSelector } from 'react-redux';
import useInitAvenant from '../useInitAvenant';

export default function AvenantProrogation() {

    const { avenant, contract, category, partnerCode } = useSelector((state) => state.subscription);
    const { currentStep, page, basket, transactionResponse, initAvenantResponse } = useSelector((state) => state.basketSlice);

    const { state } = useInitAvenant();

    if (state?.isInitAvenantLoading) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if (state?.iniAvenantLoadingError) {
        return <ErrorWidget errorMessage={state?.iniAvenantLoadingErrorMessage} />;
    }
    
    function renderChildren() {
        switch (currentStep) {
            case 0: 
                return <StepProrogationPeriod basket={basket} periodTitle={state?.customPeriodTitle} />;
            case 1:
                return <StepDetails
                            avenantCode={avenant?.code}
                            endDate={initAvenantResponse?.endDate}
                            packSelected={basket?.packSelected}
                            subscriptionRequest={basket?.subscriptionRequest}
                            refresh={state?.isInitAvenantLoading}
                        />

            case 2:
                if(page === helpers.PAGES.THANK_YOU) {
                    return <StepThankYou transactionResponse={transactionResponse} />;
                }
                break;
            default:
                return <ErrorWidget errorMessage="Ooops la page n'existe pas" />;
        }
    }

    return (
        <>
            <WizardHeader
                title={`Categorie ${category?.code} : ${category?.title}`}
                avenantTilte={`Avenant de prorogation`}
                partnerCode={partnerCode}
                police={contract?.policeNumber}
                percent={currentStep * 33}
            />
            {renderChildren()}
        </>
    )
}