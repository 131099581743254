import Assure from "api/model/assure.model";

export default class ContractsResponse {
    id;
    createdAt;
    createdBy;

    productCode;
    productTitle;

    categoryCode;
    categoryTitle;

    // CONTRACT
    policeNumber;
    contractID;
    startDate;
    endDate;

    primeTTC;
    primeNette;

    // ASSURE
    assure = new Assure();

    hasGroup;
    groupName;

    withdrawalPlace;
    partnerName;

    entitiesTitle;

    fromJson = (json={})=> {
        var contractsResponse = new ContractsResponse();
        contractsResponse = json;
        return contractsResponse
    }
} 