import { DatePicker, Form, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { disableDateRanges, disabledPrevDate } from "helpers/helpers";
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/fr_FR';
import PeriodService from "services/periodService";
import { fullFormatDate } from "helpers/date_helpers";
import { useSelector } from "react-redux";
import { CAN_SUBSCRIBE_POST_DATE } from "profileActions";

const DateEffectField=({disable, rangeDate, formField, contractRef, avenantCode, label, size='large' })=> {
    const { user } = useSelector((state) => state.auth);
    const [state, setState]=useState({
        period: null,
        isEndDateLoading: false
    })

    useEffect(()=> {
        if(formField?.startDate && formField?.periodRef) {
            getEndDate(formField?.startDate)
        }
    }, [])

    const onDateChange=(dateSelected)=> {
        getEndDate(dateSelected)
    }

    const getEndDate=(startDate)=> {
        const periodRequest={
            isFleet: false,
            avenantCode: avenantCode,
            contractRef: contractRef,
            periodRef: formField?.periodRef,
            dateEffect: startDate,
            customerStartDate: formField?.customerStartDate,
            customerEndDate: formField?.customerEndDate,
            isProrate: formField?.prorate

        }
        setState(state=> ({...state, isEndDateLoading: true}))
        console.log("PeriodRequest :::", periodRequest);
        PeriodService.getPeriodEndDate(periodRequest, (data)=> {
            console.log("::: Period : ", data)
            setState(state=> ({...state, period: data, isEndDateLoading: false}))
        }, (error)=> {
            console.log("::: errot : ", error)
            setState(state=> ({...state, isEndDateLoading: false}))
        })
    }

    return (
        <React.Fragment>
            <Form.Item
                label={label ?? "Date d'éffet du contrat" }
                name="startDate"
                rules={[{ required: true, message: "Champ obligatoire" }]}
                style={{marginBottom: '5px'}}
            >
                <DatePicker 
                    onSelect={(e)=> onDateChange(e)} 
                    style={{ width: '100%' }} 
                    size={size} 
                    locale={locale} 
                    format={"DD/MM/YYYY"} 
                    disabledDate={ !CAN_SUBSCRIBE_POST_DATE.includes(user.profile) ? (rangeDate ? disableDateRanges({
                        startDate: rangeDate?.startDate, 
                        endDate: rangeDate?.endDate
                    }) : disabledPrevDate) : null}   
                    disabled={disable} />
                    
            </Form.Item>
            <div style={{width: "100%", textAlign: 'left', marginTop:5}}>{state?.isEndDateLoading ? (<Space><div>Calcul date d'echéance...</div><Spin size='small' /></Space>) : (<span > Date d'echéance : {state?.period?.endDate ? fullFormatDate(state?.period?.endDate) : '---'}</span>)}</div>
        </React.Fragment>
    )
}

export default DateEffectField;