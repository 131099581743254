import { LeftOutlined } from "@ant-design/icons";
import { Col, Input, Row, Table } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";


export default function DeatailsCommission(props) {
    const [state, setState] = useState({
        listSouscription: [],
        souscriptionLoad: false
    });

    const columns = [
        {
            title: "Date",
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: "Police",
            dataIndex: 'police',
            key: 'police',
        },
        {
            title: "Assuré",
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: "Apporteur",
            dataIndex: 'entite',
            key: 'entite'
        },
        {
            title: "Commission",
            dataIndex: 'commission',
            key: 'commission'
        }
    ]


    return (
        <div>
            <div>
                <Link to="/commissions/commissions">
                    <LeftOutlined style={{ marginLeft: 10, color: 'blue', fontSize: 20, cursor: "pointer" }} />
                </Link>
            </div>
            <br />
            <Row>
                <Col md={8}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                        <h4 style={{ color: 'grey' }}>Produit :</h4>
                        <h3 style={{ color: 'rgb(82, 196, 26)', fontWeight: 'bold' }}>AUTO</h3>
                    </div>
                </Col>

                <Col md={8}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                        <h4 style={{ color: 'grey' }}>Categorie :</h4>
                        <h3 style={{ color: '#808384', fontWeight: 'bold' }}>401 Promenades et affaires</h3>
                    </div>
                </Col>

                <Col md={8}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', borderBottom: '1px solid rgba(112, 114, 110, 0.1)', marginBottom: 10 }}>
                        <h4 style={{ color: 'grey' }}>Periode :</h4>
                        <h3 style={{ color: '#808384', fontWeight: 'bold' }}>Du 24-06-2022 au 27-06-2022</h3>
                    </div>
                </Col>
            </Row><br />

            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Input placeholder="Recherche" style={{ width: 250, marginBottom: 10 }} />
            </div><br />

            <Table columns={columns} dataSource={state.listSouscription} />
        </div>
    )
}