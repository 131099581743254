import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class CategoryService {

    static createCategory(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.createCategoryResource, payload, sCallBack, eCallBack)
    }

    static editCategory(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.editCategoryResource, payload, sCallBack, eCallBack);
    }

    // Get all category
    readAllCategory(options={}, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.allcategoryResource, {}, sCallBack, eCallBack)
    }

    getCategoryByCode(categoryCode, sCallBack, eCallBack) {
        let options = {
            query: { code: categoryCode }
        }
        ApiRoutes.categoryByCodeResource.resourceOptions = Object.assign({}, ApiRoutes.categoryByCodeResource.resourceOptions, options);
        exeRequest(ApiRoutes.categoryByCodeResource, {}, sCallBack, eCallBack)
    }

    // :::::: CATEGORY PACKS
    
    // Get all packs of category
    static getPacks(categoryId, sCallBack, eCallBack) {
        let options = {
            path: { id: categoryId }
        }
        ApiRoutes.categoryPacksResource.resourceOptions = Object.assign({}, ApiRoutes.categoryPacksResource.resourceOptions, options);
        exeRequest(ApiRoutes.categoryPacksResource, {}, sCallBack, eCallBack)
    }

    // Get packs and guranties of category
    static getPacksAndGuaranties(categoryId, sCallBack, eCallBack) {
        let options = {
            path: { id: categoryId }
        }
        ApiRoutes.categoryPacksGuarantiesResource.resourceOptions = Object.assign({}, ApiRoutes.categoryPacksGuarantiesResource.resourceOptions, options);
        exeRequest(ApiRoutes.categoryPacksGuarantiesResource, {}, sCallBack, eCallBack)
    }

    // Attach pack to category
    static attachPack(options, sCallBack, eCallBack) {
        ApiRoutes.categoryAttachPackResource.resourceOptions = Object.assign({}, ApiRoutes.categoryAttachPackResource.resourceOptions, options);
        exeRequest(ApiRoutes.categoryAttachPackResource, {}, sCallBack, eCallBack)
    }

    // Delete pack to category
    static deleteAttachPack(options, sCallBack, eCallBack) {
        ApiRoutes.categoryDeletePackResource.resourceOptions = Object.assign({}, ApiRoutes.categoryDeletePackResource.resourceOptions, options);
        exeRequest(ApiRoutes.categoryDeletePackResource, {}, sCallBack, eCallBack)
    }

    // Modify category pack status
    static toggleAttachPack(options, sCallBack, eCallBack) {
        ApiRoutes.toggleCategoryPackResource.resourceOptions = Object.assign({}, ApiRoutes.toggleCategoryPackResource.resourceOptions, options);
        exeRequest(ApiRoutes.toggleCategoryPackResource, {}, sCallBack, eCallBack)
    }

    // :::::: PACK'S GUARANTIES :::::::: //
    // attach guarantee to category pack
    static attachPackGuarantee(options, sCallBack, eCallBack) {
        ApiRoutes.attachCategoryPackGuaranteeResource.resourceOptions = Object.assign({}, ApiRoutes.attachCategoryPackGuaranteeResource.resourceOptions, options);
        exeRequest(ApiRoutes.attachCategoryPackGuaranteeResource, {}, sCallBack, eCallBack)
    }

    static deletePackGuarantee(options, sCallBack, eCallBack) {
        ApiRoutes.deleteCategoryPackGuaranteeResource.resourceOptions = Object.assign({}, ApiRoutes.deleteCategoryPackGuaranteeResource.resourceOptions, options);
        exeRequest(ApiRoutes.deleteCategoryPackGuaranteeResource, {}, sCallBack, eCallBack)
    }

    static togglePackcGuaranties(options, sCallBack, eCallBack) {
        ApiRoutes.toggleCategoryPackcGuarantiesResource.resourceOptions = Object.assign({}, ApiRoutes.toggleCategoryPackcGuarantiesResource.resourceOptions, options);
        exeRequest(ApiRoutes.toggleCategoryPackcGuarantiesResource, {}, sCallBack, eCallBack)
    }


    // Modify guarantee criterion for category pack
    static modifyGuaranteeCriterion(options, payload, sCallBack, eCallBack) {
        ApiRoutes.modifyCategoryPackGuaranteeResource.resourceOptions = Object.assign({}, ApiRoutes.modifyCategoryPackGuaranteeResource.resourceOptions, options);
        exeRequest(ApiRoutes.modifyCategoryPackGuaranteeResource, payload, sCallBack, eCallBack)
    }

    // Delete guarantee criterion for category pack
    static deleteGuaranteeCriterion(options, sCallBack, eCallBack) {
        ApiRoutes.deleteGuaranteeCriterionResource.resourceOptions = Object.assign({}, ApiRoutes.deleteGuaranteeCriterionResource.resourceOptions, options);
        exeRequest(ApiRoutes.deleteGuaranteeCriterionResource, {}, sCallBack, eCallBack)
    }
}