import { Col, Form, Input, Space, Spin } from "antd";
import { CATEGORY_403 } from "constantes";
import React from "react";import { useState } from "react";
import VehicleService from "services/vehicleService";

const TrailerMatriculationField=({categoryCode, title, disable, setMatriculationExist, handlerFormField, xs, md})=> {

    const [state, setState] = useState({
        matriculationIsFound: false,
        matriculationVerificationLoading: false
    })

    //TODO-OK: <Verification de matricule>
    const onVerifyMatriculation = (e) => {
        let _matriculation = e.target.value;
        if (_matriculation === undefined || _matriculation === "")
            return;

        setState(state => ({ ...state, matriculationVerificationLoading: true }));
        VehicleService.verifyMatriculation({matriculation: _matriculation, isTrailer: true}, (data) => {
            handlerFormField({trailerMatriculation: _matriculation})

            if(setMatriculationExist) {
                setMatriculationExist(false) ;
            }
            
            setState(state => ({ ...state,  matriculationIsFound: false, matriculationVerificationLoading: false }));
        }, (error) => {
            if(setMatriculationExist) {
                setMatriculationExist(true) 
            }  
            setState(state => ({ ...state, matriculationIsFound: true, matriculationVerificationLoading: false }));
        })
    }

    if(![CATEGORY_403].includes(categoryCode)) return null;
    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Immatriculation"
                    name="trailerMatriculation"
                    rules={[{ required: true, message: "Champ obligatoire" }]}>

                    <Input
                        size='large'
                        placeholder= {title ? title : "Numero d'Immatriculation de la remorque" }
                        onBlur={(e) => onVerifyMatriculation(e)}
                        suffix={state.matriculationVerificationLoading ? (<Space><div>Verification...</div><Spin size='small' /></Space>) : null}
                        disabled={disable}
                    />
                </Form.Item>
                {state?.matriculationIsFound ? (<h5 style={{ color: 'red' }}>Le matricule existe déjà</h5>) : null}
            </Col>
            
        </React.Fragment>
    )
}

export default TrailerMatriculationField;