import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "http/requestConfig";

export class GroupementService {

    getAllGroupement(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.groupementResource, {}, sCallBack, eCallBack)
    }
    
    // Get all groupement type
    readAllGroupementType(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.groupementTypeResource, {}, sCallBack, eCallBack)
    }

    // Get groupement by type
    readAllGroupementByType(groupementTypeRef, sCallBack, eCallBack) {
        let options = {
            path: {groupementTypeRef: groupementTypeRef}
        }
        ApiRoutes.groupementByTypeResource.resourceOptions = Object.assign({}, ApiRoutes.groupementByTypeResource.resourceOptions, options);
        exeRequest(ApiRoutes.groupementByTypeResource, {}, sCallBack, eCallBack)
    }

    createGroupement(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.createGroupementResource, payload, sCallBack, eCallBack)
    }

    editGroupement(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.editGroupementResource, payload, sCallBack, eCallBack)
    }
}