import { Table } from "antd";
import _ from "lodash";
import React from "react";
import { Col, Row } from "reactstrap";

const MDetailGuaranties=({guaranties})=> {

    const columns = [
        {
            title: 'N°',
            dataIndex: 'id',
            key: 'id',
            render: (item, _)=> {
                return (<div>{item}</div>)
            }
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        }, 
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Engagement',
            dataIndex: 'engagements',
            key: 'engagements',
            render: (engagements, item)=> (
                <div>{_.first(engagements)?.title}</div>
            )
        },
    ];

    return (
        <React.Fragment>
            <Row>

                <Col lg={12}>
                    <Table
                        /* loading={isFlotteQuotationLoading}*/
                        columns= {columns} 
                        dataSource= { guaranties}
                        pagination= {{ position: ["bottomRight"], pageSize:  3}}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default MDetailGuaranties;