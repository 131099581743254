import { Form, Rate, Select } from "antd"
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter"
import React,  { useState } from "react"
import { useEffect } from "react"
import EngagementService from "services/engagementService"

const EngagementField=({engagements, isDefaulEngagement, setIsDefaultEngagement})=> {
    const Option = Select;
    const [state, setState]=useState({
        engagements: engagements ?? [],
        isEngagementsLoading: false
    })

    useEffect(()=> {
        console.log(":::: isDefaulEngagement : ", isDefaulEngagement)
        if(!engagements) {
            getAllEngements()
        }
        
    }, [])

    const getAllEngements=()=> {
        setState(state=> ({...state, isEngagementsLoading: true}))
        EngagementService.getAll({}, (data)=> {
            const _engagements = data || [];
            setState(state=> ({...state, engagements: _engagements, isEngagementsLoading: false}))
        }, (error)=> {
            setState(state=> ({...state, isEngagementsLoading: false}))
        })
    }

    const onFavoriteChange=(e)=> {
        console.log(":::: onFavoriteChange ", e)
        if(setIsDefaultEngagement) {
            setIsDefaultEngagement(e)
        }
    }

    return (
        <React.Fragment>
            <Form.Item
                label={(<div>Engagement : <Rate count={1} defaultValue={isDefaulEngagement} onChange={onFavoriteChange} tooltips={["Valeur par defaut"]} /></div>)}
                name="engagementRef"
                rules={[{ required: false, message: "Champ obligatoire" }]}
            >
                <SelectWithFilter size='medium' placeholder="Engagement :" addonBefore={"Engagement :"} >
                    {state.engagements.map((engagement, index)=> (<Option value={engagement?.ref} key={index}>{engagement?.title}</Option>))}
                </SelectWithFilter>
            </Form.Item>
        </React.Fragment>
    )
}

export default EngagementField;