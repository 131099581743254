import {  Form, Input } from "antd";
import React from "react";

const ValueOrFormulaField=({disable})=> {
    return (
        <React.Fragment>
            <Form.Item
                name="value"
                rules={[{ required: true, message: "Champ obligatoire" }]}
            >
                <Input size='medium' placeholder='Valeur ou formule' disabled={disable} addonBefore={"Valeur :"}/>
            </Form.Item>
        </React.Fragment>
    )
}

export default ValueOrFormulaField;