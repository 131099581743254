import { EditOutlined, ExclamationCircleOutlined, FileAddOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Modal, notification, Row, Space, Spin, Switch, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import GuaranteeService from "services/guaranteeService";
import CreateGarantieRequest from "api/request/createGarantieRequest";
import { toast } from "react-toastify";
import ActionType from "api/acionType";
import FormRegisterGarantie from "forms/addGarantieForm";
import FormEditGarantie from "forms/editGarantieForm";
import { Card } from "reactstrap";
import EditGarantieRequest from "api/request/editGarantieRequest";
import Highlighter from 'react-highlight-words';

export default function Guarantee(props) {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [form] = Form.useForm();
    const [drawerVisibility, setDrawerVisibility] = useState({
        visibility: false,
        actionType: ActionType.REGISTER
    });
    const [state, setState] = useState({
        garanties: [],
        dataSource: [],
        isGaranteeLoading: true,
        isGaranteeTableUpdateContent: false,

        loading: false,
        updateLine: {},
        isLoad: false,
        itemId: null,

        formRegisterGarantie: new FormRegisterGarantie(),
        formEditGarantie: new FormEditGarantie()
    });

    useEffect(() => {
        getAllGaranties();
    }, [])

    const getAllGaranties = () => {
        setState(state => ({ ...state, isGaranteeLoading: true }))

        GuaranteeService.getAll({}, (data) => {
            setState(state => ({ ...state, garanties: data, dataSource: data, isGaranteeLoading: false, isGaranteeTableUpdateContent: false }));
        }, (error) => {
            setState(state => ({ ...state, isGaranteeLoading: false }))
        });

    }

    const onDrawerOpen = (guarantie, actionType) => {
        if (guarantie) {
            const _formEditGarantie = FormEditGarantie.fromJson({
                id: guarantie?.id,
                code: guarantie?.code,
                title: guarantie?.title,
                description: guarantie?.description,
            });
            setState(state => ({ ...state, formEditGarantie: _formEditGarantie }))
            console.log("::::: onDrawerOpen", state.formEditGarantie)

            form.setFieldsValue({ ...form.getFieldsValue(), ...state.formEditGarantie });
        }
        else {
            form.setFieldsValue({ ...form.getFieldsValue(), ...state.formRegisterGarantie });
        }

        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: true, actionType: actionType }));
    }

    // TODO-OK: Ajout et edition d'une co-assurance
    const onRegisterOrEditGarantieSubmit = (action) => {
        setState(state => ({ ...state, loading: true }));
        form.validateFields().then((fields) => {
            switch (action) {
                case ActionType.REGISTER:
                    const _formRegisterFields = { ...state.formRegisterGarantie, ...fields }
                    let createGarantieRequest = CreateGarantieRequest.fromJson(_formRegisterFields);

                    GuaranteeService.createGarantie(createGarantieRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, formRegisterGarantie: new FormRegisterGarantie() }));
                        getAllGaranties();
                        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                        toast.success("Garantie ajoutée avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        console.log("error", error);
                        toast.error(error);
                    });
                    break;

                case ActionType.EDIT:
                    const formEditFields = { ...state.formEditGarantie, ...fields };
                    let editGarantieRequest = EditGarantieRequest.fromJson(formEditFields);

                    GuaranteeService.editGarantie(editGarantieRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, formEditGarantie: new FormEditGarantie() }));
                        getAllGaranties();
                        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                        toast.success("Garantie modifiée avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        toast.error(error);
                    });
                    break;

                default:
                    setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                    toast.error("Une erreur est survenue, veuillez réesayer");
                    break;
            }
        }).catch((error) => {
            console.log(error)
            toast.error("Garantie non enregistrée");
        })
        setState(state => ({ ...state, loading: false }));
    }

    const onSwitchChange = (guarantee) => {
        const option = { path: { id: guarantee?.id } }
        GuaranteeService.toggleStatus(option, (data) => {
            toast.success("Garantie modifiée avec succès", { style: { background: 'primary' } });
            getAllGaranties();
        }, (error) => {
            toast.error("Une erreur est survenue");
        })
    }

    //TODO-OK: <Recherche d'un co assureur>
    // function onSearchCoInsurance(e) {
    //     const val = e?.target?.value?.toLowerCase();
    //     const temp = state.coInsurances?.filter((d)=> {
    //         return d?.code?.toLowerCase().indexOf(val) !== -1 ||
    //         d?.title?.toLowerCase().indexOf(val) !== -1 ||
    //         !val
    //     });
    //     setState(state => ({...state, dataSource: temp}))
    // }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Recherche
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            width: 100,
            filters: state.dataSource.map((item) => ({
                text: item?.code,
                value: item?.code,
            })),
            onFilter: (value, record) => record?.code?.startsWith(value),
            filterSearch: true,
            //...getColumnSearchProps("code"),
            render: (code) => (
                <div style={{ fontWeight: 'bold' }}>
                    {code}
                </div>
            )
        },
        {
            title: "Titre",
            dataIndex: "title",
            key: "title",
            width: 250,
            filters: state.dataSource.map((item) => ({
                text: item?.title,
                value: item?.title,
            })),
            onFilter: (value, record) => record?.title?.startsWith(value),
            filterSearch: true,
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description"
        },
        {
            title: "Etat",
            dataIndex: "status",
            key: "status",
            render: (item, data) => (
                <div>
                    <Switch defaultChecked={data.status} size="small" onChange={() => onSwitchChange(data)} />
                </div>
            )
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (item, garantie) => (
                <>
                    <EditOutlined style={{ color: '#40E070', cursor: 'pointer' }} onClick={() => onDrawerOpen(garantie, ActionType.EDIT)} />
                </>

            )
        }
    ];

    return (
        <React.Fragment>
            <Card>
                <div style={{ padding: 20 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Liste des garanties</h4>

                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            {/* <Input placeholder="Recherche: Code, titre" style={{ width: 350, marginRight: 10 }} onChange={onSearchCoInsurance} /> */}
                            {/* <Input placeholder="Recherche: Garantie" style={{ width: 350, marginRight: 10 }} /> */}
                            <Button type="primary" onClick={() => onDrawerOpen(null, ActionType.REGISTER)} style={{ marginLeft: 10 }}>
                                <FileAddOutlined />
                                Ajouter une garantie
                            </Button>
                        </div>
                    </div>
                    <br />
                    <Table
                        dataSource={state.dataSource}
                        columns={columns}
                        loading={state.isGaranteeLoading}
                    />

                    {drawerVisibility.visibility && (
                        <Drawer
                            title={drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrement d'une garantie" : "Modifier la garantie"}
                            width={720}
                            onClose={() => { setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false })) }}
                            visible={drawerVisibility.visibility}
                            bodyStyle={{
                                paddingBottom: 80,
                            }}
                        >
                            <Form
                                name="basic"
                                onFinish={() => onRegisterOrEditGarantieSubmit(drawerVisibility.actionType)}
                                layout="vertical"
                                form={form}
                                initialValues={drawerVisibility.actionType === ActionType.REGISTER ? state.formRegisterGarantie : state.formEditGarantie}
                            >

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Code de la garantie"
                                            name="code"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Le code de la garantie svp !',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Le code de la garantie" size="middle" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Nom de la garantie"
                                            name="title"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Le nom de la garantie svp !',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Le nom de la garantie" size="middle" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={8} >
                                    <Col className="gutter-row" xs={24} md={24}>
                                        <Form.Item
                                            label="Description de la garantie"
                                            name="description"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'La description svp !',
                                                },
                                            ]}
                                        >
                                            <TextArea placeholder='Description de la garantie' size="middle" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        width: '100%',
                                        borderTop: '1px solid #e8e8e8',
                                        padding: '10px 16px',
                                        textAlign: 'right',
                                        left: 0,
                                        background: '#fff',
                                        borderRadius: '0 0 4px 4px',
                                    }}
                                >
                                    <Button
                                        style={{
                                            marginRight: 8,
                                        }}
                                        onClick={() => { setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false })); }}
                                    >
                                        Annuler
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={state.loading}>
                                        {drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrer" : "Editer"}
                                    </Button>
                                </div>
                            </Form>
                        </Drawer>
                    )}



                    {/**********DELETE ALERTE ****** */}
                    {false && (
                        <Modal
                            title="Supprimer une garantie !!!"
                            visible={false}
                            onOk={() => { }}
                            onCancel={() => { }}
                            okText="Confirmer"
                            cancelText="Annuler"
                        >
                            <h3 style={{ color: 'orange' }}> <ExclamationCircleOutlined />  Voulez-vous supprimer vraiment cette garantie ?</h3>
                        </Modal>
                    )}
                </div>
            </Card>
        </React.Fragment>
    )
}