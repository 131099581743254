import React from 'react'

const DescriptionItem = ({ title, titleWidth, content }) => (
    <div
      style={{
        fontSize: 14,
        lineHeight: '15px',
        marginBottom: 7,
        color: 'rgba(0,0,0,0.65)',
      }}
    >
      <p
        style={{
          marginRight: 8,
          display: 'inline-block',
          color: 'rgba(0,0,0,0.85)',
          width: titleWidth || 120
        }}
      >
        {title} :
      </p>
      <span style={{fontWeight: "bold"}}>{content}</span>
    </div>
);

export default DescriptionItem