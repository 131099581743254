import ApiRoutes from "api/apiRoutes";
import { buildRequestUrl } from "api/routeManager";
import { FILE_PATH_ROOT, PDF_VIEWER_URL } from "constantes";

export default class DocumentService {

    static openDocument(documentType, filename) {

        let options = {
            path: {
                documentType: documentType,
                filename:  filename
            }
        }
        ApiRoutes.openDocumentFileResource.resourceOptions = Object.assign({}, ApiRoutes.openDocumentFileResource.resourceOptions, options);
       const filePath = `${FILE_PATH_ROOT}${buildRequestUrl(ApiRoutes.openDocumentFileResource.urlSuffix,  ApiRoutes.openDocumentFileResource.resourceOptions)}`;
        
       window.open(PDF_VIEWER_URL +"?file=" + filePath);
    }

    static documentDownload(filename, documentType) {
        let filePath = `${FILE_PATH_ROOT}/files/get/${filename}`;
        if(documentType) {
            let options = {
                path: {
                    documentType: documentType,
                    filename:  filename
                }
            }
            ApiRoutes.openDocumentFileResource.resourceOptions = Object.assign({}, ApiRoutes.openDocumentFileResource.resourceOptions, options);
            filePath = `${FILE_PATH_ROOT}${buildRequestUrl(ApiRoutes.openDocumentFileResource.urlSuffix,  ApiRoutes.openDocumentFileResource.resourceOptions)}`;
        }
        
       window.open(filePath);
    }
}