import React, { useState } from 'react'

import { useSelector, useDispatch } from "react-redux";
import makeStyles from '@material-ui/styles/makeStyles';
import { Redirect, useLocation } from "react-router-dom";
import { Form, Input, Button, Card, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { signIn } from 'redux/slices/authSlice'
import { logo, banner } from 'assets/images'

import styles from './styles'
import AuthService from 'services/authService';
import Title from 'antd/lib/typography/Title';

const useStyles = makeStyles(styles)

const initialState = {
  isLoading: false,
  alert: { isOpen: false, message: '' }
};

export default function Login() {
  const [state, setState] = useState(initialState)
  const classes = useStyles()

  const accessToken = useSelector((state) => state.auth.accessToken)
  const dispatch = useDispatch();

  const onFinish = values => {
    setState(state => ({ ...state, isLoading: true }));
    (new AuthService()).signIn(values, (data) => {
      dispatch(signIn(data));
    }, (error) => {
      console.log(error);
      setState(state => ({
        ...state,
        isLoading: false,
        alert: { isOpen: true, message: "Nom utilisateur ou mot de passe incorrect" }
      }));
    });
  };

  const location = useLocation()

  const { from } = location.state || { from: { pathname: "/" } };

  if (accessToken) {
    return <Redirect to={from} />;
  }

  return (
    <div className={classes.root}>
      <div style={{ display: "flex" }}>
        <div className={classes.bannerContainer}>
          {/* <div style={{height: 500, width: 650, background: '#002464'}} ></div> */}
          <img
            src={banner}
            alt="banner"
            width={650}
            height={500}
            className={classes.logsso} 
          />
        </div>

        <Card className={classes.formCard}>
          <div className={classes.logoContainer}>
            <img src={logo} alt="logo" className={classes.logo} />
            {/* <Title type='primary' style={{color: '#002464'}}>Radis</Title> */}
          </div>

          <Form
            autoComplete="off"
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'SVP renseignez votre email ou nom utilisateur!',
                },
              ]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email ou nom utilsateur" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'SVP renseignez votre mot de passe!',
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Mot de passe"
              />
            </Form.Item>
            {/* <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <a className="login-form-forgot" href="">
                Mot de passe oublié
              </a>
            </Form.Item> */}

            <Form.Item className={classes.loginBtn}>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="login-form-button"
                loading={state.isLoading}
                style={{ width: '100%' }}
              >
                Connexion
              </Button>
            </Form.Item>
          </Form>

          {state.alert.isOpen && <Alert message={state.alert.message} type="error" showIcon />}
        </Card>
      </div>
    </div>
  );
}