import { Form, Select } from "antd"
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter"
import React,  { useState } from "react"
import { useEffect } from "react"
import SeatService from "services/SeatService"

const SeatField=({categoryRef, seats})=> {
    const Option = Select;
    const [state, setState]=useState({
        seats: seats ?? [],
        isSeatLoading: false
    })

    useEffect(()=> {
        if(!seats) {
            getSeats()
        }
    }, [])

    const getSeats=()=> {
        setState(state=> ({...state, isSeatLoading: true}));
        (new SeatService()).getByCategoryRef(categoryRef, (data)=> {
            const _seats = data || [];
            setState(state=> ({...state, seats: _seats, isSeatLoading: false}))
        }, (error)=> {
            setState(state=> ({...state, isSeatLoading: false}))
        })
    }

    return (
        <React.Fragment>
            <Form.Item
                label="Places"
                name="seatRed"
                rules={[{ required: false, message: "Champ obligatoire" }]}
            >
                <SelectWithFilter size='medium' placeholder="Selectionner" addonBefore={"Place :"}>
                    {(state.seats || []).map((seat, index)=> (<Option value={seat?.ref} key={index}>{seat?.title}</Option>))}
                </SelectWithFilter>
            </Form.Item>
        </React.Fragment>
    )
}

export default SeatField;