import { Col, Form, Input } from 'antd';
import React from 'react';

const SimpleInputField=({label, placeholder, name, formItemStyle, type, disable=false, size='large', suffix, xs, md, required=true})=> {
    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label={label ?? ''} 
                    name={name}
                    rules={[{ required, message: "Champ obligatoire" }]}
                    style={formItemStyle}
                >
                    <Input placeholder={placeholder ?? label} size={size} disabled={disable} suffix={suffix}/>
                    
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default SimpleInputField;