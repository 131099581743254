import { Tag } from "antd";
import React from "react";
import CountUp from "react-countup";

const FDetailPrime=({primeTTC, primeNette, agencyFees, extras})=> {
    return (
        <React.Fragment>
            <div className="col-xxl-3">
                <div className="card card-height-100">
                    <div className="card-header border-0 align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">PRIME TTC</h4>
                        
                    </div>
                    <div className="card-body">
                        <div id="portfolio_donut_charts" className="apex-charts" dir="ltr">
                            {/* <PortfolioCharts series={chartData} dataColors='["--vz-primary", "--vz-info", "--vz-warning", "--vz-success"]' /> */}
                            <div className="d-flex align-items-center ">
                                <div className="flex-shrink-0">
                                    <i className={"ri-exchange-dollar-line display-6 text-muted"}></i>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <h2 className="mb-0">
                                        <span className="counter-value" data-target="197">
                                            <Tag color="green" style={{ fontSize: 25, padding: 5 }}>
                                                <CountUp
                                                    start={0}
                                                    prefix={""}
                                                    suffix={"F CFA"}
                                                    separator={" "}
                                                    end={primeTTC}
                                                    decimals={0}
                                                    duration={2}
                                                />
                                            </Tag>
                                        </span>
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <ul className="list-group list-group-flush border-dashed mb-0 mt-3 pt-2">
                            <li className="list-group-item px-0">
                                <div className="d-flex">
                                    <div className="flex-shrink-0 avatar-xs">
                                        <span className="avatar-title bg-light p-1 rounded-circle">
                                            <img src={""} className="img-fluid" alt="" />
                                        </span>
                                    </div>
                                    <div className="flex-grow-1 ms-2">
                                        <h6 className="mb-1">Prime nette</h6>
                                        {/* <p className="fs-13 mb-0 text-muted"><i className="mdi mdi-circle fs-10 align-middle text-primary me-1"></i>BTC</p> */}
                                    </div>
                                    <div className="flex-shrink-0 text-end">
                                        <h6 className="mb-1"><Tag color="grey">{primeNette} F CFA</Tag></h6>
                                        {/* <p className="text-success fs-13 mb-0">${chartData[0]}.12</p> */}
                                    </div>
                                </div>
                            </li>
                            {(extras || []).map((extra, key)=> (
                                <li className="list-group-item px-0" key={key}>
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 avatar-xs">
                                            <span className="avatar-title bg-light p-1 rounded-circle">
                                                <img src={""} className="img-fluid" alt="" />
                                            </span>
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <h6 className="mb-1">{extra?.title}</h6>
                                            <p className="fs-13 mb-0 text-muted"><i className="mdi mdi-circle fs-10 align-middle text-info me-1"></i>{ extra?.description }</p>
                                        </div>
                                        <div className="flex-shrink-0 text-end">
                                            <h6 className="mb-1"><Tag color="grey"> {extra?.amount} F CFA</Tag></h6>
                                            {/* <p className="text-danger fs-13 mb-0">${chartData[1]}.18</p> */}
                                        </div>
                                    </div>
                                </li>
                            ) )}
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FDetailPrime;