import React, { useEffect } from "react";

import { Card, Spin } from "antd";
import ErrorWidget from "components/Widgets/ErrorWidget";
import MonoSubscription from "./mono";
import FlotteSubscription from "./flotte";
import useInitSubscription from "./hooks/useInitSubscription";
import { useDispatch, useSelector } from "react-redux";
import { SubscriptionWidget } from "./component/ComponentWidget";
import ListSubscription from "./ListSubscription";
import { sUpdateState } from "redux/slices/subscriptionSlice";

export default function Subscription() {

    const { state, handleNouvelAvenant } = useInitSubscription();
    const { category } = useSelector((state) => state.subscription);
    const avenant = useSelector((state) => state.subscription?.avenant);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!state.isCategorieLoading) {
            dispatch(sUpdateState([
                { path: ["category"], value: state?.category}
            ]));
        }
    }, [state.isCategorieLoading])

    if (state.isCategorieLoading && !state?.category) {
        return (<Card>
            <Spin /> Chargement en cours ...
        </Card>)
    }

    if (state.categoryLoadingError) {
        return <ErrorWidget errorMessage={state.categoryLoadingErrorMessage} />;
    }

    function renderChildren() {
        if(state.subscriptionWidget === SubscriptionWidget.SUBSCRIPTION && avenant) {
            return category?.code === '400' ? (<FlotteSubscription avenant={avenant} />) : (<MonoSubscription avenant={avenant} />);
        }
        else {
            return <ListSubscription category={state?.category} handleNouvelAvenant={handleNouvelAvenant} />
        }
    }

    return (
        <React.Fragment>
            {renderChildren()}
        </React.Fragment>
    )
}