import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class CoInsuranceService {
    
   createCoInsurance(payload, sCallBack, eCallBack) {
      exeRequest(ApiRoutes.createCoInsurancesResource, payload, sCallBack, eCallBack)
   }

   editCoInsurance( payload, sCallBack, eCallBack) {
      exeRequest(ApiRoutes.editCoInsurancesResource, payload, sCallBack, eCallBack);
   }

   getAllCoInsurances(sCallBack, eCallBack) {
      exeRequest(ApiRoutes.allCoInsurancesResource, {}, sCallBack, eCallBack)
   }

   readAllCoInsuranceByContractDetailID(options = {}, sCallBack, eCallBack ) {
      ApiRoutes.allCoInsuranceByContractDetailIDResource.resourceOptions = Object.assign({}, ApiRoutes.allCoInsuranceByContractDetailIDResource.resourceOptions, options);
      exeRequest(ApiRoutes.allCoInsuranceByContractDetailIDResource, {}, sCallBack, eCallBack)
   }

   static applyRepartition(payload, sCallBack, eCallBack) {
      exeRequest(ApiRoutes.applyRepartitionResource, payload, sCallBack, eCallBack );
   }
}