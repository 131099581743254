import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Payment from "views/pages/subscription/component/payment/Payment";
import { ContractType } from "views/pages/subscription/component/ComponentWidget";
import { MDetailOthers, MDetailPackage, MDetailPeriod, MDetailPrime, MDetailStartDate } from "../../common/details";
import { useBasket } from "views/pages/subscription/hooks/useBasket";
import _ from "lodash";
import SubscriptionRequestBuilder from "api/request/SubscriptionRequestBuilder";

const StepDetails = ({
    avenantCode,
    initAvenantResponse,
    subscriptionRequest,
    packSelected
}) => {

    const {goToPreviousStep, initStartDate, setInitStartDate, onStartDateChange }=useBasket();

    useEffect(() => {
        setInitStartDate({
            startDate: subscriptionRequest?.dateEffect,
            endDate: null,
            initialDate: initAvenantResponse?.startDate
        });
    }, [])

    // useEffect(() => {
    //     console.log(":::::StepDetails packSelected: ", packSelected)
    // }, [])

    const getGuaranties=()=> {
        if(_.size(packSelected?.customGuaranties) <= 0) return packSelected?.guaranties;
        else {
            const customGuarantiesRef = (packSelected?.customGuaranties || []).map((customGuarantee)=> customGuarantee?.guaranteeRef);
            return (packSelected?.guaranties || [])?.filter((guarantee)=> customGuarantiesRef.includes(guarantee?.ref))
        }
    }

    return (
        <React.Fragment>
            <div /* className="page-content" */>
                <Container fluid>

                    <Row>
                        <Col className="col-xxl-9 order-xxl-0 order-first">
                            <Row>
                                <MDetailPackage packTitle={packSelected?.packTitle} />

                                <MDetailPeriod perioTitle={packSelected?.period?.title} />

                                <MDetailStartDate
                                    rangeDate={initStartDate}
                                    onStartDateChange={onStartDateChange} />

                            </Row>
                            <MDetailOthers
                                vehicle={subscriptionRequest?.vehicle}
                                guaranties={getGuaranties()}
                                reductions={packSelected?.reductionGroups}
                                assistance={packSelected?.assistance}
                                assure={subscriptionRequest?.assure}
                            />
                        </Col>
                        <MDetailPrime
                            primeTTC={packSelected?.primeTTC}
                            primeNette={packSelected?.primeNette}
                            extras={packSelected?.extras}
                        />

                    </Row>
                    <Row>
                        <Col className="col-xxl-12">
                            <Payment
                                backVisibility={true}
                                projectVisibility={true}
                                onlineVisiblity={true}
                                payload={SubscriptionRequestBuilder.fromThis(avenantCode, subscriptionRequest)}
                                goBack={goToPreviousStep}
                                type={ContractType.MONO}
                            />
                        </Col>
                    </Row>
                    {/* <Row>
                        <Widgets1 />
                    </Row>
                    <Row>
                        <MyCurrencies />
                        <Trading />
                    </Row>
                    <Row>
                        <RecentActivity />
                        <TopPerformers />
                        <NewsFeed />
                    </Row> */}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default StepDetails;