export default class FormRegisterPartner {
    code;
    title;
    description;
    address;
    email;
    phone;
    natureCode;
    contributorRef;
    contributorTitle;

    fromJson = (json={})=> {
        var formRegisterPartner = new FormRegisterPartner();
        formRegisterPartner = json;
        return formRegisterPartner;
    }
}