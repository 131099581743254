import { FormField } from "forms/FormField";
import { convert } from "helpers/helpers";

export default class FlotteAutoBuilder {

    static build({ formFields, packSelected }) {
        const newFormFields = (new FormField().fromJson(formFields));

        return {
            packLineRef: packSelected.packLineRef,
            categoryRef: newFormFields.categoryRef,
            categoryTitle: newFormFields.categoryTitle,
            driverBirthDay: newFormFields.driverBirthDay,
            driverLicDeliveryDate: newFormFields.driverBirthDay,
            primeNette: packSelected.primeNette,
            primeTTC: packSelected.primeTTC,
            primeExtra: packSelected.primeExtra,
            agencyFees: 0,
            cGuaranties: packSelected?.customGuaranties,
            reductionGroups: packSelected?.reductionGroups,

            vehicle: {
                transport: {
                    ref: newFormFields.transportRef
                },
                model: {
                    ref: newFormFields.modelRef,
                    title: newFormFields.modelTitle,
                },
                brand: {
                    ref: newFormFields.brandRef,
                    title: newFormFields.brandTitle,
                },
                energy: {
                    ref: newFormFields.energyRef
                },
                totalPlace: newFormFields.nbPlace,
                fiscalPower: newFormFields.fiscalPower,
                dateMec: convert(newFormFields.dateMec),

                hasGroup: newFormFields.hasGroup,
                groupRef: newFormFields.groupementRef,
                groupName: newFormFields.groupementTitle,
                groupType: newFormFields.groupementTypeRef,

                hasGps: newFormFields.hasGps,
                newValue: newFormFields.marketValue,
                marketValue: newFormFields.marketValue,
                matriculation: newFormFields.matriculation,
                chassisNumber: newFormFields.chassisNumber,
                city: {
                    ref: newFormFields.cityRef
                },
                greyCardImg: null,
                isGood: null,
                goodWeight: null,
            }
        }
    }
}

