import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "http/requestConfig";

export default class MerchantService {
    // Recherche les apporteurs par mot
    static search(options={}, sCallBack, eCallBack ) {
        ApiRoutes.searchMerchantResource.resourceOptions = Object.assign({}, ApiRoutes.searchMerchantResource.resourceOptions, options);
        exeRequest(ApiRoutes.searchMerchantResource, {}, sCallBack, eCallBack)
    }

    static attachMerchantToSubscription(paylaod, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.attachMerchantOnSubscriptionResource, paylaod, sCallBack, eCallBack)
    }
}