import React, { useEffect, useState } from 'react'
import { Form, Card, Button, Modal,  Input, Spin, Popconfirm, Tag, Table } from 'antd'
import CustomAntdForm from 'modules/components/Forms/CustomAntdForm';
import { useHistory } from 'react-router-dom';
import Payment from 'views/pages/subscription/component/payment/Payment';
import FDetailsContract from '../../../common/FDetailsContract';
import ReturnNewTitle from 'views/pages/common/ReturnNewTitle';
import FDetailsAssure from '../../../common/FDetailsAssure';
import FDetailsReductionAndPrime from '../../../common/FDetailsReductionAndPrimes';
import * as helpers from 'helpers/helpers';
import { Col, Row } from 'reactstrap';
import { ContractType } from 'views/pages/subscription/component/ComponentWidget';


const FStepDetails=({fBasket, fBasket: {baskets,  quotationResponse, fSubscriptionRequest}, avenant, category, paymentStatus })=> {
    
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [fakeLoading, setFakeLoading] = useState(true);
    const history = useHistory();

    const [state, setState]=useState({
        
    });

    useEffect(() => {
        setTimeout(() => {
            setFakeLoading(false)
        }, 2000)
    }, [])

    const columns = [
        {
            title: 'N°',
            dataIndex: 'id',
            key: 'id',
            render: (item, basket)=> {
                return (
                    <div>{basket?.id}</div>
                )
            }
        },
        {
            title: 'Marque',
            dataIndex: 'formField',
            key: 'brandTitle',
            render: (formField, basket)=> (
                <div>{formField?.brandTitle}</div>
            )
        }, 
        {
            title: 'Modèle',
            dataIndex: 'formField',
            key: 'modelTitle',
            render: (formField, index)=> (
                <div>{formField?.modelTitle}</div>
            )
        },
        
        {
            title: 'Matricule',
            dataIndex: 'formField',
            render: (formField, basket)=> {
                return (
                    <div>{formField?.matriculation}</div>
                )
            }
        },
        {
            title: 'N° chassis',
            dataIndex: 'formField',
            key: 'chassisNumber',
            render: (formField, basket)=> {
                return (
                    <div>{formField?.chassisNumber}</div>
                );
            }
        },
        {
            title: 'Pack',
            dataIndex: 'packSelected',
            key: 'packTitle',
            render: (packSelected, basket)=> (
                <div>{packSelected?.packTitle}</div>
            )
        },
        {
            title: 'Prime sans Red Flotte',
            dataIndex: 'primeHF',
            key: 'primeHF',
            render: (item, basket)=> (
                <Tag color="volcano" key={`mono_${basket?.id}`}>
                    <div style={{fontWeight: 'bold'}}>{helpers.thousandSeparator(basket?.primeHF)} F CFA</div>
                </Tag>
                
            )
        },
        {
            title: 'Prime avec Red. flotte',
            dataIndex: 'packSelected',
            key: 'packSelected',
            render: (packSelected, basket)=> (
                <Tag color="green" key={"flotte"}>
                    <div style={{fontWeight: 'bold'}}>{helpers.thousandSeparator(packSelected?.primeTTC)} F CFA</div>
                </Tag>
            )
        }
    ];
    
    function hideModal(value) {
        setOpen(value)
    }

    console.log("111111.......fSubsubscriptionRequest: ", fSubscriptionRequest)
    console.log("111111.......fBasket: ", fBasket)
    return (
        <>
            <CustomAntdForm form={form}>
                <Card className='fistCard'>

                    {/* <FDetailsHeader primeTTC={fSubscriptionRequest?.primeTTC}/> */}
                    {/* {ReturnNewTitle('Informations sur le vehicule')}                      
                    <Row>
                        <Col lg={12}>
                            <Table
                                columns= {columns}
                                dataSource= {baskets || []}
                                pagination= {{ position: ["topRight"], pageSize: 10}}
                            />
                        </Col>
                    </Row> */}
                    <FDetailsAssure assure={fSubscriptionRequest?.assure} />
                    <FDetailsContract flotteSubscriptionRequest={fSubscriptionRequest}  period={quotationResponse?.period} />
                    <FDetailsReductionAndPrime flotteSubscriptionRequest={fSubscriptionRequest} extras={quotationResponse?.extras} />
                </Card>

                <Card >
                    <Payment
                        projectVisibility={true}
                        onlineVisiblity={true}
                        payload={fSubscriptionRequest}
                        paymentStatus={paymentStatus}
                        /* successStatus={successStatus}
                        errorStatus={errorStatus} */
                        /* goToPreviousStep={goToPreviousStep} */
                        /* goToNextStep={goToNextStep} */
                        type={ContractType.FLOTTE}
                    />
                </Card>
                
                
                <Modal
                    footer={[
                        <div>
                            <Button onClick={() => hideModal(false)}>
                                Annuler
                            </Button>
                            <Button type='primary'>
                                Valider
                            </Button>
                        </div>
                    ]}
                    onOk={() => hideModal(false)}
                    onCancel={() => hideModal(false)}
                    visible={open}
                    title='Prelevement bancaire'
                >
                    <CustomAntdForm form={form}>
                        <Row gutter={[8, 4]}>
                            <Col className="gutter-row" xs={24} md={24}>
                                <Form.Item
                                    label="Nom"
                                    name="name"
                                    rules={[{ required: true, message: "Champ obligatoire" }]}
                                >
                                    <Input placeholder='Nom du proprietaire du compte' size='large' />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={24} md={24}>
                                <Form.Item
                                    label="Prenom"
                                    name="lastName"
                                    rules={[{ required: true, message: "Champ obligatoire" }]}
                                >
                                    <Input placeholder='Prenom du proprietaire du compte' size='large' />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={24} md={24}>
                                <Form.Item
                                    label="Telephone mobile"
                                    name="mobile"
                                    rules={[{ required: true, message: "Champ obligatoire" }]}
                                >
                                    <Input placeholder='Numero de telephone du proprietaire du compte' size='large' />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={24} md={24}>
                                <Form.Item
                                    label="RIB"
                                    name="rib"
                                    rules={[{ required: true, message: "Champ obligatoire" }]}
                                >
                                    <Input placeholder='RIB du proprietaire du compte' size='large' />
                                </Form.Item>
                            </Col>
                        </Row>

                    </CustomAntdForm>
                </Modal>

            </CustomAntdForm >
        </>
    )
}

export default FStepDetails;