import { FileDoneOutlined, GiftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

const BackNextWidget=({confirmText, confirmIcon, onConfirm, cancelText, cancelIcon, onCancel, disableConfirm})=> {
    return (
        <React.Fragment>
            
            <div className="d-flex align-items-start gap-3 mt-3">
                { onCancel ? (
                    <button
                        onClick={()=> onCancel() }
                        type="button"
                        className="btn btn-light btn-label previestab"
                        data-previous="pills-bill-address-tab"
                    >
                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                        {cancelText ?? 'Retour'}
                    </button>
                ) : (<div></div>)}
                
                <button
                    disabled={disableConfirm ?? false}
                    onClick={()=>onConfirm()}
                    type="button"
                    className="btn btn-primary btn-label right ms-auto nexttab"
                >
                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{" "}
                    {confirmIcon && (<FileDoneOutlined style={{ color: '#ffffff', fontSize: 20 }} />)}  {confirmText}
                </button>
            </div>
        </React.Fragment>
    )
}

export default BackNextWidget;