import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input, Modal, notification, Spin, Table } from "antd";
import { TEST_API } from "constantes";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card } from "reactstrap";


export default function Produits(props) {
    const [form] = Form.useForm();

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg
        });
    };

    useEffect(() => {
        getAllProduit();
    }, [])

    const [state, setState] = useState({
        listProduit: [],
        productLoad: false,
        loading: false,
        initialValue: {
            description: '',
            produit: ''
        },
        updateLine: {},
        isLoad: false,
        itemId: null,
    });

    const [visible, setVisible] = useState(false);
    const [update, setUpdate] = useState(false);
    const [del, setDel] = useState(false);
    const [list, setList] = useState([]);

    const columns = [
        {
            title: "Date de creation",
            dataIndex: "date",
            key: "date"
        },
        {
            title: "Code",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Title",
            dataIndex: "produit",
            key: "produit"
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description"
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (item, data) => (
                <>
                    <EditOutlined style={{ color: '#40E070', cursor: 'pointer' }} onClick={() => { updateModalVisible(true); setState(state => ({ ...state, updateLine: data })) }} />
                    <DeleteOutlined style={{ color: '#E5AC28', cursor: 'pointer', marginLeft: 10 }} onClick={() => { setDel(true); setState(state => ({ ...state, itemId: data.id })) }} />
                </>

            )
        }
    ];

    function getAllProduit() {
        fetch(`http://localhost:3003/produits`,
            {
                method: 'get',
                headers: { 'Content-Type': 'application/json' }
            }
        ).then(response => {
            return response.json();
        })
            .then(data => {
                console.log('get all products ', data);
                setState(state => ({ ...state, listProduit: data, productLoad: true }));
                setList(data);
            })
            .catch(error => {
                console.log('get all products error -------------> ', error);
            })

    }


    function deleteLine() {
        setState(state => ({ ...state, isLoad: true }))
        fetch(`http://localhost:3003/produits/${state.itemId}`,
            {
                method: 'delete',
                headers: { 'Content-Type': 'application/json' }
            }
        ).then(response => {
            return response.json();
        })
            .then(data => {
                console.log('delete products ', data);
                getAllProduit();
                setState(state => ({ ...state, isLoad: true }))
                setDel(false);
                openNotificationWithIcon('success', 'Suppression effectuée avec succès !');

            })
            .catch(error => {
                console.log('delete products products error -------------> ', error);
                openNotificationWithIcon('error', 'Error lors de la suppression !');
            })

    }

    function addModalVisible(value) {
        form.setFieldsValue({
            produit: "",
            description: "",
        });
        setVisible(value);
    }

    function updateModalVisible(value) {
        if (value) {
            setUpdate(value);
            setTimeout(() => {
                setState(state => ({ ...state, isLoad: true }))
            }, 1);
        }
        else {
            setUpdate(value);
            setState(state => ({ ...state, isLoad: false }))
        }

    }


    function deleteModalVisible(value) {
        setDel(value);
    }


    function onFinish(value) {
        setState(state => ({ ...state, loading: true }));
        let day = new Date().getDate();
        let month = new Date().getMonth() + 1;
        let year = new Date().getFullYear();
        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;
        value.date = day + '-' + month + '-' + year;
        console.log('typing values --> ', value);
        var config = {
            method: 'post',
            url: `${TEST_API}produits`,
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                // "Authorization": `Bearer ${token}`
            },
            data: JSON.stringify(value)
        };
        axios(config)
            .then(function (response) {
                console.log('response list des produits', response);
                setState(state => ({ ...state, loading: false }));
                openNotificationWithIcon('success', 'Produit ajouté avec succès !');
                getAllProduit();
                setVisible(false);
            })
            .catch(function (error) {
                console.log(error);
                openNotificationWithIcon('error', "Erreur lors de l'enregistrement !");
            });
    }

    function onUpdate(value) {
        setState(state => ({ ...state, loading: true }));
        value.date = state.updateLine.date;
        value.id = state.updateLine.id;
        console.log('updating data --> ', value);
        var config = {
            method: 'put',
            url: `${TEST_API}produits/${state.updateLine.id}`,
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                // "Authorization": `Bearer ${token}`
            },
            data: JSON.stringify(value)
        };
        axios(config)
            .then(function (response) {
                console.log('response update des produit', response);
                setState(state => ({ ...state, loading: false }));
                getAllProduit();
                openNotificationWithIcon('success', 'Mise à jour effectuée avec succès !');
                setUpdate(false);
            })
            .catch(function (error) {
                console.log(error);
                openNotificationWithIcon('error', "Erreur lors de la mise à jour !");
            });
    }

    function onSearch(e) {
        let value = e.target.value;
        console.log('typing values --> ', value);
        let table = [];
        let index = 0;
        let data = list;

        for (let i = 0; i < data.length; i++) {
            if (
                data[i].date.toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1 ||
                data[i].id.toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1 ||
                data[i].produit.toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1 ||
                data[i].description.toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1
            ) {
                table[index] = data[i];
                index++;
            }
        }
        setState(state => ({ ...state, listProduit: table }))
        console.log('filtered list ---> ', table);
    }

    console.log('updating data --> ', state.updateLine);

    return (
        <Card>
            <div style={{padding:20}}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Liste des produits</h4>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Input placeholder="Recherche" style={{ width: 200, marginRight: 10 }} onChange={onSearch} />
                        <Button type="primary" onClick={() => addModalVisible(true)}>
                            <FileAddOutlined />
                            Ajouter un produit
                        </Button>
                    </div>
                </div>
                <br />
                {state.productLoad ? (
                    <Table dataSource={state.listProduit} columns={columns} />
                ) : (<p>En operation bientôt !</p>)}

                {visible && (
                    <Drawer
                        title="Enregistrement de produit"
                        width={720}
                        onClose={() => addModalVisible(false)}
                        visible={visible}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                    >
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            layout="vertical"
                            form={form}
                        >
                            <Form.Item
                                label="Nom du produit"
                                name="produit"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Le nom du produit svp !',
                                    },
                                ]}
                            >
                                <Input placeholder="Nom du produit" />
                            </Form.Item>

                            <Form.Item
                                label="Description du produit"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: 'La description svp !',
                                    },
                                ]}
                            >
                                <Input placeholder="Nom du produit" />
                            </Form.Item>



                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e8e8e8',
                                    padding: '10px 16px',
                                    textAlign: 'right',
                                    left: 0,
                                    background: '#fff',
                                    borderRadius: '0 0 4px 4px',
                                }}
                            >
                                <Button
                                    style={{
                                        marginRight: 8,
                                    }}
                                    onClick={() => addModalVisible(false)}
                                >
                                    Annuler
                                </Button>
                                <Button type="primary" htmlType="submit" loading={state.loading}>

                                    Enregistrer
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                )}

                {update && (
                    <Drawer
                        title="Modification d'un categorie"
                        width={720}
                        onClose={() => updateModalVisible(false)}
                        visible={update}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                    >
                        {state.isLoad ? (
                            <Form
                                name="update"
                                onFinish={onUpdate}
                                layout="vertical"
                            // form={form}
                            >
                                <Form.Item
                                    label="Nom du produit"
                                    name="produit"
                                    initialValue={state.updateLine.produit}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Le nom du produit svp !',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Nom du produit" />
                                </Form.Item>

                                <Form.Item
                                    label="Description du produit"
                                    name="description"
                                    initialValue={state.updateLine.description}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'La description svp !',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Nom du produit" />
                                </Form.Item>

                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        width: '100%',
                                        borderTop: '1px solid #e8e8e8',
                                        padding: '10px 16px',
                                        textAlign: 'right',
                                        left: 0,
                                        background: '#fff',
                                        borderRadius: '0 0 4px 4px',
                                    }}
                                >
                                    <Button
                                        style={{
                                            marginRight: 8,
                                        }}
                                        onClick={() => updateModalVisible(false)}
                                    >
                                        Annuler
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={state.loading}>
                                        Modifier
                                    </Button>
                                </div>
                            </Form>
                        ) : (<Spin />)}
                    </Drawer>
                )}

                {/**********DELETE ALERTE ****** */}
                {del && (
                    <Modal
                        title="Supprimer un produit !!!"
                        visible={del}
                        onOk={() => { deleteModalVisible(false); deleteLine() }}
                        onCancel={() => deleteModalVisible(false)}
                        okText="Confirmer"
                        cancelText="Annuler"
                    >
                        <h3 style={{ color: 'orange' }}> <ExclamationCircleOutlined />  Voulez-vous supprimer vraiment cet element ?</h3>
                    </Modal>
                )}
            </div>
        </Card>
    )
}