import React, { useEffect, useState } from "react";
import "./style.css";
import { CarOutlined, CopyOutlined, EditOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import { DeleteOutline } from "@material-ui/icons";
import { Form, Popconfirm, Space, Table, Tooltip, Input, Tag, Drawer, Button,Alert } from "antd";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { ActionType } from "helpers/helpers";
import * as helpers from 'helpers/helpers';
import ReturnNewTitle from "views/pages/common/ReturnNewTitle";
import ReturnNewLine from "views/pages/common/ReturnNewLine";
import { useBasket } from "../../../../hooks/useBasket";
import FSubscriptionWidget from "../../../common/FSubscriptionWidget";
import QuotationService from "services/quotationService";
import BasketSerivce from "services/basketService";
import FStepsWidget from "../component/FStepsWidget";
import FSettings from "../component/FSettings";
import Basket from "api/model/basket";
import QuotationResponse from "api/v1/response/QuotationResponse";
import { toast } from "react-toastify";
import _ from "lodash";
import BackNextWidget from "../../../common/BackNextWidget";
import MatriculationField2 from "views/pages/subscription/common/fields/MatriculationField2";
import TrailerMatriculationField2 from "views/pages/subscription/common/fields/TrailerMatriculationField2";
import ChassisNumberField2 from "views/pages/subscription/common/fields/ChassisNumberField2";
import { PeriodField, SocialPositionField } from "views/pages/subscription/common/fields";
import Marquee from "react-fast-marquee";
import ReductionField from "views/pages/subscription/common/fields/ReductionField";
import ReductionService from "services/reductionService";

const FStepListVehicle=({baskets, basket, isProject })=> {
    const { Search } = Input;
    const {updateFormField, updateBaskets,updateBasket, goToNextStep, updateGroup} = useBasket();
    const [quoting, setQuoting]= useState(false);

    const [dataSource, setDataSource] = useState(baskets || [])
    const [detailVehicleDrawer, setDetailVehicleDrawer] = useState({
        visible: false,
        basket: null
    });

    const [action, setAction]= useState(ActionType.ADD);
    const [openVehicleDrawer, setOpenVehicleDrawer] = useState(false);
    const [accessorySettingVisibility, setAccessorySettingVisibility] = useState(false);
    const [disabledBtnAddVehicle, setDisabledBtnAddVehicle]=useState(true);
    const [socialSelectedRef, setSocialSelectedRef] = useState(basket?.formField?.socialPosRef);
    const [periodSelectedRef, setPeriodSelectedRef] = useState(basket?.formField?.periodRef)
    const exceptReduction=["FLOTTE", 'RC', 'GROUPEMENT'];
    const [reduction, setReduction] = useState(null)
    const [reductionsSelected, setReductionsSelected] = useState([])
    const [state, setState]=useState({
        primeTotalHF : 0,
        primeTotalTTC : 0,
        nbVehicle: 0,
        reductionsWithoutFlotte: [],
        reductionFlotte: {},
        isQuotingFleet: false,

        reductions: []
    })

    useEffect(()=> {
        
        if(_.size(basket?.formField?.reductionGroups) > 0) {
            setState(state=> ({...state, reductions: basket?.formField?.reductionGroups || []}))
            setReductionsSelected((basket?.formField?.reductionGroups || []).filter((item)=> !exceptReduction.includes(item?.code)))
        }
        else {
            loadReductionGroup()
        }
    }, [])

    useEffect(()=> {
        if(socialSelectedRef && periodSelectedRef) {
            updateListVehicleBySocialRefAndPeriodRef()
        }
    }, [socialSelectedRef, periodSelectedRef])

    useEffect(()=> {
        setDataSource(baskets) 
    }, [baskets]); 

    useEffect(()=> {
        console.log(":::reductionSelected :", reduction)
        if(reduction) {
            let _reductionGroups = basket?.formField?.reductionGroups || [];
            if(_.size(_reductionGroups) <= 0 ) {
                _reductionGroups = [{...reduction}];
            }
            else {
                const index = (_reductionGroups || []).findIndex((rg)=> rg?.code === reduction?.code)
                if(index === -1) {
                    _reductionGroups = [..._reductionGroups, reduction]
                }
                else {
                    _reductionGroups = (_reductionGroups || []).map((rg)=> rg?.code === reduction?.code ? reduction : rg); 
                }
            }
            //reductionsSelected = _.size(_reductionGroups) <= 0 ? [{...reduction}] : (_reductionGroups || []).map((rg)=> rg?.code === reduction?.code ? reduction : rg);
            console.log(":::::::<> : ",_reductionGroups)
            updateGroup([{path:['basket', 'formField', 'reductionGroups'], value: [..._reductionGroups]}])
            
            if(_.size(baskets) > 0 ) {
                const _baskets = (baskets || []).map((item)=> ({
                    ...item, 
                    formField: {
                        ...item?.formField,
                        reductionGroups: [..._reductionGroups]
                    },
                    quotationRequest: {
                        ...item?.quotationRequest,
                        reductionGroups: [..._reductionGroups]
                    },
                    packSelected: {
                        ...item?.packSelected,
                        reductionGroups: [..._reductionGroups]
                    }
                }))
        
                quoteRequest(_baskets)
            }
        }
        
    }, [reduction])

    const loadReductionGroup=()=> {
        ReductionService.readAllReductionsGroup((data)=> {
            console.log("::::: reduction groups, ", data)
            setState(state=> ({...state, reductions: data || []}))
            setReductionsSelected((data || []).filter((item)=> !exceptReduction.includes(item?.code)))
        }, (error)=> {
            console.log("error loading reduction groups: ", error)
        })
    }

    const socialSelectedHandler=(socialSelected)=> {
        setSocialSelectedRef(socialSelected?.ref)
    }

    const periodSelectedHandler=(periodSelected)=> {
        setPeriodSelectedRef(periodSelected?.ref)
    }

    const updateListVehicleBySocialRefAndPeriodRef=()=> {
        setDisabledBtnAddVehicle(false)

        const _baskets = (baskets || []).map((item)=> ({
            ...item, 
            formField: {
                ...item?.formField, 
                periodRef: basket?.formField?.periodRef,
                periodTitle: basket?.formField?.periodTitle,
                socialPosRef: basket?.formField?.socialPosRef,
                socialPosTitle: basket?.formField?.socialPosTitle
            },
            quotationRequest: {
                ...item?.quotationRequest,
                periodRef: basket?.formField?.periodRef,
                socialPosRef: basket?.formField?.socialPosRef
            }}))

        quoteRequest(_baskets)
    }

    const onCloseQuotationDrawer=()=> {
        console.log("::::: closable")
        quoteRequest(baskets)
        setOpenVehicleDrawer(false)
    }

    const quoteRequest=(lbaskets, customPrimeAcc=null)=> {
        setState(state=> ({...state, isQuotingFleet: true}))

        if(_.size(lbaskets) === 0) {
            setState(state=> ({...state, isQuotingFleet: false}))
            return;
        }

        // Build baskets quotationRequests
        const quotationRequests= []
        for(const lbasket of lbaskets) {
            quotationRequests.push({
                ...lbasket?.quotationRequest,
                customPrimeAcc: customPrimeAcc,
                customGuaranties: lbasket?.packSelected?.customGuaranties,
                reductionGroups: lbasket?.packSelected?.reductionGroups,
                customAssistances: lbasket?.packSelected?.customAssistances,
                packLineRef: lbasket?.packSelected?.packLineRef,
                prorate: false,
            })
        }

        // Quote now
        QuotationService.quote({quotationRequests: [...quotationRequests], t: 'f', init: true}, (result)=> {
            const _qutotationResponse = QuotationResponse.fromJson(result);                
            const reductionFlotte = QuotationService.getFlotteReduction(_qutotationResponse?.reductionGroups)
            const reductionsWithoutFlotte = QuotationService.getFlotteReductionWithoutFlotte(_qutotationResponse?.reductionGroups);
            
            // Mettre à jour les baskets
            const _baskets = [];
            _.forEach(lbaskets, (basket, index)=> {
                const _packSelected = _qutotationResponse?.packsResponse[index];
                if(_packSelected) {
                    _baskets.push({
                        ...basket,
                        packSelected: {
                            ..._packSelected,
                            customPrimeAcc: customPrimeAcc,
                            customGuaranties: basket?.packSelected?.customGuaranties,
                            reductionGroups: basket?.packSelected?.reductionGroups,
                            customAssistances: basket?.customAssistances,
                            customPrimeAcc: customPrimeAcc
                        }
                    })
                }
            })

            // Met à jour la vue
            setState(state=> ({
                ...state,
                primeTotalHF: _qutotationResponse?.primeTHFTTC,
                primeFlotteTTC: _qutotationResponse?.primeTTC,
                reductionsWithoutFlotte: reductionsWithoutFlotte,
                reductionFlotte: reductionFlotte,
                nbVehicle: _qutotationResponse?.nbVehicle,
                isQuotingFleet: false
            }));

            updateBaskets(_baskets)
            updateGroup([
                {path: ['quotationResponse'], value: {..._qutotationResponse}}
            ])
        }, (error)=> {
            toast.error("Echec de calcul de cotation flotte")
        })
    }

    const onEditMatriculation =(basketId, newMat)=> {
        console.log("basketId - new :", newMat, basketId)
        const _baskets = (dataSource || []).map((basket)=> ({
            ...basket, 
            formField: {
                ...basket?.formField, 
                matriculation: basket.id === basketId ? newMat : basket?.formField?.matriculation
            }
        }))
        updateBaskets(_baskets);
    }

    const onEditTrailerMatriculation =(basketId, newMat)=> {
        console.log("Trailer basketId - new :", newMat, basketId)
        const _baskets = (dataSource || []).map((basket)=> ({
            ...basket, 
            formField: {
                ...basket?.formField, 
                trailerMatriculation: basket.id === basketId ? newMat : basket?.formField?.trailerMatriculation
            }
        }))
        updateBaskets(_baskets);
    }

    const onEditChassisNumber=(basketId, newChassisNumber)=> {
        const _baskets = (dataSource || []).map((basket)=> ({
            ...basket, 
            formField: {
                ...basket?.formField, 
                chassisNumber: basket.id === basketId ? newChassisNumber : basket?.formField?.chassisNumber
            }
        }))
        updateBaskets(_baskets);
    }

    const onEditTrailerChassisNumber=(basketId, newChassisNumber)=> {
        const _baskets = (dataSource || []).map((basket)=> ({
            ...basket, 
            formField: {
                ...basket?.formField, 
                trailerChassisNumber: basket.id === basketId ? newChassisNumber : basket?.formField?.trailerChassisNumber
            }
        }))
        updateBaskets(_baskets);
    }

    const   onTableAction = (action, lbasket)=> {
        switch(action) {
            case helpers.ActionType.DETAILS:
                setDetailVehicleDrawer({basket: lbasket, visible: true})
            break;

            case helpers.ActionType.COPY:
                const basket = {...lbasket}
                basket.id = null
                basket.formField = {...basket.formField, matriculation: null, chassisNumber: null, trailerMatriculation: null, trailerChassisNumber: null };

                const _baskets = BasketSerivce.addBasketOnFleet(basket, baskets);

                updateBaskets(_baskets);
                quoteRequest(_baskets)
            break;

            case helpers.ActionType.EDIT:
                console.log("Edit basket :", lbasket)
                updateBasket({...lbasket, packSelected: {...lbasket?.packSelected, selected: true}})
                setAction(ActionType.EDIT)
                setOpenVehicleDrawer(true);
            break;

            case helpers.ActionType.DELETE:
                const __baskets = BasketSerivce.removeBasketFromFleet(lbasket, baskets)
                updateBaskets(__baskets)
                quoteRequest(__baskets)
            break;

            default:
        }
    }

   const onSettingItemClick=(e)=> {
        switch(e?.key) {
            case "0":
                setAccessorySettingVisibility(true);
            break;
            case 'RC': 
                const reductionRC = state?.reductions.find((r)=> r.code === 'RC');
                if(reductionRC) {
                    setReductionsSelected([...reductionsSelected, reductionRC])
                }
                
            break;
            default:
        }
        
    }

    const onAccessorySettingValidate=(e)=> {
        if((e || e !== '') && baskets?.length > 0) {
            quoteRequest(baskets, parseInt(e));
        }
    }

    const onValidateBasket=(basket)=> {
        const _baskets = action === ActionType.ADD ? BasketSerivce.addBasketOnFleet(basket, baskets) : BasketSerivce.updateBasketOnFleet(basket, baskets)
        
        console.log(":::: Global basket to quote fleet : ", _baskets)
        // ========= Update global basket
        updateBaskets(_baskets);

        let _basket = new Basket();
        _basket.formField.socialPosRef    = basket?.formField?.socialPosRef;
        _basket.formField.socialPosTitle  = basket?.formField?.socialPosTitle; 
        _basket.formField.periodRef       = basket?.formField?.periodRef;
        _basket.formField.periodTitle     = basket?.formField?.periodTitle;
        _basket.formField.startDate       = basket?.formField?.startDate;
        _basket.formField.reductionGroups = basket?.formField?.reductionGroups;    

        _basket.formField.assureCode = basket?.formField?.assureCode; 
        _basket.formField.assureFirstname = basket?.formField?.assureFirstname;
        _basket.formField.assureLastname = basket?.formField?.assureLastname;
        _basket.formField.assurePhone = basket?.formField?.assurePhone;
        _basket.formField.assureEmail = basket?.formField?.assureEmail;
        _basket.formField.assureBirthDay = basket?.formField?.assureBirthDay;
    
        _basket.formField.assureIdentityCardRef = basket?.formField?.assureIdentityCardRef; // String of Card Object
        _basket.formField.assureIdentityCardTitle = basket?.formField?.assureIdentityCardTitle;
        _basket.formField.assureIdentityCardNumber = basket?.formField?.assureIdentityCardNumber;

        updateBasket(_basket);
        // ================================
        setOpenVehicleDrawer(false)
        quoteRequest(_baskets)
    }

    const columns = [
        {
            title: 'N°',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (item, basket)=> {
                return (
                    <div>{basket?.id}</div>
                )
            }
        },
        {
            title: 'Cat.',
            dataIndex: 'category',
            key: 'category',
            width: 50,
            render: (item, basket)=> {
                return (
                    <div>{basket?.packSelected?.category?.code}</div>
                )
            }
        },
        {
            title: 'Marque | Modèle',
            dataIndex: 'formField',
            key: 'brandTitle',
            width: 300,
            render: (formField, basket)=> <div>{formField?.brandTitle} | {formField?.modelTitle}</div>
        }, 
        // {
        //     title: 'Modèle',
        //     dataIndex: 'formField',
        //     key: 'modelTitle',
        //     render: (formField, index)=> <div>{formField?.modelTitle}</div>
        // },
        {
            title: 'Matricule',
            dataIndex: 'formField',
            render: (formField, basket)=> {
                
                return (
                    <div >
                        {`${formField?.matriculation ?? "" } ${['403'].includes(formField?.categoryCode) && formField?.trailerMatriculation ? ` || ${formField?.trailerMatriculation}` : ''}`  }
                        {!formField?.matriculation && onEditMatriculation && (
                            <MatriculationField2
                                label="Immatriculation du vehicule"
                                listMats= {dataSource.map((__basket)=> __basket?.formField?.matriculation)}
                                currentId= {basket?.id}
                                onConfirm={onEditMatriculation}  
                                width={250}
                            />
                        ) }

                        {
                            ['403'].includes(formField?.categoryCode) && (
                                !formField?.trailerMatriculation && formField?.hasTrailer && onEditMatriculation && (
                                    <TrailerMatriculationField2
                                        label="Immatriculation de la remorque"
                                        listMats={ dataSource.map((__basket)=> __basket?.formField?.trailerMatriculation)} 
                                        currentId= {basket?.id}
                                        onConfirm={onEditTrailerMatriculation} 
                                        width={250}   
                                    />
                                )
                            )
                        }
                    </div>

                    // <Form.Item
                    //     label=""
                    //     name="matriculation"
                    //     rules={[{ required: false, message: "Champ obligatoire" }]}
                    // >
                    //     <div className="input-group">
                    //         <Input
                    //             type="text"
                    //             className="form-control bg-light border-1 is-invalid"
                    //             placeholder="Numero d'Immatriculation du vehicule"
                    //             onChange={checkMatriculation}
                                
                    //         />
                    //         <button className="btn btn-primary" type="button" id="button-addon2" onClick={()=> onEditMatriculation(basket)}><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{" "}</button>
                    //     </div>
                        
                    //     {matriculeExist?.exist ? (<span style={{ color: 'red', fontSize:11 }}>{matriculeExist.errorMessage}</span>) : null}
                        
                    // </Form.Item>
                
                )
            }
        },
        {
            title: 'N° chassis',
            dataIndex: 'formField',
            key: 'chassisNumber',
            align: 'top',
            render: (formField, basket)=> {
                return (
                        <div className="tabInput">
                            {`${formField?.chassisNumber ?? "" } ${['403'].includes(formField?.categoryCode) && formField?.trailerChassisNumber ? ` || ${formField?.trailerChassisNumber}` : ''}`  }
                            
                            {!formField?.chassisNumber && onEditChassisNumber && (
                                    <ChassisNumberField2 
                                        label="N° Chassis du vehicule"
                                        listChassis={ dataSource.map((__basket)=> __basket?.formField?.chassisNumber)}
                                        currentId= {basket?.id}
                                        onConfirm={onEditChassisNumber} 
                                        width={250}
                                    />
                                ) 
                            }
                            
                            {['403'].includes(basket?.formField?.categoryCode) && !formField?.trailerChassisNumber && formField?.hasTrailer && onEditChassisNumber && (
                                <ChassisNumberField2 
                                    label="N° Chassis de la remorque"
                                    listChassis={ dataSource.map((__basket)=> __basket?.formField?.trailerChassisNumber)}
                                    currentId= {basket?.id}
                                    onConfirm={onEditTrailerChassisNumber} 
                                    width={250}
                                />
                            ) }
                            
                        </div>

                        // <Form.Item
                        //     label=""
                        //     name="chassisNumber"
                        //     rules={[{ required: true, message: "Champ obligatoire" }]}
                        // >
                        //     <div className="input-group">
                        //         <Input
                        //             type="text"
                        //             className="form-control bg-light border-1 is-invalid"
                        //             placeholder="Numero de chassis"
                        //             onChange={checkChassis}
                        //         /> <button className="btn btn-primary" type="button" id="button-addon2" onClick={()=> onEditChassisNumber(basket)}>
                        //             <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{" "}
                        //         </button>
                        //     </div>
                            
                        //     {/* {chassisExist?.exist ? (<span style={{ color: 'red', fontSize:11 }}>Le matricule existe déjà</span>) : null} */}
                            
                        // </Form.Item>
                     
                );
            }
        },
        {
            title: 'Pack',
            dataIndex: 'packSelected',
            key: 'packTitle',
            width: 150,
            render: (packSelected, basket)=> (
                <div>{packSelected?.packTitle}</div>
            )
        },
        {
            key: 'primeHFTTC',
            title: 'Prime au condit. mono',
            dataIndex: 'primeHFTTC',
            align:  "right",
            width: 200,
            fixed: 'right',
            render: (packSelected, basket)=> (
                <Tag color="volcano" key={`mono_${basket?.id}`}>
                    <div style={{fontWeight: 'bold'}}>{helpers.thousandSeparator(basket?.packSelected?.primeHFTTC)} F CFA</div>
                </Tag>
            )
        },
        {
            key: 'packSelected',
            title: 'Prime au condit. flotte',
            dataIndex: 'packSelected',
            align:  "right",
            fixed: 'right',
            width: 200,
            render: (packSelected, basket)=> (
                <Tag color="green" key={`flotte_${basket?.id}`}>
                    <div style={{fontWeight: 'bold'}}>{helpers.thousandSeparator(packSelected?.primeTTC)} F CFA</div>
                </Tag>
            )
        },
        {
            title: 'Action',
            fixed: 'right',
            width: 100,
            render: (item, basket) => (
                <div style={{ display: 'flex' }}>
                    <Space>
                        <Tooltip title="Details">
                            <EyeOutlined style={{ color: '#40AEE0' }}  onClick={()=> setDetailVehicleDrawer({visible: true, basket: {...basket}})}/>
                        </Tooltip>
                        {
                            onTableAction && quoteRequest && (
                                <>
                                    <Tooltip title="Modifier">
                                        <EditOutlined style={{ color: '#40AEE0' }}  onClick={()=> onTableAction(ActionType.EDIT, basket)}/>
                                    </Tooltip>
                                    <Tooltip title="Dupliquer">
                                        <CopyOutlined style={{ color: '#40AEE0' }}  onClick={()=> onTableAction(ActionType.COPY, basket)}/>
                                    </Tooltip>
                                    <Popconfirm
                                            placement="top"
                                            title="Voulez-vous vraiment supprimer le vehicule ?"
                                            cancelText="Annuler"
                                            okText="Confirmer"
                                            okType="danger"
                                            onConfirm={()=> onTableAction(ActionType.DELETE, basket)}
                                            
                                        >
                                            <DeleteOutline style={{ color: 'red', fontSize: 18, cursor: 'pointer' }}/>
                                            
                                        </Popconfirm>
                                </>
                            )
                        }

                    </Space>
                </div>

            )
        }
    ];

    return(
        <React.Fragment>
            <Alert
                banner
                message={
                <Marquee pauseOnHover gradient={false}>
                    Les véhicules éligibles à la reduction flottes sont ceux de la catégorie 401, 402, 403.{" "}.
                </Marquee>
                }
            />
            <FSubscriptionWidget 
                visibility={baskets?.length > 0}
                primeTotalHF={state.primeTotalHF} 
                primeFlotteTTC={state?.primeFlotteTTC}
                rate={state?.reductionFlotte?.rate}
                nbVehicle={state.nbVehicle}
                isQuoteLoading={quoting}/>
            <Card>
                <CardHeader style={{paddingBottom: 0}}>
                    <div style={{display: 'flex', justifyContent: "space-between"}}>
                        <h4>Liste des vehicules </h4>
                        {/* <div style={{display: 'flex', justifyContent:'flex-start'}}>
                            <SocialPositionField 
                                suffixIcon={"Status"}
                                handlerFormField={updateFormField} 
                                onSocialSelected={setSocialSelected}
                                size="medium"
                                formItemStyle={{label:'', style:{width: 250, margin: 0}}}
                                xs={12} md={12}/>
                            <PeriodField 
                                suffixIcon={"Durée"}
                                handlerFormField={updateFormField} 
                                periodSelected={setPeriodSelected}
                                formItemStyle={{label:'', style:{width: 250, margin: 0, marginLeft:5}}}
                                size="medium"
                                xs={12} md={12}/>
                        </div> */}
                        <div style={{display: 'flex', justifyContent: "flex-end"}}>
                            {accessorySettingVisibility && (
                                <div className="btn-label  nexttab mb-2 mr-5">
                                    <Search
                                        style={{ width: 250 }}
                                        placeholder="Prime accessoire"
                                        allowClear
                                        enterButton="Valider"
                                        disabled={_.size(baskets) <= 0}
                                        size="medium"
                                        onSearch={onAccessorySettingValidate}
                                        type="number"
                                        />
                                </div>
                            )}

                            <div className="nexttab mb-2 mr-5" style={{width: '800px'}}>
                                <Form initialValues={{socialPosRef: basket?.formField?.socialPosRef, periodRef: basket?.formField?.periodRef}}>
                                    <div style={{display:'flex', justifyContent:'end'}}>
                                        <SocialPositionField 
                                            suffixIcon={"Status"}
                                            handlerFormField={updateFormField} 
                                            onSocialSelected={socialSelectedHandler}
                                            size="medium"
                                            formItemStyle={{label:'', style:{width: 250, margin: 0, marginLeft: 5}}}
                                            activateCol={false}
                                            xs={8} md={8}/> 
                                        <PeriodField 
                                            suffixIcon={"Durée"}
                                            handlerFormField={updateFormField} 
                                            periodSelected={periodSelectedHandler}
                                            formItemStyle={{label:'', style:{width: 250, margin: 0, marginLeft:5, marginRight:5}}}
                                            size="medium"
                                            xs={8} md={8}/>
                                        {
                                            (reductionsSelected || []).map((item)=> (
                                                <ReductionField 
                                                    reduction={item}
                                                    setReduction={setReduction}
                                                    suffixIcon={item?.code} 
                                                    size="medium" 
                                                    formItemStyle={{label:'', style:{width: 100, margin: 0, marginLeft:5, marginRight:5}}}
                                                    xs={3} md={3}/>
                                            ))
                                        }
                                        
                                    </div>
                                </Form>
                            </div>
                            
                            <Button 
                                disabled={disabledBtnAddVehicle}
                                onClick={ ()=> setOpenVehicleDrawer(true) } 
                                type="button" 
                                className="btn btn-success btn-label right nexttab mb-2 btn-sm mr-5 ml-5">
                                <i className="bx bx-plus label-icon align-middle fs-16 ms-2"></i>{" "}
                                <CarOutlined style={{ color: '#ffffff', fontSize: 20 }} /> AJOUTER VEHICULE 
                            </Button>
                            <div className="btn-label  nexttab mb-2">
                                <FSettings onItemClick={onSettingItemClick} /> {" "}
                            </div>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="modal-body">
                        <Row>
                            <Col lg={12}>
                                <Table loading={state?.isQuotingFleet} columns= {columns} dataSource= {dataSource}/>
                            </Col>

                            <Col lg={12}>
                                <BackNextWidget 
                                    confirmText={"CONTINUER"}
                                    onConfirm={()=> goToNextStep()}
                                    confirmIcon={(<UserOutlined style={{ color: '#ffffff', fontSize: 20 }} />)}
                                    disableConfirm={_.size(dataSource) < 1}
                                    icon={UserOutlined}
                                    />
                            </Col>
                        </Row>
                    </div> 
                </CardBody>
            </Card>
            
            {/* Quotation panel */}
            {openVehicleDrawer && (
                <Drawer
                    title="Cotation"
                    onClose={()=> onCloseQuotationDrawer()}
                    closable={true}
                    open={openVehicleDrawer}
                    placement="right"
                    width="50vw"
                    
                    /*style={{ minWidth: "50vw" }}*/>
                        <FStepsWidget 
                            action={action}
                            basket={basket}
                            onValidateBasket={onValidateBasket}
                            setOpenVehicleDrawer={setOpenVehicleDrawer}
                            />
                </Drawer>
            )}
            
            {/* Basket details panel */}
            {detailVehicleDrawer.visible && (
                <Drawer
                    title="Détails du contract"
                    placement="right"
                    width="40%"
                    onClose={() => setDetailVehicleDrawer({visible: false})}
                    visible={detailVehicleDrawer.visible}>
                    <>
                        {ReturnNewTitle('Informations sur le vehicule')}
                        {ReturnNewLine('Category', `${detailVehicleDrawer?.basket?.formField?.categoryCode} : ${detailVehicleDrawer?.basket?.formField?.categoryTitle}`, false)}   
                        {ReturnNewLine('Energie', detailVehicleDrawer.basket?.formField?.energyTitle, false)}
                        {ReturnNewLine('Nombre de places assises', detailVehicleDrawer.basket?.formField?.nbPlace, false)}
                        {ReturnNewLine('Puissance fiscale', detailVehicleDrawer.basket?.formField?.fiscalPower + ' Cv', false)}
                        {ReturnNewLine('Date de 1ere mise en circulation', new Date(detailVehicleDrawer.basket?.formField?.dateMec).toLocaleDateString(), false)}
                        {ReturnNewLine('Valeur neuve', detailVehicleDrawer.basket?.formField?.newValue + ' FCFA', false)}
                        {ReturnNewLine('Valeur venale', detailVehicleDrawer.basket?.formField?.marketValue + ' FCFA', false)}
                        {ReturnNewLine('Balise GPS', detailVehicleDrawer.basket?.formField?.hasGps ? "Oui" : "Non", false)}
                        {ReturnNewLine('Zone de circulation', detailVehicleDrawer.basket?.formField?.cityTitle, true)}

                        {/* {returnNewTitle('Options et Garanties')} */}

                        {ReturnNewLine('Periode de couverture', detailVehicleDrawer.basket?.formField?.periodTitle, false)}
                        {/* {returnNewLine('Formule', state.listSoucription.garantie.title, false)} */}
                        {/* {returnNewLine('Franchise', state?.souscription?.packResponse?.franchise?.title, true)} */}
                        {/* {returnNewLine('Avance sur recours', state.listSoucription.avanceRecours ? "Oui" : "Non", true)} */}

                        {ReturnNewTitle('Package et reduction')}
                        {ReturnNewLine('Package', detailVehicleDrawer.basket?.packSelected?.packTitle, true)}

                        {ReturnNewTitle('Autres informations sur le vehicule', false)}
                        {ReturnNewLine('Marque', detailVehicleDrawer.basket?.formField?.brandTitle, false)}
                        {ReturnNewLine('Modèle', detailVehicleDrawer.basket?.formField?.modelTitle, false)}
                        {ReturnNewLine('Immatriculation', detailVehicleDrawer.basket?.formField?.matriculation, false)}
                        {ReturnNewLine('Numero de chassis', detailVehicleDrawer.basket?.formField?.chassisNumber, true)}

                        {/* {returnNewTitle('Informations sur l\'assuré')}

                        {returnNewLine('Nom', _initAvenantResponse?.subscription?.assure?.firstName)}
                        {returnNewLine('Prenom', _initAvenantResponse?.subscription?.assure?.lastName, false)}
                        {returnNewLine('Numero mobile', _initAvenantResponse?.subscription?.assure?.phone, true)}
                        {returnNewLine('Nature de la piece d\'identité', _initAvenantResponse?.subscription?.assure?.identityCardTitle, false)}
                        {returnNewLine('Numéro de la piece d\'identité', _initAvenantResponse?.subscription?.assure?.identityCardNumber, true)} */}

                        {/* {returnNewTitle('Détails sur le contrat')} */}


                        {/* {returnNewTitle('Reduction et Prime', false)} */}

                        {/* {returnNewLine('Frais d\'agence', state?.listSoucription?.package?.primeTTC, false)} */}
                        {/* {returnNewLine('Prime TTC', _initAvenantResponse?.packResponse?.primeTTC + ' FCFA', true)} */}
                    </>
                </Drawer>
            )}
            
        </React.Fragment>
    );
}

export default FStepListVehicle;