import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';

import { useHistory, useLocation } from 'react-router-dom';

import layoutTheme from 'modules/assets/themes/layoutTheme';
import useLayoutOneStyles from '../styles'
import { Topbar, Footer } from '../components'
import Sidebar from './Sidebar'
import { Tab, Tabs } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {},
}));

function LayoutTwo(props) {
  const { actionsWidget, companyName, routes, widgets, sideBarItems, topBarItems, tabItems, ...restProps } = props;
  const theme = {...layoutTheme, ...restProps.theme};

  const layoutOneClasses = useLayoutOneStyles();
  const classes = useStyles();

  return (
    <div fluid="true" className={classes.root}>
      <Topbar
        companyName={companyName}
        topBarItems={topBarItems}
        sideBarItems={sideBarItems}
        actionsWidget= {actionsWidget}
        theme={theme}
      />

      <Sidebar theme={theme} items={sideBarItems} topBarItems={topBarItems} />

      <div className={layoutOneClasses.contentContainer}>

        <div className={layoutOneClasses.content}>
          <div className={layoutOneClasses.topSpacerHeight} />

            <div>{widgets}</div>

            <div style={{marginLeft: 10, marginRight:10}}>
              <CustomTabs tabItems={tabItems} theme={theme} />
            </div>

            <div className={layoutOneClasses.routesContentContainer} >
              <div className={layoutOneClasses.routesContent} >
                {routes}
              </div>
            </div>

            {/* <div className={layoutOneClasses.spacerHeight} > </div>
 */}
            <div className={layoutOneClasses.footer}>
              <Footer companyName={companyName} theme={theme} />
          </div>
        </div>
      </div>
    </div>
  );
}

function CustomTabs({ tabItems, theme }) {
  const { push } = useHistory();

  const { pathname } = useLocation();

  let currentTabItems = [];

  for (let tabProperty in tabItems) {

    if (pathname.includes(tabProperty)) {
      currentTabItems = tabItems[tabProperty]
      break;
    }
  }

  const [value, setValue] = React.useState(pathname);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    push(newValue);
  };

  useEffect(() => {
    handleChange(null, pathname)
  }, [pathname]);

  if (!(currentTabItems?.length > 1)) return null;

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable digin factory"
      style={{ background: theme.tab.backgroundColor }}
    >
      {currentTabItems?.length > 1 && currentTabItems?.map(tab => (
        <Tab
          label={tab.title}
          key={tab.title}
          id={tab.url}
          value={tab.url}
          style={{ color: "white", background: pathname === tab.url ? theme.tab.selectedItemColor : '', fontSize: 12 }}
        />
      ))}
    </Tabs>
  )
}

export default LayoutTwo
