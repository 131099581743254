import React from "react";
import './style.css'

const PasswordSuggestions=({suggestions=[]})=> {
    return (
        <React.Fragment>
            <ul>
                {suggestions.map((suggestion, index) => (
                <li key={suggestion}>{suggestion}</li>
                ))}
            </ul>
        </React.Fragment>
    )
}

export default PasswordSuggestions;