import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { EditOutlined, EyeOutlined, SettingOutlined } from "@ant-design/icons";
import {  Button, Col, Dropdown, Menu, Row, Select, Skeleton, Space } from "antd";
import { thousandSeparator } from "helpers/helpers";
import SettingPackage from "./SettingPackage";
import { useBasket } from "views/pages/subscription/hooks/useBasket";
import _ from "lodash";
import QuotationService from "services/quotationService";
import QuotationResponse from "api/v1/response/QuotationResponse";
import { toast } from "react-toastify";

const menu =(handleMenuClick)=> (
    <Menu
        onClick={handleMenuClick}
        items={[
            {
                label: (
                    <>RC: Reduction commerciale</>
                ),
                key: '0',
                disabled: false
            },

            {
                type: 'divider',
            },
            {
                label: 'BNS: Bonification pour sinistre',
                key: '1',
                disabled: true,
            },
        ]}
    />
);
function AssurancePackage({packsResponse, packSelect, quotationRequest, onPackSelected, isPackCarteOn, onReductionSelected, reductionCustomerRates=[0, 5, 10, 15, 20], noEditingReduction=false, md, xs}) {
    const { Option } = Select;
    const exceptReduction=["FLOTTE"];
    const {updateGroup, updateBasket} = useBasket()
    const [settingPackageVisibility, setSettingPackageVisibility] = useState(false);
    //const reductionCustomerRates = [0, 5, 10, 15];
    const [packs, setPacks] = useState((packsResponse || []).map((pack)=> {
        return ({...pack, reductionGroups: (pack?.reductionGroups || []).map((rg)=> ({...rg, editing: false, status: (rg?.code !== "RC")})) })
    }));
    const [packSelected, setPackSelected] = useState({...packSelect, isPrimeLoading: false});

    useEffect(()=> {
        const _packs = (packs || []).map((pack)=> pack?.packLineRef == packSelect?.packLineRef ? packSelect : pack)
        setPacks(_packs);
    }, [packSelect])

    const treatReductionGroup=(packs)=> {
        const _packs = (packs || []).map((pack)=> {
            const reductionGroups = (pack?.reductionGroups || []).map((rg)=> ({...rg, editing: false, status: (rg?.code !== "RC")}))
            return ({...pack, reductionGroups: [...reductionGroups]})
        })

        return packs
    }

    const toggleReductionVisibility=(e,pack)=> {
        console.log("::::: e :" , e)  
    }

    // <Valider le pack choisi>
    const onConfirmPackSetting=(pack)=> {
        console.log(":::: onconfirm pack", pack)
        const newPacks = (packs || []).map((p)=> p?.packRef === pack?.packRef ? {...p, ...pack } : p)
        
        setPacks(newPacks)
        onPackSelected(pack)
        setDrawerVisibility(false);
    }

    const setDrawerVisibility=(value)=> {
        setSettingPackageVisibility(value);
    }

    const onPackSettingClick=(pack)=> {
        packSelectedHandler(pack)
        setDrawerVisibility(true); 
    }

    const packSelectedHandler=(pack)=> {
        onPackSelected({...pack, selected: true})
        setPackSelected({...pack, selected: true})
    }

    const handleMenuReduction = (pack) => {
        const _packs = (packs || []).map((p)=> {
            const reductionGroups = (p?.reductionGroups || []).map((rg)=> ({
                ...rg, 
                editing: false, 
                status: (rg?.code === "RC" && p?.packRef === pack?.packRef) ? true : rg?.status}));
            return ({...p, reductionGroups: [...reductionGroups]})
        })
        setPacks(_packs)
    };

    const editPackReduction=(pack, reductionGroup)=> {
        //const _reductionGroups = (pack?.reductionGroups || []).map((rg)=> ({...rg, editing: rg?.ref === reductionGroup?.ref ? true : rg?.editing }) );
        const  _packs = (packs || []).map((p)=> ({
            ...p,
            reductionGroups: (p?.reductionGroups || []).map((rg)=> ({
                ...rg, 
                editing: (p?.packRef=== pack?.packRef && rg?.ref === reductionGroup?.ref) ? true : rg?.editing 
            }) )
        }))
        setPacks(_packs)
    }

    const onReductionItemSelected=(reductionGroup, value )=> {
        setPacks((packs || []).map((pack)=> ( {...pack, isPrimeLoading: pack?.packLineRef === packSelected?.packLineRef})))
        // ReductionGroup
        const customReductionGroups = (packSelected?.reductionGroups || []).map((rg)=> ({
            ...rg,
            code: rg?.code,
            ref: rg?.ref,
            rate: (rg?.ref === reductionGroup?.ref) ? (value/100) : rg?.rate
        }));
        console.log("customReductionGroups :" , customReductionGroups)

        const _quotationRequest =  {
            ...quotationRequest,
            reductionGroups: [...customReductionGroups],
            customGuaranties: [...packSelected?.customGuaranties],
            packLineRef: packSelected?.packLineRef
        }

        QuotationService.quote({quotationRequests:[_quotationRequest], t: 'm'}, (result)=> {
            const _quotationResponse = QuotationResponse.fromJson(result);
            const _pack = _.first(_quotationResponse?.packsResponse);
            const _packSelected = {
                ...packSelected, 
                primeTTC: _pack?.primeTTC,
                primeExtra: _pack?.primeExtra,
                primeNette: _pack?.primeNette,
                extras:  [..._pack?.extras],
                reductionGroups: [... customReductionGroups]
            }
            // Mettre à jour seuelement le pack selectionné
            const newPacks = (packs || []).map((p)=> (p?.packRef === packSelected.packRef) ? {..._packSelected, isPrimeLoading: false} : p);

            setPacks(newPacks);
            packSelectedHandler(_packSelected)

        }, (error)=> {
            console.log("Error reduction group :", error)
            toast.error("Echec de reduction")
            setPacks((packs || []).map((pack)=> ( pack?.packLineRef === packSelected?.packLineRef ? {...pack, isPrimeLoading: false} : pack)))
        })
    }

    return (
        <React.Fragment>
            <Row gutter={[8, 8]}>
                {
                    (packs || []).map((pack, index)=> {
                        return (pack?.packCode !== 'PCT' || isPackCarteOn) ? 
                             (
                                <Col className="gutter-row" xs={xs ?? 24} md={md ?? 8} key={`pck${index}`}>
                                    <div
                                        style={{
                                            backgroundColor: packSelected?.packRef === pack.packRef ? "#0075C9" : "#C3C3C3",
                                            borderRadius: 8, padding: 20, boxShadow: "0px 0.5px 0.5px gray", cursor: 'pointer'
                                        }}
                                        onClick={() => packSelectedHandler(pack)}
                                    >
                                        
                                        {/* <SpinnerCircular size={50} thickness={100} speed={100} color="rgba(0, 117, 201, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" /> */}
                                        <h2 style={{ color: 'white', paddingBottom: 5, borderBottom: '1px solid white' }}>{pack?.packTitle}</h2>
                                        <h3 style={{ color: 'white', marginTop: 10 }}>Prime: { 
                                            !pack?.isPrimeLoading ? ((pack?.packCode === 'PCT') ? 
                                                (_.size(pack?.customGuaranties) > 0 ? thousandSeparator(pack?.primeTTC ?? 0) : '****')  : thousandSeparator(pack?.primeTTC ?? 0)) : 
                                            (<Skeleton.Input active={true} size='default' />)} FCFA</h3>

                                        <div style={{margin: ' auto',backgroundColor: 'white', borderRadius: 8, padding: 15}}>
                                            <div onClick={() => onPackSettingClick(pack)} style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', borderBottom: '1px solid #B5B5B5' }} >
                                                <h4>Garanties proposées</h4>
                                                <EyeOutlined />
                                            </div>

                                            <div  onClick={() => onPackSettingClick(pack)}  style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', marginTop: 5, borderBottom: '1px solid #B5B5B5' }} >
                                                <h4>{pack.assistance?.title || "Pas d'assistance"}</h4>
                                                <EyeOutlined style={{ cursor: 'pointer', marginTop: 5 }} />
                                            </div>
                                            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', marginTop: 5 }} >
                                                <Space>
                                                    <span>Reductions:</span>
                                                </Space>
                                                <div style={{ marginTop: 5 }}>
                                                    <Dropdown overlay={menu(()=> handleMenuReduction(pack))} trigger={['click']}>
                                                        <Button type="link" onClick={(e, pack) => toggleReductionVisibility(e, pack)} style={{paddingRight: "0px"}}>
                                                            <SettingOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', marginTop: 5 }} >
                                                <Space>
                                                    {(pack?.reductionGroups || []).filter((rg)=> !exceptReduction.includes(rg?.code) && rg?.status === true).map((reductionGroup)=> (
                                                        <span>| { `${reductionGroup?.code} (${reductionGroup.rate * 100} %)`}  
                                                            {!noEditingReduction && reductionGroup?.editable && (!reductionGroup?.editing  ? 
                                                                <EditOutlined  style={{  color: '#22C556', marginTop: 5 }} onClick={()=> editPackReduction(pack, reductionGroup)} /> : (
                                                                <Select  size='small' style={{width:'55px', marginLeft: '10px'}} onChange={(e)=> onReductionItemSelected(reductionGroup, e )} key={index}>
                                                                    
                                                                    { (reductionGroup?.code == 'RC' ? [ ...reductionCustomerRates, 25, 30, 35, 40, 45, 50 ] : [...reductionCustomerRates]).map((index) =><Option value={index} key={`rdg${index}`} >{index}</Option>)}
                                                                </Select>
                                                            ))}
                                                        </span>
                                                        )
                                                    )}
                                                    
                                                </Space>
                                            </div>
                                        </div>
                                        
                                    </div >
                                </Col>
                            ):(<></>)
                        
                    })
                }
            </Row>
            {
                settingPackageVisibility && (<SettingPackage 
                    packSelect={packSelected}
                    quotationRequest={quotationRequest}
                    drawerVisibility={settingPackageVisibility}
                    onConfirmPackSetting={onConfirmPackSetting}
                    setDrawerVisibility={setDrawerVisibility} 
                />)
            }
        </React.Fragment>
        
    )
};

export default AssurancePackage;