export default class CreateCategoryRequest {
    code;
    title;
    description;

    static fromJson = (json = {}) => {
        var createCategoryRequest = new CreateCategoryRequest();
        createCategoryRequest = json;
        return createCategoryRequest;
    }
}