import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class SerialService {
    
    static getAll(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.getSeriesResource, {}, sCallBack, eCallBack)
    }

    static getAllNatures(sCallBack, eCallBack){
        exeRequest(ApiRoutes.getSerialNatureResource, {}, sCallBack, eCallBack)
    }

    static registerSerial(payload, sCallBack, eCallBack){
        exeRequest(ApiRoutes.saveSerialResource, payload, sCallBack, eCallBack)
    }

    static getSerialDetail(options, sCallBack, eCallBack){
        ApiRoutes.getSerialLinesResource.resourceOptions = Object.assign({}, ApiRoutes.getSerialLinesResource.resourceOptions, options);
        exeRequest(ApiRoutes.getSerialLinesResource, {}, sCallBack, eCallBack)
    }

    static dissociateSerial(options, payload, sCallBack, eCallBack ) {
        ApiRoutes.dissociateSerialResource.resourceOptions = Object.assign({}, ApiRoutes.dissociateSerialResource.resourceOptions, options);
        exeRequest(ApiRoutes.dissociateSerialResource, payload, sCallBack, eCallBack)
    }

}