import React from "react";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import * as helpers from 'helpers/helpers';
import { Tag } from "antd";

const FSubscriptionWidget=({visibility, primeTotalHF, primeFlotteTTC,  rate, nbVehicle, isQuoteLoading, monoConditionVisibility=true})=> {

    if(!visibility) return null;

    return (
        <React.Fragment>
             <Row>
                <Col xl={12}>
                    <Card className="crm-widget">
                        <CardBody className="p-0 right">
                            <Row className="row-cols-md-3 row-cols-1">
                                {monoConditionVisibility ? (
                                    <Col className={"col-lg border-end"}>
                                        <PrimeOnMonoCondition primeTotalHF={primeTotalHF}/>
                                    </Col>
                                ): null}
                                <Col className={"col-lg border-end"}>
                                    <div className="mt-3 mt-md-0 py-4 px-3">
                                        <h5 className="text-muted text-uppercase fs-13">Taux </h5>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <i className={"display-6 text-muted ri-percent-line"}></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h2 className="mb-0"><span className="counter-value">
                                                    {(rate || 0) * 100}</span></h2>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col className={"col-lg"}>
                                    <div className="mt-3 mt-md-0 py-4 px-3">
                                        <h5 className="text-muted text-uppercase fs-13">Total vehicule </h5>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <i className={"display-6 text-muted ri-car-line"}></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h2 className="mb-0"><span className="counter-value">
                                                {nbVehicle}</span></h2>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col className={"col-lg"}>
                                    <div className="mt-3 mt-md-0 py-4 px-3">
                                        <h5 className="text-muted text-uppercase fs-13">Prime flotte </h5>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                <i className={"display-6 text-muted ri-exchange-dollar-line"}></i>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h2 className="mb-0">
                                                    <span className="counter-value">
                                                        <Tag color= "rgb(0, 117, 201)" style={{ fontSize: 22, padding: "2px"}}>
                                                            <span className="d-flex align-items-center">
                                                                {isQuoteLoading ? <Spinner size="sm" type="grow" className="flex-shrink-0"> Loading... </Spinner> : null}
                                                                {`${helpers.thousandSeparator(primeFlotteTTC)} F CFA`} 
                                                                {/* <CountUp
                                                                    start={0}
                                                                    prefix=""
                                                                    suffix=" F CFA"
                                                                    separator={' '}
                                                                    end={primeFlotteTTC}
                                                                    decimals={0}
                                                                    duration={1}
                                                                /> */}
                                                            </span> 
                                                        </Tag>
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default FSubscriptionWidget;

const PrimeOnMonoCondition=({primeTotalHF})=> {
    return (
        <React.Fragment>
            <div className="mt-3 mt-md-0 py-4 px-3">               
                <h5 className="text-muted text-uppercase fs-13">Prime au cond. mono<i className={"fs-18 float-end align-middle "}></i></h5>
                <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                        <i className={"display-6 text-muted ri-exchange-dollar-line" }></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <h2 className="mb-0"><span className="counter-value">
                            {`${helpers.thousandSeparator(primeTotalHF)} F CFA`} 
                            {/* <CountUp
                                start={0}
                                prefix=""
                                suffix=" F CFA"
                                separator={' '}
                                end={primeTotalHF}
                                decimals={0}
                                duration={1}
                            /> */}
                            </span></h2>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}