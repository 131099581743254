import Contract from "api/model/contract.model";
import Pack from "api/model/pack";
import SubscriptionRequest from "api/request/subscriptionRequest";
import QuotationResponse from "api/v1/response/QuotationResponse";

export default class InitAvenantResponse {
    avenantCode;
    startDate;
    endDate;
    quotationResponse = new QuotationResponse();
    contract = new Contract();
    subscription;
    
   
    periodTitle; // Use only for avenant prorogation
    

    fromJson = (json = {}) => {
        var initAvenantResponse = new InitAvenantResponse();
        initAvenantResponse = json;
        return initAvenantResponse;
    }
}