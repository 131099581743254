import ApiRoutes from "api/apiRoutes";
import QuotationResponse from "api/v1/response/QuotationResponse";
import _, { random } from "lodash";
import { toast } from "react-toastify";
import { exeRequest } from "../http/requestConfig";

export default class QuotationService {
    generatePacks(payload, sCallBack, eCallBack, options) {
        if (options) {
            ApiRoutes.quotationPackResource.resourceOptions = Object.assign({}, ApiRoutes.quotationPackResource.resourceOptions, options);
        }

        exeRequest(ApiRoutes.quotationPackResource, payload, sCallBack, eCallBack)
    }

    static flotteGeneratePacks(payload, sCallBack, eCallBack, options) {
        if (options) {
            ApiRoutes.flotteQuotationPackResource.resourceOptions = Object.assign({}, ApiRoutes.flotteQuotationPackResource.resourceOptions, options);
        }
        exeRequest(ApiRoutes.flotteQuotationPackResource, payload, sCallBack, eCallBack)
    }

    static v1GeneratePacks(options, payload, sCallBack, eCallBack) {
        if(options) {
            ApiRoutes.v1QuotationPackResource.resourceOptions = Object.assign({}, ApiRoutes.v1QuotationPackResource.resourceOptions, options);
        }
        exeRequest(ApiRoutes.v1QuotationPackResource, payload,  sCallBack, eCallBack )
    }

    static async quoteBaskets({baskets, prorate=false, t=null, init=false, targetPackRef=null}, sCallBack, eCallBack) {
        let quotationRequests = [];
        
        quotationRequests = (baskets || []).map((basket, index)=> {
            const quotationRequest = {
                ...basket?.quotationRequest,
                key: basket?.key,
                packLineRef: targetPackRef ?? null,  //basket?.packSelected?.packLineRef,
                prorate: prorate || false
            };
            return quotationRequest;
        });

        const options = {query: {t: t, init: init}}
        
        ApiRoutes.v1QuotationPackResource.resourceOptions = Object.assign({}, ApiRoutes.v1QuotationPackResource.resourceOptions, options);
        await exeRequest(ApiRoutes.v1QuotationPackResource, quotationRequests, (data)=> {
            
            // const _baskets = [];
            const quotationResponse = QuotationResponse.fromJson(data);
            // console.log(":::: quotationResponse :", quotationResponse)
            // // Fleet treatment
            // for (const basket of baskets) {
            //     console.log(":::: Quoteservice basket bedore: ", basket)
            //     let packs = (quotationResponse?.packsResponse || []).filter((packResponse)=> packResponse?.key === basket?.key);
            //     const _packSelected = (packs || []).find((pack)=> pack?.packLineRef === basket?.packSelected?.packLineRef)
                
                
            //     _baskets.push({
            //         ...basket,
            //         quotationResponse: quotationResponse,
            //         packSelected: {
            //             ...basket?.packSelected, 
            //             ..._packSelected
            //         }
            //     })
            // }
            // End fleet treatment
        
            sCallBack(quotationResponse)
            
        }, (error)=> {
            toast.error("Une erreur s'est produite pendant la cotation")
            eCallBack(error)
        } )
    }

    static async quote({quotationRequests, t=null, init=false}, sCallBack, eCallBack) {
        const options = {query: {t: t, init: init}}
        ApiRoutes.v1QuotationPackResource.resourceOptions = Object.assign({}, ApiRoutes.v1QuotationPackResource.resourceOptions, options);
        await exeRequest(ApiRoutes.v1QuotationPackResource, quotationRequests, (data)=> {
            const quotationResponse = QuotationResponse.fromJson(data);
            sCallBack(quotationResponse)
        }, (error)=> {
            toast.error("Une erreur s'est produite pendant la cotation")
            eCallBack(error)
        } )
    }

    static calculPrimeTTCHFFromBaskets(baskets) {
        let primeTTC = 0;
        for(const basket of baskets) {
            primeTTC += basket?.packSelected?.primeHFTTC || 0
        }

        return primeTTC;
    }

    static calculPrimeNetFromBaskets(baskets) {
        let primeNette = 0;
        for(const basket of baskets) {
            primeNette += basket?.packSelected?.primeNette || 0
        }

        return primeNette;
    }

    static getFlotteReduction(reductions) {
        const reductionGroup = (reductions || []).find((reduction)=> reduction?.code === 'FLOTTE');
        return reductionGroup;
    }

    static getFlotteReductionWithoutFlotte(reductions) {
        const reductionGroup = (reductions || []).find((reduction)=> reduction?.code !== 'FLOTTE');
        return reductionGroup || [];
    }
}