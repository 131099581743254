import { FileDoneOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import SubscriptionResponse from "api/response/subscriptionResponse";
import TransactionResponse from "api/response/transactionResponse";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import SubscriptionService from "services/subscriptionService";
import TransactionService from "services/transactionService";
import { ContractType } from "../ComponentWidget";
import * as helpers from 'helpers/helpers';

const PaymentEspece=({payload, onPaymentStatus , type})=> {
    const [state, setState] = useState({
        isOnlineSending: false
    });

    // <validate Souscription >
    const onlineProcess= ()=> {
        setState(state=> ({...state, isOnlineSending: true }));
        
        switch(type) {
            case ContractType.MONO:
                monoSuccessProcess()
            break

            case ContractType.FLOTTE:
                onFlotteSuccessProcess()
            break;
            default:
        }
    }

    //---------------- SUCCESS TREATMENT------------------------
    // MONO
    const monoSuccessProcess= ()=> {
        
        (new SubscriptionService()).generateTransaction(payload, (data)=> {
            let subscriptionresponse = (new SubscriptionResponse()).fromJson(data);
            if(subscriptionresponse.transactionId !== null || subscriptionresponse.transactionId !== undefined || subscriptionresponse.transactionId !== "") {
                notifyTransaction(subscriptionresponse.transactionId)
            }
            else {
                toast.error("Une erreur est survenue pendant la transaction, veuillez contacter l'administration");
                setState(state=> ({...state, isOnlineSending: false }));
                onPaymentStatus({error: true, errorMessage: "Une erreur est survenue pendant la transaction, veuillez contacter l'administration"});
            }
        }, (error)=> {
            toast.error(error);
            onPaymentStatus({error: true, errorMessage: error});
            setState(state=> ({...state, isOnlineSending: false }))
        })
    }

    // FLOTTE
    const onFlotteSuccessProcess= ()=> {
        (new SubscriptionService()).flotteGenerateTransaction(payload, (data)=> {
            let subscriptionresponse = (new SubscriptionResponse()).fromJson(data);
            if(subscriptionresponse.transactionId !== null || subscriptionresponse.transactionId !== undefined || subscriptionresponse.transactionId !== "") {
                notifyTransaction(subscriptionresponse.transactionId)
            }
            else {
                toast.error("Une erreur est survenue pendant la transaction, veuillez contacter l'administration");
                setState(state=> ({...state, isOnlineSending: false }));
                onPaymentStatus({error: true, errorMessage: "Une erreur est survenue pendant la transaction, veuillez contacter l'administration"});
            }
        }, (error)=> {
            toast.error(error);
            onPaymentStatus({error: true, errorMessage: error});
            setState(state=> ({...state, isOnlineSending: false }))
        })
    }

    const notifyTransaction =(transactionID)=> {
        (new TransactionService()).notify(transactionID, (data)=> {
            let _transactionResponse = (new TransactionResponse()).fromJson(data);
            //console.log(".... _transactionResponse: ", _transactionResponse);
            
            onPaymentStatus({error: false, errorMessage: "", transactionResponse: _transactionResponse, page: helpers.PAGES.THANK_YOU});
            setState(state=> ({...state, isOnlineSending: false }))
        }, (error)=> {
            toast.error(error);
            onPaymentStatus({error: true, errorMessage: error});
            setState(state=> ({...state, isOnlineSending: false }))
        })
    }
    return (
        <React.Fragment>
            <Popconfirm
                placement="top"
                title="Voulez-vous vraiment payer en espece ?"
                cancelText="Annuler"
                okText="Confirmer"
                onConfirm={onlineProcess}
                
            >
                <Button
                    style={{ color: '#ffffff', borderRadius: 8, borderColor: '#0075C9', backgroundColor: '#005680', marginRight: 10, marginTop: 21 }}
                    loading={state.isOnlineSending}
                >
                    <FileDoneOutlined style={{ color: '#ffffff', fontSize: 20 }} /> VALIDATION
                </Button>
                
            </Popconfirm>
        </React.Fragment>
    )
}

export default PaymentEspece;