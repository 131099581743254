import { configureStore } from '@reduxjs/toolkit'

import authReducer from './slices/authSlice';
import basketSliceReducer from './slices/basketSlice';
import subscriptionReducer from './slices/subscriptionSlice';

import apiResponseReducer from './slices/apiResponseSlice';

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) return undefined
        return JSON.parse(serializedState)
    } catch (e) {
        console.log(e)
        return undefined
    }
}

const store = configureStore({
    devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    reducer: {
        auth: authReducer,
        basketSlice: basketSliceReducer,
        subscription: subscriptionReducer,

        apiResponse: apiResponseReducer,
    },
    preloadedState: loadFromLocalStorage(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
})

export default store;