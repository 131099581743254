import { Card, Form, Spin } from "antd";
import CustomAntdForm from "modules/components/Forms/CustomAntdForm";
import React, { useEffect, useState } from "react";
import Payment from "views/pages/subscription/component/payment/Payment";
import { ContractType } from "views/pages/subscription/component/ComponentWidget";
import FDetailsAssure from "../FDetailsAssure";
import FDetailsHeader from "../FDetailsHeader";
import FDetailsReductionAndPrime from "../FDetailsReductionAndPrimes";

const FlotteStepAssureDetails = (props) => {
    const {
        data,
        data: {
            fBasket: { flotteSubscriptionRequest, packsSelected },
            flotteBasket
        },
        goToNextStep,
        goToPreviousStep,
    } = props

    console.log('FlotteStepAssureDetails', props);

    const [form] = Form.useForm();
    const [fakeLoading, setFakeLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setFakeLoading(false)
        }, 2000)
    }, []);

    const successStatus = (transactionResponse) => {
        const _data = {
            ...data,
            transactionResponse: { ...transactionResponse }
        }
        goToNextStep([
            { path: ['data'], value: _data }
        ]);
    }

    const errorStatus = () => { }

    if (fakeLoading) {
        return (
            <Card>
                <><Spin /> Chargement en cours ...</>
            </Card>
        )
    }

    return (
        <React.Fragment>
            <CustomAntdForm form={form}>
                <Card className='fistCard'>

                    <FDetailsHeader primeTTC={flotteBasket ? flotteBasket.flotteSubscriptionRequest?.primeTTC : flotteSubscriptionRequest?.primeTTC} /> <br />

                    <div>
                        {!fakeLoading ? (
                            <>
                                <FDetailsAssure assure={flotteBasket ? flotteBasket.flotteSubscriptionRequest?.assure : flotteSubscriptionRequest?.assure} />
                                <FDetailsReductionAndPrime flotteSubscriptionRequest={flotteSubscriptionRequest} packsSelected={packsSelected} />
                            </>
                        ) : (<div style={{ marginLeft: 20 }}><Spin /> Chargement de details ...<br /><br /></div>)}
                    </div>
                </Card>

                <Card>
                    {!fakeLoading ? (
                        <Payment
                            projectVisibility={false}
                            onlineVisiblity={true}
                            payload={flotteBasket ? flotteBasket.flotteSubscriptionRequest : flotteSubscriptionRequest}
                            successStatus={successStatus}
                            errorStatus={errorStatus}
                            goBack={goToPreviousStep}
                            type={ContractType.FLOTTE}
                        />
                    ) : (<><Spin /> Chargement de moyent de payement ...</>)}
                </Card>
            </CustomAntdForm >
        </React.Fragment>
    );
}

export default FlotteStepAssureDetails;