import { FormField } from "forms/FormField";
import * as constants from 'constantes';
import Model from "api/model/model.model";
import Brand from "api/model/brand.model";
import Vehicle from "api/model/vehicle.model";
import Transport from "api/model/transport.model";
import City from "api/model/city.model";
import SubscriptionRequest from "api/request/subscriptionRequest";
import Energy from "api/model/energy.model";
import Assure from "api/model/assure.model";
import FSubscriptionRequest from "./fSubscriptionRequest";
import moment from "moment";

export default class SubscriptionRequestBuilder {
    // ============================
    // ============= MONO =========
    // ============================

    static fromMContract({avenant, contract, assure, isProject, projectCode, partnerCode}) {
        const {packsSelected, period, categoryRef, categoryTitle, productRef, productTitle, primeTTC, primeNette, primeExtra } = contract;
        const {vehicle, packResponse} = packsSelected[0];
        return {
            productRef  : productRef,
            packLineRef : packResponse?.packLineRef,
            contractRef : contract?.ref,
            avenantRef  : avenant?.ref,
            avenantTitle: avenant?.title,
            
            categoryRef: categoryRef,
            categoryTitle : categoryTitle,

            // PRIME
            primeTTC    : primeTTC,
            primeNette  : primeNette,
            primeExtra  : primeExtra,
            
            dateEffect  : contract?.startDate, //moment((new Date(startDate).toLocaleDateString()), "DD/MM/YYYY"),
            
            // Period
            periodRef   : period?.ref,
            periodTitle : period?.title,
            customerPeriod: period?.customerPeriod,
            customerStartDate: period?.customerStartDate,
            customerEndDate: period?.customerEndDate,

            //Assure
            assure      : { ...assure },

            // Vehicle
            vehicle: vehicle,

            canal       : "BO",
            isProject : isProject,
            projectCode : projectCode,

            partnerCode: partnerCode,
            
            paymentProviderRef: constants.DEFAULT_PAYEMENT_PROVIDER,
        }
    }

    static fromBasket({category, avenant, contract, basket, isProject=false, projectCode, partnerCode, isProrate}) {
        const {formField, packSelected } = basket;
        console.log(":::::: state?.basket formField : ", formField)
        // VEHICULE

        const model = Model.fromJson({
            ref: formField?.modelRef,
            title: formField.modelTitle,
            brand: Brand.fromJson({
                ref: formField.brandRef,
                title: formField.brandTitle
            })
        });

        const trailerModel=Model.fromJson({
            ref: formField?.trailerModelRef,
            title: formField?.trailerModelTitle,
            brand: Brand.fromJson({
                ref: formField?.trailerBrandRef,
                title: formField?.trailerBrandTitle
            })
        })

        const vehicle = Vehicle.fromJson({
            ref: formField?.vehicleRef,
            transport: Transport.fromJson({ref: formField?.transportRef, title: formField?.transportTitle}),
            energy: Energy.fromJson({ ref: formField?.energyRef, title: formField?.energyTitle }),
            city: City.fromJson({ ref: formField?.cityRef, title: formField?.cityTitle }),
            model: model,
            
            matriculation: formField?.matriculation,
            chassisNumber: formField?.chassisNumber,
            
            totalPlace: formField?.nbPlace,
            fiscalPower: formField?.fiscalPower,
            dateMec: formField?.dateMec,

            hasGps: formField?.hasGps,
            newValue: formField?.newValue,
            marketValue: formField?.marketValue,

            // Trailer
            hasTrailer: formField?.hasTrailer,
            trailerModel: formField?.hasTrailer ? trailerModel : null,
            trailerMatriculation: formField?.trailerMatriculation,
            trailerChassisNumber: formField?.chassisNumber,
            payload: formField?.payload,
            inflammable: formField?.inflammable,

            greyCardImg: formField?.greyCardImg,
            isGood: formField?.isGood,
            goodWeigh: formField?.goodWeight,

            // Groupement
            hasGroup: formField?.hasGroup,
            groupRef: formField?.hasGroup ? formField?.groupementRef : '',
            groupName: formField?.hasGroup ? formField?.groupementTitle : '',
            groupType: formField?.hasGroup ? formField?.groupementTypeTitle : '',
            // Driver
            driverBirthDay: formField?.driverBirthDay,
            driverLicDeliverDate: formField?.driverLicDeliverDate 
        });

        // ASSURE
        const assure = Assure.fromJson({
            code            : formField?.assureCode,
            socialPosRef    : formField?.socialPosRef,
            socialPosTitle  : formField?.socialPosTitle,
            firstName       : formField?.assureFirstname,
            lastName        : formField?.assureLastname,
            otherName       : "",
            identityCardRef : formField?.assureIdentityCardRef,
            identityCardTitle: formField?.assureIdentityCardTitle,
            identityCardNumber: formField?.assureIdentityCardNumber,
            address         : "",
            profession      : "",
            phone           : formField?.assurePhone,
            email           : formField?.assureEmail,
            birthDay        : formField?.assureBirthDay,
            prospect        : formField?.isProspect 
        });

        /* const merchant = Merchant.fromJson({
            code: formField?.merchantCode,
            firstName: formField?.merchantFirstname,
            lastName: formField?.merchantLastname,
            phone: formField?.merchantPhone,
            email: formField?.merchantEMail
        }) */


        return SubscriptionRequest.fromJson({
            calculateGuarantee: true,
            offering    : packSelected?.offering,
            productRef  : constants.PRODUCT_AUTO_REF,
            productTitle    : "AUTO",
            partnerCode : partnerCode,
            contractRef : contract?.ref, // Utile lorsqu'on choisit un avenant autre que avenant de création
            
            avenantRef  : avenant?.ref,
            avenantTitle: avenant?.title,

            categoryRef : category?.ref,
            categoryTitle: category?.title,
            packLineRef : packSelected?.packLineRef,

            /* period: new Period({
                ref: formField.periodRef,
                title:formField?.periodTitle,
                customerStartDate: formField?.customerStartDate,
                customerEndDate: formField.customerEndDate,
                prorate: formField?.prorate,
            }), */
            periodRef   : formField.periodRef, // A revoir
            periodTitle : formField?.periodTitle,
            customerPeriod: formField?.customerPeriod,
            customerStartDate: formField?.customerStartDate,
            customerEndDate: formField.customerEndDate,
            prorate     : formField?.prorate, 

            customGuaranties: packSelected.customGuaranties || [],
            reductionGroups : packSelected.reductionGroups || [],
            customAssistances: packSelected?.assistance?.customAssistancesLines || [],

            dateEffect  : formField?.startDate,
            
            assure      : assure,
            vehicle     : vehicle,

            // PRIMES
            primeTTC    : packSelected?.primeTTC,
            primeNette  : packSelected?.primeNette,
            primeExtra  : packSelected?.primeExtra,
            agencyFees  : packSelected?.angencyFees,

            // GROUPEMENT
            hasGroup    : formField?.hasGroup,
            groupRef    : formField?.hasGroup ? formField?.groupementRef : '',
            groupName   : formField?.hasGroup ? formField?.groupementTitle : '',

            isProject   : isProject,
            projectCode : projectCode,

            capitalRef  : "",
            promoCode   : "",
            canal       : "BO",
            paymentProviderRef: constants.DEFAULT_PAYEMENT_PROVIDER,

            //merchant: merchant.firstName && merchant.lastName ? merchant : null

        })
    }

    static fromThis(avenantCode, subscriptionRequest) {
        
        const baseSubscriptionRequest = {
            avenantRef: subscriptionRequest?.avenantRef,
            contractRef: subscriptionRequest?.contractRef,
            agencyFees: subscriptionRequest?.agencyFees,
            primeTTC: subscriptionRequest?.primeTTC,
            isProject: subscriptionRequest?.isProject,
            projectCode: subscriptionRequest?.projectCode,
            partnerCode: subscriptionRequest?.partnerCode,
            paymentProviderRef: subscriptionRequest?.paymentProviderRef,
            canal: subscriptionRequest?.canal
        }
        switch(parseInt(avenantCode)) {
            case constants.CODE_AVENANTS.CREATION:
            case constants.CODE_AVENANTS.RENOUVELEMENT_AVEC_MODIFICATION:
                return SubscriptionRequest.fromJson({
                    ...subscriptionRequest
                })
                
            case constants.CODE_AVENANTS.RENOUVELEMENT_SANS_MODIFICATION:
                return SubscriptionRequest.fromJson({
                    ...baseSubscriptionRequest,
                    dateEffect: subscriptionRequest?.dateEffect
                })
            
            case constants.CODE_AVENANTS.CHANGEMENT_IMMATRICULATION:
            case constants.CODE_AVENANTS.DUPLICATA_ATTESTATION:
                return SubscriptionRequest.fromJson({
                    ...baseSubscriptionRequest,
                    vehicle: {ref:subscriptionRequest?.vehicle?.ref, matriculation: subscriptionRequest?.vehicle?.matriculation},
                    dateEffect: subscriptionRequest?.dateEffect,
                    
                });

            case constants.CODE_AVENANTS.CHANGEMENT_ASSURE:
                return SubscriptionRequest.fromJson({
                    ...baseSubscriptionRequest,
                    assure: {...subscriptionRequest?.assure},
                    dateEffect: subscriptionRequest?.dateEffect
                });

            case constants.CODE_AVENANTS.SUSPENSION:
            case constants.CODE_AVENANTS.REMISE_EN_VIGUEUR_AVEC_REPORT:
                return SubscriptionRequest.fromJson({
                    ...baseSubscriptionRequest,
                    dateEffect: subscriptionRequest?.dateEffect,
                });

            case constants.CODE_AVENANTS.CHANGEMENT_VEHICULE:
                return SubscriptionRequest.fromJson({
                    ...baseSubscriptionRequest,
                    vehicle: subscriptionRequest?.vehicle,
                    dateEffect: subscriptionRequest?.dateEffect,
                    customerStartDate: subscriptionRequest?.dateEffect,
                    prorate: true
                });
            
            case constants.CODE_AVENANTS.PROROGATON:
                return SubscriptionRequest.fromJson({
                    ...baseSubscriptionRequest,
                    periodRef   : subscriptionRequest?.periodRef,
                    dateEffect  : subscriptionRequest?.dateEffect,
                    //prorate: true
                });

            case constants.CODE_AVENANTS.EXTENSION_GARENTIES:
            case constants.CODE_AVENANTS.RETRAIT_GARENTIES:
                return SubscriptionRequest.fromJson({
                    ...baseSubscriptionRequest,
                    customGuaranties    : subscriptionRequest?.customGuaranties,
                    reductionGroups     : subscriptionRequest?.reductionGroups,
                    customerStartDate   : subscriptionRequest?.customerStartDate,
                    dateEffect          : subscriptionRequest?.dateEffect,
                    prorate             : true
                });

            case constants.CODE_AVENANTS.ANNULATION_PURE:
                return SubscriptionRequest.fromJson({
                    ...baseSubscriptionRequest,
                    dateEffect          : subscriptionRequest?.dateEffect
                });
        }
    }

    // ============================
    // ============= FLOTTE =========
    // ============================

    static fromThisFleet(avenantCode, fSubscriptionRequest) {
        const basefSubscriptionRequest = {
            avenantRef  : fSubscriptionRequest?.avenantRef,
            contractRef : fSubscriptionRequest?.contractRef,
            agencyFees  : fSubscriptionRequest?.agencyFees,
            primeTTC    : fSubscriptionRequest?.primeTTC,
            isProject   : fSubscriptionRequest?.isProject,
            projectCode : fSubscriptionRequest?.projectCode,
            partnerCode : fSubscriptionRequest?.partnerCode,
            paymentProviderRef: fSubscriptionRequest?.paymentProviderRef,
            canal       : fSubscriptionRequest?.canal
        }
        switch(parseInt(avenantCode)) {
            case constants.CODE_AVENANTS.CREATION:
            case constants.CODE_AVENANTS.RENOUVELEMENT_AVEC_MODIFICATION:
            case constants.CODE_AVENANTS.INCORPORATION:
                return FSubscriptionRequest.fromJson({
                    ...fSubscriptionRequest
                })
                
            case constants.CODE_AVENANTS.RENOUVELEMENT_SANS_MODIFICATION:
                return FSubscriptionRequest.fromJson({
                    ...basefSubscriptionRequest,
                    dateEffect: fSubscriptionRequest?.dateEffect
                })
            
            case constants.CODE_AVENANTS.CHANGEMENT_IMMATRICULATION:
            case constants.CODE_AVENANTS.DUPLICATA_ATTESTATION:
                const _autos = (fSubscriptionRequest.autos || []).map((auto)=> ({vehicle: {ref: auto?.vehicle?.ref, matriculation: auto?.vehicle?.matriculation}}))
                return FSubscriptionRequest.fromJson({
                    ...basefSubscriptionRequest,
                    autos: [..._autos],
                    dateEffect: fSubscriptionRequest?.dateEffect
                });

            case constants.CODE_AVENANTS.CHANGEMENT_ASSURE:
                return FSubscriptionRequest.fromJson({
                    ...basefSubscriptionRequest,
                    assure: {...fSubscriptionRequest?.assure},
                    dateEffect: fSubscriptionRequest?.dateEffect
                });

            case constants.CODE_AVENANTS.CHANGEMENT_VEHICULE:
                return FSubscriptionRequest.fromJson({
                    ...basefSubscriptionRequest,
                    vehicle: fSubscriptionRequest?.vehicle,
                    dateEffect: fSubscriptionRequest?.dateEffect,
                    customerStartDate: fSubscriptionRequest?.dateEffect,
                    prorate: true
                });
            
            case constants.CODE_AVENANTS.PROROGATON:
                return FSubscriptionRequest.fromJson({
                    ...basefSubscriptionRequest,
                    periodRef   : fSubscriptionRequest?.periodRef,
                    dateEffect  : fSubscriptionRequest?.dateEffect,
                    //prorate: true
                });

            case constants.CODE_AVENANTS.ANNULATION_PURE:
                return FSubscriptionRequest.fromJson({
                    ...basefSubscriptionRequest,
                    dateEffect          : fSubscriptionRequest?.dateEffect
                });
        }
    }

    static buildFlotte({ formFields, avenant, category, contract, pack, autos, isProject, projectCode }) {
        console.log("111111.........formFields : ", formFields);
        const newFormFields = (new FormField().fromJson(formFields));
        console.log("111111.........newFormFields : ", newFormFields);
        const assure = {
            code: newFormFields.assureCode,
            socialPosRef: newFormFields.socialPosRef,
            socialPosTitle: newFormFields.socialPosTitle,
            firstName: newFormFields.assureFirstname,
            lastName: newFormFields.assureLastname,
            otherName: "",
            identityCardRef: newFormFields.assureIdentityCardRef,
            identityCardNumber: newFormFields.assureIdentityCardNumber,
            identityCardTitle: newFormFields.assureIdentityCardTitle,
            address: '',
            profession: "",
            phone: newFormFields.assurePhone,
            email: newFormFields.assureEmail,
            birthDay: newFormFields.assureBirthDay,
        }

        return SubscriptionRequest.fromJson({
            calculateGuarantee: true,
            offering: "CLASSIC",
            avenantRef: avenant.ref,
            avenantTitle: avenant.title,
            productRef: constants.PRODUCT_AUTO_REF,//pack?.product?.ref,
            categoryRef: category?.ref,
            productTitle: "AUTO",

            periodRef: newFormFields.periodRef,
            periodTitle: newFormFields.periodTitle,
            isCustomerPeriod: false,

            driverBirthDay: newFormFields.driverBirthDay,
            driverLicDeliveryDate: newFormFields.driverLicDeliverDate,

            cGuaranties: [],
            reductionGroups: [],

            contractRef: contract?.ref, // Utile lorsqu'on choisit un avenant autre que avenant de création
            dateEffect: newFormFields.startDate,
            // dateEffect: convert(newFormFields.startDate),

            capitalRef: '',
            assure: assure,

            primeTTC: pack?.primeTTC,
            primeNette: pack?.primeNette,
            primeExtra: pack?.primeExtra,
            flotteRate: parseFloat(pack?.flotteRate) * 100,
            agencyFees: 0,

            paymentProviderRef: constants.DEFAULT_PAYEMENT_PROVIDER,

            promoCode: "",
            canal: "BO",
            isProject: isProject,
            projectCode: projectCode,

            autos: autos,
        });

    }

    static fSubscriptionFromContract({avenant, contract, assure, isProject, projectCode}) {
        const {packsSelected, period, categoryRef, productTitle, primeTTC, primeNette, primeExtra, rate } = contract;
        const autos = [];
        console.log("::::: assure :::: ", assure)
        for(const packSelected of packsSelected) {
            const {vehicle, packResponse} = packSelected;
            const auto = {
                packLineRef: packResponse?.packLineRef,
                categoryRef: packResponse?.category?.ref,
                categoryTitle: packResponse?.category?.title,
                vehicle: vehicle,
                customGuaranties: packResponse?.customGuaranties,
                reductionGroups: packResponse?.reductionGroups,
                customAssistance: packResponse?.assistance?.customAssistancesLines,
                primeTTC: packResponse?.primeTTC,
                primeNette: packResponse?.primeNette,
                primeExtra: packResponse?.primeExtra,
                agencyFees: packResponse?.angencyFees,
                //driverBirthDay: formField?.driverBirthDay,
                //driverLicdeliveryDate: formField?.driverLicdeliveryDate
            }

            autos.push({...auto})
        }
        
        return FSubscriptionRequest.fromJson({
            calculateGuarantee: true,
            contractRef: contract?.ref,
            offering: "CLASSIC",
            productRef: constants.PRODUCT_AUTO_REF,
            avenantRef: avenant.ref,
            avenantTitle: avenant.title,
            categoryRef: categoryRef,
            productTitle: productTitle,

            periodRef: period.ref,
            periodTitle: period.title,
            customerPeriod: period?.customerPeriod,
            customerStartDate: period?.customerStartDate,
            customerEndDate: period?.customerEndDate,

            customGuaranties: [],
            reductionGroups: [],

            dateEffect: moment(new Date(contract?.startDate).toLocaleDateString(), "DD/MM/YYYY"),

            assure: {...assure},

            primeTTC: primeTTC,
            primeNette: primeNette,
            primeExtra: primeExtra,
            rate: parseFloat(rate) * 100,
            agencyFees: 0,

            paymentProviderRef: constants.DEFAULT_PAYEMENT_PROVIDER,

            promoCode: "",
            canal: "BO",
            isProject: isProject,
            projectCode: projectCode,

            autos: autos,
        })
    }

    static buildAutoFromBasket({baskets}) {
        console.log("!!!!!!!!:::: baskets :", baskets)
        const autos = [];
        
        for (const basket of baskets) {
            const { formField, packSelected, quotationRequest } = basket;
            
            const transport = Transport.fromJson({
                ref: formField?.transportRef,
                title: formField?.transportTitle
            });

            const model = Model.fromJson({
                ref: formField?.modelRef,
                title: formField?.modelTitle,
                brand: Brand.fromJson({
                    ref : formField?.brandRef,
                    title: formField?.brandTitle
                })
            });

            const _vehicle = Vehicle.fromJson({
                transport: transport,
                model: model,
                energy: Model.fromJson({ ref: formField?.energyRef, title: formField?.energyTitle }),
                city: City.fromJson({ ref: formField?.cityRef, title: formField?.cityTitle }),
                totalPlace: formField?.nbPlace,
                fiscalPower: formField?.fiscalPower,
                dateMec: formField?.dateMec,
                hasGroup: formField?.hasGroup,
                groupRef: formField?.hasGroup ? formField?.groupementRef : '',
                groupName: formField?.hasGroup ? formField?.groupementTitle : '',
                groupType: formField?.hasGroup ? formField?.groupementTypeTitle : '',
                hasGps: formField?.hasGps,
                newValue: formField?.newValue,
                marketValue: formField?.marketValue,
                matriculation: formField?.matriculation,
                chassisNumber: formField?.chassisNumber,
                greyCardImg: formField?.greyCardImg,
                isGood: formField?.isGood,
                goodWeigh: formField?.goodWeigh,
            });

            const auto = {
                packLineRef: packSelected?.packLineRef,
                categoryRef: quotationRequest?.categoryRef,
                categoryTitle: quotationRequest?.categoryTitle,
                vehicle: _vehicle,
                customGuaranties: packSelected?.customGuaranties,
                reductionGroups: packSelected?.reductionGroups,
                primeTTC: packSelected?.primeTTC,
                primeNette: packSelected?.primeNette,
                primeExtra: packSelected?.primeExtra,
                agencyFees: packSelected?.angencyFees,
                driverBirthDay: formField?.driverBirthDay,
                driverLicdeliveryDate: formField?.driverLicdeliveryDate
            }

            autos.push({ ...auto });
        }

        return autos;
    }

    static buildAutoFromContrat({contract}) {
        const autos = [];
        const {packsSelected}= contract;

        for(const packSelected of packsSelected) {
            const {vehicle, packResponse} = packSelected;
            const auto = {
                packLineRef: packResponse?.packLineRef,
                categoryRef: packResponse?.category?.ref,
                categoryTitle: packResponse?.category?.title,
                vehicle: vehicle,
                customGuaranties: packResponse?.customGuaranties,
                reductionGroups: packResponse?.reductionGroups,
                customAssistance: packResponse?.assistance?.customAssistancesLines,
                primeTTC: packResponse?.primeTTC,
                primeNette: packResponse?.primeNette,
                primeExtra: packResponse?.primeExtra,
                agencyFees: packResponse?.angencyFees
            }

            autos.push({...auto})
        }

        return autos;
    }

    // Obsolete : se trouve dans retrait guarantie
    static fromMonoFormField({ productRef, contract, category, avenant, packSelected, formFields, partnerCode, isProject, projectCode }) {

        const transport = Transport.fromJson({
            ref: formFields?.transportRef,
            title: formFields?.transportTitle
        });

        const model = Model.fromJson({
            ref: (formFields?.customerModel !== '' || formFields?.customerModel !== undefined) ? formFields?.modelRef : formFields?.modelRef,
            title: formFields?.customerModel ? formFields?.customerModel : formFields.modelTitle,
            brand: Brand.fromJson({
                ref: formFields.brandRef,
                title: formFields?.customerBrand ? formFields?.customerBrand : formFields.brandTitle
            })
        });

        const vehicle = Vehicle.fromJson({
            transport: transport,
            model: model,
            energy: Energy.fromJson({ ref: formFields?.energyRef, title: formFields?.energyTitle }),
            city: City.fromJson({ ref: formFields?.cityRef, title: formFields?.cityTitle }),
            totalPlace: formFields?.nbPlace,
            fiscalPower: formFields?.fiscalPower,
            dateMec: formFields?.dateMec,
            hasGroup: formFields?.hasGroup,
            groupRef: formFields?.hasGroup ? formFields?.groupementRef : '',
            groupName: formFields?.hasGroup ? formFields?.groupementTitle : '',
            groupType: formFields?.hasGroup ? formFields?.groupementTypeTitle : '',
            hasGps: formFields?.hasGps,
            newValue: formFields?.newValue,
            marketValue: formFields?.marketValue,
            matriculation: formFields?.matriculation,
            chassisNumber: formFields?.chassisNumber,
            greyCardImg: formFields?.greyCardImg,
            isGood: formFields?.isGood,
            goodWeigh: formFields?.goodWeight
        });

        // ASSURE
        const assure = Assure.fromJson({
            code: formFields?.assureCode,
            socialPosRef: formFields?.socialPosRef,
            socialPosTitle: formFields?.socialPosTitle,
            firstName: formFields?.assureFirstname,
            lastName: formFields?.assureLastname,
            otherName: "",
            identityCardRef: formFields?.assureIdentityCardRef === undefined || formFields?.assureIdentityCardRef === null ? '' : formFields?.assureIdentityCardRef,
            identityCardTitle: formFields?.assureIdentityCardTitle === undefined || formFields?.assureIdentityCardTitle === null ? '' : formFields?.assureIdentityCardTitle,
            identityCardNumber: formFields?.assureIdentityCardNumber,
            address: "",
            profession: "",
            phone: formFields?.assurePhone,
            email: formFields?.assureEmail,
            birthDay: formFields?.assureBirthDay,
        });

        const subscriptionRequest = SubscriptionRequest.fromJson({
            calculateGuarantee: true,
            contractRef: contract?.ref, // Utile lorsqu'on choisit un avenant autre que avenant de création
            offering: packSelected?.offering,
            productRef: productRef,
            productTitle: "AUTO",
            avenantRef: avenant?.ref,
            avenantTitle: avenant?.title,
            categoryRef: category?.ref,
            categoryTitle: category?.title,
            packLineRef: packSelected?.packLineRef,

            periodRef: formFields.periodRef, // A revoir
            periodTitle: formFields?.periodTitle,
            customerPeriod: formFields?.customerPeriod,
            customerStartDate: formFields?.customerStartDate,
            customerEndDate: formFields.customerEndDate,
            prorate: formFields?.prorate, 

            driverBirthDay: formFields?.driverBirthDay,
            driverLicdeliveryDate: formFields?.driverLicDeliverDate,

            customGuaranties: packSelected.customGuaranties || [],
            reductionGroups: packSelected.reductionGroups || [],

            dateEffect: formFields?.startDate,
            assure: assure,
            vehicle: vehicle,

            // PRIMES
            primeTTC: packSelected?.primeTTC,
            primeNette: packSelected?.primeNette,
            primeExtra: packSelected?.primeExtra,
            agencyFees: packSelected?.angencyFees,

            // GROUPEMENT
            hasGroup: formFields?.hasGroup,
            groupRef: formFields?.hasGroup ? formFields?.groupementRef : '',
            groupName: formFields?.hasGroup ? formFields?.groupementTitle : '',

            isProject: isProject,
            projectCode: projectCode,

            capitalRef: "",
            promoCode: "",
            partnerCode: partnerCode,
            canal: "BO",
            paymentProviderRef: constants.DEFAULT_PAYEMENT_PROVIDER

        });

        return subscriptionRequest;

    }
}
