import ApiRoutes from "api/apiRoutes";
import { exeRequest, exeRequestAsync } from "http/requestConfig";

export default class ReportingService {

    // LISTE DES VEHICULES
    generateReporting(options, sCallBack, eCallBack) {
        ApiRoutes.generateListeVRepportingDocResource.resourceOptions = Object.assign({}, ApiRoutes.generateListeVRepportingDocResource?.resourceOptions, options);
        exeRequest(ApiRoutes.generateListeVRepportingDocResource, {}, sCallBack, eCallBack)
    }

    readAllReporting(options, sCallBack, eCallBack) {
        ApiRoutes.repportingResource.resourceOptions = Object.assign({}, ApiRoutes.repportingResource.resourceOptions, options);
        exeRequest(ApiRoutes.repportingResource, {}, sCallBack, eCallBack)
    }

    // BORDEREAU D'EMISSION
    readAllCommissionReporting(options, sCallBack, eCallBack) {
        ApiRoutes.repportingEmissionBordereauResource.resourceOptions = Object.assign({}, ApiRoutes.repportingEmissionBordereauResource.resourceOptions, options);
        exeRequest(ApiRoutes.repportingEmissionBordereauResource, {}, sCallBack, eCallBack)
    }

    generateBordereauEmissionReporting(options, sCallBack, eCallBack) {
        ApiRoutes.generateBordereauEmRepportingDocResource.resourceOptions = Object.assign({}, ApiRoutes.generateBordereauEmRepportingDocResource.resourceOptions, options);
        exeRequest(ApiRoutes.generateBordereauEmRepportingDocResource, {}, sCallBack, eCallBack)
    }

    // BORDEREAU D'EXPIRATION
    static readAllBordereauExpirationReporting(options, sCallBack, eCallBack) {
        ApiRoutes.repportingBordereauExpirationResource.resourceOptions = Object.assign({}, ApiRoutes.repportingBordereauExpirationResource.resourceOptions, options);
        exeRequest(ApiRoutes.repportingBordereauExpirationResource, {}, sCallBack, eCallBack)
    }
    static async generateExpirationReporting(options, sCallBack, eCallBack) {
        ApiRoutes.generateExpirationRepportingDocResource.resourceOptions = Object.assign({}, ApiRoutes.generateExpirationRepportingDocResource.resourceOptions, options);
       // const filePath = `${FILE_PATH_ROOT}${buildRequestUrl(ApiRoutes.generateExpirationRepportingDocResource.urlSuffix,  ApiRoutes.generateExpirationRepportingDocResource.resourceOptions)}`;
        //exeRequest(ApiRoutes.generateExpirationRepportingDocResource, {}, sCallBack, eCallBack)
        await exeRequestAsync(ApiRoutes.generateExpirationRepportingDocResource)
            .then((response) => response.blob())
            .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
                var _url = window.URL.createObjectURL(blob);
                window.open(_url, "_blank").focus(); // window.open + focus
                sCallBack("ok")
            }).catch((err) => {
                console.log(err);
                eCallBack(err)
            });
    }
}