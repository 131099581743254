import { useEffect, useState } from 'react';
import EnergyService from 'services/energyService';

export function useGetEnergies() {
    const [state, setState] = useState({
        isLoading: true,
        data: []
    });

    useEffect(() => {
        (new EnergyService()).getAll((responseData) => {
            setState(prevState => ({
                ...prevState,
                data: responseData || [],
                isLoading: false
            }));
        }, (error) => {
            setState(prevState => ({
                ...prevState,
                isLoading: false
            }));
        });
    }, []);

    return {
        ...state
    }
}