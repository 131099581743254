export default class FormEditCategory {
    id;
    ref;
    code;
    title;
    description;

    static fromJson = (json = {}) => {
        var formEditCategory = new FormEditCategory();
        formEditCategory = json;
        return formEditCategory;
    }
}