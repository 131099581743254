import { EyeTwoTone } from "@ant-design/icons";
import { Table } from "antd";
import React, { useEffect } from "react";
import { thousandSeparator } from "helpers/helpers";

const SouscriptionActive=({ contracts, assure, isLoad })=> {
    
    useEffect(()=>{
        console.log(":::: contracts ", contracts)
        /* $(document).ready(function() {
            setTimeout(function() {
                var table = $('#dataTable').DataTable({
                    paging: false,
                    ordering: false,
                    info: false
                });
    
                $('#dataTable').on('click', 'tbody td.dt-control', function () {
                    var tr = $(this).closest('tr');
                    var row = table.row(tr);
                
                    if (row.child.isShown()) {
                        // This row is already open - close it
                        row.child.hide();
                    } else {
                        // Open this row
                        var data = {
                            subscriptions: JSON.parse($(this).attr('data-subcription')),
                            assureCode: $(this).attr('data-assureCode'),
                            categoryCode: $(this).attr('data-categoryCode')
                        };
        
                        row.child(format(data)).show();
                    }
                });
        
                $('#dataTable').on('requestChild.dt', function (e, row) {
                    row.child(format(row.data())).show();
                }); 
    
            } ,1000);
        });  */      
    }, [contracts]);

    const format=(data)=> {
        var subscriptionRow = "";
        for(var i=0; i<data?.subscriptions.length; i++) {
            var detailsUrl = "/souscription-details/"+ data?.subscriptions[i].id + "/" + data?.assureCode + "/" + data?.subscriptions[i].contractID + "/" + data?.categoryCode;
            subscriptionRow += '<tr style="font-weight:bold"><td><span>'+ data?.subscriptions[i]?.avenantTitle + '</span></td> <td>'+ (new Date(data?.subscriptions[i]?.startDate).toLocaleDateString()) + '</td> <td>'+ (new Date(data?.subscriptions[i]?.endDate).toLocaleDateString()) + '</td> <td><Tag color="green">'+ thousandSeparator(data?.subscriptions[i]?.primeTTC ) + ' Fcfa</Tag></td><td><a href="'+detailsUrl+'">Details</a></td></tr>'; 
        }
        return (
            'Avenants: <br/><table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px; width:100%">' +
            
            subscriptionRow +
            '</table>'
        );
    };

    const contractActifsColumns=[
        {
            title: "Police",
            dataIndex: "policeNumber",
            key: "policeNumber",

        },
        {
            title: "Type",
            dataIndex: "contractType",
            key: "contractType",

        },
        {
            title: "Offre",
            dataIndex: "offering",
            key: "offering",
        },
        {
            title: "Produit",
            dataIndex: "productTitle",
            key: "productTitle",
        },
        {
            title: "Category",
            dataIndex: "categoryCode",
            key: "categoryCode",
            render: (item)=> (<div>{item}</div>)
        },

        {
            title: "Créer le",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (item)=> (<div>{(new Date(item)).toLocaleDateString()} à {(new Date(item)).toLocaleTimeString()}</div>)
        },

        {
            title: "Actions",
            dataIndex: "action",
            key: "action",
            render: (item)=> (<div><EyeTwoTone /></div>)
        },

    ]
    
    return (
        <React.Fragment>
            <h4 style={{ color: 'grey', paddingLeft: 5 }}>Produits auto</h4>
            <Table 
                columns={contractActifsColumns} 
                dataSource={contracts} 
                pagination={{ position: ["bottomRight"], pageSize: 6 }}>

            </Table>
            {/* <table width={'100%'} id="dataTable" class="table table-hover table-bordered display" >
                <thead>
                    <tr >
                        <th >Police</th>
                        <th >Type</th>
                        <th >Offre</th>
                        <th >Produit</th>
                        <th >Code</th>
                        <th >Categorie</th>
                        <th >Crée le</th>
                        <th >Actions</th>
                    </tr>
                </thead>
                <tbody>
                {isLoad ? (
                    <>
                        {
                            contracts.length > 0 ? (
                                contracts.map(function (contract, index) {
                                    return (
                                        <tr style={{ borderCollapse: 'collapse', border: '1px solid white', height: 40 }} key={index}>
                                            <td >{contract?.policeNumber}</td>
                                            <td >{contract?.contractType}</td>
                                            <td >{contract.offering}</td>
                                            <td >{contract?.productTitle}</td>
                                            <td >{contract?.productCode}</td>
                                            <td >{contract?.categoryCode + " : " + contract?.categoryTitle}</td>
                                            <td >{new Date(contract?.createdAt).toLocaleString()}</td>
                                            <td 
                                                className="dt-control" 
                                                data-subcription={JSON.stringify(contract?.subscriptions)}
                                                data-assureCode={assure?.code}
                                                data-categoryCode={contract?.categoryCode}
                                            >
                                                
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr style={{ borderCollapse: 'collapse', border: '1px solid white', height: 40 }}>
                                    <td style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5 }} colSpan={7}>
                                        <h5 style={{ textAlign: 'center' }}>Aucune données disponible !</h5>
                                    </td>
                                </tr>
                            )
                        }
                    </>
                    ) : (
                        <div style={{ display: 'flex' }}>
                            <Spin />
                            <p style={{ marginLeft: 10, color: '#3573DF' }}>Connexion au server veuillez patienter s'il vous plait ...</p>
                        </div>
                    )}
                </tbody>
                
            </table> */}

            <br />
        </React.Fragment>

    )
}

export default SouscriptionActive;