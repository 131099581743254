export default class FormRegisterCategory {
    code;
    title;
    description;

    static toJson = (json = {}) => {
        var formRegisterCategory = new FormRegisterCategory();
        formRegisterCategory = json;
        return formRegisterCategory;
    }
}