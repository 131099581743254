export default class Area {
    id;
    ref;
    code;
    title;
    description;

    fromJson = (json={})=> {
        var area = new Area();
        area = json;
        return area
    }
 }