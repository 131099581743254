export default class EditCategoryRequest {
    id;
    ref;
    code;
    title;
    description;

    static fromJson = (json = {}) => {
        var createCategoryRequest = new EditCategoryRequest();
        createCategoryRequest = json;
        return createCategoryRequest;
    }
}