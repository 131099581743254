import { Col, Form, Select, Spin } from "antd"
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter"
import React, { useEffect, useState } from "react"
import TransportService from "services/transportService"
import * as helpers from 'helpers/helpers'
import { CATEGORY_406, CATEGORY_409 } from "constantes"

const TransportField=({categoryCode, categoryRef, form, disable, handlerFormField, xs, md})=> {

    const { Option } = Select;
    
    const [state, setState] = useState({
        //TRANSPORT
        transports: [],
        isTransportLoading: true,
        transportSelected: null,
    })

    useEffect(()=> {
        getTransports();
    }, [categoryRef])

    const getTransports = () => {
        (new TransportService()).getByCategoryRef(categoryRef, (data) => {
            let transports = data || [];
            const transportSelected = transports.find((transport) => transport?.isDefault === true);
            
            if (transportSelected) {
                form.setFieldsValue({
                    transportRef: transportSelected?.ref
                })

                handlerFormField({
                    transportRef: transportSelected?.ref, 
                    transportTitle: transportSelected?.title,
                    transportCode: transportSelected?.code,
                    motor: transportSelected?.motor
                })

                if(transportSelected?.minPlace) {
                    form.setFieldsValue({
                       nbPlace: transportSelected?.minPlace
                    })

                    handlerFormField({
                        nbPlace: transportSelected?.minPlace
                    })
                }    
            }

            setState(state => ({
                ...state,
                transports: transports,
                isTransportLoading: false,
                transportSelected: { ...transportSelected }
            }));

        }, (error) => {

        })
    }

    // <Action on select>
    const onTransportSelected = (transportref) => {
        const transportSelected = state.transports.find((transport) => transport.ref === transportref);
        if(transportSelected) {
            handlerFormField({ 
                transportRef: transportSelected?.ref, 
                transportTitle: transportSelected?.title,
                transportCode: transportSelected?.code,
                motor: transportSelected?.motor
            })

            if(["TRIC", "TRI"].includes(transportSelected?.code)) {
                form.setFieldsValue({
                    fiscalPower: 2
                })
            } 

            helpers.updateState([
                { path: ["transportSelected"], value: { ...transportSelected } }
            ], setState);
        }
    }

    if([CATEGORY_406, CATEGORY_409 ].includes(categoryCode)) return null;

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Type de vehicule"
                    name="transportRef"
                    rules={[{ required: false, message: "Champ obligatoire" }]}
                >
                    {
                        state.isTransportLoading ? <Spin /> :
                            (
                                <SelectWithFilter
                                    placeholder='Choisissez le type de transport'
                                    defaultValue={state.transportSelected != null ? state.transportSelected?.title : null}
                                    onChange={(e) => { onTransportSelected(e) }}
                                    disabled={disable}
                                >
                                    {state.transports.map((transport, index) => <Option value={transport?.ref} key={index} >{transport?.description}</Option>)}
                                </SelectWithFilter>
                            )
                    }
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default TransportField;