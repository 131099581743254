import React from "react";
import {  Col, Form, Select } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import { CATEGORY_403 } from "constantes";

const InflammableField=({categoryCode, disable,  xs, md})=> {
    const { Option } = Select;

    if(![CATEGORY_403].includes(categoryCode)) return null;

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Marchandise est-elle inflammable ?"
                    name="inflammable"
                    rules={[{ required: false, message: "Champ obligatoire" }]}
                >
                    <SelectWithFilter defaultValue={false} disabled={disable}>
                        <Option value={true} >Oui</Option>
                        <Option value={false} >Non</Option>
                    </SelectWithFilter>
                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default InflammableField;