import React, { useState, useEffect } from "react";
import {  Col, Form, Select, Spin } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import { GroupementService } from "services/groupementService";


const GroupementTypeField=({handlerFormField, disable, xs, md})=> {
    const { Option } = Select;

    const [state, setState]= useState({
        // GROUPEMENT TYPE / GROUPEMENT
        groupementTypes: [],
        isGroupementTypeLoading: false,
        groupementTypeSelected: {},
    })

    useEffect(()=> {
        getGroupementTypes()
    }, [])

    // GROUPEMENT
    const getGroupementTypes = () => {
        (new GroupementService()).readAllGroupementType((data) => {
            setState(state => ({ ...state, groupementTypes: data, isGroupementTypeLoading: false }))
        }, (error) => {

        })
    }

    const onGroupementTypeSelected = (groupementTypeRef) => {

        const groupementTypeSelected = state.groupementTypes.find((groupementType) => groupementType.ref === groupementTypeRef);
        if (groupementTypeSelected) {
            handlerFormField({
                groupementTypeRef: groupementTypeSelected?.ref,
                groupementTypeTitle: groupementTypeSelected?.title,
                hasGroup: false,
                groupementRef: null,
                groupementTitle: ""
            })
        }
    }

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Choisissez votre groupement"
                    name="groupementTypeRef"
                    rules={[{ required: false, message: "Champ obligatoire" }]}
                >
                    {
                        state.isGroupementTypeLoading ? <Spin /> :
                            (
                                <SelectWithFilter placeholder='Groupement ?' onChange={(e) => onGroupementTypeSelected(e)} disabled={disable}>
                                    {state.groupementTypes.map((groupementType, index) => (<Option value={groupementType?.ref} key={index}>{groupementType?.title}</Option>))}
                                </SelectWithFilter>
                            )
                    }
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default GroupementTypeField;