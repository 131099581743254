import React from 'react';
import {  DatePicker, Form, Spin, Table, Transfer, Tag } from "antd";
import { useEffect, useState } from "react";
import * as helpers from 'helpers/helpers';
import ErrorWidget from 'components/Widgets/ErrorWidget';
import { FStepThankYou } from '../nouvelle_subscription/steps';
import QuotationRequest from 'api/request/quotationRequest';
import QuotationService from 'services/quotationService';
import { toast } from 'react-toastify';
import QuotationResponse from 'api/v1/response/QuotationResponse';
import { Card, CardBody, CardHeader, Spinner, Col, Row  } from 'reactstrap';
import WizardHeader from 'modules/components/WizardHeader';
import { difference } from 'lodash';
import moment from 'moment';
import QuotationRequestBuilder from 'helpers/QuotationRequestBuilder';
import { UserOutlined } from '@ant-design/icons';
import FStepDetails from './components/FStepDetails';
import FSubscriptionRequestBuilder from 'helpers/FSubscriptionRequestBuilder';
import useInitFlotteAvenant from '../useInitFlotteAvenant';
import { useSelector } from 'react-redux';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';


// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    
    <Transfer {...restProps}>
        {({
            direction,
            filteredItems,
            onItemSelectAll,
            onItemSelect,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
        }) => {
            const columns = direction === 'left' ? leftColumns : rightColumns;
            const rowSelection = {
                getCheckboxProps: (item) => ({
                    disabled: listDisabled || item.disabled,
                }),
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
                    const diffKeys = selected ? difference(treeSelectedKeys, listSelectedKeys) : difference(listSelectedKeys, treeSelectedKeys);
                    onItemSelectAll(diffKeys, selected);
                },
                onSelect({ key }, selected) {
                    console.log("Item selcted")
                    onItemSelect(key, selected);
                },
                selectedRowKeys: listSelectedKeys,
            };
            return (
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={filteredItems}
                    size="small"
                    style={{
                        pointerEvents: listDisabled ? 'none' : undefined,
                    }}
                    onRow={({ key, disabled: itemDisabled }) => ({
                        onClick: () => {
                            if (itemDisabled || listDisabled) return;
                            onItemSelect(key, !listSelectedKeys.includes(key));
                        },
                        })
                    }
                />
            );
        }}
    </Transfer>
);

const leftTableColumns = [
    {
        dataIndex: 'id',
        title: 'id',
        render: (item,auto) => {
            return (<span>{item}</span>)
        }
    },
    {
        dataIndex: 'auto',
        title: 'Marque',
        render: (item, auto) => {
            return (<span>{auto?.vehicle?.model?.brand?.title}</span>)
        }
    },
    {
        dataIndex: 'model',
        title: 'Modèle',
        render: (item, auto) => {
            return (<span>{auto?.vehicle?.model?.title}</span>)
        }
    },
    {
        dataIndex: 'energy',
        title: 'Energie',
        render: (item, auto) => {
            return (<span>{auto?.vehicle?.energy?.title}</span>)
        }
    },
    {
        dataIndex: 'matriculation',
        title: 'Matricule',
        render: (item, auto) => {
            return (<span>{auto?.vehicle?.matriculation}</span>)
        }
    },
    {
        dataIndex: 'chassisNumber',
        title: 'Chassis',
        render: (item, auto) => {
            return (<span>{auto?.vehicle?.chassisNumber}</span>)
        }
    },
  ];
const rightTableColumns = [
    {
        dataIndex: 'brand',
        title: 'Marque',
        render: (item, auto) => {
            return (<span>{auto?.vehicle?.model?.brand?.title}</span>)
        }
    },
    {
        dataIndex: 'model',
        title: 'Modèle',
        render: (item, auto) => {
            return (<span>{auto?.vehicle?.model?.title}</span>)
        }
    },
    {
        dataIndex: 'energy',
        title: 'Energie',
        render: (item, auto) => {
            return (<span>{auto?.vehicle?.energy?.title}</span>)
        }
    },
    {
        dataIndex: 'matriculation',
        title: 'Matricule',
        render: (item, auto) => {
            return (<span>{auto?.vehicle?.matriculation}</span>)
        }
    },
    {
        dataIndex: 'chassisNumber',
        title: 'Chassis',
        render: (item, auto) => {
            return (<span>{auto?.vehicle?.chassisNumber}</span>)
        }
    },
];

export default function FAvenantRetraitVehicule() {
   
    const { contract,avenant, assure, category, partnerCode, projectCode } = useSelector((state) => state.subscription);

    const {
        currentStep, 
        page, 
        quoteStatus, 
        basket,
        baskets,
        fSubscriptionRequest, 
        quotationResponse, 
        transactionResponse, 
        initAvenantResponse,
        runFQuote 
    } = useSelector((state) => state.basketSlice);

    const {state: iniAvenant } = useInitFlotteAvenant();
    const { quote, goToPreviousStep, updateBasket, updateBaskets, quoteFBaskets} = useBasket();
    const [openVehicleDrawer, setOpenVehicleDrawer] = useState(false)
    const [quoting, setQuoting]= useState(false);

    const [form] = Form.useForm();
    const [flotteAutos, setFlotteAutos] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);
    const [initStartDate, setInitStartDate] = useState({
        startDate: moment().toDate(),
        endDate: moment().toDate(),
        initialDate: moment().toDate()
    })
    
    const [state, setState] = useState({
        primeTotalTTC : 0,
        nbVehicle: 0,
        isFlotteQuotationLoading: false
    })

    useEffect(()=> {
        if(!iniAvenant.avenantStatus?.loading) {
            const {packsSelected} = initAvenantResponse?.contract;
        
            const _packsSelected = (packsSelected || []).map((auto, i)=> ({...auto, key: i.toString()}));
            //const originTargetKeys = _packsSelected.filter((item) => Number(item.key) % 3 > 1).map((item) => item.key);
            setFlotteAutos(_packsSelected);
            //setTargetKeys(originTargetKeys)

            // SetDate
            setInitStartDate({
                startDate: initAvenantResponse?.startDate,
                endDate: initAvenantResponse?.endDate,
                initialDate: initAvenantResponse?.startDate
            });

            form.setFieldsValue({
                startDate: moment(initAvenantResponse?.startDate)
            })
        }
    }, [iniAvenant.avenantStatus?.loading])

    // < Action sur le choix de date d'effet >
    const onSubmitDateEffect = () => {
        form.validateFields().then((values) => {
            setInitStartDate({
                ...initStartDate,
                startDate: values?.startDate 
            })

        const packsSelected = flotteAutos.filter((auto)=> targetKeys.includes(auto?.key));
        // REconstruire la flotteSubscriptionRequest
        
        const quotationRequests = [];
        for (const packSelected of packsSelected) {
            const quotationRequest = QuotationRequestBuilder.buildFromAssureAndPackSelected({ 
                contract: contract,
                assure: assure,
                period: {
                    ref: contract?.period?.ref,
                    customerStartDate: values?.startDate,
                    customerEndDate: contract?.period?.endDate,
                    prorate: true
                },
                avenant: avenant,
                packSelected: packSelected
             });
            quotationRequests.push(quotationRequest);
        }

        if(quotationRequests.length > 0) {
            const options = {
                query: {
                    contractRef: contract?.ref || null,
                    isFlotte: true
                }
            };

            setState(state=> ({...state, isFlotteQuotationLoading: true}));
            QuotationService.v1GeneratePacks(options, quotationRequests, (data)=> {
                
                // Response quotation for vehicles
                const quotationResponse = QuotationResponse.fromJson(data);

                /* dispatch({type: "updateState", data: [
                    {path: ["fBasket", "quotationResponse"], value: quotationResponse}
                ]}) */

                setState(state=> ({
                    ...state, 
                    primeTotalTTC: quotationResponse?.primeTTC,
                    nbVehicle: quotationResponse?.nbVehicle,
                    isFlotteQuotationLoading: false
                }));

            }, (error)=> {
                toast.error("Une erreur est sruvenue pendant la quotation de la flotte")
                setState(state=> ({...state, isFlotteQuotationLoading: false}));
            })
        }

        }).catch((error) => {

        })
    }

    // QUOTATION FLOTTE
    const generateQuotationFlotte=(baskets)=> {
        // List of quotationRequest
        
        const quotationRequests = (baskets || []).map((basket, index)=> {
            var quotationRequest = (new QuotationRequest()).fromJson(basket?.quotationRequest);
            quotationRequest.packLineRef = basket?.packSelected?.packLineRef;
            quotationRequest.prorate = true;
            return quotationRequest;
        });

        console.log("111111........generateQuotationFlotte quotationRequests: ", quotationRequests);

        if(quotationRequests.length > 0) {
            const options={
                query: {
                    contractRef: contract?.ref || null,
                    isFlotte: true
                }
            };

            //setState(state=> ({...state, isFlotteQuotationLoading: true}));
            QuotationService.v1GeneratePacks(options, quotationRequests, (data)=> {
                
                // Response quotation for vehicles
                const quotationResponse = QuotationResponse.fromJson(data);
                
                const _baskets = [];
                const primeTotalHF      = QuotationService.calculPrimeTTCFromBaskets(baskets);
                const reductionFlotte   = QuotationService.getFlotteReduction(quotationResponse.reductionGroups);

                for(const basket of baskets) {
                    const packSelected = (quotationResponse.packsResponse || []).find((packResponse)=> packResponse.packLineRef === basket?.packSelected?.packLineRef);
                    const _basket = {...basket, packSelected: packSelected, primeHF: basket?.packSelected?.primeTTC || 0};
                    _baskets.push({..._basket})
                }

                /* dispatch({type: "updateState", data: [
                    {path: ["fBasket", "baskets"], value: _baskets},
                    {path: ["fBasket", "quotationResponse"], value: quotationResponse}
                ]}) */
            }, (error)=> {
                toast.error("Une erreur est sruvenue pendant la quotation de la flotte")
                //setState(state=> ({...state, isFlotteQuotationLoading: false}));
            })
        }
    }

    if(state.avenantStatus?.loading) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if(state.avenantStatus?.error) {
        return <ErrorWidget errorMessage={state.avenantStatus?.errorMessage} />;
    }
    // =============================
    
    const onTransferChange = (nextTargetKeys) => {
        
        const packsSelected = flotteAutos.filter((auto)=> nextTargetKeys.includes(auto?.key));
        // REconstruire la flotteSubscriptionRequest
        
        const quotationRequests = [];
        for (const packSelected of packsSelected) {
            const quotationRequest = QuotationRequestBuilder.buildFromAssureAndPackSelected({ 
                contract: contract,
                assure: assure,
                period: {
                    ref: contract?.period?.ref,
                    customerStartDate: initStartDate?.startDate,
                    customerEndDate: contract?.period?.endDate,
                    prorate: true
                },
                avenant: avenant,
                packSelected: packSelected
             });
            quotationRequests.push(quotationRequest);
        }

        if(quotationRequests.length > 0) {
            const options = {
                query: {
                    contractRef: contract?.ref || null,
                    isFlotte: true
                }
            };

            setState(state=> ({...state, isFlotteQuotationLoading: true}));
            QuotationService.v1GeneratePacks(options, quotationRequests, (data)=> {
                
                // Response quotation for vehicles
                const quotationResponse = QuotationResponse.fromJson(data);

                /* dispatch({type: "updateState", data: [
                    {path: ["fBasket", "quotationResponse"], value: quotationResponse}
                ]}) */

                setState(state=> ({
                    ...state, 
                    primeTotalTTC: quotationResponse?.primeTTC,
                    nbVehicle: quotationResponse?.nbVehicle,
                    isFlotteQuotationLoading: false
                }));

            }, (error)=> {
                toast.error("Une erreur est sruvenue pendant la quotation de la flotte")
                setState(state=> ({...state, isFlotteQuotationLoading: false}));
            })
        }

        setTargetKeys(nextTargetKeys);
    };

    // ==============================

    // TRANSACTION STATUS
    const paymentStatus=(status)=> {
        if(!status) {
            toast.error("Une erreur s'est produite");
            return;
          }
      
          if(status && status?.error) {
            toast.error(status.errorMessage);
            return;
          }
  
        /* dispatch({type: "updateState", data: [
            { path: ['paymentStatus'], value: status },
            { path: ['transactionResponse'], value: status?.transactionResponse },
            {path:['flotteWidget'], value: helpers.PAGES.THANK_YOU}
        ]}) */
    }

    // ON Step Assure And Contract Validation
    const onGoToDetails=()=> {

        const fSubscriptionRequest = FSubscriptionRequestBuilder.fromStatelessPrevSubscription({
            prevSubscription: initAvenantResponse?.subscription,
            setFields : {
                ...initAvenantResponse?.subscription,
                assure: assure, 
                avenantRef: avenant?.ref, 
                avenantTitle: avenant?.title,
                contractRef: contract?.ref, 
                dateEffect: initStartDate?.startDate,
                
                customerPeriod: false,
                periodTitle:  "Personnalisé",
                customerStartDate:  initStartDate?.startDate,
                customerEndDate:  initStartDate?.endDate,
                prorate: true,

                primeTTC    : quotationResponse?.primeTTC,
                primeNette  : quotationResponse?.primeNette,
                primeExtra  : quotationResponse?.primeExtra,
            }
        })

        /* dispatch({type: "goToNextStep", data: [
            {
                path:['fBasket','fSubscriptionRequest'], value: fSubscriptionRequest 
            }
        ]}); */
    }

    const childrenRender=()=> {
        switch (currentStep) {
            case 0: {
                return (
                    <Card>
                        <CardHeader>
                            <Row className="mb-3 pb-1">
                                <Col xs={12}>
                                    <div className="d-flex align-items-start gap-3 mt-3">
                                        <div className="flex-grow-1">
                                            <h4>
                                                Souscription flotte : 
                                                <Tag color= "rgb(0, 117, 201)" style={{ fontSize: 22, padding: "2px"}}>
                                                    <span className="d-flex align-items-center">
                                                        {
                                                            state.isFlotteQuotationLoading ? <Spinner size="sm" type="grow" className="flex-shrink-0"> Loading... </Spinner> : null
                                                        }
                                                        
                                                        <span className="flex-grow-1 ms-2" style={{fontWeight: "bold"}}>
                                                            {
                                                                state?.primeTotalTTC === 0 ? "****** F CFA" : `-${helpers.thousandSeparator(state?.primeTotalTTC)} F CFA`
                                                            }
                                                        </span>
                                                    </span>
                                                    
                                                </Tag>
                                            </h4>
                                        </div>
                                        
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <TableTransfer
                                titles={['Vehicules de la flotte', 'Véicules à rétirer']}
                                dataSource={flotteAutos}
                                targetKeys={targetKeys}
                                disabled={false}
                                showSearch={true}
                                onChange={onTransferChange}
                                filterOption={(inputValue, item) =>
                                    item?.vehicle?.model?.brand?.title?.toLowerCase().indexOf(inputValue) !== -1 ||
                                    item?.vehicle?.model?.title?.toLowerCase()?.indexOf(inputValue) !== -1 ||
                                    item?.vehicle?.matriculation?.toLowerCase()?.indexOf(inputValue) !== -1 ||
                                    item?.vehicle?.chassisNumber?.toLowerCase()?.indexOf(inputValue) !== -1
                                }
                                leftColumns={leftTableColumns}
                                rightColumns={rightTableColumns}
                                />

                                <Row>
                                    <Col lg={12}>
                                        <div className="d-flex align-items-start gap-3 mt-3">
                                            <button
                                                onClick={onGoToDetails}
                                                type="button"
                                                className="btn btn-primary btn-label right ms-auto nexttab"
                                                disabled={(targetKeys.length < 1)}
                                            >
                                                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{" "}
                                                <UserOutlined style={{ color: '#ffffff', fontSize: 20 }} /> ALLER AU DETAILS
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                    </Card>
                );
            }
    
            case 1: {
                return (
                    <FStepDetails />
                );
            }
    
            case 2: 
                if(page === helpers.FlotteWidget.THANK_YOU) {
                    return <FStepThankYou transactionResponse={transactionResponse} />
                }
                break;

            default:
            
            /* 
            default:
                return (
                    <ListeVehicules
                        //goToNextStep={goToNextStep}
                        contract={contract}
                        avenant={avenant}
                        flotteBasket={state.flotteBasket}
                    />
                ); */
        }
    }
    

    return (
        <React.Fragment>
            <WizardHeader
                title={`Categorie ${category.code} : ${category.title}`}
                avenantTilte={`Avenant de retrait de vehicule`}
                partnerCode={partnerCode}
                police={contract?.policeNumber}
                percent={currentStep * 25}
            />

            <div className="d-flex align-items-start gap-3 mt-3">
                {
                    currentStep == 0 ? (
                        <div className="right ms-auto nexttab">
                            <Row>
                                <Col md={12}>
                                    <Form
                                        name="basic"
                                        onFinish={onSubmitDateEffect}
                                        layout="vertical"
                                        form={form}
                                    >
                                        <Form.Item
                                                label={"Date d'éffet de l'avenant"}
                                                name="startDate"
                                                rules={[{ required: true, message: "Champ obligatoire" }]}
                                            >
                                                <DatePicker 
                                                    style={{ width: '100%' }} 
                                                    size='large' 
                                                    format={"DD/MM/YYYY"} 
                                                    //defaultValue={moment()}
                                                    onChange={onSubmitDateEffect}
                                                    disabledDate={helpers.disableDateRanges({
                                                        startDate: initStartDate?.initialDate, 
                                                        endDate: initStartDate?.endDate
                                                    })} 
                                                    />
                                            </Form.Item>
                                        
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    ) : null
                }
                
            </div>

            <Card>
                <CardBody>
                    {childrenRender()}
                </CardBody>
            </Card>
        </React.Fragment>
    )
}
