import React, { useEffect, useState } from "react";
import * as helpers from 'helpers/helpers'
import { Card, Spin } from "antd";
import ErrorWidget from "components/Widgets/ErrorWidget";
import { StepThankYou } from "../../common/steps";
import useInitAvenant from "../useInitAvenant";
import WizardHeader from "modules/components/WizardHeader";
import { useSelector } from "react-redux";
import { StepDetails } from "./steps";
import QuotationService from "services/quotationService";
import { useBasket } from "views/pages/subscription/hooks/useBasket";
import _ from "lodash";
import QuotationResponse from "api/v1/response/QuotationResponse";

export default function AvenantRenouvellementSansModification() {
    
    const { avenant, contract, category, partnerCode } = useSelector((state) => state.subscription);
    const { currentStep, page, basket, transactionResponse, initAvenantResponse } = useSelector((state) => state.basketSlice);
    const { updateBasket } = useBasket();
    const { state } = useInitAvenant();
    const [quoting, setQuoting] = useState(true);

    useEffect(()=> {
        if(!state.isInitAvenantLoading) {
            const _quotationRequest = {
                ...basket.quotationRequest,
                calculateGuarantee: true,
                contractRef: contract?.ref,
                prorate: false
            }

            QuotationService.quote({quotationRequests: [_quotationRequest], t: 'm'}, (result)=> {
                const quotationResponse = QuotationResponse.fromJson(result);
                const primeTTC = quotationResponse?.primeTTC
                const primeExtra = quotationResponse?.primeExtra;
                const primeNette = quotationResponse?.primeNette;
                //const extras = quotationResponse?.extras || []
                const pack = _.first(quotationResponse?.packsResponse)
                
                updateBasket({
                    ...basket,
                    subscriptionRequest: {
                       ...basket?.subscriptionRequest,
                       primeTTC    : primeTTC,
                       primeNette  : primeExtra,
                       primeExtra  : primeNette
                    },
                    packSelected: {
                        ...basket?.packSelected,
                        ...pack 
                    }
                })

                setQuoting(false)
            }, (error)=> {

            })
        }
        
    }, [state.isInitAvenantLoading])

    if(state.isInitAvenantLoading || quoting) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if(state.iniAvenantLoadingError) {
        return <ErrorWidget errorMessage={state.iniAvenantLoadingErrorMessage} />;
    }

    function renderChildren() {
        switch (currentStep) {
            case 0:
                return <StepDetails
                            avenantCode={avenant?.code}
                            endDate={initAvenantResponse?.endDate}
                            subscriptionRequest={basket?.subscriptionRequest}
                            packSelected={basket?.packSelected}
                            />
                case 1:

                    if(page === helpers.PAGES.THANK_YOU) {
                        return <StepThankYou transactionResponse={transactionResponse} />;
                    }
                    break;
            default:
                return <ErrorWidget errorMessage="Ooops ! la page n'existe pas" />
        }
    }

    return (
        <React.Fragment>
            <WizardHeader
                title={`Categorie ${category?.code} : ${category?.title}`}
                avenantTilte= {`Avenant de renouvellement sans modification`}
                partnerCode={partnerCode}
                police={contract?.policeNumber}
                percent={state.currentStep * 100}
                />
            {renderChildren()}
        </React.Fragment>
    )
}