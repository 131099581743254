import { LeftCircleOutlined } from "@ant-design/icons";
import { Col, Form, Input } from "antd";
import { CATEGORY_406 } from "constantes";
import React from "react";
import { useEffect } from "react";

const CustomerBrandField= ({categoryCode, disable, handlerFormField, setCustomerBrand, xs, md})=> {

    useEffect(()=> {

    }, [])

    const resetCustomerBrandFields= ()=> {
        handlerFormField({
            customerBrand: false,
            
            brandRef: null,
            brandTitle: null,

            modelRef: null,
            modelTitle: null
        })
    } 

    if([CATEGORY_406].includes(categoryCode)) return null;

    return (
      <React.Fragment>
        <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
            <Form.Item
                label={(
                            <div style={{ display: 'flex' }}>
                                <span>Saisissez la marque</span>
                                    <LeftCircleOutlined style={{ marginLeft: 10, marginTop: 5, cursor: 'pointer' }} onClick={() => {setCustomerBrand(false); resetCustomerBrandFields()}} />
                                </div>)}
                name="brandTitle"
                rules={[{ required: true, message: "Champ obligatoire" }]}
            >
                <Input placeholder='Saisissez la marque' size='large' disabled={disable} />
            </Form.Item>
        </Col>
          
      </React.Fragment>
    )  
  }
  
  export default CustomerBrandField;