import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from 'redux/slices/authSlice'


function Logout() {
    const accessToken = useSelector((state) => state.auth.accessToken)
    const dispatch = useDispatch()

    const { replace } = useHistory()

    useEffect(() => {
        dispatch(logout())
    }, []);

    if (accessToken) {
        replace('/')
    }

    return (
        <div>Déconnexion en cours...</div>
    )
}

export default Logout