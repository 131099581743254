import React from 'react';
import { Spin } from "antd";
import * as helpers from 'helpers/helpers';
import useInitFlotteAvenant from "../useInitFlotteAvenant";
import ErrorWidget from 'components/Widgets/ErrorWidget';
import { FStepThankYou } from '../nouvelle_subscription/steps';
import { useSelector } from 'react-redux';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';
import { Card } from 'reactstrap';
import { FStepDetails, FStepIntro, FStepListVehicle } from './steps';
import WizardHeader from 'modules/components/WizardHeader';

export default function RenouvellementAvecModification() {
    const { avenant, category, partnerCode, projectCode, isProject, assure } = useSelector((state) => state.subscription);
    const {
        currentStep, 
        page, 
        basket,
        baskets,
        fSubscriptionRequest, 
        quotationResponse, 
        transactionResponse,
        initAvenantResponse
    } = useSelector((state) => state.basketSlice);

    const {state: iniAvenant } = useInitFlotteAvenant();
    const {goToPreviousStep} = useBasket();

    if (iniAvenant?.avenantStatus?.loading) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if (iniAvenant?.avenantStatus?.error) {
        return <ErrorWidget errorMessage={iniAvenant?.avenantStatus?.errorMessage} />;
    }

    if(page === helpers.FlotteWidget.THANK_YOU) {
        return <FStepThankYou transactionResponse={transactionResponse} />
    } 

    const renderChildren = ()=> {
        switch(currentStep) {
            case 0:
                return <FStepIntro formField={basket?.formField}/>
            case 1:
                return <FStepListVehicle 
                            baskets={baskets}
                            basket={basket}
                            assure={assure}
                            isProject={isProject}/>

            case 2:
                return <FStepDetails
                            avenantCode={avenant?.code}
                            endDate={initAvenantResponse?.endDate}
                            initAvenantResponse={initAvenantResponse}
                            quotationResponse={quotationResponse} 
                            fSubscriptionRequest={fSubscriptionRequest}
                            />
            case 4:
            break;
            default:
                return <ErrorWidget errorMessage="Ooops ! la page n'existe pas" />;

        }
    }

    return (
        <React.Fragment>
            <WizardHeader
                title={`Categorie ${category.code} : ${category.title}`}
                avenantTilte={`Avenant de renouvellement avec modification`}
                partnerCode={partnerCode}
                police={projectCode}  
                //onClick={(state.currentStep > 0 && state.currentStep < 4) ? goToPreviousStep : null}
                percent={currentStep * 25}
            />
            {renderChildren()}
        </React.Fragment>
    );
}