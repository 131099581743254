import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class PackageService {

    static createPackage(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.createPackageResource, payload, sCallBack, eCallBack)
    }

    editPackage(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.editPackageResource, payload, sCallBack, eCallBack);
    }

    static getAllPackages(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.packageResource, {}, sCallBack, eCallBack)
    }

    static getPackLinesByCatogory(options, sCallBack, eCallBack) {
        ApiRoutes.packLinesResource.resourceOptions = Object.assign({}, ApiRoutes.packLinesResource.resourceOptions, options);
        exeRequest(ApiRoutes.packLinesResource, {}, sCallBack, eCallBack)
    }

    static toggleStatus(options, sCallBack, eCallBack) {
        ApiRoutes.toggleStatusPackageResource.resourceOptions = Object.assign({}, ApiRoutes.toggleStatusPackageResource.resourceOptions, options);
        exeRequest(ApiRoutes.toggleStatusPackageResource, {}, sCallBack, eCallBack)
    }

    static copyPackGuarantee(options,  sCallBack, eCallBack) {
        ApiRoutes.copyPackGuaranteeResource.resourceOptions = Object.assign({}, ApiRoutes.copyPackGuaranteeResource.resourceOptions, options);
        exeRequest(ApiRoutes.copyPackGuaranteeResource, {}, sCallBack, eCallBack)
    }
}