import { exeRequest } from 'http/requestConfig'
import * as constants from 'constantes';

export function getValidatedCommissions(uidDelear, sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(commissions), 1000);
  exeRequest(`caisse/commission/concessionnaire/${uidDelear}`, "GET", null, sCallBack, eCallBack)
}

export function getOpEmis(sCallBack, eCallBack) {
  exeRequest('operation-guichet/emis', "GET", null, sCallBack, eCallBack)
}

export function getOpAValider(sCallBack, eCallBack) {
  exeRequest('operation-guichet/validation', "GET", null, sCallBack, eCallBack)
}

export function getDealerById(id, sCallBack, eCallBack) {
  // const dealer = dealers.find(item => item.id == id || item.code == id);

  // setTimeout(() => sCallBack(dealer), 500);
  exeRequest(`concessionnaire/status/${id}`, "GET", null, sCallBack, eCallBack);
}

export function getDealerBills(dealerUID, sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(bills), 1000);
  exeRequest(`concessionnaire/factures-non-soldees/${dealerUID}`, "GET", null, sCallBack, eCallBack)
}

export function payBills(dealerUID, data, sCallBack, eCallBack) {
  const formatData = {
    borderaux: data.amounts.bordereau.map(item => ({
      banque: { id: item.etablissementId },
      numeroBorderau: item.numeroBordereau,
      montant: item.montant,
      nom: item.nom || ""
    })),
    cheques: data.amounts.cheque.map(item => ({
      banque: { id: item.banqueId },
      numeroCheque: item.numeroCheque,
      montant: item.montant,
      matricule: item.matricule || ""
    })),
    libelle: data.values.libelle,
    montant: data.values.montant,
    dataFactureNonSoldeeFromFournisseurList: data.selectedBills
  };
  exeRequest(`concessionnaire/paiement-facture/${dealerUID}`, "POST", formatData, sCallBack, eCallBack)
}


export function sendWithdrawal(data, sCallBack, eCallBack) {
  setTimeout(() => sCallBack({ message: "Opération effectuée avec succès" }), 3000);
}

export function getChecks(sCallBack, eCallBack) {
  exeRequest('status', "GET", null, sCallBack, eCallBack)
}

export function getBanks(sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(banks), 1000);
  exeRequest('banque', "GET", null, sCallBack, eCallBack)
}

export function getUserProfileByRegisterNumber(matricule, sCallBack, eCallBack) {
  setTimeout(() => {
    sCallBack({
      nom: "Yao",
      prenom: "CHRISTIAN",
      matricule: "AA99PO",
      fonction: "Développeur"
    })
  }, 100);
  // exeRequest('approvisionnements-en-cours',"GET",null,sCallBack,eCallBack)
}

export function getOtherTypesOfOperations(sCallBack, eCallBack) {
  setTimeout(() => {
    sCallBack([
      { id: 1, libelle: "Bon provisoire" },
      { id: 2, libelle: "Bon de mission" },
    ])
  }, 1000);
  // exeRequest('approvisionnements-en-cours',"GET",null,sCallBack,eCallBack)
}

export function getUnpaidDaysByDealerId(id, sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(journeesNonSoldees, 3000));
  exeRequest(`concessionnaire/journees-non-soldees/${id}`, "GET", null, sCallBack, eCallBack)
}

export function getCashRegisterInfo(sCallBack, eCallBack) {
  setTimeout(() => sCallBack({
    libelle: "GUICHET 001",
    solde: 4000000,
    soldeOuverture: 1000000,
    totalOperationCredit: 125,
    totalOperationDebit: 50,

    montantEspece: 1000000,
    montantCheque: 1000000,
    montantBordereau: 1000000,
    autresMontant: 1000000,
  }, 1000));
  // exeRequest('approvisionnements-en-cours',"GET",null,sCallBack,eCallBack)
}

export function getOperartorProfile(sCallBack, eCallBack) {
  setTimeout(() => sCallBack({
    // oid: "GICHET 001",
    nom: "Yao",
    prenom: "Christian",
    matricule: "LON001",
    profile: "Guichetière",
    mobile: "07151781",
  }), 500);
  // exeRequest('approvisionnements-en-cours',"GET",null,sCallBack,eCallBack)
}

export function getProducts(sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(products), 100);
  exeRequest('produit', "GET", null, sCallBack, eCallBack)
}

export function checkTicketCode(data, sCallBack, eCallBack) {
  // setTimeout(() => sCallBack({
  //     message: "Félicitation, ticket gagnant",
  //     montant: 5000000,
  //     periodeValiditee: "2022-02-08T00:00:00Z"
  // }), 3000);

  exeRequest('statut-ticket', "POST", data, sCallBack, eCallBack)
}

export function checkTicketCodeSiege(data, sCallBack, eCallBack) {
  exeRequest('statut-ticket-siege', "POST", data, sCallBack, eCallBack)
}

export function validatePaymentLot(data, sCallBack, eCallBack) {
  // setTimeout(() => sCallBack({
  //     message: "Votre opération été effectuée avec sussès, veuillez imprimer votre ticket",
  //     ticketUrl: "2022-02-08T00:00:00Z"
  // }), 3000);
  exeRequest('operation-guichet', "POST", data, sCallBack, eCallBack)
}

export function createOp(data, sCallBack, eCallBack) {
  exeRequest('operation-guichet', "POST", data, sCallBack, eCallBack)
}

export function validateOp(data, sCallBack, eCallBack) {
  exeRequest('operation-guichet/validation', "PUT", data, sCallBack, eCallBack)
}

export function validateCommissions(data, sCallBack, eCallBack) {
  exeRequest('commission/prelevement', "POST", data, sCallBack, eCallBack)
}

export function getCurrencies(sCallBack, eCallBack) {
  setTimeout(() => sCallBack([
    { id: 1, code: "F CFA", libelle: "FRANC CFA" },
    { id: 2, code: "€", libelle: "EURO" },
    { id: 2, code: "$", libelle: "DOLLAR" },
  ]), 100);
  // exeRequest('approvisionnements-en-cours',"GET",null,sCallBack,eCallBack)
}



/*--- Début Requêtes communes ---*/

export function getApprovisionnements(guichetId, sCallBack, eCallBack) {
  exeRequest(`status/caisse/${guichetId}`, "GET", null, sCallBack, eCallBack)
}

export function getApprovisionnementsEnCours(guichetId, sCallBack, eCallBack) {
  exeRequest(`status/caisse/${guichetId}`, "GET", null, sCallBack, eCallBack)
}

export function getCashForCashStop(guichetId, sCallBack, eCallBack) {
  exeRequest(`status/caisse/${guichetId}`, "GET", null, sCallBack, eCallBack)
}

export function getChecksForCashStop(guichetId, sCallBack, eCallBack) {
  exeRequest(`status/caisse/${guichetId}`, "GET", null, sCallBack, eCallBack)
}

export function getSlipsForCashStop(sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(slips), 500);
  // exeRequest('approvisionnements-en-cours',"GET",null,sCallBack,eCallBack)
}

export function getStatusCaisse(sCallBack, eCallBack) {
  exeRequest('status', "GET", null, sCallBack, eCallBack)
}

/** Obtenir la liste des bon provisoires d'un personnel par son matricule. */
export function getBonProvisoireListOfStaff(matricule, sCallBack, eCallBack) {
  exeRequest(`caisse/bon-provisoire?matricule=${matricule}`, "GET", null, sCallBack, eCallBack)
}

/** Obtenir la liste des frais de missions d'un personnel par son matricule. */
export function getFraisMissionListOfStaff(matricule, sCallBack, eCallBack) {
  exeRequest(`caisse/frais-mission?matricule=${matricule}`, "GET", null, sCallBack, eCallBack)
}

/** Obtenir la liste des chèques d'un personnel par son matricule. */
export function getCheckListOfStaff(matricule, sCallBack, eCallBack) {
  exeRequest(`caisse/cheque?matricule=${matricule}`, "GET", null, sCallBack, eCallBack)
}

/** Obtenir la liste du personnel. */
export function getTheStaffList(sCallBack, eCallBack) {
  exeRequest('tiers/operateur', "GET", null, sCallBack, eCallBack);
}

/** Obtenir la liste des commissions du concessionnaire. */
export function getCommissions(uidDelear, sCallBack, eCallBack) {
  exeRequest(`commission/concessionnaire/${uidDelear}`, "GET", null, sCallBack, eCallBack)
}

/** Obtenir la liste des opérations. Par defaut, c'est la liste de la journée */
export function getHistoriquesOp(data, sCallBack, eCallBack) {
  exeRequest('operation-guichet', "GET", null, sCallBack, eCallBack)
}

/** Obtenir les détails d'une opération */
export function getOpDetails(idOperationGuichet, sCallBack, eCallBack) {
  exeRequest(`operation-guichet/${idOperationGuichet}`, "GET", null, sCallBack, eCallBack)
}

/** Obtenir les détails d'un guichet */
export function getDetailsGuichet(guichetId, sCallBack, eCallBack) {
  exeRequest(`status/caisse/${guichetId}`, "GET", null, sCallBack, eCallBack)
}

/** Obtenir les opérations émise */
export function getTransactionsIssued(sCallBack, eCallBack) {
  exeRequest(`workflow/emis`, "GET", null, sCallBack, eCallBack)
}

/** Obtenir les opérations à traiter */
export function getTransactionsToBeProcessed(sCallBack, eCallBack) {
  exeRequest(`workflow/a-valider`, "GET", null, sCallBack, eCallBack)
}

/** Obtenir la liste des concessionnaires */
export function getDealers(sCallBack, eCallBack) {
  exeRequest('tiers/concessionnaire', "GET", null, sCallBack, eCallBack);
}

/** Obtenir la liste des accessoires */
export function getAccessories(sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(accessories), 1000);
  exeRequest('caisse/accessoire', "GET", null, sCallBack, eCallBack)
}

/** Obtenir la liste des bordereaux  */
export function getSlips(sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(slips), 1000);
  exeRequest('status', "GET", null, sCallBack, eCallBack)
}

/** Obtenir la liste des bon provisoires  */
export function getProvisionalCoupons(sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(provisionalCoupons), 1000);
  exeRequest('status', "GET", null, sCallBack, eCallBack)
}

/** Obtenir la liste des frais de missions  */
export function getMissionExpenses(sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(missionExpenses), 1000);
  exeRequest('status', "GET", null, sCallBack, eCallBack)
}

/** Obtenir la liste des ordres de paiement  */
export function getPaymentOrders(sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(paymentOrders), 1000);
  exeRequest('status', "GET", null, sCallBack, eCallBack)
}

export function getBilletages(sCallBack, eCallBack) {
  exeRequest('coupure', "GET", null, sCallBack, eCallBack)
}

export function getCoffreBilletages(sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(billetages), 1000);
  exeRequest('coffre/billetage', "GET", null, sCallBack, eCallBack)
}

export function getSitesSupervision(sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(sites), 1000);
  exeRequest('supervision', "GET", null, sCallBack, eCallBack)
}

export function getSiteDetails(id, sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(siteDetails), 1000);
  exeRequest(`supervision/site/${id}`, "GET", null, sCallBack, eCallBack)
}

export function getGuichetsSiege(sCallBack, eCallBack) {
  exeRequest('listCaisseByCoffreSiege', "GET", null, sCallBack, eCallBack)
}

/*--- Fin Requêtes communes ---*/



/*--- Début Requêtes caissier ---*/

export function openTheCashRegister(sCallBack, eCallBack) {
  exeRequest('caisse/ouverture', "GET", null, sCallBack, eCallBack)
}

export function suspendTheCashRegister(sCallBack, eCallBack) {
  exeRequest('caisse/suspension', "GET", null, sCallBack, eCallBack)
}

/** Arrêter la caisse */
export function stopCashRegister(data, sCallBack, eCallBack) {
  exeRequest("caisse/arret", "POST", data, sCallBack, eCallBack)
}

export function closeTheSafe(data, sCallBack, eCallBack) {
  exeRequest(`coffre/cloture?montantTransfere=${data.montant}`, "POST", data, sCallBack, eCallBack)
}

export function coffrePropositionMontantAtransferer(data, sCallBack, eCallBack) {
  exeRequest(`coffre/proposition-transfert?montant=${data.montant}`, "POST", data, sCallBack, eCallBack)
}

export function brouillardCoffre(sCallBack, eCallBack) {
  exeRequest(`coffre/cloture`, "POST", null, sCallBack, eCallBack)
}

/** Obtenir les stat des frais de missions */
export function getMissionExpensesStat(sCallBack, eCallBack) {
  exeRequest('caisse/frais-mission/stat', "GET", null, sCallBack, eCallBack)
}

/** Obtenir les stat des bons provisoires */
export function getProvisionalCouponsStat(sCallBack, eCallBack) {
  exeRequest('caisse/bon-provisoire/stat', "GET", null, sCallBack, eCallBack)
}

/** Obtenir les stat des bordereaux */
export function getBordereauStat(sCallBack, eCallBack) {
  exeRequest('caisse/bordereau/stat', "GET", null, sCallBack, eCallBack)
}

/** Obtenir les stat des chèques */
export function getCheckStat(sCallBack, eCallBack) {
  exeRequest('caisse/cheque/stat', "GET", null, sCallBack, eCallBack)
}

/** Validder un approvisionnement */
export function validateApprovisionnementOp(data, sCallBack, eCallBack) {
  exeRequest('caisse/approvisionnement/validation', "PUT", data, sCallBack, eCallBack)
}

/*--- Début Requêtes comptable ---*/

/** Obtenir la liste des guichets d'une agence en fonction de l'utisateur conecté */
export function getGuichets(sCallBack, eCallBack) {
  exeRequest('caisse', "GET", null, sCallBack, eCallBack)
}

/** Obtenir la liste des guichets d'une agence en fonction de l'utisateur conecté */
export function getGuichetsByAgenceId(siteId, sCallBack, eCallBack) {
  exeRequest(`'caisse'`, "GET", null, sCallBack, eCallBack)
}

/** Obtenir la liste des guichets d'un site par son id */
export function getGuichetsBySiteId(siteId, sCallBack, eCallBack) {
  exeRequest(`site/${siteId}/caisse`, "GET", null, sCallBack, eCallBack)
}

/** Obtenir la liste des concessionaires d'un site par son id */
export function getConcessionnairesBySiteId(siteId, sCallBack, eCallBack) {
  exeRequest(`tiers/site/concessionnaires?idSite=${siteId}`, "GET", null, sCallBack, eCallBack)
}

export function getAgencyCashRegisters(agencyId, sCallBack, eCallBack) {
  exeRequest('caisse', "GET", null, sCallBack, eCallBack)
}

/** Validder un retrait */
export function validateWithdrawalOp(data, sCallBack, eCallBack) {
  exeRequest('coffre/retrait/validation', "PUT", data, sCallBack, eCallBack)
}

/*--- Fin Requêtes comptable ---*/



/*--- Début requêtes du modules caisse ---*/

export function initOpPaiementLotSiege(data, sCallBack, eCallBack) {
  exeRequest(`paiement-lot-siege`, "POST", data, sCallBack, eCallBack)
}

export function getOpPaiementLotSiegeGagnant(data, sCallBack, eCallBack) {
  exeRequest(`paiement-lot-siege?produitId=${data.produitId}&numeroTicket=${data.numeroTicket}`, "GET", null, sCallBack, eCallBack)
}

export function updateOpPaiementLotSiegeForAttente(data, sCallBack, eCallBack) {
  exeRequest(`paiement-lot-siege-attente`, "POST", data, sCallBack, eCallBack)
}

export function valdationOpPaiementLotSiege(idOperationGuichet, sCallBack, eCallBack) {
  exeRequest(`paiement-lot-siege-valider?idOperationGuichet=${idOperationGuichet}`, "POST", {}, sCallBack, eCallBack)
}

export function validateJournalCaisse(data, sCallBack, eCallBack) {
  exeRequest('caisse/journal', "POST", data, sCallBack, eCallBack)
}

export function annulerRetrait(idOp, sCallBack, eCallBack) {
  exeRequest(`caisse/retrait/annullee/${idOp}`, "POST", null, sCallBack, eCallBack)
}

/*--- Fin requêtes du modules caisse ---*/


/*--- Début requêtes du modules de calcul de commissions ---*/

export function getCommissionsPeriods(sCallBack, eCallBack) {
  exeRequest('commission/periode', "GET", null, sCallBack, eCallBack)
}

export function initCalculCommissions(data, sCallBack, eCallBack) {
  exeRequest('commission/init', "POST", data, sCallBack, eCallBack)
}

export function recalculerCommissions(data, sCallBack, eCallBack) {
  exeRequest('commission/init', "PUT", data, sCallBack, eCallBack)
}

export function getCommissionsCalculee(sCallBack, eCallBack) {
  exeRequest('commission/init', "GET", null, sCallBack, eCallBack)
  // const data = [
  //   {
  //     id: 1,
  //     libelle: "Commission du mois de janvier 2021",
  //     statut: "EN COUR"
  //   },
  // ];

  // setTimeout(() => sCallBack(data), 1000);
}

export function getDetailsCommissionsCalculee(data, sCallBack, eCallBack) {
  exeRequest(`commission/init/detail?annee=${data.annee}&mois=${data.mois}`, "GET", null, sCallBack, eCallBack)

  // const data1 = [
  //   {
  //     id: 1,
  //     annee: "2021",
  //     periode: {
  //       libelle: "Janvier"
  //     },
  //     produit: {
  //       id: 1,
  //       libelle: "loto"
  //     },
  //     concessionnaire: {
  //       id: 1,
  //       nom: "Yao",
  //       prenom: "Christian",
  //     },
  //     montant: 100000,
  //     montantVirement: 20000
  //   },
  //   {
  //     id: 2,
  //     annee: "2020",
  //     periode: {
  //       libelle: "Janvier"
  //     },
  //     produit: {
  //       id: 2,
  //       libelle: "ALR"
  //     },
  //     concessionnaire: {
  //       id: 1,
  //       nom: "Yao",
  //       prenom: "Christian",
  //     },
  //     montant: 150000,
  //     montantVirement: 260000
  //   },
  // ];

  // setTimeout(() => sCallBack({ data: data1 }), 1000);
}

export function validateCommissionsCalculee(data, sCallBack, eCallBack) {
  exeRequest(`commission/valide-calcul?annee=${data.annee}&mois=${data.mois}`, "PUT", null, sCallBack, eCallBack)
}

export function printCommissionsCalculee(data, sCallBack, eCallBack) {
  // exeRequest(`commission/export?produit=${data.produitId}&agence=${data.siteId}`, "GET", null, sCallBack, eCallBack)
  window.open(`${constants.API_BASE_URL}/commission/export?annee=${data.annee}&mois=${data.mois}&produit=${data.produitId}&agence=${data.siteId}&format=${data.format}`, "_blank")
}

export function printCommissionsPayeesOuCalculees(data, sCallBack, eCallBack) {
  window.open(`${constants.API_BASE_URL}/commission/export/critere?paye=${data.paye}&dateDebut=${data.dateDebut}&dateFin=${data.dateFin}&produit=${data.produit}&agence=${data.agence}&format=${data.format}`, "_blank")
}

export function printCommissionsRetenues(data, sCallBack, eCallBack) {
  window.open(`${constants.API_BASE_URL}/retenueCommission/export/critere?paye=${data.paye}&dateDebut=${data.dateDebut}&dateFin=${data.dateFin}&produit=${data.produit}&agence=${data.agence}&format=${data.format}`, "_blank")
}

/*--- Fin requêtes du modules de calcul de commissions ---*/


/*--- Début requêtes du modules de transferts de fonds ---*/

export function getTiersTransfertEnvisage(sCallBack, eCallBack) {
  exeRequest('coffre/transfert-fond/tiers', "GET", null, sCallBack, eCallBack)
}

export function initTransfertEnvisage(data, sCallBack, eCallBack) {
  exeRequest('coffre/transfert-fond/envisager', "POST", data, sCallBack, eCallBack)
}

export function validateTransfertEnvisage(data, sCallBack, eCallBack) {
  exeRequest('coffre/transfert-fond/envisager/validation', "PUT", data, sCallBack, eCallBack)
}

// export function getBilletageTransfertEnvisage(sCallBack, eCallBack) {
//   exeRequest('coffre/billetage', "GET", null, sCallBack, eCallBack)
// }

export function billetageTransfertEnvisage(data, sCallBack, eCallBack) {
  exeRequest('coffre/transfert-fond/preparation', "POST", data, sCallBack, eCallBack)
}

export function getSocietesConvoyage(sCallBack, eCallBack) {
  exeRequest('societe-convoyage', "GET", null, sCallBack, eCallBack)
}

export function validateTransfertDeFonds(data, sCallBack, eCallBack) {
  exeRequest('coffre/transfert-fond/enlevement', "PUT", data, sCallBack, eCallBack)
}

export function validateReceptionDeTransfertDeFonds(data, sCallBack, eCallBack) {
  exeRequest('coffre/transfert-fond/reception', "POST", data, sCallBack, eCallBack)
}

export function billetageReceptionDeTransfertDeFonds(data, sCallBack, eCallBack) {
  exeRequest('coffre/transfert-fond/reception/validation', "PUT", data, sCallBack, eCallBack)
}

export function getListeTransfertsDeFondsAAnnuler(sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(listeTransfertsDeFondsAAnnuler), 1000);
  exeRequest('workflow/transfert-fonds/ecart', "GET", null, sCallBack, eCallBack)
}

export function getListeTransfertsDeFondsARegulariser(sCallBack, eCallBack) {
  // setTimeout(() => sCallBack(listeTransfertsDeFondsAAnnuler), 1000);
  exeRequest('workflow/transfert-fonds/ecart', "GET", null, sCallBack, eCallBack)
}

export function postAnnulerTransfertsDeFonds(idOp, sCallBack, eCallBack) {
  exeRequest("workflow/transfert-fonds/ecart/annule", "POST", { idEcart: idOp }, sCallBack, eCallBack)
}

export function postConstaterEcartTransfertsDeFonds(idOp, sCallBack, eCallBack) {
  exeRequest("workflow/transfert-fonds/ecart/constate", "POST", { idEcart: idOp }, sCallBack, eCallBack)
}

export function postBilletageRegularisationTransfertDeFonds(data, sCallBack, eCallBack) {
  exeRequest("workflow/transfert-fonds/ecart/regularise", "POST", data, sCallBack, eCallBack)
}

export function postApurerTransfertDeFonds(data, sCallBack, eCallBack) {
  exeRequest("workflow/transfert-fonds/ecart/appure", "POST", data, sCallBack, eCallBack)
}

/*--- Fin requêtes du modules de transferts de fonds ---*/


/*--- Début requêtes du modules chef site ---*/

export function getDealerLotsPayesNotValidate(dealerUID, sCallBack, eCallBack) {
  exeRequest(`concessionnaire/remboursement-lot/${dealerUID}`, "GET", null, sCallBack, eCallBack)
}

export function validateDealerLotsPayes(data, sCallBack, eCallBack) {
  exeRequest("operation-guichet/chef-agence/validation", "POST", data, sCallBack, eCallBack)
}

export function getValidatedDealerLotsPayes(dealerUID, sCallBack, eCallBack) {
  exeRequest(`operation-guichet/remboursement-lot/${dealerUID}`, "GET", null, sCallBack, eCallBack)
}

export function payDealerLotsPayes(dealerUID, sCallBack, eCallBack) {
  exeRequest(`operation-guichet/caissiere/validation/${dealerUID}`, "GET", null, sCallBack, eCallBack)
}

export function checkTicketCodeForAgencyManager(data, sCallBack, eCallBack) {
  exeRequest('statut-numeroTicket/chef-agence', "POST", data, sCallBack, eCallBack)
}

export function validateOdcPaiementLOts(data, sCallBack, eCallBack) {
  exeRequest('validation-ticket/chef-agence', "POST", data, sCallBack, eCallBack)
}

export function checkTicketCodeOdcPaiementLots(data, sCallBack, eCallBack) {
  exeRequest('statut-numeroTicket/caissiere', "POST", data, sCallBack, eCallBack)
}

export function payOdcPaiementLots(data, sCallBack, eCallBack) {
  exeRequest('paiement-ticket/caissiere', "POST", data, sCallBack, eCallBack)
}

/*--- Fin requêtes du modules chef site ---*/



/*--- Début requêtes du module coffre ---*/

export function getRebateChecks(sCallBack, eCallBack) {
  exeRequest(`coffre/cheque/remise`, "GET", null, sCallBack, eCallBack)
}

export function validateRebateChecks(data, sCallBack, eCallBack) {
  exeRequest(`coffre/cheque/remise`, "POST", data, sCallBack, eCallBack)
}

export function getCheckListToBeCleared(sCallBack, eCallBack) {
  exeRequest(`coffre/cheque/appurement`, "GET", null, sCallBack, eCallBack)
}

export function clearCheck(data, sCallBack, eCallBack) {
  exeRequest(`coffre/cheque/appurement`, "POST", data, sCallBack, eCallBack)
}

export function annulerApprovisionnement(idOp, sCallBack, eCallBack) {
  exeRequest(`coffre/approvisionnement/annullee/${idOp}`, "POST", null, sCallBack, eCallBack)
}

/*--- Fin requêtes du module coffre ---*/


/*--- Début requêtes du module paramétres ---*/

export function getUserList(sCallBack, eCallBack) {
  exeRequest(`utilisateur`, "GET", null, sCallBack, eCallBack)
}

export function getProfiles(sCallBack, eCallBack) {

  const profiles = [
    {
      id: 1,
      libelle: "Caisse agence",
      description: "",
      statut: true,
    },
    {
      id: 2,
      libelle: "Comptable agence",
      description: "",
      statut: true,
    },
    {
      id: 3,
      libelle: "Chef exploitation",
      description: "",
      statut: false,
    },
    {
      id: 4,
      libelle: "Chef service finance",
      description: "",
      statut: false,
    },
    {
      id: 5,
      libelle: "Chef d'agence",
      description: "",
      statut: true,
    },
  ];

  setTimeout(() => sCallBack({ data: profiles }), 1000);
}

export function deleteProfile(profile, sCallBack, eCallBack) {
  exeRequest(`profil`, "GET", null, sCallBack, eCallBack)
}

export function toggleStatusProfile(profile, sCallBack, eCallBack) {
  setTimeout(() => sCallBack({ data: true }), 3000);
}

/*--- Fin requêtes du module paramétres ---*/

export function signInAccountantToCloseCashRegister(data, sCallBack, eCallBack) {
  exeRequest('caisse/connexion/comptable', "POST", data, sCallBack, eCallBack)
}

export function closeCashRegister(data, sCallBack, eCallBack) {
  exeRequest("caisse/cloture", "POST", data, sCallBack, eCallBack)
}

export function imprimerFicheIndividuelle(data, sCallBack, eCallBack) {
  exeRequest(`commission/fiche-individuelle?annee=${data.annee}&mois=${data.mois}&uid=${data.uid}&produitId=${data.produitId}`, "GET", null, sCallBack, eCallBack)
}

export function getAgences(sCallBack, eCallBack) {
  exeRequest('site', "GET", null, sCallBack, eCallBack)
}

export function getSites(sCallBack, eCallBack) {
  exeRequest('site', "GET", null, sCallBack, eCallBack)
}

export function getListeCoffres(sCallBack, eCallBack) {
  exeRequest('coffre/', "GET", null, sCallBack, eCallBack)
}

export function getFacturesPartiellesConcessionnaire(concessionnaireUID, sCallBack, eCallBack) {
  exeRequest(`concessionnaire/factures-non-soldees/credit/${concessionnaireUID}`, "GET", null, sCallBack, eCallBack)
}

export function getFacturesTotalesConcessionnaire(concessionnaireUID, sCallBack, eCallBack) {
  exeRequest(`concessionnaire/factures-non-soldees/comptant/${concessionnaireUID}`, "GET", null, sCallBack, eCallBack)
}

export function getListingCommissions(sCallBack, eCallBack) {
  exeRequest(`commission/listing`, "GET", null, sCallBack, eCallBack)
}

export function getEmargementListingCommissions(sCallBack, eCallBack) {
  exeRequest(`commission/ficheEmargement`, "GET", null, sCallBack, eCallBack)
}

export function getCommissionsGrattageAValider(uidConcessionaire, sCallBack, eCallBack) {
  exeRequest(`commission/grattage/concessionnaire/${uidConcessionaire}`, "GET", null, sCallBack, eCallBack)
}

export function validerCommissionsGrattage(uidConcessionaire, data, sCallBack, eCallBack) {
  exeRequest(`commission/grattage/validation/${uidConcessionaire}`, "POST", data, sCallBack, eCallBack)
}

export function getCommissionsGrattageValidees(uidConcessionaire, sCallBack, eCallBack) {
  exeRequest(`caisse/commission/grattage/concessionnaire/${uidConcessionaire}`, "GET", null, sCallBack, eCallBack)
}

export function confirmerTransfertDeFondsVersBanque(data, sCallBack, eCallBack) {
  exeRequest(`coffre/transfert-fond/banque/confirmation`, "PUT", data, sCallBack, eCallBack)
}

export function annulerTransfertEnvisage(data, sCallBack, eCallBack) {
  exeRequest(`coffre/transfert-fond/envisager/annulation/${data.operation.id}`, "PUT", data, sCallBack, eCallBack)
}

/*--- Debut Ticket grattage */

export function checkTicketGrattage(data, sCallBack, eCallBack) {
  exeRequest('produit/grattage/statut-ticket/espece', "POST", data, sCallBack, eCallBack)
}

export function checkTicketGrattageEnEspece(data, sCallBack, eCallBack) {
  exeRequest('produit/grattage/statut-ticket/espece', "POST", data, sCallBack, eCallBack)
}

export function checkTicketGrattageEnNature(data, sCallBack, eCallBack) {
  exeRequest('produit/grattage/statut-ticket/nature', "POST", data, sCallBack, eCallBack)
}

export function getGrattageListeProduits(sCallBack, eCallBack) {
  exeRequest('produit/grattage', "GET", null, sCallBack, eCallBack)
}

export function paieGrattage(data, sCallBack, eCallBack) {
  exeRequest('produit/grattage/espece', "POST", data, sCallBack, eCallBack)
}

export function paieGrattageEspece(data, sCallBack, eCallBack) {
  exeRequest('produit/grattage/espece', "POST", data, sCallBack, eCallBack)
}

export function paieGrattageNature(data, sCallBack, eCallBack) {
  exeRequest('produit/grattage/nature', "POST", data, sCallBack, eCallBack)
}

/*--- Fin Ticket grattage */

export function getDateOperation(sCallBack, eCallBack) {
  exeRequest('status/date-operation', "GET", null, sCallBack, eCallBack)
}

export function odcMontantARegulariser(data, sCallBack, eCallBack) {
  exeRequest(`caisse/regularistion?montant=${data.montant}`, "POST", data, sCallBack, eCallBack)
}

/* --- Début reporting ---*/

export function reportingTicketsMillionnairesPayes(data, sCallBack, eCallBack) {
  exeRequest(`reporting/tickets-millionnaires?startDate=${data.startDate}&endDate=${data.endDate}`, "GET", null, sCallBack, eCallBack)
}

export function reportingODCS(data, sCallBack, eCallBack) {
  exeRequest(`reporting/odcs?idSite=${data.siteId}&idCaisse=${data.caisseId}&date=${data.date}`, "GET", null, sCallBack, eCallBack)
}

export function reportingVenteConsommables(data, sCallBack, eCallBack) {
  exeRequest(`reporting/vente-consommables?idSite=${data.siteId}&idCaisse=${data.caisseId}&date=${data.date}`, "GET", null, sCallBack, eCallBack)
}

export function reportingVersements(data, sCallBack, eCallBack) {
  exeRequest(`reporting/versements?idSite=${data.siteId}&idProduit=${data.produitId}&idCaisse=${data.caisseId}&date=${data.date}`, "GET", null, sCallBack, eCallBack)
}

export function reportingVersement(data, sCallBack, eCallBack) {
  exeRequest(`reporting/versement?idCaisse=${data.caisseId}&data=${data.data}`, "GET", null, sCallBack, eCallBack)
}

export function reportingImpayes(data, sCallBack, eCallBack) {
  exeRequest(`reporting/impayes?idAgence=${data.siteId}&idProduit=${data.produitId}&codeConcessionnaire=${data.codeConcessionnaire}`, "GET", null, sCallBack, eCallBack)
}

export function reportingTransferts(data, sCallBack, eCallBack) {
  exeRequest(`reporting/transferts?idAgence=${data.siteId}&startDate=${data.startDate}&endDate=${data.endDate}`, "GET", null, sCallBack, eCallBack)
}

/* --- Fin reporting ---*/

/* --- Debut parametrage ---*/





export function modifierMotDePasse(data, sCallBack, eCallBack) {
  exeRequest('operateur/motDePasseOublie', "POST", data, sCallBack, eCallBack)
}


export function createUser(data, sCallBack, eCallBack) {
  exeRequest('utilisateurs', "POST", data, sCallBack, eCallBack)
}

export function updateUser(userId, data, sCallBack, eCallBack) {
  exeRequest(`utilisateurs/${userId}`, "POST", data, sCallBack, eCallBack)
}

export function deleteUser(userId, sCallBack, eCallBack) {
  exeRequest(`utilisateur`, "GET", null, sCallBack, eCallBack)
}

export function toggleStatusUser(user, sCallBack, eCallBack) {
  setTimeout(() => sCallBack({ data: true }), 3000);
}






/*------Request for 401 Step1---------*/

// export function modifierMotDePasse(data, sCallBack, eCallBack) {
//   exeRequest('operateur/motDePasseOublie', "POST", data, sCallBack, eCallBack)
// }

export function get401Data(data, link, method, sCallBack, eCallBack) {
  exeRequest(link, method, data, sCallBack, eCallBack)
}












/* --- Fin parametrage ---*/
