import { Tag } from "antd";
import React from "react";
import { thousandSeparator } from "helpers/helpers";
import ReturnNewTitle from "views/pages/common/ReturnNewTitle";

const FDetailsReductionAndPrime=({flotteSubscriptionRequest, extras} )=> {
    return (
        <React.Fragment>
            {ReturnNewTitle('Reduction et Prime')}
            <div style={{  paddingTop: 10, paddingLeft: 20, paddingRight: 20, }}>

                <div style={{ display: 'flex', width: '100%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                    <h4 style={{ marginRight: 10 }}>Prime nette </h4>
                    <h4 style={{ color: '#52c41a' }}><Tag color='gray'>{thousandSeparator(flotteSubscriptionRequest?.primeNette) + " FCFA"} </Tag></h4>
                </div>

                <>
                    {
                        (extras || []).map(function (item, index) {
                            return (
                                <div style={{ display: 'flex', width: '100%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }} key={index}>
                                    <h4 style={{ marginRight: 10 }}>{item.title} </h4>
                                    <h4 style={{ color: '#52c41a' }}><Tag color='gray'>{thousandSeparator(item.amount) + " FCFA"}</Tag></h4>
                                </div>
                            )
                        })
                    }
                </>
                <div style={{ display: 'flex', width: '100%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid #31CE49' }}>
                    <h4 style={{ marginRight: 10, fontSize: 22 }}>Prime TTC  </h4>
                    <h4 style={{ color: '#52c41a', size: 'large', fontSize: 22 }}><Tag color= "rgb(0, 117, 201)" style={{ fontSize: 22, padding: "2px"}}>{thousandSeparator(flotteSubscriptionRequest?.primeTTC)} FCFA</Tag></h4>
                </div>  
            </div>
        </React.Fragment>
    )
}

export default FDetailsReductionAndPrime;