import React from "react";
import {  Col, Form, Select, Spin } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import { useState } from "react";
import { useEffect } from "react";
import SerialService from "services/SerialService";

const SerialNatureField=({ disable, xs, md })=> {
    const { Option } = Select;

    const [state, setState]= useState({
        natures: [],
        isNatureLoading: false
    })

    useEffect(()=> {
        getAllSeriesNatures()
    }, [])

    const getAllSeriesNatures = () => {
        
        setState((state) => ({ ...state, isNatureLoading: true }));
        SerialService.getAllNatures((data) => {
            setState(state => ({ ...state, natures: data || [], isNatureLoading: false }))
        }, (error) => {
            setState((state) => ({ ...state, isEntitiesLoading: false }));
        });
    }
    
    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Categorie"
                    name="natureCode"
                    rules={[{ required: false, message: "Champ obligatoire" }]}
                >
                    {
                        state.isNatureLoading ? <Spin /> :
                            (<SelectWithFilter placeholder='Categorie' disabled={disable}>
                                {state.natures.map((nature, index) => (<Option value={nature?.code} key={index}>{nature?.title}</Option>))}
                            </SelectWithFilter>)
                    }

                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default SerialNatureField;