import { Col, Form, Row } from "antd";
import CustomAntdForm from "modules/components/Forms/CustomAntdForm";
import React, { useEffect, useState } from "react";
import TitleField from "../../composants/fields/TitleField";
import ValueOrFormulaField from "../../composants/fields/ValueOrFormulaField";
import SurpimeField from "../../composants/fields/SurprimeField";
import EngagementField from "../../composants/fields/EngagementField";
import SocialStatusField from "../../composants/fields/SocialStatusField";
import AssureClassField from "../../composants/fields/AssureClassField";
import TransportField from "../../composants/fields/TransportField";
import AreaField from "../../composants/fields/AreaField";
import FiscalPowerField from "../../composants/fields/FiscalPowerField";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import CategoryService from "services/categoryService";
import SeatField from "../../composants/fields/SeatField";
import RedevanceField from "../../composants/fields/RedevanceField";

const CriterionForm=({initialCriterion, category, pack, guarantee, defaulEngamentRef, refreshPacksAndGuaranties})=> {
    const [form] = Form.useForm();
    const [isDefaulEngagement, setIsDefaultEngagement] = useState(initialCriterion?.variables?.engagementRef === defaulEngamentRef ? 1 : 0)
    const [state, setState] = useState({
        isPackGuaranteeAdding: false,
        initialCriterion: {}
    })

    useEffect(()=> {

        if(initialCriterion) {
            const _initialCriterion ={
                id: initialCriterion?.id,
                title: initialCriterion?.title,
                value: initialCriterion?.value,
                surprime: initialCriterion?.surprime,
                rds: initialCriterion?.redevance,
                areaRef: initialCriterion?.variables?.areaRef,
                transportRef: initialCriterion?.variables?.transportRef,
                engagementRef: initialCriterion?.variables?.engagementRef,
                fiscalPowerRef: initialCriterion?.variables?.fiscalPowerRef,
                socialClassRef: initialCriterion?.variables?.socialClassRef,
                socialStatusRef: initialCriterion?.variables?.socialStatusRef
            }

            form.setFieldsValue({..._initialCriterion})
            setState(state=>({...state, initialCriterion: _initialCriterion}))
        }
    }, [initialCriterion])

    const validateCriterion=()=> {
        form.validateFields().then((values)=> {
            const _values = Object.assign({}, {...values}, {id: state?.initialCriterion?.id, isDefaultEngagement: isDefaulEngagement === 1})
            console.log(":::: Criterion :", _values);
            
            const editGuaranteeCriterionRequest = {..._values};
            const options = {
                path: { 
                    categoryRef: category?.ref,
                    packRef: pack?.ref,
                    guaranteeRef: guarantee?.ref
                }
            }
            setState(state=>({...state, isPackGuaranteeAdding: true}));
            CategoryService.modifyGuaranteeCriterion(options, editGuaranteeCriterionRequest, (data)=> {
                console.log(":::: data ", data)
                toast.success("La garantie a été ajoutée avec succès")
                //refreshPacksAndGuaranties(category, true)
                setState(state=>({...state, isPackGuaranteeAdding: false}))
            }, (error)=> {
                toast.error("Echec d'enregistrement des critères de cotation")
                setState(state=>({...state, isPackGuaranteeAdding: false}))
            })
        }).catch((error)=> {
            console.log(":::: Criterion form error :", error)
            toast.error("Echec d'enregistrement des critères de cotation")
            setState(state=>({...state, isPackGuaranteeAdding: false}))
        })
    }

    return (
        <React.Fragment>
            <CustomAntdForm  form={form} initialValues={state?.initialCriterion}>
                <Row gutter={8}>
                    <Col md={24}><TitleField /></Col>
                    <Col md={24}><ValueOrFormulaField /></Col>
                    <Col md={24}><SurpimeField /></Col>
                    <Col md={24}><RedevanceField /></Col>
                    <Col md={24}><EngagementField isDefaulEngagement={isDefaulEngagement} setIsDefaultEngagement={setIsDefaultEngagement} /></Col>
                    <Col md={12}><SocialStatusField /></Col>
                    <Col md={12}><AssureClassField /></Col>
                    <Col md={12}><AreaField /></Col>
                    <Col md={12}><FiscalPowerField categoryCode={category?.code}/></Col>
                    <Col md={12}><TransportField categoryRef={category?.ref}/></Col>
                    <Col md={12}><SeatField categoryRef={category?.ref}/></Col>
                    <Col md={24}>
                        <div className="d-flex flex-row-reverse">
                            <button
                                type="button"
                                className="btn btn-primary btn-label right nexttab btn-sm"
                                
                                onClick={() => validateCriterion()}
                                onLoad
                            >
                                <i className="bx bx-plus label-icon align-middle fs-16 ms-2"></i>{" "}
                                {state.isPackGuaranteeAdding ? (<LoadingOutlined style={{ color: '#ffffff', fontSize: 20 }} />) : (<></>)}
                                { initialCriterion?.id ? "Appliquer la modif" : "Ajouter critère"}
                            </button>
                        </div>
                    </Col>
                </Row>
                
                
            </CustomAntdForm>
        </React.Fragment>
    );
}

export default CriterionForm;