export default class FormEditGarantie {
    id;
    code;
    title;
    description;

    static fromJson = (json = {}) => {
        var formEditGarantie = new FormEditGarantie();
        formEditGarantie = json;
        return formEditGarantie;
    }
}