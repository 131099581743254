import { Input, Modal, Segmented, Select, Space } from "antd";
import Avenant from "api/model/avenant.model";
import { CAN_PRODUCT_FOR_OTHER } from "profileActions";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { basketRaz } from "redux/slices/basketSlice";
import { sUpdateState } from "redux/slices/subscriptionSlice";
import { SubscriptionWidget } from "./component/ComponentWidget";
import SubscriptionHeaderContext from "./component/SubscriptionHeaderContext";
import EntitiesService from "services/entitiesService";
import { NavigationPush } from "api/routeManager";
import { useHistory } from "react-router-dom";
import CategoryService from "services/categoryService";
import { SubscriptionSwitchOption } from "helpers/helpers";
import  './style.css';
import { BarsOutlined, ProjectOutlined, UserOutlined } from "@ant-design/icons";

const SubscriptionHeader=({category, handleNouvelAvenant, switchValue, onSwitchValue})=> {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();
    const Option = Select;

    const [state, setState]=useState({
        // CATEGORIES
        categories: [],
        categorySelected: null,
        isCategorieLoading: false,

        modalNewSubVisibility: false,

        modalCodePartnerVisibility: false,
        partnerCode: null,
        isPartnerCodeSearching: false,
        isPartnerCodeSearchError: false,
        partnerCodeSearchErrormessage: '',
        partnerCategorySelectErrormessage: ''
    })

    useEffect(()=> {
        setState(state=> ({...state, categorySelected: category}))
    }, [])

    const options = [
        {
            label: 'LISTE DES SOUSCRIPTIONS',
            value: SubscriptionSwitchOption.LIST_SUBSCRIPTION,
            icon: <BarsOutlined />
        },
        {
            label: 'LISTE DES ASSURES',
            value: SubscriptionSwitchOption.LIST_ASSURE,
            icon: <UserOutlined />
        },
        {
            label: 'LISTE DES SOUSCRIPTIONS PROJETS',
            value: SubscriptionSwitchOption.LIST_PROJECT,
            icon: <ProjectOutlined />
        }
    ];

    const onSegmentSwitchChange = (value) => {
        console.log(":::: onSwitchValue(value) : ", value )
        onSwitchValue(value)
    };

    const onPartnerCodeInputChange = (e) => setState(state => ({ ...state, partnerCode: e?.target.value }))
    
    const onOtherPartnerSubscription = () => {
        if(!state.categorySelected) {
            setState(state => ({ ...state, partnerCategorySelectErrormessage: 'Veuillez selectionner une categorie' }));
            return;
        }

        setState(state => ({ ...state, isPartnerCodeSearching: true, partnerCodeSearchErrormessage: '', partnerCategorySelectErrormessage: '' }));
        const options = {
            path: {
                partnerCode: state?.partnerCode
            }
        }
        EntitiesService.searchEntitie(options, (data) => {

            const _avenant =  Avenant.fromJson({
                ref: "f251f0b4-5b20-478d-84f0-288b661b9177",
                code: 0,
                title: "Création",
                description: "Création",
                status: true,
                isDefault: true
            });

            dispatch(sUpdateState([
                {path: ["avenant"], value: _avenant},
                {path: ["category"], value: state.categorySelected},
                {path: ["partnerCode"], value: state?.partnerCode ? state?.partnerCode : user?.entities?.code}
            ]))

            dispatch(basketRaz());

            let subscriptionHeaderContext = SubscriptionHeaderContext.fromJson({
                subscriptionWidget: SubscriptionWidget.SUBSCRIPTION,
                avenant: _avenant,
                category: state.categorySelected,
                partnerCode: state?.partnerCode ? state?.partnerCode : user?.entities?.code
            })
            setState(state => ({ ...state, modalCodePartnerVisibility: false, isPartnerCodeSearching: false }))
            if(handleNouvelAvenant) {
                console.log(":::: handleNouvelAvenant")
                handleNouvelAvenant(subscriptionHeaderContext);
            }
            else {
                NavigationPush(()=> {
                    console.log("subscriptionHeaderContext :", subscriptionHeaderContext)
                    history.push({
                        pathname: '/souscription/' + state.categorySelected?.code,
                        state: subscriptionHeaderContext
                    })
                })
            }
                
        }, (error) => {
            setState(state => ({ ...state, isPartnerCodeSearching: false, partnerCodeSearchErrormessage: error }));
        })
    }

    const onNewSubscription=()=> {
        const _avenant=  Avenant.fromJson({
            ref: "f251f0b4-5b20-478d-84f0-288b661b9177",
            code: 0,
            title: "Création",
            description: "Création",
            status: true,
            isDefault: true
        });

        dispatch(sUpdateState([
            {path: ["avenant"], value: _avenant},
            {path: ["category"], value: state.categorySelected},

            {path: ["partnerCode"], value: state?.partnerCode ? state?.partnerCode : user?.entities?.code},
            {path: ["isProject"], value: false},
            {path: ["projectCode"], value: null},
        ]))

        dispatch(basketRaz());

        let subscriptionHeaderContext = SubscriptionHeaderContext.fromJson({
            subscriptionWidget: SubscriptionWidget.SUBSCRIPTION,
            avenant: _avenant,
            category: state.categorySelected,
            partnerCode: state?.partnerCode ? state?.partnerCode : user?.entities?.code
        })

        if(handleNouvelAvenant) {
            handleNouvelAvenant(subscriptionHeaderContext);
        }
        else {
            NavigationPush(()=> {
                console.log("subscriptionHeaderContext :", subscriptionHeaderContext)
                history.push({
                    pathname: '/souscription/' + state.categorySelected?.code,
                    state: subscriptionHeaderContext
                })
            })
        } 
    }

    const onNewSubscriptionByLoadingCategories=()=> {
        setState(state=> ({...state, isCategorieLoading: true}));
        (new CategoryService()).readAllCategory({},(data)=> {
            const _categories = (data || []).sort((a,b) => a.pos - b.pos);
            setState(state=> ({...state, categories: _categories, isCategorieLoading: false, modalNewSubVisibility: true}));
        }, (error)=> {
            setState(state=> ({...state, isCategorieLoading: false}))
        })
    }

    const onPartnerModalOpen=()=> {
        setState(state=> ({...state, isCategorieLoading: true}));
        (new CategoryService()).readAllCategory({},(data)=> {
            const _categories = (data || []).sort((a,b) => a.pos - b.pos);
            setState(state=> ({...state, categories: _categories, isCategorieLoading: false, modalCodePartnerVisibility: true}));
        }, (error)=> {
            setState(state=> ({...state, isCategorieLoading: false}))
        })
    }

    const onCategoryChange = (value)=> {
        const categorySelected = state.categories.find((category)=> category.code === value)
        if(categorySelected) {
            setState(state=> ({...state, categorySelected: categorySelected}))
        }    
    }

    return (
        <React.Fragment>
            <Row className="mb-3 pb-1">
                <Col xs={12}>
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                        <div className="flex-grow-1">
                            <div>
                                {/* <Radio.Group
                                    options={options}
                                    onChange={onSwitchChange}
                                    value={switchValue}
                                    optionType="button"
                                    buttonStyle="solid"
                                /> */}
                                <Segmented 
                                    options={options} 
                                    onChange={onSegmentSwitchChange}
                                    value={switchValue}
                                    size="large"/>
                            </div>
                        </div>
                        
                        {CAN_PRODUCT_FOR_OTHER.includes(user.profile) && (
                                <div className="mt-3 mt-lg-0" style={{ marginRight: 3 }}>
                                    <form action="#">
                                        <Row className="g-3 mb-0 align-items-center">

                                            <div className="col-auto">
                                                <Button color="primary" className="btn-label right" onClick={() => onPartnerModalOpen()}> <i className=" bx bx-plus label-icon align-middle fs-16 ms-2"></i> Affaire nouvelle pour un intermediaire</Button>
                                            </div>
                                        </Row>
                                    </form>
                                </div>
                            )}
                        
                        <div className="mt-3 mt-lg-0">
                            <form action="#">
                                <Row className="g-3 mb-0 align-items-center">

                                    <div className="col-auto">
                                        <Button color="primary" className="btn-label right" onClick={()=> category ? onNewSubscription() : onNewSubscriptionByLoadingCategories()}> <i className=" bx bx-plus label-icon align-middle fs-16 ms-2"></i> Affaire nouvelle</Button>
                                    </div>
                                </Row>
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>

            <Modal
                title="Code intermediaire"
                open={state.modalCodePartnerVisibility}
                centered
                onOk={onOtherPartnerSubscription}
                confirmLoading={state.isPartnerCodeSearching}
                onCancel={() => setState(state => ({ ...state, modalCodePartnerVisibility: false }))}>
                <Space direction="vertical" style={{width: "100%"}}>
                    <div >
                        <Input
                            size="large"
                            placeholder="Saisir le code intermediaire"
                            onChange={onPartnerCodeInputChange}
                            /* prefix={"R"}
                            
                            type='number' */
                            style={{ textTransform: "uppercase" }}
                        />
                        <span style={{ color: "red" }}>{state.partnerCodeSearchErrormessage}</span>
                    </div>

                    {!category && (<div>
                            <Select style={{ width: '100%' }} placeholder='Selectionner une catégorie' onChange={onCategoryChange}>
                                {(state.categories || []).map((categorie) => <Option value={categorie?.code} key={categorie?.code}> {categorie?.code} : {categorie?.title}</Option>)}
                            </Select>
                            <span style={{ color: "red" }}>{state.partnerCategorySelectErrormessage}</span>
                        </div>)
                    }
                    

                </Space>
                
            </Modal>

            <Modal
                title="Veillez choisir la categorie"
                open={state.modalNewSubVisibility}
                centered
                onOk={() => onNewSubscription()}
                
                onCancel={() => setState(prevState => ({ ...prevState, modalNewSubVisibility: false }))}>
                <Select style={{ width: '100%' }} placeholder='Selectionner une catégorie' onChange={onCategoryChange}>
                    {(state.categories || []).map((categorie) => <Option value={categorie?.code} key={categorie?.code}> {categorie?.code} : {categorie?.title}</Option>)}
                </Select>
            </Modal>
        </React.Fragment>
    )
}

export default SubscriptionHeader;