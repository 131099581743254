import { PRODUCT_AUTO_REF } from "constantes";

export default class InitAvenantRequest {
    producRef = PRODUCT_AUTO_REF;
    contractRef;
    avenantCode;
    assureCode;
    periodRef;
    subscriptionRequest;
    fSubscriptionRequest

    static fromJson = (json={})=> {
        var initAvenantRequest = new InitAvenantRequest();
        initAvenantRequest = json;
        return initAvenantRequest;
    }
}