import FStepAssureDetails from "../../common/steps/FStepAssureDetails";
import useInitFlotteAvenant from "../useInitFlotteAvenant";
import React from 'react';
import { FStepThankYou } from "./steps";
import WizardHeader from "modules/components/WizardHeader";
import { Card, Spin } from "antd";
import ErrorWidget from "components/Widgets/ErrorWidget";
import { useSelector } from "react-redux";
import { useBasket } from "views/pages/subscription/hooks/useBasket";

const FAvenantAnnulation= ()=> {
    const {contract, category, partnerCode} = useSelector((state) => state.subscription);

    const {
        currentStep,
        baskets, 
        page,
        fSubscriptionRequest, 
        quotationResponse, 
        transactionResponse,
        initAvenantResponse
    } = useSelector((state) => state.basketSlice);
    const {updateGroup} = useBasket()
    const { state } = useInitFlotteAvenant();

    if(state.avenantStatus?.loading) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if(state.avenantStatus?.error) {
        return <ErrorWidget errorMessage={state.avenantStatus?.errorMessage} />;
    }

    function renderChildren() {
        
        switch (state.currentStep) {
            case 0:
                return <FStepAssureDetails 
                        goToNextStep={null}  
                        goToPreviousStep={null} 
                        data = {state} 
                        />;
             case 1:
                return <FStepThankYou data = {state}  />;

                default:
        }
    }

    return (
        <React.Fragment>
            <WizardHeader
                title={`Categorie ${category?.code} : ${category?.title}`}
                avenantTilte={`Avenant d'annulation`}
                partnerCode={partnerCode}
                police={contract?.policeNumber}
                percent={currentStep * 50}
                />
            {renderChildren()}
        </React.Fragment>
    )
}

export default FAvenantAnnulation;