import { EditOutlined, ExclamationCircleOutlined, FileAddOutlined, PlusOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Modal, Row, Table, Switch, Menu, Dropdown, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import CreateBrandRequest from "api/request/createBrandRequest";
import { toast } from "react-toastify";
import ActionType from "api/acionType";
import BrandRegisterForm from "forms/brandRegisterForm";
import BrandEditForm from "forms/brandEditForm";
import { Card } from "reactstrap";
import BrandService from "services/brandService";
import ModelService from "services/modelService";
import EditBrandRequest from "api/request/editBrandRequest";

export default function Brand(props) {

    const [form] = Form.useForm();
    const [drawerVisibility, setDrawerVisibility] = useState({
        visibility: false,
        actionType: ActionType.REGISTER
    });

    const [modelDrawerVisibility, setModelDrawerVisibility] = useState({
        visible: false
    });

    const [state, setState] = useState({
        marques: [],
        models: [],
        dataSource: [],
        isMarqueLoading: true,
        isRegistingOrEditing: false,

        loading: false,
        updateLine: {},
        isLoad: false,
        itemId: null,

        formRegisterBrand: new BrandRegisterForm(),
        formEditBrand : new BrandEditForm()
    });

    useEffect(() => {
        getAllBrands();
        getAllModels();
    }, [])

    

    const getAllBrands = () => {
        setState(state => ({ ...state, isMarqueLoading: true }));
        BrandService.getAll({}, (data) => {
            setState(state => ({ ...state, marques: data, dataSource: data, isMarqueLoading: false, isMarqueTableUpdateContent: false }));
        }, (error) => { 
            setState(state => ({ ...state, isMarqueLoading: false }));
        });

    }

    const getAllModels = () => {

        ModelService.readAllModels((data) => {
            setState(state => ({ ...state, models: data || [] }));
        }, (error) => { });

    }

    const onDrawerOpen = (Brand, actionType) => {
        if (Brand) {
            const formEditBrand = state.formEditBrand.fromJson({
                id: Brand?.id,
                code: Brand?.code,
                title: Brand?.title,
                description: Brand?.description,
            });
            setState(state=> ({...state, formEditBrand: formEditBrand}))
            form.setFieldsValue({ ...form.getFieldsValue(), ...formEditBrand });
        }
        else {
            form.setFieldsValue({ ...form.getFieldsValue(), ...state.formRegisterBrand });
        }

        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: true, actionType: actionType }));
    }

    const onModelDrawerOpen = () => {
        setModelDrawerVisibility({ visible: true })
    }

    // TODO-OK: Ajout et edition d'une co-assurance
    const onRegisterOrEditBrandSubmit = (action) => {
        
        form.validateFields().then((fields) => {
            setState(state => ({ ...state, isRegistingOrEditing: true }));
            switch (action) {
                case ActionType.REGISTER:
                    const _formRegisterFields = {...state.formRegisterBrand, ...fields}
                    let createMarqueRequest = CreateBrandRequest.fromJson(_formRegisterFields);

                    BrandService.createBrand(createMarqueRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, formRegisterBrand: new BrandRegisterForm(), isMarqueTableUpdateContent: true }));
                        getAllBrands();
                        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                        toast.success("Marque ajoutée avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        console.log("error", error);
                        toast.error(error);
                    });
                    break;

                case ActionType.EDIT:
                    const formEditFields = { ...state.formEditBrand, ...fields };
                    let editMarqueRequest = EditBrandRequest.fromJson(formEditFields);

                    new BrandService().editBrand(editMarqueRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, formEditBrand : new BrandEditForm(), isUserTableUpdateContent: true }));
                        getAllBrands();
                        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                        toast.success("Marque modifiée avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        toast.error(error);
                    });
                    break;

                default:
                    setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                    setState(state => ({ ...state, isRegistingOrEditing: false }));
                    toast.error("Une erreur est survenue, veuillez réesayer");
                    break;
            }
        }).catch((error) => {
            toast.error("Marque non enregistrée");
            setState(state => ({ ...state, isRegistingOrEditing: false }));
        })
        
    }

    const onSwitchChange=(brand)=> {
        const option = {path:{id: brand?.id}}
        BrandService.toggleStatus(option, (data)=> {
            toast.success("La marque modifiée avec succès", { style: { background: 'primary' } });
            getAllBrands();
        }, (error)=> {
            toast.error("Une erreur est survenue");
        })
    }

    //TODO-OK: <Recherche d'un co assureur>
    // function onSearchCoInsurance(e) {
    //     const val = e?.target?.value?.toLowerCase();
    //     const temp = state.coInsurances?.filter((d)=> {
    //         return d?.code?.toLowerCase().indexOf(val) !== -1 ||
    //         d?.title?.toLowerCase().indexOf(val) !== -1 ||
    //         !val
    //     });
    //     setState(state => ({...state, dataSource: temp}))
    // }

    const columns = [
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            filters: state.dataSource.map((item)=> ({
                text: item?.code,
                value: item?.code,
              })),
            onFilter: (value, record) => record?.code?.startsWith(value),
            filterSearch: true,
            render: (code) => (
                <div style={{ fontWeight: 'bold' }}>
                    {code}
                </div>
            )
        },
        {
            title: "Titre",
            dataIndex: "title",
            key: "title",
            filters: state.dataSource.map((item)=> ({
                text: item?.title,
                value: item?.title,
              })),
            onFilter: (value, record) => record?.title?.startsWith(value),
            filterSearch: true,
        },
        {
            title: "Etat",
            dataIndex: "status",
            key: "status",
            render: (item, data) => (
                <div>
                    <Switch defaultChecked={data.status} size="small" onChange={()=> onSwitchChange(data)}/>
                </div>
            )
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 100,
            key: "action",
            render: (item, marque) => (
                <>
                    <EditOutlined style={{ color: '#40E070', cursor: 'pointer', marginRight: 50 }} onClick={() => onDrawerOpen(marque, ActionType.EDIT)} />
                    <Dropdown overlay={(
                        <Menu
                            items={[
                                {
                                    key: '1',
                                    label: <div style={{ color: '#000', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onModelDrawerOpen()}>
                                        <PlusOutlined style={{ color: '#000', marginRight: 5 }} /> Ajouter un modèle
                                    </div>
                                },
                                // {
                                //     key: '2',
                                //     label: <div style={{ color: '#000', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => console.log("Merci")}>
                                //         <PlusOutlined style={{ color: '#000', marginRight: 5 }} /> Ajouter un package
                                //     </div>
                                // },
                                // {
                                //     key: '3',
                                //     label: <div style={{ color: '#000', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => console.log("Merci")}>
                                //         <PlusOutlined style={{ color: '#000', marginRight: 5 }} /> Ajouter un package
                                //     </div>
                                // },
                            ]}
                        />
                    )}>
                        <MoreOutlined style={{ color: 'orange', fontSize: 18 }} />
                    </Dropdown>
                </>

            )
        }
    ];

    return (
        <Card>
            <div style={{ padding: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Liste des marques</h4>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        {/* <Input placeholder="Recherche: Code, titre" style={{ width: 350, marginRight: 10 }} onChange={onSearchCoInsurance} /> */}
                        <Input placeholder="Recherche: Marque" style={{ width: 350, marginRight: 10 }} />
                        <Button type="primary" onClick={() => onDrawerOpen(null, ActionType.REGISTER)} style={{ marginLeft: 10 }}>
                            <FileAddOutlined />
                            Ajouter une marque
                        </Button>
                    </div>
                </div>
                <br />
                <Table
                    dataSource={state.dataSource}
                    columns={columns}
                    loading={state.isMarqueLoading}
                />

                {drawerVisibility.visibility && (
                    <Drawer
                        title={drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrement d'une marque" : "Modifier la marque"}
                        width={720}
                        onClose={() => { setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false })) }}
                        visible={drawerVisibility.visibility}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                    >
                        <Form
                            name="basic"
                            onFinish={() => onRegisterOrEditBrandSubmit(drawerVisibility.actionType)}
                            layout="vertical"
                            form={form}
                            initialValues={drawerVisibility.actionType === ActionType.REGISTER ? state?.formRegisterBrand : state.formEditBrand}
                        >

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Code de la marque"
                                        name="code"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Le code de la marque svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Le code de la marque" size="middle" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Nom de la marque"
                                        name="title"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Le nom de la marque svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Le nom de la marque" size="middle" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Description de la marque"
                                        name="description"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'La description svp !',
                                            },
                                        ]}
                                    >
                                        <TextArea placeholder='Description de la marque' size="middle" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e8e8e8',
                                    padding: '10px 16px',
                                    textAlign: 'right',
                                    left: 0,
                                    background: '#fff',
                                    borderRadius: '0 0 4px 4px',
                                }}
                            >
                                <Button
                                    style={{
                                        marginRight: 8,
                                    }}
                                    onClick={() => { setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false })); }}
                                >
                                    Annuler
                                </Button>
                                <Button type="primary" htmlType="submit" loading={state.isRegistingOrEditing}>
                                    {drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrer" : "Editer"}
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                )}

                {modelDrawerVisibility.visible && (
                    <Drawer
                        title="Choisissez les modèles"
                        width={500}
                        onClose={() => setModelDrawerVisibility({ visible: false })}
                        visible={modelDrawerVisibility.visible}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                        extra={<Input placeholder="Rechercher" style={{ width: 200, marginRight: 5 }} suffix={<SearchOutlined />} />}
                    >
                        <Form
                            name="basic"
                            layout="horizontal"
                        >

                            {
                                state.models.map((model, index) => (
                                    <Form.Item >
                                        <Checkbox>{model.title}</Checkbox>
                                    </Form.Item>
                                ))
                            }

                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e8e8e8',
                                    padding: '10px 16px',
                                    textAlign: 'right',
                                    left: 0,
                                    background: '#fff',
                                    borderRadius: '0 0 4px 4px',
                                }}
                            >
                                <Button
                                    style={{
                                        marginRight: 8,
                                    }}
                                    onClick={() => { setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false })); }}
                                >
                                    Annuler
                                </Button>
                                <Button type="primary" htmlType="submit" loading={state.loading}>
                                    {drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrer" : "Editer"}
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                )}

                {/**********DELETE ALERTE ****** */}
                {false && (
                    <Modal
                        title="Supprimer une marque !!!"
                        visible={false}
                        onOk={() => { }}
                        onCancel={() => { }}
                        okText="Confirmer"
                        cancelText="Annuler"
                    >
                        <h3 style={{ color: 'orange' }}> <ExclamationCircleOutlined />  Voulez-vous supprimer vraiment cette marque ?</h3>
                    </Modal>
                )}
            </div>
        </Card>
    )
}