
export default class ApiResponse {
    error = false;
    statusCode = "";
    statusMessage = "";
    data = [];
    fromJson = (json={})=> {
        var apiResponse = new ApiResponse();
        apiResponse = json;
        return apiResponse
    }
}