import { Form, Select } from "antd"
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter"
import React,  { useState } from "react"
import { useEffect } from "react"
import SocialStatusService from "services/socialStatusService"

const SocialStatusField=({socialStatus})=> {
    const Option = Select;
    const [state, setState]=useState({
        socialStatus: socialStatus ?? [],
        isSocialStatusLoading: false
    })

    useEffect(()=> {
        if(!socialStatus) {
            getSocialStatus()
        }
        
    }, [])

    const getSocialStatus=()=> {
        setState(state=> ({...state, isSocialStatusLoading: true}))
        SocialStatusService.getAll({}, (data)=> {
            const _socialStatus = data || [];
            setState(state=> ({...state, socialStatus: _socialStatus, isSocialStatusLoading: false}))
        }, (error)=> {
            setState(state=> ({...state, isSocialStatusLoading: false}))
        })
    }

    return (
        <React.Fragment>
            <Form.Item
                label="Status social."
                name="socialStatusRef"
                rules={[{ required: false, message: "Champ obligatoire" }]}
            >
                <SelectWithFilter size='medium' placeholder="Selectionner" addonBefore={"Status social :"}>
                    {state.socialStatus.map((socialStatus, index)=> (<Option value={socialStatus?.ref} key={index}>{socialStatus?.title}</Option>))}
                </SelectWithFilter>
            </Form.Item>
        </React.Fragment>
    )
}

export default SocialStatusField;