import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Checkbox, Collapse, Divider, Radio, Space, Spin, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import GuaranteeService from "services/guaranteeService";

const info = (title, description) => (
    <div>
        <div style={{ borderBottom: '1px solid white', marginBottom: 10 }}>
            <h4 style={{ color: 'white' }}>{title}</h4>
        </div>
        <p>
            {description}
        </p>
    </div>
)

const SettingGuarantee=({ category, pack, /* guaranties, */ setCustomGuarantiesCollect })=> {
    const { Panel } = Collapse;
    const [customGuaranties, setCustomGuaranties]=useState((pack?.customGuaranties || []).map((customGuarantee)=> ({...customGuarantee, selected: true})))

    const [state, setState]= useState({
        guaranties: [],
        isGuarantiesLoading: true
    })

    useEffect(()=> {
        console.log(":::: customGuaranties ", pack?.customGuaranties)
        if(pack?.packRef && category?.ref && _.size(state.guaranties) <=0) {
            const options = {
                query: {
                    categoryRef: category?.ref,
                    packRef: pack?.packRef
                }
            }
            GuaranteeService.getCategoryAndPack(options, (data)=> {
                setState(state=> ({...state, guaranties: (data ||[]).sort(((a,b)=> a?.guaranteeOrder - b?.guaranteeOrder)), isGuarantiesLoading: false}))
            }, (error)=> {
                setState(state=> ({...state, isGuarantiesLoading: false}))
            })
        }
    }, [pack])

    useEffect(()=> {
        console.log("::::: ==> SettingGuarantee - customGuaranties : ", customGuaranties)
        setCustomGuarantiesCollect(customGuaranties)
    }, [customGuaranties])

    const onChange = (key) => console.log("::: Colapse handler : ", key);

    const getDefaultEngemement=(guarantee)=> {
        const customGuarantee = (customGuaranties || []).find((customerGuarantee)=> customerGuarantee?.guaranteeRef === guarantee?.ref );
        console.log("::: getDefaultEngemement : ", customGuarantee)
        console.log("::: guarantee?.defaultEngagementRef : ", guarantee?.defaultEngagementRef)
        return customGuarantee !== null && customGuarantee !== undefined   ? customGuarantee?.guaranteeRef : guarantee?.defaultEngagementRef
    }

    const getDefaultFranchise=(guarantee)=> {
        const customGuarantee = (customGuaranties || []).find((customerGuarantee)=> customerGuarantee?.guaranteeRef === guarantee?.ref );
        return customGuarantee ? customGuarantee?.franchise : true
    }

    //<Lorsque qu'on change l'option d'engagement pour une garantie>
    const _onEngagementOptionChange = (guaranteeRef, engagementRef) => {
        let _customGuaranties = [];
        const index = customGuaranties.findIndex((customerGuarantee)=> customerGuarantee?.guaranteeRef === guaranteeRef)
        if(index !== -1) {
            customGuaranties[index] = Object.assign({}, {...customGuaranties[index]}, {engagementRef: engagementRef})
            console.log("::: _customGuaranties[index] :", customGuaranties[index])
            _customGuaranties = [...customGuaranties]
        }
        else {
            const _guarantee = state?.guaranties.find((guarantee)=> guarantee?.ref === guaranteeRef)
            _customGuaranties = [...customGuaranties, {
                guaranteeRef: guaranteeRef,
                engagementRef: engagementRef,
                franchise: _guarantee?.franchise,
                selected: _guarantee?.required
            }]
        }
        
        setCustomGuaranties(_customGuaranties);
        
    };

    //<Event changement d'option de franchise>
    const onFranchiseChange= (guaranteeRef, e)=> {
        const checked = e.target?.value;
        let _customGuaranties = [];
        const index = customGuaranties.findIndex((customerGuarantee)=> customerGuarantee?.guaranteeRef === guaranteeRef)
        if(index !== -1) {
            customGuaranties[index] = Object.assign({}, {...customGuaranties[index]}, {franchise: checked})
            console.log("::: _customGuaranties[index] :", customGuaranties[index])
            _customGuaranties = [...customGuaranties]
        }
        else {
            const _guarantee = state?.guaranties.find((guarantee)=> guarantee?.ref === guaranteeRef)
            
            _customGuaranties = [...customGuaranties, {
                guaranteeRef: guaranteeRef,
                engagementRef: _guarantee?.defaultEngagementRef,
                franchise: checked,
                selected: _guarantee?.required
            }]
        }
        
        setCustomGuaranties(_customGuaranties);
    }

    const onPackGuaranteeSelected=(guaranteeRef,e)=> {
        const checked = e.target?.checked;
        let _customGuaranties = [];
        const index = customGuaranties.findIndex((customerGuarantee)=> customerGuarantee?.guaranteeRef === guaranteeRef)
        if(index !== -1) {
            customGuaranties[index] = Object.assign({}, {...customGuaranties[index]}, {selected: checked})
            _customGuaranties = [...customGuaranties]
        }
        else {
            const _guarantee = state?.guaranties.find((guarantee)=> guarantee?.ref === guaranteeRef)
            
            _customGuaranties = [...customGuaranties, {
                guaranteeRef: guaranteeRef,
                engagementRef: _guarantee?.defaultEngagementRef,
                franchise: true,
                selected: checked
            }]
        }
        setCustomGuaranties(_customGuaranties);
    }

    const collapsePanelHeaderWidget= (guarantee, i, style={color: '#9EA1A3', fontWeight: "normal"})=> {
        return  (<div>
             <div style={{ display: 'flex', justifyContent: 'space-between' }} key={i}>
                 <div style={{ display: 'flex' }}>
                    {
                        guarantee?.required ? 
                        (<Checkbox checked style={style}>{guarantee?.title}</Checkbox>) : 
                        (<Checkbox checked={customGuaranties.findIndex((customGuarantee)=> (customGuarantee?.guaranteeRef === guarantee?.ref) && customGuarantee?.selected === true) !== -1} style={style} onChange={(e)=> onPackGuaranteeSelected(guarantee?.ref,e)}>{guarantee?.title}</Checkbox>)}
                 </div>
             </div>
             <div style={{fontSize: 9, marginLeft:25, color: '#9EA1A3', display: 'flex', justifyContent: 'space-between'}} >
                 {/* <div style={{ display: 'flex' }}> {guarantee.engagements.find((engagement)=> engagement.onDefault === true )?.title}</div> */}
                 <div style={{ display: 'flex' }}>{guarantee.applyFranchise && guarantee.franchise ? 'Avec franchise' : (guarantee.applyFranchise && !guarantee.franchise ? 'Sans franchise': '') }</div>
             </div>
        </div>);
    }

    if(state?.isGuarantiesLoading) {
        return <Card><Spin /> Chargement des garanties en cours ...</Card>
    }

    return (
        <React.Fragment>
            <Collapse bordered={false} onChange={onChange}>
                {
                    (state?.guaranties || []).map((guarantee, i)=> {
                        return (
                            <Panel showArrow={false} header={collapsePanelHeaderWidget(guarantee, i, state?.guaranties.findIndex((guaranteeOption)=> guaranteeOption?.guaranteeRef === guarantee?.ref ) !== -1 ? {color: '#9EA1A3', fontWeight: "bold"} : { fontWeight: "normal"})} key={i}>
                                <div>
                                    {
                                        guarantee?.defaultEngagementRef === null || guarantee?.defaultEngagementRef === undefined ? (
                                            <h6>Aucune option prévue pour cette garantie</h6>
                                        ) : (
                                            <div>
                                                <Divider orientation="left">Engagements</Divider>
                                                <Radio.Group name="radiogroup" onChange={(e)=> _onEngagementOptionChange(guarantee?.ref, e.target.value)} /* value={getDefaultEngemement(guarantee)} */   defaultValue= {getDefaultEngemement(guarantee) } >
                                                    <Space direction="vertical" style={{marginLeft: 45}}>
                                                        {(guarantee?.engagements || []).map((engagement, index) => {
                                                            return (
                                                                <div style={{ display: 'flex', justifyContent: 'space-between' }} key={index}>
                                                                    <Radio  key={index} value={engagement?.ref}  style={{ marginBottom: 10 }} disabled={(customGuaranties || []).findIndex((customGuarantee)=> customGuarantee.engagementRef === engagement.ref) !== -1}>{engagement.description}</Radio>
                                                                    {/* <div>
                                                                        <Radio checked  key={index} value={engagement?.ref}  style={{ marginBottom: 10 }} disabled={(guarantiesOptions || []).findIndex((guaranteeOption)=> guaranteeOption.engagementRef === engagement.ref) !== -1}>{engagement.description}</Radio>
                                                                    </div> */}
                                                                    <Tooltip placement="right" title={info("Description", engagement.title)}>
                                                                        <InfoCircleOutlined style={{ color: 'blue', fontSize: 15, cursor: 'pointer' }} />
                                                                    </Tooltip>
                                                                </div>
                                                            )
                                                        })}
                                                    </Space>
                                                </Radio.Group>
                                            </div>
                                        )
                                    }
                                    {
                                        guarantee.applyFranchise ? (
                                            <div>
                                                <Divider orientation="left">franchise</Divider>
                                                <Radio.Group name="radiogroup" onChange={(e)=> onFranchiseChange(guarantee?.ref, e)} defaultValue={getDefaultFranchise(guarantee)} style={{marginLeft: 45}}>
                                                    <Radio key={1} value={true}>Avec franchise</Radio>
                                                    <Radio key={2} value={false}>Sans franchise</Radio>
                                                </Radio.Group>
                                            </div>
                                        ) : (<></>)
                                    }
                                </div>
                            </Panel>
                        )
                    })
                }
            </Collapse>
        </React.Fragment>
    )
}

export default SettingGuarantee;