import RouteModule from 'routes.module';

//=================================
//===== ALL APP FONCTIONALITY =====
//=================================
export const appModules = [
    // Catgories
    RouteModule.souscription400,
    RouteModule.souscription401,
    RouteModule.souscription402,
    RouteModule.souscription403,
    RouteModule.souscription404,
    RouteModule.souscription405,
    RouteModule.souscription406,
    RouteModule.souscription407,
    RouteModule.souscription408,
    RouteModule.souscription409,
    RouteModule.souscription410,
    // Details souscription
    RouteModule.detailSouscription,

    // Grouppement 
    RouteModule.listGroupement,

    RouteModule.detailSoubscriptionAssure,
    RouteModule.detailCommission,
    RouteModule.detailParametre,
    RouteModule.listContractCoInsurance,
    RouteModule.listCommisions,
    RouteModule.reporting,
    RouteModule.reportingCommission,
    RouteModule.reportingExpirations,

    // SETTINGS
    RouteModule.settingAgency,
    RouteModule.settingCoInsurance,
    //RouteModule.settingCommission,
    //RouteModule.settingCoupons,
    RouteModule.settingGuaranties,
    RouteModule.settingGroupement,
    RouteModule.settingBrands,
    RouteModule.settingModele,
    RouteModule.settingPackages,
    //RouteModule.settingPackageGuaranties,
    RouteModule.settingPartners,
    //RouteModule.settingProducts,
    RouteModule.settingReduction,
    //RouteModule.settingReductions,
    RouteModule.settingUsers,
    RouteModule.settingAttestationGesture
];



