import { EyeOutlined } from "@ant-design/icons";
import { Spin, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";


export default function SouscriptionResilie(props) {
    const { souscription, isLoad } = props
    function thousandSeparator(x) {
        if (x == null || x == undefined) {
            return 0;
        }
        else {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    }

    return (
        <div>
            <h4 style={{ color: 'grey', paddingLeft: 5 }}>Produits auto</h4>
            <table width={'100%'}>
                <tr style={{ borderCollapse: 'collapse', border: '1px solid white', height: 40 }}>
                    <th style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5 }}>Police</th>
                    <th style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5 }}>Assuré</th>
                    <th style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5 }}>Date d'effet</th>
                    <th style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5 }}>Date d'echéance</th>
                    <th style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5 }}>Montant</th>
                    <th style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5 }}>Origine</th>
                    <th style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5 }}>Actions</th>
                </tr>
                {isLoad ? (
                    <>
                        {
                            souscription.length > 0 ? (
                                souscription.map(function (item, index) {
                                    return (
                                        <tr style={{ borderCollapse: 'collapse', border: '1px solid white', height: 40 }} key={index}>
                                            <td style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5, color: 'grey' }}>{item?.numPolice}</td>
                                            <td style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5, color: 'grey' }}>{item?.assure}</td>
                                            <td style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5, color: 'grey' }}>{item.dateEffet}</td>
                                            <td style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5, color: 'grey' }}>{item?.endDate}</td>
                                            <td style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5, color: 'grey' }}><Tag color='green'>{thousandSeparator(item?.montant) + ' FCFA'}</Tag></td>
                                            <td style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5, color: 'grey' }}>{item?.origin}</td>
                                            <td style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5, color: 'grey' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    <div style={{ color: '#3573DF', cursor: 'pointer' }}>
                                                        <Link to={`/souscription/${item.id}/details/${item.assureCode} / ${item.contratId} / ${item.categorie}`}>
                                                            <EyeOutlined style={{ color: '#3573DF' }} />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr style={{ borderCollapse: 'collapse', border: '1px solid white', height: 40 }}>
                                    <td style={{ borderCollapse: 'collapse', border: '1px solid #DEDDDD', paddingLeft: 5 }} colSpan={7}>
                                        <h5 style={{ textAlign: 'center' }}>Aucune données disponible !</h5>
                                    </td>
                                </tr>
                            )
                        }
                    </>
                ) : (
                    <div style={{ display: 'flex' }}>
                        <Spin />
                        <p style={{ marginLeft: 10, color: '#3573DF' }}>Connexion au server veuillez patienter s'il vous plait ...</p>
                    </div>
                )}
            </table>
            <br />
        </div>

    )
}