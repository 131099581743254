import React from "react";
import { convert } from "helpers/helpers";
import ReturnNewLine from "views/pages/common/ReturnNewLine";
import ReturnNewTitle from "views/pages/common/ReturnNewTitle";

const FDetailsContract=({flotteSubscriptionRequest, period})=> {
    return (
        <React.Fragment>
            {ReturnNewTitle('Détails sur le contrat')}
            {ReturnNewLine('Periode de couverture', period?.title, false)}
            {ReturnNewLine("Date d'effet", convert(flotteSubscriptionRequest?.dateEffect), true)}
            {ReturnNewLine("Email de notification", flotteSubscriptionRequest?.assure?.email, true)}
        </React.Fragment>
    )
}

export default FDetailsContract;