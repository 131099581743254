import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CategoryService from "services/categoryService";
import { SubscriptionType, SubscriptionWidget } from "../component/ComponentWidget";

export default function useInitSubscription() {
    const location = useLocation();
    const pathParam = location.state;

    const [state, setState]= useState({
        pathParam           : pathParam,
        subscriptionWidget  : pathParam ? pathParam?.subscriptionWidget : SubscriptionWidget.LIST_SUBSCRIPTION,
        subscriptionType    : pathParam?.subscriptionType ? pathParam?.subscriptionType : SubscriptionType.NORMAL,
        
        category: null,
        isCategorieLoading  : true,
        categoryLoadingError: false,
        categoryLoadingErrorMessage: ""
    })

    useEffect(() => {
        // RECUPERER LA CATEGORIE
        if (location && location !== undefined) {
            const params = location.pathname.split("/");
            if (params.length > 0) {
                const categoryCode = params[params.length - 1];

                (new CategoryService()).getCategoryByCode(categoryCode, (data) => {
                    setState(state=> ({...state, category: data, isCategorieLoading: false}))

                }, (error) => {
                    setState(state=> ({
                        ...state, isCategorieLoading: false,
                        categoryLoadingError: true,
                        categoryLoadingErrorMessage: error
                     }))
                })
            }
        }
    }, [])

    const handleNouvelAvenant = (subscriptionHeaderContext) => {

        //console.log(":::: subscriptionHeaderContext :", subscriptionHeaderContext)
        setState(prevState => {
            return {
                ...prevState,
                //basket: subscriptionHeaderContext?.basket,
                subscriptionWidget: subscriptionHeaderContext?.subscriptionWidget,
                contractType: subscriptionHeaderContext.contractType,
                subscriptionType: subscriptionHeaderContext.subscriptionType,
            }
        })
    }

    return {
        state,
        handleNouvelAvenant
    }
}