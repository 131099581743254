import React, { useEffect } from "react";
import { Col, Form } from "antd";
import InputNumberFormatFr from 'modules/components/InputNumberFormatFr/InputNumberFormatFr';
import { CATEGORY_406 } from "constantes";

const FiscalPowerField=({categoryCode, disable, handlerFormField, form,  min, xs, md })=> {

    useEffect(()=> {
        if(handlerFormField && form && min) {
            form.setFieldsValue({fiscalPower: min})
            handlerFormField({
                fiscalPower: min
            })
        } 
    }, [min])

    if([CATEGORY_406].includes(categoryCode)){
        return null;
    }

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Puissance fiscale"
                    name="fiscalPower"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    <InputNumberFormatFr
                        min={min ?? 1}
                        max={1000}
                        style={{ width: '100%' }}
                        placeholder='Puissance fiscale'
                        disabled={disable}
                    />
                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default FiscalPowerField;