import React, { useState } from 'react'
import Payment from 'views/pages/subscription/component/payment/Payment';
import { ContractType } from 'views/pages/subscription/component/ComponentWidget';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';
import { Col, Container, Row } from 'reactstrap';
import { MDetailOthers, MDetailPackage, MDetailPeriod, MDetailPrime, MDetailStartDate } from '../../common/details';
import SubscriptionRequestBuilder from 'api/request/SubscriptionRequestBuilder';

const StepDetails = ({ 
    avenantCode,
    endDate,
    packSelected,
    subscriptionRequest
}) => {
    const {updateGroup} = useBasket()
    const [initStartDate, setInitStartDate] = useState({
        startDate: subscriptionRequest?.customerStartDate ?? subscriptionRequest?.dateEffect,
        endDate: endDate,
        initialDate: subscriptionRequest?.customerStartDate ?? subscriptionRequest?.dateEffect
    })

    const onStartDateChange = (newDateEffect) => {

        setInitStartDate({
            ...initStartDate,
            startDate: newDateEffect
        })

        updateGroup([
            { path: ['basket', 'subscriptionRequest', 'dateEffect'], value: newDateEffect }
        ])
    }

    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-xxl-9 order-xxl-0 order-first">
                        <Row>
                            <MDetailPackage packTitle={packSelected?.packTitle} />

                            <MDetailPeriod perioTitle={packSelected?.period?.title} />

                            <MDetailStartDate editable={true} rangeDate={initStartDate} onStartDateChange={onStartDateChange} />
                        </Row>
                        <MDetailOthers
                            vehicle={subscriptionRequest?.vehicle}
                            assure={subscriptionRequest?.assure}
                        />
                    </Col>
                    <MDetailPrime
                        primeTTC={packSelected?.primeTTC}
                        primeNette={packSelected?.primeNette}
                        extras={packSelected?.extras}
                    />
                </Row>
                <Row>
                    <Col className="col-xxl-12">
                        <Payment
                            backVisibility={false}
                            projectVisibility={false}
                            onlineVisiblity={true}
                            payload={SubscriptionRequestBuilder.fromThis(avenantCode, subscriptionRequest)}
                            type={ContractType.MONO}
                        />
                    </Col>
                </Row>
            </Container >
        </React.Fragment>
    )
}

export default StepDetails;