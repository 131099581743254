export default class Avenant {
    id;
    ref;
    code;
    title;
    description;
    status;
    isDefault = true

    static fromJson = (json = {}) => {
        var avenant = new Avenant();
        avenant = json
        return avenant
    }
}