import { toast } from 'react-toastify';
import { Button, notification } from "antd";
import React from "react"
import * as constants from 'constantes';
import store from 'redux/store'
import * as actionTypes from "redux/actionTypes";
import ResourceConfig, { buildRequestUrl } from '../api/routeManager';
import ApiResponse from 'api/response/apiResponse';
import { logout } from 'redux/slices/authSlice';
import HTTPStatus from 'api/httpMethod';

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) return undefined
        return JSON.parse(serializedState)
    } catch (e) {
        console.log(e)
        return undefined
    }
}

const close = () => {
    console.log(
        'Notification was closed. Either the close button was clicked or duration time elapsed.',
    );
};

function onClick(url) {
    //history.push(url);
    openNotification.close();
}

const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
        <Button type="primary" size="small" onClick={() => onClick("/deconnexion")}>
            Reconnexion
        </Button>
    );
    notification.open({
        message: <h4 style={{ color: 'red' }}>Votre session a expiré !!</h4>,
        description:
            <h4 style={{ color: 'orange' }}>Veuillez vous reconnecter svp.</h4>,
        btn,
        key,
        onClose: ()=> close(),
        duration: 5
    });
};

const request = (options) => {
    
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    const token = loadFromLocalStorage()?.auth?.accessToken
    if (token) {
        headers.append('Authorization', 'Bearer ' + token)
    }
    else {
        if(options?.authenticated) {
            store.dispatch(logout());
            return Promise.reject({});
        }
    }

    options = Object.assign({}, { headers }, options);

    return fetch(options.url, options)
        .then(response => { 
            if (!response.ok) {
                return Promise.reject(response);
            }

            return response.json().then(json => {
                return json;
            })
        });
}

async function requestAsync(options) {
    
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    const token = loadFromLocalStorage()?.auth?.accessToken
    if (token) {
        headers.append('Authorization', 'Bearer ' + token)
    }
    else {
        if(options?.authenticated) {
            store.dispatch(logout());
            return Promise.reject({});
        }
    }

    options = Object.assign({}, { headers }, options);

    const response = await fetch(options.url, options);
    return response
}

export async function exeRequest(resourceConfig = (new ResourceConfig({})), payload={}, callBackSuccess, callBackError, context) {
    console.log(resourceConfig)
    
    if(!resourceConfig?.urlSuffix || !resourceConfig.method) {
        toast.error("Desolé ! Une erreur reseau s'est produite . Veuillez ressayer ...!");
        return;
    }

    const URI = buildRequestUrl(resourceConfig.urlSuffix, resourceConfig.resourceOptions);
    let options = {
        url: `${constants.API_BASE_URL}${URI}`,
        method: resourceConfig.method,
        authenticated: resourceConfig.authenticated
    }
    
    if (payload && Object.keys(payload).length !== 0) {
        options = Object.assign({}, { body: JSON.stringify(payload) }, options);
    }
    
    request(options).then(response => {
        
        let apiResponse = (new ApiResponse()).fromJson(response);
        
        if(!apiResponse.error && 
            (apiResponse.statusCode === HTTPStatus.STATUS_CODE_SUCCESS || 
                apiResponse.statusCode === HTTPStatus.STATUS_GRPC_CODE_SUCCESS || 
                apiResponse.statusCode === HTTPStatus.STATUS_HTTP_CODE_SUCCESS)) {
            callBackSuccess(apiResponse.data)
        }
        else {
            console.log(":::: Erreur produite")
            //openNotification()
            callBackError && callBackError(apiResponse?.statusMessage);
        }

    }).catch(error => {
        console.log("error : ", error);
        error.message = "Desolé ! Une erreur reseau s'est produite . Veuillez ressayer ...!";
        if (error?.status === HTTPStatus.UNAUTHORIZED) {
            if (callBackError) callBackError(error);
            //openNotification();
            store.dispatch(logout());
        } else {
            toast.error(error.message);
            if (callBackError) callBackError(error);
        }
    });
}

export async function exeRequestAsync(resourceConfig = (new ResourceConfig({})), payload={}) {
    
    if(!resourceConfig?.urlSuffix || !resourceConfig.method) {
        toast.error("Desolé ! Une erreur reseau s'est produite . Veuillez ressayer ...!");
        return;
    }

    const URI = buildRequestUrl(resourceConfig.urlSuffix, resourceConfig.resourceOptions);
    let options = {
        url: `${constants.API_BASE_URL}${URI}`,
        method: resourceConfig.method,
        authenticated: resourceConfig.authenticated
    }
    
    if (payload && Object.keys(payload).length !== 0) {
        options = Object.assign({}, { body: JSON.stringify(payload) }, options);
    }
    
    return await requestAsync(options);
}

export function exePromiseAll(urls, method, data, callBackSuccess, callBackError, context) {
    let options = {}
    let promises = [];

    for (let url of urls) {
        options = {
            url: `${constants.API_BASE_URL}/${url}`,
            method: method
        }

        if (data && data !== {}) {
            options = Object.assign({}, { body: JSON.stringify(data) }, options);
        }

        promises = [...promises, request(options)]
    }

    Promise.all(promises).then(response => {
        if (["POST", "PUT", "DELETE"].includes(method)) {
            store.dispatch({
                type: actionTypes.SHOW_API_RESPONSE,
                data: response,
            });

            if (callBackError) callBackError(response);
        } else if (!response.status) {
            toast.error(response.message);
            if (callBackError) callBackError(response);
        }

        if (response.status) {
            callBackSuccess(response)
        }
    }).catch(error => {
        error.message = error.message || "Desolé ! Une erreur reseau s'est produite . Veuillez ressayer ...!"
        if (error.status === 401) {
            toast.error(error.message);
            store.dispatch({ type: actionTypes.LOG_OUT })
        } else {
            toast.error(error.message);
            if (callBackError) callBackError(error);
        }
    });
}