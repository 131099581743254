import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Card, Button, Spin, Menu, Dropdown, Space, Tag } from 'antd'
import { CarOutlined, FileDoneOutlined, LeftOutlined, SettingOutlined } from '@ant-design/icons';
import './styleStep2.css'
import { toast } from 'react-toastify';
import AssurancePackage from 'views/pages/common/AssurancePackage';
import {  useSelector } from 'react-redux';
import ErrorWidget from 'components/Widgets/ErrorWidget';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';
import _ from 'lodash';
import QuotationRequest from 'api/request/quotationRequest';
import QuotationService from 'services/quotationService';
import QuotationResponse from 'api/v1/response/QuotationResponse';
import AmountHeader from 'views/pages/subscription/common/AmounHeader';

const menu =(handlePacksMenuClick)=> (
    <Menu
        onClick={handlePacksMenuClick}
        items={[
            {
                label: (
                    <>Package à la carte</>
                ),
                key: '0',
            },

            {
                type: 'divider',
            },
            {
                label: 'Pack avantage',
                key: '2',
                disabled: true,
            },
            {
                label: 'Pack évolutive',
                key: '3',
                disabled: true,
            },
            {
                label: 'Pack confort',
                key: '4',
                disabled: true,
            },
            {
                label: 'Pack liberté',
                key: '5',
                disabled: true,
            },
        ]}
    />
);

const StepPackages = ({basket}) => {
    const { contract, avenant } = useSelector((state) => state.subscription);
    const {goToNextStep, goToPreviousStep, quote, updateBasket, updateGroup} = useBasket();
    const [form] = Form.useForm();
    
    const [isPackCarteOn, setIsPackCarteOn] = useState(false);
    const [ packSelected, setPackSelected] = useState({...basket?.packSelected, loading: false})    
    const [state, setState] = useState({
        selectedPackageId: '',
        reductions: [],
        customerReductions: [], // basket?.quotationResponse?.reductionGroups ||
        isReductionEditionOpened: false,
        packSelected: {},
        errorGeneratePacks: false,
        errorMessageGeneratePacks: "",
        isPacksLoading: true,
        isPacksLoadingError: false,
        packsLoadingErrorMessage: ""
    });

    useEffect(() => { 

        // ===== Quote tous les packages à partir des inputs de step precedents
        const _quotationRequest = QuotationRequest.buildFromFormFields({
            formFields: basket?.formField, 
            contract: contract, 
            avenant: avenant, 
            packLineRef: null
        });

        QuotationService.quote({quotationRequests: [_quotationRequest], t: 'm'}, (result)=> {
            const _quotationResponse = QuotationResponse.fromJson(result);
            const _packSelected = (_quotationResponse?.packsResponse || []).find((packResponse)=> packResponse?.packLineRef === packSelected.packLineRef);
            const _basket = {
                ...basket, 
                quotationRequest: _quotationRequest, 
                packSelected: {
                    ...basket?.packSelected, 
                    ..._packSelected
                }, 
                quotationResponse: _quotationResponse
            }
            updateBasket(_basket);
            setState(state=> ({ ...state, isPacksLoading: false}))
            
            // Quote specifiquement le package selectionné
            if(packSelected?.selected) {
                console.log("::: Quote pack Selected")
                quotePackSelected(_quotationRequest)
            }

        }, (error)=> {
            setState(state=> ({...state, isPacksLoading: false, isPacksLoadingError:true,  packsLoadingErrorMessage: error}))
        })
        //============================================        
    }, []);

    const quotePackSelected=(_quotationRequest)=> {
        const __quotationRequest =  {
            ..._quotationRequest,
            customGuaranties: [...packSelected?.customGuaranties],
            reductionGroups: [...packSelected?.reductionGroups],
            packLineRef: packSelected?.packLineRef
        }

        QuotationService.quote({quotationRequests: [__quotationRequest], t: 'm'}, (result)=> {
            const _quotationResponse = QuotationResponse.fromJson(result);
            const _pack = _.first(_quotationResponse.packsResponse);
            updateGroup([
                {path: ['basket', 'quotationRequest'], value: {...__quotationRequest}},
                {path: ['basket', 'packSelected'], value: {
                    ...packSelected, 
                    primeTTC: _pack?.primeTTC,
                    primeNette: _pack?.primeNette,
                    primeExtra: _pack?.primeExtra,
                    agencyFees: _pack?.agencyFees,
                    extras: _pack?.extras
                }}
            ])

            setPackSelected({
                ...packSelected, 
                primeTTC: _pack?.primeTTC,
                primeNette: _pack?.primeNette,
                primeExtra: _pack?.primeExtra,
                agencyFees: _pack?.agencyFees,
                extras: _pack?.extras
            })
        }, (error)=> {

        })
    }
    
    const onPackSelected=(pack)=> {
        setPackSelected(pack)
    }

    const handlePacksMenuClick = (e) => {
        if(e?.key ==="0" ) {
            setIsPackCarteOn(true)
        }
    };

    const goBack=()=> {
        // Persist pack Selected
        if(packSelected?.selected) {
            updateGroup([
                { path: ['basket', 'packSelected'], value: {...packSelected}}
            ])
        }
        goToPreviousStep(null)
    }

    const goToNext=()=> {
        // Persist pack Selected
        if (_.size(packSelected?.selected) <= 0  && packSelected.primeTTC <= 0) {
            toast.error("Vous devrez selectionnez un package");
            return;
        }

        console.log(":::: Details StepOack ", packSelected)

        form.validateFields()
            .then(values => {
                // Custom guaranties
                let _customGuaranties = [];
                for(const customGuarantee of packSelected?.customGuaranties) {
                    if(customGuarantee?.selected === true) {
                        _customGuaranties = [..._customGuaranties, {
                            guaranteeRef: customGuarantee?.guaranteeRef,
                            engagementRef: customGuarantee?.engagementRef,
                            franchise: customGuarantee?.franchise
                        }]
                    }
                }

                let _otherCustomGuaranties=[];
                for(const guarantee of packSelected?.guaranties) {
                    if(guarantee?.required && (packSelected?.customGuaranties || []).findIndex((customGuarantie)=> customGuarantie?.guaranteeRef === guarantee?.ref) === -1) {
                        const defaultEngagement = (guarantee?.engagements || []).find((engagement)=> engagement?.default);
                        _otherCustomGuaranties = [..._otherCustomGuaranties, {
                            guaranteeRef: guarantee?.ref,
                            engagementRef: defaultEngagement?.ref,
                            franchise: guarantee?.franchise
                        }]
                    }
                }
                _customGuaranties = [..._customGuaranties, ..._otherCustomGuaranties];

                const _basket = {
                    ...basket,
                    formField: {
                        ...basket?.formField,
                        codePromo: values?.code
                    },
                    quotationRequest: {
                        ...basket?.quotationRequest,
                        customGuaranties: [..._customGuaranties],
                        reductionGroups: [...packSelected?.reductionGroups],
                        packLineRef: packSelected?.packLineRef
                    },
                    packSelected: {
                        ...packSelected,
                        //customGuaranties: [..._customGuaranties]
                    }
                }
                updateBasket(_basket);
                goToNextStep(null)
            })
            .catch(errorInfo => { 
                console.log("Error form :", errorInfo)
            });
    }

    if(state?.isPacksLoading) {
        return (
            <Card>
                <Spin/> Calcul de quotation en cours ....
            </Card>
        )
    }

    if(state?.isPacksLoadingError) {
        return <ErrorWidget errorMessage={state?.packsLoadingErrorMessage} goBack={()=> goToPreviousStep(null)}/>
    }
    
    return (
        <React.Fragment>
            <AmountHeader packSelected={packSelected}/>

            <Card
                title={(
                    <div style={{ display: 'flex', justifyContent: 'space-betwenn' }}>
                        <div style={{ height: 30, width: '100%', marginTop: -10 }}><h4 style={{ color: 'grey' }}>Choix du pack</h4></div>
                        <div style={{ marginTop: 0 }}>
                            <Dropdown overlay={menu(handlePacksMenuClick)} trigger={['click']}>
                                <Button type="link" onClick={() => console.log("Carte")} style={{paddingRight: "0px", color: 'rgb(0, 117, 201)'}}>
                                    <SettingOutlined style={{color: 'blue'}}/>
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                )}
                headStyle={{ backgroundColor: 'rgba(233,233,233, 0.5)', color: '#ffffff', height: 15, boxShadow: "0px 0.5px 0.5px gray", }}
            >
                <AssurancePackage 
                    packSelect={basket?.packSelected}
                    quotationRequest={basket?.quotationRequest}
                    packsResponse={basket?.quotationResponse?.packsResponse || []}
                    
                    onPackSelected={onPackSelected} 
                    isPackCarteOn={isPackCarteOn}
                    />
            </Card>
            <br />

            <Card>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={()=> goBack() } style={{ color: '#005680', border: '1px solid #005680', borderRadius: 8, marginRight: 10, marginTop: 21 }}>
                        <LeftOutlined style={{ color: '#005680' }} /> Retour
                    </Button>

                    <Button
                        style={{ color: '#ffffff', borderRadius: 8, backgroundColor: '#005680', marginRight: 10, marginTop: 21, border: '1px solid #005680' }}
                        onClick={goToNext}
                        disabled={_.size(packSelected?.guaranties) <= 0}
                    >
                        <CarOutlined style={{ color: '#ffffff', fontSize: 20 }} /> CONTINUER
                    </Button>
                </div>
            </Card>
        </React.Fragment>
    )
}

export default StepPackages;