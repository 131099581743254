import Category from "api/model/category.model";
import Period from "api/model/period.model";
import Product from "api/model/product.model";

export default class Pack {
    packLineRef;
    product = new Product();
    category =  new Category();
    offering;
    packRef;
    packCode;
    packTitle;
    packDescription;
    packStatus;
    period = new Period();
    primeTTC;
    primeHFTTC  = 0; //<primeTTC Hors Flotte>
    primeNette;
    primeExtra;
    angencyFees;
    customPrimeAcc;

    guaranties=[];
    customGuaranties=[]
    extras = [];
    reductionGroups= [];
    assistance;

    previousSelected=false;
    
    fromJson = (json = {}) => {
        var pack = new Pack();
        return {...pack, ...json}
    }

    static _fromJson = (json = {}) => {
        var pack = new Pack();
        return {...pack, ...json}
    }
}