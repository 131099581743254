export default class FormEditCoInsurance {
    code;
    title;
    phone;
    email;
    description;

    fromJson = (json={})=> {
        var formEditCoInsurance = new FormEditCoInsurance();
        formEditCoInsurance = json;
        return formEditCoInsurance;
    }
}