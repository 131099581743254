import { Form, Input } from "antd";
import React from "react";

const AssureCodeField=({disable})=> {
    return (
        <React.Fragment>
            <Form.Item
                label="Code de l'assuré"
                name="assureCode"
                rules={[{ required: false, message: "" }]}
            >
                <Input size='large' disabled={disable} />
            </Form.Item>
        </React.Fragment>
    )
}

export default AssureCodeField;