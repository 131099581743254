
import React, { useState } from "react"
import * as constants from 'constantes'
import AvenantDeCreation from "./avenant/nouvelle_souscription"
import AvenantRenouvellementSansModification from "./avenant/renouvellement_sans_modification"
import AvenantRenouvellementAvecModification from "./avenant/renouvellement_avec_modification"
import AvenantExtensionGaranties from "./avenant/extension_garanties"
import AvenantChangementAssure from "./avenant/changement_assure"
import AvenantChangementVehicule from "./avenant/changement_vehicule"
import AvenantChangementImmatriculation from "./avenant/changement_immatriculation"
import AvenantProrogation from "./avenant/prorogation"
import Duplicata from "./avenant/duplicata"
import AvenantDeSuspension from "./avenant/suspension"
import AvenantDeAnnulation from "./avenant/annulation"
import AvenantRemiseEnVigueur from "./avenant/remise_en_vigueur"
import AvenantExtensionRetraitGarantie from "./avenant/extension_retrait_garanties"
import AvenantResiliationPure from "./avenant/resiliaton_pure"
import AvenantResiliationAvecRistourne from "./avenant/resiliation_avec_ristoune"
import { useSelector } from "react-redux"
import AvenantRetraitGarantie from "./avenant/retrait_garanties"

const MonoSubscription = ({ avenant }) => {

    function renderChildren() {
        // console.log("AVENANT CHILDREN", )
        switch (parseInt(avenant?.code)) {

            case constants.CODE_AVENANTS.CREATION:
                return <AvenantDeCreation />

            case constants.CODE_AVENANTS.RENOUVELEMENT_SANS_MODIFICATION:
                return <AvenantRenouvellementSansModification />

            case constants.CODE_AVENANTS.RENOUVELEMENT_AVEC_MODIFICATION:
                return <AvenantRenouvellementAvecModification />

            case constants.CODE_AVENANTS.EXTENSION_RETRAIT_GARENTIES:
                return <AvenantExtensionRetraitGarantie />

            case constants.CODE_AVENANTS.EXTENSION_GARENTIES:
                    return <AvenantExtensionGaranties />

            case constants.CODE_AVENANTS.RETRAIT_GARENTIES:
                 return <AvenantRetraitGarantie />

            case constants.CODE_AVENANTS.CHANGEMENT_ASSURE:
                return <AvenantChangementAssure />

            case constants.CODE_AVENANTS.CHANGEMENT_VEHICULE:
                return <AvenantChangementVehicule />

            case constants.CODE_AVENANTS.CHANGEMENT_IMMATRICULATION:
                return <AvenantChangementImmatriculation />

            case constants.CODE_AVENANTS.PROROGATON:
                return <AvenantProrogation />

            case constants.CODE_AVENANTS.DUPLICATA_ATTESTATION:
                return <Duplicata />

            case constants.CODE_AVENANTS.SUSPENSION:
                return <AvenantDeSuspension />
            case constants.CODE_AVENANTS.ANNULATION_PURE:
                return <AvenantDeAnnulation />
            case constants.CODE_AVENANTS.REMISE_EN_VIGUEUR_AVEC_REPORT:
                return <AvenantRemiseEnVigueur />

            case constants.CODE_AVENANTS.RESILIATION_PURE:
                return <AvenantResiliationPure />
            case constants.CODE_AVENANTS.RESILIATION_AVEC_RISTOUNE:
                return <AvenantResiliationAvecRistourne />

            default:
        }
    }

    return (
        <React.Fragment>
            {renderChildren()}
        </React.Fragment>
    )
}

export default MonoSubscription;