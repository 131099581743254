import Assure from "api/model/assure.model";
import Pack from "api/model/pack";
import SubscriptionRequest from "api/request/subscriptionRequest";
import moment from "moment";

export class FormField {

    constructor({
        categoryRef = null,
        categoryCode = null,
        categoryTitle = null,

        //Vehicle
        vehicleRef = null,
        transportRef = null,
        transportTitle = null,
        transportCode = null,
        motor= null,
        energyRef = null,
        energyTitle = null,
        nbPlace = null,
        fiscalPower = null,
        cylinder= null,
        dateMec = null,
        newValue = null,
        marketValue = null,
        payload = null,
        inflammable= false,
    
        hasGroup = false,
    
        groupementTypeRef = null,
        groupementTypeTitle = null,
        groupementRef = null,
        groupementTitle = null,
    
        cityRef = null,
        cityTitle = null,
    
        agePermis = null,
        driverBirthDay = null,
        driverLicDeliverDate = null,
    
        periodRef = null,
        periodTitle = null,
        customerPeriod = false,
        customerStartDate=null,
        customerEndDate = null,
        prorate=false,
    
        assureurForfait = null,
        contribution = null,
        avanceRecours = 0,
        fraisAgence = 0,
        puissanceRemorque = 0,
        remorque = null,
        hasGps = false,
    
        codePromo = "",
    
        // MODEL / BRAND
        customerBrand = false,
        brandRef = null,
        brandTitle = null,
        customerModel = false,
        modelRef = null,
        modelTitle = null,
    
        matriculation = null,
        chassisNumber = null,

        // Trailer
        hasTrailer = false,
        trailerCustomerBrand=false,
        trailerBrandRef=null,
        trailerBrandTitle=null,
        trailerCustomerModel=false,
        trailerModelRef=null,
        trailerModelTitle=null,
        trailerMatriculation=null,
        trailerChassisNumber=null,

        // ASSURE
        assureCode = null, // Utile lorsque l'assuré a été trouvé automatiquement
        assureFirstname = null,
        assureLastname = null,
        assurePhone = null,
        assureEmail = null,
        assureBirthDay = null,
    
        assureIdentityCardRef = null, // String of Card Object
        assureIdentityCardTitle = null,
        assureIdentityCardNumber = null,
        isProspect=true,
    
        socialPosRef = null,
        socialPosTitle = null,
    
        // DATE EFFECT
        startDate = null,
    
        carteGrise = null,
        files = null,
    
        customGuaranties = [],
        reductionGroups = [],

        // MERCHANT
        /* merchantCode = null, 
        merchantFirstname = null,
        merchantLastname = null,
        merchantPhone = null,
        merchantEmail = null, */
    }) {
        this.categoryRef = categoryRef;
        this.categoryCode = categoryCode;
        this.categoryTitle = categoryTitle;

        this.vehicleRef = vehicleRef;
        this.transportRef = transportRef;
        this.transportTitle = transportTitle;
        this.transportCode = transportCode;
        this.motor = motor;
        this.energyRef = energyRef;
        this.energyTitle = energyTitle;

        this.nbPlace = nbPlace;
        this.fiscalPower = fiscalPower;
        this.cylinder = cylinder;
        this.dateMec = dateMec;
        this.newValue = newValue;
        this.marketValue = marketValue;

        this.payload = payload;
        this.inflammable = inflammable;

        this.hasGroup = hasGroup;

        this.groupementTypeRef = groupementTypeRef;
        this.groupementTypeTitle = groupementTypeTitle;
        this.groupementRef = groupementRef;
        this.groupementTitle = groupementTitle;

        this.cityRef = cityRef;
        this.cityTitle = cityTitle;

        this.agePermis = agePermis;
        this.driverBirthDay = driverBirthDay;
        this.driverLicDeliverDate = driverLicDeliverDate;

        this.periodRef = periodRef;
        this.periodTitle = periodTitle;
        this.customerPeriod = customerPeriod;
        this.customerStartDate = customerStartDate;
        this.customerEndDate = customerEndDate;
        this.prorate = prorate;

        this.assureurForfait = assureurForfait;
        this.contribution = contribution;
        this.avanceRecours = avanceRecours;
        this.fraisAgence = fraisAgence;
        this.puissanceRemorque = puissanceRemorque;
        this.remorque = remorque;
        this.hasGps = hasGps;

        this.codePromo = codePromo;

        // MODEL / BRAND
        this.customerBrand = customerBrand;
        this.brandRef = brandRef;
        this.brandTitle = brandTitle;
        this.customerModel = customerModel;
        this.modelRef = modelRef;
        this.modelTitle = modelTitle;

        this.matriculation = matriculation;
        this.chassisNumber = chassisNumber;

        // Trailer
        this.hasTrailer = hasTrailer;
        this.trailerCustomerBrand = trailerCustomerBrand;
        this.trailerBrandRef=trailerBrandRef;
        this.trailerBrandTitle=trailerBrandTitle;
        this.trailerCustomerModel = trailerCustomerModel;
        this.trailerModelRef=trailerModelRef;
        this.trailerModelTitle=trailerModelTitle;
        this.trailerMatriculation=trailerMatriculation;
        this.trailerChassisNumber=trailerChassisNumber;

        // ASSURE
        this.assureCode = assureCode; // Utile lorsque l'assuré a été trouvé automatiquement
        this.assureFirstname = assureFirstname;
        this.assureLastname = assureLastname;
        this.assurePhone = assurePhone;
        this.assureEmail = assureEmail;
        this.assureBirthDay = assureBirthDay;

        this.assureIdentityCardRef = assureIdentityCardRef; // String of Card Object
        this.assureIdentityCardTitle = assureIdentityCardTitle;
        this.assureIdentityCardNumber = assureIdentityCardNumber;
        this.isProspect = isProspect;

        this.socialPosRef = socialPosRef;
        this.socialPosTitle = socialPosTitle;

        // DATE EFFECT
        this.startDate = startDate;

        this.carteGrise = carteGrise;
        this.files = files;
        
        this.customGuaranties = customGuaranties;
        this.reductionGroups = reductionGroups;

       /*  this.merchantCode = merchantCode; // Utile lorsque le merchant a été trouvé automatiquement
        this.merchantFirstname = merchantFirstname;
        this.merchantLastname = merchantLastname;
        this.merchantPhone = merchantPhone;
        this.merchantEmail = merchantEmail; */
    }

    fromJson = (json = {}) => {
        var formField = new FormField();
        formField = json;
        return formField;
    }

    

    buildFormFieldFromPrevSubscription(prevSubscription, packSelected, assure, merchant=null) {
        const _assure = Assure.fromJson(assure);
        const _prevSubscription = SubscriptionRequest.fromJson(prevSubscription);
        const _packSelected = (new Pack()).fromJson(packSelected);
        
        const inputs = {
            // Step 1
            categoryRef: _prevSubscription?.categoryRef,
            categoryTitle: _prevSubscription?.categoryTitle,
            transportRef: _prevSubscription?.vehicle?.transport?.ref,
            transportTitle: _prevSubscription?.vehicle?.transport?.title,
            energyRef: _prevSubscription?.vehicle?.energy?.ref,
            energyTitle: _prevSubscription?.vehicle?.energy?.title,

            nbPlace: _prevSubscription?.vehicle?.totalPlace,
            fiscalPower: _prevSubscription?.vehicle?.fiscalPower,
            dateMec: moment((new Date(_prevSubscription?.vehicle?.dateMec).toLocaleDateString()), "DD/MM/YYYY"),
            newValue: _prevSubscription?.vehicle?.newValue,
            marketValue: _prevSubscription?.vehicle?.marketValue,
            payload: _prevSubscription?.vehicle?.payload,

            hasGroup: _prevSubscription?.vehicle?.hasGroup,

            groupementTypeRef: "",
            groupementTypeTitle: "",
            groupementRef: _prevSubscription?.vehicle?.groupRef,
            groupementTitle: _prevSubscription?.vehicle?.groupName,

            cityRef: _prevSubscription?.vehicle?.city?.ref,
            cityTitle: _prevSubscription?.vehicle?.city?.title,

            socialPosRef: _prevSubscription?.vehicle?.socialPosRef,
            socialPosTitle: _prevSubscription?.vehicle?.socialPosTitle,

            agePermis: null,
            driverBirthDay: _prevSubscription?.driverBirthDay,
            driverLicDeliverDate: _prevSubscription?.driverLicdeliveryDate,
            periodRef: _prevSubscription?.periodRef,
            periodTitle: _prevSubscription?.periodTitle,

            assureurForfait: 0,
            contribution: 0,
            avanceRecours: 0,
            fraisAgence: 0,
            puissanceRemorque: 0,
            remorque: null,
            hasGps: _prevSubscription?.vehicle?.hasGps,

            // Step 2
            codePromo: "",

            // Step 3
            brandRef: _prevSubscription?.vehicle?.model?.brand?.ref,
            brandTitle: _prevSubscription?.vehicle?.model?.brand?.title,
            modelRef: _prevSubscription?.vehicle?.model?.ref,
            modelTitle: _prevSubscription?.vehicle?.model?.title,

            matriculation: _prevSubscription?.vehicle?.matriculation,
            chassisNumber: _prevSubscription?.vehicle?.chassisNumber,

            assureCode: _assure?.code, // Utile lorsque l'assuré a été trouvé automatiquement
            assureFirstname: _assure?.firstName,
            assureLastname: _assure?.lastName,
            assurePhone: _assure?.phone,
            assureEmail: _assure?.assureEmail,
            assureBirthDay: _assure?.birthDay,
            assureIdentityCardRef: _assure?.identityCardRef, // String of Card Object
            assureIdentityCardTitle: _assure?.identityCardTitle,
            assureIdentityCardNumber: _assure?.identityCardNumber,
            isProspect: _assure?.isProspect,

            startDate: "",

            carteGrise: "",
            files: "",

            customGuaranties: _packSelected?.customGuaranties,
            reductionGroups: _packSelected.reductionGroups,

            /* merchantCode: merchant?.code,
            merchantFirstname: merchant?.firstname,
            merchantLastname: merchant?.lastname,
            merchantPhone: merchant.phone,
            merchantEmail: merchant.email */

        }

        return inputs;
    }
}