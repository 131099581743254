import {  Form, Input } from "antd";
import React from "react";

const AssureFirstNameField=({disable})=> {
    return (
        <React.Fragment>
            <Form.Item
                label="Nom"
                name="assureFirstname"
                rules={[{ required: true, message: "Champ obligatoire" }]}
            >
                <Input size='large' placeholder='Nom' disabled={disable} />
            </Form.Item>
        </React.Fragment>
    )
}

export default AssureFirstNameField;