import { Form, Select } from "antd"
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter"
import React,  { useState } from "react"
import { useEffect } from "react"
import AssureClassService from "services/assureClassService"

const AssureClassField=({assureClasses})=> {
    const Option = Select;
    const [state, setState]=useState({
        assureClasses: assureClasses ?? [],
        isAssureClassesLoading: false
    })

    useEffect(()=> {
        if(!assureClasses) {
            getAssureClasses()
        }
    }, [])

    const getAssureClasses=()=> {
        setState(state=> ({...state, isAssureClassesLoading: true}))
        AssureClassService.getAll({}, (data)=> {
            const _assureClasses = data || [];
            setState(state=> ({...state, assureClasses: _assureClasses, isAssureClassesLoading: false}))
        }, (error)=> {
            setState(state=> ({...state, isAssureClassesLoading: false}))
        })
    }

    return (
        <React.Fragment>
            <Form.Item
                label="Classe socio."
                name="socialClassRef"
                rules={[{ required: false, message: "Champ obligatoire" }]}
            >
                <SelectWithFilter size='medium' placeholder="Selectionner" addonBefore={"Classe socio. :"}>
                    {state.assureClasses.map((assureClass, index)=> (<Option value={assureClass?.ref} key={index}>{assureClass?.title}</Option>))}
                </SelectWithFilter>
            </Form.Item>
        </React.Fragment>
    )
}

export default AssureClassField;