import React from "react";
import './style.css'

const PasswordIndicator=({score})=> {
    
    const colors = {
        0: "#e5e5e5",
        1: "#9B2C2C",
        2: "#D44949",
        3: "#DCA02D",
        4: "#387F95",
        5: "#48AE65"
    };

    const getColor = (power, index) => {
        if (power > index) {
            return colors[power];
        }
        return colors[0];
    };
    
    const indicatorIndexes = [0, 1, 2, 3, 4];

    return (
        <React.Fragment>
            <div className="mt-2 indicator-container">
                {indicatorIndexes.map((indicatorIndex, index) => (
                <div
                    className="indicator"
                    key={indicatorIndex}
                    style={{ backgroundColor: getColor(score + 1, indicatorIndex) }}
                />
                ))}
            </div>
        </React.Fragment>
    )
}

export default PasswordIndicator;