import QuotationService from 'services/quotationService';
import QuotationRequestBuilder from 'helpers/QuotationRequestBuilder';

export function useBuildQuotationFlotte() {
    // const [state, setState] = useState({
    //     isLoadingFlotteQuotation: false,
    //     flottePrimeExtra: 0,
    //     flottePrimeNette: 0,
    //     agencyFees: 0,
    //     flottePrimeTTC: 0,
    //     flotteExtras: [],
    //     flotteRate: 0,
    //     primeTTCHorsFlotte: 0,
    // });

    // const calculTotalPrimeTTCHorsFlotte = (flotteBaskets) => {
    //     let primeTTC = 0;

    //     (flotteBaskets || []).forEach(basket => {
    //         primeTTC += basket?.packSelected?.primeTTC;
    //     });

    //     return primeTTC;
    // }

    function buildQuotation({ baskets, avenant }, successCb, errorCb) {
        console.log(".....flotteGeneratePacks : ", avenant);
        // const quotationRequests = (flotteBasket || []).map((basket, index) => {
        //     var quotationRequest = (new QuotationRequest()).fromJson(basket.quotationRequest);
        //     quotationRequest.packLineRef = basket.packSelected.packLineRef;
        //     return quotationRequest;
        // });

        const quotationRequests = baskets.map(basket => QuotationRequestBuilder.build({ formFields: basket.formFields, avenant, packLineRef: basket.packSelected?.packLineRef }));

        if (quotationRequests.length > 0) {
            // setState(prevState => ({ ...prevState, isLoadingFlottePrime: true }));

            QuotationService.flotteGeneratePacks(quotationRequests, (data) => {
                console.log(".....flotteGeneratePacks : ", data);

                // Recalcule prime hors flotte
                // const _primeTTCHorsFlotte = calculTotalPrimeTTCHorsFlotte(flotteBasket);

                // setState(prevState => ({
                //     ...prevState,
                //     flottePrimeExtra: data?.primeExtra,
                //     flottePrimeNette: data?.primeNette,
                //     agencyFees: data?.agencyfees,
                //     flottePrimeTTC: data?.primeTTC,
                //     flotteExtras: data?.extras || [],
                //     flotteRate: (data?.flotteRate || 0) * 100,
                //     isLoadingFlottePrime: false,
                //     primeTTCHorsFlotte: 0,
                //     isLoadingFlotteQuotation: false
                // }));

                successCb({
                    primeExtra: data?.primeExtra,
                    primeNette: data?.primeNette,
                    agencyFees: data?.agencyfees,
                    primeTTC: data?.primeTTC,
                    extras: data?.extras || [],
                    flotteRate: (data?.flotteRate || 0) * 100,
                    primeTTCHorsFlotte: 0,
                    category: data?.category
                });
            }, (error) => {
                console.log(error);

                // setState(prevState => ({
                //     ...prevState,
                //     isLoadingFlotteQuotation: false
                // }));

                if (errorCb) errorCb(error);
            })
        } else if (errorCb) {
            errorCb('Aucune quotation')
        }
    }

    return { buildQuotation }
}
