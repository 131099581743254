import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify";
import { addBasket, deleteBasket, quoteAsync, toNextStep, toPreviousStep, upBasket, upBaskets, upFormField, upQuotationRequest, upState, upFSusbscriptionRequest, upSusbscriptionRequest, basketRaz, editBaskets, quoteBaskets, upBasketsQuotationRequest, eventFQuote } from "redux/slices/basketSlice"
import * as helpers from 'helpers/helpers';

export function useBasket() {

    const {contract, avenant, category, isProject, projectCode, partnerCode } = useSelector(state => state.subscription);
    const dispatch   = useDispatch();

    const [initStartDate, setInitStartDate] = useState({
        startDate: null,
        endDate: null,
        initialDate: null
    })
    
    const razBasket = ()=> dispatch(basketRaz());

    const updateGroup=(data)=> dispatch(upState(data))
    
    const updateFormField=(field)=> {
        dispatch(upFormField(field))
    }

    const updateBasket=(newBasket)=> dispatch(upBasket(newBasket))

    const updateBaskets=(newBaskets)=> dispatch(upBaskets(newBaskets))

    const goToNextStep=(data=null)=>dispatch(toNextStep(data))

    const goToPreviousStep=(data)=>dispatch(toPreviousStep(data));

    // :::::::: PARCOURS QUOTATION ::::::::::::
    const updateQuotationRequest = (specificPack=false)=> dispatch(upQuotationRequest({contract: contract, avenant: avenant, specificPack: specificPack}))
    // ::::::::::::::::::::::::::::::::::::::::

    // :::::::: UPDATE BASKETS QUOTATIONS REQUEST ::::::::::::
    const updateBasketsQuotationRequest = ()=> dispatch(upBasketsQuotationRequest({contract: contract, avenant: avenant}))
    // ::::::::::::::::::::::::::::::::::::::::

    const updateSusbscriptionRequest=({isProrate=false})=> dispatch(upSusbscriptionRequest({
        contract: contract, 
        avenant: avenant,
        category: category,
        isProject: isProject,
        projectCode: projectCode,
        partnerCode: partnerCode,
        isProrate: isProrate
    }))

    // FLOTTE
    const quoteFBaskets=()=> dispatch(quoteBaskets());
    const fQuoting=(toQuote)=> dispatch(eventFQuote(toQuote));

    const updateFSusbscriptionRequest=({isProrate=false, assure})=> dispatch(upFSusbscriptionRequest({
        contract: contract, 
        avenant: avenant,
        category: category,
        isProject: isProject,
        projectCode: projectCode,
        partnerCode: partnerCode,
        isProrate: isProrate,
        assure: assure
    }))

    // ::::::::::: PACKAGE / REDUCTION :::::::::::::
    const onAddBasket=(newBasket)=>dispatch(addBasket(newBasket));
    const onEditBaskets=(newBasket)=>dispatch(editBaskets(newBasket));

    const onDeleteBasket=(newBasket)=> dispatch(deleteBasket(newBasket));

    const applyReduction = (_basket, customerReductions) => {
       
        const basket = {
            ..._basket, 
            quotationRequest : {
                ..._basket.quotationRequest,
                reductionGroups: [...customerReductions]
            }
        }

        console.log(":::: _basket :", _basket)
        dispatch(upState([
            { path: ['basket'], value: basket}
        ]))

        quote({baskets: [basket]});
    }

    // ::::::::::::::::::::::::::::::::::::::::::::::

    // :::::::::::::: QUOTES PACKS :::::::::::::::
    const quote = ({baskets, prorate=false, t, init=false}) => {
        let quotationRequests = [];
        
        quotationRequests = (baskets || []).map((basket, index)=> {
            const quotationRequest = {
                ...basket?.quotationRequest,
                //packLineRef: basket?.packSelected?.packLineRef,
                prorate: prorate || false
            };
            return quotationRequest;
        });

        const options = {
            query: {t:t ?? null, init: init}
        }

        dispatch(quoteAsync({options: options, payload: quotationRequests}))
    }

    // ::::::::::::::::::::::::::::::::::::::::::::::

    // :::::::::::::: PAIEMENT BASKET :::::::::::::::
    const updatePaymentStatus=({error=false, errorMessage='', transactionResponse, page= helpers.PAGES.THANK_YOU })=> {
        /* if(!status) {
          toast.error("Une erreur s'est produite");
          return;
        } */
    
        if(error) {
            dispatch(updateGroup([
                { path: ['paymentStatus'], value: {error: error, errorMessage: errorMessage } }
            ]))
          toast.error(errorMessage);
          return;
        }

        dispatch(toNextStep([
            { path: ['paymentStatus'], value: {error: error, errorMessage: errorMessage, transactionResponse:transactionResponse, page: page } },
            { path: ['transactionResponse'], value: transactionResponse },
            { path: ['page'], value: page },
        ]))
    }

    //======================================
    const onStartDateChange = (newDateEffect) => {

        setInitStartDate({
            ...initStartDate,
            startDate: newDateEffect
        })

        dispatch(upState([
            { path: ['basket', 'subscriptionRequest', 'dateEffect'], value: newDateEffect }
        ]))
    }

    const onFStartDateChange = (newDateEffect) => {

        setInitStartDate({
            ...initStartDate,
            startDate: newDateEffect
        })

        dispatch(upState([
            {path:['fSubscriptionRequest', 'dateEffect'], value: newDateEffect}
        ]))
    }

    return { 
        razBasket,
        updateGroup,
        updateFormField,
        updateBasket, 
        updateBaskets,
        updateBasketsQuotationRequest,
        goToNextStep,
        goToPreviousStep,

        updateQuotationRequest,
        updateSusbscriptionRequest,
        updateFSusbscriptionRequest,
        
        quoteFBaskets,
        fQuoting,
        onAddBasket,
        onEditBaskets,
        onDeleteBasket, 
        applyReduction,

        quote,

        updatePaymentStatus,
        
        // Period
        initStartDate,
        setInitStartDate,
        onStartDateChange,
        onFStartDateChange
    }
}