import React from 'react';
import { WindowsOutlined } from "@ant-design/icons";
import { ACTIONS } from "profileActions";
import Coassurance from "views/pages/co-assurance/CoAssurance";
import DetailAssureSouscription from "views/pages/details_assure";
import DeatailsCommission from "views/pages/details_commission/DetailCommission";
import Details from "views/pages/details_parametres/Details";
import DetailsSouscription from "views/pages/subscription/details_souscriptions";
import Agences from "views/pages/parametres/agence/Agences";
import Categorie from "views/pages/parametres/categorie";
import Co_Assurance from "views/pages/parametres/co-assurance/Co_Assurance";
import SettingCommissions from "views/pages/parametres/commission/CommissionSetting";
import Coupon from "views/pages/parametres/coupon/Coupon";
import Groupement from "views/pages/parametres/groupement/Groupement";
import Produits from "views/pages/parametres/produits/Produits";
import Utilisateurs from "views/pages/parametres/utilisateurs/Utilisateurs";
import Reporting from "views/pages/rapports/Reporting";
import Subscription from "views/pages/subscription";
import ListSubscriptionGroupement from "views/pages/groupement/ListSubscriptionGroupement";
import SettingPartenaire from 'views/pages/parametres/partenaires/Partenaires';
import Emission from 'views/pages/rapports/Emission';
import Expiration from 'views/pages/rapports/Expiration';
import SerialAttestation from 'views/pages/parametres/serial_attestation';

export const APP_BASE_URL = {
    souscriptions: { url: "/souscription", title: "Souscriptions autos" },
    details_subscription: { url: "/souscription-details", title: "Details souscription auto" },
    groupement: { url: "/groupement", title: "Groupements" },
    co_assurance: { url: "/co-assurance", title: "Co assurance" },
    commissions: { url: "/commissions", title: "Commissions" },
    details_assure: { url: "/assure", title: "Details souscription assure" },
    details_commission: { url: "/commission", title: "Details commission" },
    details_parametre: { url: "/detailParametre", title: "Details parametres" },
    categories: { url: "/categorie", title: "Categorie" },
    reporting: { url: "/reporting", title: "Reporting" },
    parametres: { url: "/parametres", title: "Paramètres" },
};

//=====================================
//===== BIND ACTIONS TO COMPONENT =====
//=====================================
export default class RouteModule {

    static souscription400 = {
        name: "400",
        title: "Categorie 400",
        url: `${APP_BASE_URL.souscriptions.url}/400`,
        base_url: APP_BASE_URL.souscriptions.url,
        sidebarTitle: APP_BASE_URL.souscriptions.title,
        topBar2Title: APP_BASE_URL.souscriptions.title,
        icon: <WindowsOutlined />,
        component: Subscription
    };
    static souscription401 = {
        name: "401",
        title: "Categorie 401",
        url: `${APP_BASE_URL.souscriptions.url}/401`,
        base_url: APP_BASE_URL.souscriptions.url,
        sidebarTitle: APP_BASE_URL.souscriptions.title,
        topBar2Title: APP_BASE_URL.souscriptions.title,
        icon: <WindowsOutlined />,
        component: Subscription
    };

    //Souscription categorie 402
    static souscription402 = {
        name: ACTIONS.souscription_cat_402,
        title: "Categorie 402",
        url: `${APP_BASE_URL.souscriptions.url}/${ACTIONS.souscription_cat_402}`,
        base_url: APP_BASE_URL.souscriptions.url,
        sidebarTitle: APP_BASE_URL.souscriptions.title,
        topBar2Title: APP_BASE_URL.souscriptions.title,
        icon: <WindowsOutlined />,
        component: Subscription
    };

    static souscription403 = {
        name: ACTIONS.souscription_cat_403,
        title: "Categorie 403",
        url: `${APP_BASE_URL.souscriptions.url}/${ACTIONS.souscription_cat_403}`,
        base_url: APP_BASE_URL.souscriptions.url,
        sidebarTitle: APP_BASE_URL.souscriptions.title,
        topBar2Title: APP_BASE_URL.souscriptions.title,
        icon: <WindowsOutlined />,
        component: Subscription
    };

    static souscription404 = {
        name: ACTIONS.souscription_cat_404,
        title: "Categorie 404",
        url: `${APP_BASE_URL.souscriptions.url}/${ACTIONS.souscription_cat_404}`,
        base_url: APP_BASE_URL.souscriptions.url,
        sidebarTitle: APP_BASE_URL.souscriptions.title,
        topBar2Title: APP_BASE_URL.souscriptions.title,
        icon: <WindowsOutlined />,
        component: Subscription
    };

    static souscription405 = {
        name: ACTIONS.souscription_cat_405,
        title: "Categorie 405",
        url: `${APP_BASE_URL.souscriptions.url}/${ACTIONS.souscription_cat_405}`,
        base_url: APP_BASE_URL.souscriptions.url,
        sidebarTitle: APP_BASE_URL.souscriptions.title,
        topBar2Title: APP_BASE_URL.souscriptions.title,
        icon: <WindowsOutlined />,
        component: Subscription
    };

    static souscription406 = {
        name: ACTIONS.souscription_cat_406,
        title: "Categorie 406",
        url: `${APP_BASE_URL.souscriptions.url}/${ACTIONS.souscription_cat_406}`,
        base_url: APP_BASE_URL.souscriptions.url,
        sidebarTitle: APP_BASE_URL.souscriptions.title,
        topBar2Title: APP_BASE_URL.souscriptions.title,
        icon: <WindowsOutlined />,
        component: Subscription
    };

    static souscription407 = {
        name: ACTIONS.souscription_cat_407,
        title: "Categorie 407",
        url: `${APP_BASE_URL.souscriptions.url}/${ACTIONS.souscription_cat_407}`,
        base_url: APP_BASE_URL.souscriptions.url,
        sidebarTitle: APP_BASE_URL.souscriptions.title,
        topBar2Title: APP_BASE_URL.souscriptions.title,
        icon: <WindowsOutlined />,
        component: Subscription
    };

    static souscription408 = {
        name: ACTIONS.souscription_cat_408,
        title: "Categorie 408",
        url: `${APP_BASE_URL.souscriptions.url}/${ACTIONS.souscription_cat_408}`,
        base_url: APP_BASE_URL.souscriptions.url,
        sidebarTitle: APP_BASE_URL.souscriptions.title,
        topBar2Title: APP_BASE_URL.souscriptions.title,
        icon: <WindowsOutlined />,
        component: Subscription
    };

    static souscription409 = {
        name: ACTIONS.souscription_cat_409,
        title: "Categorie 409",
        url: `${APP_BASE_URL.souscriptions.url}/${ACTIONS.souscription_cat_409}`,
        base_url: APP_BASE_URL.souscriptions.url,
        sidebarTitle: APP_BASE_URL.souscriptions.title,
        topBar2Title: APP_BASE_URL.souscriptions.title,
        icon: <WindowsOutlined />,
        component: Subscription
    };

    static souscription410 = {
        name: ACTIONS.souscription_cat_410,
        title: "Categorie 410",
        url: `${APP_BASE_URL.souscriptions.url}/${ACTIONS.souscription_cat_410}`,
        base_url: APP_BASE_URL.souscriptions.url,
        sidebarTitle: APP_BASE_URL.souscriptions.title,
        topBar2Title: APP_BASE_URL.souscriptions.title,
        icon: <WindowsOutlined />,
        component: Subscription
    };

    // ======================================

    //Souscription details
    static detailSouscription = {
        name: ACTIONS.details_souscription,
        title: "Details souscription",
        url: `${APP_BASE_URL.details_subscription.url}/:subscriptionID/:assureCode/:contratId/:categorie`,
        base_url: APP_BASE_URL.details_subscription.url,
        icon: <WindowsOutlined />,
        component: DetailsSouscription
    };

    //Assure Souscription details
    static detailSoubscriptionAssure = {
        name: ACTIONS.details_souscription_assure,
        title: "Assuré souscription details ",
        url: `${APP_BASE_URL.details_assure.url}/:assureCode/contrats`,
        base_url: APP_BASE_URL.details_assure.url,
        icon: <WindowsOutlined />,
        component: DetailAssureSouscription
    };

    //Menu Groupement
    static listGroupement = {
        name: ACTIONS.list_souscription_groupement,
        title: "Liste des groupements",
        url: `${APP_BASE_URL.groupement.url}/contrats`,
        base_url: APP_BASE_URL.groupement.url,
        sidebarTitle: APP_BASE_URL.groupement.title,
        topBar2Title: APP_BASE_URL.groupement.title,
        icon: <WindowsOutlined />,
        component: ListSubscriptionGroupement
    };

    static detailCommission = {
        name: ACTIONS.liste_commissions,
        title: "Details commission",
        url: `${APP_BASE_URL.details_commission.url}/details/:commissionId`,
        base_url: APP_BASE_URL.details_commission.url,
        icon: <WindowsOutlined />,
        component: DeatailsCommission
    };

    static detailParametre = {
        name: ACTIONS.detail_parametre,
        title: "Details parametres",
        url: `${APP_BASE_URL.details_parametre.url}/${ACTIONS.detail_parametre}/:type/:id`,
        base_url: APP_BASE_URL.details_parametre.url,
        icon: <WindowsOutlined />,
        component: Details
    };

    //Menu Co-assurance
    static listContractCoInsurance = {
        name: ACTIONS.list_co_assurance,
        title: "Liste co-assurance",
        url: `${APP_BASE_URL.co_assurance.url}/contrats`,
        base_url: APP_BASE_URL.co_assurance.url,
        sidebarTitle: APP_BASE_URL.co_assurance.title,
        topBar2Title: APP_BASE_URL.co_assurance.title,
        icon: <WindowsOutlined />,
        component: Coassurance
    };

    //Menu commissions
    /* static listCommisions = {
        name: ACTIONS.commissions,
        title: "Commissions",
        url: `${APP_BASE_URL.commissions.url}/${ACTIONS.commissions}`,
        base_url: APP_BASE_URL.commissions.url,
        sidebarTitle: APP_BASE_URL.commissions.title,
        topBar2Title: APP_BASE_URL.commissions.title,
        icon: <WindowsOutlined />,
        component: Emission
    }; */

    //Menu reporting: Liste des vehicules
    static reporting = {
        name: ACTIONS.reporting_liste_vehicules,
        title: "Liste des véhicules",
        url: `${APP_BASE_URL.reporting.url}/${ACTIONS.reporting_liste_vehicules}`,
        base_url: APP_BASE_URL.reporting.url,
        sidebarTitle: APP_BASE_URL.reporting.title,
        topBar2Title: APP_BASE_URL.reporting.title,
        icon: <WindowsOutlined />,
        component: Reporting
    };

    //Menu reporting: Bordereau d'émission
    static reportingCommission = {
        name: ACTIONS.reporting_bordereau_des_emissions,
        title: "Bordereau des émissions",
        url: `${APP_BASE_URL.reporting.url}/${ACTIONS.reporting_bordereau_des_emissions}`,
        base_url: APP_BASE_URL.reporting.url,
        sidebarTitle: APP_BASE_URL.reporting.title,
        topBar2Title: APP_BASE_URL.reporting.title,
        icon: <WindowsOutlined />,
        component: Emission
    };

    //Menu reporting: Liste des expirations
    static reportingExpirations = {
        name: ACTIONS.reporting_bordereau_des_expirations,
        title: "Bordereau des expirations",
        url: `${APP_BASE_URL.reporting.url}/${ACTIONS.reporting_bordereau_des_expirations}`,
        base_url: APP_BASE_URL.reporting.url,
        sidebarTitle: APP_BASE_URL.reporting.title,
        topBar2Title: APP_BASE_URL.reporting.title,
        icon: <WindowsOutlined />,
        component: Expiration
    };

    //===========================
    //=======PARAMETRES==========
    //===========================
    static settingAgency = {
        name: ACTIONS.agences,
        title: "Agences",
        url: `${APP_BASE_URL.parametres.url}/${ACTIONS.param_agences}`,
        base_url: APP_BASE_URL.parametres.url,
        sidebarTitle: APP_BASE_URL.parametres.title,
        topBar2Title: APP_BASE_URL.parametres.title,
        icon: <WindowsOutlined />,
        component: Agences
    };

    static settingAgency = {
        name: ACTIONS.param_categories,
        title: "Categories",
        url: `${APP_BASE_URL.parametres.url}/${ACTIONS.param_categories}`,
        base_url: APP_BASE_URL.parametres.url,
        sidebarTitle: APP_BASE_URL.parametres.title,
        topBar2Title: APP_BASE_URL.parametres.title,
        icon: <WindowsOutlined />,
        component: Categorie
    };

    static settingCoInsurance = {
        name: ACTIONS.param_co_assurance,
        title: "Co-assurance",
        url: `${APP_BASE_URL.parametres.url}/${ACTIONS.param_co_assurance}`,
        base_url: APP_BASE_URL.parametres.url,
        sidebarTitle: APP_BASE_URL.parametres.title,
        topBar2Title: APP_BASE_URL.parametres.title,
        icon: <WindowsOutlined />,
        component: Co_Assurance
    }

    static settingCommission = {
        name: ACTIONS.param_commission,
        title: "Commissions",
        url: `${APP_BASE_URL.parametres.url}/${ACTIONS.param_commission}`,
        base_url: APP_BASE_URL.parametres.url,
        sidebarTitle: APP_BASE_URL.parametres.title,
        topBar2Title: APP_BASE_URL.parametres.title,
        icon: <WindowsOutlined />,
        component: SettingCommissions
    };

    static settingCoupons = {
        name: ACTIONS.param_coupons,
        title: "Coupons",
        url: `${APP_BASE_URL.parametres.url}/${ACTIONS.param_coupons}`,
        base_url: APP_BASE_URL.parametres.url,
        sidebarTitle: APP_BASE_URL.parametres.title,
        topBar2Title: APP_BASE_URL.parametres.title,
        icon: <WindowsOutlined />,
        component: Coupon
    };

    /* static settingGuaranties = {
        name: ACTIONS.param_garanties,
        title: "Garanties",
        url: `${APP_BASE_URL.parametres.url}/${ACTIONS.param_garanties}`,
        base_url: APP_BASE_URL.parametres.url,
        sidebarTitle: APP_BASE_URL.parametres.title,
        topBar2Title: APP_BASE_URL.parametres.title,
        icon: <WindowsOutlined />,
        component: Garantie
    }; */

    static settingGroupement = {
        name: ACTIONS.param_groupement,
        title: "Groupements",
        url: `${APP_BASE_URL.parametres.url}/${ACTIONS.param_groupement}`,
        base_url: APP_BASE_URL.parametres.url,
        sidebarTitle: APP_BASE_URL.parametres.title,
        topBar2Title: APP_BASE_URL.parametres.title,
        icon: <WindowsOutlined />,
        component: Groupement
    };

    static settingAttestationGesture = {
        name: ACTIONS.param_gestion_attestation,
        title: "Gestion des attestations",
        url: `${APP_BASE_URL.parametres.url}/${ACTIONS.param_gestion_attestation}`,
        base_url: APP_BASE_URL.parametres.url,
        sidebarTitle: APP_BASE_URL.parametres.title,
        topBar2Title: APP_BASE_URL.parametres.title,
        icon: <WindowsOutlined />,
        component: SerialAttestation
    };

    /* static settingReduction = {
        name: ACTIONS.param_reduction,
        title: "Réduction",
        url: `${APP_BASE_URL.parametres.url}/${ACTIONS.param_reduction}`,
        base_url: APP_BASE_URL.parametres.url,
        sidebarTitle: APP_BASE_URL.parametres.title,
        topBar2Title: APP_BASE_URL.parametres.title,
        icon: <WindowsOutlined />,
        component: Reduction
    }; */

    static settingPartners = {
        name: ACTIONS.param_partenaires,
        title: "Partenaires",
        url: `${APP_BASE_URL.parametres.url}/${ACTIONS.param_partenaires}`,
        base_url: APP_BASE_URL.parametres.url,
        sidebarTitle: APP_BASE_URL.parametres.title,
        topBar2Title: APP_BASE_URL.parametres.title,
        icon: <WindowsOutlined />,
        component: SettingPartenaire
    };

    static settingProducts = {
        name: ACTIONS.param_produits,
        title: "Produits",
        url: `${APP_BASE_URL.parametres.url}/${ACTIONS.param_produits}`,
        base_url: APP_BASE_URL.parametres.url,
        sidebarTitle: APP_BASE_URL.parametres.title,
        topBar2Title: APP_BASE_URL.parametres.title,
        icon: <WindowsOutlined />,
        component: Produits
    };

    static settingUsers = {
        name: ACTIONS.param_users,
        title: "Utilisateurs",
        url: `${APP_BASE_URL.parametres.url}/${ACTIONS.param_users}`,
        base_url: APP_BASE_URL.parametres.url,
        sidebarTitle: APP_BASE_URL.parametres.title,
        topBar2Title: APP_BASE_URL.parametres.title,
        icon: <WindowsOutlined />,
        component: Utilisateurs
    }
}

export const AppRoutes = [
    {
        key: "subscription",
        name: "subscription",
        title: "Souscription autos",
        baseUrl: "/souscription",
        icon: <WindowsOutlined />,
        disabled: false,
        visibility: true,
        children: [
            {
                key: "400",
                name: "400", 
                title: "Categorie 400", 
                disabled: false,
                visibility: true,
                url: "/souscription/400",
                component: Subscription
            },
            {
                key: "401",
                name: "401", 
                title: "Categorie 401", 
                disabled: false,
                visibility: true,
                url: "/souscription/401",
                component: Subscription
            },
            {
                key: "402",
                name: "402", 
                title: "Categorie 402", 
                disabled: false,
                visibility: true,
                url: "/souscription/402",
                component: Subscription
            },
            {
                key: "403",
                name: "403", 
                title: "Categorie 403", 
                disabled: false,
                visibility: false,
                url: "/souscription/403",
                component: Subscription
            },

        ]
    }
]