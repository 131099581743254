import React from "react"

export const CardHeaderInfoSup = ()=> {
    return (
        <React.Fragment>
            <div style={{ height: 30, width: '100%', marginTop: -10 }}>
                <h4 style={{ color: 'grey' }}>Informations Complementatires</h4>
            </div>
        </React.Fragment>
    )
}

export default CardHeaderInfoSup;
