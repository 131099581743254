import WizardHeader from "modules/components/WizardHeader";
import React, { useState } from "react";
import * as helpers from 'helpers/helpers';
import StepDetails from "./steps/StepDetails";
import ErrorWidget from "components/Widgets/ErrorWidget";
import useInitAvenant from "../useInitAvenant";
import { Card, Spin } from "antd";
import { StepThankYou } from "./steps";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useBasket } from "views/pages/subscription/hooks/useBasket";
import StepListGuaranties from "./steps/StepListGuaranties";
import { StepThankYouProject } from "../../common/steps";

export default function AvenantExtensionRetraitGarantie() {
    const {contract, avenant, category, partnerCode } = useSelector((state) => state.subscription);
    const { currentStep, page, basket, transactionResponse, initAvenantResponse } = useSelector((state) => state.basketSlice);
    const { goToPreviousStep, updateBasket } = useBasket();
    const [quoting, setQuoting] = useState(true);
    const {state} = useInitAvenant();

    useEffect(()=> {
        console.log('::::: initAvenantResponse : ', initAvenantResponse)
    }, [state.isInitAvenantLoading])

    if(state?.isInitAvenantLoading) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if(state?.iniAvenantLoadingError) {
        return <ErrorWidget errorMessage={state?.iniAvenantLoadingErrorMessage} />;
    }

    const onValidate = (guaranties) => {
        // console.log('modelRef', modelRef)
        // console.log('state.models', state.models)
        // console.log('index', index)

        let basket = {}
        const formField = {
            ...state.basket.formField,
            guaranties: guaranties
        }

        const subscriptionRequest = {
            ...state.basket.subscriptionRequest,
            guaranties: guaranties
        }
        basket = {
            ...state.basket,
            formField,
            subscriptionRequest
        }
        let currentStep = state.currentStep;

        console.log('basket in updating --> ', basket)

        /* dispatch(mGoToNextStep([
            {path: ['mBasket'], value: {...basket}}
        ])) */

    }

    if(state.isInitAvenantLoading ) { //|| quoting
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
      }
    
    if(state.iniAvenantLoadingError) {
        return <ErrorWidget errorMessage={state.iniAvenantLoadingErrorMessage} />;
    }

    function renderChildren() {
        switch (currentStep) {
            case 0:
                return <StepListGuaranties
                    onValidate={onValidate}
                    basket={basket}
                    />;
            case 1:
                return <StepDetails
                  endDate={initAvenantResponse?.endDate}
                  subscriptionRequest={basket?.subscriptionRequest}
                  packSelected={basket?.packSelected}/>
            case 2:
                if (page === helpers.PAGES.THANK_YOU) {
                    return <StepThankYou transactionResponse={transactionResponse} />;
                }

                if (page === helpers.PAGES.PROJECT_THANK_YOU) {
                    // console.log('transactionResponse in index ---> ', transactionResponse)
                    return <StepThankYouProject transactionResponse={transactionResponse} />;
                }
                break
            default:
                return <ErrorWidget errorMessage="Ooops la page n'existe pas" />;
        }
    }

    return (

        <React.Fragment>
          <WizardHeader
              title={`Categorie ${category.code} : ${category.title}`}
              avenantTilte={`Avenant d'extension et reduction de garanties`}
              partnerCode={partnerCode}
              police={contract?.policeNumber}
              onClick={(currentStep > 0 && state.currentStep < 1) ? goToPreviousStep : null}
              percent={currentStep * 34}
              />
          {renderChildren()}
      </React.Fragment>
    )
}