import { Table } from "antd";
import React from "react";
import { Col, Row } from "reactstrap";


const MDetailListVehicle = ({ vehicle }) => {

    const columns = [
        {
            key: 'id',
            title: 'N°',
            dataIndex: 'id',
            width: 40,
            render: (_, vehicle) => (<div>1</div>)
        },
        {
            title: 'Marque',
            dataIndex: 'brand',
            key: 'brand',
            width: 150,
            render: (index, vehicle) => <div>{vehicle?.model?.brand?.title}</div>
        },
        {
            title: 'Modèle',
            dataIndex: 'model',
            key: 'model',
            width: 150,
            render: (index, vehicle) => <div>{vehicle?.model?.title}</div>
        },

        {
            title: 'Matricule',
            dataIndex: 'matriculation',
            render: (index, vehicle) => <div>{vehicle?.matriculation}</div>
        },
        {
            title: 'N° chassis',
            dataIndex: 'chassisNumber',
            key: 'chassisNumber',
            render: (index, vehicle) => <div>{vehicle?.chassisNumber}</div>
        },

        {
            title: 'Energy',
            dataIndex: 'energyTitle',
            key: 'energyTitle',
            render: (index, vehicle) => <div>{vehicle?.energy?.title}</div>
        },

        {
            title: 'Places',
            dataIndex: 'totalPlace',
            key: 'totalPlace',
            render: (index, vehicle) => <div>{vehicle?.totalPlace}</div>
        },
        {
            title: 'Puiss. fiscale',
            dataIndex: 'fiscalPower',
            key: 'fiscalPower',
            render: (index, vehicle) => <div>{vehicle?.fiscalPower}</div>
        },
        {
            title: 'Transport',
            dataIndex: 'formField',
            key: 'transportTitle',
            render: (index, vehicle) => <div>{vehicle?.transport?.title}</div>
        },

        {
            title: 'Valeur neuve',
            dataIndex: 'newValue',
            fixed: 'right',
            key: 'newValue',
            render: (index, vehicle) => <div>{vehicle?.newValue}</div>
        },

        {
            title: 'Valeur venale',
            dataIndex: 'marketValue',
            fixed: 'right',
            key: 'marketValue',
            render: (index, vehicle) => <div>{vehicle?.marketValue}</div>
        },

        {
            title: 'Ville de circulation',
            dataIndex: 'formField',
            fixed: 'right',
            key: 'cityTitle',
            render: (index, vehicle) => (
                <div>{vehicle?.city?.title}</div>
            )
        },

        /* {
            title: 'Zone de circulation',
            dataIndex: 'formField',
            key: 'cityTitle',
            render: (formField, basket)=> (
                <div>{"Cotonou"}</div>
            )
        }, */
    ];

    return (
        <React.Fragment>

            <Row>
                <div>
                    <Col lg={12}>

                        <Table
                            //loading={isFlotteQuotationLoading}
                            columns={columns}
                            dataSource={[vehicle]}
                            scroll={{
                                x: 1300,
                            }}
                        //pagination= {{ position: ["topRight"], pageSize: pageSize ? pageSize : 10}}
                        />

                    </Col>
                </div>
            </Row>
        </React.Fragment>
    );
}

export default MDetailListVehicle;