import { DatePicker } from "antd";
import moment from "moment";
import React from "react";
import { useState } from "react";
import * as helpers from 'helpers/helpers';

const EditCustomerDatePicker=({rangeDate, onDateChange})=> {
    const [open, setOpen] = useState(false);

    console.log("rangeDate EditCustomerDatePicker : ", new Date(rangeDate?.initialDate))
    return (
        <React.Fragment>
            <span
                onClick={() => setOpen(!open)}
                style={{cursor:"pointer"}}
            >
                <i className={"align-middle me-1  ri-edit-fill "}></i>{" "} 
                {
                    rangeDate?.initialDate && (
                        <DatePicker 
                            style={{ width:0, height: '100%', position: 'absolute', top: 0, left: 0, background: 'transparent', visibility: 'hidden' }} 
                            bordered={false}
                            format={"DD/MM/YYYY"} 
                            open={open}
                            onOpenChange={open => setOpen(open)}
                            defaultValue={moment(new Date(rangeDate?.initialDate)) }
                            onChange={onDateChange}
                            disabledDate={ rangeDate?.endDate ? helpers.disableDateRanges({
                                startDate: rangeDate?.initialDate, 
                                endDate: rangeDate?.endDate
                            }) : helpers.disabledPrevCustomerDate}  
                        />
                    )
                }
                
            </span>
        </React.Fragment>
    )
}

export default EditCustomerDatePicker;