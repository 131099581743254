import { FileDoneOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

const PaymentOnline=()=> {
    return (
        <React.Fragment>
            <Button
                    style={{ color: '#ffffff', borderRadius: 8, borderColor: '#9db7b4', backgroundColor: '#9db7b4', marginRight: 10, marginTop: 21 }}
                    // onClick={handleSubmit}
                    disabled={true}
                >
                    <FileDoneOutlined style={{ color: '#ffffff', fontSize: 20 }} /> PAIEMENT EN LIGNE
                </Button>
        </React.Fragment>
    )
}

export default PaymentOnline;