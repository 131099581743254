import { Col, Form } from "antd";
import { CATEGORY_403 } from "constantes";
import InputNumberFormatFr from "modules/components/InputNumberFormatFr/InputNumberFormatFr";
import React from "react";

const PayloadField=({categoryCode, disable, xs, md})=> {

    if(![CATEGORY_403].includes(categoryCode)) return null;
    
    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Charge utile (Tonnes)"
                    name="payload"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    <InputNumberFormatFr placeholder='Charge utile' min={1} disabled={disable} />
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default PayloadField;