import Basket from "api/model/basket";
import { ActionType } from "../helpers/helpers";
import QuotationRequestBuilder from "helpers/QuotationRequestBuilder";
import _ from "lodash";
import * as helpers from 'helpers/helpers';

export default class BasketSerivce {
    
    static addBasketOnFleet(newBasket, baskets) {
        //const packSelected = {...newBasket?.packSelected, key: newBasket?.packSelected?.key ?? helpers.randomString(8).toUpperCase()};
        return [...baskets, {...newBasket, id: _.size(baskets) + 1}];
    }

    static removeBasketFromFleet(basket, baskets) {
        const _baskets = baskets.filter((b)=> b?.id != basket?.id);
        return _baskets;
    }

    static updateBasketOnFleet(basket, baskets=[]) {
        console.log("::: Basket Before update : ", basket)
        console.log("::: Baskets Before update : ", baskets)
        const index = _.findIndex(baskets, {id: basket?.id})
        console.log(":::: Loadashindex", index)
        var _baskets = [...baskets];
        if(index != -1) {
            _baskets[index] = {...basket}
            //baskets.slice(index,1, basket)
        }
        console.log("::: Baskets Afeter update : ", baskets)
        return [..._baskets];
        
    }

    static buildQuotationRequest({contract, avenant, formFields, specificPackRef}) {
       
        console.log("::::: specificPackRef :", specificPackRef)
        const _quotationRequest = QuotationRequestBuilder.build({
            formFields: formFields, 
            contract: contract, 
            avenant: avenant, 
            packLineRef: specificPackRef
        });

        return _quotationRequest;
    }
}