import React, { useState } from 'react';
import { Card, Table, Tooltip } from 'antd';
import WizardBottomButton from 'modules/components/WizardBottomButton';
import { EditOutlined } from '@ant-design/icons';
import EditInfoVehicle from './EditInfoVehicle';
import { useBuildQuotationFlotte } from 'hooks/useQuotationFlote';
import SubscriptionRequestBuilder from "api/request/SubscriptionRequestBuilder";
import FlotteAutoBuilder from 'helpers/FlotteAutoBuilder';

function FStepEditInfoVehicle({ baskets, formFields, avenant, contract, goToNextStep }) {
    const [state, setState] = useState({
        visibleEdittingVehicle: false,
        baskets,
        currenFormFields: null,
        isBuildQuotationFlotteLoading: false,
    });

    const { buildQuotation } = useBuildQuotationFlotte();

    function handleGoToNextStep() {
        setState(prevState => ({
            ...prevState,
            isBuildQuotationFlotteLoading: true,
        }));

        buildQuotation({ baskets, avenant }, (data) => {
            const autos = baskets.map(basket => FlotteAutoBuilder.build({ formFields: basket.formFields, packSelected: basket.packSelected }));
            const flotteSubscriptionRequest = SubscriptionRequestBuilder.buildFlotte({ formFields: formFields, autos, avenant, contract, pack: { ...data } });

            goToNextStep([
                { path: ['flotteBasket', 'baskets'], value: [...state.baskets] },
                { path: ['flotteBasket', 'flotteSubscriptionRequest'], value: flotteSubscriptionRequest }
            ]);
        });
    }

    function toogleEditInfoVehicle(currenFormFields) {
        if (state.visibleEdittingVehicle && currenFormFields) {
            setState(prevState => {
                const index = prevState.baskets.findIndex(basket => basket.formFields.matriculation === currenFormFields.matriculation);
                const newBaskets = [...prevState.baskets];

                if (index >= 0) {
                    newBaskets[index].formFields = { ...currenFormFields };
                }

                return {
                    ...prevState,
                    visibleEdittingVehicle: !prevState.visibleEdittingVehicle,
                    baskets: newBaskets,
                    currenFormFields
                }
            });
        } else {
            setState(prevState => ({ ...prevState, visibleEdittingVehicle: !prevState.visibleEdittingVehicle, currenFormFields: currenFormFields }));
        }
    }

    const columns = [
        {
            title: 'Marque',
            dataIndex: 'formFields',
            key: 'brandTitle',
            render: formFields => formFields?.brandTitle
        },
        {
            title: 'Modèle',
            dataIndex: 'formFields',
            key: 'modelTitle',
            render: formFields => formFields?.modelTitle
        },
        {
            title: 'Matricule',
            dataIndex: 'formFields',
            key: 'matriculation',
            render: (formFields) => formFields?.matriculation
        },
        {
            title: 'N° chassis',
            dataIndex: 'formFields',
            key: 'chassisNumber',
            render: (formFields) => formFields?.chassisNumber
        },
        {
            title: 'Action',
            dataIndex: 'formFields',
            key: 'action',
            render: (formFields) => (
                <Tooltip title="Modifier">
                    <EditOutlined style={{ color: '#40AEE0', cursor: 'pointer' }} onClick={() => toogleEditInfoVehicle(formFields)} />
                </Tooltip>
            )
        }
    ];

    return (
        <>
            <Card>
                <Table
                    columns={columns}
                    dataSource={state.baskets}
                    pagination={{ position: ["topRight"] }}
                />

                <WizardBottomButton
                    btn1={{
                        label: "Suivant",
                        loading: state.isBuildQuotationFlotteLoading,
                        onClick: handleGoToNextStep
                    }}
                />
            </Card>

            {state.visibleEdittingVehicle && (
                <EditInfoVehicle visible={state.visibleEdittingVehicle} close={toogleEditInfoVehicle} formFields={state.currenFormFields} />
            )}
        </>
    )
}

export default FStepEditInfoVehicle;