import { Button, Col, Form, Input, Modal, Row, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import VehicleService from 'services/vehicleService';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';

const StepImmatriculation = ({basket, oldMAtriculation }) => {
    const {goToNextStep} = useBasket();

    const [form] = Form.useForm();
    const [state, setState] = useState({
        matriculationVerificationLoading: false,
        matriculationIsFound: false,
        isSubscriptionSending: false,
        modalVisibility: true
    });

    useEffect(()=> {
    console.log("::: basket ", basket) 
    }, [])

    //TODO-OK: <Verification de matricule>
    const onVerifyMatriculation = (matriculation) => {
        
        if (matriculation === undefined || matriculation === "")
            return;

        setState(state => ({ ...state, matriculationVerificationLoading: true }));
        VehicleService.verifyMatriculation({matriculation:matriculation}, (data) => {

            goToNextStep([
                {path: ['basket','subscriptionRequest', 'vehicle', 'matriculation'], value: matriculation}
            ])

            setState(state => ({
                ...state,
                matriculationIsFound: false,
                matriculationVerificationLoading: false,
                isSubscriptionSending: true,
                modalVisibility: false
            }));

        }, (error) => {
            setState(state => ({ ...state, grayField: true, matriculationIsFound: true, matriculationVerificationLoading: false, isSubscriptionSending: false }));
        })
    }

    const onSubmit = () => {
        form.validateFields().then((values) => {
            onVerifyMatriculation(values.newMatriculation)
        }).catch((error) => {

        })
    }

    return (
        <Modal
            title="Changement d'immatriculation"
            visible={state.modalVisibility}
            centered
            onOk={() => { }}
            onCancel={() => { }}
            footer={[]}
        >

            <Form
                name="basic"
                onFinish={onSubmit}
                layout="vertical"
                form={form}
            >
                <Row gutter={8} >
                    <Col className="gutter-row" xs={24} md={24}>
                        <Form.Item
                            label="Ancienne immatriculation"
                            name="oldMatriculation"
                            initialValue={oldMAtriculation}
                            rules={[
                                {
                                    required: true,
                                    message: 'Entrez l\'ancienne immatriculation svp !',
                                },
                            ]}
                        >
                            <Input placeholder="Ancienne immatriculation" disabled />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8} >
                    <Col className="gutter-row" xs={24} md={24}>
                        {/* <MatriculationField title={"Nouvelle immatriculation"}/> */}
                        <Form.Item
                            label="Nouvelle immatriculation"
                            name="newMatriculation"
                            rules={[{ required: true,message: 'Entrez la nouvelle immatriculation svp !'}]}
                        >
                            <Input
                                placeholder="Nouvelle immatriculation"
                                suffix={state.matriculationVerificationLoading ? (<Space><div>Verification...</div><Spin size='small' /></Space>) : null}
                            />
                        </Form.Item>
                        {
                            state.matriculationIsFound ? (<h5 style={{ color: 'red' }}>Le matricule existe déjà</h5>) : (<></>)
                        }
                    </Col>
                </Row>

                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e8e8e8',
                        padding: '10px 16px',
                        textAlign: 'right',
                        left: 0,
                        background: '#fff',
                        borderRadius: '0 0 4px 4px',
                    }}
                >
                    <Button
                        style={{
                            marginRight: 8,
                        }}
                        onClick={() => { setState(state => ({ ...state, modalVisibility: false })) }}
                    >
                        Annuler
                    </Button>
                    <Button type="primary" htmlType="submit" loading={state.matriculationVerificationLoading}>
                        Enregistrer
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}

export default StepImmatriculation;