import { LoadingOutlined } from "@ant-design/icons";
import { Select, Spin } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import React, { useState } from "react";
import { toast } from "react-toastify";
import CategoryService from "services/categoryService";

const AddPack=({category, packs, refreshPacksAndGuaranties})=> {
    const Option = Select;
    const [state, setState] = useState({
        packOthers: [],
        isPackLoading: false,
        packOthersSelected: {}
    })

    const attachPack=()=> {
        const options = {
            path: { 
                categoryRef: category.ref,
                packRef: state?.packOthersSelected?.ref
            }
        }
        CategoryService.attachPack(options, (data)=> {
            toast.success("Le pack a été ajouté avec succès")
            refreshPacksAndGuaranties(category)
        }, (error)=> {
            toast.error("Echec d'enregistrement de pack")
        })
    }

    const onOthersPackSelected=(packSelectedRef)=> {
        console.log(":::: packSelected :", packSelectedRef)
        const packOtherSelected = packs.find((pack)=> pack?.ref === packSelectedRef);
        setState(state=> ({...state, packOthersSelected: packOtherSelected}))
    }

    return (
        <React.Fragment>
            <div className="d-flex item-align-end flex-row mb-5" >
                <div style={{width: "100%"}}>
                    {
                        !state.isPackLoading ? (
                            <SelectWithFilter  placeholder='Selectionner un package' onChange={(e)=> onOthersPackSelected(e)} style={{width: "100%"}} size={"medium"}>
                                {packs.map((pack, index) => (<Option value={pack?.ref} key={index}>{pack?.title}</Option>))}
                            </SelectWithFilter>
                        ) : (<><Spin /> Chargement des packs</>)
                    }
                </div>
                <div style={{marginLeft: 5, height: "33px"}} >
                    <button
                        type="button"
                        className="btn btn-primary btn-label right nexttab btn-sm"
                        style={{height: "100%"}}
                        onClick={() => attachPack()}
                        disabled={state.isPackLoading}
                        onLoad
                    >
                        <i className="bx bx-plus label-icon align-middle fs-16 ms-2"></i>{" "}
                        {state.isPackGuaranteeAdding ? (<LoadingOutlined style={{ color: '#ffffff', fontSize: 20 }} />) : (<></>)}
                        Ajouter
                    </button>
                </div>
        </div>
        </React.Fragment>
    )
}

export default AddPack;