import { Col, Form } from "antd";
import InputNumberFormatFr from "modules/components/InputNumberFormatFr/InputNumberFormatFr";
import React from "react";

const SimpleInputNumber=({label, placeholder, name, format, required=false, disable, xs, md })=> {

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label={label}
                    name={name}
                    rules={[{ required: required, message: "Champ obligatoire" }]}
                >
                    <InputNumberFormatFr 
                        format={format} 
                        mask="_" placeholder={placeholder} min={1} disabled={disable} />
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default SimpleInputNumber