import { Button, Col, Form, Input, Modal, Row, Select, Space, Spin } from 'antd';
import SelectWithFilter from 'modules/components/CustomsSelects/SelectWithFilter';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import AvenantService from 'services/avenantService';
import PeriodService from 'services/periodService';
import * as constants from 'constantes';
import InitAvenantRequest from 'api/request/initAvenantRequest';
import InitAvenantResponse from 'api/response/initAvenantResponse';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';
import _ from 'lodash';

const StepProrogationPeriod = ({basket, periodTitle}) => {
    const {contract, avenant, assure, period } = useSelector((state) => state.subscription);
    const {updateGroup, goToNextStep} = useBasket();

    const [form] = Form.useForm();
    const { Option } = Select;

    const [state, setState] = useState({
        verificationLoading: false,
        modalVisibility: true,

        periods: [],
        isPeriodLoadingError: false,
        periodLoadingErrorMessage: ''
    });

    useEffect(() => {
        getPeriods();
    }, []);

    // LOAD Period
    const getPeriods = () => {
        (new PeriodService()).getAll((data) => {
            let _periods = data || [];
            _periods = _periods?.filter((_period, index) => (_period?.days) < 365 && (_period?.days >= 30));

            setState(state => ({ ...state, periods: _periods, isWidegetLoading: false }))
        }, (error) => {
            setState((state) => ({ ...state, isPeriodLoadingError: true, isWidegetLoading: false, widgetLoadingErrorMessage: error }))
        })
    }

    const onSubmit = () => {
        form.validateFields().then((values) => {
            const newPeriodSelected = state.periods.find((period, index) => period.ref === values?.newPeriodRef);
            if (newPeriodSelected) {

                setState(state=>({...state, verificationLoading: true}));
                const initAvenantRequest = InitAvenantRequest.fromJson({
                    productRef: constants.PRODUCT_AUTO_REF,
                    contractRef: contract?.ref,
                    avenantCode: avenant?.code,
                    assureCode: assure?.code,
                    periodRef: newPeriodSelected?.ref
                });

                AvenantService.init(initAvenantRequest,(responseData)=> {
                    const initAvenantResponse = (new InitAvenantResponse()).fromJson(responseData);
                    if(responseData) {
                        let _currentPack = _.first(initAvenantResponse?.quotationResponse?.packsResponse);
                        _currentPack.period = newPeriodSelected;
                        
                        const subscriptionRequest = {
                            ...basket.subscriptionRequest,
                            avenantRef  : avenant?.ref,
                            avenantTitle: avenant?.title,
                            contractRef : contract?.ref,

                            periodRef   : newPeriodSelected?.ref,
                            dateEffect  : initAvenantResponse?.startDate,
                            periodTitle : newPeriodSelected?.title,

                            primeTTC    : _currentPack?.primeTTC,
                            primeNette  : _currentPack?.primeNette,
                            primeExtra  : _currentPack?.primeExtra
                        }

                        updateGroup([
                            {path: ['basket', 'packSelected'], value: {...basket.packSelected, ..._currentPack, previousSelected: true}},
                            {path: ['basket', 'subscriptionRequest'], value: {...subscriptionRequest}},
                            {path: ['initAvenantResponse'], value: {...initAvenantResponse}},
                        ]);
                        goToNextStep(null);
                        setState(state=>({...state, verificationLoading: false, modalVisibility: false}));
                    }
                    else {
                        setState(state=>({...state, verificationLoading: false, periodLoadingErrorMessage: "Une erreur s'est produite"})); 
                    }
                    
                }, (error)=> {
                    setState(state=>({...state, verificationLoading: false, periodLoadingErrorMessage: error}));
                })
            }
            else {
                setState(state=>({...state, verificationLoading: false, periodLoadingErrorMessage: "Vous devez choisir une période"})); 
            }
        })
        .catch((error) => {
            console.log("E: " , error)
        })
    }

    return (
        <Modal
            title={`Prorogation du contrat n°${contract?.policeNumber}`}
            visible={state.modalVisibility}
            centered
            onOk={() => { }}
            onCancel={() => { }}
            footer={[]}
        >

            <Form
                name="basic"
                onFinish={onSubmit}
                layout="vertical"
                form={form}
            >
                <Row gutter={8} >
                    <Col className="gutter-row" xs={24} md={24}>
                        <Form.Item
                            label="Ancienne période"
                            //initialValue={data?.customPeriodTitle}
                            rules={[
                                {
                                    required: true,
                                    message: 'Entrez l\'ancienne periode svp !',
                                },
                            ]}
                        >
                            <Input placeholder="Ancienne période" value={periodTitle ?? period?.title} disabled />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8} >
                    <Col className="gutter-row" xs={24} md={24}>
                        <Form.Item

                            label="Periode additionnelle"
                            // label="Periode de couverture de l'assurance"
                            name="newPeriodRef"
                            rules={[{ required: true, message: "Champ obligatoire" }]}
                        >
                            <SelectWithFilter placeholder='Periode de couverture'>
                                {state.periods?.map((_period, index) => (<Option value={_period?.ref} key={index}>{_period?.title}</Option>))}
                            </SelectWithFilter>
                        </Form.Item>
                    </Col>
                </Row>
                <h5 style={{ color: "red", marginBottom: 10, textAlign: 'center' }}> {state?.periodLoadingErrorMessage}</h5><br />

                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e8e8e8',
                        padding: '10px 16px',
                        textAlign: 'right',
                        left: 0,
                        background: '#fff',
                        borderRadius: '0 0 4px 4px',
                    }}
                >
                    <Button
                        style={{
                            marginRight: 8,
                        }}
                        onClick={() => { setState(state => ({ ...state, modalVisibility: false })) }}
                    >
                        Annuler
                    </Button>
                    <Button type="primary" htmlType="submit" loading={state.verificationLoading}>
                        Enregistrer
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}

export default StepProrogationPeriod;