export default class Energy {
    id;
    ref;
    code;
    title;
    description;

    static fromJson = (json={})=> {
        var energy = new Energy();
        energy = json;
        return energy
    }
 }