import useInitFlotteAvenant from "../useInitFlotteAvenant";
import React from 'react';
import { FStepDetails, FStepThankYou } from "./steps";
import WizardHeader from "modules/components/WizardHeader";
import { Card, Spin } from "antd";
import ErrorWidget from "components/Widgets/ErrorWidget";
import { useSelector } from "react-redux";
import FStepChoiseVehicle from "./steps/FStepChoiseVehicle";
import * as helpers from 'helpers/helpers';
import { useBasket } from "views/pages/subscription/hooks/useBasket";

const FAvenantDuplicata= ()=> {
    const { avenant, contract, category, projectCode, partnerCode } = useSelector((state) => state.subscription);
    const { currentStep, baskets, page, fSubscriptionRequest, quotationResponse, transactionResponse, initAvenantResponse } = useSelector((state) => state.basketSlice);
    const { updateGroup } = useBasket()
    const { state } = useInitFlotteAvenant();

    if(state.avenantStatus?.loading) {
        return <Card><Spin /> Chargement et configuration en cours ...</Card>
    }

    if(state.avenantStatus?.error) {
        return <ErrorWidget errorMessage={state.avenantStatus?.errorMessage} />;
    }

    function renderChildren() {
        
        switch (currentStep) {
            case 0:
                return <FStepChoiseVehicle 
                            baskets={baskets} 
                            fSubscriptionRequest={fSubscriptionRequest}
                            startDate={initAvenantResponse?.startDate}
                            endDate={initAvenantResponse?.endDate} />
            case 1:
                return <FStepDetails 
                            avenantCode={avenant?.code}
                            quotationResponse={quotationResponse} 
                            fSubscriptionRequest={fSubscriptionRequest}/>;
            case 2:
                if(page === helpers.FlotteWidget.THANK_YOU) {
                    return <FStepThankYou transactionResponse={transactionResponse} />
                }
                break;
            default:
                return <ErrorWidget errorMessage="Ooops ! la page n'existe pas" />;
        }
    }

    return (
        <React.Fragment>
            <WizardHeader
                title={`Categorie ${category?.code} : ${category?.title}`}
                avenantTilte={`Avenant de duplicata`}
                partnerCode={partnerCode}
                police={contract?.policeNumber}
                percent={currentStep * 50}
                />
            {renderChildren()}
        </React.Fragment>
    )
}

export default FAvenantDuplicata;