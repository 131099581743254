import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class ExtraService {

    static createExtra(payload, sCallBack, eCallBack) {
        //exeRequest(ApiRoutes.createPackageResource, payload, sCallBack, eCallBack)
    }

    editExtra(payload, sCallBack, eCallBack) {
        //exeRequest(ApiRoutes.editPackageResource, payload, sCallBack, eCallBack);
    }

    static getAllExtras(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.extrasResource, {}, sCallBack, eCallBack)
    }

    static toggleStatus(options, sCallBack, eCallBack) {
        ApiRoutes.toggleStatusExtraResource.resourceOptions = Object.assign({}, ApiRoutes.toggleStatusExtraResource.resourceOptions, options);
        exeRequest(ApiRoutes.toggleStatusExtraResource, {}, sCallBack, eCallBack)
    }
}