import { EditOutlined, FileAddOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Select, notification, Row, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import CreateModelRequest from "api/request/createModelRequest";
import { toast } from "react-toastify";
import ActionType from "api/acionType";
import FormRegisterModel from "forms/addModelForm";
import FormEditModel from "forms/editModelForm";
import { Card } from "reactstrap";
import ModelService from "services/modelService";
import EditModelRequest from "api/request/editModelRequest";
import BrandService from "services/brandService";

export default function Model(props) {
    const [form] = Form.useForm();
    const [drawerVisibility, setDrawerVisibility] = useState({
        visibility: false,
        actionType: ActionType.REGISTER
    });

    const Option = Select;

    const openNotificationWithIcon = (type, msg) => {
        notification[type]({
            message: msg
        });
    };

    const [state, setState] = useState({
        models: [],
        dataSource: [],
        isModelLoading: false,
        isModelTableUpdateContent: false,

        isRegistingOrEditing: false,
        loading: false,
        updateLine: {},
        isLoad: false,
        itemId: null,

        brands: [],

        formRegisterModel: new FormRegisterModel(),
        formEditModel: new FormEditModel()
    });

    useEffect(() => {
        getAllModels();
        getAllBrands();
    }, [])

    const getAllModels = () => {
        setState(state => ({ ...state, isModelLoading: true }))
        ModelService.readAllModels((data) => {
            setState(state => ({ ...state, models: data || [], dataSource: data, isModelLoading: false, isModelTableUpdateContent: false }));
        }, (error) => { });

    }

    const getAllBrands = () => {
        BrandService.getAll({}, (data) => {
            setState(state => ({ ...state, brands: data || [] }));
        }, (error) => {
            setState(state => ({ ...state, isMarqueLoading: false }));
        });

    }

    const onDrawerOpen = (model, actionType) => {
        if (model) {
            const formEditModel = state.formEditModel.fromJson({
                id: model?.id,
                code: model?.code,
                title: model?.title,
                description: model?.description,
                // brandRef: model?.brandRef
            });
            setState(state => ({ ...state, formEditModel: formEditModel }))
            form.setFieldsValue({ ...form.getFieldsValue(), ...formEditModel });
        }
        else {
            form.setFieldsValue({ ...form.getFieldsValue(), ...state.formRegisterModel });
        }

        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: true, actionType: actionType }));
    }

    // TODO-OK: Ajout et edition d'une co-assurance
    const onRegisterOrEditModelSubmit = (action) => {
        setState(state => ({ ...state, loading: true }));
        form.validateFields().then((fields) => {
            switch (action) {
                case ActionType.REGISTER:
                    const _formRegisterFields = { ...state.formRegisterModel, ...fields };
                    let createModelRequest = CreateModelRequest.fromJson(_formRegisterFields);

                    ModelService.createModel(createModelRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, formRegisterModel: new FormRegisterModel(), isRegistingOrEditing: false, isModelTableUpdateContent: true }));
                        getAllModels();
                        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                        toast.success("Modèle ajouté avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        console.log("error");
                        toast.error(error);
                    });
                    break;

                case ActionType.EDIT:
                    const formEditFields = { ...state.formEditModel, ...fields };
                    let editModelRequest = EditModelRequest.fromJson(formEditFields);

                    new ModelService().editModel(editModelRequest, (data) => {
                        form.resetFields();
                        setState(state => ({ ...state, formEditModel: new FormEditModel(), isRegistingOrEditing: false, isUserTableUpdateContent: true }));
                        getAllModels();
                        setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                        toast.success("Modèle modifié avec succès", { style: { background: 'primary' } });
                    }, (error) => {
                        toast.error(error);
                    });
                    break;

                default:
                    setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false }));
                    toast.error("Une erreur est survenue, veuillez réesayer");
                    break;
            }
        }).catch((error) => {
            console.log("error", error);
            toast.error("Modèle non enregistré");
        })
        setState(state => ({ ...state, loading: false }));
    }

    //TODO-OK: <Recherche d'un co assureur>
    function onSearchModel(e) {
        const val = e?.target?.value?.toLowerCase();
        const temp = state.models?.filter((d) => {
            return d?.code?.toLowerCase().indexOf(val) !== -1 ||
                d?.title?.toLowerCase().indexOf(val) !== -1 ||
                !val
        });
        setState(state => ({ ...state, dataSource: temp }))
    }

    const onSwitchChange = (model) => {
        console.log("model", model);
        const option = { path: { id: model?.id } }
        ModelService.toggleStatus(option, (data) => {
            toast.success("Package modifié avec succès", { style: { background: 'primary' } });
            getAllModels();
        }, (error) => {
            console.log("error", error);
            toast.error("Une erreur est survenue");
        })
    }

    const columns = [
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            render: (code) => (
                <div style={{ fontWeight: 'bold' }}>
                    {code}
                </div>
            )
        },
        {
            title: "Titre",
            dataIndex: "title",
            key: "libelle"
        },
        {
            title: "Marque",
            dataIndex: "brand",
            key: "brand",
            render: (brand) => (
                <div>
                    {brand?.title}
                </div>
            )
        },
        {
            title: "Etat",
            dataIndex: "status",
            key: "status",
            render: (item, model) => (
                <div>
                    <Switch defaultChecked={model.status} size="small" onChange={() => onSwitchChange(model)} />
                </div>
            )
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (model) => (
                <>
                    <EditOutlined style={{ color: '#40E070', cursor: 'pointer' }} onClick={() => onDrawerOpen(model, ActionType.EDIT)} />
                </>

            )
        }
    ];

    return (
        <Card>
            <div style={{ padding: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Liste des modèles</h4>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Input placeholder="Recherche: Code, titre" style={{ width: 350, marginRight: 10 }} onChange={onSearchModel} />
                        {/* <Input placeholder="Recherche: Modèle" style={{ width: 350, marginRight: 10 }} /> */}
                        <Button type="primary" onClick={() => onDrawerOpen(null, ActionType.REGISTER)} style={{ marginLeft: 10 }}>
                            <FileAddOutlined />
                            Ajouter un modèle
                        </Button>
                    </div>
                </div>
                <br />
                <Table
                    dataSource={state.dataSource}
                    columns={columns}
                    loading={state.isModelTableUpdateContent || state.isModelLoading}
                />

                {drawerVisibility.visibility && (
                    <Drawer
                        title={drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrement d'un modèle" : "Modifier le modèle"}
                        width={720}
                        onClose={() => { setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false })) }}
                        visible={drawerVisibility.visibility}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                    >
                        <Form
                            name="basic"
                            onFinish={() => onRegisterOrEditModelSubmit(drawerVisibility.actionType)}
                            layout="vertical"
                            form={form}
                            initialValues={drawerVisibility.actionType === ActionType.REGISTER ? state.formRegisterModel : state.formEditModel}
                        >

                            {/* <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Choisissez la marque"
                                        name="brandRef"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'La marque svp !',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Choix de la marque">
                                            {
                                                state.brands.map((brand, index) => (
                                                    <Option key={index} value={brand?.ref}>{brand?.title}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row> */}

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Code du modèle"
                                        name="code"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Le code du modèle svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Le code du modèle" size="middle" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Nom du modèle"
                                        name="title"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Le nom du modèle svp !',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Le nom du modèle" size="middle" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={8} >
                                <Col className="gutter-row" xs={24} md={24}>
                                    <Form.Item
                                        label="Description du modèle"
                                        name="description"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'La description svp !',
                                            },
                                        ]}
                                    >
                                        <TextArea placeholder='Description du modèle' size="middle" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    borderTop: '1px solid #e8e8e8',
                                    padding: '10px 16px',
                                    textAlign: 'right',
                                    left: 0,
                                    background: '#fff',
                                    borderRadius: '0 0 4px 4px',
                                }}
                            >
                                <Button
                                    style={{
                                        marginRight: 8,
                                    }}
                                    onClick={() => { setDrawerVisibility(drawerVisibility => ({ ...drawerVisibility, visibility: false })); }}
                                >
                                    Annuler
                                </Button>
                                <Button type="primary" htmlType="submit" loading={state.loading}>
                                    {drawerVisibility.actionType === ActionType.REGISTER ? "Enregistrer" : "Editer"}
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                )}
            </div>
        </Card>
    )
}