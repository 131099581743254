import React from "react";
import {  Col, Form } from "antd";
import InputNumberFormatFr from "modules/components/InputNumberFormatFr/InputNumberFormatFr";
import { CATEGORY_405, CATEGORY_406 } from "constantes";

const MarketValueField=({ categoryCode, form, max, disable, xs, md})=> {
    
    if([CATEGORY_405, CATEGORY_406].includes(categoryCode)) return null;

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label={<>Valeur venale (<i>Minimal: 1 000 000 F CFA</i>)</>}
                    name="marketValue"
                    rules={[
                        { required: true, message: "Champ obligatoire" },
                    ]}
                >
                    <InputNumberFormatFr 
                        placeholder='Valeur venale'
                        //main={1500000} 
                        min={1000000}
                        
                        //onBlur={(e) => { onChange(e.target.value); }} 
                        disabled={disable} />
                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default MarketValueField;