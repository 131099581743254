export const date_r = function formatDate(date) {
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
};

export const fullFormatDate =(date, dateSeperator, showHours=true)=> {
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();    
    var  hour = "" + ((d.getUTCHours() === 0 || d.getUTCHours() === 23) ? d.getUTCHours() : d.getHours()),
        minutes ="" + ((d.getUTCMinutes() === 0 || d.getUTCMinutes() === 59) ? d.getUTCMinutes() : d.getMinutes()),
        secondes = "" + (d.getUTCSeconds() === 0 ? d.getUTCSeconds() :  d.getSeconds());
    
        // Ajuster le fuseau horaire
        if(d.getUTCHours() >= 23) {
            d.setDate(d.getDate()-1);
            month = "" + (d.getMonth() + 1);
            day = "" + d.getDate();
            year = d.getFullYear();
        }
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hour.length < 2) hour = "0" + hour;
    if (minutes.length < 2) minutes = "0" + minutes;
    if (secondes.length < 2) secondes = "0" + secondes;

    const times = [hour, minutes, secondes].join(":");
    const dates = [day, month, year].join(dateSeperator ?? "/");
    return `${dates} ${showHours ? " à " + times : ""}`;
};