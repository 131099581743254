import React from 'react';
import { FStepEditInfoVehicle, FStepThankYou } from './steps';
import FlotteStepAssureDetails from "../../common/steps/FlotteStepAssureDetails";
import useInitFlotteAvenant from '../useInitFlotteAvenant';
import * as constants from 'constantes'
import ErrorWidget from 'components/Widgets/ErrorWidget';
import { Spin } from 'antd';
import WizardHeader from 'modules/components/WizardHeader';

export default function FAvenantChangementVehicule({ data }) {
    const { state, goToNextStep, goToPreviousStep } = useInitFlotteAvenant(data);

    console.log('FAvenantChangementVehicule', state);

    function renderChildren() {
        switch (state.currentStep) {
            case 0:
                return (
                    <FStepEditInfoVehicle
                        goToNextStep={goToNextStep}
                        baskets={state.flotteBasket.baskets}
                        formFields={state.flotteBasket.formFields}
                        contract={state.contract}
                        avenant={state.avenant}
                    />
                );

            case 1:
                return (
                    <FlotteStepAssureDetails
                        goToNextStep={goToNextStep}
                        goToPreviousStep={goToPreviousStep}
                        data={state}
                        showAssureDetails={true}
                        showPrimeDetails={true}
                    />
                );

            case 2:
                return <FStepThankYou data={state} />;
        }
    }

    return (
        <>
            {
                state.currentStep !== 3 ? (
                    <WizardHeader
                        title={`Categorie ${state?.category?.code} : ${state?.category?.title}`}
                        avenantTilte={state.avenant?.code === constants.CODE_AVENANTS.CREATION ? "Nouvelle affaire" : `Avenant de ${state.avenant?.title}`}
                        onClick={(state.currentStep > 0 && state.currentStep < 4) ? goToPreviousStep : null}
                        percent={state.currentStep * 33}
                    />
                ) : <></>
            }
            {state.isInitAvenantLoading ? <><Spin /> Chargement ...</> : (state.iniAvenantLoadingError ? <><ErrorWidget errorMessage={state.iniAvenantLoadingErrorMessage} /></> : renderChildren())}
        </>
    )
}