import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Card, Tooltip, Button, Checkbox } from 'antd'
import CustomAntdForm from 'modules/components/Forms/CustomAntdForm';
import { GiftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import VehicleCardHeader1 from 'views/pages/subscription/common/VehicleCarHeader1';
import Header1 from 'views/pages/subscription/common/Header1';
import { useSelector } from 'react-redux';
import { CityField, DriverLicField, EnergyField, FiscalPowerField, GpsField, GroupementField, GroupementTypeField, MarketValueField, MecField, NewValueField, PeriodField, PlaceField, SocialPositionField, TransportField } from 'views/pages/subscription/common/fields';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';
import DriverBirthField from 'views/pages/subscription/common/fields/DriverBirthdayField';
import PayloadField from 'views/pages/subscription/common/fields/PayloadField';
import InflammableField from 'views/pages/subscription/common/fields/InflammableField';
import {  CATEGORY_403, CATEGORY_405, CATEGORY_406, CATEGORY_407, CATEGORY_408, CATEGORY_409, CATEGORY_410 } from 'constantes';
import CylinderField from 'views/pages/subscription/common/fields/CylinderField';

const StepQuotationForm = ({ basket }) => {
    const { category } = useSelector((state) => state.subscription);
    const { updateFormField, updateQuotationRequest, goToNextStep } = useBasket();

    const [form] = Form.useForm();
    const [toogleGroupement, setToogleGroupement] = useState(basket ? basket?.formField?.hasGroup : false);
    const [maxMarketValue, setMaxMarketValue] = useState(null);
    const [state, setState] = useState({
        errorBag: { error: false, errorMessage: "" }
    });

    useEffect(()=> {

    }, [])

    const validateStepForm = () => {
        form.validateFields()
            .then(values => {
                updateFormField(values);
                //updateQuotationRequest();
                goToNextStep(null)
            })
            .catch(errorInfo => {
                console.log(errorInfo)
                setState(state => ({ ...state, errorBag: { error: true, errorMessage: errorInfo } }));
            });
    }

    const toggleGroupementCheckBox=(e)=> {
        const checked = e.target.checked; 
        setToogleGroupement(checked);

        if(!checked) {
            updateFormField({
                hasGroup: false,
                groupementRef: null,
                groupementTitle: ""
            });
        }
    }

    const cardHeaderspecial = (
        <div style={{ height: 30, width: '100%', marginTop: -10 }}>
            <h4 style={{ color: 'grey' }}>Informations Complementatires</h4>
        </div>
    );

    const cardHeader3 = (
        <div style={{ height: 30, width: '100%', marginTop: -10, display: 'flex' }}>
            <h4 style={{ color: 'grey', marginRight: 3 }}>Packs</h4>
            <Tooltip placement="right" title={Header1} AutoAdjustOverflow={true}>
                <InfoCircleOutlined style={{ color: '#0075C9', marginLeft: 10, marginTop: 7 }} />
            </Tooltip>
            {state?.errorBag?.error ? (<div style={{ color: "red" }}> Corriger les données invalides saisies</div>) : null}
        </div>
    );

    return (
        <React.Fragment>
            <CustomAntdForm form={form} initialValues={basket.formField}>
                <Card
                    title={<>{VehicleCardHeader1()}</>}
                    headStyle={{ backgroundColor: 'rgba(233,233,233, 0.5)', color: '#ffffff', height: 15, boxShadow: "0px 0.5px 0.5px gray", }}
                >
                    <Row gutter={8} >
                        <TransportField form={form} categoryCode={category?.code} categoryRef={category?.ref} handlerFormField={updateFormField} />
                        <EnergyField categoryCode={category?.code} handlerFormField={updateFormField} />
                        {basket?.formField.motor !== 'NEANT' ? (<CityField categoryCode={category?.code} handlerFormField={updateFormField} />) : null}
                        <MecField categoryCode={category?.code}/>
                        {basket?.formField.motor !== 'NEANT' ? (<PlaceField categoryCode={category?.code}/>) : null}
                        {basket?.formField.motor !== 'NEANT' ? (<FiscalPowerField categoryCode={category?.code} form={form} handlerFormField={updateFormField}  />) : null}
                        {!["MCY"].includes(basket?.formField?.transportCode) ? <CylinderField categoryCode={category?.code}/> : null}
                        <NewValueField  categoryCode={category?.code} form={form}  onBlur={setMaxMarketValue} />
                        <MarketValueField categoryCode={category?.code} max={maxMarketValue} />
                        <PayloadField categoryCode={category?.code}/>
                        <InflammableField categoryCode={category?.code}/>
                        <SocialPositionField categoryCode={category?.code} handlerFormField={updateFormField} />
                        <PeriodField handlerFormField={updateFormField} month={[CATEGORY_405].includes(category?.code) ? 12 : null}/>
                    </Row>
                </Card>

                <Card
                    title={cardHeaderspecial}
                    headStyle={{ backgroundColor: 'rgba(233,233,233, 0.5)', color: '#ffffff', height: 15, boxShadow: "0px 0.5px 0.5px gray", }}
                >

                    <Row gutter={8} >
                        <GpsField />
                        <DriverBirthField categoryCode={category?.code} />
                        <DriverLicField categoryCode={category?.code}/>
                        {![CATEGORY_403,CATEGORY_406, CATEGORY_407, CATEGORY_408, CATEGORY_409, CATEGORY_410].includes(category?.code) ? (<Col className="gutter-row" xs={12} md={6}></Col>): null}
                        <Col className="gutter-row" xs={12} md={6}><Checkbox defaultChecked={toogleGroupement} onChange={(e) => toggleGroupementCheckBox(e)} disabled={false} >Faites-vous partie d'un groupement ?</Checkbox></Col>
                        {toogleGroupement || basket?.formField?.hasGroup ? (
                                <>
                                    <GroupementTypeField handlerFormField={updateFormField} />
                                    <GroupementField groupementTypeRef={basket?.formField?.groupementTypeRef} handlerFormField={updateFormField} />
                                </>
                            ) : null}

                    </Row>
                </Card>
                <Card
                    title={cardHeader3}
                    headStyle={{ backgroundColor: 'rgba(233,233,233, 0.5)', color: '#ffffff', height: 15, boxShadow: "0px 0.5px 0.5px gray", }}
                >

                    <div style={{ padding: 20, display: 'flex', justifyContent: 'end' }}>
                        <Button
                            style={{ color: '#ffffff', borderRadius: 8, backgroundColor: '#005680', marginTop: 21, border: '1px solid #005680' }}
                            onClick={validateStepForm}
                        >
                            <GiftOutlined style={{ color: '#ffffff', fontSize: 20 }} /> AFFICHER LES PACKS
                        </Button>
                    </div>
                </Card>
            </CustomAntdForm>
        </React.Fragment>
    )
}

export default StepQuotationForm;