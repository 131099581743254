import ApiRoutes from "api/apiRoutes";
import { exeRequest, exeRequestToDel } from "../http/requestConfig";

export default class TransportService {
    
    getByCategoryRef(categoryRef, sCallBack, eCallBack) {
        let options = {
            path: {categoryRef: categoryRef}
        }
        ApiRoutes.transportResource.resourceOptions = Object.assign({}, ApiRoutes.transportResource.resourceOptions, options);
        exeRequest(ApiRoutes.transportResource, {}, sCallBack, eCallBack)
    }
}