import WizardHeader from "modules/components/WizardHeader";
import React, { useEffect } from "react";
import * as helpers from 'helpers/helpers';
import { StepQuotationForm, StepAssureContract, StepDetails, StepPackages, StepThankYou } from "./steps";
import { StepThankYouProject } from "../../common/steps";
import ErrorWidget from "components/Widgets/ErrorWidget";
import useInitAvenant from "../useInitAvenant";
import { Card, Spin } from "antd";
import { useSelector } from "react-redux";
import { useBasket } from "views/pages/subscription/hooks/useBasket";

export default function AvenantRenouvellementAvecModification() {
  const { contract, avenant, category, partnerCode } = useSelector((state) => state.subscription);
  const { currentStep, page, basket, transactionResponse, initAvenantResponse } = useSelector((state) => state.basketSlice);
  const { updateFormField, goToPreviousStep } = useBasket()

  const { state } = useInitAvenant()

  useEffect(()=> {
    updateFormField({
      categoryRef: category?.ref
    })

  }, []);

  if (state?.isInitAvenantLoading) {
    return <Card><Spin /> Chargement et configuration en cours ...</Card>
  }

  if (state?.iniAvenantLoadingError) {
    return <ErrorWidget errorMessage={state?.iniAvenantLoadingErrorMessage} />;
  }

  function renderChildren() {
    switch (currentStep) {
      case 0:
        return <StepQuotationForm basket={basket} />;
      case 1:
        return <StepPackages basket={basket} />;
      case 2:
        return <StepAssureContract basket={basket} />;
      case 3:
        return <StepDetails
                  avenantCode={avenant?.code}
                  initAvenantResponse={initAvenantResponse}
                  subscriptionRequest={basket?.subscriptionRequest}
                  packSelected={basket?.packSelected}
                />
      case 4:
        if (page === helpers.PAGES.THANK_YOU) {
          return <StepThankYou transactionResponse={transactionResponse} />;
        }
      
        if (page === helpers.PAGES.PROJECT_THANK_YOU) {
          return <StepThankYouProject transactionResponse={transactionResponse} />;
        }
        break;
      default:
        return <ErrorWidget errorMessage="Ooops ! la page n'existe pas" />;
    }
  }

  return (

    <React.Fragment>
      <WizardHeader
        title={`Categorie ${category.code} : ${category.title}`}
        avenantTilte={`Avenant de ${avenant?.title}`}
        partnerCode={partnerCode}
        police={contract?.policeNumber}
        onClick={(currentStep > 0 && currentStep < 4) ? goToPreviousStep : null}
        percent={currentStep * 25}
      />

      {renderChildren()}
    </React.Fragment>
  )
}