import React from 'react';
import { Spin } from "antd";
import { useEffect, useState } from "react";
import * as helpers from 'helpers/helpers';
import useInitFlotteAvenant from "../useInitFlotteAvenant";
import { DetailsParcoursFlotte } from './components';
import ErrorWidget from 'components/Widgets/ErrorWidget';
import FSubscriptionWidget from "views/pages/subscription/flotte/common/FSubscriptionWidget";
import { FStepThankYou } from '../nouvelle_subscription/steps';

export default function ResiliationAvecRistourne({ data }) {
    const { state: { flotteBasket, avenant, contract, isInitAvenantLoading, iniAvenantLoadingError: initAvenantLoadingError } } = useInitFlotteAvenant(data);

    const [state, setState] = useState({
        currentStep: 0,
        flotteBasket: null,
        contract: null,
        transactionResponse: null,
    });

    function goToNextStep(data) {
        helpers.goToNextStep(data, setState);
    }

    function goToPreviousStep(data) {
        helpers.goToPreviousStep(data, setState);
    }

    useEffect(() => {
        if (!isInitAvenantLoading) {
            setState(prevState => ({
                ...prevState, flotteBasket, avenant, contract
            }));
        }
    }, [isInitAvenantLoading]);

    if (isInitAvenantLoading || !state.flotteBasket) {
        return <><Spin /> Chargement ...</>
    } else if (initAvenantLoadingError) {
        return <ErrorWidget errorMessage={initAvenantLoadingError} />
    }

    switch (state.currentStep) {
        case 0: {
            return (
                <>
                    <FSubscriptionWidget
                        primeTTCHorsFlotte={helpers.getAmounts(state.flotteBasket?.baskets?.map(basket => basket?.packSelected?.primeTTC))}
                        nbVehicle={state.flotteBasket?.baskets?.length}
                        flotteRate={state.flotteBasket?.flotteSubscriptionRequest.flotteRate}
                    />

                    <DetailsParcoursFlotte
                        goToNextStep={goToNextStep}
                        goToPreviousStep={goToPreviousStep}
                        contract={contract}
                        avenant={avenant}
                        flotteBasket={state.flotteBasket}
                    />
                </>
            );
        }

        case 1: {
            return (
                <FStepThankYou
                    data={{ transactionResponse: state.transactionResponse }}
                />
            );
        }
        default:
            return (
                <>
                    <FSubscriptionWidget
                        primeTTCHorsFlotte={helpers.getAmounts(state.flotteBasket?.baskets?.map(basket => basket?.packSelected?.primeTTC))}
                        nbVehicle={state.flotteBasket?.baskets?.length}
                        flotteRate={state.flotteBasket?.flotteSubscriptionRequest.flotteRate}
                    />

                    <DetailsParcoursFlotte
                        goToNextStep={goToNextStep}
                        goToPreviousStep={goToPreviousStep}
                        contract={contract}
                        avenant={avenant}
                        flotteBasket={state.flotteBasket}
                    />
                </>
            );
    }
}