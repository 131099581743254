import { Form, Select } from "antd"
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter"
import React,  { useState } from "react"
import { useEffect } from "react"
import FiscalPowerService from "services/fiscalPowerService"

const FiscalPowerField=({categoryCode, fiscalPowers})=> {
    const Option = Select;
    const [state, setState]=useState({
        fiscalPowers: fiscalPowers ?? [],
        isFiscalPowersLoading: false
    })

    useEffect(()=> {
        if(!fiscalPowers) {
            getFiscalPowers()
        }
    }, [])

    const getFiscalPowers=()=> {
        setState(state=> ({...state, isFiscalPowersLoading: true}));
        const options = {
            path: {categoryCode: categoryCode}
        }
        FiscalPowerService.getAll(options, (data)=> {
            const _fiscalPowers = data || [];
            setState(state=> ({...state, fiscalPowers: _fiscalPowers, isFiscalPowersLoading: false}))
        }, (error)=> {
            setState(state=> ({...state, isFiscalPowersLoading: false}))
        })
    }

    return (
        <React.Fragment>
            <Form.Item
                label="Puissance fiscale."
                name="fiscalPowerRef"
                rules={[{ required: false, message: "Champ obligatoire" }]}
            >
                <SelectWithFilter size='medium' placeholder="Selectionner" suffixIcon={"Puissance fiscale. :"}>
                    {state.fiscalPowers.map((fiscalPower, index)=> (<Option value={fiscalPower?.ref} key={index}>{fiscalPower?.criterion}</Option>))}
                </SelectWithFilter>
            </Form.Item>
        </React.Fragment>
    )
}

export default FiscalPowerField;