import React from "react";
import { Card } from "reactstrap";


export default function Coupon(props) {


    return (
        <div>
             <Card>
                <div style={{padding:20}}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>Liste des coupons</h4>
                    </div>

                    <br/>
                <br/>
                <p>En operation bientôt !</p>
                </div>
            </Card>
        </div>
    )
}