import React, { useState } from "react";
import { AutoComplete, Input } from "antd";
import Assure from "api/model/assure.model";
import AssureService from "services/assureService";
import ReactBtn from "../../component/Common/ReactBtn";

const AssureSearchField= ({form, formField, handlerFormField, newBtnVisibility})=> {
    const [options, setOptions] = useState([]);
    const [defaultAssure, setDefaultAssure] = useState({});
   
    // TODO-OK: <La recherche en 360°>
    const handleSearchAssure = (word) => {
        if(word === null || word === undefined || word === '' ) {
            const assure = {
                assureCode      : defaultAssure?.assureCode,
                assureFirstname : defaultAssure?.assureFirstname,
                assureLastname  : defaultAssure?.assureLastname,
                assurePhone     : "00-00-00-00",
                assureIdentityCardNumber: defaultAssure?.assureIdentityCardNumber,
                assureIdentityCardRef: defaultAssure?.assureIdentityCardRef,
                assureIdentityCardTitle: defaultAssure?.assureIdentityCardTitle,
                assureEmail     : defaultAssure?.assureEmail,
                isProspect      : defaultAssure?.isProspect
            }
            form.setFieldsValue({...assure});
            handlerFormField(assure)
            setOptions([])
        };

        const options = {
            query: {word: word, page: 0, size: 10}
        }

        AssureService.seachableAssure(options,(data)=> {
            const _assureSearchResult = (data?.content || []).map((assure)=> (
                {
                    item: assure,
                    value: `${assure.firstName} ${assure.lastName}`,
                    label: (
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span> { assure.firstName + " " +  assure.lastName}  </span>
                            <span> { assure.phone }</span>
                        </div>
                    )
                }
            ));
            setOptions(_assureSearchResult);
        }, (error)=> {
            setOptions([])
        }); 
    };

    const onAssureSearchSelected = (item) => {
        if (item == null) return;
    
        const _assure = Assure.fromJson(item);
        const assure = {
            assureCode      : _assure?.code,
            assureFirstname : _assure?.firstName,
            assureLastname  : _assure?.lastName,
            assurePhone     : _assure?.phone,
            assureIdentityCardNumber: _assure?.identityCardNumber,
            assureIdentityCardRef: _assure?.identityCardRef,
            assureIdentityCardTitle: _assure?.identityCardTitle,
            assureEmail     : _assure?.email,
            isProspect      : _assure?.prospect
        }
        form.setFieldsValue({...assure});        
        handlerFormField(assure)
    }

    const onNewAssure=()=> {
        const _assure = {
            assureCode      : null,
            assureFirstname : null,
            assureLastname  : null,
            assurePhone     : "00-00-00-00",
            assureIdentityCardNumber: null,
            assureIdentityCardRef: null,
            assureIdentityCardTitle: null,
            assureEmail     : null,
            isProspect      : true
        };
        form.setFieldsValue({..._assure});        
        handlerFormField({..._assure})
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex', marginLeft: 20 }}>
                <AutoComplete
                    dropdownMatchSelectWidth={400}
                    style={{width: 400}}
                    options={options}
                    onSelect={(e, itemSelected)=> {
                        onAssureSearchSelected(itemSelected.item)
                    }}
                    onSearch={handleSearchAssure}
                >
                    
                    <Input.Search size="large" placeholder='Recherche : Code assuré, nom et prénom, Email, Telephone' enterButton />
                </AutoComplete>
                {newBtnVisibility && (<ReactBtn title={"Nouveau assuré"} icon={'plus'} onClickHandler={onNewAssure}/>)}
                
            </div>
        </React.Fragment>
    )
}

export default AssureSearchField;