//========================
//===== LIST ACTIONS =====
//========================
export const ACTIONS = {
    souscription        : 'subscription',
    souscription_cat_400: '400',
    souscription_cat_401: '401',
    souscription_cat_402: '402',
    souscription_cat_403: '403',
    souscription_cat_404: '404',
    souscription_cat_405: '405',
    souscription_cat_406: '406',
    souscription_cat_407: '407',
    souscription_cat_408: '408',
    souscription_cat_409: '409',
    souscription_cat_410: '410',
    details_souscription: 'details-souscription',
    details_souscription_assure: 'details-souscription-assure',
    liste_commissions: 'list-commissions',
    list_souscription_groupement: 'list-souscription-groupement',
    list_co_assurance: 'list-co-assurance',
    reporting_liste_vehicules: 'liste-des-vehicules',
    reporting_bordereau_des_emissions: 'bordereau-des-emissions',
    reporting_bordereau_des_expirations: 'bordereau-des-expirations',
    reduction_commerciale: "reduction-commerciale",

    parametres: 'parametres',
    param_agences: 'agences',
    param_garanties: 'garanties',
    param_packageGarantie: 'packageGarantie',
    param_commission: 'commission',
    detail_parametre: 'detail_parametre',
    param_co_assurance: 'co-assurance',
    param_gestion_attestation: 'gestion_attestation',

    param_categories: 'categories',
    param_produits: 'produits',
    param_coupons: 'coupons',
    param_groupement: 'groupement',
    param_marques: 'marques',
    param_modele: 'modele',
    param_package: 'package',
    param_partenaires: 'partenaires',
    param_users: 'utilisateurs',
    param_reduction: 'reduction'
};

//========================
//===== APP PROFILES =====
//========================
export const PROFILE_SU = 'PROFILE_SU';
export const PROFILE_SU_ADMIN = 'PROFILE_SU';
export const PROFILE_ADMINISTATOR = 'PROFILE_ADMIN';
export const PROFILE_ADMIN_CENTRAL = 'PROFILE_ADMIN_CENTRAL';
export const PROFILE_SUPERVISOR = 'PROFILE_SUPERVISOR';
export const PROFILE_PRODUCTOR_CENTRAL = "PROFILE_PRODUCTOR_CENTRAL";
export const PROFILE_PRODUCTOR = "PROFILE_PRODUCTOR";
export const PROFILE_AGENCY = "PROFILE_AGENCY";
export const PROFILE_PARTNER = "PROFILE_PARTNER";

//========================
//===== GROUPE PROFILE ===
//========================

export const CAN_PRODUCT_FOR_OTHER = [
    PROFILE_ADMINISTATOR,
    PROFILE_SU_ADMIN,
    PROFILE_SU,
    PROFILE_ADMIN_CENTRAL,
    PROFILE_PRODUCTOR_CENTRAL
]

export const CAN_REGENERATE_ATTESTATION = [
    PROFILE_ADMINISTATOR,
    PROFILE_SU_ADMIN,
    PROFILE_SU,
    PROFILE_ADMIN_CENTRAL
]

export const CAN_EXTRAT_REPORTING_FOR_OTHER = [
    PROFILE_ADMINISTATOR,
    PROFILE_ADMIN_CENTRAL,
    PROFILE_PRODUCTOR_CENTRAL
]

export const CAN_TOOGLE_SUBSCRIPTION_STATUS = [
    PROFILE_ADMINISTATOR,
    PROFILE_SU_ADMIN,
    PROFILE_SU,
    PROFILE_ADMIN_CENTRAL,
    PROFILE_PRODUCTOR_CENTRAL
]

export const CAN_EDIT_MERCHANT=[
    PROFILE_ADMINISTATOR,
    PROFILE_SU_ADMIN,
    PROFILE_SU,
    PROFILE_ADMIN_CENTRAL,
    PROFILE_PRODUCTOR_CENTRAL
]

export const CAN_EDIT_STARTDATE_AND_PERIOD=[
    PROFILE_ADMINISTATOR,
    PROFILE_SU_ADMIN,
    PROFILE_SU,
    PROFILE_ADMIN_CENTRAL,
    PROFILE_PRODUCTOR_CENTRAL
]

export const CAN_SUBSCRIBE_POST_DATE=[
    PROFILE_ADMINISTATOR,
    PROFILE_SU_ADMIN,
    PROFILE_SU,
    PROFILE_ADMIN_CENTRAL,
    PROFILE_PRODUCTOR_CENTRAL
]

//========================
//===== PROFILE ROLE =====
//========================
export const ROLE_MGT_COINSURANCE = "ROLE_MGT_COINSURANCE";

//==================================
//===== BIND ACTIONS / PROFILE =====
//==================================

export const ACTIONS_USER = [
    ACTIONS.souscription,
    ACTIONS.souscription_cat_400,
    ACTIONS.souscription_cat_401,
    ACTIONS.souscription_cat_402,
    ACTIONS.souscription_cat_403,
    ACTIONS.souscription_cat_404,
    ACTIONS.souscription_cat_405,
    ACTIONS.souscription_cat_406,
    ACTIONS.souscription_cat_407,
    ACTIONS.souscription_cat_408,
    ACTIONS.souscription_cat_409,
    ACTIONS.souscription_cat_410,
    ACTIONS.details_souscription,
    ACTIONS.reporting_liste_vehicules,
    ACTIONS.reporting_bordereau_des_emissions,
    ACTIONS.reporting_bordereau_des_expirations
];

export const ACTIONS_ADMINISTRATOR = [
    ...ACTIONS_USER,
    ACTIONS.details_souscription_assure,
    ACTIONS.liste_commissions,
    ACTIONS.list_souscription_groupement,
    ACTIONS.list_co_assurance,

    ACTIONS.parametres,
    ACTIONS.param_agences,
    ACTIONS.param_garanties,
    ACTIONS.param_packageGarantie,
    ACTIONS.param_commission,
    ACTIONS.detail_parametre,
    ACTIONS.param_co_assurance,
    ACTIONS.param_categories,
    ACTIONS.param_produits,
    ACTIONS.param_coupons,
    ACTIONS.param_groupement,
    ACTIONS.param_marques,
    ACTIONS.param_modele,
    ACTIONS.param_package,
    ACTIONS.param_partenaires,
    ACTIONS.param_users,
    ACTIONS.param_reduction,
    ACTIONS.param_gestion_attestation
];

export const ACTIONS_SU = [
    ...ACTIONS_ADMINISTRATOR
];

export const ACTIONS_SUPERVISOR = [
    ...ACTIONS_USER
];

export const ACTIONS_PRODUCTOR = [
    ...ACTIONS_USER,
    ACTIONS.details_souscription_assure,
    ACTIONS.list_souscription_groupement,
    ACTIONS.reporting_bordereau_des_emissions,
    ACTIONS.reporting_bordereau_des_expirations
];

export const ACTIONS_PRODUCTOR_CENTRAL = [
    ...ACTIONS_USER,
    ACTIONS.details_souscription_assure,
    ACTIONS.list_souscription_groupement,
    ACTIONS.reporting_bordereau_des_emissions,
    ACTIONS.reporting_bordereau_des_expirations
];

export const ACTIONS_AGENCY = [
    ...ACTIONS_USER,
    ACTIONS.details_souscription_assure,
    ACTIONS.list_souscription_groupement,
]

export const ACTIONS_PARNER = [
    ...ACTIONS_USER,
    ACTIONS.details_souscription_assure,
    ACTIONS.list_souscription_groupement,
    ACTIONS.liste_commissions,
    ACTIONS.reporting_liste_vehicules,
    ACTIONS.reporting_bordereau_des_emissions,
    ACTIONS.reporting_bordereau_des_expirations
]