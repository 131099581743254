import React, { useEffect, useState } from 'react'

import { Row, Col, Form, Card, Button, Checkbox } from 'antd'

import CustomAntdForm from 'modules/components/Forms/CustomAntdForm';
import { GiftOutlined } from '@ant-design/icons';
import VehicleCardHeader1 from 'views/pages/subscription/common/VehicleCarHeader1';
import { useSelector } from 'react-redux';
import CardHeaderInfoSup from 'views/pages/subscription/common/CardHeaderInfoSup';
import CardHeaderError from 'views/pages/subscription/common/CardHeaderError';
import { BrandField, ChassisNumberField, CityField, CustomerBrandField, CustomerModelField, DateEffectField, DriverLicField, EnergyField, FiscalPowerField, GpsField, MarketValueField, MatriculationField, MecField, ModelField, NewValueField, PeriodField, PlaceField, SocialPositionField, TransportField } from 'views/pages/subscription/common/fields';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';
import DriverBirthField from 'views/pages/subscription/common/fields/DriverBirthdayField';
import VehicleCardHeader2 from 'views/pages/subscription/common/VehicleCardHeader2';
import CylinderField from 'views/pages/subscription/common/fields/CylinderField';
import { CATEGORY_403, CATEGORY_405 } from 'constantes';
import InflammableField from 'views/pages/subscription/common/fields/InflammableField';
import PayloadField from 'views/pages/subscription/common/fields/PayloadField';
import TrailerBrandField from 'views/pages/subscription/common/fields/TrailerBrandField';
import TrailerCustomerBrandField from 'views/pages/subscription/common/fields/TrailerCustomerBrandField';
import TrailerModelField from 'views/pages/subscription/common/fields/TrailerModelField';
import TrailerCustomerModelField from 'views/pages/subscription/common/fields/TrailerCustomerModelField';
import TrailerMatriculationField from 'views/pages/subscription/common/fields/TrailerMatriculationField';
import TrailerChassisNumberField from 'views/pages/subscription/common/fields/TrailerChassisNumberField';

const StepChangeVehicle = ({ basket, startDate, endDate }) => {
    const {category }  = useSelector((state) => state.subscription);
    const {updateFormField, updateQuotationRequest, updateSusbscriptionRequest, goToNextStep} = useBasket();

    const [form] = Form.useForm();
    const [customerBrand, setCustomerBrand] = useState(false);
    const [maxMarketValue, setMaxMarketValue] = useState(null);
    const [trailerCustomerBrand, setTrailerCustomerBrand] = useState(true);
    const [toogleTrailer, setToogleTrailer] = useState(basket?.formField?.hasTrailer)
    const [matriculationExist, setMatriculationExist] = useState(true)
    const [state, setState]= useState({
        errorBag: { error: false, errorMessage: "" }
    })

    useEffect(()=> {
        if(basket?.formField?.modelRef == null && basket?.formField?.modelTitle) {
            setCustomerBrand(true)
        }
    }, [])

    const validateStepForm = () => {

        form.validateFields()
            .then(values => {
                updateFormField({...values});
                updateFormField({customerStartDate: values?.startDate})
                updateQuotationRequest();
                updateSusbscriptionRequest({isProject: false, projectCode: "", isProrate: true})
                goToNextStep(null); 
            })
            .catch(errorInfo => {
                setState(state => ({ ...state, errorBag: { error: true, errorMessage: errorInfo } }));
            });
    }

    const toggleTrailerCheckBox=(e)=> {
        const checked = e.target.checked; 
        updateFormField({hasTrailer: checked})
        setToogleTrailer(checked);

        if(!checked) {
            /* updateFormField({
                hasTrailer: false,
                groupementRef: null,
                groupementTitle: ""
            }); */
        }
    }

    return (
        <React.Fragment>
            <CustomAntdForm form={form} initialValues={basket?.formField}>
                <Card
                    title={<>{VehicleCardHeader1()}</>}
                    headStyle={{ backgroundColor: 'rgba(233,233,233, 0.5)', color: '#ffffff', height: 15, boxShadow: "0px 0.5px 0.5px gray", }}
                >
                    <Row gutter={8} >
                        <TransportField form={form} categoryCode={category?.code} categoryRef={category?.ref} handlerFormField={updateFormField} />
                        <EnergyField categoryCode={category?.code} handlerFormField={updateFormField} />
                        {basket?.formField.motor !== 'NEANT' ? (<CityField categoryCode={category?.code} handlerFormField={updateFormField} />) : null}
                        <MecField categoryCode={category?.code}/>
                        {basket?.formField.motor !== 'NEANT' ? (<PlaceField categoryCode={category?.code}/>) : null}
                        {basket?.formField.motor !== 'NEANT' ? (<FiscalPowerField categoryCode={category?.code} form={form} handlerFormField={updateFormField} defaultValue={ ["TRIC", "TRI"].includes(basket?.formField?.transportCode) ? 2 : null } />) : null}
                        <CylinderField categoryCode={category?.code}/>
                        <NewValueField categoryCode={category?.code} form={form}  onBlur={setMaxMarketValue} />
                        <MarketValueField categoryCode={category?.code} max={maxMarketValue} />
                        <PayloadField categoryCode={category?.code}/>
                        <InflammableField categoryCode={category?.code}/>
                        <SocialPositionField categoryCode={category?.code} handlerFormField={updateFormField} disable={true}/>
                        <PeriodField handlerFormField={updateFormField} month={[CATEGORY_405].includes(category?.code) ? 12 : null} disable={true}/>
                    </Row>
                </Card>

                <Card
                    title={(<CardHeaderInfoSup />)}
                    headStyle={{ backgroundColor: 'rgba(233,233,233, 0.5)', color: '#ffffff', height: 15, boxShadow: "0px 0.5px 0.5px gray", }}
                >
                    <Row gutter={8} >
                        <GpsField />
                        <DriverBirthField categoryCode={category?.code} disable={true}/>
                        <DriverLicField categoryCode={category?.code} disable={true}/>
                        <Col className="gutter-row" xs={12} md={6}></Col>
                    </Row>
                </Card>
                <Card title={(<VehicleCardHeader2/>)} headStyle={{ backgroundColor: '#e9e9e9', color: '#ffffff', height: 20 }}>
                    <Row gutter={[8, 8]}>
                        {!customerBrand ? 
                            (<BrandField handlerFormField={updateFormField} setCustomerBrand={setCustomerBrand}/>): 
                            <CustomerBrandField handlerFormField={updateFormField} setCustomerBrand={setCustomerBrand}/>
                        }

                        { !customerBrand ? 
                            (<ModelField 
                                brandRef={basket?.formField?.brandRef} 
                                handlerFormField={updateFormField}/>):
                            <CustomerModelField />  
                        }
                        <MatriculationField handlerFormField={updateFormField} setMatriculationExist={setMatriculationExist}/>
                        <ChassisNumberField />
                    </Row>
                    {
                        [CATEGORY_403].includes(category?.code) ? (
                            <>
                                <Row>
                                    <Col className="gutter-row" xs={12} md={6}>
                                        <Checkbox defaultChecked={toogleTrailer} onChange={(e) => toggleTrailerCheckBox(e)} disabled={false} >Disposez-vous d'une remorque ?</Checkbox>
                                    </Col>
                                </Row> 
                                <br/>
                                {toogleTrailer ? (
                                    <Row gutter={[8, 8]}>
                                        {!trailerCustomerBrand ? 
                                                <TrailerBrandField categoryCode={category?.code} handlerFormField={updateFormField} setCustomerBrand={setTrailerCustomerBrand}/>: 
                                                <TrailerCustomerBrandField categoryCode={category?.code} handlerFormField={updateFormField} setTrailerCustomerBrand={setTrailerCustomerBrand}/>
                                        }

                                        {!trailerCustomerBrand ? 
                                                <TrailerModelField categoryCode={category?.code} brandRef={basket?.formField?.brandRef} handlerFormField={updateFormField}/>:
                                                <TrailerCustomerModelField categoryCode={category?.code}/>
                                        }
                                        <TrailerMatriculationField categoryCode={category?.code} handlerFormField={updateFormField} setMatriculationExist={setMatriculationExist} />
                                        <TrailerChassisNumberField categoryCode={category?.code}/>
                                    </Row>
                                ) : null}   
                            </>
                        ) : null
                    }
                </Card>
                <Card title={(<div style={{ height: 30, width: '100%', marginTop: -10 }}>
                    <h4 style={{ color: 'grey' }}>Detail du contrat</h4>
                    </div>)} headStyle={{ backgroundColor: '#e9e9e9', color: '#ffffff', height: 20 }}>
                    <Row gutter={[8, 8]}>
                        <Col className="gutter-row" xs={12} md={6}>
                            <DateEffectField rangeDate={{startDate: startDate, endDate: endDate}}/>
                        </Col>
                    </Row>
                </Card>
                <Card
                    title={CardHeaderError(state?.erroBag?.error)}
                    headStyle={{ backgroundColor: 'rgba(233,233,233, 0.5)', color: '#ffffff', height: 15, boxShadow: "0px 0.5px 0.5px gray", }}
                >

                    <div style={{ padding: 20, display: 'flex', justifyContent: 'end' }}>

                        <Button
                            style={{ color: '#ffffff', borderRadius: 8, backgroundColor: '#005680', marginTop: 21, border: '1px solid #005680' }}
                            onClick={validateStepForm}
                        >
                            <GiftOutlined style={{ color: '#ffffff', fontSize: 20 }} /> Passer aux details
                        </Button>
                    </div>
                </Card>
            </CustomAntdForm>
        </React.Fragment>
    )
}

export default StepChangeVehicle;