import React from 'react';

import { HomeOutlined } from '@ant-design/icons';

export function getTabs2(activatedRoutes) {
    const routes = activatedRoutes.filter(item => item.baseUrl);

    let baseUrls = {};

    for (let route of routes) {
        const children = route?.children || [];
        const tabItem = baseUrls[route.baseUrl] || [];

        baseUrls[route.baseUrl] = [
            ...tabItem,
            { 
                ...route, 
                title: route.title, 
                url: route.url, 
                name: route.name 
            }
        ];
    }

    return baseUrls;
}

export function getTabs(activatedRoutes) {
    const listeDesTabsDeUtilisateurConnecte = activatedRoutes.filter(item => item.base_url);

    let listeFormateeDesTabsDeUtilisateurConnecte = {};

    for (let item of listeDesTabsDeUtilisateurConnecte) {

        const tabItem = listeFormateeDesTabsDeUtilisateurConnecte[item.base_url] || [];

        listeFormateeDesTabsDeUtilisateurConnecte[item.base_url] = [
            ...tabItem,
            { 
                ...item, 
                title: item.title, 
                url: item.url, 
                name: item.name }
        ];
    }

    return listeFormateeDesTabsDeUtilisateurConnecte;
}

export function getTopBarItems(appRoutes) {
    const listeDesElementsTopBar2DeUtilisateurConnecte = appRoutes.filter(item => item.topBar2Title);

    let listeFormateeDesElementsTopBar2DeUtilisateurConnecte = [
        {
            title: "Accueil",
            icon: <HomeOutlined />,
            url: "/",
            name: 'home'
        },
    ];

    for (let item of listeDesElementsTopBar2DeUtilisateurConnecte) {

        const index = listeFormateeDesElementsTopBar2DeUtilisateurConnecte.findIndex(el => el.title === item.topBar2Title);

        if (index === -1) {
            listeFormateeDesElementsTopBar2DeUtilisateurConnecte = [
                ...listeFormateeDesElementsTopBar2DeUtilisateurConnecte,
                {
                    title: item.topBar2Title,
                    icon: item.icon,
                    url: item.url,
                    name: item.name
                },
            ];
        }
    }

    return listeFormateeDesElementsTopBar2DeUtilisateurConnecte
}

export function getTopBarItemsv2(appRoutes) {
    const topBarItems = appRoutes.filter(item => item.title);

    let _topBarItems = [
        {
            title: "Accueil",
            icon: <HomeOutlined />,
            url: "/",
            name: 'home'
        },
    ];

    for (let item of topBarItems) {

        const index = _topBarItems.findIndex(el => el.title === item.title);

        if (index === -1) {
            _topBarItems = [
                ..._topBarItems,
                {
                    title: item.title,
                    icon: item.icon,
                    url: item.baseUrl,
                    name: item.name
                },
            ];
        }
    }

    return _topBarItems
}

export function getSideBarItems(userActions) {
    const sideBarItems = userActions.filter(item => item.sidebarTitle);

    let _sideBarItems = [];

    // Formater le sidebar de l'utilsateur courrant (ou connecté)
    for (let item of sideBarItems) {
        const __sideBarItems = sideBarItems.filter(el => el.sidebarTitle === item.sidebarTitle);

        if (__sideBarItems.length === 1) {
            _sideBarItems = [
                ..._sideBarItems,
                {
                    title: item.sidebarTitle,
                    key: item.sidebarTitle,
                    children: [{ 
                        title: item.title, 
                        url: item.url, 
                        key: item.url, 
                        name: item.name
                    }]
                }
            ];
        } else if (__sideBarItems.length > 1) {

            const indexDansListeDesElementsIdentiquesDansLeSidebar = __sideBarItems.findIndex(el => el.name === item.name);

            if (indexDansListeDesElementsIdentiquesDansLeSidebar == 0) {

                _sideBarItems = [
                    ..._sideBarItems,
                    {
                        title: item.sidebarTitle,
                        key: item.sidebarTitle,
                        children: [{ 
                            title: item.title, 
                            url: item.url, 
                            key: item.url, 
                            name: item.name,
                            disabled: item?.disabled ?? false,
                        }]
                    }
                ];
            } else {

                const index = _sideBarItems.findIndex(el => el.title === item.sidebarTitle);

                _sideBarItems[index].children = [
                    ..._sideBarItems[index].children,
                    { 
                        title: item.title, 
                        url: item.url, 
                        key: item.url, 
                        name: item.name,
                        disabled: item?.disabled ?? false, 
                    }
                ];
            }
        }
    }

    return _sideBarItems
}
