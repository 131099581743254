import { Table, DatePicker, Form, Pagination, Spin, Input, Tag, Typography, Select, Segmented, Row, Col } from "antd";
import PageResponse from "api/response/pageResponse";
import { DEFAULT_TABLE_MAX_SIZE } from "constantes";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card,CardBody, CardHeader, Button } from "reactstrap";
import {  DocumentType, onDocumentDownload, thousandSeparator } from "helpers/helpers";
import ReportingService from "services/reportingService";
import { useSelector } from "react-redux";
import { PROFILE_ADMINISTATOR, PROFILE_ADMIN_CENTRAL, PROFILE_PRODUCTOR_CENTRAL } from "profileActions";
import { FileExcelOutlined, FilePdfOutlined, LoadingOutlined } from "@ant-design/icons";

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

export default function Expiration(props) {

    const { user} = useSelector((state) => state.auth);
    console.log("USER", user);
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const [current, setCurrent] = useState(1);
    const [typeFile, setTypeFile] = useState(2);
    const [seachOption, setSearchOption]= useState({
        startDate: null,
        endDate: null,
        partnerCode: null
    })
    const [state, setState] = useState({
        reportings: [],
        isReportingLoading: false,
        isGenerateReportLoading: false,
        inputSaissie: "",
        page: 0,
        size: 100, 
        totalElements: 0, 
        totalPages: 0
    });

    useEffect(()=>{
        //readCommissionReportings(null, null, null, 0);
    }, [])

    const onPageChange = (page) => {
        console.log(":::: onPageChange ", page)
        setCurrent(page);
        refresh();
    };

    const onShowSizeChange = (current) => {
        refresh();
    };

    // REFRESH TABLE
    const refresh=()=> {
        form.validateFields().then((value)=> {
            
            const rangeDate = value?.rangeDate;
            const startDate = rangeDate[0]?.format("YYYY-MM-DD");
            const endDate   = rangeDate[1]?.format("YYYY-MM-DD");
            const partnerCode = value?.partnerCode === undefined || value?.partnerCode === null || value?.partnerCode === '' ? null : value?.partnerCode ;

            if(!startDate || !endDate) {
                toast.error("veuiller choisir une date");
                return;
            }
            
            loadBordereauExpirationReportings(startDate, endDate, partnerCode, current-1);   
        })
        
    }

    const onDownloadBordereauExpiration=()=> {
        setState(state => ({ ...state, isGenerateReportLoading: true }));
        const options = {
            query: { 
                startDate: seachOption.startDate, 
                endDate: seachOption.endDate, 
                partnerCode: seachOption.partnerCode,
                fileType: typeFile ?? 1
                 
            }};
        ReportingService.generateExpirationReporting(options, (data)=> {
            setState(state => ({ ...state, isGenerateReportLoading: false }));
            console.log("response", data);
            setState(state => ({ ...state, isReportingLoading: false,  reportings: [], totalElements: 0, page: 0}));
            
        }, (error)=> {
            setState(state => ({ ...state, isGenerateReportLoading: false }));
            toast.error(error)
        });        
    }

    // GENERATED RAPPORT
    const generateReport =(values) => {
        setState(state => ({ ...state, isGenerateReportLoading: true }));
        form.validateFields().then((value)=> {
            const rangeDate = value.rangeDate;
            const startDate = rangeDate[0].format("YYYY-MM-DD");
            const endDate = rangeDate[1].format("YYYY-MM-DD");

            let options = {
                query: { startDate: startDate, endDate: endDate }
            };

            (new ReportingService()).generateBordereauEmissionReporting(options, (data)=> {
                setState(state => ({ ...state, isGenerateReportLoading: false }));
                console.log("response", data);
                if(data?.error) {
                    toast.info(data?.errorMessage);
                    setState(state => ({ ...state, isReportingLoading: false,  reportings: [], totalElements: 0, page: 0}));
                }
                else {
                    
                    onDocumentDownload(DocumentType.REPPORT,  `rapport_bordereau_emission_${data?.entitiesCode}.xlsx`);
                    loadBordereauExpirationReportings(startDate, endDate, 0);
                }
                
            }, (error)=> {
                setState(state => ({ ...state, isGenerateReportLoading: false }));
                toast.error(error)
            });
        })
    
    }

    // READ ALL REPORT
    const loadBordereauExpirationReportings = (startDate, endDate, partnerCode, page, size) => {
        setState(state => ({ ...state, isReportingLoading: true }));
        const options = {
            query: { 
                startDate: startDate, 
                endDate: endDate, 
                partnerCode: partnerCode,
                page: page,
                size: null
             }
        };
        ReportingService.readAllBordereauExpirationReporting(options, (data)=> {
            let pageResponse = (new PageResponse()).fromJson(data);
            setState(state => ({ ...state, isReportingLoading: false,  reportings: pageResponse.content, totalElements: pageResponse.totalElements, page: pageResponse.page}));
            setSearchOption((seachOption=> ({...seachOption, startDate: startDate, endDate: endDate, partnerCode:  partnerCode})));
        }, (error)=> {
            setState(state => ({ ...state, isReportingLoading: false }));
            toast.error(error)
        });
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 15 }} spin />;
    const { Text } = Typography;

    const columns = [
        
        {
            title: "N° Police",
            dataIndex: "policeNumber",
            key: "policeNumber",
            width: 150
        },
        {
            title: "Av.",
            dataIndex: "avenantCode",
            key: "avenantCode",
            width: 40
        },
        {
            title: "Assuré",
            dataIndex: "assureFullName",
            key: "assureFullName",
            width: 250
        },
        {
            title: "Effet",
            dataIndex: "startDate",
            key: "startDate",
            width: 90,
        },
        {
            title: "Echéance",
            dataIndex: "endDate",
            key: "endDate",
            width: 90,
        },
        {
            title: "Adresse assuré",
            dataIndex: "address",
            key: "address",
            width: 250
        },

        {
            title: "Teléphone",
            dataIndex: "phone",
            key: "phone",
            width: 90,
        },
        {
            title: "Réfefence / Désignation",
            dataIndex: "reference",
            key: "reference",
            width: 90   
        },
        
    ];

    return (
        <React.Fragment>
            <Row>
                <Col lg={24} md={24}>
                    <Card>
                        <CardHeader>
                            <Form {...layout} name="control-ref" form={form} onFinish={refresh} >
                                <Row gutter={[8,8]}>
                                    <Col md={8}>
                                        <Form.Item
                                            name="rangeDate" label="Choisir date debut et fin"
                                            rules={[{ required: true }]}
                                        >
                                            <RangePicker
                                                //showTime={{ format: 'HH:mm' }}
                                                size={'large'}
                                                format={"DD/MM/YYYY"}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>

                                    </Col>
                                    {
                                        [PROFILE_ADMINISTATOR, PROFILE_ADMIN_CENTRAL, PROFILE_PRODUCTOR_CENTRAL].includes(user?.profile) && (
                                            <Col span={2} md={3}>
                                                <Form.Item
                                                    name="partnerCode" 
                                                    label="Code intermediaire"
                                                    rules={[{ required: false }]}
                                                
                                                >
                                                    <Input
                                                        size="large"
                                                        placeholder="Code "
                                                    // onChange={onChange}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )
                                    }
                                    
                                    <Col span={5} md={8} className="text-right">
                                        <div className="d-flex align-items-lg-end flex-lg-row flex-column">
                                            <div className="col-auto">
                                                <Button
                                                    className="btn-label left"
                                                    type="primary"
                                                    style={{ marginTop: 40, justifyContent: 'center' }}
                                                    htmlType="submit"
                                                >
                                                    <i className=" bx bx-refresh label-icon align-middle fs-16"></i>
                                                    Actualisé
                                                </Button>
                                            </div>
                                            <div className="col-auto" style={{marginLeft:5}} >
                                                <Segmented 
                                                    options={[{label:'Excel', value: 1, disabled:false, icon: <FileExcelOutlined />}, {label: 'PDF', value: 2, icon: <FilePdfOutlined />}]} 
                                                    onChange={setTypeFile}
                                                    value={typeFile}
                                                    size="large" 
                                                    />
                                            </div>
                                            <div style={{marginLeft:5}}>
                                                <div className="col-auto">
                                                    <Button  color="primary" className="btn-label right" style={{ marginTop: 40, justifyContent: 'center' }} onClick={onDownloadBordereauExpiration}> 
                                                        <i className=" bx bx-download label-icon align-middle fs-16 ms-2"></i> 
                                                        {state.isGenerateReportLoading ? (<Spin indicator={antIcon} />) : null}  Télécharger 
                                                        
                                                    </Button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Table 
                                    columns={columns} 
                                    dataSource={state.reportings}
                                    pagination={{ position: ["topRight"], pageSize: 10 }}
                                    scroll={{ x: 1700 }} 
                                    key="reportTable"
                                    loading={state.isReportingLoading}
                                    bordered
                                    
                                    /* summary={(pageData) => {
                                        let tPrime = 0;
                                        let tCommission = 0;
                                       
                                        pageData.forEach(({ primeTTC, primeCommission }) => {
                                            tPrime += parseInt(primeTTC.replace(" ", ""));
                                            tCommission += parseInt(primeCommission.replace(" ", ""));
                                        });
                                
                                        return (
                                          <>
                                            <Table.Summary.Row style={{fontWeight: "bold"}}>
                                              <Table.Summary.Cell index={0} colSpan={9} >Total</Table.Summary.Cell>
                                              <Table.Summary.Cell index={9} align="right">
                                                <Text >{thousandSeparator(tPrime)}</Text>
                                              </Table.Summary.Cell>
                                              <Table.Summary.Cell index={10} align="right">
                                              </Table.Summary.Cell>
                                              <Table.Summary.Cell index={11} align="right">
                                                <Text>{thousandSeparator(tCommission)}</Text>
                                              </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                          </>
                                        );
                                      }} */
                                    
                                /><br />
                                <div style={{ textAlign: 'center' }}>
                                    <Pagination current={current} onChange={onPageChange} defaultPageSize={DEFAULT_TABLE_MAX_SIZE} total={state.totalElements} showSizeChanger onShowSizeChange={onShowSizeChange} />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}