import { SettingOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import React from "react";

const FSettings=({onItemClick})=> {
    
    const menu =(onItemClick)=> (
        <Menu
            onClick={onItemClick}
            items={[
                {
                    label: (<>Modif. prime accessoire</>),
                    key: '0',
                },
    
                {type: 'divider',},
                {label: '', key: '1', disabled: true},
            ]}
        />
    );

    return (
        <React.Fragment>
            <Dropdown overlay={menu(onItemClick)} trigger={['click']} className="label-icon align-middle fs-16 ms-2">
                <Space>
                    <SettingOutlined style={{cursor: "pointer"}}/>
                </Space>
            </Dropdown>
        </React.Fragment>
    )
}

export default FSettings;