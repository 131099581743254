import { Col, Form, Select, Spin } from "antd";
import { CATEGORY_406 } from "constantes";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import React, { useState } from "react";
import { useEffect } from "react";


import EnergyService from "services/energyService";

const EnergyField=({categoryCode, disable, handlerFormField, xs, md})=> {
    const { Option } = Select;

    const [state, setState]= useState({
        // ENERGY
        energies: [],
        isEnergyLoading: true,
    })

    useEffect(()=> {
        getAllEnergies()
    }, [])

    const getAllEnergies = () => {
        (new EnergyService()).getAll((data) => {
            setState(state => ({ ...state, energies: data, isEnergyLoading: false }))
        }, (error) => {
            //setState(state=> ({...state, errorBag: {error: true, errorMessage: errorInfo} })); 
        })
    }

    const onEnergySelected = (energyRef) => {
        const energySelected = state.energies.find((energy) => energy.ref === energyRef);
        if (energySelected) {
            handlerFormField({
                energyRef: energySelected?.ref,
                energyTitle: energySelected?.title
            });
        }
    }

    if([CATEGORY_406].includes(categoryCode)) return null;

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Energie"
                    name="energyRef"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    {
                        state.isEnergyLoading ? <Spin /> :
                            (
                                <SelectWithFilter
                                    placeholder='Energie du vehicule'
                                    onChange={(e) => { onEnergySelected(e) }}
                                    disabled={disable}
                                >
                                    {(state.energies || []).map((energie, index) => <Option value={energie?.ref} key={index}>{energie?.title}</Option>)}
                                </SelectWithFilter>
                            )
                    }
                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default EnergyField;