export default class EditBrandRequest {
    id;
    code;
    title;
    description;

    static fromJson = (json = {}) => {
        var createBrandRequest = new EditBrandRequest();
        createBrandRequest = json;
        return createBrandRequest;
    }
}