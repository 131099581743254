import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, Form, Select, Space, Spin } from "antd";
import { CATEGORY_403 } from "constantes";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import React, { useEffect } from "react";
import { useState } from "react";
import BrandService from "services/brandService";
import ModelService from "services/modelService";

const TrailerBrandField=({categoryCode, trailerBrandRef, disable, handlerFormField, setCustomerBrand, xs, md })=> {
    const Option= Select;

    const [state, setState] = useState({
        // BRAND
        trailerBrands: [],
        isTrailerBrandLoading: true,
        isCustomerTrailerBrand: false,

         // MODEL
         trailerModels: [],
         isTrailerModelLoading: false,
         isSelected: false,
    })

    useEffect(()=> {
        // ALL BRAND
        BrandService.getAll({},(data) => {
            setState(state => ({ ...state, trailerBrands: data || [], isTrailerBrandLoading: false }));
        }, (error) => { });

        if(trailerBrandRef) {
            getModelsByBrandRef(trailerBrandRef);
        }
    }, [])

    const getModelsByBrandRef=(brandRef)=> {
        if (brandRef) {
            setState(state => ({ ...state, isTrailerModelLoading: true }));
            (new ModelService()).readModelsByBrand(brandRef, (data) => {
                setState(state => ({ ...state, trailerModels: data, isTrailerModelLoading: false }));
            }, (error) => { });
        }
        else console.log('la marque n\'existe pas.')
    }

    const onBrandSelected = (trailerBrandRef) => {
        const brandSelected = state.trailerBrands.find((brand) => brand.ref === trailerBrandRef);

        if (brandSelected) {
            handlerFormField({
                trailerBrandRef: brandSelected?.ref,
                trailerBrandTitle: brandSelected?.title
            })

            getModelsByBrandRef(trailerBrandRef);
        }
    }

    const onModelSelected = (modelRef) => {
        const modelSelected = state.trailerModels.find((model) => model.ref === modelRef);

        if (modelSelected) {
            handlerFormField({
                trailerModelRef: modelSelected?.ref,
                trailerModelTitle: modelSelected?.title
            })
        }
    }

    const resetBrandFields= ()=> {
        handlerFormField({
            trailerBrandRef: null,
            trailerBrandTitle: null
        })
    }

    if(![CATEGORY_403].includes(categoryCode)) return null;

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label={(<div style={{ display: 'flex' }}><h5>Marque</h5><PlusCircleOutlined style={{ marginLeft: 10, marginTop: 5, cursor: 'pointer' }} onClick={() => {setCustomerBrand(true); resetBrandFields()}} /></div>)}
                    name="trailerBrandRef"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    <SelectWithFilter 
                        placeholder='Marque du vehicule' 
                        onChange={onBrandSelected} 
                        id="marque" 
                        disabled={disable || state?.isTrailerModelLoading}
                        suffixIcon={state.isTrailerModelLoading ? (<Space><div>Chargement...</div><Spin size='small' /></Space>) : null}
                        >
                        {state.trailerBrands.map((brand, index) => (<Option value={brand.ref} key={index}>{brand.title}</Option>))}
                    </SelectWithFilter>
                </Form.Item>
            </Col>

            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label={(<h4>Modèle remorque</h4>)}
                    name="trailerModelRef"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    <SelectWithFilter
                            placeholder='Modèle du vehicule'
                            onChange={onModelSelected}
                            loading={state.isTrailerModelLoading}
                            id="model"
                            disabled={disable}
                        >
                            {(state.trailerModels || []).map((model, index) => (<Option value={model.ref} key={`${model.ref}${index}`}>{model.title}</Option>))}
                        </SelectWithFilter>
                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default TrailerBrandField;