import FStepAssureDetails from "../../common/steps/FStepAssureDetails";
import useInitFlotteAvenant from "../useInitFlotteAvenant";
import React from 'react';
import { FStepThankYou } from "./steps";
import WizardHeader from "modules/components/WizardHeader";
import { Spin } from "antd";
import ErrorWidget from "components/Widgets/ErrorWidget";
import * as constants from 'constantes';

const FAvenantremiseEnVigueur= ({data})=> {
    const { state, goToNextStep, goToPreviousStep } = useInitFlotteAvenant(data);
    // let _data = data;
    data.period = data.newPeriod;
    console.log('on rentre ici', data)
    function renderChildren() {
        
        switch (state.currentStep) {
            case 0:
                
                return <FStepAssureDetails 
                        goToNextStep={goToNextStep}  
                        goToPreviousStep={goToPreviousStep} 
                        data = {state} 
                        />;
             case 1:
                return <FStepThankYou data = {state}  />;
        }
    }

    return (
        <>
            {
                state.currentStep !== 1 ? (
                    <WizardHeader
                    title={`Categorie ${state?.category?.code} : ${state?.category?.title}`}
                    avenantTilte={state.avenant?.code === constants.CODE_AVENANTS.CREATION ? "Nouvelle affaire" : `Avenant de ${state.avenant?.title}`}
                    onClick={(state.currentStep > 0 && state.currentStep < 4) ? goToPreviousStep : null}
                    percent={state.currentStep * 100}
                    />
                ): <></>
            }
            {state.isInitAvenantLoading ? <><Spin /> Chargement ...</> : (state.iniAvenantLoadingError ? <><ErrorWidget errorMessage={state.iniAvenantLoadingErrorMessage} /></> : renderChildren())   }
        </>
    )
}

export default FAvenantremiseEnVigueur;