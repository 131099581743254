import React from "react";

const VehicleCardHeader1 = ()=> (
        <React.Fragment>
            <div style={{ height: 30, width: '100%', marginTop: -10 }}>
                <h4 style={{ color: 'grey' }}>Informations sur le vehicule</h4>
            </div>
        </React.Fragment>
    );

export default VehicleCardHeader1;