import { Tag } from 'antd';
import { thousandSeparator } from 'helpers/helpers';
import React from 'react'
import { Spinner } from 'reactstrap';

const HeaderPrime=({prime, visible, isLoading, noVisibleMsg})=> {
    return (
        <div style={{ display: 'flex', justifyContent: 'end', backgroundColor: 'rgba(233,233,233, 0.5)', color: '#ffffff', boxShadow: "0px 0.5px 0.5px gray" }}>
            <div style={{ height: 30,  display: 'flex' }}>
                <h4 >Montant: </h4>
                {
                    visible ? (
                        <div style={{ marginLeft: '10px'}}>
                            <Tag color='#1DCC70' style={{fontSize: 18}}>
                                {isLoading && <Spinner size="sm" type="grow" className="flex-shrink-0"> Loading... </Spinner>}
                                {thousandSeparator(prime ?? 0)} FCFA
                            </Tag>
                        </div>
                    ) : (
                        <div style={{marginLeft: '10px'}}>
                            <Tag color='#9db7b4'>*{noVisibleMsg ?? '** Selon le pack choisi ***'}</Tag>
                        </div>
                    )

                }
            </div>
        </div>
    )
}

export default HeaderPrime;