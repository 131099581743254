import { Col, Form } from "antd";
import { CATEGORY_406 } from "constantes";
import InputNumberFormatFr from "modules/components/InputNumberFormatFr/InputNumberFormatFr";
import React from "react";;

const PlaceField=({categoryCode, disable, xs, md })=> {

    if([CATEGORY_406].includes(categoryCode)) return null;

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Nombre de place"
                    name="nbPlace"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    <InputNumberFormatFr placeholder='nombre de place' min={1} disabled={disable} />
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default PlaceField;