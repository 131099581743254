import {  Card, Spin } from "antd";
import WizardHeader from "modules/components/WizardHeader";
import React, { useEffect, useState } from "react";
import ErrorWidget from "components/Widgets/ErrorWidget";
import { StepAssure, StepDetails } from "./steps";
import { StepThankYou } from "../../common/steps";
import useInitAvenant from "../useInitAvenant";
import { useSelector } from "react-redux";
import * as helpers from 'helpers/helpers';
import { useBasket } from "views/pages/subscription/hooks/useBasket";
import QuotationService from "services/quotationService";
import _ from "lodash";

export default function AvenantChangementAssure() {
  const { avenant, contract, category, partnerCode } = useSelector((state) => state.subscription);
  const { currentStep, page, basket, transactionResponse, initAvenantResponse } = useSelector((state) => state.basketSlice);
  const { goToPreviousStep, updateGroup } = useBasket();
  const [quoting, setQuoting] = useState(true);
  const {state} = useInitAvenant()
  
  useEffect(()=> {
    if(!state.isInitAvenantLoading) {
      const _packSelected = _.first(basket?.quotationResponse?.packsResponse);
      const _subscriptionRequest = {
          ...basket?.subscriptionRequest,
          primeTTC: basket?.quotationResponse?.primeTTC,
          primeExtra: basket?.quotationResponse?.primeExtra,
          primeNette: basket?.quotationResponse?.primeNette,
          extras: basket?.quotationResponse?.extras || []
      }
      updateGroup([
          { path: ['basket', 'packSelected'], value: { ..._packSelected } },
          { path: ['basket', 'subscriptionRequest'], value: { ..._subscriptionRequest } },
      ])
    }
  }, [state.isInitAvenantLoading])

  if(state.isInitAvenantLoading) {
    return <Card><Spin /> Chargement et configuration en cours ...</Card>
  }

  if(state.iniAvenantLoadingError) {
      return <ErrorWidget errorMessage={state.iniAvenantLoadingErrorMessage} />;
  }

  function renderChildren() {
      switch (currentStep) {
          case 0:
            return <StepAssure basket={basket} />;
          case 1:
            return <StepDetails
                      avenantCode={avenant?.code}
                      endDate={initAvenantResponse?.endDate}
                      subscriptionRequest={basket?.subscriptionRequest}
                      packSelected={basket?.packSelected}
                    />

          case 2:
            if(page === helpers.PAGES.THANK_YOU) {
              return <StepThankYou transactionResponse={transactionResponse} />;
            }
            break;
          default:
              return <ErrorWidget errorMessage="Ooops la page n'existe pas" />;
      }
  }

  return (
      <React.Fragment>
          <WizardHeader
              title={`Categorie ${category.code} : ${category.title}`}
              avenantTilte={`Avenant de changement d'assuré`}
              partnerCode={partnerCode}
              police={contract?.policeNumber}
              onClick={(currentStep > 0 && state.currentStep < 1) ? goToPreviousStep : null}
              percent={currentStep * 34}
              />
          {renderChildren()}
      </React.Fragment>
  )
}