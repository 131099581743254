export default class SignUpRequest {
    name;
    username;
    email;
    phone;
    password;
    entitiesCode;
    profileRef;
    status;

    fromJson = (json={})=> {
        var signUpRequest = new SignUpRequest();
        signUpRequest = json;
        return signUpRequest;
    }
}