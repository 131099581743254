import { Form, Card, Tag } from 'antd';
import CustomAntdForm from 'modules/components/Forms/CustomAntdForm';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import { thousandSeparator } from 'helpers/helpers';
import AssureCodeField from '../../../../common/fields/AssureCodeField';
import AssureFirstNameField from '../../../../common/fields/AssureFirtsNameField';
import AssureLastNameField from '../../../../common/fields/AssureLastNameField';
import AssureSearchField from '../../../../common/fields/AssureSearchField';
import CardIDField from '../../../../common/fields/CardIDField';
import CardNumberField from '../../../../common/fields/CardNumberField';
import DateEffectField from '../../../../common/fields/DateEffectField';
import EmailField from '../../../../common/fields/EmailField';
import PhoneField from '../../../../common/fields/PhoneField';
import { useBasket } from '../../../../hooks/useBasket';
import BackNextWidget from '../../../common/BackNextWidget';

const FStepAssureAndContract= ({formField})=> {
    const { contract, avenant } = useSelector((state) => state.subscription);
    const {basket, quotationResponse} = useSelector(state=> state.basketSlice);
    const {updateFormField, updateFSusbscriptionRequest, goToNextStep, goToPreviousStep} = useBasket()
   
    const [form] = Form.useForm();
    const [state, setState] = useState({
        selectedPackageId: '',
        identityCards: [],

        // ASSURE
        assure: {},
        isIdentityCardLoading: true,
        isAssureLoading: false,
        assureIsFinded: false,
        searchValue: null,
        isSelected: false,
    });

    const handleSubmit= ()=> {
        form.validateFields()
            .then(values => {
                updateFormField({...formField, ...values});
                updateFSusbscriptionRequest({isProject: false, projectCode: "", isProrate: false})
                goToNextStep(null)
            })
            .catch(errorInfo => { 
                console.log("Input field error : ", errorInfo)
                toast.error("Formulaire invalide ");
            });
    }

    const CardHeaderDefault = (title)=> (
        <div className='d-flex'>
            <div style={{ height: 30, width: '100%', marginTop: -10 }}>
                <h4 style={{ color: 'grey' }}>{title}</h4>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'end', backgroundColor: 'rgba(233,233,233, 0.5)', color: '#ffffff', boxShadow: "0px 0.5px 0.5px gray" }}>
                <div style={{ height: 30,  display: 'flex' }}>
                    <h4 >Montant à payer</h4>
                    <div style={{marginLeft: '10px'}}>
                        <Tag color='#1DCC70' style={{fontSize: 18}}>{thousandSeparator(quotationResponse?.primeTTC)} FCFA</Tag>
                    </div>
                </div>
            </div>
            <CustomAntdForm form={form} initialValues={formField}>
                <Card
                    title={CardHeaderDefault("Détails sur votre contrat")}
                    headStyle={{ backgroundColor: '#e9e9e9', color: '#ffffff', height: 20, boxShadow: "0px 0.5px 0.5px gray", }}
                >
                    <Row gutter={[8, 8]}>
                        <Col className="gutter-row" xs={12} md={3}>
                            <DateEffectField 
                                avenantCode={avenant?.code}
                                contractRef={contract?.ref}
                                formField={basket?.formField}
                            />
                        </Col>
                    </Row>
                    
                </Card>
                <Card
                    title={<div className='d-flex' style={{ height: 30, width: '100%', marginTop: -10 }}>
                                <div ><h4 style={{ color: 'grey' }}>Identification de l'assuré</h4> </div>
                                <div className='ms-auto'> <AssureSearchField form={form} handlerFormField={updateFormField} /></div>
                            </div>}
                    headStyle={{ backgroundColor: '#e9e9e9', color: '#ffffff', height: 20, boxShadow: "0px 0.5px 0.5px gray", }}
                >
                    <Row gutter={[8, 8]}>
                        <Col className="gutter-row" xs={12} md={3}>
                            <AssureFirstNameField disable={basket?.formField?.assureCode}/>
                        </Col>

                        <Col className="gutter-row" xs={12} md={3}>
                            <AssureLastNameField disable={basket?.formField?.assureCode}/>
                        </Col>

                        <Col className="gutter-row" xs={12} md={3}>
                            <PhoneField disable={basket?.formField?.assureCode}/>
                        </Col>
                        <Col className="gutter-row" xs={12} md={3}>
                            <AssureCodeField disable={true}/>
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]} >
                        <Col className="gutter-row" xs={12} md={3}>
                            <EmailField disable={basket?.formField?.assureCode}/>
                        </Col>
                        <Col className="gutter-row" xs={12} md={3}>
                            <CardIDField disable={basket?.formField?.assureCode} handlerFormField={updateFormField} />
                        </Col>

                        <Col className="gutter-row" xs={12} md={3}>
                            <CardNumberField disable={basket?.formField?.assureCode} cardIDRef={formField?.card} />
                        </Col>
                        
                    </Row>
                    
                    <Row >
                        <Col lg={12}>
                            <BackNextWidget 
                                confirmText={"PASSER AU DETAILS DU CONTRAT"}
                                onConfirm={()=> handleSubmit()}
                                disableConfirm={false}
                                onCancel={()=>  goToPreviousStep(null)}
                                />
                        </Col>
                    </Row>
                </Card>

            </CustomAntdForm>
        </React.Fragment>
    );
}

export default FStepAssureAndContract;