import { Form, Input } from "antd";
import React from "react";

const CardNumberField=({ cardIDRef, disable })=> {
    //const [disabled, setDisabled] = useState(disable);

    /* useEffect(()=> {
        if(cardIDRef) {
            setDisabled(false)
        }
    }, [cardIDRef]) */
    
    return (
        <React.Fragment>
            <Form.Item
                label="Numéro de la piece de l'assuré"
                name="assureIdentityCardNumber"
                rules={[{ required: false, message: "Champ obligatoire" }]}
            >
                <Input size='large' placeholder='Numero de la piece' disabled={disable} />
            </Form.Item>
        </React.Fragment>
    )
}

export default CardNumberField; 