import { EyeOutlined, TeamOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Input, Table, Tag, Pagination, Space, Tooltip, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { DEFAULT_TABLE_MAX_SIZE } from "constantes";
import { useSelector } from "react-redux";
import { ROLE_MGT_COINSURANCE } from "profileActions";
import ErrorWidget from "components/Widgets/ErrorWidget";
import PageResponse from "api/response/pageResponse";
import ContractsResponse from "api/response/contratsResponse";
import ContractService from "services/contractService";
import { TableAction, thousandSeparator } from "helpers/helpers";
import AddCoInsurance from "../Component/CoInsurance/AddCoInsurance";
import { useHistory } from "react-router-dom";
import { fullFormatDate } from "helpers/date_helpers";

export default function ContractTable({ categoryRef, title, type }) {
    const { user } = useSelector((state) => state.auth);
    const [current, setCurrent] = useState(1);
    const [isDrawerCoInsuranceVisible, setIsDrawerCoInsuranceVisible] = useState(false);
    const { Search } = Input;
    const history = useHistory();
    const [state, setState]= useState({
        // CONTRACT
        initialData: [],
        tempContractData: [],

        isContractLoading: true,
        contractLoadingError: false,
        contractLoadingErrorMessage: "",
        contractSelected: new ContractsResponse(),

        page: 0, 
        size: 0, 
        totalElements: 0, 
        totalPages: 0
    });

    useEffect(()=> {
        getSubscriptionContracts(categoryRef);
    }, [])

    // Liste des soubscription
    const getSubscriptionContracts = (categoryRef, word, page) => {
        const options = {
            query: {
                categoryRef: categoryRef ?? null,
                hasGroup: type === "GROUPEMENT" ? true : null,
                coi: type === "COINSURANCE" ? true : null,
                word: word ?? null,
                page: page ?? 0
            }
        };

        setState(state => ({ ...state, isContractLoading: true }));
        ContractService.readSubscriptionContracts(options, (data) => {
            let _pageResponse = (new PageResponse()).fromJson(data);

            const contracts = _pageResponse.content || [];
            setState(state => ({
                ...state,
                initialData: contracts,
                tempContractData: contracts,
                page: _pageResponse.page,
                size: _pageResponse.size,
                totalElements: _pageResponse.totalElements,
                totalPages: _pageResponse.totalPages,
                isContractLoading: false
            }))
        }, (error) => {
            setState(state => ({ ...state, isContractLoading: false, contractLoadingError: true, contractLoadingErrorMessage: error }))
        });
    }

    // ======== CO INSURANCE===============
    const onConfirmCoInsuranceRepartition =()=> {
        setIsDrawerCoInsuranceVisible(false);
        getSubscriptionContracts(categoryRef?.ref);
    }

    // ====================================
    const onAction=(type, contract)=> {
        switch(type) {
            case TableAction.DETAILS: 
            history.push(`/souscription-details/${contract.id}/${contract.assure.code}/${contract.contractID}/${contract.categoryCode}`)
            break;

            case TableAction.COINSURANCE:
                setIsDrawerCoInsuranceVisible(true);
                setState((state) => ({ ...state, contractSelected: contract }))
            break;

            default:
        }
    }

    const onPageChange = (page) => {
        console.log(":::: onPageChange ", page)
        setCurrent(page);
        changePagePaginationHandler(page - 1)
    };

    const onShowSizeChange = (current) => {
        changePagePaginationHandler(current - 1)
    };

    const changePagePaginationHandler= (currentPage) => {
        getSubscriptionContracts(categoryRef, null, currentPage);
    }

    const searchHandler = (e) => {
        const word = e?.target?.value?.toLowerCase()?.trim().replace(" ","");
        
        let tmpList = [];
        if (word == null || word == undefined || word == '') {
            tmpList = state?.initialData;
        }
        else {
            let newList = state?.initialData.filter((data)=> {
                //console.log(":::: word", word)
                /* var reg = new RegExp('\\b' + word.toString() + '\\b', "gi");
                var resultat = data?.assure.firstName.toLowerCase().match(reg);
                console.log(":::: regex", resultat) */
                const policeNumber = (data?.policeNumber?.toString() ?? '').toLowerCase().replace(" ","");
                const firstName = (data?.assure?.firstName ?? '').toLowerCase().replace(" ","");
                const lastName = (data?.assure?.lastName ?? '').toLowerCase().replace(" ","");
                const startDate = data?.startDate != null ? new Date(data?.startDate).toString().toLowerCase() : '';
                const endDate = data?.endDate != null ? new Date(data?.endDate).toString().toLowerCase() : '';
                const createdAt = data?.createdAt != null ? new Date(data?.createdAt).toString().toLowerCase() : '';
                const productTitle = (data?.productTitle ?? '').toLowerCase().replace(" ","");
                const modelTitle = (data?.vehicle?.model?.title ?? '').toLowerCase().replace(" ","");
                const brandTitle = (data?.vehicle?.model?.brand?.title ?? '').toLowerCase().replace(" ","");
                const chassisNumber = (data?.vehicle?.chassisNumber ?? '').toLowerCase().replace(" ","");
                const matriculation = (data?.vehicle?.matriculation ?? '').toLowerCase().replace(" ","");
                const groupName = (data?.groupName ?? '').toLowerCase().replace(" ","");

                return (
                    policeNumber.indexOf(word.toString()) !== -1 ||
                    firstName.indexOf(word) > -1 ||
                    lastName.indexOf(word) > -1 ||
                    (firstName + lastName).indexOf(word) > -1 ||
                    startDate.indexOf(word) > -1 ||
                    endDate.indexOf(word) > -1 ||
                    createdAt.indexOf(word) > -1 ||
                    productTitle.indexOf(word) > -1 ||
                    modelTitle.indexOf(word) > -1 ||
                    brandTitle.indexOf(word) > -1 ||
                    chassisNumber.indexOf(word) > -1 ||
                    matriculation.indexOf(word) > -1 ||
                    groupName.indexOf(word) > -1
                ) 
            });

            //console.log(":::: newList", newList)

            tmpList = newList;
        }

        if(tmpList.length === 0) {
            getSubscriptionContracts(categoryRef, word);
        }
        else {
            setState(state => ({ ...state, tempContractData: [...tmpList] }))
        }
    }

    const columns = [
        {
            title: 'Police',
            dataIndex: 'policeNumber',
            key: 'policeNumber',
            width: 190,
            filterSearch: true,
            filters: (state?.initialData || []).map((item)=> ({
                text: item?.policeNumber,
                value: item?.policeNumber,
              })),
            onFilter: (value, record) => record?.policeNumber?.startsWith(value),
            render: (item, contract) => (
                <div style={{ fontWeight: "bold" }}>
                    {contract?.policeNumber}
                </div>
            )
        },
        {
            title: 'Assuré',
            dataIndex: 'assureFirstname',
            key: 'assureFirstname',
            width: 190,
            filterSearch: true,
            filters: (state?.initialData || []).map((item, i)=> ({
                
                text: item?.assure?.firstName + ' ' + item?.assure?.lastName,
                value: item?.assure?.firstName+ ' ' + item?.assure?.lastName + ' ' + item?.policeNumber,
              })),
            onFilter: (value, record) => (record?.assure?.firstName+ ' ' + record?.assure?.lastName + ' ' + record?.policeNumber)?.startsWith(value),
            render: (item, contract) => (
                <div>
                    {contract?.assure?.firstName + ' ' + contract?.assure?.lastName}
                </div>
            )
        },
        
        {
            title: "Produit",
            dataIndex: 'produit',
            key: 'produit',
            width: 90,
            render: (item, contract) => (
                <div>
                    {contract?.productTitle + ' ' + contract?.categoryCode}
                </div>
            )
        },
        {
            title: 'Matricule',
            dataIndex: 'matriculation',
            key: 'matriculation',
            width: 100,
            filterSearch: true,
            filters: (state?.initialData || []).map((item)=> ({
                text: item?.vehicle?.matriculation,
                value: item?.vehicle?.matriculation,
              })),
            onFilter: (value, record) => record?.vehicle?.matriculation?.startsWith(value),
            render: (item, contract) => (
                <div>{contract?.vehicle ? contract?.vehicle?.matriculation : "-"}</div>
            )
        },
        {
            title: 'Marque et modèle',
            dataIndex: 'brandRef',
            key: 'brandRef',
            width:250,
            render: (item, contract) => (
                <div>{contract?.vehicle ? contract?.vehicle?.model?.brand?.title + ' | ' + contract?.vehicle?.model?.title : "-"}</div>
            )
        },
        {
            title: "Apporteur",
            dataIndex: 'apporteur',
            key: 'apporteur',
            width: 200,
            render: (item, contract) => (<div>{contract?.entitiesTitle}</div>)
        },
        {
            title: "Groupement",
            dataIndex: 'groupName',
            key: 'groupName',
            render: (item, contract) => (<div>{contract.groupName || contract.groupName !== "" ? contract.groupName : "-"}</div>)
        },

        {
            title: 'Emis le',
            dataIndex: 'date',
            fixed: 'right',
            key: 'date',
            width: 100,
            render: (item, contract) => fullFormatDate(contract?.createdAt)
        },

        {
            title: 'Date effet',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 100,
            fixed: 'right',
            render: (item, contract) => fullFormatDate(contract?.startDate)
        },
        {
            title: 'Echéance',
            dataIndex: 'endDate',
            key: 'endDate',
            fixed: 'right',
            width: 100,
            render: (item, contract) => fullFormatDate(contract?.endDate)
        },

        {
            title: 'Statut',
            dataIndex: 'Statut',
            key: 'Statut',
            fixed: 'right',
            width: 80,
            align: 'center',
            
            render: (item, contract) => (
                <div>
                    {
                        contract?.statusMessage === "CONTRACT_RENEW" ? (<Tag color="green">Renouvé</Tag>)
                            : contract?.statusMessage === "CONTRACT_START" ? (<Tag color="green">Actif</Tag>)
                                : contract?.statusMessage === "CONTRACT_EXPIRE" ? (<Tag color="red">Expiré</Tag>)
                                    : contract?.statusMessage === "CONTRACT_CANCEL" ? (<Tag color="red">Annulé</Tag>)
                                        : contract?.statusMessage === "CONTRACT_HIDE" ? (<Tag color="orange">Suspendu</Tag>)
                                            : contract?.statusMessage === "CONTRACT_WAITING" ? (<Tag color="grey">En attente</Tag>)
                                                : contract?.statusMessage === "CONTRACT_RESCIND" ? (<Tag color="grey">Résilié</Tag>)
                                                    : <Tag color="grey">Echu</Tag>}
                </div>
            )
        },
        
        // {
        //     title: "Attestation",
        //     dataIndex: 'attestation',
        //     key: 'attestation',
        //     render: (item, contract) => (
        //         <div>
        //             {
        //                 !contract.attestationIsGenerated ? <Tag color="orange">à imprimer</Tag> : <Tag color="green">Généré</Tag>
        //             }
        //         </div>
        //     )
        // },
        {
            title: " Actions",
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 80,
            render: (item, contract) => (
                <div style={{ display: 'flex' }}>
                    <Space>
                        <Tooltip title="Détails contrat">
                            <EyeOutlined style={{ color: '#40AEE0' }} onClick={() => onAction(TableAction.DETAILS, contract)} />
                        </Tooltip>

                        {user?.roles.includes(ROLE_MGT_COINSURANCE) && (contract?.statusMessage === "CONTRACT_RENEW" || contract?.statusMessage == "CONTRACT_START") ? (
                                <Tooltip title={!contract?.coInsuranceApplied ? "Co-assuré la souscription" : "Dejà co-assuré"} color={!contract?.coInsuranceApplied ? '#40AEE0' : 'red'} key={!contract?.coInsuranceApplied ? '#40AEE0' : 'red'}>
                                    {
                                        !contract?.coInsuranceApplied ? (<UsergroupAddOutlined style={{ color: '#40AEE0' }} onClick={() => { onAction(TableAction.COINSURANCE, contract) }} />) : (<TeamOutlined style={{ color: 'green' }} />)
                                    }
                                </Tooltip>
                            ) : null
                        }
                    </Space>
                </div>

            )
        },
    ];

    if(state.contractLoadingError) {
        return <ErrorWidget errorMessage={state?.contractLoadingErrorMessage} />;
    }

    return (
        <React.Fragment>
            {title ? (<div><h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>{title}</h4><br /></div>) : null}
            <Search
                addonBefore="Recherche"
                placeholder="Numero Police, Nom Assure, Date d'effet, Date d'echéance, Numero d'immatriculation, numéro de chassis ..."
                allowClear
                onSearch={searchHandler}
                onChange={searchHandler}
                onFocus={searchHandler}
                style={{}} />

            <div style={{marginTop: 10}}>
                <Table
                    columns={columns}
                    dataSource={state.tempContractData}
                    pagination={{ position: ["topRight"], pageSize: 10 }}
                    scroll={{ x: 1700 }}
                    key="contractTable2"
                    loading={state.isContractLoading}
                    
                /><br />
                <div style={{ textAlign: 'center' }}>
                    <Pagination 
                         
                        current={current} 
                        onChange={onPageChange} 
                        defaultPageSize={DEFAULT_TABLE_MAX_SIZE} 
                        total={state.totalElements} 
                        showSizeChanger 
                        showTitle={true}
                        onShowSizeChange={onShowSizeChange} />
                </div>
            </div>

            <Drawer
                title={`Co-assurer: ${state.contractSelected?.policeNumber} (Prime nette: ${thousandSeparator(state.contractSelected?.primeNette)} F CFA)`}
                width={720}
                onClose={setIsDrawerCoInsuranceVisible}
                open={isDrawerCoInsuranceVisible}
                bodyStyle={{
                    paddingBottom: 80,
                }}
            >
                <AddCoInsurance 
                    contract={state.contractSelected}  
                    onConfirm={onConfirmCoInsuranceRepartition}
                    onCancel={()=> setIsDrawerCoInsuranceVisible(false)} />

            </Drawer>
        </React.Fragment>
    )
}