import { CopyOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined, FileFilled } from "@ant-design/icons";
import { Input, Table, Tag, Pagination, Space, Tooltip, Drawer, Popconfirm, Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { DEFAULT_TABLE_MAX_SIZE } from "constantes";
import { DocumentType, onDocumentOpen, thousandSeparator } from "helpers/helpers";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import ContractService from "services/contractService";
import PageResponse from "api/response/pageResponse";
import SubscriptionDetails from "./SubscriptionDetails";
import Basket from "api/model/basket";
import {  SubscriptionWidget } from "../subscription/component/ComponentWidget";
import Avenant from "api/model/avenant.model";
import SubscriptionService from "services/subscriptionService";
import { toast } from "react-toastify";
import { sUpdateState } from "redux/slices/subscriptionSlice";
import SubscriptionDetailModel from "api/model/subscriptionDetail.model";
import ContractsResponse from "api/response/contratsResponse";
import CategoryService from "services/categoryService";
import SubscriptionHeaderContext from "../subscription/component/SubscriptionHeaderContext";
import { useHistory } from "react-router-dom";


class ActionType {
    static DETAILS = "details";
    static EDIT = "edit";
    static DELETE = "delete";
    static DUPLICATE = "duplicate";
}

export default function ProjectTable({ category, title, handleNouvelAvenant }) {
    const dispatch = useDispatch()
    const history = useHistory();
    const [current, setCurrent] = useState(1);
    const { Search } = Input;
    const [list, setList] = useState([]);
    const [drawerDetailProjectVisibility, setDrawerDetailProjectVisibility] = useState(false)

    const [state, setState]=useState({
        initialData: [],
        tempProjectData: [],

        // PROJECT
        projectPageResponse: new PageResponse(),
        isProjectLoading: true,
        projectSelected: new ContractsResponse(),
        projectSelected: {},
        subcriptionDetail: {},

        projectStatus: {error: false, errorMessage: ''},

        page: 0, 
        size: 0, 
        totalElements: 0, 
        totalPages: 0
    })

    useEffect(()=>{
        getSubscriptionsProjects(category?.code, null, 0);
    }, [])

    // Liste des soubscription projects
    const getSubscriptionsProjects = (categoryCode, word, page) => {
        if (page == null || page === undefined) page = 0;
        if (word == null || word === undefined) word = null;
        if (categoryCode == null || categoryCode === undefined) categoryCode = null;

        setState(state => ({
            ...state,
            isProjectLoading: true
        }));

        const options = {
            query: {
                categoryCode: categoryCode,
                word: word,
                page: page
            }
        };

        (new ContractService()).readSubscriptionProjects(options, (data) => {
            let _pageResponse = (new PageResponse()).fromJson(data);
            const projects = _pageResponse?.content || []
            setState(state => ({
                ...state,
                initialData: projects,
                tempProjectData: projects,
                projectPageResponse: _pageResponse,
                
                page: _pageResponse.page,
                size: _pageResponse.size,
                totalElements: _pageResponse.totalElements,
                totalPages: _pageResponse.totalPages,

                isProjectLoading: false,
            }))
        }, (error) => {
            setState(state => ({ ...state, isProjectLoading: false, projectStatus: {error: true, errorMessage: error} }))
        });
    }

    const onAction=(type, project)=> {
        switch(type) {
            case ActionType.DETAILS:
                let subscriptionDetails = new SubscriptionDetailModel();
                if (project) {
                    console.log(":::: project : ", project)
                    subscriptionDetails.id = project?.id;
                    subscriptionDetails.categoryCode = project?.categoryCode;
                    subscriptionDetails.policeNumber = project?.policeNumber;
                    subscriptionDetails.entitiesTitle = project?.entitiesTitle;
                    subscriptionDetails.avenantTitle = project?.avenantTitle;
                    subscriptionDetails.numAvenant = project?.avenantCode;
                    subscriptionDetails.startDate = project.startDate;
                    subscriptionDetails.endDate = project?.endDate;
                    subscriptionDetails.months = project?.months;
                    subscriptionDetails.days = project?.days;
                    subscriptionDetails.paymentProviderTitle = "--/--";
                    subscriptionDetails.transactionId = "--/--";
                    subscriptionDetails.assure = project?.assure;
        
                    subscriptionDetails.primeNette = project?.primeNette;
                    subscriptionDetails.primeTTC = project?.primeTTC;
        
                    subscriptionDetails.extras = project?.extras || [];
                    subscriptionDetails.guaranties = project?.guaranties || [];
                    subscriptionDetails.reductionGroups = project?.reductionGroups || [];
        
                    subscriptionDetails.subscription = project?.subscription || [];
                    subscriptionDetails.assistances = project?.assistances || [];
                    subscriptionDetails.packsSelected = project?.packsSelected || [];

                    subscriptionDetails.vehicles = (project?.vehicles || []).map((vehicle)=> {
                        const packSelected = (project?.packsSelected || []).find((packSelected)=> packSelected?.vehicle?.ref === vehicle?.ref) 
                        return Object.assign({}, vehicle, {
                            packTitle: packSelected?.packResponse?.packTitle,
                            categoryTitle: packSelected?.packResponse?.category?.title
                        })
                    });
        
                    setState(state => ({ ...state, subcriptionDetail: subscriptionDetails }))
                    setDrawerDetailProjectVisibility(true)
                }
            break;

            case ActionType.EDIT:
                let basket = new Basket();
                const _avenant =  Avenant.fromJson({
                    ref: "f251f0b4-5b20-478d-84f0-288b661b9177",
                    code: 0,
                    title: "Création",
                    description: "Création",
                    status: true,
                    isDefault: true
                });

                (new CategoryService()).getCategoryByCode(project?.categoryCode, (data)=> {
                    const _category = data;

                    switch (project?.categoryCode) {
                        case "400":
                            dispatch(sUpdateState([
                                { path: ["avenant"], value: _avenant },
                                { path: ["category"], value: _category },
                                { path: ["assure"], value: project?.assure },
                                { path: ["contract"], value: {...project} },
                                { path: ["partnerCode"], value: project?.entitiesCode }, //user?.entities?.code
                                { path: ["projectCode"], value: project?.policeNumber },
                                { path: ["isProject"], value: true },
                                
                            ]));
                            break;
    
                        default:
                            basket.subscriptionRequest = { 
                                ...project?.subscription, 
                                projectCode: project?.policeNumber,
                                partnerCode: project?.entitiesCode,
                                isProject: true
                             };
                            basket.packSelected = { 
                                ...project?.packsSelected[0]?.packResponse, 
                                selected: true,
                                packLineRef: null 
                            };
    
                            dispatch(sUpdateState([
                                { path: ["avenant"], value: _avenant },
                                { path: ["category"], value: _category },
                                { path: ["assure"], value: project?.assure },
                                { path: ["contract"], value: {...project} },
                                { path: ["partnerCode"], value: project?.entitiesCode }, //user?.entities?.code
                                { path: ["projectCode"], value: project?.policeNumber },
                                { path: ["isProject"], value: true },
                                
                            ]));
                    }

                    let subscriptionHeaderContext = SubscriptionHeaderContext.fromJson({
                        subscriptionWidget: SubscriptionWidget.SUBSCRIPTION,
                        avenant: _avenant,
                        category: _category,
                        partnerCode: project?.entitiesCode
                    })

                    if(handleNouvelAvenant) {
                        handleNouvelAvenant(subscriptionHeaderContext);
                    }
                    else {
                        
                        history.push({
                            pathname: `/souscription/${category ? category?.code : project?.categoryCode}`,
                            state: subscriptionHeaderContext,
                        })
                    }

                }, (error)=> {});

                
            break;

            case ActionType.DUPLICATE:
                const options = {
                    path: {
                        ref: project.ref
                    }
                };
                SubscriptionService.duplicateProject(options, (data) => {
                    let _pageResponse = (new PageResponse()).fromJson(data);
                    getSubscriptionsProjects(project.categoryCode, null, 0);
                }, (error) => {});
            break;

            case ActionType.DELETE:
                if (project) {
                    let options = {
                        query: {
                            projectId: project?.id
                        }
                    };
                    ContractService.deleteProjectSubscription(options, (data) => {
                        getSubscriptionsProjects(category?.code, null, 0);
                    }, (error) => {
                        toast.error(error)
                    });
                }
            break;

            default:
        }
    }

    const onPageChange = (page) => {
        setCurrent(page);
        changePagePaginationHandler(page - 1)
    };

    const changePagePaginationHandler= (currentPage) => {
        getSubscriptionsProjects(category?.code, null, currentPage);
    }

    const onShowSizeChange = (current) => {
        changePagePaginationHandler(current - 1)
    };

    const onSearch = (e) => {
        const word = e?.target?.value?.toLowerCase()?.trim().replace(" ","");
        let tmpList = [];
        if (word == null || word == undefined || word == '') {
            tmpList = state?.initialData;
        }
        else {
            let newList = state?.initialData.filter((data)=> {
                //console.log(":::: word", word)
                /* var reg = new RegExp('\\b' + word.toString() + '\\b', "gi");
                var resultat = data?.assure.firstName.toLowerCase().match(reg);
                console.log(":::: regex", resultat) */
                const policeNumber = (data?.policeNumber?.toString() ?? '').toLowerCase().replace(" ","");
                const firstName = (data?.assure?.firstName ?? '').toLowerCase().replace(" ","");
                const lastName = (data?.assure?.lastName ?? '').toLowerCase().replace(" ","");
                const startDate = data?.startDate != null ? new Date(data?.startDate).toString().toLowerCase() : '';
                const endDate = data?.endDate != null ? new Date(data?.endDate).toString().toLowerCase() : '';
                const createdAt = data?.createdAt != null ? new Date(data?.createdAt).toString().toLowerCase() : '';
                const productTitle = (data?.productTitle ?? '').toLowerCase().replace(" ","");
                const modelTitle = (data?.vehicle?.model?.title ?? '').toLowerCase().replace(" ","");
                const brandTitle = (data?.vehicle?.model?.brand?.title ?? '').toLowerCase().replace(" ","");
                const chassisNumber = (data?.vehicle?.chassisNumber ?? '').toLowerCase().replace(" ","");
                const matriculation = (data?.vehicle?.matriculation ?? '').toLowerCase().replace(" ","");
                const groupName = (data?.groupName ?? '').toLowerCase().replace(" ","");

                return (
                    policeNumber.indexOf(word.toString()) !== -1 ||
                    firstName.indexOf(word) > -1 ||
                    lastName.indexOf(word) > -1 ||
                    (firstName + lastName).indexOf(word) > -1 ||
                    startDate.indexOf(word) > -1 ||
                    endDate.indexOf(word) > -1 ||
                    createdAt.indexOf(word) > -1 ||
                    productTitle.indexOf(word) > -1 ||
                    modelTitle.indexOf(word) > -1 ||
                    brandTitle.indexOf(word) > -1 ||
                    chassisNumber.indexOf(word) > -1 ||
                    matriculation.indexOf(word) > -1 ||
                    groupName.indexOf(word) > -1
                ) 
            });

            //console.log(":::: newList", newList)

            tmpList = newList;
        }

        if(tmpList.length === 0) {
            getSubscriptionsProjects(null, word);
        }
        else {
            setState(state => ({ ...state, tempProjectData: [...tmpList] }))
        }

        // let globalList = [];
        // let index = 0;
        // if (list.length == 0) {
        //     setList(state?.initialData)
        // }
        
        // const val = e?.target?.value?.toLowerCase()?.trim();
        // if (val == null || val == undefined || val == '') {
        //     globalList = list;
        // }
        // else {
        //     let newList = [];
        //     for (let i = 0; i < list.length; i++) {
        //         if (
        //             state?.initialData[i].policeNumber.toString().toLowerCase().indexOf(val.toString()) !== -1 ||
        //             state?.initialData[i].assure.firstName.toLowerCase().indexOf(val) > -1 ||
        //             state?.initialData[i].assure.lastName.toLowerCase().indexOf(val) > -1 ||
        //             state?.initialData[i].startDate.toLowerCase().indexOf(val) > -1 ||
        //             state?.initialData[i].endDate.toLowerCase().indexOf(val) > -1 ||
        //             state?.initialData[i].productTitle.toString().indexOf(val) > -1 ||
        //             new Date(state?.initialData[i].createdAt).toString().toLowerCase().indexOf(val) > -1 ||
        //             state?.initialData[i].vehicle?.chassisNumber?.toLowerCase().indexOf(val) > -1 ||
        //             state?.initialData[i].vehicle?.matriculation?.toLowerCase().indexOf(val) > -1 ||
        //             state?.initialData[i]?.groupName?.toLowerCase().indexOf(val) > -1
        //         ) {
        //             newList[index] = state?.initialData[i];
        //             index++;
        //         }
        //         globalList = newList;
        //     }
        // }
        

        
    }

    const columns = [
        {
            title: 'N° devis',
            dataIndex: 'policeNumber',
            key: 'policeNumber',
            width: 150,
            filterSearch: true,
            filters: (state?.initialData || []).map((item)=> ({
                text: item?.policeNumber,
                value: item?.policeNumber,
              })),
            onFilter: (value, record) => record?.policeNumber?.startsWith(value),
            render: (item, project) => (
                <div style={{ fontWeight: "bold" }}>{project?.policeNumber}</div>
            )
        },
        {
            title: 'Assuré',
            dataIndex: 'assureFirstname',
            key: 'assureFirstname',
            width: 250,
            filterSearch: true,
            filters: (state?.initialData || []).map((item, i)=> ({
                
                text: item?.assure?.firstName + ' ' + item?.assure?.lastName,
                value: item?.assure?.firstName+ ' ' + item?.assure?.lastName + ' ' + item?.policeNumber,
              })),
            onFilter: (value, record) => (record?.assure?.firstName+ ' ' + record?.assure?.lastName + ' ' + record?.policeNumber)?.startsWith(value),
            render: (item, project) => (<div>{project?.assure?.firstName + ' ' + project?.assure?.lastName}</div>)
        },
        {
            title: 'Groupement',
            dataIndex: 'assureFirstname',
            key: 'assureFirstname',
            render: (item, project) => (
                <div>{project?.hasGroup ? project?.groupName : "-"}</div>
            )
        },
        {
            title: 'Créé le',
            dataIndex: 'date',
            key: 'date',
            render: (item, project) => (
                <div>
                    {(new Date(project?.createdAt).toLocaleDateString()) + " à " + (new Date(project?.createdAt).toLocaleTimeString())}
                </div>
            )
        },
        /* {
            title: "Date d'effet",
            dataIndex: 'startDate',
            key: 'startDate',
            render: (item, project) => (
                <div>
                    {new Date(project.startDate).toLocaleDateString()}
                </div>
            )
        },
        {
            title: "Date d'echeance",
            dataIndex: 'endDate',
            key: 'endDate',
            render: (item, project) => (
                <div>
                    {new Date(project.endDate).toLocaleDateString()}
                </div>
            )
        }, */
        {
            title: "Montant",
            dataIndex: 'montant',
            key: 'montant',
            render: (item, project) => (
                <div>
                    <Tag color="green">{thousandSeparator(project?.primeTTC) + ' Fcfa'}</Tag>
                </div>
            )
        },
        {
            title: "Produit",
            dataIndex: 'produit',
            key: 'produit',
            render: (item, project) => (
                <div>
                    {project?.productTitle + ' ' + project?.categoryCode}
                </div>
            )
        },
        {
            title: "Apporteur",
            dataIndex: 'apporteur',
            key: 'apporteur',
            render: (item, project) => (
                <div>
                    {project?.entitiesTitle}
                </div>
            )
        },
        {
            title: " Actions",
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 150,
            render: (item, project) => (
                <div style={{ display: 'flex' }}>
                    <Space>
                        <Tooltip title="Détails contrat">
                            <EyeOutlined style={{ color: '#40AEE0' }} onClick={() => onAction(ActionType.DETAILS, project)} />
                        </Tooltip>

                        <Tooltip title="Dupliquer le contrat">
                            <Popconfirm
                                placement="top"
                                title="Voulez-vous dupliquer ce projet ?"
                                cancelText="Annuler"
                                okText="Confirmer"
                                okType="primary"
                                onConfirm={() => onAction(ActionType.DUPLICATE, project)}
                            >
                                <CopyOutlined style={{ color: '#40AEE0' }} />
                            </Popconfirm>
                        </Tooltip>

                        <Tooltip title="Modifier le projet" color="green">
                            <EditOutlined style={{ color: '#40AEE0' }} onClick={() => onAction(ActionType.EDIT, project)} />
                        </Tooltip>

                        <Tooltip title="Télecharger le devis">
                            <Dropdown overlay={(
                                    <Menu
                                        items={[
                                            {
                                                key: '1',
                                                label: <div style={{ color: '#3573DF', cursor: 'pointer' }} onClick={() => onDocumentOpen(DocumentType.DEVIS, `${project?.policeNumber?.replace("/", "_")}_${project?.id}.pdf`)}>
                                                    <FileFilled style={{ color: '#3573DF' }} /> Dévis
                                                </div>
                                            },

                                            project.categoryCode === '400' ? {
                                                key: '2',
                                                label: <div style={{ color: '#3573DF', cursor: 'pointer' }} onClick={() => onDocumentOpen(DocumentType.DEVIS, `annexe_${project?.policeNumber?.replace("/", "_")}_${project?.id}.pdf`)}>
                                                    <FileFilled style={{ color: '#3573DF' }} /> Annexe
                                                </div>
                                            } : null,
                                            /* project.categoryCode !== '400' ? {
                                                key: '2',
                                                label: <div style={{ color: '#3573DF', cursor: 'pointer' }} onClick={() => onDocumentOpen(DocumentType.DEVIS, `${project?.policeNumber?.replace("/", "_")}_${project?.id}.pdf`)}>
                                                    <FileFilled style={{ color: '#3573DF' }} /> Dévis
                                                </div>
                                            } : null */
                                        ]}
                                    />
                                )}>
                                    <div > <DownloadOutlined style={{ color: '#3573DF' }} /></div>
                            </Dropdown>

                            {/* {
                                project.categoryCode === '400' ? (
                                    <DownloadOutlined style={{ color: 'green' }} onClick={() => onDocumentOpen(DocumentType.DEVIS, `flotte_${project?.policeNumber?.replace("/", "_")}_${project?.id}.pdf`)} />
                                ) : (
                                    <DownloadOutlined style={{ color: 'green' }} onClick={() => onDocumentOpen(DocumentType.DEVIS, `mono_${project?.policeNumber?.replace("/", "_")}_${project?.id}.pdf`)} />
                                )
                            } */}

                        </Tooltip>

                        <Tooltip title="Rejeter le projet">
                            <Popconfirm
                                placement="top"
                                title="Voulez-vous vraiment supprimer le projet ?"
                                cancelText="Annuler"
                                okText="Confirmer"
                                okType="danger"
                                onConfirm={() => onAction(ActionType.DELETE, project)}
                            >
                                <DeleteOutlined style={{ color: 'red' }} />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                </div>

            )
        },
    ];

    return (
        <React.Fragment>
            {
                title && (
                    <>
                        <div>
                            <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 300 }}>{title}</h4>
                        </div><br />
                    </>
                )
            }
            
            <Search
                addonBefore="Recherche"
                placeholder="Numero Police, Nom Assure, Date création, Date d'effet, Numero d'immatriculation, numéro de chassis ..."
                allowClear
                onSearch={onSearch}
                onChange={onSearch}
                onFocus={onSearch}
                style={{}}/>

            <br />
            <div>
                <Table
                    columns={columns}
                    dataSource={state?.tempProjectData}
                    pagination={{ position: ["topRight"], pageSize: 10 }}
                    scroll={{ x: 1400 }}
                    key="contractTable"
                    loading={state.isProjectLoading}

                /><br />
                <div style={{ textAlign: 'center' }}>
                    <Pagination 
                        current={current} 
                        onChange={onPageChange} 
                        defaultPageSize={DEFAULT_TABLE_MAX_SIZE} 
                        total={state?.totalElements} 
                        showSizeChanger 
                        showTitle={true}
                        onShowSizeChange={onShowSizeChange} />
                </div>
            </div>

            <Drawer
                title='DETAILS PROJET'
                placement="right"
                width={600}
                onClose={() => setDrawerDetailProjectVisibility(false)}
                open={drawerDetailProjectVisibility}
            >
                <SubscriptionDetails subscriptionDetail={state.subcriptionDetail} />

                {/* <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e8e8e8',
                        padding: '10px 16px',
                        textAlign: 'right',
                        left: 0,
                        background: '#fff',
                        borderRadius: '0 0 4px 4px',
                    }}
                >
                    <Popconfirm
                        placement="top"
                        title="Voulez-vous vraiment valider le projet ?"
                        cancelText="Annuler"
                        okText="Confirmer"
                        onConfirm={() => goToStepProjectDetail(state.subcriptionDetail)}
                    >
                        <Button
                            style={{ color: '#ffffff', borderRadius: 8, borderColor: '#0075C9', backgroundColor: '#005680', marginRight: 10, marginTop: 21 }}

                        >
                            <FileDoneOutlined style={{ color: '#ffffff', fontSize: 20 }} /> VALIDER
                        </Button>

                    </Popconfirm>
                </div> */}

            </Drawer>
        </React.Fragment>
        
    )
}