export default class FormRegisterExtra {
    code;
    title;
    description;

    static toJson = (json = {}) => {
        var formRegisterExtra = new FormRegisterExtra();
        formRegisterExtra = json;
        return formRegisterExtra;
    }
}