import React, { useEffect, useState} from "react";
import { Col, Container, Row } from "reactstrap";
import Payment from "views/pages/subscription/component/payment/Payment";
import { ContractType } from "views/pages/subscription/component/ComponentWidget";
import { MDetailOthers, MDetailPackage, MDetailPeriod, MDetailPrime, MDetailStartDate } from "../../common/details";
import { useBasket } from "views/pages/subscription/hooks/useBasket";
import { useSelector } from "react-redux";
import InitAvenantRequest from "api/request/initAvenantRequest";
import AvenantService from "services/avenantService";
import * as constants from 'constantes';
import InitAvenantResponse from "api/response/initAvenantResponse";
import { Card, Spin } from "antd";
import _ from "lodash";
import QuotationResponse from "api/v1/response/QuotationResponse";
import SubscriptionRequestBuilder from "api/request/SubscriptionRequestBuilder";

const StepDetails = ({
    avenantCode,
    initAvenantResponse,
    packSelected,
    subscriptionRequest
}) => {
    const { contract, avenant, assure, period, projectCode } = useSelector((state) => state.subscription);
    const {updateGroup, updatePaymentStatus: paymentStatus, initStartDate, setInitStartDate, onStartDateChange} = useBasket()
    const [quoting, setQuoting]= useState(false);

    useEffect(() => {
        setInitStartDate({
            startDate: subscriptionRequest?.dateEffect,
            endDate: initAvenantResponse?.endDate,
            initialDate: initAvenantResponse?.startDate
        });
        const initAvenantRequest = InitAvenantRequest.fromJson({
            productRef: constants.PRODUCT_AUTO_REF,
            contractRef: contract?.ref,
            avenantCode: avenant?.code,
            assureCode: assure?.code,
            periodRef: period?.ref,
            projectCode: projectCode 
        });
        getPrime(initAvenantRequest);
    }, [])

    const getPrime=(initAvenantRequest)=> {
        setQuoting(true)
        AvenantService.init(initAvenantRequest, (responseData) => {
            const initAvenantResponse = (new InitAvenantResponse()).fromJson(responseData);
            const quotationResponse = QuotationResponse.fromJson(initAvenantResponse?.quotationResponse);
            const packSelected = _.first(quotationResponse?.packsResponse)
            setQuoting(false)
            updateGroup([
                { path: ['basket', 'subscriptionRequest'], value: { 
                    ...subscriptionRequest, 
                    primeTTC    : quotationResponse?.primeTTC,
                    primeNette  : quotationResponse?.primeNette,
                    primeExtra  : quotationResponse?.primeExtra,
                    agencyFees  : quotationResponse?.agencyFees,
                    customerStartDate: subscriptionRequest?.customerStartDate
                }},

                {path: ['basket', 'packSelected'], value: {...packSelected}}
            ]);

            
        }, (error)=> {})
    }


    const onDateEffectChange = (newDateEffect) => {

        const initAvenantRequest = InitAvenantRequest.fromJson({
            productRef: constants.PRODUCT_AUTO_REF,
            contractRef: contract?.ref,
            avenantCode: avenant?.code,
            assureCode: assure?.code,
            periodRef: period?.ref,
            projectCode: projectCode,
            dateEffect: newDateEffect,
            customerStartDate: newDateEffect,
            prorate: true            
        });

        onStartDateChange(newDateEffect);
        getPrime(initAvenantRequest);
    }

    if(quoting) {
       return <Card><Spin /> Calcul nouvelle prime en cours ...</Card>
    } 

    return (
        <React.Fragment>
            <div /* className="page-content" */>
                <Container fluid>

                    <Row>
                        <Col className="col-xxl-9 order-xxl-0 order-first">
                            <Row>
                                <MDetailPackage packTitle={packSelected?.packTitle} />

                                <MDetailPeriod perioTitle={packSelected?.period?.title} />

                                <MDetailStartDate rangeDate={initStartDate} onStartDateChange={onDateEffectChange} />

                            </Row>
                            <MDetailOthers
                                vehicle={subscriptionRequest?.vehicle}
                                assure={subscriptionRequest?.assure}
                            />
                        </Col>
                        <MDetailPrime
                            title="(ristourne)"
                            primeTTC={packSelected?.primeTTC}
                            primeNette={packSelected?.primeNette}
                            extras={packSelected?.extras}
                        />

                    </Row>
                    <Row>
                        <Col className="col-xxl-12">
                            <Payment
                                projectVisibility={false}
                                onlineVisiblity={true}
                                payload={SubscriptionRequestBuilder.fromThis(avenantCode, subscriptionRequest)}
                                paymentStatus={paymentStatus}
                                type={ContractType.MONO}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default StepDetails;