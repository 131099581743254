import React from "react";
import {  Col, Form, Select, Spin } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";
import { useState } from "react";
import { useEffect } from "react";
import PeriodService from "services/periodService";
import { LeftCircleOutlined } from "@ant-design/icons";

const PeriodField=({ handlerFormField, month, periodSelected, suffixIcon, size='large', formItemStyle={label: "Durée du contrat (periode)"}, activateCol, disable, xs, md})=> {
    const { Option } = Select;

    const [state, setState]= useState({
       // PERIOD
       periods: [],
       isPeriodLoading: true,
       isCustomerPeriod: false,
       customerPeriod: ["", ""],
       customerStartDate: null,
       customerEndDate: null,
       nbJour: 0,
    })

    useEffect(()=> {
        getPeriods()
    }, [])

    const getPeriods = () => {
        (new PeriodService()).getAll((data) => {
            let _periods = data ?? [];
            if(month ) {
                _periods = _periods.filter((period)=> period?.months === month);
            }
            setState(state => ({ ...state, periods: _periods, isPeriodLoading: false }))
        }, (error) => {

        })
    }
    
    const onPeriodSelected = (periodRef) => {
        const _periodSelected = state.periods.find((period) => period.ref === periodRef);
        if (_periodSelected) {
            if(handlerFormField) {
                handlerFormField({
                    periodRef: _periodSelected?.ref,
                    periodTitle: _periodSelected?.title
                });
            }
            
            if(periodSelected) {
                periodSelected(_periodSelected)
            }
        }
    }

    let onCustomerPeriodSelected = (value, dateString) => {
        handlerFormField( { 
            customerStartDate: dateString[0],
            customerEndDate: dateString[1]
        })
    }

    const customInputLabel = (
        <div style={{ display: 'flex' }}>
            {state.isCustomerPeriod ? (<h4>Choisissez votre periode</h4>) : (<h4>Saisissez la marque</h4>)}
            <LeftCircleOutlined style={{ marginLeft: 10, marginTop: 5, cursor: 'pointer', color: 'orange' }} onClick={() => setState(state => ({ ...state, isCustomerPeriod: false }))} />
        </div>
    )

    const formItem=(
        <Form.Item
            //label={state.isCustomerPeriod ? customInputLabel : "Durée du contrat (periode)"}
            name="periodRef"
            rules={[{ required: true, message: "Champ obligatoire" }]}
            {...formItemStyle}
        >
            {state.isPeriodLoading ? <Spin /> :
                (<SelectWithFilter 
                    placeholder='Periode de couverture' 
                    onChange={(e) => { onPeriodSelected(e) }} 
                    disabled={disable}
                    size={size}
                    suffixIcon={suffixIcon}>
                        {(state.periods || []).map((period, index) => (<Option value={period?.ref} key={index}>{period?.title}</Option>))}
                    </SelectWithFilter>)}
        </Form.Item>
    )

    return (
        <React.Fragment>
            {activateCol ? (<Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>{formItem}</Col>): formItem}
        </React.Fragment>
    )
}

export default PeriodField;