import {  Form, Input } from "antd";
import React from "react";

const MerchantFirstNameField=({disable})=> {
    return (
        <React.Fragment>
            <Form.Item
                label="Nom apporteur"
                name="merchantFirstname"
                rules={[{ required: true, message: "Champ obligatoire" }]}
            >
                <Input size='medium' placeholder='Nom' disabled={disable} />
            </Form.Item>
        </React.Fragment>
    )
}

export default MerchantFirstNameField;