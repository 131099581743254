export default class Assistance {
    id;
    code;
    title;
    description;
    assistanceLines = []

    fromJson = (json={})=> {
        var assistance = new Assistance();
        assistance = json;
        return assistance
    }
}