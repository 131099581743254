import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class AssureService {
     // Recupere tous les assure
     readAllAssure(options={}, sCallBack, eCallBack ) {
        ApiRoutes.assureResource.resourceOptions = Object.assign({}, ApiRoutes.assureResource.resourceOptions, options);
        exeRequest(ApiRoutes.assureResource, {}, sCallBack, eCallBack)
    }

    // Recupere tous les assure
    seachLoupAssure(options={}, sCallBack, eCallBack ) {
        ApiRoutes.assureLoupResource.resourceOptions = Object.assign({}, ApiRoutes.assureLoupResource.resourceOptions, options);
        exeRequest(ApiRoutes.assureLoupResource, {}, sCallBack, eCallBack)
    }

    // Recherche les assures par mot
    static seachableAssure(options={}, sCallBack, eCallBack ) {
        ApiRoutes.searchableAssureResource.resourceOptions = Object.assign({}, ApiRoutes.searchableAssureResource.resourceOptions, options);
        exeRequest(ApiRoutes.searchableAssureResource, {}, sCallBack, eCallBack)
    }

    // Search assure
    searchByPhoneEmailAndCode(word, sCallBack, eCallBack) {
        let options = {
            query: {
                q: word
            }
        }
        ApiRoutes.searchAssureResource.resourceOptions = Object.assign({}, ApiRoutes.searchAssureResource.resourceOptions, options);
        exeRequest(ApiRoutes.searchAssureResource, {}, sCallBack, eCallBack)
    }
}