import { Form, Input } from "antd";
import React from "react";

const MerchantCodeField=({disable})=> {
    return (
        <React.Fragment>
            <Form.Item
                label=""
                name="merchantCode"
                rules={[{ required: false, message: "" }]}
            >
                <Input size='small' disabled={disable} />
            </Form.Item>
        </React.Fragment>
    )
}

export default MerchantCodeField;