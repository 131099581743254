import { createSlice } from '@reduxjs/toolkit'
import User from 'api/model/user';

const initialState = {
    accessToken: "",
    profile: "",
    user: new User(),
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signIn: (state, action) => {
            console.log('data in singin  ---> ', action);
            try {
                const auth = { 
                    user: action.payload.content, 
                    accessToken: action.payload.accessToken, 
                    profile: action.payload.profile
                }

                const serializedState = JSON.stringify({ auth });
                localStorage.setItem('state', serializedState);

                state.accessToken = auth.accessToken;
                state.user = auth.user;
                state.profile = auth.profile;

            } catch (error) { }
        },
        logout: (state) => {
            localStorage.clear();
            state.accessToken = initialState.accessToken;
            state.user = initialState.user;
        }
    },
})

// Action creators are generated for each case reducer function
export const { signIn, logout } = authSlice.actions

export default authSlice.reducer