import React from "react";
import {  Col, Form, Select } from "antd";
import SelectWithFilter from "modules/components/CustomsSelects/SelectWithFilter";

const GpsField=({disable, xs, md})=> {
    const { Option } = Select;

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Vehicule a t-il un tracer GPS ?"
                    name="hasGps"
                    rules={[{ required: false, message: "Champ obligatoire" }]}
                >
                    <SelectWithFilter /*defaultValue={false}*/ disabled={disable}>
                        <Option value={true} >Oui</Option>
                        <Option value={false} >Non</Option>
                    </SelectWithFilter>
                </Form.Item>
            </Col>
            
        </React.Fragment>
    )
}

export default GpsField;