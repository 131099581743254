export default (theme) => ({
  root: {
    fontFamily: "Helvetica Neue",
    // backgroundImage: `url(${bg_geocrm})`,
    background: `#6D9CCA`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  formCard: {
    width: 400,
    paddingTop: 50,
    display: "flex",
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: 25,
    [theme.breakpoints.only('xs')]: {
      fontSize: 20,
    },
    fontWeight: 700,
    color: 'rgb(255, 102, 104)',
    marginBottom: 20,
    '& i': {
      color: 'rgb(245, 165, 0)',
      fontSize: 37,
      [theme.breakpoints.only('xs')]: {
        fontSize: 30,
      },
    }
  },
  bannerContainer: {
    // width: 400,
    // display: "flex",
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
    marginBottom: 20,
    borderBottom: '1px solid gray'
  },
  logo: {
    width: 180,
    height: 40,
    [theme.breakpoints.only('xs')]: {
      width: 200,
      height: 100,
    },
  },
  loginBtn: {
    textAlign: 'center'
  }
});
