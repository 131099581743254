import { Form,Col, Row, } from "antd";
import CustomAntdForm from "modules/components/Forms/CustomAntdForm";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, } from "reactstrap";
import vertication from "../../../../../../../assets/images/verification-img.png";
import PeriodField from "../../../../common/fields/PeriodField";
import SocialPositionField from "../../../../common/fields/SocialPositionField";
import { useBasket } from "../../../../hooks/useBasket";
import { LoadingOutlined } from "@ant-design/icons";

const FStepIntro=({formField})=> {
    const [form] = Form.useForm();
    const {basket, baskets} = useSelector((state) => state.basketSlice);
    const {updateFormField, goToNextStep, updateBaskets, updateBasketsQuotationRequest} = useBasket();

    const [state, setState]=useState({
        quoting: false
    })

    const onConfirmPeriod= ()=> {
        form.validateFields().then((values)=> {
            if(baskets.length > 0) {
                const _baskets = [];
                for(const __basket of baskets) {
                    const b = {
                        ...__basket,
                        formField: {
                            ...__basket.formField,
                            socialPosRef: basket?.formField?.socialPosRef,
                            socialPosTitle: basket?.formField?.socialPosTitle,
                            periodRef: basket?.formField?.periodRef,
                            periodTitle: basket?.formField?.periodTitle,
                        }
                    }
                    _baskets.push({...b});
                }
                console.log("......_baskets: ", baskets)
                updateBaskets(_baskets)
                updateBasketsQuotationRequest();
                setState(state=> ({...state, quoting: true}))
                goToNextStep(null) 
            }
        }).catch((error)=> {})
    }

    return (
        <React.Fragment>
            <Card>
                <CardHeader>
                    <h4>Souscription flotte </h4>
                </CardHeader>
                <CardBody>
                    <div >
                        <CustomAntdForm form={form} initialValues={formField}>
                            <div style={{display: 'flex', justifyContent: "center", flexDirection: "column"}}>
                                <div className="text-center">
                                    <h4 className="mt-4 fw-semibold">Souscription flotte</h4>
                                    <p className="text-muted mt-3">
                                        Les véhicules éligibles à la reduction flottes sont ceux de la catégorie 401, 402, 403.{" "}
                                    </p>
                                </div>
                                <div className="mt-4 text-left" >
                                    <Row gutter={8} className="d-flex flex-wrap justify-content-center row">
                                        <SocialPositionField handlerFormField={updateFormField} xs={12} md={5}/>
                                        <PeriodField handlerFormField={updateFormField} xs={12} md={5}/>
                                    </Row>
                                </div>
                                <div className="mt-4 text-center">
                                    <button
                                        onClick={onConfirmPeriod}
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        {state.quoting ? (<LoadingOutlined style={{ color: '#ffffff', fontSize: 20 }} />) : null}
                                            {'Valider pour continuer'.toLocaleUpperCase()}
                                    </button>
                                </div>
                            </div>
                        </CustomAntdForm>

                        <Row className="justify-content-center mt-5 mb-2">
                            <Col md={8}>
                                <img src={vertication} alt="" className="img-fluid" />
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default FStepIntro;