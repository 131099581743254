export default class FormEditPack {
    id;
    code;
    categorie;
    description;

    fromJson = (json = {}) => {
        var formEditPack = new FormEditPack();
        formEditPack = json;
        return formEditPack;
    }
}