import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import _ from 'lodash';
import TableTransfer from 'views/pages/subscription/common/TableTransfer';
import QuotationService from 'services/quotationService';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';
import GuaranteeService from 'services/guaranteeService';
import QuotationResponse from 'api/v1/response/QuotationResponse';
import { toast } from 'react-toastify';
import BackNextWidget from 'views/pages/subscription/flotte/common/BackNextWidget';
import HeaderPrime from 'views/pages/subscription/common/HeaderPrime';
import { RefreshOutlined } from '@material-ui/icons';
import { LoadingOutlined } from '@ant-design/icons';
import StartDateField from '../../common/StartDateField';
import { useSelector } from 'react-redux';
import SubscriptionRequestBuilder from 'api/request/SubscriptionRequestBuilder';

const leftTableColumns = [
    {
        dataIndex: 'code',
        title: 'Code',
    },
    {
        dataIndex: 'description',
        title: 'Description',
    },
];
const rightTableColumns = [
    {
        dataIndex: 'code',
        title: 'Code',
    },
    {
        dataIndex: 'description',
        title: 'Description',
    },
];

const StepListGuaranties = ({ basket, onValidate }) => {
    const {contract, avenant, category, partnerCode, isProject, projectCode } = useSelector((state) => state.subscription);
    const {goToNextStep, updateBasket} = useBasket()
    const [moveKeysCollect, setMoveKeysCollect] = useState(_.map(basket?.packSelected?.guaranties || [], (g)=> g?.code))
    const [targetKeys, setTargetKeys] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [packSelected, setPackSelected] = useState(basket?.packSelected);
    const [ packSelectedGuaranties, setPackSelecedGuaranties] = useState(basket?.packSelected?.guaranties || [])
    const [state, setState] = useState({
        isQuoting: false,
        primeTTC: 0,
        primeNette: 0,
        primeExtra: 0,
        extras: [],
        primeVisibility: false,
        guaranties: [],
        isGuaranteeLoading: true,

        isPrimeCalculating: false
    })

    useEffect(() => {
        setState(state => ({...state, isGuaranteeLoading: true}));
        GuaranteeService.getAll({},(data) => {
            const _allGuaranties = (data || []).map((guarantee, i)=> ({...guarantee, key: guarantee?.code, disabled: ['RC', 'CED'].includes(guarantee?.code) }));//disabled: ['RC', 'CED'].includes(guarantee?.code) //guarantee?.required
            const packGuarantiesCode = packSelectedGuaranties.map((guarantee, i)=> guarantee?.code);
           
            setState(state=> ({...state, guaranties: [..._allGuaranties], isGuaranteeLoading: false}))
            setDataSource([..._allGuaranties] )
            setTargetKeys(_allGuaranties.filter((guarantee)=> !packGuarantiesCode.includes(guarantee?.key)).map((g)=> g?.code))
        }, () => {
            setState(state => ({...state, isGuaranteeLoading: false}));
        });
    }, []);

    const onTransferChange = (nextTargetKeys, direction, moveKeys) => {
        console.log(":::: nextTargetKeys : ", nextTargetKeys)
        console.log(":::: moveKeys : ", moveKeys)
        setTargetKeys(nextTargetKeys);
        var _moveKeysCollect = [];
        if(direction === 'right') { // Soustraire la Key de la list de collect
            _moveKeysCollect = moveKeysCollect.filter((key)=> !moveKeys.includes(key))
        }
        if(direction === 'left') { // REDUCTION
            _moveKeysCollect = moveKeysCollect;
            _.forEach(moveKeys, (key)=>{
                if(!moveKeysCollect.includes(key)) _moveKeysCollect.push(key);
            })
        }
        setMoveKeysCollect(_moveKeysCollect)
        
    }

    const treatReductionExtensionGuaranties=()=> {
        const reductionGuaranties = packSelectedGuaranties?.filter((packSelectedGuarantee)=> !moveKeysCollect?.includes(packSelectedGuarantee?.code));
        var redCustomerGuaranties = (reductionGuaranties || []).map((guarantee)=> {
            const engagement = (guarantee?.engagements || []).find((engagement)=> engagement?.default === true);
            return ({
                guaranteeRef: guarantee?.ref,
                engagementRef: engagement?.ref,
                franchise: guarantee?.franchise
            })
        })
        var extensionGuaranties = [];
        _.forEach(moveKeysCollect, (moveKey)=> {
            const index = _.findIndex(packSelectedGuaranties, (packSelectedGuarantee)=> packSelectedGuarantee?.code === moveKey);
            if(index === -1) {
                const _guarantee = _.find(state.guaranties, guarantee=> guarantee?.code === moveKey)
                extensionGuaranties.push({
                    guaranteeRef: _guarantee?.ref,
                    engagementRef: null,
                    franchise: true
                })
            }
        })

        console.log(":::: redCustomerGuaranties", redCustomerGuaranties)
        console.log(":::: extensionGuaranties", extensionGuaranties)

        return {redCustomerGuaranties, extensionGuaranties}
    }

    const onCalculatePrime=()=> {
        const {redCustomerGuaranties, extensionGuaranties} = treatReductionExtensionGuaranties()
        
        // Quote extension / reduction guarantees
        setState(state => ({...state, isPrimeCalculating: true}));
        if(_.size(redCustomerGuaranties) <= 0 && _.size(extensionGuaranties) <= 0) {
            setState(state => ({...state, primeTTC: 0 , primeVisibility: true, isPrimeCalculating: false}));
        }
        else if(_.size(redCustomerGuaranties) <= 0 || _.size(extensionGuaranties) <= 0) {
            const quotationRequest={
                ...basket?.quotationRequest,
                customGuaranties: [...redCustomerGuaranties, ...extensionGuaranties ],
                packLineRef: basket.packSelected?.packLineRef,
                prorate: true
            }
            QuotationService.quote({quotationRequests: [quotationRequest], t: 'm', prorate: true},(result)=> {
                const quotationResponse = QuotationResponse.fromJson(result);
                const primeTTC = quotationResponse?.primeTTC;
                const primeExtra = quotationResponse?.primeExtra;
                const primeNette = quotationResponse?.primeNette;
                const extras = quotationResponse?.extras || []
               
                setState(state => ({
                    ...state, 
                    primeTTC: ((_.size(redCustomerGuaranties) > 0 ? primeTTC * (-1) : primeTTC )), 
                    primeExtra: primeExtra,
                    primeNette: primeNette,
                    extras: extras,
                    primeVisibility: true,
                    isPrimeCalculating: false}));

            }, (error)=> {
                setState(state => ({...state, isPrimeCalculating: false}));
            })
        }
        else { // Quote simultanement reduction et extension guarantee
            const redQuotationRequest={
                ...basket?.quotationRequest,
                customGuaranties: [...redCustomerGuaranties],
                packLineRef: basket.packSelected?.packLineRef,
                prorate: true
            }
            QuotationService.quote({quotationRequests: [redQuotationRequest], t: 'm', prorate: true}, (result)=> {
                const redQuotationResponse = QuotationResponse.fromJson(result);
                const redPrimeTTC = (redQuotationResponse?.primeTTC ?? 0) * (-1)
                const redPrimeExtra = redQuotationResponse?.primeExtra;
                const redPrimeNette = redQuotationResponse?.primeNette;
                const redExtras = redQuotationResponse?.extras || []
                // REQ 2:
                
                const extQuotationRequest={
                    ...basket?.quotationRequest,
                    customGuaranties: [...extensionGuaranties],
                    packLineRef: basket.packSelected?.packLineRef
                }
                QuotationService.quote({quotationRequests: [extQuotationRequest], t: 'm', prorate: true},(result)=> {
                    const extQuotationResponse = QuotationResponse.fromJson(result);
                    const extPrimeTTC = (extQuotationResponse?.primeTTC ?? 0) * (-1)
                    const extPrimeExtra = extQuotationResponse?.primeExtra ?? 0
                    const extPrimeNette = extQuotationResponse?.primeNette ?? 0
                    const extExtras = extQuotationResponse?.extras || []
                   
                    setState(state => ({
                        ...state, 
                        primeTTC: extPrimeTTC + (redPrimeTTC),
                        primeExtra: redPrimeExtra + extPrimeExtra,
                        primeNette: redPrimeNette + extPrimeNette,
                        extras: [...redExtras, ...extExtras], 
                        primeVisibility: true, 
                        isPrimeCalculating: false
                    }));
    
                }, (error)=> {
                    setState(state => ({...state, isPrimeCalculating: false}));
                })
            }, (error)=> {
                toast.error("Une erreur survenue pendant le calcul de prime")
                setState(state => ({...state, isPrimeCalculating: false}));
            })
        }
    }

    const onNextSubmit=()=> {
        const {redCustomerGuaranties, extensionGuaranties} = treatReductionExtensionGuaranties()
        
        const _basket = {
            ...basket,
            packSelected: {
                ...basket?.packSelected,
                primeTTC: state?.primeTTC,
                primeExtra: state?.primeExtra,
                primeNette: state?.primeNette,
                extras: state?.extras,
                customGuaranties: [...redCustomerGuaranties, ...extensionGuaranties]
            }
        }

        const _subscriptionRequest = SubscriptionRequestBuilder.fromBasket({
            category    : category,
            contract    : contract,
            avenant     : avenant,
            partnerCode : partnerCode,
            isProject   : isProject,
            projectCode : projectCode,
            isProrate   : true,
            basket      : _basket,
            
        });

        updateBasket({
            ..._basket, 
            subscriptionRequest: {
                ..._subscriptionRequest,
                customerStartDate: _basket?.formField?.startDate
            }
        })
        
        goToNextStep(null)
    }
    
    return (
        <React.Fragment>
            <HeaderPrime prime={state?.primeTTC} visible={state?.primeVisibility} />
            <Card title={(<div className="d-flex align-items-start flex-row-reverse gap-3">
                        <button
                            disabled={_.size(moveKeysCollect) <= 0}
                            onClick={()=> onCalculatePrime()}
                            type="button"
                            className="btn btn-primary btn-label btn-sm right nexttab"
                        >
                            <i className="ri-arrow-up-line label-icon align-middle fs-16 ms-2"></i>{" "}
                            {state?.isPrimeCalculating ? (<LoadingOutlined style={{ color: '#ffffff', fontSize: 20 }} />) : (<RefreshOutlined style={{ color: '#ffffff', fontSize: 20 }} />)} Calculer la prime
                        </button>
                        <div>
                            <StartDateField size={'medium'}/>
                        </div>
                    </div>)} headStyle={{height: "22px"}}>
                
                <TableTransfer
                    titles={[(<span style={{fontWeight: "bold"}}>{`${packSelected?.packTitle} : Garanties`}</span>), 'Garanties à rétirer']}
                    loading={state.isGuaranteeLoading}
                    dataSource={state?.guaranties}
                    targetKeys={targetKeys}
                    //selectedKeys={["0", "1"]}
                    //initialTargetKeys={state?.initialTargetKeys}
                    // disabled={disabled}
                    onChange={onTransferChange}
                    showSearch={true}
                    filterOption={(inputValue, item) => {
                        const inputValueFormatted = inputValue?.toLowerCase();
                        return (item?.title?.toLowerCase().includes(inputValueFormatted)) || item?.description?.toLowerCase().includes(inputValueFormatted)
                    }}
                    leftColumns={leftTableColumns}
                    rightColumns={rightTableColumns}
                />

                <BackNextWidget 
                    confirmText={"CONTINUER"}
                    onConfirm={onNextSubmit}
                    disableConfirm={targetKeys.length <= 0}
                />

            </Card>
        </React.Fragment>
    );
};
export default StepListGuaranties;