export default class BrandEditForm {
    id;
    code;
    title;
    description;

    fromJson = (json = {}) => {
        var brandEditForm = new BrandEditForm();
        brandEditForm = json;
        return brandEditForm;
    }
}