import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {},

    contentContainer: {
        padding: 5,
        position: "absolute",
        left: 280,
        right: 0,
        top: 0,
        bottom: 0,
    },

    content: {
        margin: 0,
        display: "flex",
        flexDirection: 'column',
        minHeight: '100vh',
    },

    routesContentContainer: {
        flex: 1 //, boxShadow: "0px 1px 1px gray", borderRadius: 5
    },

    routesContent: {
        //border: '2px solid #F1F1F1', 
        padding: 10, 
        borderRadius: 5, 
        minHeight: 300
    },

    footer: {},

    spacerHeight: { height: 10 },
    topSpacerHeight: { height: 105 },
}));

export default useStyles
