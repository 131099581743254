import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import React, { useEffect, useState } from "react";
import AssureTable from "views/pages/common/AssureTable";
import Category from "api/model/category.model";
import PageResponse from "api/response/pageResponse";
import ContractsResponse from "api/response/contratsResponse";
import ProjectTable from "views/pages/common/ProjectTable";
import ContractTable from "views/pages/common/ContractTable";
import { useDispatch } from "react-redux";
import { sRaz } from "redux/slices/subscriptionSlice";
import { basketRaz } from "redux/slices/basketSlice";
import SubscriptionHeader from "./SubscriptionHeader";
import { SubscriptionSwitchOption } from 'helpers/helpers';

const ListSubscription = ({ category, handleNouvelAvenant }) => {
    const dispatch = useDispatch();
    const [switchValue, setSwitchValue] = useState(SubscriptionSwitchOption.LIST_SUBSCRIPTION);

    const [state, setState] = useState({
        // CATEGORIE
        category: new Category(),
        //categoryLoading: false,
        categoryLoadingError: false,
        categoryLoadingErrorMessage: "",

        // CONTRACT
        contractPageResponse: new PageResponse(),
        tempContractData: [],
        isContractLoading: true,
        contractLoadingError: false,
        contractLoadingErrorMessage: "",
        contractSelected: new ContractsResponse()
    });

    useEffect(() => {
        setState((state) => ({ ...state, category: category }))
        dispatch(basketRaz())
        dispatch(sRaz())
    }, []);

    return (
        <div>
            <React.Fragment>
                <SubscriptionHeader 
                    switchValue={switchValue} 
                    onSwitchValue={setSwitchValue} 
                    category={category}
                    handleNouvelAvenant={handleNouvelAvenant} />

                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <div>
                                    <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: "auto" }}>{(`Categorie ${state.category.code} : ${state.category.title}`).toLocaleUpperCase()}</h4>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    { (switchValue === SubscriptionSwitchOption.LIST_SUBSCRIPTION) && (<ContractTable categoryRef={category?.ref} key={"contrat"}/>) }
                                    { (switchValue === SubscriptionSwitchOption.LIST_ASSURE) && (<AssureTable category={category} key={"assure"}/>) }
                                    { (switchValue === SubscriptionSwitchOption.LIST_PROJECT) && (<ProjectTable category={category} handleNouvelAvenant={handleNouvelAvenant} key={"project"}/>) }

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        </div>
    )
}

export default ListSubscription;