import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "http/requestConfig";

export default class VehicleService {
    
    static verifyMatriculation({matriculation, isTrailer=false}, sCallBack, eCallBack) {
        const _options = {query:{mat: matriculation, isTrailer: isTrailer}};
        ApiRoutes.verifyMatriculationResource.resourceOptions = Object.assign({}, ApiRoutes.verifyMatriculationResource.resourceOptions, _options);
        exeRequest(ApiRoutes.verifyMatriculationResource, {}, sCallBack, eCallBack)
    }
}