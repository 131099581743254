import React, { useEffect, useState } from "react";
import {  Collapse, Divider, Row, Col, Drawer,  Table, Tabs, Tag, Form } from "antd";
import { thousandSeparator } from "helpers/helpers";
import VehicleDetailsWidget from "../VehicleDetailsWidget";
import _ from "lodash";
import Vehicle from "api/model/vehicle.model";
import RegisterMerchant from "./RegisterMerchant";
import DetailCoInsurance from "views/pages/Component/CoInsurance/DetailCoInsurance";
import { fullFormatDate } from "helpers/date_helpers";
import AddAnnulationMotif from "./AddAnnulationMotif";
import { CloseCircleOutlined, EditFilled, LoadingOutlined } from "@ant-design/icons";
import { CAN_EDIT_MERCHANT, CAN_EDIT_STARTDATE_AND_PERIOD } from "profileActions";
import { useSelector } from "react-redux";
import { DateEffectField, PeriodField } from "../../common/fields";
import SubscriptionService from "services/subscriptionService";
import CustomAntdForm from "modules/components/Forms/CustomAntdForm";
import { toast } from "react-toastify";

const DetailsSubscription=({subscriptionSelected, visibility, onClose})=> {
    const [form] = Form.useForm();
    const { user } = useSelector((state) => state.auth);
    const { TabPane } = Tabs;
    const { Panel } = Collapse;
    const [ editStartDate, setEditStartDate] = useState(false)
    const [ editPeriod, setEditPeriod] = useState(false)

    const [state, setState]= useState({
        vehicles: [],
        editionCancelMotif: false,
        editMerchant: false,

        isSubscriptionEditing: false
    })

    useEffect(()=> {
        console.log(":::: subscriptionSelected " , subscriptionSelected)
        const packsSelected = subscriptionSelected?.packsSelected || [];

        const _vehicles = packsSelected.map(packSelected => {
            const v = Vehicle.fromJson(packSelected?.vehicle);
            return {...v, categoryTitle: packSelected?.packResponse?.category?.title, packTitle: packSelected?.packResponse?.packTitle}
        });
        setState(state=> ({...state, vehicles: _vehicles}))
    }, [])
    
    const columns = [
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Titre',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Prime brute',
            dataIndex: 'primeNet',
            key: 'primeNet'
        },
        {
            title: 'Prime nette',
            dataIndex: 'amount',
            key: 'amount'
        }
    ];

    const columnsReduction = [
        {
            title: 'Titre',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Pourcentage',
            dataIndex: 'rate',
            key: 'rate',
            render: (item, reduction) => {
                return ((reduction.rate) * 100) + " %";
            }
        }
    ];

    const columnsAssistances = [
        {
            title: 'Details assistance',
            dataIndex: 'guaranteeName',
            key: 'guaranteeName'
        },
    ]

    const onTabChange = () => {

    }

    const motifApplying=(v)=> {
       console.log("::: Apply cancel motif")
    }

    const applySubcriptionModif=()=> {
        const values = form.getFieldsValue();
        console.log(":::: value :", values)
        const paylaod = {
            contractDetailRef: subscriptionSelected?.ref,
            periodRef: editPeriod ? values?.periodRef : null,
            startDate: editStartDate ? values?.startDate : null
        }
        SubscriptionService.edit(paylaod, (data)=> {
            toast.success("Modification effectué avec succès")
            onClose(true)
        }, (error)=> {
            toast.error(error ?? "Echec de modification du contrat")
        })
    }
    
    return (
        <React.Fragment>
            <Drawer
                title='DETAILS AVENANT'
                placement="right"
                width={600}
                onClose={()=> onClose()}
                visible={visibility}
            >
                <div>
                    <CustomAntdForm  form={form} initialValues={{periodRef: subscriptionSelected?.period?.ref}} >
                        <Row gutter={[12, 12]}>
                            <Col md={24}>
                                <div style={{ display: 'flex', justifyContent:'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                    <p >Avenant :</p>
                                    <p >{`(${subscriptionSelected?.numAvenant}) ${subscriptionSelected?.avenantTitle}`}</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div style={{ display: 'flex',  justifyContent:'space-between' }}>
                                    <p >Partenaire :</p>
                                    <p >{subscriptionSelected?.entitiesTitle}</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div style={{ display: 'flex', justifyContent:'space-between' }}>
                                    <p >Apporteur :</p>
                                    <p >{(subscriptionSelected?.merchantFirstname ?? "-")} {(subscriptionSelected?.merchantLastname ?? "-")}</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                {!editStartDate && (
                                    <div style={{ display: 'flex', justifyContent:'space-between' }}>
                                        <p >Date debut :</p>
                                        <p >{ fullFormatDate(subscriptionSelected?.startDate) } 
                                            {CAN_EDIT_STARTDATE_AND_PERIOD.includes(user?.profile) && <EditFilled onClick={()=> setEditStartDate(!editStartDate)}  style={{color: 'blue'}} />}
                                        </p>
                                    </div>)}
                                {editStartDate && (<DateEffectField 
                                                        label={(<div>Edition de date d'éffet <CloseCircleOutlined onClick={()=> setEditStartDate(!editStartDate)} style={{color: 'red'}}/></div> )} 
                                                        size="medium"
                                                        formField={{periodRef: subscriptionSelected?.period?.ref}}
                                                        rangeDate={{startDate: null, endDate: subscriptionSelected?.endDate}}
                                                    />)}
                                
                            </Col>
                            <Col md={12}>
                                <div style={{ display: 'flex', justifyContent:'space-between' }}>
                                    <p >Date fin :</p>
                                    <p >{fullFormatDate(subscriptionSelected?.endDate) }</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                {!editPeriod && (
                                    <div style={{ display: 'flex', justifyContent:'space-between' }}>
                                        <p >Durée contrat :</p>
                                        <p >
                                            {subscriptionSelected?.months > 0 ? subscriptionSelected?.months + " mois" : subscriptionSelected?.days + ' Jours'} 
                                            {CAN_EDIT_STARTDATE_AND_PERIOD.includes(user?.profile) && <EditFilled onClick={()=> setEditPeriod(!editPeriod)} style={{color: 'blue'}} />}
                                        </p>
                                    </div>
                                )}
                                {editPeriod && (
                                    <PeriodField 
                                        size="medium"
                                        formItemStyle={{label: (<div>Edition de date d'éffet <CloseCircleOutlined onClick={()=> setEditPeriod(!editPeriod)} style={{color: 'red'}}/></div> )}}
                                        />
                                )}
                            </Col>
                            {(editStartDate || editPeriod) && (
                                <Col md={24}>
                                    <div className="d-flex flex-row-reverse">
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-label right nexttab btn-sm"
                                            
                                            onClick={() => applySubcriptionModif()}
                                            onLoad
                                        >
                                            <i className="bx bx-plus label-icon align-middle fs-16 ms-2"></i>{" "}
                                            {state.isSubscriptionEditing && (<LoadingOutlined style={{ color: '#ffffff', fontSize: 20 }} />)}
                                            { "Appliquer"}
                                        </button>
                                    </div>
                                </Col>
                            )}
                            
                        </Row>
                    </CustomAntdForm>

                    <Divider orientation="left">Autres details </Divider>

                    <Tabs defaultActiveKey="1" onChange={onTabChange} tabPosition="left">

                        <TabPane tab="Transaction" key="1">
                            <Row>
                                <Col md={24}>
                                    <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Moyen de paiement </p>
                                        <p>{subscriptionSelected?.paymentProviderTitle}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={24}>
                                    <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Ref. transaction </p>
                                        <p>{subscriptionSelected?.transactionId}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={24}>
                                    <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Prime nette:  </p>
                                        <p ><Tag color={'green'}>{(thousandSeparator(subscriptionSelected?.primeNette) + ' FCFA')}</Tag></p>
                                    </div>
                                </Col>
                            </Row>
                            {
                                (subscriptionSelected?.extras || []).map(function (extra, index) {
                                    // console.log("Package item : ", item)
                                    return (
                                        <Row>
                                            <Col md={24}>
                                                <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>{extra?.title}({extra?.code})  </p>
                                                    <p >{thousandSeparator(extra?.amount) + ' FCFA'}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }

                            <Row>
                                <Col md={24}>
                                    <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>TTC à payer  </p>
                                        <p ><Tag color={'green'}>{(thousandSeparator(subscriptionSelected?.primeTTC) + ' FCFA')}</Tag></p>
                                    </div>
                                </Col>
                            </Row>

                        </TabPane>

                        <TabPane tab="Garanties" key="2">
                            <Table columns={columns} dataSource={subscriptionSelected?.guaranties} rowKey="id" />
                        </TabPane>
                        <TabPane tab="Reduction" key="4">
                            <Table columns={columnsReduction} dataSource={subscriptionSelected?.reductionGroups} />
                        </TabPane>

                        <TabPane tab="Véhicule" key="3">
                            {
                                subscriptionSelected?.categoryCode === '400' ? (
                                    <Collapse>
                                        {
                                            (state?.vehicles || []).map((vehicle, index) => {
                                                return (
                                                    <Panel header={`${vehicle?.model?.brand?.title} / ${vehicle?.model?.title}`} key={index}>
                                                        <VehicleDetailsWidget vehicle={vehicle}/>
                                                    </Panel>
                                                );
                                            })
                                        }
                                    </Collapse>
                                ) : <VehicleDetailsWidget vehicle={_.first(state?.vehicles)}/>
                            }
                        </TabPane>
                        
                        <TabPane tab="Assistance" key="5">
                            {
                                (subscriptionSelected?.assistances || []).map((assistance) => (
                                    <>
                                        <Row>
                                            <Col md={24}>
                                                <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Formule:</p>
                                                    <p >{thousandSeparator(assistance?.title)}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Table columns={columnsAssistances} dataSource={assistance?.assistanceLines} />
                                    </>
                                ))
                            }

                        </TabPane>
                        <TabPane tab="Assuré" key="6">
                            <Row>
                                <Col md={24}>
                                    <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Code:  </p>
                                        <p style={{ fonWeight: 'bold' }}>{subscriptionSelected?.assure?.code}</p>
                                    </div>
                                </Col>
                                <Col md={24}>
                                    <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Statut:  </p>
                                        <p style={{ fonWeight: 'bold' }}>{subscriptionSelected?.assureSocialPosTitle}</p>
                                    </div>
                                </Col>

                                <Col md={24}>
                                    <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Nom:  </p>
                                        <p >{subscriptionSelected?.assureFirstname} </p>
                                    </div>
                                </Col>
                                <Col md={24}>
                                    <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Prenoms: </p>
                                        <p >{subscriptionSelected?.assureLastname}</p>
                                    </div>
                                </Col>

                                {/* <Col md={12}>
                                <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Adresse:  </p>
                                        <p >{state?.assure?.address}</p>
                                    </div>
                                </Col> */}
                                {/* <Col md={12}>
                                    <div style={{ display: 'flex', width: '90%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Profession: </p>
                                        <p >{state?.assure?.profession}</p>
                                    </div>
                                </Col> */}

                                <Col md={24}>
                                    <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Email:  </p>
                                        <p >{subscriptionSelected?.assure?.email}</p>
                                    </div>
                                </Col>
                                <Col md={24}>
                                    <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Telephone: </p>
                                        <p >{subscriptionSelected?.assure?.phone}</p>
                                    </div>
                                </Col>

                                <Col md={24}>
                                    <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Type de piece:  </p>
                                        <p >{subscriptionSelected?.assure?.identityCardTitle}</p>
                                    </div>
                                </Col>
                                <Col md={24}>
                                    <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                        <p style={{ marginRight: 10, color: '#8E8E8E' }}>Numero de piece: </p>
                                        <p >{subscriptionSelected?.assure?.identityCardNumber}</p>
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Apporteur" key="7">
                            {
                                subscriptionSelected?.merchantCode && !state?.editMerchant ? (
                                    <>
                                        <Row>
                                            <Col md={24}>
                                                <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Code:  </p>
                                                    <p style={{ fonWeight: 'bold' }}>{subscriptionSelected?.merchantCode}</p>
                                                </div>
                                            </Col>

                                            <Col md={24}>
                                                <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Nom:  </p>
                                                    <p >{subscriptionSelected?.merchantFirstname} </p>
                                                </div>
                                            </Col>
                                            <Col md={24}>
                                                <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Prenoms: </p>
                                                    <p >{subscriptionSelected?.merchantLastname}</p>
                                                </div>
                                            </Col>

                                            <Col md={24}>
                                                <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Email:  </p>
                                                    <p >{subscriptionSelected?.merchantMail ?? '-'}</p>
                                                </div>
                                            </Col>
                                            <Col md={24}>
                                                <div style={{ display: 'flex', width: '98%', marginBottom: 15, justifyContent: 'space-between', borderBottom: '1px solid rgba(112, 114, 110, 0.2)' }}>
                                                    <p style={{ marginRight: 10, color: '#8E8E8E' }}>Telephone: </p>
                                                    <p >{subscriptionSelected?.merchantPhone}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        {CAN_EDIT_MERCHANT.includes(user.profile) && (
                                            <Divider children={(<>Edition <EditFilled style={{color: "blue"}} onClick={()=> setState(state=> ({...state, editMerchant: true}))}/></>)}/>
                                        )}
                                    </>
                                ) : (
                                    <RegisterMerchant 
                                        initialValues={{
                                            merchantCode      : subscriptionSelected?.merchantCode,
                                            merchantFirstname : subscriptionSelected?.merchantFirstname,
                                            merchantLastname  : subscriptionSelected?.merchantLastname,
                                            merchantPhone     : subscriptionSelected?.merchantPhone,
                                            merchantEmail     : subscriptionSelected?.merchantMail
                                        }}
                                        subscriptionRef={subscriptionSelected?.ref} 
                                        onMerchantRegistered={()=> onClose(true)}
                                    />
                                )
                            }
                            
                            
                        </TabPane>
                        {
                            subscriptionSelected?.coInsuranceApplied ? (
                                <TabPane tab="CoAssureurs" key="8">
                                    <DetailCoInsurance contract={subscriptionSelected} />
                                </TabPane>
                                
                            ) : null
                        }
                        {
                            ["CONTRACT_RESCIND", "CONTRACT_CANCEL"].includes(subscriptionSelected?.contract?.statusMessage) && (
                                <TabPane tab="Motif" key={8}>
                                    {!subscriptionSelected?.cancelMotif || state.editionCancelMotif ? (
                                        <AddAnnulationMotif subscriptionRef={subscriptionSelected?.ref} defautltContent={subscriptionSelected?.cancelMotif} onMotifApplying={motifApplying} />
                                        ) : (
                                        <div>
                                            <p>Motif : {subscriptionSelected?.cancelMotif ?? '---'}</p>
                                            <Divider children={(<>Edition <EditFilled onClick={()=> setState(state=> ({...state, editionCancelMotif: true}))}/></>)}/>
                                        </div>) }
                                    
                                </TabPane>
                            )
                        }
                        
                        
                    </Tabs>
                </div>
            </Drawer>
        </React.Fragment>
    )
}

export default DetailsSubscription;