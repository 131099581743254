import { Col, Form, Input, Space, Spin } from "antd";
import React from "react";import { useState } from "react";
import VehicleService from "services/vehicleService";

const MatriculationField=({title, disable, setMatriculationExist, handlerFormField, xs, md})=> {

    const [state, setState]=useState({
        matriculeSearch: {
            exist: false,
            message: ""
        },
        matriculationVerificationLoading: false
    })

    //TODO-OK: <Verification de matricule>
    const onVerifyMatriculation = (e) => {
        let _matriculation = e.target.value;
        if (_matriculation === undefined || _matriculation === "")
            return;

        setState(state => ({ ...state, matriculationVerificationLoading: true }));
        VehicleService.verifyMatriculation({matriculation:_matriculation}, (data) => {
            const exist = data?.exist;
            const message = data?.message;
            handlerFormField({matriculation: _matriculation})

            if(setMatriculationExist) {
                setMatriculationExist(exist) ;
            }
            
            setState(state => ({ ...state,  matriculeSearch: {exist: exist, message: message}, matriculationVerificationLoading: false }));
        }, (error) => {
            if(setMatriculationExist) {
                setMatriculationExist(true) 
            }  
            setState(state => ({ ...state, matriculeSearch: {exist: true, message: error}, matriculationVerificationLoading: false }));
        })
    }

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Immatriculation"
                    name="matriculation"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    <Input
                        size='large'
                        placeholder= {title ? title : "Numero d'Immatriculation du vehicule" }
                        onBlur={(e) => onVerifyMatriculation(e)}
                        suffix={state.matriculationVerificationLoading ? (<Space><div>Verification...</div><Spin size='small' /></Space>) : null}
                        disabled={disable || state?.matriculationVerificationLoading}
                    />

                </Form.Item>
                {state?.matriculeSearch.exist && (<span style={{ color: 'red', fontSize:"15px" }}>{state?.matriculeSearch?.message}</span>) }
            </Col>
            
        </React.Fragment>
    )
}

export default MatriculationField;