import { Col, DatePicker, Form } from "antd";
import { CATEGORY_406 } from "constantes";
import React from "react";
import { disabledNextDate } from "helpers/helpers";

const MecField=({ categoryCode, disable, xs, md })=> {

    if([CATEGORY_406].includes(categoryCode)) return null;

    return (
        <React.Fragment>
            <Col className="gutter-row" xs={xs ?? 12} md={md ?? 6}>
                <Form.Item
                    label="Date de 1ère mise en circulation"
                    name="dateMec"
                    rules={[{ required: true, message: "Champ obligatoire" }]}
                >
                    <DatePicker style={{ width: '100%' }} size='large' format={"DD/MM/YYYY"} disabledDate={disabledNextDate} disabled={disable} />
                </Form.Item>
            </Col>
        </React.Fragment>
    )
}

export default MecField;