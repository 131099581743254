import React from 'react'

import { makeStyles } from '@material-ui/styles'
import { LeftOutlined, HomeOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router';
import { Progress } from 'antd';
import { Card, CardBody, Col, Row } from 'reactstrap';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: "space-between",
    marginBottom: 10,
    borderBottom: "2px solid #F1F1F1",
  },
  spacer: {
    height: 20
  },
  item: {
    color: "#449D48",
    cursor: "pointer",
    fontSize: 16
  }
}));

export default function WizardHeader(props) {
  const { onClick, goToBack, title, percent, contentCenter, contentRight, avenantTilte, partnerCode, police, isProject } = props;

  const classes = useStyles();
  const { push } = useHistory();

  function handleGoBack() {
    if (goToBack) goToBack();
    else if (onClick) onClick();
    else return null;
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col lg={12} md={12}>
              <Row style={{borderBottom: "2px solid #F1F1F1"}}>
                <Col lg={4} md={4}>
                  {goToBack || onClick ? (
                      <div className={classes.item} onClick={() => handleGoBack()}>
                        <LeftOutlined /> Retour
                      </div>
                    ) : (
                      <div className={classes.item} onClick={() => push('/')}>
                        <HomeOutlined />
                      </div>
                    )}
                </Col>
                <Col lg={4} md={4} style={{textAlign: 'center'}}>
                  {(percent || contentCenter || percent === 0) && (
                    contentCenter || (
                      <div className='m-auto' style={{ width: 300 }}>
                        <Progress style={{ height: 30 }} percent={percent} />
                      </div>
                    )
                  )}
                </Col>
                <Col lg={4} md={4} style={{textAlign: 'right'}}>
                  {contentRight || <h4 className={classes.item}>{title}</h4>}
                </Col>
              </Row>
              {/* <div className={classes.container}>
                <div style={{width: 300}}>
                  {goToBack || onClick ? (
                    <div className={classes.item} onClick={() => handleGoBack()}>
                      <LeftOutlined /> Retour
                    </div>
                  ) : (
                    <div className={classes.item} onClick={() => push('/')}>
                      <HomeOutlined />
                    </div>
                  )}
                </div>

                {(percent || contentCenter || percent === 0) && (
                  contentCenter || (
                    <div style={{ width: 300 }}>
                      <Progress style={{ height: 30 }} percent={percent} />
                    </div>
                  )
                )}

                {contentRight || <h4 className={classes.item}>{title}</h4>}
              </div> */}
            </Col>

            <Col lg={12} md={12}>
                <Row>
                  <Col lg={4} md={4}>
                    <div  style={{fontWeight: "bold", fontSize: 18}}>Code: {partnerCode}</div>
                  </Col>
                  <Col lg={4} md={4}>
                    <div style={{  fontWeight: "bold", fontSize: 18, textAlign: 'center'}}>{avenantTilte}</div>
                  </Col>
                  <Col lg={4} md={4}>
                    <div className='ms-auto' style={{fontWeight: "bold", fontSize: 18, textAlign: 'right'}}>{police ? (isProject ? `N° devis: ${police}` : `Police: ${police}`) : ""}</div>
                  </Col>
                </Row>
                {/* <div className='d-flex align-items-lg-center flex-lg-row'>
                  <div  style={{fontWeight: "bold", fontSize: 18}}>Code: {partnerCode}</div>
                  <div className='ms-auto' style={{  fontWeight: "bold", fontSize: 18}}>{avenantTilte}</div>
                  <div className='ms-auto' style={{fontWeight: "bold", fontSize: 18}}>{police ? `Police: ${police}` : ""}</div>
                </div> */}
                
            </Col>
            
          </Row>
         
          
        </CardBody>

      </Card>
      {/* <Divider /> */}
    </>
  )
}
