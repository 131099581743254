import {  Drawer } from "antd";
import React, {  useState } from "react";
import PageResponse from "api/response/pageResponse";
import ContractsResponse from "api/response/contratsResponse";
import DetailCoInsurance from "../Component/CoInsurance/DetailCoInsurance";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import ContractTable from "../common/ContractTable";
import { TableAction } from "helpers/helpers";


export default function Coassurance(props) {
 
    const [state, setState] = useState({
        isCategoryLoading: true,
        categories: [],
        contractPageResponse: new PageResponse(),
        tempContractData: [],
        isContractLoading: true,
        drawerVisibility: false,
        contractSelected: new ContractsResponse()
    });

    const drawerClose = ()=> {
        setState((state)=>({...state, drawerVisibility: false}));
    }

    return (
        <React.Fragment>
            {/* <div style={{ display: 'flex', justifyContent: 'end' }}>
                <SelectWithFilter placeholder="Categorie" size="middle" style={{ minWidth: 200 }} onChange={(e) => onCategoryChange(e)}>
                    {
                        !state.isCategoryLoading ? (
                            <>
                                {
                                    state.categories.map(function (category, index) {
                                        return (<Option value={category.ref} key={index}>{category.code + ' - ' + category.title}</Option>)
                                    })
                                }
                            </>
                        ) : (<Spin />)
                    }
                </SelectWithFilter>
            </div>
            <br /> */}

            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <div>
                                <h4 style={{ borderBottom: '1px solid rgb(0, 117, 201)', borderLeft: '7px solid rgb(0, 117, 201)', paddingLeft: 10, paddingBottom: 5, width: 500 }}>{(`Liste des contracts coassurés`).toLocaleUpperCase()}</h4>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <ContractTable type={TableAction.COINSURANCE} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
            {state.drawerVisibility && (
                <Drawer
                    title="Details de la souscription"
                    width={720}
                    onClose={() => drawerClose()}
                    visible={state.drawerVisibility}
                    bodyStyle={{
                        paddingBottom: 80,
                    }}
                >
                    <DetailCoInsurance contract={state.contractSelected} />
                </Drawer>
            )}
        </React.Fragment>
    )
}