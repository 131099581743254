export default class Period {
    id;
    ref;
    code;
    days;
    months;
    title;
    customerStartDate;
    customerEnDate;
    prorate;

    fromJson = (json = {}) => {
        var period = new Period();
        period = json
        return period
    }
}