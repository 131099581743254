import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class GroupeReductionService {

    createGroupeReduction(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.createGroupeReductionResource, payload, sCallBack, eCallBack)
    }

    editGroupeReduction(options, payload, sCallBack, eCallBack) {
        ApiRoutes.editGroupeReductionResource.resourceOptions = Object.assign({}, ApiRoutes.editGroupeReductionResource.resourceOptions, options);
        exeRequest(ApiRoutes.editGroupeReductionResource, payload, sCallBack, eCallBack);
    }

    readAllGroupeReductions(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.groupeReductionResource, {}, sCallBack, eCallBack)
    }
}