import { Card, Form, Spin } from "antd";
import CustomAntdForm from "modules/components/Forms/CustomAntdForm";
import React, { useEffect, useState } from "react";
import Payment from "views/pages/subscription/component/payment/Payment";
import { ContractType } from "views/pages/subscription/component/ComponentWidget";
import FDetailsAssure from "../FDetailsAssure";
import FDetailsHeader from "../FDetailsHeader";
import FDetailsReductionAndPrime from "../FDetailsReductionAndPrimes";

const FStepAssureDetails = (props) => {
    const {
        data: {
            fBasket: { flotteSubscriptionRequest, packsSelected }
        }
    } = props

    const [form] = Form.useForm();
    const [fakeLoading, setFakeLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setFakeLoading(false)
        }, 2000)
    }, [])

    const successStatus = (transactionResponse) => {
        /* goToNextStep([
            { path: ['transactionResponse'], value: transactionResponse }
        ]); */
    }

    const errorStatus = () => {

    }


    if (fakeLoading) {
        return (
            <Card>
                <><Spin /> Chargement en cours ...</>
            </Card>
        )
    }

    return (
        <React.Fragment>
            
                <Card className='fistCard'>
                    <CustomAntdForm form={form}>
                        <FDetailsHeader primeTTC={flotteSubscriptionRequest?.primeTTC} /> <br />

                        <FDetailsAssure assure={flotteSubscriptionRequest?.assure} />
                        <FDetailsReductionAndPrime flotteSubscriptionRequest={flotteSubscriptionRequest} packsSelected={packsSelected} />
                    </CustomAntdForm >
                </Card>

                <Card >
                        <Payment
                            projectVisibility={false}
                            onlineVisiblity={true}
                            payload={flotteSubscriptionRequest}
                            successStatus={successStatus}
                            errorStatus={errorStatus}
                            //goBack={goToPreviousStep}
                            type={ContractType.FLOTTE}
                        />
                </Card>
            
        </React.Fragment>
    )
}

export default FStepAssureDetails;