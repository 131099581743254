import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class ContractService {

     // Recupere tous les souscriptions contrats
    static async readSubscriptionContracts(options={}, sCallBack, eCallBack ) {
        ApiRoutes.subscriptionContractsResource.resourceOptions = Object.assign({}, ApiRoutes.subscriptionContractsResource.resourceOptions, options);
        exeRequest(ApiRoutes.subscriptionContractsResource, {}, sCallBack, eCallBack)
    }

    // Recupere tous les souscriptions project
    readSubscriptionProjects(options={}, sCallBack, eCallBack ) {
        ApiRoutes.projectsResource.resourceOptions = Object.assign({}, ApiRoutes.projectsResource.resourceOptions, options);
        exeRequest(ApiRoutes.projectsResource, {}, sCallBack, eCallBack)
    }
    
    static deleteProjectSubscription(options={}, sCallBack, eCallBack) {
        ApiRoutes.deleteProjectResource.resourceOptions = Object.assign({}, ApiRoutes.deleteProjectResource.resourceOptions, options);
        exeRequest(ApiRoutes.deleteProjectResource, {}, sCallBack, eCallBack)
    }

    // Generate attestion documents
    static generateAttestation(options={}, sCallBack, eCallBack) {
        ApiRoutes.generateAttestationResource.resourceOptions = Object.assign({}, ApiRoutes.generateAttestationResource.resourceOptions, options);
        exeRequest(ApiRoutes.generateAttestationResource, {}, sCallBack, eCallBack)
    }

    // Toutes les souscriptions d'un contract d'un assuré
    static readAllContractDetailsByAssure(options={}, sCallBack, eCallBack) {
        ApiRoutes.assureSubscriptionsByCategoryResource.resourceOptions = Object.assign({}, ApiRoutes.assureSubscriptionsByCategoryResource.resourceOptions, options);
        exeRequest(ApiRoutes.assureSubscriptionsByCategoryResource, {}, sCallBack, eCallBack)
    }

    // Recupere tous les contract d'un assuré
    static readAssureContracts(options={}, sCallBack, eCallBack ) {
        ApiRoutes.assureContractsResource.resourceOptions = Object.assign({}, ApiRoutes.assureContractsResource.resourceOptions, options);
        exeRequest(ApiRoutes.assureContractsResource, {}, sCallBack, eCallBack)
    }

    // Toggle subscription status
    static toggleSubscriptionPaiementStatus(options={}, sCallBack, eCallBack) {
        ApiRoutes.subscriptionTogglePaimentStatus.resourceOptions = Object.assign({}, ApiRoutes.subscriptionTogglePaimentStatus.resourceOptions, options);
        exeRequest(ApiRoutes.subscriptionTogglePaimentStatus, {}, sCallBack, eCallBack)
    }
}