import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";
import * as constants from 'constantes';

export default class AvenantService {

    getDefaultAvenant(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.avenantDefaultResource, {}, sCallBack, eCallBack)
    }

    readAllAvenants(options, sCallBack, eCallBack) {
        ApiRoutes.readAllAvenantsResource.resourceOptions = Object.assign({}, ApiRoutes.readAllAvenantsResource.resourceOptions, options)
        exeRequest(ApiRoutes.readAllAvenantsResource, {}, sCallBack, eCallBack)
    }

    static init(initAvenantRequest, sCallBack, eCallBack) {
        // let options={
        //     path:{
        //         avenantCode: avenant?.code,
        //         contractRef: contract?.ref
        //     },
        //     query:{
        //         assureCode: assure?.code
        //     }
        // }
        // console.log("initAvenantRequest", initAvenantRequest)

        switch (parseInt(initAvenantRequest?.avenantCode)) {

            case constants.CODE_AVENANTS.CREATION:
            case constants.CODE_AVENANTS.RENOUVELEMENT_SANS_MODIFICATION:
            case constants.CODE_AVENANTS.RENOUVELEMENT_AVEC_MODIFICATION:
            case constants.CODE_AVENANTS.EXTENSION_RETRAIT_GARENTIES:
            case constants.CODE_AVENANTS.CHANGEMENT_IMMATRICULATION:
            case constants.CODE_AVENANTS.CHANGEMENT_ASSURE:
            case constants.CODE_AVENANTS.CHANGEMENT_VEHICULE:
            case constants.CODE_AVENANTS.PROROGATON:
            case constants.CODE_AVENANTS.DUPLICATA_ATTESTATION:
            case constants.CODE_AVENANTS.SUSPENSION:
                //ApiRoutes.initAvenantResource.resourceOptions = Object.assign({}, ApiRoutes.initAvenantResource.resourceOptions, options);
                exeRequest(ApiRoutes.initAvenantResource, { ...initAvenantRequest }, sCallBack, eCallBack);
                break
            default:
                exeRequest(ApiRoutes.initAvenantResource, { ...initAvenantRequest }, sCallBack, eCallBack);
        }

    }
}