export class HttpMethod {
    static GET = "GET";
    static POST = "POST";
    static PUT = "PUT";
    static DELETE = "DELETE";
    static OPTION = "OPTION";
}

export default class HTTPStatus {
    static STATUS_CODE_ERROR = 20;
    static STATUS_CODE_SUCCESS = 10;
    static STATUS_GRPC_CODE_SUCCESS = 0;
    static STATUS_HTTP_CODE_SUCCESS = 200;
    static STATUS_CODE_BAD_CREDENTIALS = 21;
    static UNAUTHORIZED = 401;
}