import ApiRoutes from "api/apiRoutes"
import { exeRequest } from "../http/requestConfig"

export default class ModelService {
    createModel(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.createModelResource, payload, sCallBack, eCallBack)
    }

    editModel(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.editModelResource, payload, sCallBack, eCallBack);
    }

    static readAllModels(sCallBack, eCallBack) {
        exeRequest(ApiRoutes.modelsResource, {}, sCallBack, eCallBack)
    }

    readModelsByBrand(brandRef, sCallBack, eCallBack) {
        let options = {
            query: { brandRef: brandRef, s: true }
        }
        ApiRoutes.modelsResource.resourceOptions = Object.assign({}, ApiRoutes.modelsResource.resourceOptions, options);
        exeRequest(ApiRoutes.modelsResource, {}, sCallBack, eCallBack);
    }

    static toggleStatus(options, sCallBack, eCallBack) {
        ApiRoutes.toggleStatusModelResource.resourceOptions = Object.assign({}, ApiRoutes.toggleStatusModelResource.resourceOptions, options);
        exeRequest(ApiRoutes.toggleStatusModelResource, {}, sCallBack, eCallBack)
    }
}