import React from 'react'
import { Row, Col, Button, Card, Form} from 'antd'
import CustomAntdForm from 'modules/components/Forms/CustomAntdForm';
import { FileDoneOutlined } from '@ant-design/icons';
import { AssureCodeField, AssureFirstNameField, AssureLastNameField, AssureSearchField, CardIDField, CardNumberField, EmailField, PhoneField } from 'views/pages/subscription/common/fields';
import { useBasket } from 'views/pages/subscription/hooks/useBasket';
import HeaderPrime from 'views/pages/subscription/common/HeaderPrime';

const FStepAssure = ({basket, primeTTC}) => {
    const { updateFormField, updateFSusbscriptionRequest, goToNextStep } = useBasket();
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                updateFormField({...values});
                updateFSusbscriptionRequest({isProrate: false})
                goToNextStep(null)
            })
            .catch(errorInfo => {
                console.log("errorInfo", errorInfo);
            });
    }

    return (
        <React.Fragment>
            <HeaderPrime prime={primeTTC} visible={true}/>
            <CustomAntdForm form={form} initialValues={basket?.formField}>
               
                <Card title={<div className='d-flex' style={{ height: 30, width: '100%', marginTop: -10, display: "flex" }}>
                                <div><h4 style={{ color: 'grey' }}>Informations sur l'assuré </h4></div>
                                <div className='ms-auto'>
                                    <AssureSearchField form={form} handlerFormField={updateFormField} newBtnVisibility={true}/>
                                    
                                </div>
                            </div>} headStyle={{ backgroundColor: '#e9e9e9', color: '#ffffff', height: 15 }}>
                    <div style={{display:'flex', justifyContent:'end'}}> 
                        
                    </div>
                    <Row>
                        <Col className="gutter-row" xs={12} md={6}>
                            <AssureCodeField disable={true} />
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                        
                        <Col className="gutter-row" xs={12} md={6}>
                            <AssureFirstNameField disable={basket?.formField?.assureCode} />
                        </Col>

                        <Col className="gutter-row" xs={12} md={6}>
                            <AssureLastNameField disable={basket?.formField?.assureCode} />
                        </Col>

                        <Col className="gutter-row" xs={12} md={6}>
                           <PhoneField disable={basket?.formField?.assureCode} />
                        </Col>

                        <Col className="gutter-row" xs={12} md={6} >
                        </Col>
                        <Col className="gutter-row" xs={12} md={6}>
                            <EmailField disable={basket?.formField?.assureCode}/>
                        </Col>

                        <Col className="gutter-row" xs={12} md={6}>
                            <CardIDField handlerFormField={updateFormField} disable={basket?.formField?.assureCode}/>
                        </Col>

                        <Col className="gutter-row" xs={12} md={6}>
                            <CardNumberField disable={basket?.formField?.assureCode} />
                        </Col>
                    </Row>
                    
                </Card>

                <Card >
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        {/* <Button
                            style={{ color: '#005680', border: '1px solid #005680', borderRadius: 8, marginRight: 10, marginTop: 21, marginBottom: 20 }}
                            onClick={()=> goToPreviousStep(null)}
                        >
                            <LeftOutlined style={{ color: '#005680' }} /> Retour
                        </Button> */}

                        <Button
                            style={{ color: '#ffffff', borderRadius: 8, backgroundColor: '#005680', marginRight: 10, marginTop: 21, marginBottom: 20, border: '1px solid #005680' }}
                            onClick={handleSubmit}
                        >
                            <FileDoneOutlined style={{ color: '#ffffff', fontSize: 20 }} /> PASSER AU DETAILS DU CONTRAT
                        </Button>
                    </div>
                </Card>

            </CustomAntdForm>
        </React.Fragment>
    )
}

export default FStepAssure;
