
import ApiRoutes from "api/apiRoutes";
import { exeRequest } from "../http/requestConfig";

export default class GuaranteeService {
    static createGarantie(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.createGuaranteeResource, payload, sCallBack, eCallBack)
    }

    static editGarantie(payload, sCallBack, eCallBack) {
        exeRequest(ApiRoutes.editGuaranteeResource, payload, sCallBack, eCallBack);
    }

    static getAll(options, sCallBack, eCallBack) {
        ApiRoutes.guaranteesResource.resourceOptions = Object.assign({}, ApiRoutes.guaranteesResource.resourceOptions, options);
        exeRequest(ApiRoutes.guaranteesResource, {}, sCallBack, eCallBack)
    }

    static toggleStatus(options, sCallBack, eCallBack) {
        ApiRoutes.toggleStatusGuaranteeResource.resourceOptions = Object.assign({}, ApiRoutes.toggleStatusGuaranteeResource.resourceOptions, options);
        exeRequest(ApiRoutes.toggleStatusGuaranteeResource, {}, sCallBack, eCallBack)
    }

    static getCategoryAndPack(options, sCallBack, eCallBack) {
        ApiRoutes.guaranteeLineResource.resourceOptions = Object.assign({}, ApiRoutes.guaranteeLineResource.resourceOptions, options);
        exeRequest(ApiRoutes.guaranteeLineResource, {}, sCallBack, eCallBack)
    }

    static getCategoryFreePackGuaranties(options, sCallBack, eCallBack) {
        ApiRoutes.freePackGuaranteeLineResource.resourceOptions = Object.assign({}, ApiRoutes.freePackGuaranteeLineResource.resourceOptions, options);
        exeRequest(ApiRoutes.freePackGuaranteeLineResource, {}, sCallBack, eCallBack)
    }
}